import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { app } from '../app';
import { I18nText, translate } from '../i18n_text';
import { StageChangeChartData, varietyStageChangeChart } from '../api/overview';

let template = require('raw-loader!../../templates/components/variety_stage_change_chart.html').default;

class VarietyStageChangeChart {
  private id: string;
  result: Deferred<{}>;

  loading = ko.observable(true);
  data = ko.observable<StageChangeChartData>();

  constructor(params: { id: string; result: Deferred<{}> }) {
    this.id = params.id;
    this.result = params.result;

    this.fetch();
  }

  private async fetch() {
    try {
      this.data(await varietyStageChangeChart(this.id));
    } finally {
      this.loading(false);
    }
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

ko.components.register('variety-stage-change-chart', {
  viewModel: VarietyStageChangeChart,
  template: template,
});

export function openVarietyStageChangeChart(variety: { id: string; name: string | I18nText }) {
  app.formsStackController.push({
    title: translate(variety.name),
    name: 'variety-stage-change-chart',
    showNav: true,
    className: 'variety-stage-change-chart',
    params: { id: variety.id, result: new Deferred<{}>() },
  });
}
