import { makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';
import { ClientTypeData } from './client_types';

export interface DriverData {
  id?: string;
  name_json: I18nText;
  client_type: ClientTypeData;
}

export const { list, retrieve, save, remove } = makeDefaultApi<DriverData, { client_type_ids?: string[] }>(
  'drivers'
);
