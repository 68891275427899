import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { Unit } from '../models/unit';
import * as unitCategoriesApi from '../api/unit_categories';
import { FormNestedEntitiesConfiguration } from '../components/form_nested_entities';
import i18n from '../i18n';
import { createWithComponent } from '../utils/ko_utils';
import { saveTenantUnits, tenantUnits } from '../api/units';
import { canEditUnits } from '../permissions';

let template = require('raw-loader!../../templates/units_edit.html').default;

interface UnitCategoryConfig extends FormNestedEntitiesConfiguration<Unit> {
  categoryName: string;
  canonicalUnit: string;
  showOffset: boolean;
}

class UnitsEditScreen extends BaseForm<{}> {
  units = ko.observableArray<Unit>();
  unitCategoryConfigs: UnitCategoryConfig[] = [];
  globalError = ko.observable('');
  canEdit = ko.observable(canEditUnits());

  constructor(params: {}, componentInfo: KnockoutComponentTypes.ComponentInfo) {
    super({});

    let unitCategoriesDataPromise = unitCategoriesApi.list();
    let promise = Promise.all([unitCategoriesDataPromise, tenantUnits()]).then(
      ([unitCategoriesData, tenantUnitsData]) => {
        this.units(tenantUnitsData.units.map((u) => new Unit(u.unit_category, u)));
        this.unitCategoryConfigs = unitCategoriesData.map((data) => this.getUnitsConfig(data));
      }
    );
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  private getUnitsConfig(category: unitCategoriesApi.UnitCategoryData): UnitCategoryConfig {
    let categoryUnits = ko.observableArray<Unit>(
      this.units().filter((unit) => unit.unitCategorySlug == category.slug)
    );

    return {
      title: category.name + ':',
      addTitle: i18n.t('Add Unit')(),
      missingTitle: i18n.t('Incomplete unit')(),
      categoryName: category.name,
      canonicalUnit: category.canonical_unit,
      showOffset: category.slug == 'temperature',

      entities: categoryUnits,

      canDisable: () => false,
      isTrialActive: () => false, // Since canDisable is false this will never be visible
      disabled: () => false,
      disable: () => {},

      canRemove: (entity) => {
        return entity.canDelete;
      },

      add: () => {
        let unit = new Unit(category.slug);
        categoryUnits.push(unit);
        this.units.push(unit);
        return unit;
      },

      remove: (entity) => {
        categoryUnits.remove(entity);
        this.units.remove(entity);
      },

      hasErrors: (entity) => {
        return entity.hasErrors();
      },

      showErrors: (entity) => {
        entity.showErrors();
      },

      actions: [],

      getSummaryName: (entity) => {
        return entity.description() && entity.name()
          ? entity.description() + ' (' + entity.name() + ')'
          : '';
      },
    };
  }

  save = () => {
    this.globalError('');
    for (let unit of this.units()) {
      unit.conversionFactor.serverError(null);
      unit.conversionOffset.serverError(null);
    }

    if (this.validateLocal(this.units)) {
      let missing = this.unitCategoryConfigs
        .filter((category) => category.entities().length == 0)
        .map((category) => category.categoryName);

      if (missing.length > 0) {
        this.globalError(
          i18n.t(
            "You must define at least one unit for each category. The following categories don't have any units defined: "
          )() + missing.join(', ')
        );
        this.saving(false);
        return;
      }

      this.executeSaveRequest(saveTenantUnits({ units: this.units().map((u) => u.toData()) })).then(
        (validation) => {
          this.onRemoteValidation({}, this.units, validation);

          // propagate validation errors down
          if (!validation.isValid) {
            let units = this.units();
            let unitErrors = <any>validation.errors['units'];
            if (unitErrors) {
              for (let i = 0; i < unitErrors.length; i++) {
                let unit = units[i];
                let unitError = unitErrors[i];
                if (typeof unitError === 'string') {
                  this.globalError(unitError);
                  break;
                }
                if (unitError.conversion_factor) {
                  this.setServerError(unit.conversionFactor, unitError.conversion_factor);
                }
                if (unitError.conversion_offset) {
                  this.setServerError(unit.conversionOffset, unitError.conversion_offset);
                }
              }
            }
          }
        }
      );
    }
  };

  dispose = () => {
    this.units().forEach((unit) => unit.dispose());
  };
}

export const unitsEdit = {
  name: 'units-edit',
  viewModel: createWithComponent(UnitsEditScreen),
  template,
};

ko.components.register(unitsEdit.name, unitsEdit);
