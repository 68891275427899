import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { GeoJSON } from '../api/datasets';

let template = require('raw-loader!../../templates/components/map.html').default;

class Map {
  value: GeoJSON[];

  result: Deferred<{}>;

  constructor(params: { value: GeoJSON[]; result?: Deferred<{}> }) {
    this.value = params.value;
    this.result = params.result;
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

ko.components.register('map', { viewModel: Map, template: template });
