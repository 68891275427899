import * as ko from 'knockout';

import { BaseForm } from '../screens/base_form';
import { RegistrationHistory } from '../models/registration_history';
import { RegistrationHistoryData, registrationApi, registrationHistoryApi } from '../api/registrations';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/registration_history_edit.html').default;

class RegistrationHistoryEditScreen extends BaseForm<RegistrationHistoryData> {
  entity = ko.observable<RegistrationHistory>(null);

  constructor(
    params: {
      registrationId: string;
      result?: Deferred<RegistrationHistoryData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let request = params.registrationId ? registrationApi.retrieve(params.registrationId) : undefined;
    let promise = Promise.all([request]).then(([data]) => {
      this.entity(new RegistrationHistory(data));
    });
    this.loadedAfter(promise);
  }

  save = () => {
    if (!this.entity()) {
      return;
    }

    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(registrationHistoryApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let registrationHistoryEdit = {
  name: 'registration-history-edit',
  viewModel: createWithComponent(RegistrationHistoryEditScreen),
  template: template,
};

ko.components.register(registrationHistoryEdit.name, registrationHistoryEdit);
