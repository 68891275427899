import * as ko from 'knockout';

import * as dashboardApi from '../api/dashboard';
import { app } from '../app';
import i18n from '../i18n';
import { factChangeReasons } from '../models/attribute_meta';
import { setGlobalError } from '../screens/base_edit';
import { Deferred } from '../utils/deferred';
import { openFactEdit } from '../screens/fact_edit';
import { sortBy } from 'lodash';
import * as factsApi from '../api/facts';
import { tryFormatDateTime , STORAGE_KEYS} from '../utils';
import { OverviewClickAction} from '../screens/trial_facts'


const template = require('raw-loader!../../templates/components/observation_history_dialog.html').default;

class ObservationHistoryDialog {
  loading = ko.observable(true);
  saving = ko.observable(false);
  error = ko.observable('');
  selectedValue = ko.observable<number>(null);
  atLeastOnePictureComment = ko.observable(false);
  values = ko.observableArray<dashboardApi.FactHistoricValueData>();
  restoreButtonLabel = ko.pureComputed(() => {
    if (this.saving()) {
      return i18n.t('Restoring...')();
    }
    return i18n.t('Restore')();
  });

  plotName = ko.observable('');
  referenceDimName = ko.observable('');
  description = ko.observable('');
  openFileMenusIndexes = ko.observableArray<number>([]);
  actionsConfig = ko.observable<ActionsConfig>();
  actionsDisplayConfig = ko.observable<ActionsDisplayConfig>();
  factId = ko.observable<string>('');
  mmId = ko.observable<string>('');

  constructor(
    private params: {
      factId: string;
      mmId: string;
      actionsConfig: ActionsConfig;
      actionsDisplayConfig: ActionsDisplayConfig;
      result?: Deferred<void>;
    }
  ) {
    this.actionsConfig(params.actionsConfig);
    this.actionsDisplayConfig(params.actionsDisplayConfig);

    this.onFactAndMmIdChange(params.factId, params.mmId);
  }

  init = () => {
    dashboardApi.history(this.factId(), this.mmId()).then((data) => {
      // The sorting here allows us to display the documents first, and
      // then the images
      const orderedData = data.map((factLog) => ({
        ...factLog,
        files_extras: sortBy(factLog.files_extras, (fileExtra) => this.isImage(fileExtra.mime_type)),
      }));

      this.values(orderedData);

      this.atLeastOnePictureComment(
        orderedData.some((factLog) => {
          return factLog.files_extras.some((fileExtra) => fileExtra.comment);
        })
      );
      factsApi.retrieve(this.factId()).then((data) => {
        this.plotName(data.plot_name);
        this.referenceDimName(data.reference_dim_name);
        this.description(data.dimensions.map((data) => data.dm_name + ': ' + data.dim_name).join(', '));

        this.loading(false);
      });
    });
  };

  onNextClick = () => {
    if (!this.actionsDisplayConfig().isNextButtonEnabled()) {
      return;
    }
    const [factId, measurementMetaId] = this.actionsConfig().getNextFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onPrevClick = () => {
    if (!this.actionsDisplayConfig().isPrevButtonEnabled()) {
      return;
    }
    const [factId, measurementMetaId] = this.actionsConfig().getPrevFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onNextSkipClick = () => {
    if (!this.actionsDisplayConfig().isNextSkipButtonEnabled()) {
      return;
    }

    const [factId, measurementMetaId] = this.actionsConfig().getNextSkipFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onPrevSkipClick = () => {
    if (!this.actionsDisplayConfig().isPrevSkipButtonEnabled()) {
      return;
    }
    const [factId, measurementMetaId] = this.actionsConfig().getPrevSkipFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  private onFactAndMmIdChange = (factId: string, measurementMetaId: string) => {
    this.factId(factId);
    this.mmId(measurementMetaId);

    this.init();
  };

  getChangeReasonTitle = (value: dashboardApi.FactHistoricValueData) => {
    return factChangeReasons.find((c) => c.value === value.reason)?.title;
  };

  getGPSCoordinates = (value: dashboardApi.FactHistoricValueData) => {
    if (value.latitude && value.longtitude) {
      return `${value.latitude},${value.longtitude}`;
    }
    return null;
  };

  isFileMenuOpen = (valueIndex: number) => {
    return this.openFileMenusIndexes().includes(valueIndex);
  };

  closeFileMenu = (valueIndex: number) => {
    this.openFileMenusIndexes.remove(valueIndex);
  };

  openFileMenu = (valueIndex: number) => {
    if (this.openFileMenusIndexes().includes(valueIndex)) {
      return;
    }
    this.openFileMenusIndexes.push(valueIndex);
  };

  getCommentOnlyExtra = (value: dashboardApi.FactHistoricValueData) => {
    if (value.comment_only_extra) {
      return value.comment_only_extra.comment;
    }
    return '-';
  };

  isImage(mimeType: string) {
    return mimeType.startsWith('image/');
  }

  getGPSCoordinatesLink = (value: dashboardApi.FactHistoricValueData) => {
    if (value.latitude && value.longtitude) {
      return `https://www.google.com/maps/search/${value.latitude},${value.longtitude}`;
    }
    return null;
  };

  getGPSWarningMessage = (value: dashboardApi.FactHistoricValueData) => {
    return `${i18n.t('GPS was valid at')()} ${tryFormatDateTime(value.location_valid_at)}`
  }

  selectValue = (valueIndex: number) => {
    if (!this.saving()) {
      this.selectedValue(valueIndex);
    }
  };

  goToEdit = () => {
    app.formsStackController.clear();

    openFactEdit(this.factId(), this.mmId(), this.actionsConfig(), this.actionsDisplayConfig()).then(() =>
      this.params.result.reject()
    );
  };

  restore = async (valueIndex: number) => {
    if (valueIndex === null || valueIndex === undefined || this.saving()) {
      return;
    }

    this.saving(true);

    try {
      const factLog = this.values()[valueIndex];
      const extraLogIds = [];
      if (factLog.comment_only_extra) {
        extraLogIds.push(factLog.comment_only_extra.id);
      }
      if (factLog.files_extras) {
        extraLogIds.push(...factLog.files_extras.map((f) => f.id));
      }

      const res = await dashboardApi.restoreHistory(factLog.id, this.params.mmId, extraLogIds);
      if (!res.isValid) {
        setGlobalError(this.error, res);
      } else {
        this.params.result.resolve();
      }
    } finally {
      this.closeFileMenu(valueIndex);
      this.saving(false);
    }
  };

  close = () => {
    this.params.result.reject();
  };
}

const name = 'observation-history-dialog';

ko.components.register(name, { viewModel: ObservationHistoryDialog, template });

interface ActionsConfig {
  getNextFactIdAndMmId?: () => [string, string];
  getPrevFactIdAndMmId?: () => [string, string];
  getNextSkipFactIdAndMmId?: () => [string, string];
  getPrevSkipFactIdAndMmId?: () => [string, string];
}

interface ActionsDisplayConfig {
  isNextButtonEnabled: () => boolean;
  isPrevButtonEnabled: () => boolean;
  isNextSkipButtonEnabled: () => boolean;
  isPrevSkipButtonEnabled: () => boolean;
}
export function openObservationHistoryDialog(
  factId: string,
  mmId: string,
  actionsConfig: ActionsConfig,
  actionsDisplayConfig?: ActionsDisplayConfig
): Promise<void> {
  localStorage.setItem(STORAGE_KEYS.OVERVIEW_CLICK_ACTION, OverviewClickAction.HISTORY)

  return app.formsStackController.push({
    title: i18n.t('Observation history')(),
    name,
    params: { factId, mmId, actionsConfig, actionsDisplayConfig, result: new Deferred<void>() },
    isBig: true,
    showNav: false,
  });
}
