import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { RegistrationRegion } from '../models/registration_region';
import { RegistrationRegionData, regRegionsApi } from '../api/registration_regions';
import { BaseEditScreen } from './base_edit';
import { canEditRegistrationRegions } from '../permissions';

let template = require('raw-loader!../../templates/registration_region_edit.html').default;

class RegistrationRegionEditScreen extends BaseEditScreen<RegistrationRegionData, RegistrationRegion> {
  canEdit = ko.observable(canEditRegistrationRegions());

  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<RegistrationRegionData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super({ api: regRegionsApi, focus: true }, params, componentInfo);
  }

  instantiate(data: RegistrationRegionData) {
    return new RegistrationRegion(data);
  }
}

export let registrationRegionEdit = {
  name: 'registration-region-edit',
  viewModel: createWithComponent(RegistrationRegionEditScreen),
  template: template,
};

ko.components.register(registrationRegionEdit.name, registrationRegionEdit);
