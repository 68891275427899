import * as ko from 'knockout';

import { CropVarietyData } from '../api/crop_varieties';
import { CountryData } from '../api/countries';
import {
  getCountrySearchConfig,
  getRegistrationRegionSearchConfig,
  getPISearchConfig,
} from '../components/configs/search_configs';
import { RegistrationData, RegistrationPhaseType } from '../api/registrations';
import { RegistrationPhase } from '../models/registration_phase';
import { FormNestedEntitiesConfiguration } from '../components/form_nested_entities';
import {
  RegistrationHistory,
  RegionalRegistrationHistory,
  NationalRegistrationHistory,
} from './registration_history';
import i18n from '../i18n';
import { RegistrationRegionData } from '../api/registration_regions';
import { translate } from '../i18n_text';
import { tryFormatDate } from '../utils';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { PortofolioItemData } from '../api/portofolio_items';
import { session } from '../session';

export class Registration {
  id = ko.observable<string>(null);
  cropVariety = ko.observable<CropVarietyData>(null).extend({
    required: true,
  });
  region = ko.observable<RegistrationRegionData>(null);
  country = ko.observable<CountryData>(null).extend({ required: true });
  supportBySFSA = ko.observable(true);
  regionalHistory = ko.observableArray<RegionalRegistrationHistory>();
  nationalHistory = ko.observableArray<NationalRegistrationHistory>();
  portofolioItems = ko.observableArray<PortofolioItemData>();

  editUrl: string;
  addHistoryUrl: string;

  countrySearchConfig = getCountrySearchConfig(this.country);
  regionSearchConfig = getRegistrationRegionSearchConfig(this.region);
  piSearchConfig: FormSelectSearchConfiguration<PortofolioItemData>;

  historyConfig: FormNestedEntitiesConfiguration<RegistrationHistory> = {
    title: i18n.t(['registrations_phases_colon', 'Registration phases:']),
    addTitle: '',
    missingTitle: i18n.t('No phase selected'),
    entities: null,
    actions: [],

    canDisable: () => false,
    isTrialActive: () => false, // Since canDisable is false this will never be visible
    disabled: () => false,
    disable: () => {},

    canRemove: () => true,
    add: () => null,
    remove: (entity) => null,
    hasErrors: (entity) => entity.hasErrors(),
    showErrors: (entity) => entity.showErrors(),
    getSummaryName: (entity) => {
      if (!entity.phase()) {
        return '';
      }
      let phase = translate(entity.phase().name_json);
      let date = tryFormatDate(entity.changed());
      let typeName = RegistrationPhase.TYPE_OPTIONS.find(
        (option) => option.value === entity.phase().type
      ).name;
      if (phase && !date) {
        return `${typeName} ${phase}`;
      }
      if (phase && date) {
        return `${date}: ${typeName} ${phase}`;
      }

      return '';
    },
  };

  regionalHistoryConfig: FormNestedEntitiesConfiguration<RegionalRegistrationHistory> = {
    ...this.historyConfig,
    title: i18n.t(['regional_registrations_phases_colon', 'Regional Registration phases:']),
    addTitle: i18n.t('Add Regional Phase Change'),
    entities: this.regionalHistory,
    add: () => {
      let history = new RegionalRegistrationHistory(null);
      this.regionalHistory.push(history);
      return history;
    },
    remove: (entity) => this.regionalHistory.remove(entity),
  };

  nationalHistoryConfig: FormNestedEntitiesConfiguration<NationalRegistrationHistory> = {
    ...this.historyConfig,
    title: i18n.t(['national_registrations_phases_colon', 'National Registration phases:']),
    addTitle: i18n.t('Add National Phase Change'),
    entities: this.nationalHistory,
    add: () => {
      let history = new NationalRegistrationHistory(null);
      this.nationalHistory.push(history);
      return history;
    },
    remove: (entity) => this.nationalHistory.remove(entity),
  };

  constructor(data?: RegistrationData) {
    if (data) {
      this.id(data.id);
      this.cropVariety(data.crop_variety);
      this.country(data.country);
      this.region(data.region);
      this.supportBySFSA(data.support_by_sfsa);
      this.portofolioItems(data.portofolio_items);
      const regionalRegistrationPhases = data.history.filter(
        (entry) => entry.phase.type === RegistrationPhaseType.REGIONAL
      );
      const nationalRegistrationPhases = data.history.filter(
        (entry) => entry.phase.type === RegistrationPhaseType.NATIONAL
      );

      this.regionalHistory(
        regionalRegistrationPhases.map((entry) => new RegionalRegistrationHistory(null, entry))
      );
      this.nationalHistory(
        nationalRegistrationPhases.map((entry) => new NationalRegistrationHistory(null, entry))
      );

      const sorting = (h1: RegistrationHistory, h2: RegistrationHistory) =>
        h1.changed().getTime() - h2.changed().getTime();
      this.regionalHistory.sort(sorting);
      this.nationalHistory.sort(sorting);
    }
    this.editUrl = '/registrations/' + this.id();
    this.addHistoryUrl = '/registrations/' + this.id() + '/history/new/';
    this.piSearchConfig = getPISearchConfig(this.portofolioItems, {
      disableCreate: true,
      tenantId: session.tenant().slug,
    });
  }

  regionCountryName = ko.pureComputed(() =>
    this.region() ? translate(this.region().name_json) : this.country().name
  );

  regionName = ko.computed(() => (this.region() ? translate(this.region().name_json) : '-'));

  countryName = ko.computed(() => (this.country() ? this.country().name : '-'));

  regionalPhases = ko.pureComputed(() => this.regionalHistory());

  nationalPhases = ko.pureComputed(() => this.nationalHistory());

  regionalPhase = ko.pureComputed(() =>
    this.regionalPhases().length > 0 ? this.regionalPhases()[this.regionalPhases().length - 1].phase() : '-'
  );
  regionalPhaseChanged = ko.pureComputed(() =>
    this.regionalPhases().length > 0
      ? this.regionalPhases()[this.regionalPhases().length - 1].changed()
      : '-'
  );
  nationalPhase = ko.pureComputed(() =>
    this.nationalPhases().length > 0 ? this.nationalPhases()[this.nationalPhases().length - 1].phase() : '-'
  );
  nationalPhaseChanged = ko.pureComputed(() =>
    this.nationalPhases().length > 0
      ? this.nationalPhases()[this.nationalPhases().length - 1].changed()
      : '-'
  );

  toData(): RegistrationData {
    return {
      id: this.id(),
      crop_variety: this.cropVariety(),
      country: this.country(),
      region: this.region(),
      support_by_sfsa: this.supportBySFSA(),
      history: [
        ...this.regionalHistory().map((h) => h.toData()),
        ...this.nationalHistory().map((h) => h.toData()),
      ],
      portofolio_items: this.portofolioItems()
    };
  }
}
