import * as ko from 'knockout';

import { app } from '../app';
import i18n from '../i18n';
import { Deferred } from '../utils/deferred';
import QrScanner from 'qr-scanner';

const template = require('raw-loader!../../templates/components/scan_qr_code.html').default;

class QRCodeScanner {
  details: string[] = [];
  private result: Deferred<{}>;

  qrScanner: any = null;
  callback: (uuid: string) => any;
  errorMessage?: ko.Observable<string | undefined>;

  constructor(params: { callback: (uuid: string) => any; result: Deferred<{}> }) {
    this.result = params.result;
    this.errorMessage = ko.observable(undefined);

    const videoElement = document.getElementById('scan-qr-video');
    this.qrScanner = new QrScanner(videoElement as HTMLVideoElement, (result) => this.onQRcapture(result));
    this.qrScanner.start().catch((error: Error) => this.errorMessage(error.toString()));
    this.callback = params.callback;
    document.getElementById('second-camera').appendChild(this.qrScanner.$canvas);
  }

  onQRcapture = (uuid: string) => {
    if (uuid) {
      this.qrScanner.stop();
      this.callback(uuid);
    }
  };

  onClose = () => {
    this.qrScanner?.destroy();
    this.result.resolve(null);
  };
}

ko.components.register('scan-qr-code', {
  viewModel: QRCodeScanner,
  template,
});

export function openQRCodeScanner(callback: (uuid: string) => void): Promise<{}> {
  return app.formsStackController.push({
    title: i18n.t('Scan QR Code')(),
    name: 'scan-qr-code',
    params: { callback, result: new Deferred<{}>() },
  });
}
