import * as ko from 'knockout';
import page from 'page';
import i18n from '../i18n';

import { confirmNavigation, clearConfirmNavigation } from '../utils/routes';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as trialsApi from '../api/trials';
import * as usersApi from '../api/users';
import * as trialTypesApi from '../api/trial_types';
import { DimensionMeta } from '../models/dimension_meta';
import { TrialWizard, Trial } from '../models/trial';
import { TrialState } from '../models/TrialState';
import { BaseTrialStep } from '../components/trial_wizard/base';
import { BaseForm } from './base_form';
import { deepEquals } from '../utils/deep_equals';
import { makeTrialCopyName } from './trial_copy';
import { loggingErrors } from '../error_logging';
import { translate, asI18nText, getDefaultTranslation } from '../i18n_text';
import { canEditTrialLimited } from '../permissions';
import { unlinkMM } from '../models/measurement_meta';
import { getTooManyPlotsText } from '../components/trial_wizard/trial_layout';
import { session } from '../session';
import { confirmDialog } from '../components/confirm_dialog';
import { warnIfPlotCountExceeded } from '../utils/plot_count_utils';

let template = require('raw-loader!../../templates/trial_wizard.html').default;

const sleep = (ms = 0) => new Promise(resolve => setTimeout(resolve, ms));

export class WizardController {
  trialWizard: KnockoutObservable<TrialWizard>;
  showAdvanced: KnockoutObservable<boolean>;
  ready = ko.observable(false);
  screen: BaseTrialStep;

  constructor(trialWizard: KnockoutObservable<TrialWizard>, showAdvanced: KnockoutObservable<boolean>) {
    this.trialWizard = trialWizard;
    this.showAdvanced = showAdvanced;
  }

  /**
   * Bind this controller to a particular wizard step.
   *
   * The wizard step needs to have a 'trial' member and also all the methods
   * called by this class.
   *
   * @param screen  view model representing a wizard step
   */
  bind(screen: BaseTrialStep) {
    screen.trialWizard = this.trialWizard;
    screen.showAdvanced = this.showAdvanced;
    screen.ready = this.ready;
    this.screen = screen;
  }
}

interface WizardStep {
  title: KnockoutComputed<string>;
  componentName: string;
}

interface GateInnovationParams {
  innovation_id: string;
  innovation_name: string;
  user: string;
  owner: string | null;
  editor: string | null;
  viewers: string[];
  category: string;
}

class TrialWizardScreen extends BaseForm<{}> {
  // shared among wizard, controllers & wizard steps
  trialWizard = ko.observable<TrialWizard>();

  wizardSteps: ko.ObservableArray<WizardStep> = ko.observableArray();
  wizardCurrentStep = ko.observable(0);
  wizardControllers: WizardController[] = [];

  showAdvanced = ko.observable(false);
  isActionsMenuOpen = ko.observable(false);
  errorsNotice = ko.observable('');

  private lastEnabledStep = ko.observable(0);
  private lastSavedData: trialsApi.TrialWizardData = null;
  private userData: usersApi.UserData;

  private initialId: string;
  private initialTemplate: boolean;
  id = ko.pureComputed(() => this.trialWizard()?.trial().id() ?? this.initialId);
  template = ko.pureComputed(() => this.trialWizard()?.trial()?.template ?? this.initialTemplate);
  warningController = ko.pureComputed(() => this.trialWizard()?.warningController());

  constructor(params: {
    id?: string;
    editMode: trialsApi.EditMode;
    template: boolean;
    fromTemplateId?: string;
    resetTemplate?: boolean;
    fromBaseTemplate?: boolean;
    cropId?: string;
    gateParams?: GateInnovationParams;
  }) {
    super({});

    this.initialId = params.id;
    this.initialTemplate = params.template;

    this.showAdvanced(localStorage.getItem('trial-wizard-advanced') === 'true');

    let mePromise = usersApi.me();
    let siteDMPromise = dimensionMetasApi.retrieve(dimensionMetasApi.SITE_SLUG);
    let replicationDMPromise = dimensionMetasApi.retrieve(dimensionMetasApi.REPETITION_SLUG);
    let wizardPromise = params.id ? trialsApi.retrieveWizard(params.id) : undefined;
    let templatePromise = params.fromTemplateId
      ? trialsApi.retrieveWizard(params.fromTemplateId, params.cropId)
      : undefined;
    let usersPromise = params.gateParams ? usersApi.list({}) : undefined;
    let trialTypesPromise = params.gateParams ? trialTypesApi.list({}) : undefined;

    let loaded = Promise.all([
      mePromise,
      usersPromise,
      siteDMPromise,
      replicationDMPromise,
      wizardPromise,
      templatePromise,
      trialTypesPromise,
    ]).then(
      ([
        userData,
        usersData,
        siteDMData,
        replicationDMData,
        trialWizardData,
        templateWizardData,
        trialTypesData,
      ]) => {
        if (!trialWizardData && templateWizardData) {
          trialWizardData = templateWizardData;
          this.setupCopyData(
            trialWizardData,
            params.template,
            params.resetTemplate,
            params.fromBaseTemplate
          );
        }

        this.userData = userData;
        this.trialWizard(
          new TrialWizard(
            new DimensionMeta(siteDMData),
            new DimensionMeta(replicationDMData),
            userData,
            params.template,
            params.editMode,
            trialWizardData
          )
        );

        const steps = this.initWizardSteps();
        if (this.trialWizard().trial().tpp()) {
          this.trialWizard()
            .traits.load()
            .then(() => this.trialWizard().checkTPPTraits());
        }
        // controllers existence == wizard data loaded
        this.wizardControllers = steps.map(() => new WizardController(this.trialWizard, this.showAdvanced));

        // check if user had previously quit the wizard after step 1
        if (this.trialWizard().testSubjects().length > 0) {
          this.lastEnabledStep(steps.length - 1);
        }
        this.lastSavedData = this.trialWizard().toData();

        params.gateParams &&
          this.setGateInnovationParams(params.gateParams, userData, usersData, trialTypesData);
      }
    );

    this.loadedAfter(loaded);

    confirmNavigation(
      i18n.t(
        'There are unsaved changes. If you leave the page you will lose them. Are you sure you want to continue?'
      )(),
      this.hasUnsavedChanges
    );
  }

  private async setGateInnovationParams(
    gateParams: GateInnovationParams,
    userData: usersApi.UserData,
    usersData: usersApi.UserData[],
    trialTypesData: trialTypesApi.TrialTypeData[]
  ) {
    if (userData.email !== gateParams.user) {
      await confirmDialog(
        i18n.t('User not matched')(),
        [
          `${i18n.t('Your current user')()} ${userData.email} ${i18n.t("doesn't match the GATE user")()} ${
            gateParams.user
          }`,
          i18n.t('Please log in with the correct user')(),
        ],
        '',
        true,
        i18n.t('Log out')()
      );
      await session.logout();
      window.onbeforeunload = null;
      location.reload();
      return;
    }

    if (!session.isAtLeastEditor()) {
      await confirmDialog(
        i18n.t('At least Editor role required')(),
        [
          i18n.t(
            'Your current permissions are not enough to perform this action. Please use a different account'
          )(),
        ],
        '',
        true,
        i18n.t('Log out')()
      );
      await session.logout();
      window.onbeforeunload = null;
      location.reload();
      return;
    }

    const trialType = trialTypesData.find(
      (trialType) => getDefaultTranslation(trialType.name_json) === gateParams.category
    );
    if (!trialType) {
      await confirmDialog(
        i18n.t('Trial type does not exist')(),
        [
          i18n.t('Trial type matching the innovation category does not exist')() +
            ': ' +
            gateParams.category,
          i18n.t('Please create a missing trial type')(),
        ],
        '',
        true,
        i18n.t('Return to home page')()
      );
      window.onbeforeunload = null;
      window.location.href = '/';
      return;
    }

    const owner = usersData.find((user) => user.email === gateParams.owner);
    if (!owner) {
      await confirmDialog(
        i18n.t('Innovation Project Manager not found in Fieldtrials')(),
        [
          i18n.t(`User doesn't exist in Fieldtrials`)() + ' ' + gateParams.owner,
          i18n.t('Please create the user or choose a different Project Manager in GATE and try again')(),
        ],
        '',
        true,
        i18n.t('Return to home page')()
      );
      window.onbeforeunload = null;
      window.location.href = '/';
      return;
    }

    this.trialWizard().trial().owners.removeAll();
    const editor = usersData.find((user) => user.email === gateParams.editor);
    const viewers = usersData.filter((user) => gateParams.viewers.includes(user.email));
    let usersToAdd = new Set<usersApi.UserData>([owner, ...viewers]);
    if (editor) {
      usersToAdd.add(editor);
    }
    for (const user of Array.from(usersToAdd)) {
      this.trialWizard().trial().owners.push(user);
    }

    this.trialWizard().trial().trialType(trialType);
    this.trialWizard().trial().gateInnovationId(Number(gateParams.innovation_id));
    this.trialWizard().trial().gateInnovationName(gateParams.innovation_name);
  }

  uploading = ko.pureComputed(() => {
    return this.trialWizard() && this.trialWizard().uploading();
  });

  allowEditAnyLimited = ko.pureComputed(() => {
    if (!this.trialWizard()) {
      return true;
    }
    return canEditTrialLimited(this.userData, this.trialWizard().trial());
  });

  allowSave = ko.pureComputed(() => {
    return this.allowEditAnyLimited();
  });

  private initWizardSteps() {
    const steps = [
      {
        title: i18n.t('Basic information'),
        componentName: 'trial-basic-data',
      },
      {
        title:
            (SERVER_INFO.USE_FACTORS_NAMING || session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial()))
            ? i18n.t('Treatment factors')
            : i18n.t('Test subject'),
        componentName: 'trial-test-subject',
      },
      ...(session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
        ? [
            {
              title: i18n.t('Treatments'),
              componentName: 'trial-treatments',
            },
          ]
        : []),
      {
        title: i18n.t('Sites'),
        componentName: 'trial-sites',
      },
      {
        title: i18n.t('Experimental design'),
        componentName: 'trial-experimental-design',
      },
      {
        title: i18n.t('Layout'),
        componentName: 'trial-layout',
      },
      {
        title: i18n.t('Traits'),
        componentName: 'trial-assessments',
      },
      {
        title: i18n.t('Visits'),
        componentName: 'trial-visits',
      },
      {
        title: i18n.t('Activation'),
        componentName: 'trial-activation',
      },
    ];

    this.wizardSteps(steps);

    return steps;
  }

  private setupCopyData(
    trialWizardData: trialsApi.TrialWizardData,
    template: boolean,
    resetTemplate: boolean,
    fromBaseTemplate: boolean
  ) {
    trialWizardData.trial.created_from_template_id =
      template || fromBaseTemplate ? null : trialWizardData.trial.id;
    trialWizardData.copy_dashboard_from_id = trialWizardData.trial.id;
    trialWizardData.trial.id = null;
    if (!trialWizardData.trial.protocol && !fromBaseTemplate) {
      trialWizardData.trial.protocol = translate(trialWizardData.trial.name_json);
    }
    if (APP_CONFIG.AUTO_NAME_TRIALS) {
      trialWizardData.trial.name_json = asI18nText('');
    } else {
      trialWizardData.trial.name_json = fromBaseTemplate
        ? asI18nText('')
        : makeTrialCopyName(trialWizardData.trial);
    }
    trialWizardData.trial.name_slug = ''; // this will force to regenerate
    trialWizardData.trial.state = TrialState.Draft;
    trialWizardData.trial.template = template;
    trialWizardData.trial.owners = [];
    for (let ddm of trialWizardData.test_subjects) {
      ddm.id = null;
      if (resetTemplate) {
        ddm.limit_to = [];
      }
    }
    for( let treatment of trialWizardData.treatments) {
      treatment.id = null;
    }

    if (resetTemplate) {
      trialWizardData.trial.scheduled_planting_date = null;
      trialWizardData.treatments = [];
      trialWizardData.sites = [];
      trialWizardData.custom_plot_numbers = false;
      trialWizardData.custom_plot_position = false;
      trialWizardData.plot_guides = null;
      trialWizardData.plots = null;
      trialWizardData.trial.randomization_seed = Trial.makeRandomizationSeed();
    } else {
      if (trialWizardData.plot_guides) {
        trialWizardData.plot_guides.plot_guides.id = trialWizardData.plot_guides.plot_guides.id.map(
          () => null
        );
      }
      // don't change randomization seed, so plots will stay the same
      trialWizardData.plots.plots.id = trialWizardData.plots.plots.id.map(() => null);
    }
    for (let dataset of trialWizardData.datasets) {
      dataset.copied_from_id = dataset.id;
      dataset.id = null;

      for (let ddm of dataset.measurement_dataset_dimension_metas) {
        ddm.id = null;
      }
      for (let mm of dataset.measurement_metas) {
        mm.copied_from_id = mm.id;
        unlinkMM(mm, template);
      }
      for (let ddm of dataset.required_dataset_dimension_metas) {
        ddm.id = null;
      }
    }
    for (let scheduledVisit of trialWizardData.scheduled_visits) {
      scheduledVisit.id = null;
      for (let obs of scheduledVisit.observations) {
        obs.id = null;
      }
    }

    // we can't refer to non-library traits & svs, so drop the ones that don't have a library reference
    trialWizardData.traits = trialWizardData.traits.filter((t) => !!t.mm_template_id);
    trialWizardData.scheduled_visits_days = trialWizardData.scheduled_visits_days.filter(
      (d) => !!d.sv_template_id
    );
    // now make the ids refer to the library, so new traits & svs will be created
    for (let trait of trialWizardData.traits) {
      trait.mm_id = trait.mm_template_id;
    }
    for (let sv of trialWizardData.scheduled_visits_days) {
      sv.sv_id = sv.sv_template_id;
    }
    if (trialWizardData.trait_actions) {
      // Also make Trait Action ids refer to library
      trialWizardData.trait_actions = trialWizardData.trait_actions.map((action) => {
        action.source_measurement_meta_id = action.source_measurement_meta_template_id;
        action.target_measurement_meta_id = action.target_measurement_meta_template_id;

        if (action.target_measurement_value_template_id) {
          action.target_measurement_value = action.target_measurement_value_template_id;
        }

        // Remove id so that the new instance will be created on the server
        action.id = undefined;
        return action;
      });
    }
  }

  dispose() {
    if (this.trialWizard()) {
      this.trialWizard().dispose();
    }

    clearConfirmNavigation();
  }

  close() {
    // no-op
  }

  hasUnsavedChanges = (): boolean => {
    if (!this.trialWizard()) {
      return false;
    }

    if (!this.trialWizard().trial().id()) {
      return true;
    }

    if (this.uploading()) {
      return true;
    }

    return !deepEquals(this.lastSavedData, this.trialWizard().toData());
  };

  tppTraitsCheckDialog = async () => {
    if (this.trialWizard().needToConfirmMissingTppTraits) {
      const selectedTraitSlugs = new Set(
        this.trialWizard()
          .traits.selectedTraits()
          .map((trait) => trait.nameSlug)
      );

      let missingTppTraits: string[] = [];
      for (const [slug, name] of Array.from(this.trialWizard().tppTraitNameBySlug.entries())) {
        if (!selectedTraitSlugs.has(slug)) {
          missingTppTraits.push(name);
        }
      }

      if (missingTppTraits.length > 0) {
        const title = i18n.t('Warning')();
        const message = [
          i18n.t('You do not have all required traits of the attached TPP in your trial:')(),
          ...missingTppTraits.map((name) => `- ${name}`),
        ];
        await confirmDialog(title, message);
        this.trialWizard().needToConfirmMissingTppTraits = false;
      }
    }
  };

  save = async () => {
    if (this.uploading()) {
      return;
    }

    await this.tppTraitsCheckDialog();

    // we lose the scroll when the wizard is reloaded (due to asynchronously loaded form components),
    // so we keep track of it and set it back
    let x = window.scrollX;
    let y = window.scrollY;
    let onScroll = () => {
      x = window.scrollX;
      y = window.scrollY;
    };
    $(window).on('scroll', onScroll);

    this.saveRequest()
      .then(() => {
        $(window).off('scroll', onScroll);
        window.scrollTo(x, y);
      })
      .catch(() => {
        $(window).off('scroll', onScroll);
        window.scrollTo(x, y);

        for (let controller of this.wizardControllers) {
          controller.screen.showErrors();
        }

        this.errorsNotice(i18n.t('Some outstanding errors prevent the trial from being saved.')());
        setTimeout(() => {
          this.errorsNotice('');
        }, 3000);
      });
  };

  onStepNavigate = async (nextStep: number) => {
    await this.tppTraitsCheckDialog();

    try {
      await this.saveRequest();
      if (nextStep < this.wizardSteps().length) {
        this.setWizardStep(nextStep);
      } else {
        // finish wizard
        super.close();
      }
    } catch (e) {
      for (let controller of this.wizardControllers) {
        controller.screen.showErrors();
      }

      let firstStepWithError = -1;
      for (let i = 0; i < this.wizardControllers.length; i++) {
        if (this.wizardControllers[i].screen.hasErrors()) {
          firstStepWithError = i;
          break;
        }
      }

      if (firstStepWithError > this.wizardCurrentStep()) {
        // allow to proceed without saving
        this.errorsNotice(i18n.t('Some outstanding errors prevent the trial from being saved.')());
        this.setWizardStep(nextStep);
      } else {
        this.errorsNotice(i18n.t('Please correct all outstanding errors to continue.')());
      }

      setTimeout(() => {
        this.errorsNotice('');
      }, 3000);
    }
  };

  next = async () => {
    if (this.trialWizard().uploading()) {
      return;
    }

    let step = this.wizardCurrentStep();
    let nextStep = step + 1;

    if (nextStep > this.wizardSteps().length) {
      return;
    }

    await this.onStepNavigate(nextStep);
  };

  previous = () => {
    if (this.wizardCurrentStep() <= 0) {
      super.close();
    }

    this.setWizardStep(this.wizardCurrentStep() - 1);
  };

  cancel = () => {
    page(session.toTenantPath(this.template() ? '/trial_templates/' : '/trials/'));
  };

  openActionsMenu = () => {
    this.isActionsMenuOpen(true);
  };

  closeActionsMenu = () => {
    this.isActionsMenuOpen(false);
  };

  onWizardStepSelected = async (step: WizardStep) => {
    if (this.saving()) {
      return;
    }

    let stepIndex = this.wizardSteps().indexOf(step);
    if (this.isStepDisabled(stepIndex)) {
      return;
    }

    await this.onStepNavigate(stepIndex);
    this.setWizardStep(stepIndex);
  };

  private setWizardStep = (step: number) => {
    if (step < 0 || step >= this.wizardSteps().length || step === this.wizardCurrentStep()) {
      return;
    }

    if (step <= this.lastEnabledStep()) {
      this.wizardControllers[step].screen.showErrors();
    }

    this.lastEnabledStep(Math.max(this.lastEnabledStep(), step));
    this.wizardCurrentStep(step);
    this.wizardControllers[step].screen.reload();
  };

  isStepDisabled = (step: number) => {
    return step > this.lastEnabledStep();
  };

  stepHasErrors = (step: number) => {
    if (
      step <= this.lastEnabledStep() &&
      step !== this.wizardCurrentStep() &&
      step < this.wizardControllers.length &&
      this.wizardControllers[step].ready()
    ) {
      return this.wizardControllers[step].screen.hasErrors();
    } else {
      return false;
    }
  };

  private async saveRequest(): Promise<void> {
    if (this.trialWizard().traits.needsLoadLibrarySV) {
      await this.trialWizard().loadLibrarySV();
    }

    let hasLocalErrors = false;

    for (let i = 0; i <= this.lastEnabledStep(); i++) {
      const screen = this.wizardControllers[i].screen;

      screen.clearServerErrors();

      // Wait until the screen is ready
      while(!screen.ready()) {
        await sleep(100);
      }
      hasLocalErrors = !screen.applyLocalValidation() || hasLocalErrors;
    }

    if (hasLocalErrors) {
      return Promise.reject(null);
    }

    if (this.validateLocal(this.trialWizard().getValidationTarget())) {
      let data = this.trialWizard().toData();

      if (this.trialWizard().trial().id() && deepEquals(data, this.lastSavedData)) {
        // nothing to save
        this.saving(false);
        return Promise.resolve(null);
      }

      let plotRequest = Promise.resolve(null);
      let plotsGenerated = true;
      let forceRegen = this.trialWizard().forceRegeneratePlots();
      if (forceRegen !== 'no') {
        plotRequest = this.trialWizard()
          .generatePlotsRequest({
            fullReset: false,
            useCustom: forceRegen !== 'full',
          })
          .then((res) => {
            // NOTE: forceRegeneratePlots will be set to 'no' when the trial is
            // re-loaded after saving it
            if (res.isValid) {
              this.trialWizard().customPlotNumbers(res.custom_plot_numbers);
              this.trialWizard().customPlotPosition(res.custom_plot_position);
              this.trialWizard().setPlots(res.plots, true);

              data = this.trialWizard().toData();
            } else {
              plotsGenerated = false;
            }
          });
      }

      let saveRequest = plotRequest.then(() => {
        if (plotsGenerated) {
          return trialsApi.saveWizard(data);
        } else {
          return { isValid: false, entityId: null, errors: {} };
        }
      });

      return this.executeSaveRequest(saveRequest)
        .then((validation) => {
          return loggingErrors(() => {
            this.onRemoteValidation(data, this.trialWizard(), validation);

            if (!plotsGenerated) {
              this.errorsNotice(getTooManyPlotsText(this.trialWizard().trial())());
              setTimeout(() => this.errorsNotice(''), 5000);
              return;
            }

            if (validation.isValid) {
              this.saving(true); // pretend we're still saving

              this.trialWizard().isDirty(false);
              this.lastSavedData.is_dirty = false;
              if (validation.originalResponse && validation.originalResponse.plot_count_per_site) {
                warnIfPlotCountExceeded(validation.originalResponse.plot_count_per_site);
              }

              return trialsApi
                .retrieveWizard(validation.entityId)
                .then((data) => {
                  loggingErrors(() => {
                    this.trialWizard(
                      new TrialWizard(
                        this.trialWizard().siteDM,
                        this.trialWizard().replicationDM,
                        this.userData,
                        false,
                        this.trialWizard().editMode,
                        data
                      )
                    );
                    this.saving(false);

                    this.lastSavedData = this.trialWizard().toData();

                    this.forEachEnabledScreen((screen) => {
                      screen.didSave();
                    });
                  });
                })
                .catch(() => {
                  this.saving(false);
                });
            } else {
              this.forEachEnabledScreen((screen) => {
                screen.applyServerErrors(validation.errors);
              });

              return Promise.reject(null);
            }
          });
        })
        .then(() => Promise.resolve<void>(null)); // make type system happy
    } else {
      return Promise.reject(null);
    }
  }

  private forEachEnabledScreen(callback: (screen: BaseTrialStep) => void) {
    for (let i = 0; i <= this.lastEnabledStep(); i++) {
      callback(this.wizardControllers[i].screen);
    }
  }
}

export let trialWizard = {
  name: 'trial-wizard',
  viewModel: TrialWizardScreen,
  template: template,
};

ko.components.register(trialWizard.name, trialWizard);
