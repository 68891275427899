import * as ko from 'knockout';
import page from 'page';

import * as trialDocumentsApi from '../api/trial_documents';
import { createWithComponent } from '../utils/ko_utils';
import { FileUploadDelegate } from '../components/basic_widgets';
import { CloudStorageUpload, CloudStorageUploadDelegate } from '../cloud_storage_upload';
import { DocumentCategoryData } from '../api/document_categories';
import { getDocumentCategorySearchConfig } from '../components/configs/search_configs';
import { clearModelServerErrors, applyModelServerErrors } from './base_form';
import { setGlobalError } from './base_edit';
import { session } from '../session';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/trial_document_add_archive.html').default;

const SIZE_LIMIT = 200 * 1024 * 1024; // 200MB

class TrialDocumentAddArchiveScreen implements CloudStorageUploadDelegate {
  private trialId: string;

  saving = ko.observable(false);
  globalError = ko.observable('');

  archiveFileName = ko.observable('').extend({ required: true });
  categories = ko.observableArray<DocumentCategoryData>();
  categoriesSearch = getDocumentCategorySearchConfig(this.categories);

  canReuseEndpoint = true;
  getUploadEndpoint = trialDocumentsApi.getUploadEndpoint;

  onFileUploaded(userFileName: string, fileName: string, fileUrl: string, contentType: string): void {
    this.archiveFileName(fileName);
  }

  private cloudUpload = new CloudStorageUpload(this);
  uploadDelegate: FileUploadDelegate = {
    fileUploadError: this.cloudUpload.fileUploadError,
    onUploadStarted: (file: File) => {
      this.archiveFileName('');
      this.archiveFileName.isModified(false);
      this.globalError('');

      if (file.size > SIZE_LIMIT) {
        this.globalError(i18n.t('Maximum archive size (200 MB) exceeded')());
        return false;
      }

      return true;
    },
    onFileContents: (
      userFileName: string,
      fileContents: ArrayBuffer,
      contentType: string,
      prepareXHR: () => XMLHttpRequest
    ) => {
      return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
    },
  };

  constructor(params: { trialId: string }) {
    this.trialId = params.trialId;
  }

  save = async () => {
    this.globalError('');
    clearModelServerErrors(this);

    const validation = ko.validation.group([this.archiveFileName, this.categories]);
    if (validation().length > 0) {
      validation.showAllMessages();
      return;
    }

    this.saving(true);
    try {
      const res = await trialDocumentsApi.addArchive(this.trialId, {
        archive_file_name: this.archiveFileName(),
        categories: this.categories(),
      });
      if (res.isValid) {
        this.cancel();
      } else {
        applyModelServerErrors(this, res.errors);
        setGlobalError(this.globalError, res);
      }
    } finally {
      this.saving(false);
    }
  };

  cancel = () => {
    page(session.toTenantPath(`/trials/${this.trialId}/documents/`));
  };
}

export let trialDocumentAddArchive = {
  name: 'trial-document-add-archive',
  viewModel: createWithComponent(TrialDocumentAddArchiveScreen),
  template: template,
};

ko.components.register(trialDocumentAddArchive.name, trialDocumentAddArchive);
