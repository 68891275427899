import * as ko from 'knockout';
import i18n from '../i18n';

import { AttributeMetaData } from '../api/dimension_metas';
import * as dimensionsApi from '../api/dimensions';
import { parseDate, parseDateTime, serializeDate, serializeDateTime } from '../api/serialization';
import { ValueMeta } from './value_meta';
import { FLOAT_VALIDATION_RULES, tryFormatDate, tryFormatDateTime } from '../utils';
import { ListFilter } from '../components/list_loader';
import { FilterDelegate } from '../components/list_filters';
import { I18nText, translate } from '../i18n_text';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { MeasurementTypeData, MeasurementChoiceData } from '../api/measurement_types';
import { AttributeChoiceData, AttributeChoiceListData } from '../api/attribute_choice_lists';
import { FormLocationMapPoint } from '../components/basic_widgets';
import { Point } from '../ko_bindings/map';

export const defaultRateLimit: ko.RateLimitOptions = {
  timeout: 250,
  method: 'notifyWhenChangesStop',
};

export enum ChangeReason {
  WEATHER = 'WEATHER',
  ANIMAL = 'ANIMAL',
  DONT_KNOW = 'DONT_KNOW',
  MISTAKE = 'MISTAKE',
  OTHER = 'OTHER',
}

export const factChangeReasons = [
  { value: '', title: i18n.t('Select')() },
  { value: ChangeReason.WEATHER, title: i18n.t('Weather damage')() },
  { value: ChangeReason.ANIMAL, title: i18n.t('Animal damage')() },
  { value: ChangeReason.DONT_KNOW, title: i18n.t("Don't know")() },
  { value: ChangeReason.MISTAKE, title: i18n.t('Mistake')() },
  { value: ChangeReason.OTHER, title: i18n.t('Other')() },
];
export class DynamicAttribute<T = {}> {
  id: string;
  nameJson: KnockoutObservable<I18nText>;
  type: string;
  changeReason = ko.observable('');

  value = ko.observable<T>(null).extend({
    validatable: true,
    serverError: true,
  });

  constructor(valueMeta: ValueMeta, validationRules: {}, changeReason: string = '') {
    this.id = valueMeta.id();
    this.nameJson = valueMeta.nameJson;
    this.type = valueMeta.type();
    this.value = this.value.extend(validationRules);
    this.changeReason(changeReason);
  }

  getAttrName(): string {
    return 'attr_' + this.id;
  }

  getMesName(): string {
    return 'mes_' + this.id;
  }

  serialize() {
    const value = this.value();
    if (typeof value !== 'number' && !this.value()) {
      return null;
    } else {
      return value;
    }
  }

  deserialize(value: T) {
    this.value(value);
  }

  /** Should return primitive type of value.
   * int, string, list of int or strings.
   * no objects please
   */
  flatValue(): string {
    return this.value()?.toString();
  }

  format() {
    return this.value() ? this.value().toLocaleString() : '';
  }

  asFilter(): ListFilter {
    return null;
  }

  asFilterDelegate(): FilterDelegate {
    return null;
  }
}

class IntegerDynamicAttribute extends DynamicAttribute {
  constructor(valueMeta: ValueMeta, validationRules: {}) {
    super(valueMeta, ko.utils.extend({ digit: true }, validationRules));
  }

  asFilter(): ListFilter {
    return {
      name: translate(this.nameJson()),
      slug: this.getAttrName(),
      type: 'numeric',
      minValue: ko.observable<string>().extend({ digit: true, rateLimit: defaultRateLimit }),
      maxValue: ko.observable<string>().extend({ digit: true, rateLimit: defaultRateLimit }),
    };
  }

  asFilterDelegate(): FilterDelegate {
    const minValue = ko.observable<string>(null);
    const maxValue = ko.observable<string>(null);

    return {
      title: translate(this.nameJson()),
      minValue: minValue.extend({
        digit: true,
      }),
      maxValue: maxValue.extend({
        digit: true,
        validation: {
          validator: (value) => {
            const areNumbers = !isNaN(Number(minValue())) && !isNaN(Number(value));
            return !areNumbers || Number(minValue()) <= Number(value);
          },
          message: i18n.t('Please ensure the minimum value does not exceed the maximum.')(),
        },
      }),
    };
  }
}

class DecimalDynamicAttribute extends DynamicAttribute {
  constructor(valueMeta: ValueMeta, validationRules: {}) {
    super(valueMeta, ko.utils.extend(FLOAT_VALIDATION_RULES, validationRules));
  }

  format() {
    let number = parseFloat(<string>this.value());
    if (isNaN(number)) {
      return '';
    }

    return number.toLocaleString();
  }

  asFilter(): ListFilter {
    return {
      name: translate(this.nameJson()),
      slug: this.getAttrName(),
      type: 'numeric',
      minValue: ko.observable<string>().extend({ number: true, rateLimit: defaultRateLimit }),
      maxValue: ko.observable<string>().extend({ number: true, rateLimit: defaultRateLimit }),
    };
  }

  asFilterDelegate(): FilterDelegate {
    const minValue = ko.observable<string>(null);
    const maxValue = ko.observable<string>(null);

    return {
      title: translate(this.nameJson()),
      minValue: minValue.extend({ number: true }),
      maxValue: maxValue.extend({
        number: true,
        validation: {
          validator: (value) => {
            const areNumbers = !isNaN(Number(minValue())) && !isNaN(Number(value));
            return !areNumbers || Number(minValue()) <= Number(value);
          },
          message: i18n.t('Please ensure the minimum value does not exceed the maximum.')(),
        },
      }),
    };
  }
}

class StringDynamicAttribute extends DynamicAttribute {
  constructor(valueMeta: ValueMeta, validationRules: {}) {
    super(valueMeta, validationRules);
  }

  serialize() {
    return this.value();
  }

  asFilter(): ListFilter {
    return {
      name: translate(this.nameJson()),
      slug: this.getAttrName(),
      type: 'text',
      value: ko.observable('').extend({ rateLimit: defaultRateLimit }),
    };
  }

  asFilterDelegate(): FilterDelegate {
    const filter = this.asFilter();
    return {
      title: filter.name,
      textValue: filter.value as ko.Observable<string>,
    };
  }
}

class DateDynamicAttribute extends DynamicAttribute {
  serialize() {
    return serializeDate(<Date>this.value());
  }

  deserialize(value: {}) {
    this.value(parseDate(<string>value));
  }

  format() {
    return tryFormatDate(<string | Date>this.value());
  }

  asFilter(): ListFilter {
    return {
      name: translate(this.nameJson()),
      slug: this.getAttrName(),
      type: 'date',
      minValue: ko.observable<Date>(null),
      maxValue: ko.observable<Date>(null),
    };
  }

  asFilterDelegate(): FilterDelegate {
    const minValue = ko.observable<Date>(null);
    const maxValue = ko.observable<Date>(null);

    return {
      title: translate(this.nameJson()),
      minValue: minValue.extend({
        //@ts-ignore
        date: true,
      }),
      maxValue: maxValue.extend({
        //@ts-ignore
        date: true,
        validation: {
          validator: (value) => !minValue() || value >= minValue(),
          message: i18n.t('Please ensure the start date is before the end date.')(),
        },
      }),
    };
  }
}

class TimestampDynamicAttribute extends DynamicAttribute {
  serialize() {
    return serializeDateTime(<Date>this.value());
  }

  deserialize(value: {}) {
    this.value(parseDateTime(<string>value));
  }

  format() {
    return tryFormatDateTime(<string | Date>this.value());
  }

  asFilter(): ListFilter {
    return {
      name: translate(this.nameJson()),
      slug: this.getAttrName(),
      type: 'date',
      minValue: ko.observable<Date>(null),
      maxValue: ko.observable<Date>(null),
    };
  }
}

class LocationDynamicAttribute extends DynamicAttribute {
  point = ko.observable<Point>(null);
  value = FormLocationMapPoint.posObservable().extend({
    validatable: true,
    serverError: true,
  });

  deserialize(value: string | [number, number]) {
    if (Array.isArray(value)) {
      value = value.join(', ');
    }

    this.value(value || '');
    this.point(FormLocationMapPoint.asPoint(this.value));
  }
}

abstract class BaseChoiceDynamicAttribute<T1, T2 extends { choices?: T1[] }> extends DynamicAttribute {
  options: { name: string; value: string }[] = [];

  constructor(valueMeta: ValueMeta, validationRules: {}) {
    super(valueMeta, validationRules);

    this.options = this.get(valueMeta).choices.map((choice) => {
      return this.toOption(valueMeta, choice);
    });
    this.options.splice(0, 0, { name: i18n.t('Select')(), value: '' });
  }

  format() {
    for (let opt of this.options) {
      if (opt.value === this.value()) {
        return opt.name;
      }
    }

    return '';
  }

  deserialize(value: string | number) {
    super.deserialize(value?.toString());
  }

  abstract get(valueMeta: ValueMeta): T2;
  abstract toOption(valueMeta: ValueMeta, choice: T1): { name: string; value: string };
}

class ChoiceDynamicAttribute extends BaseChoiceDynamicAttribute<MeasurementChoiceData, MeasurementTypeData> {
  get(valueMeta: ValueMeta) {
    return valueMeta.measurementType();
  }

  toOption(valueMeta: ValueMeta, choice: MeasurementChoiceData) {
    let name = translate(choice.name_json);
    if (valueMeta.measurementType().rating) {
      name += ' (' + choice.value + ')';
    }
    return { name, value: choice.id };
  }
}

class AttrChoiceDynamicAttribute extends BaseChoiceDynamicAttribute<
  AttributeChoiceData,
  AttributeChoiceListData
> {
  constructor(private valueMeta: ValueMeta, validationRules: {}) {
    super(valueMeta, validationRules);
  }

  get(valueMeta: ValueMeta) {
    return valueMeta.choiceList();
  }

  toOption(valueMeta: ValueMeta, choice: AttributeChoiceData) {
    return { name: translate(choice.name_json), value: choice.id };
  }

  asFilter(): ListFilter {
    return {
      name: translate(this.nameJson()),
      slug: this.getAttrName(),
      type: 'choices',
      value: ko.observable(''),
      choices: [{ name: i18n.t('Select')(), value: '' }].concat(
        this.valueMeta.choiceList().choices.map((choice) => ({
          name: translate(choice.name_json),
          value: choice.id,
        }))
      ),
    };
  }

  asFilterDelegate(): FilterDelegate {
    const defaultChoice = { id: '', name: i18n.t('All')(), selected_by_default: true };
    return {
      title: translate(this.nameJson()),
      choices: [defaultChoice].concat(
        this.valueMeta.choiceList().choices.map((choice) => ({
          id: choice.id,
          name: translate(choice.name_json),
          name_json: choice.name_json,
          selected_by_default: false,
        }))
      ),
      value: ko.observable<{
        id: string;
        name: string;
        selected_by_default: boolean;
      }>(defaultChoice),
    };
  }
}

class EntityDynamicAttribute extends DynamicAttribute<dimensionsApi.DimensionData> {
  searchConfig: FormSelectSearchConfiguration<dimensionsApi.DimensionData> = {
    getSummaryName: (entity) => {
      return entity.name_json;
    },

    list: (params) => {
      return dimensionsApi.list(
        this.valueMeta.entityDimensionMeta().id,
        { measurement_meta_id: this.valueMeta.id() },
        params
      );
    },

    entity: this.value,
  };

  flatValue(): string {
    return this.value()?.id;
  }

  constructor(private valueMeta: ValueMeta, validationRules: {}) {
    super(valueMeta, validationRules);
  }
}

let ATTRIBUTE_TYPES = [
  { name: i18n.t('Text'), value: 'string' },
  { name: i18n.t('Choice'), value: 'attr_choice_list' },
  { name: i18n.t('Integer Number'), value: 'integer' },
  { name: i18n.t('Number'), value: 'decimal' },
  { name: i18n.t('Date'), value: 'date' },
  { name: i18n.t('Date and Time'), value: 'timestamp' },
  { name: i18n.t('GPS Point'), value: 'gps' },
];

function getAttributeTypes(allow: { integer: boolean }) {
  let types = ATTRIBUTE_TYPES;
  if (!allow.integer) {
    types = types.filter((type) => type.value !== 'integer');
  }

  return types;
}

let FACTORIES = [
  { value: 'string', factory: StringDynamicAttribute },
  { value: 'string_long', factory: StringDynamicAttribute },
  { value: 'attr_choice_list', factory: AttrChoiceDynamicAttribute },
  { value: 'integer', factory: IntegerDynamicAttribute },
  { value: 'decimal', factory: DecimalDynamicAttribute },
  { value: 'date', factory: DateDynamicAttribute },
  { value: 'timestamp', factory: TimestampDynamicAttribute },
  { value: 'gps', factory: LocationDynamicAttribute },
  { value: 'choice', factory: ChoiceDynamicAttribute },
  { value: 'entity', factory: EntityDynamicAttribute },
  { value: 'barcode', factory: StringDynamicAttribute },
];

export function makeDynamicAttribute<T extends ValueMeta>(
  valueMeta: T,
  validationRules: {}
): DynamicAttribute {
  for (let attributeType of FACTORIES) {
    if (attributeType.value == valueMeta.type()) {
      return new attributeType.factory(valueMeta, validationRules);
    }
  }

  return null;
}

export class AttributeMeta extends ValueMeta {
  constructor(data?: AttributeMetaData) {
    super(
      {
        types: getAttributeTypes({ integer: data && data.type === 'integer' }),
        allowRequired: true,
        selectUnitForTrial: null,
        hasHelpText: false,
        allowEditNameSlug: !data || !data.id,
        useCropPrefix: false,
        requireMeasurementType: false,
      },
      data
    );

    if (data) {
      this.choiceList(data.choice_list);
    }
  }

  toData(): AttributeMetaData {
    let data: AttributeMetaData = <AttributeMetaData>super.toData();

    if (this.needsChoiceList()) {
      data.choice_list = this.choiceList();
    } else {
      data.choice_list = null;
    }

    return data;
  }
}
