import { PlotsPreviewModel } from './plots_preview_model';
import { plotColorClasses } from '../plots_edit/plot_view';

let template = require('raw-loader!./plots_preview.html').default;

const colorClasses = plotColorClasses.map((x) => x.split(' '));

export class PreviewView {
  readonly root = loadTemplate();

  private columnLabels = this.root.querySelector('.plots-preview-column-labels') as HTMLElement;
  private rowLabels = this.root.querySelector('.plots-preview-row-labels') as HTMLElement;
  private plotsContainer = this.root.querySelector('.plots-preview-plots') as HTMLElement;

  update(model: PlotsPreviewModel) {
    this.fillLabels(this.rowLabels, model.nRows);
    this.fillLabels(this.columnLabels, model.nCols);

    this.plotsContainer.innerHTML = '';
    for (let i = 0; i < model.nRows; i++) {
      let row = document.createElement('div');
      row.className = 'plots-preview-row';

      for (let j = 0; j < model.nCols; j++) {
        let node = document.createElement('div');

        if (model.hasBorderTop(i, j)) {
          node.classList.add('plots-top');
        }
        if (model.hasBorderBottom(i, j)) {
          node.classList.add('plots-bottom');
        }
        if (model.hasBorderLeft(i, j)) {
          node.classList.add('plots-left');
        }
        if (model.hasBorderRight(i, j)) {
          node.classList.add('plots-right');
        }
        if (model.exists(i, j)) {
          let colors = colorClasses[model.comboId(i, j) % colorClasses.length];
          for (let className of colors) {
            node.classList.add(className);
          }
        } else {
          node.classList.add('plots-preview-empty');
        }

        row.appendChild(node);
      }

      this.plotsContainer.appendChild(row);
    }
  }

  private fillLabels(container: HTMLElement, upTo: number) {
    container.innerHTML = '';

    for (let i = 1; i <= upTo; i++) {
      let node = document.createElement('div');
      node.textContent = i.toString();
      container.appendChild(node);
    }
  }
}

let domTemplate: HTMLElement = null;
function loadTemplate() {
  if (!domTemplate) {
    domTemplate = document.createElement('div');
    domTemplate.innerHTML = template;
  }

  return domTemplate;
}
