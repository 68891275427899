import * as ko from 'knockout';

import { TreatmentData, TreatmentFactorDimensionData, TreatmentFactorData } from '../api/trials';
import { I18nText } from '../i18n_text';

export class TreatmentFactorDimension {
  id = ko.observable<string>();
  name = ko.observable<string>();
  dimension_meta_id = ko.observable<number>();

  constructor(data: TreatmentFactorDimensionData) {
    if (data) {
      this.id(data.id);
      this.name(data.name);
      this.dimension_meta_id(data.dimension_meta_id);
    }
  }

  toData(): TreatmentFactorDimensionData {
    return {
      id: this.id(),
      name: this.name(),
      dimension_meta_id: this.dimension_meta_id(),
    };
  }
}

export class TreatmentFactor {
  id = ko.observable<number>();
  dimension = ko.observable<TreatmentFactorDimension>();

  constructor(data: TreatmentFactorData) {
    if (data) {
      this.id(data.id);
      this.dimension(new TreatmentFactorDimension(data.dimension));
    }
  }

  toData(): TreatmentFactorData {
    return {
      id: this.id(),
      dimension: this.dimension().toData(),
    };
  }
}

export class Treatment {
  id = ko.observable<number>();
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  disabled = ko.observable<boolean>();
  control = ko.observable<boolean>();
  order = ko.observable<number>();
  factors = ko.observableArray<TreatmentFactor>();

  constructor(data?: TreatmentData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.disabled(data.disabled);
      this.control(data.control);
      this.order(data.order);
      this.factors(data.factors.map((factor) => new TreatmentFactor(factor)));
    }
  }

  toData(): TreatmentData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      disabled: this.disabled(),
      control: this.control(),
      order: this.order(),
      factors: this.factors().map((factor) => factor.toData()),
    };
  }
}
