import * as ko from 'knockout';

import { TPP } from '../models/tpp';

let template = require('raw-loader!../../templates/components/driver_analysis_legend.html').default;

class DriverAnalysisLegend {
  tpp: KnockoutObservable<TPP>;

  constructor(params: { tpp: KnockoutObservable<TPP> }) {
    this.tpp = params.tpp;
  }
}

ko.components.register('driver-analysis-legend', {
  viewModel: DriverAnalysisLegend,
  template: template,
});
