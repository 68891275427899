import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { TPP, TPPTrait } from '../models/tpp';
import * as tppsApi from '../api/tpps';
import * as usersApi from '../api/users';
import { GeoJSON } from '../api/datasets';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';
import { siteLocations } from '../api/trials';
import { getTrialLocationsMap } from './trials';
import i18n from '../i18n';
import { canEditTPP } from '../permissions';

let template = require('raw-loader!../../templates/tpp_edit.html').default;

type TabName = 'cover_input' | 'profile_input' | 'driver_analysis' | 'trial_sites';

class TPPEditScreen extends BaseForm<tppsApi.TPPData> {
  scaleOptions: { name: string; value: string }[] = [
    { name: 'N/A', value: '' },
    { name: i18n.t('1 (lowest)')(), value: '1' },
    { name: '2', value: '2' },
    { name: '3', value: '3' },
    { name: '4', value: '4' },
    { name: '5', value: '5' },
    { name: '6', value: '6' },
    { name: '7', value: '7' },
    { name: '8', value: '8' },
    { name: '9', value: '9' },
    { name: i18n.t('10 (highest)')(), value: '10' },
  ];

  entity = ko.validatedObservable<TPP>(null);
  selected = ko.observable<TabName>('cover_input');
  loadingMap = ko.observable(true);
  mapPoints = ko.observableArray<GeoJSON>();

  showSaveError = ko.observable(false);
  showSaveSuccess = ko.observable(false);
  canEdit = ko.observable(canEditTPP());

  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<tppsApi.TPPData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let dataPromise = params.id ? tppsApi.retrieve(params.id) : undefined;
    let promise = Promise.all([usersApi.me(), dataPromise]).then(([user, data]) => {
      this.entity(new TPP(user, data));
      if (!data && params.initialName) {
        this.entity().nameJson(asI18nText(params.initialName));
      }
    });
    this.loadedAfter(promise);

    this.loadMap(params.id);
  }

  dispose() {
    if (this.entity()) {
      this.entity().dispose();
    }
  }

  selectCoverInput = () => this.selected('cover_input');
  selectProfileInput = () => this.selected('profile_input');
  selectDriverAnalysis = () => this.selected('driver_analysis');
  selectTrialSites = () => this.selected('trial_sites');

  private loadMap(tppId: string) {
    if (!tppId) {
      this.loadingMap(false);
      return;
    }

    siteLocations({ template: false, sort_by: '', tpp_id: tppId })
      .then((data) => {
        this.loadingMap(false);
        this.mapPoints(getTrialLocationsMap(data));
      })
      .catch((e) => {
        this.loadingMap(false);
        throw e;
      });
  }

  addTrait = () => {
    this.entity().addTrait();
  };

  removeTrait = (trait: TPPTrait) => {
    this.entity().removeTrait(trait);
  };

  private timeoutToken: number = null;

  save = () => {
    clearTimeout(this.timeoutToken);
    this.showSaveError(false);
    this.showSaveSuccess(false);
    if (this.validateLocal(this.entity) && !this.entity().traitsError()) {
      let data = this.entity().toData();
      const team = this.entity().team().slice();
      this.executeSaveRequest(tppsApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation, () => null);
        if (validation.isValid) {
          this.entity().id(validation.entityId);
          this.showSaveSuccess(true);
        } else {
          this.showSaveError(true);

          const teamErrors: any = validation.errors['team'] || [];
          for (let i = 0; i < teamErrors.length; i++) {
            this.applyModelServerErrors(team[i], teamErrors[i]);
          }
        }

        this.timeoutToken = window.setTimeout(() => {
          this.showSaveError(false);
          this.showSaveSuccess(false);
        }, 5000);
      });
    } else if (this.entity().traitsError()) {
      this.showSaveError(true);
    }
  };
}

export let tppEdit = {
  name: 'tpp-edit',
  viewModel: createWithComponent(TPPEditScreen),
  template: template,
};

ko.components.register(tppEdit.name, tppEdit);
