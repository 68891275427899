import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { app } from '../app';
import i18n from '../i18n';
import { createWithComponent } from '../utils/ko_utils';
import { Point } from '../ko_bindings/map';

let template = require('raw-loader!../../templates/components/map_point.html').default;

class MapPoint {
  placeholderText = i18n.t('Search location')();

  obs: ko.Observable<Point>;
  enable: boolean;
  result: Deferred<{} | null> | undefined;
  mapCenterLocation?: Point;

  constructor(params: {
    value: ko.Observable<Point>;
    enable: boolean;
    result?: Deferred<{} | null>;
    mapCenterLocation?: Point;
  }) {
    this.obs = params.value;
    this.enable = params.enable;
    this.result = params.result;
    this.mapCenterLocation = params.mapCenterLocation;
  }

  onClose = () => {
    this.result?.resolve(null);
  };
}

ko.components.register('map-point', {
  viewModel: createWithComponent(MapPoint),
  template: template,
});

export function selectLocationPointPopup(
  obs: ko.Observable<Point | null>,
  enable: boolean,
  isMapModalClosed?: ko.Observable<boolean>,
  mapCenterLocation?: Point
) {
  const result = new Deferred<Point | null>();
  if (isMapModalClosed !== undefined) {
    result.promise.then(() => {
      isMapModalClosed(true);
    });
  }
  app.formsStackController.push<
    Point | null,
    {
      value: ko.Observable<Point | null>;
      enable: boolean;
      result: Deferred<Point | null>;
      mapCenterLocation?: Point;
    }
  >({
    title: i18n.t('Location')(),
    name: 'map-point',
    isBig: true,
    params: {
      value: obs,
      enable: enable,
      result: result,
      mapCenterLocation: mapCenterLocation,
    },
  });
}
