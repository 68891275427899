import * as ko from 'knockout';

import i18n from '../i18n';
import * as trialsApi from '../api/trials';
import { ListLoaderDelegate } from './list_loader';
import { ListRequestParams } from '../api/request';

let template = require('raw-loader!../../templates/components/site_activity_status.html').default;

class SiteActivityStatusScreen
  implements ListLoaderDelegate<trialsApi.SiteActivityStatusData, trialsApi.SiteActivityStatusData>
{
  private trialId: string;

  noItemsText = i18n.t('No sites defined for this trial.')();

  constructor(params: { trialId: string }) {
    this.trialId = params.trialId;
  }

  fetch(params: ListRequestParams): Promise<trialsApi.SiteActivityStatusData[]> {
    return trialsApi.listSiteActivityStatus(this.trialId);
  }

  instantiate(data: trialsApi.SiteActivityStatusData) {
    return data;
  }

  remove(id: string) {
    return Promise.resolve(null);
  }

  canRemove(entity: trialsApi.SiteActivityStatusData): boolean {
    return false;
  }
}

export let siteActivityStatus = {
  name: 'site-activity-status',
  viewModel: SiteActivityStatusScreen,
  template: template,
};

ko.components.register(siteActivityStatus.name, siteActivityStatus);
