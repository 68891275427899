import * as ko from 'knockout';

import i18n from '../i18n';
import * as dmApi from '../api/dimension_metas';
import * as dimensionsApi from '../api/dimensions';
import { ImportEntitiesDelegate, ImportError } from '../components/import_entities';
import { BaseLoadingScreen } from './base_loading_screen';
import { translate } from '../i18n_text';

const template = require('raw-loader!../../templates/import_dimensions.html').default;

class ImportDimensionsScreen extends BaseLoadingScreen implements ImportEntitiesDelegate<string[]> {
  title = ko.observable('Import');
  description: string;
  backTitle: string = null;

  templateBaseName: string;
  importUrl: () => string;

  duplicateActionOptions: {
    id: dimensionsApi.ImportDimensionsDuplicatesAction;
    name: string;
  }[] = [
    { id: 'insert', name: i18n.t('Add all records')() },
    {
      id: 'update_duplicates',
      name: i18n.t('Update records with an existing name (overwrites existing data)')(),
    },
    {
      id: 'ignore_duplicates',
      name: i18n.t('Ignore records with an existing name')(),
    },
    {
      id: 'update_duplicates_code',
      name: i18n.t('Update records with an existing code (overwrites existing data)')(),
    },
    {
      id: 'ignore_duplicates_code',
      name: i18n.t('Ignore records with an existing code')(),
    },
  ];
  duplicateAction = ko.observable<dimensionsApi.ImportDimensionsDuplicatesAction>('insert');

  private dmIdOrSlug: string;
  private onImportSuccess: (ids: string[]) => void;

  constructor(params: { dmIdOrSlug: string; onSuccess?: (ids: string[]) => void }) {
    super();

    this.dmIdOrSlug = params.dmIdOrSlug;
    this.onImportSuccess = params.onSuccess;

    if (!this.onImportSuccess) {
      this.backTitle = i18n.t('Back to list')();
    }

    if (params.dmIdOrSlug === dmApi.CROP_VARIETY_SLUG) {
      this.templateBaseName = 'crop_varieties';
    } else if (params.dmIdOrSlug === dmApi.SITE_SLUG) {
      this.templateBaseName = 'sites';
    } else if (params.dmIdOrSlug === dmApi.CV_STAGE_SLUG) {
      this.templateBaseName = 'stages';
    } else if (params.dmIdOrSlug === dmApi.CROP_SLUG) {
      this.templateBaseName = 'crops';
    } else if (params.dmIdOrSlug === dmApi.TRIAL_TYPE_SLUG) {
      this.templateBaseName = 'trial_types';
    } else {
      this.templateBaseName = 'dimension-elements';
    }

    let promise = dmApi.retrieve(params.dmIdOrSlug).then((dm) => {
      let entityName = translate(dm.name_json);
      if (params.dmIdOrSlug === dmApi.CROP_VARIETY_SLUG) {
        entityName = 'crop varieties';
      } else if (params.dmIdOrSlug === dmApi.SITE_SLUG) {
        entityName = 'sites';
      } else if (params.dmIdOrSlug === dmApi.CROP_VARIETY_SLUG) {
        entityName = 'crops';
      }

      this.title(i18n.t('Import {{entityName}}', { entityName })());
      this.description = i18n.t(
        'To import {{entityName}}, download the Excel template, fill-in the desired records and then upload the file.',
        { entityName }
      )();
      this.importUrl = () =>
        `/api/dimensions/${params.dmIdOrSlug}/import_records/?duplicate_action=${this.duplicateAction()}`;
    });
    this.loadedAfter(promise);
  }

  downloadTemplate(): Promise<Blob> {
    return dimensionsApi.downloadImportTemplate(this.dmIdOrSlug);
  }

  onSuccess = async (data: string[]): Promise<ImportError[]> => {
    if (this.onImportSuccess) {
      this.onImportSuccess(data);
    }

    return [];
  };
}

export const importDimensions = {
  name: 'import-dimensions',
  viewModel: ImportDimensionsScreen,
  template,
};

ko.components.register(importDimensions.name, importDimensions);
