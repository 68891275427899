import { RemoveResult, requestRemoveEntity, request, requestAnyWithValidation } from './request';
import { MeasurementMetaData, DatasetFactSummaryData } from './datasets';
import { FileUploadEndpoint } from '../cloud_storage_upload';

export function removeObservation(id: string, params: { measurement_meta_id: string }): Promise<{}> {
  return request('POST', '/api/facts/' + id + '/remove_observation/', params);
}

export function remove(id: string): Promise<RemoveResult> {
  return requestRemoveEntity('/api/facts/' + id + '/');
}

export type EditExtraData = {
  id: string;
  file_name: string;
  file_url: string;
  mime_type: string;
  user_file_name: string;
  measurement_meta_id: number | null;
  comment: string;
}

export interface EditFactData {
  id: string;
  plot_name?: string; // read-only
  reference_dim_name?: string; // read-only
  treatment_name?: string; // read-only
  dimensions?: { dm_name: string; dim_name: string }[]; // read-only
  mms?: MeasurementMetaData[]; // read-only
  comment: string;
  extras: EditExtraData[];
  metadata_id?: string; // write-only
  unit_names?: { [mm_id: string]: string }; // read-only
  change_reasons?: { [mm_id: string]: string }[];
  [key: string]: {};
}

export function retrieve(id: string): Promise<EditFactData> {
  return request('GET', '/api/facts/' + id + '/');
}

interface UpdateFactResult {
  isValid: boolean;
  entity?: DatasetFactSummaryData;
  errors: { [field: string]: [string] };
}

export function update(data: EditFactData): Promise<UpdateFactResult> {
  return requestAnyWithValidation('PUT', '/api/facts/' + data.id + '/', data).then((val) => {
    if (val.status == 200 || val.status == 201) {
      return { isValid: true, entity: val.result, errors: {} };
    } else {
      return { isValid: false, entity: null, errors: val.result };
    }
  });
}

export function getExtraUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
  return request('POST', '/api/facts/extra_upload_url/', {
    content_type: contentType,
  });
}
