import * as ko from 'knockout';

import i18n from '../i18n';
import * as dashboardApi from '../api/dashboard';
import { ChartConfig } from '../ko_bindings/d3chart';
import { downloadBlob, downloadURI, findById } from '../utils';
import { autoChartDetailsDialog } from '../components/auto_chart_details_dialog';
import { canEditTrialReport } from '../permissions';

interface DashboardType {
  id: string;
  name: KnockoutComputed<string>;
}

// this wrapper is required to avoid auto-unwrapping by KO
// when passing the observable to a form input in the template
interface GroupBy {
  id: KnockoutObservable<string>;
}

export interface Chart {
  editURL?: string;
  canRemove: boolean;
  canEditOptions: boolean;
  name: string;
  unitName: string;
  loading: KnockoutObservable<boolean>;
  config: KnockoutObservable<ChartConfig>;
  refresh(): void;
  dispose(): void;
  setScale(scale: number): void;
  download(_: {}, evt: Event): void;
}

export class AutoChart implements Chart {
  private filterId: string;
  measurementMetaId: string;
  private dimensionMetas: dashboardApi.DashboardDDMData[];
  private groupBySubs: KnockoutSubscription[] = [];
  private subscriptions: KnockoutSubscription[] = [];

  canRemove = false;
  name: string;
  unitName: string;
  loading = ko.observable(true);
  config = ko.observable<ChartConfig>({ scale: 1, data: [] });
  dashboardTypes: DashboardType[] = [
    { id: 'box_plot', name: i18n.t('Box plot') },
    { id: 'average', name: i18n.t('Average with std. dev.') },
    { id: 'histogram', name: i18n.t('Histogram') },
  ];
  selectedChartType = ko.observable('');
  dmOptions: dashboardApi.DashboardDDMData[];
  groupBy = ko.observableArray<GroupBy>();

  constructor(
    public canEditOptions: boolean,
    private data: dashboardApi.DashboardDatasetData | dashboardApi.DashboardScheduledVisitData,
    measurementMetaData: dashboardApi.DashboardMMData,
    private dataset: dashboardApi.DashboardDatasetData,
    existing: AutoChart
  ) {
    this.filterId = data.id;
    this.measurementMetaId = measurementMetaData.id;

    if (measurementMetaData.class_only) {
      this.dashboardTypes.splice(0, 2);
    }

    this.selectedChartType(this.dashboardTypes[0].id);

    this.name = measurementMetaData.name;
    this.unitName = measurementMetaData.unit_name;
    this.dimensionMetas = dataset.dataset_dimension_metas;
    this.dmOptions = [{ dimension_meta_id: null, dimension_meta_name: i18n.t('No grouping')() }].concat(
      this.dimensionMetas
    );

    if (existing) {
      this.selectedChartType(existing.selectedChartType());
      this.groupBy(existing.groupBy());
    } else {
      if (this.dimensionMetas.length > 0) {
        this.groupBy.push({
          id: ko.observable(this.dimensionMetas[0].dimension_meta_id),
        });
      }
    }
    this.setupGroupBy();

    this.subscriptions.push(this.selectedChartType.subscribe(this.refresh));
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.disposeGroupBySubs();
  }

  private disposeGroupBySubs() {
    this.groupBySubs.forEach((s) => s.dispose());
  }

  private setupGroupBy() {
    this.disposeGroupBySubs();

    let groupBy = this.groupBy().filter((g) => !!g.id());

    this.groupBy(groupBy.slice());
    if (this.groupBy().length < this.dimensionMetas.length) {
      this.groupBy.push({ id: ko.observable(null) });
    }

    this.groupBySubs = this.groupBy().map((g) => g.id.subscribe(this.refresh));
  }

  setData(data?: dashboardApi.AutoChartData) {
    this.config({
      scale: this.config().scale,
      threshold: data?.threshold,
      data: data?.data ?? [],
      onClick: autoChartDetailsDialog,
    });
  }

  refresh = () => {
    this.setupGroupBy();

    this.loading(true);

    let params = {
      dataset_id: this.data === this.dataset ? this.filterId : null,
      scheduled_visit_id: this.data === this.dataset ? null : this.filterId,
      charts: [this.chartParams()],
    };
    dashboardApi
      .charts(params)
      .then(([chartData]) => {
        this.loading(false);
        this.setData(chartData);
      })
      .catch(() => {
        this.loading(false);
        this.setData({ threshold: null, data: [] });
      });
  };

  chartParams(): dashboardApi.ChartDetailParams {
    return {
      measurement_meta_id: this.measurementMetaId,
      chart_type: this.selectedChartType(),
      group_by: this.groupBy()
        .filter((g) => !!g.id())
        .map((g) => g.id()),
    };
  }

  setScale(scale: number) {
    this.config({
      scale: scale,
      data: this.config().data,
      onClick: autoChartDetailsDialog,
    });
  }

  download = (_: {}, evt: Event) => {
    downloadChart(evt.target as Element, this.name);
  };
}

export class CustomChart implements Chart {
  chartId: string;

  canRemove = canEditTrialReport();
  canEditOptions = false;
  editURL: string;
  name: string;
  unitName: string;
  hasSummary: boolean;
  loading = ko.observable(true);
  config = ko.observable<ChartConfig>({ scale: 1, data: [] });

  constructor(trialId: string, data: dashboardApi.CustomChartData) {
    this.chartId = data.id;
    this.name = data.name;
    this.unitName = data.unit_name;
    this.hasSummary = data.has_summary;

    this.editURL = canEditTrialReport() ? '/dashboard/' + trialId + '/chart/' + data.id + '/' : null;

    this.refresh();
  }

  dispose() {}

  refresh() {
    this.loading(true);
    dashboardApi
      .customChart(this.chartId)
      .then(this.loaded)
      .catch(() => this.loaded([]));
  }

  private loaded = (data: dashboardApi.ChartData[]) => {
    this.loading(false);
    this.config({ scale: this.config().scale, data });
  };

  setScale(scale: number) {
    this.config({ scale: scale, data: this.config().data });
  }

  download = (_: {}, evt: Event) => {
    downloadChart(evt.target as Element, this.name);
  };

  downloadExcel = () => {
    this.downloadExcelBlob(dashboardApi.customChartExcel);
  };

  downloadSummary = () => {
    this.downloadExcelBlob(dashboardApi.customChartSummary);
  };

  private downloadExcelBlob(apiCall: (chartId: string) => Promise<Blob>) {
    this.loading(true);

    apiCall(this.chartId)
      .then((data) => {
        this.loading(false);
        downloadBlob(data, this.name + '.xlsx');
      })
      .catch(() => {
        this.loading(false);
      });
  }
}

interface Option {
  id: string;
  name: string;
}

interface MesOption extends Option {
  dataset_id: string;
  class_only: boolean;
}

export class EditCustomChart {
  private refreshCounter = 0;
  private name = i18n.t('Preview')();

  private canRefresh = true;
  private subscriptions: KnockoutSubscription[] = [];

  chartTypes: Option[] = [
    { id: 'bar', name: i18n.t('Bar chart')() },
    { id: 'radar', name: i18n.t('Radar chart')() },
  ];
  labelOptions: Option[] = [
    { id: null, name: i18n.t('No label')() },
    { id: 'rank', name: i18n.t('Label with rank')() },
  ];

  dms: Option[];
  requiredMesOptions: MesOption[];
  mesOptions: MesOption[];
  mesCompat: dashboardApi.MesCompat;
  surfaceMMs: Option[];

  private id: string;
  chartType = ko.observable('bar');
  label = ko.observable<string>(null);
  secondaryGroupById = ko.observable<string>(null);
  groupBy = ko.observableArray<GroupBy>();
  stats = ko.observableArray<EditStat>();

  errors = ko.observableArray<string>();

  loading = ko.observable(false);
  config = ko.observable<ChartConfig>({ scale: 1.5, data: [] });

  canHaveLabel = ko.pureComputed(() => {
    return this.chartType() === 'bar';
  });

  canHaveSecondaryGroupBy = this.canHaveLabel;

  constructor(
    private trialId: string,
    options: dashboardApi.ChartOptionsData,
    data: dashboardApi.EditCustomChartData
  ) {
    this.dms = [{ id: null, name: i18n.t('Select')() }].concat(options.dms);
    this.requiredMesOptions = options.mes_options;
    this.mesOptions = [{ id: null, name: i18n.t('Select')(), dataset_id: '', class_only: false }].concat(
      options.mes_options
    );
    this.surfaceMMs = [{ id: null, name: i18n.t('Trial default')(), dataset_id: '' }].concat(
      options.mes_options.filter((opt) => opt.is_surface)
    );
    this.mesCompat = options.mes_compat;

    if (data) {
      this.id = data.id;
      this.chartType(data.chart_type);
      this.label(data.label);
      this.secondaryGroupById(data.secondary_group_by_id);
      data.group_by.forEach(this.addGroupBy);
      this.stats(data.stats.map((stat, i) => new EditStat(this, i === 0, stat)));
    } else {
      // initialize new chart with an acceptable default
      for (let dm of options.dms) {
        if (dm.slug === 'site') {
          this.secondaryGroupById(dm.id);
        }
      }
      if (options.dms.length > 0) {
        this.addGroupBy(options.dms[0].id);
      }
    }

    this.addGroupBy(null);

    this.subscriptions.push(this.chartType.subscribe(this.onChartTypeChange));
    this.subscriptions.push(this.label.subscribe(this.refresh));
    this.subscriptions.push(this.secondaryGroupById.subscribe(this.refresh));

    this.refresh();
  }

  dispose() {
    for (let sub of this.subscriptions) {
      sub.dispose();
    }
    for (let stat of this.stats()) {
      stat.dispose();
    }
  }

  toData(): dashboardApi.EditCustomChartData {
    return {
      id: this.id,
      chart_type: this.chartType(),
      label: this.label() || null,
      secondary_group_by_id: this.secondaryGroupById() || null,
      group_by: this.groupBy()
        .map((g) => g.id())
        .filter((id) => !!id),
      stats: this.stats()
        .filter((stat) => !!stat.mesId())
        .map((stat) => stat.toData()),
    };
  }

  mesName(id: string) {
    let mes = findById(this.requiredMesOptions, id);
    return mes ? mes.name : '';
  }

  dmName(id: string) {
    let dm = findById(this.dms, id);
    return dm ? dm.name : '';
  }

  hasSummary = ko.pureComputed(() => {
    let sec = this.secondaryGroupById() ? 1 : 0;
    return this.chartType() === 'bar' && sec + this.groupBy().length - 1 >= 2;
  });

  private addGroupBy = (initialValue: string) => {
    if (this.groupBy().length >= this.dms.length - 1) {
      return;
    }

    let id = ko.observable(initialValue);
    this.groupBy.push({ id: id });
    this.subscriptions.push(id.subscribe(this.onGroupByIdChanged.bind(this)));
  };

  private forceRefresh() {
    this.canRefresh = true;
    this.refresh();
  }

  refresh = () => {
    if (!this.canRefresh) {
      return;
    }

    let counter = ++this.refreshCounter;

    this.ensureStats();
    if (this.validate()) {
      this.loading(true);
      dashboardApi
        .customChartPreview(this.trialId, this.toData())
        .then((data) => this.loaded(counter, data))
        .catch(() => this.loaded(counter, []));
    } else {
      this.loaded(counter, []);
    }
  };

  private loaded = (counter: number, data: dashboardApi.ChartData[]) => {
    if (counter < this.refreshCounter) {
      return;
    }

    this.loading(false);
    this.config({ scale: this.config().scale, data });
  };

  validate(): boolean {
    if (this.stats().filter((stat) => !!stat.mesId()).length === 0) {
      // happens when the trial has no measurement,
      // simply ignore, users won't see an editing interface anyway.
      return false;
    }

    let errors: string[] = [];

    for (let stat of this.stats()) {
      errors = errors.concat(stat.validate());
    }

    this.errors(errors);

    return errors.length === 0;
  }

  private onGroupByIdChanged = () => {
    let groupBy = this.groupBy().filter((g) => !!g.id());
    this.groupBy(groupBy);
    this.addGroupBy(null);

    this.refresh();
  };

  private onChartTypeChange = () => {
    this.canRefresh = false;

    if (this.chartType() === 'radar') {
      this.label(null);
      if (this.secondaryGroupById()) {
        let found = false;
        for (let group of this.groupBy()) {
          if (group.id() === this.secondaryGroupById()) {
            found = true;
          }
        }
        if (!found) {
          // since we have a dm which is not found in groupBy, the last groupBy
          // is guaranteed to be empty
          this.groupBy()[this.groupBy().length - 1].id(this.secondaryGroupById());
        }
        this.secondaryGroupById(null);
      }
    }

    this.forceRefresh();
  };

  private ensureStats() {
    this.canRefresh = false;

    let stats = this.stats().filter((stat) => {
      if (stat.mesId()) {
        return true;
      } else {
        stat.dispose();
        return false;
      }
    });
    if (stats.length < 1) {
      stats.push(new EditStat(this, true));
      stats[0].ensureNotEmpty();
    }
    if (this.chartType() === 'bar' && stats.length > 1) {
      stats = stats.slice(0, 1);
      stats[0].ensureNotEmpty();
    }
    if (this.chartType() === 'radar') {
      stats.push(new EditStat(this, false));
    }

    this.stats(stats);

    this.canRefresh = true;
  }

  download = (_: {}, evt: Event) => {
    downloadChart(evt.target as Element, this.name);
  };

  downloadExcel = () => {
    this.downloadExcelBlob(dashboardApi.customChartExcelPreview);
  };

  downloadSummary = () => {
    this.downloadExcelBlob(dashboardApi.customChartSummaryPreview);
  };

  private downloadExcelBlob(
    apiCall: (trialId: string, data: dashboardApi.EditCustomChartData) => Promise<Blob>
  ) {
    this.loading(true);

    apiCall(this.trialId, this.toData())
      .then((data) => {
        this.loading(false);
        downloadBlob(data, this.name + '.xlsx');
      })
      .catch(() => {
        this.loading(false);
      });
  }
}

let allCalcTypes: Option[] = [
  { id: 'raw', name: i18n.t('As-is')() },
  { id: 'normalized_yield', name: i18n.t('Normalized yield')() },
  { id: 'ratio', name: i18n.t('Ratio (A/B)')() },
  { id: 'perc', name: i18n.t('Percentage (A/(A+B))')() },
];
let allStatTypes: Option[] = [
  { id: 'mean', name: i18n.t('Mean')() },
  { id: 'std_err', name: i18n.t('Std. err.')() },
  { id: 'control_perc', name: i18n.t('% of best control')() },
  { id: 'frequency', name: i18n.t('Frequency (histogram)')() },
];

class EditStat {
  private canRefresh = true;
  private subscriptions: KnockoutSubscription[] = [];

  mesOptions: MesOption[];
  mes2Options = ko.observableArray<MesOption>();

  mesId = ko.observable<string>(null);
  calc = ko.observable('raw');
  mes2Id = ko.observable<string>(null);
  yieldDmId = ko.observable<string>(null);
  areaMMId = ko.observable<string>(null);
  stat = ko.observable('mean');

  constructor(private chart: EditCustomChart, public isRequired: boolean, data?: dashboardApi.EditStatData) {
    this.mesOptions = isRequired ? chart.requiredMesOptions : chart.mesOptions;

    if (data) {
      this.mesId(data.measurement_meta_id);
      this.calc(data.calc);
      this.mes2Id(data.measurement_meta_2_id);
      this.yieldDmId(data.yield_dim_id);
      this.areaMMId(data.area_mm_id);
      this.stat(data.stat);
    }

    this.subscriptions.push(this.mesId.subscribe(this.onMesChanged));
    this.subscriptions.push(this.calc.subscribe(this.onCalcChanged));
    this.subscriptions.push(this.mes2Id.subscribe(this.refresh));
    this.subscriptions.push(this.yieldDmId.subscribe(this.refresh));
    this.subscriptions.push(this.areaMMId.subscribe(this.refresh));
    this.subscriptions.push(this.stat.subscribe(this.refresh));
  }

  dispose() {
    for (let sub of this.subscriptions) {
      sub.dispose();
    }
  }

  calcTypes = ko.pureComputed(() => {
    let opt = this.mesOption();

    if (opt && opt.class_only) {
      return allCalcTypes.slice(0, 1);
    } else {
      return allCalcTypes;
    }
  });

  statTypes = ko.pureComputed(() => {
    let opt = this.mesOption();

    if (opt && opt.class_only) {
      return allStatTypes.slice(3, 4);
    } else {
      return allStatTypes;
    }
  });

  private mesOption() {
    return findById(this.mesOptions, this.mesId());
  }

  toData(): dashboardApi.EditStatData {
    return {
      calc: this.calc(),
      stat: this.stat(),
      measurement_meta_id: this.mesId(),
      measurement_meta_2_id: this.mes2Id() || null,
      yield_dim_id: this.yieldDmId() || null,
      area_mm_id: this.areaMMId() || null,
    };
  }

  validate() {
    let errors: string[] = [];

    if (this.mesId()) {
      if (!this.isCompatible(this.mesId(), this.chart.secondaryGroupById())) {
        errors.push(
          i18n.t(['cant_use_mes_when_clustering_by', "{{mes}} can't be used when clustering by {{dm}}"], {
            mes: this.chart.mesName(this.mesId()),
            dm: this.chart.dmName(this.chart.secondaryGroupById()),
          })()
        );
      }

      if (!this.isCompatible(this.mesId(), this.yieldDmId())) {
        errors.push(
          i18n.t(['cant_use_mes_when_summing_over', "{{mes}} can't be used when summing over {{dm}}"], {
            mes: this.chart.mesName(this.mesId()),
            dm: this.chart.dmName(this.yieldDmId()),
          })()
        );
      }

      for (let group of this.chart.groupBy()) {
        let id = group.id();

        if (!this.isCompatible(this.mesId(), id)) {
          errors.push(
            i18n.t(['cant_use_mes_when_groupin', "{{mes}} can't be used when grouping by {{dm}}"], {
              mes: this.chart.mesName(this.mesId()),
              dm: this.chart.dmName(id),
            })()
          );
        }
      }
    }

    if (this.yieldDmId()) {
      for (let group of this.chart.groupBy()) {
        if (group.id() === this.yieldDmId()) {
          let name = this.chart.dmName(this.yieldDmId());
          errors.push(
            i18n.t("{{name}} is already used in grouping. It can't be summed over in yield calculation.", {
              name,
            })()
          );
          break;
        } else if (this.chart.secondaryGroupById() === this.yieldDmId()) {
          let name = this.chart.dmName(this.yieldDmId());
          errors.push(
            i18n.t(
              "{{name}} is already used to cluster the bars. It can't be summed over in yield calculation.",
              { name }
            )()
          );
          break;
        }
      }
    }

    return errors;
  }

  private isCompatible(mesId: string, dmId: string): boolean {
    let compatDms = this.chart.mesCompat[mesId];
    return !dmId || compatDms.indexOf(dmId) !== -1;
  }

  private onMesChanged = (newValue: string) => {
    this.canRefresh = false;

    if (!newValue) {
      this.mes2Options([]);
      this.mes2Id(null);
    } else {
      let opt = this.mesOption();

      if (opt.class_only) {
        this.calc('raw');
        this.stat('frequency');
      }

      let datasetId = opt.dataset_id;
      this.mes2Options(this.mesOptions.filter((mes) => mes.dataset_id === datasetId));
      if (this.mes2Id() && this.mes2Options().filter((mes) => mes.id === this.mes2Id()).length === 0) {
        if (this.needsMes2() && this.mes2Options().length > 0) {
          this.mes2Id(this.mes2Options()[0].id);
        } else {
          this.mes2Id(null);
        }
      }
    }

    this.forceRefresh();
  };

  private onCalcChanged = (newValue: string) => {
    this.canRefresh = false;

    if (newValue !== 'normalized_yield') {
      this.yieldDmId(null);
      this.areaMMId(null);
    }
    if (newValue !== 'ratio' && newValue !== 'perc') {
      this.mes2Id(null);
    } else if (!this.mes2Id() && this.mes2Options().length > 0) {
      this.mes2Id(this.mes2Options()[0].id);
    }

    this.forceRefresh();
  };

  refresh = () => {
    if (this.canRefresh) {
      this.chart.refresh();
    }
  };

  private forceRefresh() {
    this.canRefresh = true;
    this.refresh();
  }

  needsMes2 = ko.pureComputed(() => {
    return this.calc() === 'ratio' || this.calc() === 'perc';
  });

  canHaveNormYieldOptions = ko.pureComputed(() => {
    return this.calc() === 'normalized_yield';
  });

  ensureNotEmpty() {
    let firstMesIndex = this.isRequired ? 0 : 1; // element 0 is the empty one if not required

    this.canRefresh = false;
    if (this.mesId() === null && this.mesOptions.length > firstMesIndex) {
      this.mesId(this.mesOptions[firstMesIndex].id);
    }
    this.canRefresh = true;
  }
}

function downloadChart(target: Element, name: string) {
  let $parent = $(target).parents('.card-content');
  let svg = $parent.find('svg').get(0);
  let canvas = $parent.find('canvas').get(0) as HTMLCanvasElement;

  let svgText = new XMLSerializer().serializeToString(svg);
  let svgBlob = new Blob([svgText], { type: 'image/svg+xml;charset=utf-8' });

  if (navigator.msSaveBlob) {
    // due to a SecurityException when writing a data-uri svg to the canvas,
    // on IE 11 we don't support png, instead we download directly an svg.
    navigator.msSaveBlob(svgBlob, name + '.svg');
    return;
  }

  let svgURL = URL.createObjectURL(svgBlob);

  let img = new Image();
  img.onload = () => {
    URL.revokeObjectURL(svgURL);

    canvas.height = img.height;
    canvas.width = img.width;

    let ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(img, 0, 0);

    downloadURI(canvas.toDataURL(), name + '.png');
  };
  img.src = svgURL;
}
