import * as ko from 'knockout';

let template = require('raw-loader!../../templates/crop_varieties_request.html').default;

class CropVarietiesRequestScreen {
  customVarietyRequestURL = APP_CONFIG.CUSTOM_VARIETY_REQUEST_URL;
}

export let cropVarietiesRequest = {
  name: 'crop-varieties-request',
  viewModel: CropVarietiesRequestScreen,
  template: template,
};

ko.components.register(cropVarietiesRequest.name, cropVarietiesRequest);
