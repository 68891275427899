import { makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';

export interface DocumentCategoryData {
  id?: string;
  name_json: I18nText;
}

export const { list, retrieve, save, remove } = makeDefaultApi<DocumentCategoryData, { ids?: string[] }>(
  'document_categories'
);
