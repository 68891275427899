import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import * as svApi from '../api/scheduled_visits';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { canEditScheduledVisits } from '../permissions';

let template = require('raw-loader!../../templates/scheduled_visit_library.html').default;

class ScheduledVisitLibraryScreen implements ListLoaderDelegate<svApi.ScheduledVisitData> {
  canEdit = canEditScheduledVisits();
  listActions = ko.observableArray<ListHeaderAction>([]);

  constructor() {
    if (this.canEdit) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/scheduled_visit_library/new/',
        tooltipTitle: i18n.t('Add scheduled visit')(),
      });
    }
  }

  fetch(params: ListRequestParams) {
    return svApi.list(params);
  }

  instantiate(data: svApi.ScheduledVisitData) {
    return data;
  }

  remove(id: string) {
    return svApi.remove(id);
  }

  canRemove(data: svApi.ScheduledVisitData) {
    return this.canEdit;
  }
}

export const scheduledVisitLibrary = {
  name: 'scheduled-visit-library',
  viewModel: ScheduledVisitLibraryScreen,
  template,
};

ko.components.register(scheduledVisitLibrary.name, scheduledVisitLibrary);
