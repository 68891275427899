import * as ko from 'knockout';
import i18n from '../i18n';

import { RemoveResult } from '../api/request';
import { ListLoaderDelegate, ListFilter } from './list_loader';
import { Deferred } from '../utils/deferred';
import { defaultRateLimit } from '../models/attribute_meta';
import { I18nText } from '../i18n_text';
import * as mmApi from '../api/measurement_metas';
import { findById } from '../utils';

let template = require('raw-loader!../../templates/components/trait_advanced_search.html').default;

interface AdvancedSearchModel<TData, TModel> extends ListLoaderDelegate<TData, TModel> {
  allowMultipleSelections: boolean;
  loading: KnockoutObservable<boolean>;

  getItemName: (item: TModel) => I18nText | string;

  cancel: () => void;
  selectAll: () => void;
  done: () => void;
  onItemSelected: (item: TModel) => void;
  isItemSelected: (item: TModel) => boolean;
}

export type TraitAdvancedSearchResult = { id: string; name_json: I18nText }[];

class TraitAdvancedSearch
  implements AdvancedSearchModel<mmApi.MeasurementMetaLibraryData, mmApi.MeasurementMetaLibraryData>
{
  filters: ListFilter[] = [
    {
      name: i18n.t('Name')(),
      slug: 'name_prefix',
      type: 'text',
      value: ko.observable('').extend({ rateLimit: defaultRateLimit }),
    },
  ];

  private result: Deferred<TraitAdvancedSearchResult>;
  private entities: mmApi.MeasurementMetaLibraryData[] = [];
  private selections = ko.observableArray<{ id: string; name_json: I18nText }>();

  allowMultipleSelections = true;

  loading = ko.observable(false);

  constructor(params: {
    allowMultipleSelections: boolean;
    initialMultipleSelections: { id: string; name_json: I18nText }[];
    result: Deferred<TraitAdvancedSearchResult>;
  }) {
    this.allowMultipleSelections = params.allowMultipleSelections;
    this.selections(params.initialMultipleSelections ?? []);
    this.result = params.result;
  }

  getItemName(item: mmApi.MeasurementMetaLibraryData): I18nText {
    return item.name_json;
  }

  cancel = () => {
    this.result.reject();
  };

  selectAll = () => {
    this.entities.map((item) => this.selections.push({ id: item.id, name_json: item.name_json }));
  };

  done = () => {
    this.result.resolve(this.selections());
  };

  onItemSelected = (item: mmApi.MeasurementMetaLibraryData) => {
    const selected = findById(this.selections(), item.id);

    const traitSelected: { id: string; name_json: I18nText } = { id: item.id, name_json: item.name_json };
    if (!selected) {
      this.selections.push(traitSelected);
    }
  };

  isItemSelected = (item: mmApi.MeasurementMetaLibraryData) => {
    return !!findById(this.selections(), item.id);
  };
  mergeEntities = (items: mmApi.MeasurementMetaLibraryData[]) => {
    const ids = this.entities.length > 0 ? new Set(this.entities.map((entity) => entity.id)) : new Set([]);
    const merged = [...this.entities, ...items.filter((item) => !ids.has(item.id))];
    return merged;
  };

  async fetch(params: any): Promise<mmApi.MeasurementMetaLibraryData[]> {
    params.name_prefix = this.filters[0].value() as string;

    const fetchedItems = await mmApi.list(params);
    if (params.offset === 0) {
      this.entities = fetchedItems;
    } else {
      this.entities = this.mergeEntities(fetchedItems);
    }
    return fetchedItems;
  }

  instantiate(data: mmApi.MeasurementMetaLibraryData): mmApi.MeasurementMetaLibraryData {
    return data;
  }

  remove(id: string): Promise<RemoveResult> {
    return Promise.reject(null);
  }

  canRemove(entity: mmApi.MeasurementMetaLibraryData): boolean {
    return false;
  }
}

export let traitAdvancedSearch = {
  name: 'trait-advanced-search',
  viewModel: TraitAdvancedSearch,
  template: template,
};

ko.components.register(traitAdvancedSearch.name, traitAdvancedSearch);
