import * as ko from 'knockout';
import page from 'page';
import { BaseLoadingScreen } from './base_loading_screen';
import {
  header,
  ReviewMergeRecord,
  ReviewDataset,
  ReviewRecordKey,
  record,
  ReviewMergeAttribute,
  MergeAttributeAction,
  MergeRecordAction,
  merge,
  reviewRecordKeyEq,
  reviewRecordKeyHash,
} from '../api/review';
import { indexOf, findById } from '../utils';
import { logError } from '../error_logging';
import { openReviewConfirmMergeAll } from '../components/review_confirm_merge_all';
import { session } from '../session';
import { canMergeSyncReview } from '../permissions';

let template = require('raw-loader!../../templates/review.html').default;

interface Option {
  id: number | string;
  name: string;
}

class ReviewScreen extends BaseLoadingScreen {
  loadingRecord = ko.observable(true);
  saving = ko.observable(false);
  trials = ko.observableArray<Option>();
  allDatasets = ko.observableArray<ReviewDataset>();
  trialId = ko.observable<string>(null);
  datasetId = ko.observable<string>(null);

  trialDatasets = ko.pureComputed(() => this.getTrialDatasets());
  records = ko.observableArray<ReviewRecordKey>(); // already sorted by trial, dataset

  recordIdx = ko.observable(0);
  position = ko.pureComputed(() => this.recordIdx() + 1 + '/' + this.records().length);

  private openedRecords: {
    [key: string]: { record: ReviewMergeRecord; mergeActions: MergeAction[] };
  } = {};
  private record = ko.observable<ReviewMergeRecord>(null);
  mergeActions = ko.observableArray<MergeAction>();

  private ignoreChange = false;
  private subscriptions: KnockoutSubscription[] = [];

  allUser = ko.pureComputed(() => this.mergeActions().every((action) => action.keepUser()));
  allServer = ko.pureComputed(() => this.mergeActions().every((action) => action.keepServer()));

  canMerge = canMergeSyncReview();

  constructor() {
    super();

    this.subscriptions.push(this.trialId.subscribe(this.onTrialIdChanged));
    this.subscriptions.push(this.datasetId.subscribe(this.onDatasetIdChanged));

    let promise = header().then((data) => {
      this.trials(data.trials);
      this.allDatasets(data.datasets);
      this.records(data.records);
      if (data.trials.length > 0) {
        this.trialId(data.trials[0].id.toString());
      }
    });
    this.loadedAfter(promise);
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
  }

  private getTrialDatasets() {
    return this.allDatasets().filter((ds) => ds.trial_id.toString() === this.trialId());
  }

  private onTrialIdChanged = () => {
    if (this.ignoreChange) {
      return;
    }

    this.datasetId(this.getTrialDatasets()[0].id.toString());
  };

  private onDatasetIdChanged = (id: string) => {
    if (this.ignoreChange) {
      return;
    }

    // guaranteed to exist
    this.recordIdx(indexOf(this.records(), (rec) => rec.dataset_id.toString() === id));
    this.loadRecord();
  };

  private loadRecord = () => {
    let key = this.records()[this.recordIdx()];
    if (!key) {
      return;
    }
    let existing = this.openedRecords[reviewRecordKeyHash(key)];
    if (existing) {
      this.record(existing.record);
      this.mergeActions(existing.mergeActions);
      return;
    }

    this.loadingRecord(true);
    record(key)
      .then((data) => {
        this.record(data);
        this.mergeActions(data.attributes.map((attr) => new MergeAction(attr)));
        this.openedRecords[reviewRecordKeyHash(key)] = {
          record: this.record(),
          mergeActions: this.mergeActions(),
        };
        this.loadingRecord(false);
      })
      .catch((e) => {
        logError(e.toString(), e);
        this.loadingRecord(false);
      });
  };

  onMergeAll = () => {
    openReviewConfirmMergeAll().then(() => page(session.toTenantPath('/trials/')));
  };

  onPrevious = () => {
    this.goTo(this.recordIdx() - 1);
  };

  onNext = () => {
    this.goTo(this.recordIdx() + 1);
  };

  private goTo(idx: number) {
    idx = Math.max(0, Math.min(this.records().length - 1, idx));
    this.recordIdx(idx);
    this.updateDsTrial();
    this.loadRecord();
  }

  private updateDsTrial() {
    this.ignoreChange = true;
    let dsId = this.records()[this.recordIdx()].dataset_id || 0;
    let ds = findById(this.allDatasets(), dsId);
    this.datasetId(dsId.toString());
    this.trialId(ds.trial_id.toString());
    this.ignoreChange = false;
  }

  selectAllCurrent = () => {
    for (let action of this.mergeActions()) {
      action.selectServer();
    }
  };

  selectAllUser = () => {
    for (let action of this.mergeActions()) {
      action.selectUser();
    }
  };

  save = () => {
    if (this.saving()) {
      return;
    }

    let record = this.record();
    if (!record) {
      return;
    }

    this.saving(true);
    merge(this.toData(record))
      .then((validation) => {
        // NOTE: any validation issue is an internal error
        if (validation.isValid) {
          this.removeKey(record);
        }
        this.saving(false);
      })
      .catch(() => {
        this.saving(false);
      });
  };

  private removeKey(key: ReviewRecordKey) {
    let idx = indexOf(this.records(), (key2) => reviewRecordKeyEq(key2, key));
    this.records.splice(idx, 1);

    let otherInDs = indexOf(this.records(), (key2) => key2.dataset_id === key.dataset_id);
    if (otherInDs === -1) {
      // dataset is now empty, remove it
      let dsIdx = indexOf(this.allDatasets(), (ds) => ds.id === key.dataset_id);
      let [ds] = this.allDatasets.splice(dsIdx, 1);
      if (this.trialDatasets().length === 0) {
        // trial is now empty, remove it
        let trIdx = indexOf(this.trials(), (tr) => tr.id === ds.trial_id);
        this.trials.splice(trIdx, 1);
        if (this.trials().length > 0) {
          this.trialId(this.trials()[Math.min(this.trials().length - 1, trIdx)].id.toString());
        }
      } else {
        this.datasetId(this.trialDatasets()[0].id.toString());
      }
    } else {
      this.goTo(this.recordIdx());
    }
  }

  private toData(record: ReviewMergeRecord): MergeRecordAction {
    return {
      record_type: record.record_type,
      review_id: record.id,
      attribute_actions: this.mergeActions().map((action) => action.toData()),
    };
  }
}

class MergeAction {
  keepUser = ko.observable(true);
  keepServer = ko.observable(false);

  selectedValue = ko.pureComputed(() => (this.keepUser() ? this.data.user_value : this.data.server_value));

  constructor(public data: ReviewMergeAttribute) {}

  selectServer = () => {
    this.keepServer(true);
    this.keepUser(false);
  };

  selectUser = () => {
    this.keepServer(false);
    this.keepUser(true);
  };

  toData(): MergeAttributeAction {
    return {
      id: this.data.id,
      action: this.keepUser() ? 'user' : 'server',
    };
  }
}

export let review = {
  name: 'review',
  viewModel: ReviewScreen,
  template: template,
};

ko.components.register(review.name, review);
