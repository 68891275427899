import * as ko from 'knockout';

import { app } from '../app';
import i18n from '../i18n';
import { Deferred } from '../utils/deferred';
import { Value } from '../api/review';
import { MaybeKO } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/review_value.html').default;

class ReviewValue {
  type: 'geo' | 'url' | 'url_list' | 'other';
  value: MaybeKO<Value>;

  isEmpty = ko.pureComputed(() => {
    let value = ko.unwrap(this.value);
    return value === null || value === undefined || value === '';
  });

  constructor(params: { type: MaybeKO<'geo' | 'url' | 'url_list' | 'other'>; value: MaybeKO<Value> }) {
    this.type = ko.unwrap(params.type);
    this.value = params.value;
  }

  openMap = (_: {}, event: Event) => {
    event.stopPropagation();

    app.formsStackController.push({
      title: i18n.t('Map')(),
      name: 'map',
      isBig: true,
      params: {
        value: [ko.unwrap(this.value)],
        result: new Deferred<{}>(),
      },
    });
  };
}

const reviewValue = {
  name: 'review-value',
  viewModel: ReviewValue,
  template: template,
};

ko.components.register(reviewValue.name, reviewValue);
