import * as ko from 'knockout';
import i18n from '../i18n';

import { MaybeKO, asObservable, KOMaybeArray, moveUp, moveDown } from '../utils/ko_utils';
import { app } from '../app';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as dimensionsApi from '../api/dimensions';
import * as sitesApi from '../api/sites';
import { DimensionMeta } from '../models/dimension_meta';
import { FormSelectSearchConfiguration, FormSelectCreate } from './form_select_search';
import { Deferred } from '../utils/deferred';
import { LimitToDimension } from '../models/dataset_dimension_meta';
import { CountryData } from '../api/countries';
import { RegionData } from '../api/regions';
import { translate, I18nText } from '../i18n_text';
import { UserData } from '../api/users';
import { canEditCropVariety } from '../permissions';
import { parseDate } from '../api/serialization';
import { TrialWizard } from '../models/trial';
import { asArray } from '../utils';
import { getDimensionCreate } from '../models/dimension';
import { uuid4 } from '../utils/uuid';
import { session } from '../session';
import { cropVarietiesList } from '../api/v2/crop_variety';
import * as dragula from 'dragula';

let template = require('raw-loader!../../templates/components/dimensions_table.html').default;

const PAGE_SIZE = 100;

export interface DimensionsTableConfig {
  user: UserData;

  canReorder: boolean;
  addDimensionText: MaybeKO<string>;

  dimensionMeta: MaybeKO<DimensionMeta>;
  dimensions: KnockoutObservableArray<LimitToDimension>;
  crops?: KOMaybeArray<dimensionsApi.DimensionData>;
  country?: KnockoutObservable<CountryData>;
  region?: KnockoutObservable<RegionData>;
  date?: KnockoutObservable<Date>;
  trialWizard?: TrialWizard;
  trialId?: KnockoutObservable<string>;

  visibleItemsLimit?: KnockoutObservable<number>;

  allowAnonymize: boolean;
  allowEditControl: boolean;
  allowEditDisable: boolean | KnockoutComputed<boolean>;
  allowEditOptional: boolean;
  allowViewLimitToDimensionNameAndAttributes?: boolean;
  enableOrderNumber?: boolean;
  enableDragableReorder?: boolean;
  enableEditDimensionMeta?: boolean;
  allowIncludeInFactorialCombinations?: boolean;
  allowEdit: () => boolean;
  allowEditAny: () => boolean;

  confirmChangeEntities?: () => Promise<{}>;
  onEntityRemove?: (arg0: LimitToDimension) => Promise<void>;
  multipleSubjectsOptionEnabled?: boolean;
}

class DimensionsTable implements DimensionsTableBodyDelegate {
  config: KnockoutObservable<DimensionsTableConfig>;
  visibleItemsLimit: KnockoutObservable<number> = ko.observable(PAGE_SIZE);

  dimensionSearchConfig = ko.pureComputed(() => {
    let trialWizard = this.config().trialWizard;
    let dimensionMeta = ko.unwrap(this.config().dimensionMeta);
    let create: FormSelectCreate<dimensionsApi.DimensionData, LimitToDimension> = undefined;
    let advancedSearch: {
      componentName: string;
      extraParams: {};
      instantiate: (data: dimensionsApi.DimensionData) => LimitToDimension;
      serialize: (instance: LimitToDimension) => dimensionsApi.DimensionData;
    } = undefined;
    let user = this.config().user;
    if (
      dimensionMeta &&
      (dimensionMeta.slug() !== dimensionMetasApi.CROP_VARIETY_SLUG || canEditCropVariety(user))
    ) {
      create = {
        ...getDimensionCreate(dimensionMeta.toData(), {
          initialCrop: this.config().crops,
          initialCountry: this.config().country,
          initialRegion: this.config().region,
        }),
        instantiate: (data) => new LimitToDimension(trialWizard, dimensionMeta, data),
        insert: (dim: LimitToDimension) => {
          if (this.config().canReorder || this.config().enableDragableReorder) {
            this.config().dimensions.push(dim);
          } else {
            let dims = this.config().dimensions();
            let i = 0;
            for (; i < dims.length; i++) {
              if (translate(dim.nameJson()).localeCompare(translate(dims[i].nameJson())) < 0) {
                break;
              }
            }

            this.config().dimensions.splice(i, 0, dim);
          }
        },
        insertAll: (dims: LimitToDimension[]) => {
          // This method must be used instead of calling the .insert() method multiple times.
          // This is because when .insert() adds the element to the ObservableArray, all the
          // subscribers are notified. For a large number of elements, this can make the browser
          // unresponsive or kill the tab.
          if (this.config().canReorder || this.config().enableDragableReorder) {
            this.config().dimensions.push(...dims);
          } else {
            let i = 0;
            for (let dim of dims) {
              for (; i < this.config().dimensions().length; i++) {
                if (translate(dim.nameJson()).localeCompare(translate(this.config().dimensions()[i].nameJson())) < 0) {
                  break;
                }
              }

              this.config().dimensions.splice(i, 0, dim);
              i++;
            }
        }
        }
      };
      if (dimensionMeta.slug() === dimensionMetasApi.CROP_VARIETY_SLUG) {
        (create.extraParams as any)['multi'] = true;
      }
      advancedSearch = {
        componentName: 'dimension-advanced-search',
        extraParams: create.extraParams,
        instantiate: (data) => new LimitToDimension(trialWizard, dimensionMeta, data),
        serialize: (instance) => instance.toData(),
      };
    }

    let res: FormSelectSearchConfiguration<LimitToDimension> = {
      getSummaryName: (dimension) => {
        return dimension.nameJson();
      },

      list: (params) => {
        if (!dimensionMeta) {
          return Promise.resolve([]);
        }

        let country = this.config().country && this.config().country();
        let req: Promise<dimensionsApi.DimensionData[]>;
        if (this.isSite()) {
          let countryIds = country ? [country.id] : null;
          req = sitesApi.list({ country_ids: countryIds, ...params });
        } else {
          let crops = this.config().crops;
          let cropIds = asArray(crops ? crops() : null).map((crop) => crop.id);
          if (this.dimensionMetaSlugIs(dimensionMetasApi.CROP_VARIETY_SLUG)) {
            return cropVarietiesList(
              { crop_ids: cropIds, country_id: country ? country.id : null },
              params
            ).then(({ results }) => {
              return results.map((data) => new LimitToDimension(trialWizard, dimensionMeta, data));
            });
          } else
            req = dimensionsApi.list(
              dimensionMeta.id(),
              { crop_ids: cropIds, country_id: country ? country.id : null },
              params
            );
        }
        return req.then((result) => {
          return result.map((data) => new LimitToDimension(trialWizard, dimensionMeta, data));
        });
      },

      manuallyManageEntities: true,
      entities: this.config().dimensions,

      create: create,
      confirmChangeEntities: this.config().confirmChangeEntities,

      advancedSearch: advancedSearch,
    };

    return res;
  });

  private configSub: KnockoutSubscription;
  private dimensionsSub: KnockoutSubscription;

  warnStage = session.tenant().tpp_enabled;

  tbody = dimensionsTableBody(this);

  constructor(params: { config: MaybeKO<DimensionsTableConfig> }) {
    this.config = asObservable(params.config);

    this.configSub = this.config.subscribe(this.subscribeToDimensions);

    this.subscribeToDimensions();
  }

  dispose() {
    this.configSub.dispose();
    this.dimensionsSub.dispose();
    this.tbody.dispose();
    this.tbody = null;
  }

  showMore() {
    if (this.visibleItemsLimit() >= this.config().dimensions().length) {
      return;
    }

    this.visibleItemsLimit(this.visibleItemsLimit() + PAGE_SIZE);
    this.tbody.update(this);
  }

  private subscribeToDimensions = () => {
    if (this.dimensionsSub) {
      this.dimensionsSub.dispose();
    }
    this.dimensionsSub = this.config().dimensions.subscribe(this.reloadStages);
    this.reloadStages();

    this.tbody.updateConfig(this);
  };

  private reloadStages = () => {
    if (!this.showStage()) {
      return;
    }

    let config = ko.unwrap(this.config);
    let dims = config.dimensions();
    let trialId = config.trialId && config.trialId();
    dimensionsApi
      .getStagesByCountry(
        trialId,
        dims.map((dim) => dim.id())
      )
      .then((stages) => {
        this.allStages(stages);
      });
  };

  allStages = ko.observable<dimensionsApi.StageByCountryData>({
    observation_planting_date: null,
    stages: {},
  });

  getStage(dimension: LimitToDimension): I18nText {
    let config = ko.unwrap(this.config());
    let country = ko.unwrap(config.country);
    let stages = this.allStages().stages[dimension.id()];
    let date = parseDate(this.allStages().observation_planting_date) || ko.unwrap(config.date);

    if (!stages || !country) {
      return null;
    }

    let countryStages = stages[country.id];
    if (!countryStages) {
      return null;
    }

    // countryStages is already sorted by start_at, desc
    for (let stage of countryStages) {
      let stageDate = parseDate(stage.start_at);
      if (!date || date.getTime() >= stageDate.getTime()) {
        return stage.name_json;
      }
    }

    return null;
  }

  showStage = ko.pureComputed(() => {
    let config = ko.unwrap(this.config());
    let dimensionMeta = ko.unwrap(config.dimensionMeta);
    let country = ko.unwrap(config.country);

    return dimensionMeta.slug() === dimensionMetasApi.CROP_VARIETY_SLUG && !!country;
  });

  isSite = ko.pureComputed(() => {
    let dimensionMeta = ko.unwrap(this.config().dimensionMeta);
    return dimensionMeta.slug() === dimensionMetasApi.SITE_SLUG;
  });

  dimensionMetaSlugIs = (slug: string) => {
    let dimensionMeta = ko.unwrap(this.config().dimensionMeta);
    return dimensionMeta.slug() === slug;
  };

  canEditDimensionMeta = ko.pureComputed(() => {
    let dimensionMeta = ko.unwrap(this.config().dimensionMeta);
    if (this.config().enableEditDimensionMeta === false) {
      return false;
    }
    return (
      dimensionMeta.slug() !== dimensionMetasApi.CROP_VARIETY_SLUG || canEditCropVariety(this.config().user)
    );
  });

  editDimensionMeta = () => {
    let dimensionMeta = ko.unwrap(this.config().dimensionMeta);
    let promise = app.formsStackController.push({
      title: i18n.t('Configure')(),
      name: 'dimension-meta-edit',
      params: {
        id: dimensionMeta.id(),
        result: new Deferred<dimensionMetasApi.DimensionMetaData>(),
      },
    });

    promise
      .then((data: dimensionMetasApi.DimensionMetaData) => {
        // re-fetch to get any new attribute id
        return dimensionMetasApi.retrieve(data.id);
      })
      .then((data: dimensionMetasApi.DimensionMetaData) => {
        dimensionMeta.initFromData(data);
        for (let dimension of this.config().dimensions()) {
          dimension.initFromData(dimension.toData(), dimensionMeta);
        }
      })
      .catch(() => {
        // nothing to do
      });
  };

  onMoveUp(dim: LimitToDimension) {
    moveUp(this.config().dimensions, dim);
  }

  onMoveDown(dim: LimitToDimension) {
    moveDown(this.config().dimensions, dim);
  }

  onEditDimension(dim: LimitToDimension) {
    let dimensionMeta = ko.unwrap(this.config().dimensionMeta);
    const componentName = getDimensionCreate(dimensionMeta.toData()).componentName;

    let params: {
      dimensionMetaId: string;
      id: string;
      result: Deferred<dimensionsApi.DimensionData>;
      onNameOverrideChange?: (value: string) => void;
      nameOverride?: string;
    }
    = {
      dimensionMetaId: dimensionMeta.id(),
      id: dim.id(),
      result: new Deferred<dimensionsApi.DimensionData>(),
    }

    if(this.config().trialWizard?.editMode == 'library') {
      params['onNameOverrideChange'] = (value: string) => {
          dim.nameOverride(value);
        }
      params['nameOverride'] = dim.nameOverride()
    }

    let promise = app.formsStackController.push({
      title: i18n.t('Edit')(),
      name: componentName,
       params,
    });

    promise
      .then((data: dimensionsApi.DimensionData) => {
        dim.initFromData(data, dimensionMeta);
        this.reloadStages();
        this.tbody.updateConfig(this);
      })
      .catch(() => {
        // nothing to do
      });
  }

  async onRemoveDimension(dim: LimitToDimension) {
    let config = this.config();

    if (config.onEntityRemove) {
      try{
        await config.onEntityRemove(dim);
      } catch (e) {
        console.error(e);
        return;
      }
    }

    if (config.confirmChangeEntities) {
      config.confirmChangeEntities().then(() => {
        this.config().dimensions.remove(dim);
      });
    } else {
      this.config().dimensions.remove(dim);
    }
  }

  onControl(dim: LimitToDimension, value: boolean) {
    dim.control(value);
    // nothing to update
  }

  onDisable(dim: LimitToDimension, value: boolean) {
    dim.disabled(value);
    // nothing to update
  }

  onOptional(dim: LimitToDimension, value: boolean) {
    dim.optional(value);
    // nothing to update
  }

  onIncludeInFactorialCombinations(limitToDimension: LimitToDimension, value: boolean): void {
    limitToDimension.includeInFactorialCombinations(value);
  }
}

ko.components.register('dimensions-table', {
  viewModel: DimensionsTable,
  template: template,
});

interface DimensionsTableBodyDelegate {
  onMoveUp(dim: LimitToDimension): void;
  onMoveDown(dim: LimitToDimension): void;
  onEditDimension(dim: LimitToDimension): void;
  onRemoveDimension(dim: LimitToDimension): void;
  onControl(dim: LimitToDimension, value: boolean): void;
  onDisable(dim: LimitToDimension, value: boolean): void;
  onOptional(dim: LimitToDimension, value: boolean): void;
  onIncludeInFactorialCombinations(dim: LimitToDimension, value: boolean): void;
}

function dimensionsTableBody(delegate: DimensionsTableBodyDelegate) {
  const root = document.createElement('tbody');

  // drake will be instantiated once in update() if enableDragableReorder is true
  let drake: dragula.Drake = null;

  const update = (tbl: DimensionsTable) => {
    root.innerHTML = '';
    const isSite = tbl.isSite();
    const showStage = tbl.showStage();
    const config = tbl.config();
    const visibleItemsLimit = tbl.visibleItemsLimit;
    const allowEditAny = config.allowEditAny();
    const canReorder = config.canReorder && allowEditAny;
    const allowEditDisable = ko.unwrap(config.allowEditDisable);
    const allowEditOptional = config.allowEditOptional;
    const canEditDm = tbl.canEditDimensionMeta();
    const allowEdit = config.allowEdit();
    const canRemove = allowEditAny && allowEdit;
    const enableOrderNumber = config.enableOrderNumber;
    const enableDragableReorder = config.enableDragableReorder;

    if (enableDragableReorder && !drake) {
      drake = dragula([root], {
        revertOnSpill: true,
        moves: (el, container, handle) => {
          return handle.classList.contains('dimensions-table-drag-handle');
        },
      });

      const onDrop = (el: HTMLTableRowElement) => {
        const dimensionId = el.getAttribute('dim-id');
        const newIndex = Array.from(root.querySelectorAll('tr')).indexOf(el);
        config.dimensions.splice(
          newIndex,
          0,
          config.dimensions().splice(
            config.dimensions().findIndex((d) => d.id() == dimensionId),
            1
          )[0]
        );
        config.confirmChangeEntities();

        if(!enableOrderNumber) {
          return;
        }

        const trs = root.querySelectorAll('tr');
        for (let i = 0; i < trs.length; i++) {
          const tr = trs[i];
          const td = tr.querySelector('.dimensions-table-position');
          td.textContent = (i + 1).toString();
        }
      };
      // to add styling for element while dragging
      drake.on('drag', (el: HTMLTableRowElement) => {
        el.classList.add('dimensions-table-dragging');
      });
      drake.on('dragend', (el: HTMLTableRowElement) => {
        el.classList.remove('dimensions-table-dragging');
      });

      drake.on('drop', onDrop);
    }

    for (const dim of config.dimensions().slice(0, visibleItemsLimit())) {
      const tr = document.createElement('tr');
      tr.setAttribute('dim-id', dim.id());

      if (canReorder) {
        const td = document.createElement('td');
        const div = document.createElement('div');
        const up = document.createElement('i');
        const down = document.createElement('i');

        div.className = 'dimensions-table-move';
        up.className = 'material-icons';
        up.textContent = 'keyboard_arrow_up';
        down.className = 'material-icons';
        down.textContent = 'keyboard_arrow_down';

        div.appendChild(up);
        div.appendChild(down);
        td.appendChild(div);
        tr.appendChild(td);

        up.onclick = () => delegate.onMoveUp(dim);
        down.onclick = () => delegate.onMoveDown(dim);
      }

      if (enableDragableReorder) {
        const td = document.createElement('td');
        const div = document.createElement('div');
        const handle = document.createElement('i');
        handle.className = 'dimensions-table-drag-handle material-icons';
        handle.textContent = 'drag_indicator';
        div.appendChild(handle);
        td.appendChild(div);
        tr.appendChild(td);
      }

      if (enableOrderNumber) {
        const td = document.createElement('td');
        td.className = 'dimensions-table-position';
        td.textContent = (config.dimensions().indexOf(dim) + 1).toString();
        tr.appendChild(td);
      }

      if (config.allowViewLimitToDimensionNameAndAttributes ?? true) {
        const name = document.createElement('td');
        name.textContent = translate(dim.nameJson());
        tr.appendChild(name);
      }

      if(session.tenant() && session.tenant().name_override_enabled && tbl.config().trialWizard?.editMode == 'library' && !tbl.config().multipleSubjectsOptionEnabled) {
        const nameOverride = document.createElement('td');
        nameOverride.textContent = translate(dim.nameOverride());
        tr.appendChild(nameOverride);
      }

      if (config.allowAnonymize) {
        const code = document.createElement('td');
        code.textContent = dim.anonymizedCode();
        tr.appendChild(code);
      }

      if(config.allowIncludeInFactorialCombinations) {
        const includeInFactorialCombinations = document.createElement('td');
        const includeInFactorialCombinationsCheckbox = checkbox((value) => delegate.onIncludeInFactorialCombinations(dim, value));
        includeInFactorialCombinationsCheckbox.update(dim.includeInFactorialCombinations(), allowEdit);

        includeInFactorialCombinations.appendChild(includeInFactorialCombinationsCheckbox.root);
        tr.appendChild(includeInFactorialCombinations);
      }

      if (config.allowEditControl) {
        const td = document.createElement('td');
        const control = checkbox((value) => delegate.onControl(dim, value));
        control.update(dim.control(), allowEditAny);

        td.appendChild(control.root);
        tr.appendChild(td);
      }

      if (allowEditDisable) {
        const td = document.createElement('td');
        const disabled = checkbox((value) => delegate.onDisable(dim, value));
        disabled.update(dim.disabled(), allowEditAny);

        td.appendChild(disabled.root);
        tr.appendChild(td);
      }

      if (allowEditOptional) {
        const td = document.createElement('td');
        const optional = checkbox((value) => delegate.onOptional(dim, value));
        optional.update(dim.optional(), allowEditAny);

        td.appendChild(optional.root);
        tr.appendChild(td);
      }

      if (isSite) {
        const td = document.createElement('td');
        td.textContent = translate(dim.region()?.name_json);
        tr.appendChild(td);
      }

      if (showStage) {
        const td = document.createElement('td');
        td.textContent = translate(tbl.getStage(dim));
        tr.appendChild(td);
      }

      if (config.allowViewLimitToDimensionNameAndAttributes ?? true) {
        for (const attr of dim.attributes()) {
          const td = document.createElement('td');
          td.textContent = attr.format();
          tr.appendChild(td);
        }
      }

      const actions = document.createElement('td');
      if (canEditDm) {
        const edit = document.createElement('i');
        edit.className = 'material-icons edit-limit-to';
        edit.textContent = 'create';
        edit.onclick = () => delegate.onEditDimension(dim);
        actions.appendChild(edit);
      }
      if (canRemove) {
        const remove = document.createElement('i');
        remove.className = 'material-icons remove-limit-to';
        remove.textContent = 'delete_outline';
        remove.onclick = () => delegate.onRemoveDimension(dim);
        actions.appendChild(remove);
      }

      tr.appendChild(actions);

      root.appendChild(tr);
    }
  };

  let subscriptions: KnockoutSubscription[] = [];

  const dispose = () => {
    subscriptions.forEach((sub) => sub.dispose());
    subscriptions = [];
  };

  return {
    root,
    updateConfig: (tbl: DimensionsTable) => {
      dispose();

      const config = tbl.config();
      const allowEditAny = ko.pureComputed(() => config.allowEditAny());
      const allowEdit = ko.pureComputed(() => config.allowEdit());
      subscriptions = [
        tbl.isSite,
        tbl.showStage,
        tbl.canEditDimensionMeta,
        allowEditAny,
        config.allowEditDisable,
        config.dimensions,
        allowEdit,
        config.country,
        tbl.allStages,
        config.date,
      ]
        .map((obs) => (obs as any)?.subscribe?.(() => update(tbl)))
        .filter((x) => !!x);

      update(tbl);
    },
    update,
    dispose,
  };
}

function checkbox(delegate: (value: boolean) => void) {
  const id = uuid4();
  const container = document.createElement('div');
  const input = document.createElement('input');
  const label = document.createElement('label');

  container.className = 'checkbox-container';
  input.id = id;
  input.type = 'checkbox';
  input.className = 'filled-in';
  label.htmlFor = id;

  container.appendChild(input);
  container.appendChild(label);

  input.onchange = () => delegate(input.checked);

  return {
    root: container,
    update: (value: boolean, enabled: boolean) => {
      input.checked = value;
      input.disabled = !enabled;
    },
  };
}
