import { request } from './request';

export interface CustomReportData {
  id: number;
  name: string;
  description: string;
  iframe_url: string;
  trial: number | null;
}

export type ErrorResponse = { [field: string]: string[] } 

export type QueryParams = {
  trial?: string | null;
}

export function retrieveCustomReports(queryParams: QueryParams): Promise<CustomReportData[]> {
  let url = '/api/v2/custom-reports/';
  if(queryParams) {
    url = url + '?' + new URLSearchParams(queryParams).toString();
  }

  return request('GET', url, {}, { injectTenant: true, version: 2 });
}

export function deleteCustomReport(id: number): Promise<void> {
  return request('DELETE', `/api/v2/custom-reports/${id}/`, {}, { injectTenant: true, version: 2 });
}

export function createCustomReport(data: CustomReportData): Promise<CustomReportData | ErrorResponse> {
  return request('POST', '/api/v2/custom-reports/', data, { injectTenant: true, version: 2, skipErrorResponseHandling: true });
}
