import * as ko from 'knockout';
import { ReleaseNotesData, releaseNotesList } from '../api/release_notes';

let template = require('raw-loader!../../templates/release_notes.html').default;

class ReleaseNotesScreen {
  loading = ko.observable(true);
  newPageIsLoading = ko.observable(true);

  items = ko.observableArray<ReleaseNotesData>([]);

  limit = 10;
  offset = 0;
  hasNextPage: boolean = true;

  constructor() {
    this.fetch();
    this.attachScroller();
  }

  fetch() {
    if (this.hasNextPage) {
      releaseNotesList(this.limit, this.offset).then((response) => {
        this.items.push(...response.results);
        this.hasNextPage = !!response.next;
        this.offset += this.limit;
        this.loading(false);
        this.newPageIsLoading(false);
      });
    } else {
      this.newPageIsLoading(false);
    }
  }

  attachScroller() {
    const content = document.querySelector('.release-notes .list') as HTMLElement;
    content.addEventListener('scroll', () => this.scroller(), false);
  }

  scroller() {
    const content = document.querySelector('.release-notes .list') as HTMLElement;

    if (!this.newPageIsLoading() && content.scrollTop > content.scrollHeight - content.offsetHeight - 100) {
      this.newPageIsLoading(true);
      this.fetch();
    }
  }
}

export let releaseNotes = { name: 'release-notes', viewModel: ReleaseNotesScreen, template: template };

ko.components.register(releaseNotes.name, releaseNotes);
