import * as ko from 'knockout';

let template = require('raw-loader!../../templates/components/more_dropdown_menu.html').default;

type OptionAction = () => void;

export interface Option {
  label: string;
  action: OptionAction;
  disabled?: boolean;
}

export type Options = Option[];

class MoreDropdownMenu {
  isOpen = ko.observable(false);
  label = ko.observable<string>('');
  icon = ko.observable<string>('more_vert');
  menuOptions: ko.ObservableArray<Option>;

  constructor(params: { options: any, label?: string, iconName?: string }) {
    this.menuOptions = params.options
    if(params.label) {
      this.label(params.label)
    }

    if(params.iconName) {
      this.icon(params.iconName)
    }
  }

  getClickAction(optionAction: OptionAction) {
    return () => {
        this.isOpen(false);
        optionAction();
    }
  }

  openMenu = () => {
    this.isOpen(true);
  }

  closeMenu = () => {
    this.isOpen(false);
  }
}
ko.components.register('more-dropdown-menu', { viewModel: MoreDropdownMenu, template: template });
