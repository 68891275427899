import { request, requestWithValidation, ValidationResult } from './request';

export interface AutoChartOptionRefData {
  measurement_meta_id: string;
  chart_type: string;
}

export interface AutoChartOptionData {
  measurement_meta_id: string;
  chart_type: string;
  threshold: string | number;
}

export function getAutoChartOption(ref: AutoChartOptionRefData): Promise<AutoChartOptionData> {
  return request('POST', '/api/auto_chart_options/get/', ref);
}

export function saveAutoChartOption(data: AutoChartOptionData): Promise<ValidationResult> {
  return requestWithValidation('POST', '/api/auto_chart_options/save/', data);
}
