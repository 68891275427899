import * as ko from 'knockout';
import { parseDate, serializeDate } from '../../api/serialization';
import { app } from '../../app';
import i18n from '../../i18n';

import { Deferred } from '../../utils/deferred';
import { createWithComponent } from '../../utils/ko_utils';
import { DataEntryVisitData, saveVisitDate } from '../data_entry_api';

const template = require('raw-loader!./data_entry_edit_visit_date.html').default;

class DataEntryEditVisitDate {
  private trialId: string;
  private visit: DataEntryVisitData;
  private result: Deferred<void>;

  value = ko.observable<Date>(null).extend({ required: true });
  saving = ko.observable(false);

  constructor(params: { trialId: string; visit: DataEntryVisitData; result: Deferred<void> }) {
    this.trialId = params.trialId;
    this.visit = params.visit;
    this.result = params.result;

    this.value(parseDate(this.visit.visit_date || this.visit.scheduled_date));
  }

  cancel = () => this.result.resolve();

  save = async () => {
    if (!this.value.isValid()) {
      return;
    }

    this.saving(true);
    try {
      await saveVisitDate({
        trial_id: this.trialId,
        sv_id: this.visit.sv_id,
        site_id: this.visit.site_id,
        visit_id: this.visit.id,
        date: serializeDate(this.value()),
      });
      this.result.resolve();
    } finally {
      this.saving(false);
    }
  };
}

const dataEntryEditVisitDate = {
  name: 'data-entry-edit-visit-date',
  viewModel: createWithComponent(DataEntryEditVisitDate),
  template,
};

ko.components.register(dataEntryEditVisitDate.name, dataEntryEditVisitDate);

export function openDataEntryEditVisitDate(trialId: string, visit: DataEntryVisitData): Promise<void> {
  return app.formsStackController.push({
    title: i18n.t('Set visit date')(),
    name: dataEntryEditVisitDate.name,
    params: {
      trialId,
      visit,
      result: new Deferred<void>(),
    },
  });
}
