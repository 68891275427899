/**
 * Knockout binding for setting the indeterminate value of a checkbox.
 */
import * as ko from 'knockout';

ko.bindingHandlers['indeterminateValue'] = {
  update: function (element, valueAccessor) {
    const value = ko.utils.unwrapObservable(valueAccessor());
    element.indeterminate = value;
  },
};
