export function diffList<R, T, U extends { root: Element }>(
  parent: Element,
  { create, update }: { create: () => U; update: (view: U, item: T, params: R) => void }
): (items: T[], params: R) => void {
  let status = new Map<T, U>();

  return (items: T[], params: R) => {
    const prevStatus = status;
    status = new Map();

    // keep the nodes added by dragula inside the container
    let transit: Element[] = [];
    const nChildren = parent.children.length;
    for (let i = 0; i < nChildren; i++) {
      const child = parent.children[i];
      if (child.classList.contains('gu-transit')) {
        transit.push(child);
      }
    }

    parent.innerHTML = '';

    let seenElems = new Set<Element>();
    for (let item of items) {
      const view = prevStatus.get(item) || create();
      status.set(item, view);
      update(view, item, params);

      parent.appendChild(view.root);
      seenElems.add(view.root);
    }
    for (let node of transit) {
      // check that the transit node wasn't also one of the views
      if (!seenElems.has(node)) {
        parent.appendChild(node);
      }
    }
  };
}

export interface RenderedView<T> {
  root: Element;
  update: (x: T) => void;
}

type View<T> = () => RenderedView<T>;

/**
 * Creates a dynamic view that can switch between different subviews based on the
 * result of a test function.
 *
 * @param {Element} container - The HTML element that will contain the view.
 * @param {(x: T) => string} test - A function that takes a value of type T and
 * returns a string. This function is used to determine which view to display.
 * @param {{ [key: string]: View<T> }} views - An object where the keys are strings
 * and the values are functions that return a View<T>. The string returned by the
 * test function is used to select the view from this object.
 *
 * @returns {{ root: Element, update: (x: T) => void }} - 'root' is the container
 * element. 'update' is a function that takes a value of type T, determines which
 * view to display using the test function, and updates the view.
 */
export function choose<T>(container: Element, test: (x: T) => string, views: { [key: string]: View<T> }) {
  let prev: string = null;
  let view: RenderedView<T> = null;

  return {
    root: container,
    update: (x: T) => {
      let choice = test(x);
      if (!view || choice !== prev) {
        view = views[test(x)]();
        prev = choice;

        container.innerHTML = '';
        container.appendChild(view.root);
      }
      view.update(x);
    },
  };
}

export function indexInParent(element: Element): number {
  return Array.prototype.indexOf.call(element.parentElement.children, element);
}

export function removeIfChild(parent: Element, toRemove: Element) {
  if (toRemove.parentElement === parent) {
    parent.removeChild(toRemove);
  }
}
