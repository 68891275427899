import * as ko from 'knockout';

import { DimensionMetaData, CV_STAGE_SLUG, TRIAL_TYPE_SLUG } from '../api/dimension_metas';
import { AttributeMeta, makeDynamicAttribute } from './attribute_meta';
import { OrderedEntities } from './helpers/ordered_entities';
import { slugValidation, SlugGenerator } from '../ko_bindings/slug_validation';
import { I18nText } from '../i18n_text';
import { FilterDelegate, convertToParameter, updateFilterValue } from '../components/list_filters';

/**
 * A map of filter attribute name to filter value.
 *
 * @example { attr_1: "apple", attr_2: "oran" }
 */
export type AttributeFilters = Record<string, string | string[]>;

export class DimensionMeta {
  private slugGenerator: SlugGenerator;
  private attributeFilterToSlug: Map<FilterDelegate, string>;

  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  isDate = ko.observable<boolean>(false);
  slug = ko.observable<string>(null);
  nameSlug = ko.observable('').extend(slugValidation);
  nameTemplate = ko.observable('');
  attributeMetas = ko.observableArray<AttributeMeta>();

  orderedAttributes = new OrderedEntities(this.attributeMetas);

  editUrl = ko.pureComputed(() => {
    return '/dimensions/' + this.id() + '/';
  });

  recordsUrl = ko.pureComputed(() => {
    if (this.slug() === CV_STAGE_SLUG) {
      return '/stages/';
    } else if (this.slug() === TRIAL_TYPE_SLUG) {
      return '/trial_types/';
    }

    return '/dimensions/' + this.id() + '/elements/';
  });

  canViewAndEditRecords = ko.pureComputed(() => {
    return !this.isDate();
  });

  canRemove = ko.pureComputed(() => {
    return !this.slug();
  });

  constructor(data?: DimensionMetaData) {
    this.initFromData(data);
  }

  initFromData(data?: DimensionMetaData) {
    if (data) {
      this.id(data.id);
      this.isDate(data.is_date);
      this.slug(data.slug);
      this.nameJson(data.name_json);
      this.nameSlug(data.name_slug);
      this.nameTemplate(data.name_template);

      if (data.attribute_metas) {
        this.attributeMetas(data.attribute_metas.map((data) => new AttributeMeta(data)));
        this.orderedAttributes = new OrderedEntities(this.attributeMetas);
      }
    }

    this.slugGenerator = new SlugGenerator(this.nameJson, null, this.nameSlug, {
      canEdit: !this.id(),
      fillIfEmpty: true,
    });
  }

  dispose() {
    for (let attributeMeta of this.attributeMetas()) {
      attributeMeta.dispose();
    }
    this.slugGenerator.dispose();
  }

  toData(): DimensionMetaData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      slug: this.slug(),
      is_date: this.isDate(),
      name_slug: this.nameSlug(),
      name_template: this.nameTemplate(),
      attribute_metas: this.isDate()
        ? []
        : this.attributeMetas().map((attributeMeta) => attributeMeta.toData()),
    };
  }

  makeDynamicAttributes() {
    return this.attributeMetas().map((attributeMeta) => makeDynamicAttribute(attributeMeta, {}));
  }

  getAttributeFilters(initialFilterValues?: AttributeFilters): FilterDelegate[] {
    this.attributeFilterToSlug = new Map<FilterDelegate, string>();
    const newFilters: FilterDelegate[] = [];

    this.makeDynamicAttributes().forEach((attribute) => {
      const filter = attribute.asFilterDelegate();
      if (filter) {
        this.attributeFilterToSlug.set(filter, attribute.getAttrName());
        newFilters.push(filter);

        if (initialFilterValues && initialFilterValues[attribute.getAttrName()]) {
          updateFilterValue(filter, initialFilterValues[attribute.getAttrName()]);
        }
      }
    });

    return newFilters;
  }

  /**
   * Returns an object with the filter slug as key and the
   * serialized filter value as value.
   *
   * @example { attr_1: "apple", attr_2: "oran" }
   */
  getAttributeFilterValuesBySlug() {
    return Array.from(this.attributeFilterToSlug)
      .map(([filter, slug]) => convertToParameter(filter, slug))
      .reduce((allFilters, currentFilter) => ({ ...allFilters, ...currentFilter }), {});
  }
}
