import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import {
  RegistrationHistoryData,
  RegistrationFiltersData,
  registrationHistoryApi,
} from '../api/registrations';
import { RegistrationHistory } from '../models/registration_history';
import { RegistrationsFilters } from './registrations';
import { updateLocationWithQueryString } from '../utils';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/registration_histories.html').default;

class RegistrationHistoriesScreen
  implements ListLoaderDelegate<RegistrationHistoryData, RegistrationHistory>
{
  private regFilters = new RegistrationsFilters();
  newFilters = this.regFilters.filters;
  listActions: ListHeaderAction[] = [
    {
      title: i18n.t('Registrations')(),
      icon: 'card_membership',
      href: '/registrations/',
    },
  ];
  loading = ko.observable(true);

  constructor(params: { filters: RegistrationFiltersData }) {
    this.regFilters.setInitial(params.filters).then(() => {
      this.loading(false);
    });
  }

  fetch(params: ListRequestParams) {
    let data = this.regFilters.toData();
    updateLocationWithQueryString(data);
    return registrationHistoryApi.list({ ...data, ...params });
  }

  instantiate(data: RegistrationHistoryData) {
    return new RegistrationHistory(data.registration, data);
  }

  getEditUrl(entity: RegistrationHistory) {
    return '';
  }

  remove(id: string) {
    return Promise.reject(null);
  }

  canRemove(entity: RegistrationHistory) {
    return false;
  }
}

export let registrationHistories = {
  name: 'registration-histories',
  viewModel: RegistrationHistoriesScreen,
  template: template,
};

ko.components.register(registrationHistories.name, registrationHistories);
