import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import * as seasonsApi from '../api/seasons';
import { session } from '../session';
import { addChangelogListAction, addChangelogAction } from './changelog';
import { Action } from '../components/basic_widgets';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/seasons.html').default;

class SeasonsScreen implements ListLoaderDelegate<seasonsApi.SeasonData, seasonsApi.SeasonData> {
  canCreate = session.isAtLeastEditor();

  listActions = ko.observableArray<ListHeaderAction>([]);
  constructor() {
    if (this.canCreate) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/seasons/new/',
        tooltipTitle: i18n.t('Add Season')(),
      });
    }
    this.listActions.push(...addChangelogListAction('season'));
  }
  fetch(params: ListRequestParams) {
    return seasonsApi.list({ country_id: undefined, ...params });
  }

  instantiate(data: seasonsApi.SeasonData) {
    return data;
  }

  getEditUrl(data: seasonsApi.SeasonData) {
    return '/seasons/' + data.id + '/';
  }

  remove(id: string) {
    return seasonsApi.remove(id);
  }

  canRemove(entity: seasonsApi.SeasonData) {
    return session.isAtLeastEditor();
  }

  getActions(entity: seasonsApi.SeasonData): Action[] {
    return addChangelogAction('season', entity.id);
  }
}

export let seasons = {
  name: 'seasons',
  viewModel: SeasonsScreen,
  template: template,
};

ko.components.register(seasons.name, seasons);
