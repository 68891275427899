import * as ko from 'knockout';
import { sortBy } from 'lodash';
import { serializeDateTime } from '../../api/serialization';
import { app } from '../../app';
import {
  CloudStorageUploadDelegate,
  CloudStorageUpload,
  FileUploadEndpoint,
} from '../../cloud_storage_upload';
import { FileUpload, FileUploadDelegate } from '../../components/basic_widgets';
import { viewImages } from '../../components/view_image';
import { Deferred } from '../../utils/deferred';
import { createWithComponent } from '../../utils/ko_utils';
import { DataEntryEditModel, DataEntryPictureData, DataEntryPictureModel } from './data_entry_edit_model';

const template = require('raw-loader!./data_entry_edit_pictures.html').default;

class DataEntryEditPictures implements FileUploadDelegate, CloudStorageUploadDelegate {
  canReuseEndpoint = false;

  private cloudUpload = new CloudStorageUpload(this);
  fileUploadError = this.cloudUpload.fileUploadError;

  loading = ko.observable(true);
  saving = ko.observable(false);
  plotName = '';
  plotDetails = '';
  pictures = ko.observableArray<DataEntryPicture>();
  uploadButtonLabelText: string;

  title: string;
  private model: DataEntryEditModel;
  private pictureModel: DataEntryPictureModel;
  private result: Deferred<void>;
  private selectedMeasurementMetaId: number;

  constructor(params: {
    title: string;
    model: DataEntryEditModel;
    pictureModel: DataEntryPictureModel;
    result: Deferred<void>;
    selectedMeasurementMetaId: number;
    uploadButtonLabelText: string;
  }) {
    this.title = params.title;
    this.model = params.model;
    this.pictureModel = params.pictureModel;
    this.result = params.result;
    this.selectedMeasurementMetaId = params.selectedMeasurementMetaId;
    this.uploadButtonLabelText = params.uploadButtonLabelText;

    this.plotName = this.model.selectedPlotName();
    this.plotDetails = this.model.selectedPlotDimNames();

    this.pictureModel.pictures(this.model).then((pics) => {
      // The received data is sorted, so that comment-only entries are the first ones and then the ones with the images
      this.pictures(
        sortBy(pics.filter(e => e.measurement_meta_id == null || e.measurement_meta_id == this.selectedMeasurementMetaId), ['file_url', 'created_timestamp']).map((e) => new DataEntryPicture(this.pictureModel, e, this.selectedMeasurementMetaId)),
      );
      this.loading(false);
    });
  }

  acceptMimeType() {
    return this.pictureModel.acceptMimeType;
  }

  canDelete(data: DataEntryPicture): boolean {
    return this.pictureModel.canDelete(this.pictures.indexOf(data));
  }

  canEditPicture(): boolean {
    return this.pictureModel.canEditPicture();
  }

  onDelete = (data: DataEntryPicture) => {
    this.pictures.remove(data);
  };

  cancel = () => this.result.resolve();

  save = async () => {
    this.saving(true);

    try {
      await this.pictureModel.save(
        this.model,
        this.pictures().map((e) => e.toData()).filter(extra => extra.comment || extra.file_url
        ));
      this.result.resolve();
    } finally {
      this.saving(false);
    }
  };

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest
  ) => {
    return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
  };

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return this.pictureModel.getUploadEndpoint(contentType);
  }

  onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
    const pic = new DataEntryPicture(this.pictureModel);
    pic.onFileUploaded(userFileName, fileName, publicURL, contentType);
    pic.measurementMetaId(this.selectedMeasurementMetaId);
    this.pictures.push(pic);
  }
}

const dataEntryEditPictures = {
  name: 'data-entry-edit-pictures',
  viewModel: createWithComponent(DataEntryEditPictures),
  template,
};

ko.components.register(dataEntryEditPictures.name, dataEntryEditPictures);

class DataEntryPicture implements FileUploadDelegate, CloudStorageUploadDelegate {
  canReuseEndpoint = false;

  private cloudUpload = new CloudStorageUpload(this);
  fileUploadError = this.cloudUpload.fileUploadError;

  uploadController = new FileUpload({
    icon: '',
    accept: '*/*',
    delegate: this,
  });

  isFileMenuOpen = ko.observable(false);

  id: string = null;
  private fileName = '';
  userFileName = ko.observable('');
  mimeType = ko.observable('');
  fileUrl = ko.observable('');
  comment = ko.observable('');
  measurementMetaId = ko.observable<number | null>(null)
  private created: string;

  constructor(private pictureModel: DataEntryPictureModel, data?: DataEntryPictureData, measurementMetaId?: number) {
    if (data) {
      this.id = data.id;
      this.fileName = data.file_name;
      this.userFileName(data.user_file_name);
      this.mimeType(data.mime_type);
      this.fileUrl(data.file_url);
      this.comment(data.comment);
      this.created = data.created;
      this.measurementMetaId(data.id == null ? measurementMetaId : data.measurement_meta_id);
    } else {
      this.created = serializeDateTime(new Date());
    }
  }

  isImage = ko.pureComputed(() => this.mimeType().indexOf('image/') === 0);

  toData(): DataEntryPictureData {
    return {
      id: this.id,
      file_name: this.fileName,
      user_file_name: this.userFileName(),
      mime_type: this.mimeType(),
      file_url: this.fileUrl(),
      comment: this.comment(),
      created: this.created,
      measurement_meta_id: this.measurementMetaId(),
    };
  }

  showImage = () => {
    if (this.fileUrl() && this.isImage()) {
      viewImages([this.fileUrl()]);
    }
  };

  onOpenFileMenu = () => this.isFileMenuOpen(true);
  onCloseFileMenu = () => this.isFileMenuOpen(false);

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest
  ) => {
    this.isFileMenuOpen(false);
    return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
  };

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return this.pictureModel.getUploadEndpoint(contentType);
  }

  onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
    this.fileName = fileName;
    this.userFileName(userFileName);
    this.fileUrl(publicURL);
    this.mimeType(contentType);
  }
}

export function openDataEntryEditPictures(
  title: string,
  model: DataEntryEditModel,
  pictureModel: DataEntryPictureModel,
  selectedMeasurementMetaId: number,
  uploadButtonLabelText: string,
): Promise<void> {
  return app.formsStackController.push({
    title,
    name: dataEntryEditPictures.name,
    params: {
      title,
      model,
      pictureModel,
      selectedMeasurementMetaId,
      uploadButtonLabelText,
      result: new Deferred<void>(),
    },
  });
}
