import * as ko from 'knockout';

import * as dashboardApi from '../api/dashboard';
import { app } from '../app';
import i18n from '../i18n';
import { Deferred } from '../utils/deferred';

const template = require('raw-loader!../../templates/components/auto_chart_details_dialog.html').default;

class AutoChartDetailsDialog {
  details: string[] = [];
  private result: Deferred<{}>;

  constructor(params: { data: dashboardApi.ChartData; result: Deferred<{}> }) {
    this.result = params.result;

    const data = params.data;
    if (
      dashboardApi.isBoxPlotData(data) ||
      dashboardApi.isAverageData(data) ||
      dashboardApi.isBarData(data)
    ) {
      this.details = [
        i18n.t('Data points')() + ': ' + data.n_values.toString(),
        i18n.t('Group by')() + ': ' + data.names.join(', '),
      ];
      for (const subgroupRow of data.subgroup_counts) {
        this.details.push(
          subgroupRow.dm_name + ': ' + subgroupRow.dims.map((d) => `${d[0]}: ${d[1]}`).join(', ')
        );
      }
    }
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

ko.components.register('auto-chart-details-dialog', {
  viewModel: AutoChartDetailsDialog,
  template,
});

export function autoChartDetailsDialog(data: dashboardApi.ChartData): Promise<{}> {
  return app.formsStackController.push({
    title: i18n.t('Details')(),
    name: 'auto-chart-details-dialog',
    showNav: true,
    params: { data, result: new Deferred<{}>() },
  });
}
