import * as ko from 'knockout';
import { ScheduledVisit } from '../models/trial';

const template = require('raw-loader!../../templates/components/scheduled_visit_edit_base.html').default;

class ScheduledVisitEditBase {
  enable: KnockoutSubscribable<boolean>;
  scheduledVisit: ScheduledVisit;
  fromTrialWizard: KnockoutSubscribable<boolean>;

  constructor(params: {
    enable: KnockoutSubscribable<boolean>;
    scheduledVisit: ScheduledVisit;
    fromTrialWizard: KnockoutSubscribable<boolean>;
  }) {
    this.enable = params.enable;
    this.scheduledVisit = params.scheduledVisit;
    this.fromTrialWizard = params.fromTrialWizard;
  }
}

const scheduledVisitEditBase = {
  name: 'scheduled-visit-edit-base',
  viewModel: ScheduledVisitEditBase,
  template,
};

ko.components.register(scheduledVisitEditBase.name, scheduledVisitEditBase);
