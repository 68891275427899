import * as ko from 'knockout';

import { Chart } from 'chart.js';
import i18n from '../i18n';

export interface SpiderChartConfig {
  testNames: string[];
  datasets: SpiderChartDataset[];
  aspectRatio?: number;
}

export interface SpiderChartDataset {
  label: string;
  values: number[]; // one per testNames item
  unscaledValues: number[]; // one per testNames item
  controlValue: number;
  unscaledControlValue: number;
  targetValue: number;
  unscaledTargetValue: number;
}

function truncateLabel(label: string): string {
  const maxLen = 15;

  if (label.length > maxLen) {
    return `${label.substring(0, maxLen)}…`;
  } else {
    return label;
  }
}

const colors = ['#1f77b4', '#ff7f0e', '#9467bd'];

function setup(element: Element, config: SpiderChartConfig) {
  if (!config) {
    return;
  }
  return new Chart(<HTMLCanvasElement>element, {
    type: 'radar',
    data: {
      labels: config.datasets.map((ds) => truncateLabel(ds.label)),
      datasets: config.testNames
        .map((name, idx) => ({
          label: name,
          backgroundColor: 'transparent',
          borderColor: colors[idx % colors.length],
          pointBackgroundColor: colors[idx % colors.length],
          spanGaps: false,
          data: config.datasets.map((ds) => ds.values[idx]),
        }))
        .concat([
          {
            label: i18n.t('Control')(),
            backgroundColor: 'transparent',
            borderColor: '#757575',
            pointBackgroundColor: '#757575',
            spanGaps: false,
            data: config.datasets.map((ds) => ds.controlValue),
          },
          {
            label: i18n.t('Target')(),
            backgroundColor: 'transparent',
            borderColor: '#4caf50',
            pointBackgroundColor: '#4caf50',
            spanGaps: false,
            data: config.datasets.map((ds) => ds.targetValue),
          },
        ]),
    },
    options: {
      plugins: {
        legend: { display: true },
        tooltip: {
          callbacks: {
            title: (items) => {
              return config.datasets[items[0].dataIndex].label;
            },
            label: (item) => {
              const data = config.datasets[item.dataIndex];
              const value =
                item.datasetIndex < config.testNames.length
                  ? data.unscaledValues[item.datasetIndex]
                  : item.datasetIndex === config.testNames.length
                  ? data.unscaledControlValue
                  : data.unscaledTargetValue;
              if (value == null) {
                return 'N/A';
              }
              return value.toLocaleString();
            },
          },
        },
      },
      scales: {
        r: {
          min: 0,
          max: 1,
          ticks: {
            display: false,
            stepSize: 1 / 3,
            maxTicksLimit: 4,
          },
          grid: {
            circular: true,
          },
        },
      },
      aspectRatio: config.aspectRatio ?? 2,
    },
  });
}

ko.bindingHandlers['spiderChart'] = {
  init: (element: Element, valueAccessor: () => KnockoutObservable<SpiderChartConfig>) => {
    renderGraph(element, valueAccessor);
  },
  update: (element: Element, valueAccessor: () => KnockoutObservable<SpiderChartConfig>) => {
    renderGraph(element, valueAccessor);
  },
};

function renderGraph(element: Element, valueAccessor: () => KnockoutObservable<SpiderChartConfig>) {
  if ((element as any)['chart']) {
    (element as any)['chart'].destroy();
  }
  const chart = setup(element, ko.unwrap(valueAccessor()));
  (element as any)['chart'] = chart;
}
