import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import i18n from '../i18n';
import { pollTask } from '../api/tasks';
import { TrialDocumentFilters } from '../api/trial_documents';
import * as trialDocumentsApi from '../api/trial_documents';

const template = require('raw-loader!../../templates/components/trial_documents_download.html').default;

class TrialDocumentsDownload {
  private result: Deferred<{}>;
  private pollToken: number;

  progressText = ko.observable('');
  archiveUrl = ko.observable('');
  error = ko.observable('');

  constructor(params: { trialId: string; filters: TrialDocumentFilters; result: Deferred<{}> }) {
    this.result = params.result;

    this.archive(params.trialId, params.filters);
  }

  dispose() {
    if (this.pollToken) {
      clearInterval(this.pollToken);
      this.pollToken = null;
    }
  }

  onClose = () => {
    this.result.resolve(null);
  };

  private async archive(trialId: string, filters: TrialDocumentFilters) {
    this.progressText(this.makeProgressText(1));
    this.archiveUrl('');
    this.error('');

    const { task_id } = await trialDocumentsApi.downloadArchive(trialId, filters);
    this.pollToken = window.setInterval(() => this.poll(task_id), 2000);
  }

  private makeProgressText(perc: number) {
    return i18n.t('Preparing archive ({{perc}}%)...', { perc })();
  }

  private async poll(taskId: string) {
    const res = await pollTask(taskId);
    if (!res) {
      // network/server error while polling
      return;
    }
    if (res.status !== 'task_done') {
      this.progressText(this.makeProgressText(res.completion_perc));
      return;
    }

    this.progressText('');
    clearInterval(this.pollToken);
    this.pollToken = null;
    if (res.error_msg) {
      this.error(res.error_msg);
    } else {
      this.archiveUrl(res.download_url);
    }
  }
}

export let trialDocumentsDownload = {
  name: 'trial-documents-download',
  viewModel: TrialDocumentsDownload,
  template,
};

ko.components.register(trialDocumentsDownload.name, trialDocumentsDownload);
