import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { NameI18n } from '../models/name';
import * as namesApi from '../api/names';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { asI18nText } from '../i18n_text';

let template = require('raw-loader!../../templates/name_edit.html').default;

class NameEditScreen extends BaseForm<namesApi.NameI18nData> {
  private baseName: string;
  public title: string;
  public createTitle: string;
  public editTitle: string;
  entity = ko.observable<NameI18n>(null);

  constructor(
    params: {
      baseName: string;
      title: string;
      createTitle: string;
      editTitle: string;
      id: string;
      initialName: string;
      result?: Deferred<namesApi.NameI18nData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    this.baseName = params.baseName;
    this.title = params.title;
    this.createTitle = params.createTitle;
    this.editTitle = params.editTitle;

    let request = params.id ? namesApi.retrieve(params.baseName, params.id) : undefined;
    let promise = Promise.all([request]).then(([nameData]) => {
      this.entity(new NameI18n(params.baseName, nameData));

      if (!nameData && params.initialName) {
        this.entity().nameJson(asI18nText(params.initialName));
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (!this.entity()) {
      return;
    }

    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(namesApi.save(this.baseName, data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let nameEdit = {
  name: 'name-edit',
  viewModel: createWithComponent(NameEditScreen),
  template: template,
};

ko.components.register(nameEdit.name, nameEdit);
