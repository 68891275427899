import { request, makeDefaultApi, requestRaw, listParamsToQueryString } from './request';
import { BaseGroupData } from './base_groups';
import { CountryData } from './countries';
import { DatasetFactSummaryData } from './datasets';
import { UserLogMixinData } from './changelog';
import { requestWithStatus } from './base_request';

export type UserRole =
  | 'staff'
  | 'head'
  | 'editor'
  | 'viewer'
  | 'admin'
  | 'read_only_admin'
  | 'editor'
  | 'manager'
  | 'restricted_manager'
  | 'template_editor'
  | 'data_entry'
  | 'partner';

export interface UserData extends UserLogMixinData {
  id?: string;
  name: string;
  email: string;
  role: UserRole;
  country: CountryData;
  data_entry_permissions_summary?: 'all' | 'some' | 'some_restricted' | 'none';
  last_sync_time?: string;
  last_sync_upload_time?: string;
  last_sync_os?: string;
  last_sync_app_version?: string;
  is_active?: boolean;
}

export interface UserDetailData extends UserData {
  groups: BaseGroupData[];
  country_roles?: CountryUserData[]; // read-only
  country_roles_write: CountryUserData[]; // write-only
  send_invite?: boolean; // write-only
  password?: string; // write-only
}

export interface CountryUserData {
  country: CountryData;
  role: UserRole;
}

export function isUserDetailData(arg: UserData | UserDetailData): arg is UserDetailData {
  return (<UserDetailData>arg).groups !== undefined || !!(<UserDetailData>arg).country_roles;
}

export const { list, retrieve, save, remove } = makeDefaultApi<
  UserData,
  {
    roles?: string[];
    countries?: string[];
    ids?: string[];
    include_gate?: boolean;
    for_edit?: boolean;
  },
  UserDetailData
>('users');

export function makeRole(id: string, role: string): Promise<{}> {
  return request('POST', '/api/users/' + id + '/make_role/', { role });
}

export function me(): Promise<UserData> {
  return request('GET', '/api/users/me/');
}

export function resendInvite(userId: string): Promise<{}> {
  return request('POST', '/api/users/' + userId + '/resend_invite/');
}

export async function changePassword(
  firebaseTenantId: string,
  resetCode: string,
  newPassword: string
): Promise<{ isValid: boolean; errors: { [field: string]: [string] } }> {
  const response = await requestWithStatus<{}>('POST', `/api/password/change_password/`, undefined, {
    firebase_tenant_id: firebaseTenantId,
    reset_code: resetCode,
    new_password: newPassword,
  });
  if (response.status === 200) {
    return { isValid: true, errors: {} };
  } else {
    return { isValid: false, errors: response.result };
  }
}

export interface UserMapFactSummaryParams {
  from_date: string;
  to_date: string;
  trial_id: string;
}

export function listMapFactSummaryData(
  userId: string,
  params: UserMapFactSummaryParams
): Promise<DatasetFactSummaryData[]> {
  return request('POST', '/api/users/' + userId + '/map_fact_summary/', params);
}

export function exportUsers(params: Record<string, unknown>): Promise<Blob> {
  return requestRaw('GET', '/api/users/export/?' + listParamsToQueryString(params as {}));
}