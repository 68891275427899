import * as ko from 'knockout';

import { I18nText } from '../i18n_text';
import { CountryData } from '../api/countries';
import { getCountrySearchConfig } from '../components/configs/search_configs';
import { RegistrationRegionData } from '../api/registration_regions';

export class RegistrationRegion {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  countries = ko.observableArray<CountryData>(null);

  countriesSearch = getCountrySearchConfig(this.countries);

  constructor(data?: RegistrationRegionData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.countries(data.countries);
    }
  }

  toData(): RegistrationRegionData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      countries: this.countries(),
    };
  }
}
