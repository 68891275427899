import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { Project } from '../models/project';
import { session } from '../session';
import { ProjectData, projectApi } from '../api/projects';
import { addChangelogListAction, addChangelogAction } from './changelog';
import { Action } from '../components/basic_widgets';

let template = require('raw-loader!../../templates/projects.html').default;

class ProjectsScreen implements ListLoaderDelegate<ProjectData, Project> {
  canCreate = session.isAtLeastEditor();

  listActions = addChangelogListAction('project');

  fetch(params: ListRequestParams) {
    return projectApi.list(params);
  }

  instantiate(data: ProjectData) {
    return new Project(data);
  }

  getName(entity: Project) {
    return entity.name();
  }

  getEditUrl(entity: Project) {
    return entity.editUrl;
  }

  remove(id: string) {
    return projectApi.remove(id);
  }

  canRemove(entity: Project) {
    return entity.canEdit();
  }

  getActions(entity: Project): Action[] {
    return addChangelogAction('project', entity.id());
  }
}

export let projects = {
  name: 'projects',
  viewModel: ProjectsScreen,
  template: template,
};

ko.components.register(projects.name, projects);
