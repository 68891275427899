import { makeDefaultApi, requestRaw } from './request';
import { I18nText } from '../i18n_text';
import { CountryData } from './countries';

export interface RegionData {
  id?: string;
  name_json: I18nText;
  country: CountryData;
  code: string;
}

export const { list, retrieve, save, remove } = makeDefaultApi<
  RegionData,
  { country_id?: string; ids?: string[] }
>('regions');

export function downloadRegionImportTemplate(): Promise<Blob> {
  return requestRaw('GET', '/api/regions/import_template/');
}
