import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { MeasurementType } from '../models/measurement_type';
import * as measurementTypesApi from '../api/measurement_types';
import * as usersApi from '../api/users';
import { getCropSearchConfig } from '../components/configs/search_configs';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';
import { canEditMeasurementType } from '../permissions';
import { CropData } from '../api/crops';
import {
  EditMeasurementChoicesDelegate,
  applyChoicesErrors,
  validateChoices,
  uploadingChoice,
} from '../components/edit_measurement_choices';

let template = require('raw-loader!../../templates/measurement_type_edit.html').default;

class MeasurementTypeEditScreen extends BaseForm<measurementTypesApi.MeasurementTypeData> {
  measurementType = ko.observable<MeasurementType>(null);
  cropSearchConfig = ko.pureComputed(() => {
    return getCropSearchConfig(this.measurementType() ? this.measurementType().crop : undefined);
  });

  private userData: usersApi.UserData;

  editChoices: EditMeasurementChoicesDelegate;

  constructor(
    params: {
      id: string;
      initialName?: string;
      initialCrop?: CropData;
      result?: Deferred<measurementTypesApi.MeasurementTypeData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let mePromise = usersApi.me();

    if (params.id) {
      let promise = Promise.all([mePromise, measurementTypesApi.retrieve(params.id)]).then(
        ([userData, data]) => {
          this.userData = userData;
          this.measurementType(new MeasurementType(data));
          this.initEditChoices();
        }
      );
      this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
    } else {
      let promise = mePromise.then((data) => {
        this.userData = data;
        this.measurementType(new MeasurementType());
        if (params.initialName) {
          this.measurementType().nameJson(asI18nText(params.initialName));
        }
        if (params.initialCrop) {
          this.measurementType().crop(params.initialCrop);
        }
        this.initEditChoices();
      });
      this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
    }
  }

  private initEditChoices() {
    this.editChoices = {
      choices: this.measurementType().choices,
      globalError: ko.observable<string>(''),
    };
  }

  canEdit = ko.pureComputed(() => canEditMeasurementType(this.userData));
  uploading = ko.pureComputed(() => uploadingChoice(this.editChoices));

  save = () => {
    if (!this.measurementType() || !this.canEdit()) {
      return;
    }

    let choicesValid = validateChoices(this.editChoices);

    if (this.validateLocal(this.measurementType) && choicesValid) {
      let data = this.measurementType().toData();
      this.executeSaveRequest(measurementTypesApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.measurementType(), validation);

        if (!validation.isValid) {
          applyChoicesErrors(this.editChoices, validation.errors['choices']);
        }
      });
    } else {
      this.saving(false);
    }
  };
}

export let measurementTypeEdit = {
  name: 'measurement-type-edit',
  viewModel: createWithComponent(MeasurementTypeEditScreen),
  template: template,
};

ko.components.register(measurementTypeEdit.name, measurementTypeEdit);
