import * as ko from 'knockout';
import { CropData } from '../api/crops';
import { FormulaSuggestion } from '../api/measurement_metas';
import i18n from '../i18n';

import { escapeForRegex } from '../utils';
import { MaybeKO, asObservable } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/measurement_meta_edit_formula.html').default;

class MeasurementMetaEditFormula {
  measurementMeta: KnockoutObservable<{
    formula: KnockoutObservable<string>;
    unitFormula: KnockoutObservable<string>;
    crops: KnockoutObservable<CropData[]>;
  }>;
  enable: () => boolean;

  private availableSuggestions: KnockoutSubscribable<FormulaSuggestion[]>;
  suggestions = ko.observableArray<FormulaSuggestion>();

  private formulaInput: HTMLTextAreaElement;

  private subscriptions: KnockoutSubscription[] = [];

  private validateFormula: (formula: string, crops?: CropData[]) => boolean;
  validationSuccessText = ko.observable<string>(null);
  validationWarningText = ko.observable<string>(null);
  validating = ko.observable<boolean>(false);

  constructor(params: {
    measurementMeta: MaybeKO<{
      formula: KnockoutObservable<string>;
      unitFormula: KnockoutObservable<string>;
      crops: KnockoutObservable<CropData[]>;
    }>;
    enable: () => boolean;
    availableSuggestions?: KnockoutSubscribable<FormulaSuggestion[]>;
    validateFormula?: (formula: string, crops?: CropData[]) => boolean;
    validationWarningText: KnockoutObservable<string>;
  }) {
    this.measurementMeta = asObservable(params.measurementMeta);
    this.enable = params.enable;
    this.availableSuggestions = params.availableSuggestions ?? ko.observableArray();

    this.suggestions(this.availableSuggestions());
    this.subscriptions.push(this.availableSuggestions.subscribe(this.onFormulaPositionChanged));
    this.validateFormula = params.validateFormula;
    this.validationWarningText = params.validationWarningText;
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
    this.subscriptions = [];
  }

  onFormulaElement = (nodes: NodeList) => {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes.item(i);
      if (node.nodeType === Node.ELEMENT_NODE) {
        const textarea = (node as HTMLElement).querySelector('textarea');
        if (textarea) {
          this.formulaInput = textarea;
        }
      }
    }
  };

  onValidateFormula = async () => {
    this.validating(true);
    try {
      const formula = this.measurementMeta().formula();
      if (this.validateFormula) {
        const crops = this.measurementMeta().crops();
        const isValid = await this.validateFormula(formula, crops);
        if (isValid) {
          this.validationSuccessText(i18n.t('The formula is valid')());
        } else {
          this.validationSuccessText(null);
        }
      }
    } finally {
      this.validating(false);
    }
  };

  onFormulaPositionChanged = () => {
    const availableSuggestions: FormulaSuggestion[] = this.availableSuggestions();
    const [start, end] = this.getSymbolRange();

    let term = '';
    if (start < end) {
      term = this.formulaInput.value.slice(start, end);
    }

    if (!term) {
      this.suggestions(availableSuggestions);
    } else {
      let regexpString = '.*';
      for (let i = 0; i < term.length; i++) {
        regexpString += escapeForRegex(term.charAt(i)) + '.*';
      }
      const regex = new RegExp(regexpString, 'i');

      this.suggestions(
        availableSuggestions.filter((sugg) => regex.test(sugg.name) || regex.test(sugg.code))
      );
    }
    this.validationSuccessText(null);
  };

  onSuggestionSelected = (suggestion: FormulaSuggestion) => {
    const mm = this.measurementMeta();
    const [start, end] = this.getSymbolRange();
    const current = mm.formula();
    const before = current.slice(0, start);
    const after = current.slice(end, current.length);

    mm.formula(before + suggestion.code + after);
    this.formulaInput?.focus();
  };

  private getSymbolRange(): [number, number] {
    const value = this.measurementMeta().formula();

    if (!this.formulaInput) {
      return [value.length, value.length];
    }

    const idOrSpaceChar = /\s|[_a-zA-Z0-9]/;

    let start = this.formulaInput.selectionStart;
    while (start > 0 && idOrSpaceChar.test(value.charAt(start - 1))) {
      start--;
    }

    let end = this.formulaInput.selectionStart;
    while (end < value.length && idOrSpaceChar.test(value.charAt(end))) {
      end++;
    }

    const sym = value.slice(start, end);
    const startingSpaces = sym.match(/^(\s+)/)?.[1].length ?? 0;
    const endingSpaces = sym.match(/(\s+)$/)?.[1].length ?? 0;

    return [start + startingSpaces, end - endingSpaces];
  }
}

ko.components.register('measurement-meta-edit-formula', {
  viewModel: MeasurementMetaEditFormula,
  template: template,
});
