import * as ko from 'knockout';

import { BaseForm } from './base_form';
import * as mmApi from '../api/measurement_metas';
import * as datasetsApi from '../api/datasets';
import * as dimensionsApi from '../api/dimensions';
import * as usersApi from '../api/users';
import {
  MeasurementMeta,
  MeasurementMetaDimensionMeta,
  MeasurementMetaTrialLimitTo,
  unlinkMM,
} from '../models/measurement_meta';
import { UserData } from '../api/users';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { DimensionData } from '../api/dimensions';
import {
  getCropSearchConfig,
  getTraitCategorySearchConfig,
  getUnitSearchConfig,
  getClientTypeSearchConfig,
  getDriverSearchConfig,
  getScheduledVisitSearchConfig,
  getMMTagSearchConfig,
} from '../components/configs/search_configs';
import { TraitCategoryData } from '../api/trait_categories';
import { UnitData } from '../api/units';
import { makeHasError, createWithComponent } from '../utils/ko_utils';
import { session } from '../session';
import { canEditMMLibrary } from '../permissions';
import { validateChoices, applyChoicesErrors } from '../components/edit_measurement_choices';
import { ClientTypeData } from '../api/client_types';
import { DriverData } from '../api/drivers';
import { DimensionsTableConfig } from '../components/dimensions_table';
import i18n from '../i18n';
import { NameI18nData } from '../api/names';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { BoolDict } from '../utils';
import { MeasurementMetaTagData } from '../api/measurement_meta_tags';
import { TrialWizard } from '../models/trial';
import { LimitToDimension } from '../models/dataset_dimension_meta';
import { translate } from '../i18n_text';

let template = require('raw-loader!../../templates/measurement_meta_library_edit.html').default;

type AllOrPartial = 'all' | 'partial';

class MeasurementMetaLibraryEditScreen extends BaseForm<datasetsApi.MeasurementMetaData> {
  showDriver = session.tenant().tpp_enabled;
  management: boolean;
  forEdit: boolean;
  wizard: TrialWizard;
  onSaved: () => void;

  canNormalize = ko.observable(false);
  entity = ko.observable<MeasurementMeta>(null);
  clientType = ko.observable<ClientTypeData>(null);
  driver = ko.observable<DriverData>(null);
  selectedMDM = ko.observable<MeasurementMetaDimensionMeta>(null);
  selectedTrialTestSubjectLimitTo = ko.observable<MeasurementMetaTrialLimitTo>(null);
  user: UserData;

  cropSearchConfig: FormSelectSearchConfiguration<DimensionData>;
  traitCategorySearchConfig: FormSelectSearchConfiguration<TraitCategoryData>;
  traitUnitNumSearch: FormSelectSearchConfiguration<UnitData>;
  traitUnitDenSearch: FormSelectSearchConfiguration<UnitData>;
  clientTypeSearch = getClientTypeSearchConfig(this.clientType);
  driverSearch = getDriverSearchConfig(this.driver, this.clientType);
  scheduledVisitsSearch: FormSelectSearchConfiguration<NameI18nData>;
  tagsSearch: FormSelectSearchConfiguration<MeasurementMetaTagData>;

  hasTraitError: KnockoutComputed<boolean>;
  hasBaseError: KnockoutComputed<boolean>;
  scheduledVisitsError = ko.observable('');
  warningMessage = ko.observable('');

  selected = ko.observable<'trait' | 'base'>('trait');

  availableSuggestions = ko.observableArray<mmApi.FormulaSuggestion>();

  replicationLimitToDimensionOptions: LimitToDimension[] = [];

  testSubjectAllOrPartial = ko.observable<AllOrPartial>(null);
  replicationAllOrPartial = ko.observable<AllOrPartial>(null);
  siteAllOrPartial = ko.observable<AllOrPartial>(null);

  private subscriptions: KnockoutSubscription[] = [];

  constructor(
    params: {
      management: boolean;
      id: string;
      forEdit?: boolean;
      result?: Deferred<datasetsApi.MeasurementMetaData>;
      wizard?: TrialWizard;
      onSaved?: () => void;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super({ result: params.result });
    this.management = params.management;
    // forEdit and wizard is needed for change measurement from
    // trial wiard
    this.forEdit = !!params.forEdit;
    this.wizard = params.wizard;
    this.onSaved = params.onSaved ?? (() => {});

    const getReplicationLimitToDimensionOptions = async (): Promise<LimitToDimension[]> => {
      // We only have a number of repetitions, but we need the actual dimensions, so use API to fetch them.
      // Ony needed when editing in the trial wizard for limiting to certain repetitions.
      if (!this.wizard) {
        return Promise.resolve([]);
      }
      return (await dimensionsApi.list(this.wizard.replicationDM.id(), {}, {}))
        .filter((replication) => Number(translate(replication.name_json)) <= this.wizard.replications())
        .map((replicationData) => new LimitToDimension(null, this.wizard.replicationDM, replicationData));
    };

    let promise = Promise.all([
      usersApi.me(),
      mmApi.librarySuggestions(),
      getReplicationLimitToDimensionOptions(),
      params.id ? mmApi.retrieve(params.id, { getParams: { forEdit: this.forEdit } }) : undefined,
    ]).then(([user, availableSuggestions, replicationLimitToDimensionOptions, data]) => {
      if (!this.management && data && data.normalize) {
        // supported only for editing, can't create new normalized mms
        this.canNormalize(true);
      }

      this.user = user;
      this.availableSuggestions(availableSuggestions);
      this.replicationLimitToDimensionOptions = replicationLimitToDimensionOptions;
      let mm = new MeasurementMeta(
        {
          allowDimensionMeta: true,
          allowRanking: false,
          management: this.management,
          requireMeasurementType: false,
          validateLimitTo: false,
        },
        this.wizard?.trial(),
        data
      );
      if (data && this.allowLimitTrialDimensions()) {
        mm.setTrialDimensionLimitTo(data.trial_limit_to, this.wizard, replicationLimitToDimensionOptions);
      }

      if (!this.forEdit) {
        mm.order(0);
      }
      if (!this.forEdit) {
        mm.crops = mm.crops.extend({ required: true });
      }
      let traitUnitValidation = {
        required: {
          onlyIf: () => mm.normalize() && mm.calculation() === 'area_div',
        },
      };
      mm.traitUnitNum = mm.traitUnitNum.extend(traitUnitValidation);
      mm.traitUnitDen = mm.traitUnitDen.extend(traitUnitValidation);

      this.cropSearchConfig = getCropSearchConfig(mm.crops);
      this.traitCategorySearchConfig = getTraitCategorySearchConfig(
        mm.traitCategory,
        { clientType: this.clientType, driver: this.driver },
        user
      );
      this.traitUnitNumSearch = getUnitSearchConfig(mm.traitUnitNum, {
        getCategories: () => ['volume', 'weight', 'pieces'],
      });
      this.traitUnitDenSearch = getUnitSearchConfig(mm.traitUnitDen, {
        getCategories: () => ['area'],
      });
      this.scheduledVisitsSearch = getScheduledVisitSearchConfig(mm.scheduledVisits);
      this.tagsSearch = getMMTagSearchConfig(mm.tags);

      this.hasTraitError = makeHasError(
        mm.traitCategory,
        mm.nameJson,
        mm.crops,
        mm.calculation,
        mm.normalizationMM,
        mm.traitUnitNum,
        mm.traitUnitDen,
        mm.aggregation
      );
      // NOTE: can only have date/number/derived types, no need to validate fields that appear for other types
      this.hasBaseError = makeHasError(
        mm.observationName,
        mm.nameSlug,
        mm.description,
        mm.unit,
        mm.validationNumberMinValue,
        mm.validationNumberMaxValue,
        mm.validationMaxDecimals,
        mm.helpTextJson,
        mm.formula,
        mm.pictures,
        mm.validationDateMinValue,
        mm.validationDateMaxValue
      );

      this.entity(mm);
      this.selectMDM(mm.mdms()[0] || null);
      this.subscriptions.push(this.clientType.subscribe(this.onUpdateClientType));

      this.subscriptions.push(mm.traitCategory.subscribe(this.onUpdateCategory));
      this.onUpdateCategory();

      if (this.allowLimitTrialDimensions()) {
        this.testSubjectAllOrPartial(this.isAtLeastOneTrialTestSubjectLimitToSet() ? 'partial' : 'all');
        this.replicationAllOrPartial(this.isAtLeastOneReplicationLimitToSet() ? 'partial' : 'all');
        this.siteAllOrPartial(this.isAtLeastOneSiteLimitToSet() ? 'partial' : 'all');

        this.subscriptions.push(this.siteAllOrPartial.subscribe(this.onSiteAllOrPartialChange));
        this.subscriptions.push(
          this.replicationAllOrPartial.subscribe(this.onReplicationAllOrPartialChange)
        );
        this.subscriptions.push(
          this.testSubjectAllOrPartial.subscribe(this.onTestSubjectAllOrPartialChange)
        );

        this.subscriptions.push(this.entity().collectForSite.subscribe(this.onCollectForSiteChange));
        this.subscriptions.push(
          this.entity().collectForReplication.subscribe(this.onCollectForReplicationChange)
        );
        this.subscriptions.push(
          this.entity().collectForTestSubjects.subscribe(this.onCollectForTestSubjectsChange)
        );

        this.selectTrialTestSubjectLimitTo(this.entity().trialTestSubjectLimitTos()[0]);
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }

  allowEdit = () => true;
  allowEditAny = () => canEditMMLibrary();
  allowEditFilters = () => this.allowEditAny() && !this.entity()?.traitCategory();

  allowEditIncludingTrialState = () => {
    if (this.wizard && this.entity().trialId() !== null) {
      return this.wizard.trial().isDraft();
    }
    return this.allowEditAny();
  };

  allowScheduledVisitRemove = () => {
    return this.allowEditIncludingTrialState();
  };

  selectTrait = () => this.selected('trait');
  selectBase = () => this.selected('base');

  showTrait = ko.pureComputed(() => !this.entity().normalize() || this.selected() === 'trait');
  showObservation = ko.pureComputed(() => !this.entity().normalize() || this.selected() === 'base');
  allowLimitTrialDimensions = ko.pureComputed(
    () =>
      this.wizard &&
      !this.wizard.template() &&
      session.tenant() &&
      session.tenant().limit_traits_to_dimensions_enabled
  );

  save = () => {
    this.scheduledVisitsError('');

    const mm = this.entity();
    if (!mm) {
      return;
    }

    let editChoices = mm.editChoices;
    let choicesValid = !mm.needsMeasurementType() || validateChoices(editChoices);

    if (this.validateLocal(this.entity) && choicesValid) {
      let data = mm.toData();
      this.executeSaveRequest(mmApi.save(data, { getParams: { forEdit: this.forEdit } })).then(
        (validation) => {
          this.onRemoteValidation(data, mm, validation);
          if (!validation.isValid) {
            applyChoicesErrors(editChoices, validation.errors['mt_choices']);

            const svErrors: any[] = validation.errors['scheduled_visits'] || [];
            if (svErrors && svErrors.length > 0) {
              this.scheduledVisitsError(svErrors.join(' '));
            }

            const mdmsErrors: any[] = validation.errors['mdms'] || [];
            for (let i = 0; i < mm.mdms().length; i++) {
              const errors = mdmsErrors[i];
              if (errors) {
                this.applyModelServerErrors(mm.mdms()[i], errors);
              }
            }
          } else {
            this.onSaved();
          }
        }
      );
    } else {
      this.saving(false);
    }
  };

  private onUpdateClientType = (value: ClientTypeData) => {
    const driver = this.driver();
    if (driver && driver.client_type?.id !== value?.id) {
      this.driver(null);
    }
  };

  private onTestSubjectAllOrPartialChange = (value: AllOrPartial) => {
    if (value === 'all') {
      this.entity().clearTrialTestSubjectLimitTo();
    }
  };

  private onCollectForTestSubjectsChange = (value: boolean) => {
    if (!value) {
      this.entity().clearTrialTestSubjectLimitTo();
    }
  };

  private onReplicationAllOrPartialChange = (value: AllOrPartial) => {
    if (value === 'all') {
      this.entity().clearTrialReplicationLimitTo();
    }
  };

  private onCollectForReplicationChange = (value: boolean) => {
    if (!value) {
      this.entity().clearTrialTestSubjectLimitTo();
    }
  };

  private onSiteAllOrPartialChange = (value: AllOrPartial) => {
    if (value === 'all') {
      this.entity().clearTrialSiteLimitTo();
    }
  };

  private onCollectForSiteChange = (value: boolean) => {
    if (!value) {
      this.entity().clearTrialSiteLimitTo();
    }
  };

  private isAtLeastOneTrialTestSubjectLimitToSet = () => {
    return this.entity()
      .trialTestSubjectLimitTos()
      .some((testSubjectLimit) => testSubjectLimit.limitTo().length > 0);
  };

  private isAtLeastOneReplicationLimitToSet = () => {
    if (!this.entity().trialReplicationLimitTo()) {
      return false;
    }
    return this.entity().trialReplicationLimitTo().limitTo().length > 0;
  };

  private isAtLeastOneSiteLimitToSet = () => {
    if (!this.entity().trialSiteLimitTo()) {
      return false;
    }
    return this.entity().trialSiteLimitTo().limitTo().length > 0;
  };

  private onUpdateCategory = () => {
    const category = this.entity().traitCategory();

    if (category?.driver) {
      this.driver(category.driver);
      this.clientType(category.driver.client_type);
    }
  };

  addMDM = () => {
    const mdm = new MeasurementMetaDimensionMeta();
    this.entity().mdms.push(mdm);

    this.selectedMDM(mdm);
  };

  selectMDM = (mdm: MeasurementMetaDimensionMeta) => {
    mdm?.showErrors();
    this.selectedMDM(mdm);
  };

  removeMDM = (mdm: MeasurementMetaDimensionMeta) => {
    let index = this.entity().mdms().indexOf(mdm);
    this.entity().mdms.remove(mdm);
    this.selectMDM(this.entity().mdms()[Math.max(0, index - 1)] || null);
  };

  selectTrialTestSubjectLimitTo = (value: MeasurementMetaTrialLimitTo) => {
    this.selectedTrialTestSubjectLimitTo(value);
  };

  getDimensionsConfig(
    mdm: MeasurementMetaDimensionMeta,
    allowEditIncludingTrialState: boolean = true
  ): DimensionsTableConfig {
    return {
      user: this.user,

      canReorder: false,
      addDimensionText: i18n.t('Add records')(),
      dimensionMeta: mdm.dimensionMeta,
      dimensions: mdm.limitTo,
      crops: this.wizard?.trial()?.crop || this.entity().crops,

      allowAnonymize: false,
      allowEditControl: false,
      allowEditDisable: false,
      allowEditOptional: true,
      allowEdit: () => allowEditIncludingTrialState,
      allowEditAny: () => this.allowEditAny() && allowEditIncludingTrialState,

      enableEditDimensionMeta: allowEditIncludingTrialState,

      confirmChangeEntities: () => Promise.resolve(null),
    };
  }

  getTrialTestSubjectLimitToDimensionsConfig(trialTestSubjectLimitTo: MeasurementMetaTrialLimitTo) {
    let res: FormSelectSearchConfiguration<LimitToDimension> = {
      entities: trialTestSubjectLimitTo.limitTo,

      list: (params) => {
        const testSubject = this.wizard
          .testSubjects()
          .find(
            (testSubject) => testSubject.dimensionMeta().id() === trialTestSubjectLimitTo.dimensionMeta.id()
          );

        return Promise.resolve(
          testSubject
            .limitTo()
            .filter((limitTo) =>
              translate(limitTo.nameJson()).toLowerCase().includes(params.name_prefix.toLowerCase())
            )
        );
      },

      getSummaryName: (entity) => {
        return entity.nameJson();
      },
    };
    return res;
  }

  getTrialRepetitionLimitToDimensionsConfig() {
    let res: FormSelectSearchConfiguration<LimitToDimension> = {
      entities: this.entity().trialReplicationLimitTo().limitTo,

      list: (params) => {
        return Promise.resolve(
          this.replicationLimitToDimensionOptions.filter((replication) =>
            translate(replication.nameJson()).toLowerCase().includes(params.name_prefix.toLowerCase())
          )
        );
      },

      getSummaryName: (entity) => {
        return entity.nameJson();
      },
    };
    return res;
  }

  getTrialSiteLimitToDimensionsConfig() {
    let res: FormSelectSearchConfiguration<LimitToDimension> = {
      entities: this.entity().trialSiteLimitTo().limitTo,

      list: (params) => {
        return Promise.resolve(
          this.wizard
            .sites()
            .filter((site) =>
              translate(site.nameJson()).toLowerCase().includes(params.name_prefix.toLowerCase())
            )
        );
      },

      getSummaryName: (entity) => {
        return entity.nameJson();
      },
    };
    return res;
  }

  copyFrom = async () => {
    const mms: datasetsApi.MeasurementMetaData[] = await app.formsStackController.push({
      className: 'select-from-library-popup',
      isBig: true,
      title: i18n.t('Select')(),
      name: 'select-from-library',
      params: {
        template: false,
        traits: [],
        crop: null,
        trialType: null,
        mmSlugs: {},
        single: true,
        selectedTraits: [],
        prepareResult: async (mmSlugs: BoolDict, data: datasetsApi.MeasurementMetaData[]) => {
          if (data.length === 1) {
            return [await mmApi.retrieve(data[0].id)];
          } else {
            return data;
          }
        },
        result: new Deferred<datasetsApi.MeasurementMetaData[]>(),
      },
    });

    if (mms.length !== 1) {
      return;
    }

    const source = mms[0];

    unlinkMM(source, false);
    source.id = this.entity().id();
    source.name_json = this.entity().nameJson();
    source.name_slug = this.entity().nameSlug();
    source.management = this.management;
    if (this.management) {
      source.normalize = false;
    }

    this.entity().setData(source);
    this.selectMDM(this.entity().mdms()[0] ?? null);
  };

  validateFormula = async (formula: string) => {
    const validation = await mmApi.validateFormula({
      formula,
      collect_for_replication: this.entity().collectForReplication(),
      collect_for_site: this.entity().collectForSite(),
      collect_for_test_subjects: this.entity().collectForTestSubjects(),
      dimension_meta_ids: this.entity()
        .mdms()
        .map((mdm) => mdm.id),
      crops: this.entity().crops(),
      check_dependencies: false,
    });
    if (!validation.isValid) {
      this.applyModelServerErrors(this.entity(), validation.errors);
    }
    // check dependencies. All errors on this is actually a warnings;
    const warning = await mmApi.validateFormula({
      formula,
      collect_for_replication: this.entity().collectForReplication(),
      collect_for_site: this.entity().collectForSite(),
      collect_for_test_subjects: this.entity().collectForTestSubjects(),
      dimension_meta_ids: this.entity()
        .mdms()
        .map((mdm) => mdm.id),
      crops: this.entity().crops(),
      check_dependencies: true,
    });
    if (warning.errors['formula']) {
      this.warningMessage(warning.errors['formula'][0]);
    }
    return !warning.errors['formula'];
  };
}

export let measurementMetaLibraryEdit = {
  name: 'measurement-meta-library-edit',
  viewModel: createWithComponent(MeasurementMetaLibraryEditScreen),
  template: template,
};

ko.components.register(measurementMetaLibraryEdit.name, measurementMetaLibraryEdit);
