import * as ko from 'knockout';
import { UnitDetailData } from '../api/units';

import i18n from '../i18n';
import { FLOAT_VALIDATION_RULES } from '../utils';
import { canEditUnits } from '../permissions';
import { SlugGenerator } from '../ko_bindings/slug_validation';

export class Unit {
  private slugGenerator: SlugGenerator;

  id = ko.observable<string>(null);
  code = ko.observable<string>('').extend({
    required: true,
  });
  name = ko.observable('').extend({
    required: true,
  });
  description = ko.observable('').extend({
    required: true,
  });
  conversionFactor = ko.observable('').extend({
    required: true,
    serverError: true,
    ...FLOAT_VALIDATION_RULES,
  });
  conversionOffset = ko.observable('0').extend({
    required: true,
    serverError: true,
    ...FLOAT_VALIDATION_RULES,
  });
  canDelete = true;
  canEdit = canEditUnits();

  private errors = ko.validation.group(this);

  equivalenceText = ko.pureComputed(() => i18n.t('is equivalent to 1 {{ name }}', { name: this.name() })());

  replacePowerWithDigit(unitShortName: string): string {
    let powers: string = '⁰¹²³⁴⁵⁶⁷⁸⁹';
    let digits: string = '0123456789';
    for (let index = 0; index < powers.length; index++) {
      if (unitShortName.includes(powers[index])) {
        unitShortName.replace(powers[index], digits[index]);
      }
    }

    return unitShortName;
  }

  constructor(public unitCategorySlug: string, data?: UnitDetailData) {
    if (data) {
      this.id(data.id);
      this.name(data.name);
      this.code(data.code);
      this.description(data.description);
      this.conversionFactor(data.conversion_factor.toString());
      this.conversionOffset(data.conversion_offset.toString());
      this.canDelete = data.can_delete;
    }

    this.slugGenerator = new SlugGenerator(this.name, null, this.code, {
      canEdit: !this.id(),
      fillIfEmpty: true,
    });
  }

  hasErrors(): boolean {
    return (
      this.errors().length > 0 ||
      !!this.conversionFactor.serverError() ||
      !!this.conversionOffset.serverError()
    );
  }

  showErrors() {
    this.errors.showAllMessages();
  }

  toData(): UnitDetailData {
    return {
      id: this.id(),
      unit_category: this.unitCategorySlug,
      name: this.name(),
      code: this.code(),
      description: this.description(),
      conversion_factor: parseFloat(this.conversionFactor()),
      conversion_offset: parseFloat(this.conversionOffset()),
    };
  }

  dispose() {
    this.slugGenerator.dispose();
  }
}
