import { listRequest, ListRequestParams, request, requestWithValidation, ValidationResult } from './request';

export interface UnitData {
  id?: string;
  name: string;
  description: string;
  unit_category?: string; // read-only
  unit_category_name?: string; // read-only
}

interface UnitFilters {
  names?: string[];
  categories?: string[];
}

export function list(params: ListRequestParams & UnitFilters): Promise<UnitData[]> {
  return listRequest('/api/units/', params);
}

export interface TenantUnitsData {
  units: UnitDetailData[];
}

export interface UnitDetailData extends UnitData {
  unit_category: string;
  conversion_factor: number;
  conversion_offset: number;
  can_delete?: boolean; // read-only
  code?: string;
}

export function tenantUnits(): Promise<TenantUnitsData> {
  return request('GET', '/api/units/edit/');
}

export function saveTenantUnits(data: TenantUnitsData): Promise<ValidationResult> {
  return requestWithValidation('POST', '/api/units/edit/', data);
}
