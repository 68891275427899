import * as ko from 'knockout';
import i18n from '../i18n';

import { session } from '../session';
import { BaseLoadingScreen } from './base_loading_screen';
import { Coupon } from '../models/coupon';
import { ValidationResult } from '../api/request';
import * as subscriptionsApi from '../api/subscriptions';
import * as couponsApi from '../api/coupons';
import { parseDate, parseDateTime } from '../api/serialization';
import { getExpirationDays } from '../models/tenant';
import { logError } from '../error_logging';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { SUBSCRIPTION_WEATHER_INFO_COMPONENT } from '../components/subscription_weather_info';

let template = require('raw-loader!../../templates/subscription.html').default;

declare var Stripe: any;

class SubscriptionScreen extends BaseLoadingScreen {
  stripe: any;
  card: any;

  // see https://stripe.com/docs/billing/taxes/tax-ids#supported-tax-id
  countryOptions = [
    { name: i18n.t('Select'), id: '' },
    { name: i18n.t('Australia'), id: 'Australia' },
    { name: i18n.t('Austria'), id: 'Austria' },
    { name: i18n.t('Belgium'), id: 'Belgium' },
    { name: i18n.t('Brazil'), id: 'Brazil' },
    { name: i18n.t('Bulgaria'), id: 'Bulgaria' },
    { name: i18n.t('Canada'), id: 'Canada' },
    { name: i18n.t('Cyprus'), id: 'Cyprus' },
    { name: i18n.t('Croatia'), id: 'Croatia' },
    { name: i18n.t('Czech'), id: 'Czech' },
    { name: i18n.t('Denmark'), id: 'Denmark' },
    { name: i18n.t('Estonia'), id: 'Estonia' },
    { name: i18n.t('Finland'), id: 'Finland' },
    { name: i18n.t('France'), id: 'France' },
    { name: i18n.t('Germany'), id: 'Germany' },
    { name: i18n.t('Greece'), id: 'Greece' },
    { name: i18n.t('Hong Kong'), id: 'Hong Kong' },
    { name: i18n.t('Hungary'), id: 'Hungary' },
    { name: i18n.t('India'), id: 'India' },
    { name: i18n.t('Ireland'), id: 'Ireland' },
    { name: i18n.t('Italy'), id: 'Italy' },
    { name: i18n.t('Japan'), id: 'Japan' },
    { name: i18n.t('Latvia'), id: 'Latvia' },
    { name: i18n.t('Liechtenstein'), id: 'Liechtenstein' },
    { name: i18n.t('Lithuania'), id: 'Lithuania' },
    { name: i18n.t('Luxembourg'), id: 'Luxembourg' },
    { name: i18n.t('Malaysia'), id: 'Malaysia' },
    { name: i18n.t('Malta'), id: 'Malta' },
    { name: i18n.t('Mexico'), id: 'Mexico' },
    { name: i18n.t('Netherlands'), id: 'Netherlands' },
    { name: i18n.t('New Zealand'), id: 'New Zealand' },
    { name: i18n.t('Norway'), id: 'Norway' },
    { name: i18n.t('Poland'), id: 'Poland' },
    { name: i18n.t('Portugal'), id: 'Portugal' },
    { name: i18n.t('Romania'), id: 'Romania' },
    { name: i18n.t('Russia'), id: 'Russia' },
    { name: i18n.t('Singapore'), id: 'Singapore' },
    { name: i18n.t('Slovenia'), id: 'Slovenia' },
    { name: i18n.t('Slovakia'), id: 'Slovakia' },
    { name: i18n.t('South Africa'), id: 'South Africa' },
    { name: i18n.t('South Korea'), id: 'South Korea' },
    { name: i18n.t('Spain'), id: 'Spain' },
    { name: i18n.t('Sweden'), id: 'Sweden' },
    { name: i18n.t('Switzerland'), id: 'Switzerland' },
    { name: i18n.t('Taiwan'), id: 'Taiwan' },
    { name: i18n.t('Thailand'), id: 'Thailand' },
    { name: i18n.t('United Kingdom'), id: 'United Kingdom' },
    { name: i18n.t('United States'), id: 'United States' },
  ];

  titleText = i18n.t('{{ productName }} Subscription Plans', {
    productName: SERVER_INFO.PRODUCT_NAME,
  })();
  collectedText = ko.pureComputed(() =>
    i18n.t('{{ currentYearMeasurementsFraction }} measurements collected in {{ currentYear }}', {
      currentYearMeasurementsFraction: this.currentYearMeasurementsFraction(),
      currentYear: this.currentYear,
    })()
  );
  yearlyDiscountText = ko.pureComputed(() =>
    i18n.t('{{ yearlyDiscount }}% discount', {
      yearlyDiscount: this.yearlyDiscount(),
    })()
  );

  // current tenant plan or null if tenant has no plan (free trial)
  planId = ko.observable<string>(null);
  yearly = ko.observable(false);
  weather = ko.observable<subscriptionsApi.WeatherSubscription>('none');
  currentYear = new Date().getFullYear();
  currentYearMeasurementsCount = ko.observable<number>(0);
  availablePlans = ko.observableArray<subscriptionsApi.PlanData>();
  // new tenant plan. set only if tenant already has a plan and changes to a new one
  newPlanId = ko.observable<string>(null);
  newYearly = ko.observable(false);
  newWeather = ko.observable<subscriptionsApi.WeatherSubscription>('none');

  private weatherSelectedOptionReentrant = false;
  weatherSelectedOption = ko.observable<subscriptionsApi.WeatherSubscription>('none');

  yearlyDiscountPerc = 0;
  yearlyDiscount = ko.observable('');
  weatherAmount = ko.observable(0);
  weatherAdvancedAmount = ko.observable(0);

  weatherAmountFormatted = ko.pureComputed(() => '$' + (this.weatherAmount() / 100).toFixed(0) + '.-');
  weatherAdvancedAmountFormatted = ko.pureComputed(
    () => '$' + (this.weatherAdvancedAmount() / 100).toFixed(0) + '.-'
  );

  // coupon id as entered by the user
  couponId = ko.observable<string>(null).extend({
    validatable: true,
    serverError: true,
  });
  // server-verified coupon object that was successfully applied by the user
  appliedCoupon = ko.observable<Coupon>();

  applyingCoupon = ko.observable(false);
  paying = ko.observable(false);
  paymentError = ko.observable('');
  cancellingSubscription = ko.observable(false);
  actualPrice = ko.observable<number>(null);
  subscriptionSpecialConditions = ko.observable('');
  editable = ko.observable(true);

  paymentPopup = ko.observable<string>(null);
  invoiceName = ko.observable('').extend({ required: true });
  invoiceAddress = ko.observable('').extend({ required: true });
  invoiceCountry = ko.observable('').extend({ required: true });
  invoiceTaxId = ko.observable('').extend({ serverError: true });
  invoiceValidation = ko.validation.group([
    this.invoiceName,
    this.invoiceAddress,
    this.invoiceCountry,
    this.invoiceTaxId,
  ]);
  popupPaying = ko.observable(false);
  paymentPopupError = ko.observable('');

  planVerboseName = ko.pureComputed(() => {
    if (!this.planId()) {
      return i18n.t('Free trial')();
    }

    return this.getPlanVerboseName(this.planId(), this.weather());
  });

  newPlanVerboseName = ko.pureComputed(() => {
    if (!this.newPlanId()) {
      return '';
    }

    return this.getPlanVerboseName(this.newPlanId(), this.newWeather());
  });

  private getPlanVerboseName(planId: string, weather: subscriptionsApi.WeatherSubscription): string {
    let name = this.getPlan(planId).name;
    if (weather === 'basic') {
      name += ' + ' + i18n.t('Basic weather add-on')();
    } else if (weather === 'advanced') {
      name += ' + ' + i18n.t('Advanced weather add-on')();
    }

    return name;
  }

  currentTotal = ko.pureComputed(() => {
    // for currently paid amount we ignore any coupon that might have been
    // applied, so this can be somewhat inaccurate.
    return this.calculateTotalAmount(this.planId(), this.yearly(), this.weather());
  });

  paymentPopupTotal = ko.pureComputed(() => {
    let value = this.formatAmount(
      this.calculateTotalAmount(this.paymentPopup(), this.yearly(), this.weather(), this.appliedCoupon())
    );
    if (this.yearly()) {
      value += '/' + i18n.t(['year_lowercase', 'year'])();
    } else {
      value += '/' + i18n.t(['month_lowercase', 'month'])();
    }

    return value;
  });

  newTotal = ko.pureComputed(() => {
    return this.calculateTotalAmount(
      this.newPlanId(),
      this.newYearly(),
      this.newWeather(),
      this.appliedCoupon()
    );
  });

  appliedCouponDiscount = ko.pureComputed(() => {
    if (!this.appliedCoupon()) {
      return '';
    }

    return this.appliedCoupon().discountDescription(this.yearly());
  });

  smallScreen = ko.observable(false);
  groupedPlans = ko.computed(() => {
    if (this.smallScreen()) {
      return this.availablePlans().map((plan) => {
        return [plan];
      });
    } else {
      return [this.availablePlans()];
    }
  });

  currentYearMeasurementsFraction = ko.pureComputed<string>(() => {
    let result = `${this.formatNumber(this.currentYearMeasurementsCount())}`;
    if (this.getCurrentPlanMaxMeasurements()) {
      result += '/' + this.formatNumber(this.getCurrentPlanMaxMeasurements());
    }
    return result;
  });

  currentYearMeasurementsPercent = ko.pureComputed<number>(() => {
    return 100 * (this.currentYearMeasurementsCount() / this.getCurrentPlanMaxMeasurements());
  });

  expirationDate = ko.observable<Date>(null);

  lastWeatherActivation = ko.observable<Date | null>(null);
  weatherQuotaUsage = ko.observable(0);
  weatherQuotaMax = ko.observable(0);

  private koSubscriptions: KnockoutSubscription[] = [];

  constructor(params: { planId?: string }) {
    super();

    if (getExpirationDays() <= 0) {
      this.expirationDate(parseDate(session.tenant().expiration_date));
    }

    this.stripe = new Stripe(SERVER_INFO.STRIPE_PUBLISHABLE_KEY);
    let elements = this.stripe.elements();
    this.card = elements.create('card');

    let promise = subscriptionsApi.retrieve().then((subscriptionData) => {
      this.availablePlans(subscriptionData.available_plans);

      if (subscriptionData.plan) {
        this.planId(subscriptionData.plan);
        this.yearly(subscriptionData.yearly);
        this.weather(subscriptionData.weather);
        this.weatherSelectedOption(subscriptionData.weather);
      }

      this.currentYearMeasurementsCount(subscriptionData.current_year_measurements_count);
      this.yearlyDiscountPerc = subscriptionData.yearly_discount;
      this.yearlyDiscount((subscriptionData.yearly_discount * 100).toFixed(0));
      this.weatherAmount(subscriptionData.weather_amount);
      this.weatherAdvancedAmount(subscriptionData.advanced_weather_amount);
      this.actualPrice(subscriptionData.actual_price);
      this.lastWeatherActivation(parseDateTime(subscriptionData.last_weather_activation));
      this.weatherQuotaUsage(subscriptionData.weather_quota_usage);
      this.weatherQuotaMax(subscriptionData.weather_quota_max);
      this.subscriptionSpecialConditions(subscriptionData.subscription_special_conditions);
      this.editable(subscriptionData.editable);

      if (params.planId) {
        let plan = this.getPlan(params.planId);
        if (plan) {
          this.subscribe(plan);
        }
      }

      this.koSubscriptions.push(this.weatherSelectedOption.subscribe(this.onWeatherChanged));
    });
    this.loadedAfter(promise);

    $(window).on('resize', this.onResize);
    this.onResize();
  }

  dispose() {
    this.koSubscriptions.forEach((sub) => sub.dispose());
    $(window).off('resize', this.onResize);
  }

  private onResize = () => {
    this.smallScreen(window.innerWidth < 700);
  };

  private getPlan(id: string) {
    for (let plan of this.availablePlans()) {
      if (plan.id == id) {
        return plan;
      }
    }

    return null;
  }

  private getYearlyAmount(monthlyAmount: number) {
    return Math.ceil(monthlyAmount * 12 * (1 - this.yearlyDiscountPerc));
  }

  private calculateTotalAmount(
    planId: string,
    yearly: boolean,
    weather: subscriptionsApi.WeatherSubscription,
    coupon?: Coupon
  ): number {
    if (!planId) {
      return 0;
    }

    let amount = this.getPlan(planId).amount;
    if (weather === 'basic') {
      amount += this.weatherAmount();
    } else if (weather === 'advanced') {
      amount += this.weatherAdvancedAmount();
    }

    // apply discounts
    if (yearly) {
      amount = this.getYearlyAmount(amount);
    }
    if (coupon) {
      amount = coupon.applyDiscount(amount);
    }

    return amount;
  }

  private getCurrentPlanMaxMeasurements(): number {
    let planId = this.planId();
    let plan: subscriptionsApi.PlanData;

    if (planId) {
      plan = this.getPlan(planId);
    } else {
      plan = this.availablePlans()[0];
    }

    return plan.measurements;
  }

  formatAmount(amount: number) {
    let dollarAmount = amount / 100;

    return '$' + dollarAmount.toLocaleString();
  }

  formatNumber(n: number) {
    return n ? n.toLocaleString() : ' ';
  }

  /**
   * Apply promotional code entered by the user.
   *
   * Makes API call to the server to verify the code and retrieve discount
   * details. The discount will be applied if the user makes a purchase after
   * applying the code.
   */
  applyCoupon() {
    this.couponId.serverError(null);
    this.appliedCoupon(null);
    if (!this.couponId()) {
      return;
    }

    this.applyingCoupon(true);

    couponsApi.retrieve(this.couponId().toUpperCase()).then((response) => {
      this.applyingCoupon(false);

      if (!response.isValid) {
        this.setServerError(this.couponId, response.errors['coupon_id']);
      } else {
        this.appliedCoupon(new Coupon(response.coupon));
      }
    });
  }

  /**
   * Create a new subscription or change existing subscription to a new plan.
   */
  subscribe = (plan: subscriptionsApi.PlanData) => {
    if (!this.editable()) {
      return;
    }

    if (this.planId()) {
      // tenant already has a subscription
      // set observables to launch UI for updating subscription
      this.newPlanId(plan.id);
      this.newYearly(this.yearly());
      this.newWeather(this.weather());
    } else {
      // no subscription yet. process initial payment
      this.paymentPopup(plan.id);
    }
  };

  closePaymentPopup = () => {
    this.paymentPopup(null);
  };

  confirmPayment = () => {
    event.preventDefault();

    if (this.invoiceValidation().length > 0) {
      this.invoiceValidation.showAllMessages();
      return;
    }

    this.popupPaying(true);

    let planId = this.paymentPopup();
    let yearly = this.yearly();
    let weather = this.weather();
    let couponId = this.appliedCoupon() ? this.appliedCoupon().id() : null;

    let onSuccess = () => {
      // reload to fetch the new tenant definition (without the plan id parameter)
      location.href = location.pathname;
    };
    let onServerError = () => {
      this.popupPaying(false);
      this.paymentPopupError(i18n.t('There was an error contacting the payment server.')());
    };

    this.stripe
      .createPaymentMethod({ type: 'card', card: this.card })
      .then((result: any) => {
        if (result.error) {
          this.popupPaying(false);
          this.paymentPopupError(result.error.message);
        } else {
          return subscriptionsApi.create({
            plan: planId,
            yearly: yearly,
            weather: weather,
            payment_method: result.paymentMethod.id,
            coupon: couponId,
            name: this.invoiceName(),
            address: this.invoiceAddress(),
            country_id: this.invoiceCountry(),
            tax_id: this.invoiceTaxId(),
          });
        }
      })
      .then((res: { status: number; result: subscriptionsApi.CreateSubscriptionResult }) => {
        if (!res) {
          return;
        }

        if (res.status !== 200) {
          onServerError();
          return;
        }

        if (res.result.status === 'succeeded') {
          onSuccess();
          return;
        }

        if (res.result.status === 'failed') {
          this.popupPaying(false);
          if (res.result.failure_code === 'tax_id_invalid') {
            this.invoiceTaxId.serverError('This tax ID is invalid for the selected country');
          } else {
            this.paymentPopupError(
              i18n.t('We could not charge your card. Please check the payment information and try again.')()
            );
          }
          return;
        }

        return this.stripe.confirmCardPayment(res.result.client_secret).then((res: any) => {
          if (res.error) {
            this.popupPaying(false);
            this.paymentPopupError(res.error.message);
          } else {
            subscriptionsApi.confirm().then(onSuccess);
          }
        });
      })
      .catch((e: any) => {
        onServerError();
        logError('payment', e);
      });
  };

  private onWeatherChanged = (value: subscriptionsApi.WeatherSubscription) => {
    if (this.weatherSelectedOptionReentrant) {
      return;
    }

    if (this.planId()) {
      this.newPlanId(this.planId());
      this.newYearly(this.yearly());
      this.newWeather(value);
    } else {
      this.weather(value);
    }
  };

  /**
   * Perform subscription change as it has just been confirmed.
   */
  confirmSubscriptionChange = () => {
    let plan = this.newPlanId();
    let yearly = this.newYearly();
    let weather = this.newWeather();
    let coupon = this.appliedCoupon() ? this.appliedCoupon().id() : null;
    this.newPlanId(null);

    if (plan) {
      this.paying(true);
      this.onPayment(plan, yearly, weather, subscriptionsApi.update({ plan, yearly, weather, coupon }));
    }
  };

  /**
   * Cancel subscription change that was about to be performed.
   */
  cancelSubscriptionChange = () => {
    this.newPlanId(null);
    try {
      this.weatherSelectedOptionReentrant = true;
      this.newWeather('none');
      this.weatherSelectedOption(this.weather());
    } finally {
      this.weatherSelectedOptionReentrant = false;
    }
  };

  /**
   * Process payment for subscription update.
   *
   * Executes API call to create or update a subscription. The user is
   * charged on the backend, this method is then responsible for handling
   * the success or error in a user friendly manner.
   *
   * @param planId  target plan id for the customer
   * @param yearly  true if the plan is annual
   * @param promise  promise for API call for subscription creation/change
   */
  private onPayment(
    planId: string,
    yearly: boolean,
    weather: subscriptionsApi.WeatherSubscription,
    promise: Promise<ValidationResult>
  ) {
    promise
      .then((result) => {
        this.paying(false);
        this.newPlanId(null);

        if (result.isValid) {
          this.planId(planId);
          this.yearly(yearly);
          this.weather(weather);
          // reload to fetch the new tenant definition (without the plan id parameter)
          location.href = location.pathname;
        } else {
          this.paymentError(result.errors['token'].join(', '));
        }
      })
      .catch((res) => {
        this.paying(false);
        this.newPlanId(null);
      });
  }

  cancelSubscription = () => {
    this.cancellingSubscription(true);
  };

  confirmCancelSubscription = () => {
    this.cancellingSubscription(false);

    this.paying(true);
    subscriptionsApi
      .remove()
      .then(() => {
        this.paying(false);
        this.planId(null);
        // reload to fetch the new tenant definition
        location.reload();
      })
      .catch(() => {
        this.paying(false);
      });
  };

  cancelCancelSubscription = () => {
    this.cancellingSubscription(false);
  };

  closePaymentError = () => {
    this.paymentError('');
  };

  setServerError(obsv: KnockoutObservable<{}>, errors: string[]) {
    obsv.serverError(errors.join('. '));
  }

  openWeatherInfo = () => {
    app.formsStackController.push({
      title: '',
      name: SUBSCRIPTION_WEATHER_INFO_COMPONENT,
      isBig: true,
      params: {
        weatherAmountFormatted: this.weatherAmountFormatted(),
        weatherAdvancedAmountFormatted: this.weatherAdvancedAmountFormatted(),
        result: new Deferred<{}>(),
      },
    });
  };

  getObsPerYearText(n: number) {
    if (!n) {
      return i18n.t('Measurements per year to be defined')();
    }
    return i18n.t('{{ number }} Measurements (per year)', {
      number: this.formatNumber(n),
    })();
  }

  hasSpecialDiscount(data: subscriptionsApi.PlanData) {
    return data.id === this.planId() && this.actualPrice() && this.actualPrice() * 100 !== data.amount;
  }
}

export let subscription = {
  name: 'subscription',
  viewModel: SubscriptionScreen,
  template: template,
};

ko.components.register(subscription.name, subscription);
