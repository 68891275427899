import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { Deferred } from '../utils/deferred';
import { API, ValidationResult } from '../api/request';
import { I18nText, asI18nText } from '../i18n_text';

interface BaseModel<TData> {
  name?: KnockoutObservable<string>;
  nameJson?: KnockoutObservable<I18nText>;
  toData(): TData;
}

export interface BaseEditParams<TData> {
  id: string;
  initialName?: string;
  result?: Deferred<TData>;
}

export abstract class BaseEditScreen<
  TData extends { id?: string },
  T extends BaseModel<TData>
> extends BaseForm<TData> {
  entity = ko.validatedObservable<T>(null);
  globalError = ko.observable('');

  constructor(
    private options: { api: API<TData>; focus: boolean },
    params: BaseEditParams<TData>,
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let dataPromise = params.id ? options.api.retrieve(params.id) : undefined;
    let promise = Promise.all([dataPromise]).then(([data]) => {
      this.entity(this.instantiate(data));

      if (!data && this.entity().name && params.initialName) {
        this.entity().name(params.initialName);
      }
      if (!data && this.entity().nameJson && params.initialName) {
        this.entity().nameJson(asI18nText(params.initialName));
      }
    });
    this.loadedAfter(promise).then(() => {
      if (this.options.focus) {
        this.focusFirst(componentInfo.element);
      }
    });
  }

  abstract instantiate(data: TData): T;

  save = () => {
    this.globalError('');

    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(this.options.api.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
        setGlobalError(this.globalError, validation);
      });
    }
  };
}

export function setGlobalError(globalError: KnockoutObservable<string>, validation: ValidationResult) {
  if (!validation.isValid) {
    if (!Array.isArray(validation.errors)) {
      let errors: any = validation.errors;
      let globalErrors = (errors['__all__'] || ([] as string[])).concat(
        errors['non_field_errors'] || []
      );
      if (globalErrors.length > 0) {
        globalError(globalErrors.join('. '));
      }
    } else {
      let errors: string[] = validation.errors;
      if (errors.length > 0) {
        globalError(errors.join('. '));
      }
    }
  }
}
