import { makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';
import { UserLogMixinData } from './changelog';

export interface SeasonData extends UserLogMixinData {
  id?: string;
  name_json: I18nText;
  code: string;
  countries_summary?: string; // list-only
}

export const { list, retrieve, save, remove } = makeDefaultApi<SeasonData, { country_id: string }>(
  'seasons'
);