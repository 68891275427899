import * as ko from 'knockout';
import { findById } from '../utils';
import i18n from '../i18n';
import { MaybeKO } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/normalization_select.html').default;

export interface NormalizationMMData {
  id: string; // NOTE: special value 'default' means taking the area from the trial model
  name: string;
  is_date: boolean;
  is_surface: boolean;
}

interface NormalizationData {
  normalize: boolean;
  normalization_mm_id: string;
}

export class NormalizationSelectConfig {
  normalizationMMId: KnockoutObservable<string>;
  normRequired: ko.Computed<boolean>;

  constructor(
    public mesId: KnockoutComputed<string> | KnockoutObservable<string>,
    public allMMs: MaybeKO<NormalizationMMData[]>,
    public makeAreaOption: (title: string, slug: string) => NormalizationMMData,
    public onMesChanged: () => void,
    public normalizeDates: boolean
  ) {
    this.normRequired = ko.pureComputed(() => {
      let selected = findById(ko.unwrap(allMMs), mesId());
      return selected && this.normalizeDates && selected.is_date;
    });
    this.normalizationMMId = ko.observable('').extend({
      required: { onlyIf: () => this.normRequired() },
    });
    setDefaultNormalization(this.normalizationMMId, ko.unwrap(allMMs));
  }

  init(data: NormalizationData) {
    this.normalizationMMId(data.normalize ? data.normalization_mm_id || 'default' : '');
  }

  toData(): NormalizationData {
    let normMMId = this.normalizationMMId();

    return {
      normalize: normMMId !== '',
      normalization_mm_id: normMMId === 'default' || normMMId === '' ? null : normMMId,
    };
  }
}

class NormalizationSelect {
  normalizationMMId: KnockoutObservable<string>;
  normRequired: ko.Computed<boolean>;
  normalizationMMs = ko.pureComputed<NormalizationMMData[]>(() => this.getNormalizationMMs());

  private config: NormalizationSelectConfig;
  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { config: NormalizationSelectConfig }) {
    this.config = params.config;
    this.normalizationMMId = params.config.normalizationMMId;
    this.normRequired = params.config.normRequired;
    this.subscriptions.push(this.config.mesId.subscribe(this.onMesChanged));
    this.onMesChanged(this.config.mesId());
  }

  private getNormalizationMMs(): NormalizationMMData[] {
    let allMMs = ko.unwrap(this.config.allMMs);
    let selected = findById(allMMs, this.config.mesId());

    if (selected && selected.is_date) {
      return this.config.normalizeDates ? allMMs.filter((mm) => mm.is_date) : [];
    } else {
      return [
        this.config.makeAreaOption(i18n.t("Don't normalize")(), ''),
        this.config.makeAreaOption(i18n.t('Trial default for all plots')(), 'default'),
      ].concat(allMMs.filter((mm) => mm.is_surface));
    }
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
  }

  private onMesChanged = (mesId: string) => {
    if (!mesId) {
      this.config.onMesChanged();
      return;
    }

    let selected = findById(ko.unwrap(this.config.allMMs), mesId);

    // NOTE: this.normalizationMMs() might not be up-to-date yet
    let normMMs = this.getNormalizationMMs();
    if (
      (this.config.normalizationMMId() === '' && selected.is_date) ||
      (this.config.normalizationMMId() !== '' && !findById(normMMs, this.config.normalizationMMId()))
    ) {
      setDefaultNormalization(this.config.normalizationMMId, normMMs);
    }

    this.config.onMesChanged();
  };
}

function setDefaultNormalization(
  normalizationMMId: KnockoutObservable<string>,
  normMMs: NormalizationMMData[]
) {
  normalizationMMId(normMMs.length > 0 ? normMMs[0].id : '');
}

ko.components.register('normalization-select', {
  viewModel: NormalizationSelect,
  template: template,
});
