import * as ko from 'knockout';

import i18n from '../i18n';
import { DimensionData } from '../api/dimensions';
import { CropVarietyData, CropVarietyStageData } from '../api/crop_varieties';
import { DimensionMeta } from './dimension_meta';
import { Dimension } from './dimension';
import { CountryData } from '../api/countries';
import { parseDate, serializeDate } from '../api/serialization';
import {
  getCountrySearchConfig,
  getStageSearchConfig,
  getBreederSearchConfig,
  getCropVarietyTypeSearchConfig,
  getCropVarietySearchConfig,
} from '../components/configs/search_configs';
import { FormNestedEntitiesConfiguration } from '../components/form_nested_entities';
import { translate } from '../i18n_text';
import { CropData } from '../api/crops';
import { BreederData } from '../api/organizations';
import { NameI18nData } from '../api/names';

export class CropVariety extends Dimension {
  crop = ko.observable<CropData>(null).extend({
    required: true,
  });
  cropEditable = ko.observable(true);
  stages = ko.observableArray<CropVarietyStage>();
  origin = ko.observable<BreederData>(null);
  origin2 = ko.observable<BreederData>(null);
  varietyType = ko.observable<NameI18nData>(null);
  originType: NameI18nData = null;
  origin2Type: NameI18nData = null;
  parent1 = ko.observable<NameI18nData>(null);
  parent2 = ko.observable<NameI18nData>(null);

  editUrl = ko.pureComputed(() => {
    return '/crop_varieties/' + this.id() + '/';
  });

  stagesConfig: FormNestedEntitiesConfiguration<CropVarietyStage> = {
    title: i18n.t('Stages per country:'),
    addTitle: i18n.t('Add stage'),
    missingTitle: i18n.t('Select value'),

    entities: this.stages,
    actions: [],

    canDisable: () => false,
    isTrialActive: () => false, // Since canDisable is false this will never be visible
    disabled: () => false,
    disable: () => {},

    canRemove: (entity) => true,
    add: () => {
      let stage = new CropVarietyStage();
      this.stages.push(stage);
      return stage;
    },
    remove: (stage: CropVarietyStage) => {
      this.stages.remove(stage);
    },
    hasErrors: (entity) => entity.hasErrors(),
    showErrors: (entity) => entity.showErrors(),

    getSummaryName: (entity) => {
      let names: string[] = [];
      if (entity.country()) {
        names.push(entity.country().name);
      }
      if (entity.stage()) {
        names.push(translate(entity.stage().name_json));
      }

      return names.join(' - ');
    },
  };
  originSearch = getBreederSearchConfig(this.origin);
  origin2Search = getBreederSearchConfig(this.origin2);
  varietyTypeSearch = getCropVarietyTypeSearchConfig(this.varietyType);
  parent1Search = getCropVarietySearchConfig(this.parent1);
  parent2Search = getCropVarietySearchConfig(this.parent2);

  constructor(data?: CropVarietyData, dimensionMeta?: DimensionMeta) {
    super(data, dimensionMeta);

    if (APP_CONFIG.CROP_VARIETY_REQUIRED_ORIGIN) {
      this.origin = this.origin.extend({ required: true });
    }

    if (data) {
      this.crop(data.crop_id);
      this.cropEditable(!!data.crop_editable);
      if (data.stages) {
        this.stages(data.stages.map((data) => new CropVarietyStage(data)));
      }
      this.origin(data.origin);
      this.origin2(data.origin_2);
      this.varietyType(data.variety_type);
      this.originType = data.origin_type;
      this.origin2Type = data.origin_2_type;
      this.parent1(data.parent_1);
      this.parent2(data.parent_2);
    }
  }

  toData(): CropVarietyData {
    return {
      crop_id: this.crop(),
      variety_type: this.varietyType(),
      origin: this.origin(),
      origin_2: this.origin2(),
      stages: this.stages().map((stage) => stage.toData()),
      parent_1: this.parent1(),
      parent_2: this.parent2(),
      ...super.toData(),
    };
  }
}

export class CropVarietyStage {
  id: string = null;
  country = ko.observable<CountryData>().extend({ required: true });
  stage = ko.observable<DimensionData>().extend({ required: true, serverError: true });
  startAt = ko.observable<Date>(null).extend({ required: true });
  external = ko.observable(false);
  comment = ko.observable('');

  countrySearchConfig = getCountrySearchConfig(this.country);
  stageSearchConfig = getStageSearchConfig(this.stage);

  private errors = ko.validation.group(this);

  constructor(data?: CropVarietyStageData) {
    if (data) {
      this.id = data.id;
      this.country(data.country);
      this.stage(data.stage);
      this.startAt(parseDate(data.start_at));
      this.external(data.external);
      this.comment(data.comment);
    }
  }

  hasErrors = ko.pureComputed(() => {
    return this.errors().length > 0 || !!this.stage.serverError();
  });

  showErrors() {
    this.errors.showAllMessages();
  }

  toData(): CropVarietyStageData {
    return {
      id: this.id,
      country: this.country(),
      stage: this.stage(),
      start_at: serializeDate(this.startAt()),
      external: this.external(),
      comment: this.comment(),
    };
  }
}
