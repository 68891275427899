import {
  request,
  requestWithValidation,
  requestRemoveEntity,
  ValidationResult,
  RemoveResult,
  ListRequestParams,
  listRequest,
} from '../api/request';
import { I18nText } from '../i18n_text';

export interface NameData {
  id: string;
  name: string;
}

export interface NameI18nData {
  id?: string;
  name_json: I18nText;
  code?: string;
}

export function list(
  baseName: string,
  params: { ids?: string[]; name_search?: string } & ListRequestParams,
  options?: { tenantId?: string; publicList?: boolean }
): Promise<NameI18nData[]> {
  if (options && options.tenantId && options.publicList) {
    return listRequest('/api/t/' + options.tenantId + '/' + baseName + '/list_public/', params, {
      injectTenant: false,
      skipToken: options.publicList,
    });
  } else if (options && options.tenantId) {
    return listRequest('/api/t/' + options.tenantId + '/' + baseName + '/', params, { injectTenant: false });
  } else {
    return listRequest('/api/' + baseName + '/', params);
  }
}

export function retrieve(
  baseName: string,
  id: string,
  options?: { tenantId?: string; publicList?: boolean }
): Promise<NameI18nData> {
  if (options && options.tenantId && options.publicList) {
    return request('GET', '/api/t/' + options.tenantId + '/' + baseName + '/' + id + '/public/', undefined, {
      injectTenant: false,
      skipToken: options.publicList,
    });
  } else if (options && options.tenantId) {
    return request('GET', '/api/t/' + options.tenantId + '/' + baseName + '/' + id + '/', undefined, {
      injectTenant: false,
    });
  } else {
    return request('GET', '/api/' + baseName + '/' + id + '/');
  }
}

export function save(baseName: string, data: NameI18nData): Promise<ValidationResult> {
  let endpoint = '/api/' + baseName + '/';
  let method = 'POST';
  if (data.id) {
    endpoint += data.id + '/';
    method = 'PUT';
  }

  return requestWithValidation(method, endpoint, data);
}

export function remove(baseName: string, id: string): Promise<RemoveResult> {
  return requestRemoveEntity('/api/' + baseName + '/' + id + '/');
}
