import * as ko from 'knockout';

import { I18nText } from '../i18n_text';
import { getClientTypeSearchConfig } from '../components/configs/search_configs';
import { ClientTypeData } from '../api/client_types';
import { DriverData } from '../api/drivers';

export class Driver {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  clientType = ko.observable<ClientTypeData>(null).extend({
    required: true,
  });

  clientTypeSearchConfig = getClientTypeSearchConfig(this.clientType);

  constructor(data?: DriverData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.clientType(data.client_type);
    }
  }

  toData(): DriverData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      client_type: this.clientType(),
    };
  }
}
