import * as ko from 'knockout';
import page from 'page';
import i18n from '../i18n';

import { ListRequestParams } from '../api/request';
import { ListLoader, ListLoaderDelegate } from '../components/list_loader';
import { User, ftUserOptions } from '../models/user';
import * as usersApi from '../api/users';
import { countriesApi } from '../api/countries';
import { Action } from '../components/basic_widgets';
import { CountryData } from '../api/countries';
import { deflateList } from '../api/serialization';
import { session } from '../session';
import { FilterDelegate } from '../components/list_filters';
import { updateLocationWithQueryString, asArray, downloadBlob } from '../utils';
import { BaseLoadingScreen } from './base_loading_screen';
import { openResendInvite } from '../components/resend_invite';
import { ListHeaderAction } from '../components/list_header';
import { canEditUsers, canInviteUsers } from '../permissions';

let template = require('raw-loader!../../templates/users.html').default;

class UsersScreen extends BaseLoadingScreen implements ListLoaderDelegate<usersApi.UserData, User> {
  loader: ListLoader<usersApi.UserData, User>;
  private countryFilter = ko.observableArray<CountryData>(null);
  private roleFilter = ko.observableArray<{ name: string; id: string }>();
  searchPlaceholder = i18n.t('Search users by name or email')();
  search = ko.observable('');
  canEdit = canEditUsers();
  canInvite = canInviteUsers();
  exporting = ko.observable(false);

  newFilters: FilterDelegate[] = [
    {
      title: i18n.t('Country')(),
      entities: this.countryFilter,
      list: countriesApi.list,
    },
    {
      title: i18n.t('Role')(),
      entities: this.roleFilter,
      list: (params) => {
        let roles = ftUserOptions.roleOptions;
        if (params.name_prefix) {
          roles = roles.filter((role) => role.name.indexOf(params.name_prefix) >= 0);
        }
        return Promise.resolve(roles);
      },
    },
  ];
  listActions = ko.observableArray<ListHeaderAction>();
  constructor(params: {
    filters: { countries: string | string[]; roles: string | string[]; search: string };
  }) {
    super();

    let roles = asArray(params.filters.roles);
    this.roleFilter(ftUserOptions.roleOptions.filter((role) => roles.indexOf(role.id) !== -1));

    if (params.filters.search) {
      this.search(params.filters.search);
    }
    let countries = asArray(params.filters.countries);
    let countriesPromise =
      countries.length > 0 ? countriesApi.list({ ids: countries }) : Promise.resolve<CountryData[]>([]);
    let promise = countriesPromise.then((countryData) => {
      if (countryData) {
        this.countryFilter(countryData);
      }
    });
    if (this.canInvite) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/users/new/',
        tooltipTitle: i18n.t('Add User')(),
      });
    }
    this.listActions.push({
      title: i18n.t('Export')(),
      icon: 'file_download',
      onClick: this.export,
      loading: this.exporting,
    });

    this.loadedAfter(promise);
  }

  export = async () => {
    this.exporting(true);
    try {
      const data = await usersApi.exportUsers({
        roles: deflateList(this.roleFilter),
        countries: deflateList(this.countryFilter),
        search: this.search(),
        for_edit: true,
      });
      downloadBlob(data, 'users.xlsx');
    } finally {
      this.exporting(false);
    }
  };

  onReady(loader: ListLoader<usersApi.UserData, User>): void {
    this.loader = loader;
    this.search.subscribe(() => this.loader.forceLoad());
  }

  fetch(params: ListRequestParams) {
    let filters = {
      roles: deflateList(this.roleFilter),
      countries: deflateList(this.countryFilter),
      search: this.search(),
    };
    updateLocationWithQueryString(filters);

    return usersApi.list({ ...filters, ...params, for_edit: true });
  }

  instantiate(userData: usersApi.UserData) {
    return new User(ftUserOptions, userData);
  }

  remove(id: string) {
    return usersApi.remove(id);
  }

  canRemove(user: User) {
    return this.canEdit;
  }

  private makeRole(user: User, role: usersApi.UserRole) {
    usersApi.makeRole(user.id(), role).then(() => {
      user.role(role);
    });
  }

  getActions(user: User): Action[] {
    let res: Action[] = [
      {
        icon: 'map',
        title: i18n.t('Map activities')(),
        cssClass: '',
        onClick: () => {
          page(session.toTenantPath('/users/' + user.id() + '/activities/'));
        },
      },
    ];

    if (this.canEdit) {
      res.push({
        icon: 'send',
        title: i18n.t('Resend invite')(),
        cssClass: '',
        onClick: () => openResendInvite(user),
      });
    }

    if (session.isAdmin()) {
      if (user.role() !== 'admin') {
        res.push({
          icon: 'lock',
          title: i18n.t('Make admin')(),
          cssClass: '',
          onClick: () => {
            this.makeRole(user, 'admin');
          },
        });
      }

      if (user.role() !== 'read_only_admin') {
        res.push({
          icon: 'lock',
          title: i18n.t('Make read-only admin')(),
          cssClass: '',
          onClick: () => {
            this.makeRole(user, 'read_only_admin');
          },
        });
      }

      if (user.role() !== 'template_editor') {
        res.push({
          icon: 'lock',
          title: i18n.t('Make template editor')(),
          cssClass: '',
          onClick: () => {
            this.makeRole(user, 'template_editor');
          },
        });
      }

      if (user.role() !== 'manager') {
        res.push({
          icon: 'lock',
          title: i18n.t('Make manager')(),
          cssClass: '',
          onClick: () => {
            this.makeRole(user, 'manager');
          },
        });
      }

      if (
        user.role() !== 'restricted_manager' &&
        session.tenant() &&
        session.tenant().restricted_manager_enabled
      ) {
        res.push({
          icon: 'lock',
          title: i18n.t('Make restricted manager')(),
          cssClass: '',
          onClick: () => {
            this.makeRole(user, 'restricted_manager');
          },
        });
      }

      if (user.role() !== 'editor' && !session.isManager()) {
        res.push({
          icon: 'lock',
          title: i18n.t('Make editor')(),
          cssClass: '',
          onClick: () => {
            this.makeRole(user, 'editor');
          },
        });
      }

      if (user.role() !== 'data_entry') {
        res.push({
          icon: 'lock_open',
          title: i18n.t('Make staff')(),
          cssClass: '',
          onClick: () => {
            this.makeRole(user, 'data_entry');
          },
        });
      }
    }

    return res;
  }
}

export let users = {
  name: 'users',
  viewModel: UsersScreen,
  template: template,
};

ko.components.register(users.name, users);
