import * as ko from 'knockout';
import { MeasurementChoice } from '../models/measurement_type';
import { clearModelServerErrors, applyModelServerErrors } from '../screens/base_form';
import i18n from '../i18n';
import { MaybeKO, asObservable } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/edit_measurement_choices.html').default;

export interface EditMeasurementChoicesDelegate {
  choices: KnockoutObservableArray<MeasurementChoice>;
  globalError: KnockoutObservable<string>;
}

class EditMeasurementChoices {
  choices: KnockoutObservableArray<MeasurementChoice>;
  globalError: KnockoutObservable<string>;
  enable: KnockoutObservable<boolean>;

  constructor(params: { delegate: EditMeasurementChoicesDelegate; enable: MaybeKO<boolean> }) {
    this.choices = params.delegate.choices;
    this.globalError = params.delegate.globalError;
    this.enable = asObservable(params.enable);
  }

  addChoice = () => {
    this.choices.push(new MeasurementChoice());
  };

  removeChoice = (choice: MeasurementChoice) => {
    this.choices.remove(choice);
  };
}

export function uploadingChoice(delegate: EditMeasurementChoicesDelegate): boolean {
  for (let choice of delegate.choices()) {
    if (choice.imageUpload.uploading()) {
      return true;
    }
  }

  return false;
}

export function validateChoices(delegate: EditMeasurementChoicesDelegate): boolean {
  for (let choice of delegate.choices()) {
    clearModelServerErrors(choice);
  }
  delegate.globalError('');

  if (delegate.choices().length === 0) {
    delegate.globalError(i18n.t('You must specify at least one choice')());
    return false;
  }

  return true;
}

export function applyChoicesErrors(delegate: EditMeasurementChoicesDelegate, errors: {}) {
  if (!errors) {
    return;
  }

  let choiceErrors = <{ [idx: number]: { [field: string]: string[] } }>(<any>errors);
  for (let k in choiceErrors) {
    if (choiceErrors.hasOwnProperty(k)) {
      let choice = delegate.choices()[k];
      if (choice) {
        applyModelServerErrors(choice, choiceErrors[k]);
      }
    }
  }
}

ko.components.register('edit-measurement-choices', {
  viewModel: EditMeasurementChoices,
  template: template,
});
