import { makeDefaultApi } from './request';
import { UserData } from './users';
import { UserLogMixinData } from './changelog';

export interface ProjectData extends UserLogMixinData {
  id: string;
  name: string;
  from_date: string;
  to_date: string;
  users: UserData[]; // not present in list
}

export const projectApi = makeDefaultApi<ProjectData>('projects');
