import i18n from '../i18n';

import * as dashboardApi from '../api/dashboard';
import { Chart, AutoChart, CustomChart } from './chart';
import { confirmDialog } from '../components/confirm_dialog';

export function makeAutoChartGroup(
  canEdit: boolean,
  filter: dashboardApi.ChartFilter,
  data: dashboardApi.DashboardDatasetData | dashboardApi.DashboardScheduledVisitData,
  datasets: dashboardApi.DashboardDatasetData[],
  index: number,
  existing: ChartGroup[]
) {
  const existingGroup = existing.filter(
    (gr) =>
      gr.filter.dataset_id === filter.dataset_id &&
      gr.filter.scheduled_visit_id === filter.scheduled_visit_id
  )[0];
  const existingCharts = (existingGroup?.charts() ?? []) as AutoChart[];

  const charts = data.measurement_metas
    .map((measurementMetaData) => {
      const dataset = datasets.filter((ds) => ds.id === measurementMetaData.dataset_id)[0];
      if (!dataset) {
        return null;
      }

      const existingChart = existingCharts.filter(
        (chart) => chart.measurementMetaId === measurementMetaData.id
      )[0];
      return new AutoChart(canEdit, data, measurementMetaData, dataset, existingChart);
    })
    .filter((chart) => !!chart);
  return new AutoChartGroup(filter, data.name, index, charts, null);
}

export function makeCustomChartGroup(trialId: string, data: dashboardApi.CustomChartData[], index: number) {
  return new ChartGroup(
    { dataset_id: null, scheduled_visit_id: null },
    '',
    index,
    data.map((chartData) => new CustomChart(trialId, chartData)),
    trialId
  );
}

export class ChartGroup {
  charts = ko.observableArray<Chart>();

  constructor(
    public filter: dashboardApi.ChartFilter,
    public name: string,
    public index: number,
    charts: Chart[],
    private trialId: string
  ) {
    this.charts(charts);
  }

  refresh() {
    this.charts().forEach((chart) => chart.refresh());
  }

  dispose() {
    for (let chart of this.charts()) {
      chart.dispose();
    }
    this.charts([]);
  }

  onRemove = (chart: CustomChart) => {
    let title = i18n.t('Removing chart')();
    let msg = i18n.t(['remove_chart_warning', 'Are you sure you want to remove this chart?'])();

    confirmDialog(title, msg, 'dialog-remove').then(() => {
      dashboardApi.removeCustomChart(this.trialId, chart.chartId).then(() => {
        this.charts.remove(chart);
      });
    });
  };
}

class AutoChartGroup extends ChartGroup {
  async refresh() {
    const charts = this.charts() as AutoChart[];

    charts.forEach((chart) => chart.loading(true));
    try {
      const data = await dashboardApi.charts({
        ...this.filter,
        charts: charts.map((chart) => chart.chartParams()),
      });
      charts.forEach((chart, idx) => {
        chart.setData(data[idx]);
      });
    } finally {
      charts.forEach((chart) => chart.loading(false));
    }
  }
}
