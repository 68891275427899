import { TraitActionChoiceOptionData } from './measurement_types';
import { request } from './request';
import { ValueMetaData } from './value_meta_data';

export type ActionType = 'set_value' | 'play_sound';
export type SoundName = 'success_sound' | 'error_sound';

export interface TraitActionData {
  id?: string;
  action_type: ActionType;
  source_measurement_meta_id: string;
  target_measurement_meta_id: string;
  target_measurement_value: string;
  source_measurement_meta_template_id?: string; // read-only
  target_measurement_meta_template_id?: string; // read-only
  target_measurement_value_template_id?: string; // read-only
  sound_name?: SoundName;
}

export interface TraitActionSourceData extends Pick<ValueMetaData, 'id' | 'name_json'> {}
export interface TraitActionTargetData extends Pick<ValueMetaData, 'id' | 'name_json'> {
  value_choices: TraitActionChoiceOptionData[];
}
export interface TraitActionOptionsData {
  sources: TraitActionSourceData[];
  targets: TraitActionTargetData[];
  action_types: { value: ActionType; label: string }[];
  sound_names?: { value: SoundName; label: string }[];
}

export function listTraitActionOptions(params: {
  measurement_meta_ids: string[];
}): Promise<TraitActionOptionsData> {
  return request('POST', `/api/v2/trait_actions/list_options/`, params, {
    injectTenant: true,
    version: 2,
  });
}
