import * as ko from 'knockout';

import i18n from '../i18n';
import { I18nText } from '../i18n_text';
import { RegistrationPhaseData } from '../api/registration_phases';

export type RegistrationPhaseType = 'REGIONAL' | 'NATIONAL';

export class RegistrationPhase {
  static TYPE_OPTIONS = [
      { value: 'REGIONAL', name: i18n.t('Regional')() },
      { value: 'NATIONAL', name: i18n.t('National')() },
    ];


  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  type = ko.observable<RegistrationPhaseType>(RegistrationPhase.TYPE_OPTIONS[0].value as RegistrationPhaseType);

  constructor(data?: RegistrationPhaseData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.type(data.type);
    }
  }

  toData(): RegistrationPhaseData{
    return {
      id: this.id(),
      name_json: this.nameJson(),
      type: this.type(),
    };
  }
}
