import * as ko from 'knockout';

import { Registration } from '../models/registration';
import { RegistrationData, registrationApi } from '../api/registrations';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { BaseEditScreen } from './base_edit';
import { CropVarietyData } from '../api/crop_varieties';
import { CountryData } from '../api/countries';

let template = require('raw-loader!../../templates/registration_edit.html').default;

class RegistrationEditScreen extends BaseEditScreen<RegistrationData, Registration> {
  canEditCV = ko.observable(false);
  canEditCountry = ko.observable(false);

  constructor(
    private params: {
      id: string;
      initialCropVariety: CropVarietyData;
      initialCountry: CountryData;
      result?: Deferred<RegistrationData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super({ api: registrationApi, focus: false }, params, componentInfo);

    this.canEditCV(!params.initialCropVariety);
    this.canEditCountry(!params.initialCountry);
  }

  instantiate(data: RegistrationData) {
    let reg = new Registration(data);
    if (!data && this.params.initialCropVariety) {
      reg.cropVariety(this.params.initialCropVariety);
    }
    if (!data && this.params.initialCountry) {
      reg.country(this.params.initialCountry);
    }

    return reg;
  }
}

export let registrationEdit = {
  name: 'registration-edit',
  viewModel: createWithComponent(RegistrationEditScreen),
  template: template,
};

ko.components.register(registrationEdit.name, registrationEdit);
