import * as ko from 'knockout';

import { BaseForm } from './base_form';
import * as trialsApi from '../api/trials';
import * as usersApi from '../api/users';
import { slugValidation, SlugGenerator } from '../ko_bindings/slug_validation';
import { I18nText, translate, asI18nText } from '../i18n_text';
import { canCreateTemplate } from '../permissions';
import { setGlobalError } from './base_edit';

let template = require('raw-loader!../../templates/trial_copy.html').default;

export function makeTrialCopyName(trialData: trialsApi.TrialData): I18nText {
  return asI18nText(translate(trialData.name_json) + ' (copy)');
}

class TrialCopyScreen extends BaseForm<{}> {
  private slugGenerator: SlugGenerator;
  private trialData: trialsApi.TrialData;

  canCreateTemplate = ko.observable(false);

  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  nameSlug = ko.observable('').extend(slugValidation);
  template = ko.observable(false);

  globalError = ko.observable('');

  constructor(params: { id: string }) {
    super({});

    let mePromise = usersApi.me();
    let trialPromise = trialsApi.retrieve(params.id);
    let promise = Promise.all([mePromise, trialPromise]).then(([userData, trialData]) => {
      // in library mode, we can't copy a normal trial to a template trial, since
      // the normal trial might not have mm & sv template_id set, and we need library mode templates
      // to always have library references set.
      this.canCreateTemplate(
        canCreateTemplate(userData) && (trialData.template || trialData.edit_mode !== 'library')
      );
      this.trialData = trialData;
      this.nameJson(makeTrialCopyName(trialData));
      this.slugGenerator = new SlugGenerator(this.nameJson, null, this.nameSlug, {
        canEdit: true,
        fillIfEmpty: true,
      });
      this.slugGenerator; // make compiler shut up
    });
    this.loadedAfter(promise);
  }

  save = () => {
    if (this.validateLocal(this)) {
      let data: trialsApi.TrialCopyData = {
        name_json: this.nameJson(),
        name_slug: this.nameSlug(),
        template: this.template(),
      };

      this.executeSaveRequest(trialsApi.copy(this.trialData.id, data)).then((validation) => {
        this.onRemoteValidation({}, this, validation);
        setGlobalError(this.globalError, validation);
      });
    }
  };
}

export let trialCopy = {
  name: 'trial-copy',
  viewModel: TrialCopyScreen,
  template: template,
};

ko.components.register(trialCopy.name, trialCopy);
