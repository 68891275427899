import * as ko from 'knockout';

import { BaseForm } from '../screens/base_form';
import { createWithComponent } from '../utils/ko_utils';
import { Deferred } from '../utils/deferred';
import { app } from '../app';
import i18n from '../i18n';
import { AutoChartOptionData, getAutoChartOption, saveAutoChartOption } from '../api/auto_chart_options';
import { emptyToNull, readDecimal } from '../utils';

const template = require('raw-loader!../../templates/components/auto_chart_options_edit.html').default;

class AutoChartOptionEdit extends BaseForm<AutoChartOptionData & { id?: string }> {
  private mmId: string;
  private chartType: string;

  threshold = ko.observable('').extend({ number: true, serverError: true });

  constructor(
    params: {
      mmId: string;
      chartType: string;
      result: Deferred<AutoChartOptionData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super({ result: params.result });

    this.mmId = params.mmId;
    this.chartType = params.chartType;

    const promise = getAutoChartOption({
      measurement_meta_id: this.mmId,
      chart_type: this.chartType,
    }).then((data) => {
      this.threshold(readDecimal(data.threshold));
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = async () => {
    if (this.validateLocal(this)) {
      const data: AutoChartOptionData = {
        measurement_meta_id: this.mmId,
        chart_type: this.chartType,
        threshold: emptyToNull(this.threshold()),
      };
      const validation = await this.executeSaveRequest(saveAutoChartOption(data));
      this.onRemoteValidation(data, this, validation);
    } else {
      this.saving(false);
    }
  };
}

const autoChartOptionsEdit = {
  name: 'auto-chart-options-edit',
  viewModel: createWithComponent(AutoChartOptionEdit),
  template,
};

ko.components.register(autoChartOptionsEdit.name, autoChartOptionsEdit);

export function openAutoChartOptionsEdit(params: { mmId: string; chartType: string }) {
  return app.formsStackController.push({
    title: i18n.t('Chart options')(),
    name: autoChartOptionsEdit.name,
    params: {
      result: new Deferred<{}>(),
      ...params,
    },
  });
}
