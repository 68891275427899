import * as ko from 'knockout';
import page from 'page';

import i18n from '../i18n';
import { BaseLoadingScreen } from './base_loading_screen';
import * as dashboardApi from '../api/dashboard';
import { ChartGroup, makeAutoChartGroup } from '../models/chart_group';
import { ChartGroupListDelegate } from '../components/chart_group_list';
import { session } from '../session';
import { TrialFactsScreenDelegate } from './trial_facts';

let template = require('raw-loader!../../templates/dashboard.html').default;

class DashboardScreen extends BaseLoadingScreen implements ChartGroupListDelegate, TrialFactsScreenDelegate {
  trialId: string;
  openOnMap: boolean;

  chartsTitle = i18n.t('Monitoring charts')();
  trialName = ko.observable('');
  allChartGroups = ko.observableArray<ChartGroup>();
  showTourOverlay = ko.observable(false);

  selectedGroupIndex = ko.observable(0);
  groupSelectionTitle = ko.observable('');
  isActionsMenuOpen = ko.observable(false);

  chartGroups = ko.pureComputed(() => {
    if (this.allChartGroups().length === 0) {
      return [];
    }

    return [this.allChartGroups()[this.selectedGroupIndex()]];
  });

  canExport = ko.pureComputed(() => session.isAtLeastEditor());

  constructor(params: { trialId: string; openOnMap: boolean }) {
    super();

    this.trialId = params.trialId;
    this.openOnMap = params.openOnMap;

    this.selectedGroupIndex.subscribe(() => this.refreshCurrent());
    this.loadedAfter(this.reload());
  }

  private async reload() {
    const metaData = await dashboardApi.meta(this.trialId);

    this.trialName(metaData.trial_name);

    if (metaData.scheduled_visits.length === 0) {
      this.groupSelectionTitle(i18n.t('Select observations group')());
      this.allChartGroups(
        metaData.datasets.map((data, index) =>
          makeAutoChartGroup(
            metaData.can_edit,
            { dataset_id: data.id, scheduled_visit_id: null },
            data,
            [data],
            index,
            this.allChartGroups()
          )
        )
      );
    } else {
      this.groupSelectionTitle(i18n.t('Select scheduled visit')());
      this.allChartGroups(
        metaData.scheduled_visits.map((data, index) =>
          makeAutoChartGroup(
            metaData.can_edit,
            { dataset_id: null, scheduled_visit_id: data.id },
            data,
            metaData.datasets,
            index,
            this.allChartGroups()
          )
        )
      );
    }
    this.refreshCurrent();
  }

  private refreshCurrent = () => {
    let groups = this.allChartGroups();
    if (groups.length > 0) {
      groups[this.selectedGroupIndex()].refresh();
    }
  };

  dispose() {
    for (let group of this.allChartGroups()) {
      group.dispose();
    }
  }

  async onMainTabSelected() {
    this.loading(true);
    try {
      await this.reload();
    } finally {
      this.loading(false);
    }
  }

  goBack = () => {
    page(session.toTenantPath('/trials/'));
  };

  openActionsMenu = () => {
    this.isActionsMenuOpen(true);
  }

  closeActionsMenu = () => {
    this.isActionsMenuOpen(false);
  }
}

export let dashboard = {
  name: 'dashboard',
  viewModel: DashboardScreen,
  template: template,
};

ko.components.register(dashboard.name, dashboard);
