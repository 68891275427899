import * as ko from 'knockout';
import { fitMap } from './map';

declare const google: any;

ko.bindingHandlers['mapGoTo'] = {
  init: (element: Element, valueAccessor: () => void) => {
    let autocomplete = new google.maps.places.Autocomplete(element);
    autocomplete.setFields(['geometry']);

    let listener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
      let place = autocomplete.getPlace();
      if (place && place.geometry) {
        let position = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        fitMap([position]);
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      google.maps.event.removeListener(listener);
    });
  },
};
