import * as ko from 'knockout';

import i18n from '../i18n';
import * as trialsApi from '../api/trials';
import { ImportEntitiesDelegate } from '../components/import_entities';

let template = require('raw-loader!../../templates/import_update_facts.html').default;

class ImportUpdateFactsScreen implements ImportEntitiesDelegate {
  private trialId: string;

  title = i18n.t('Bulk update observations');
  description = i18n.t(
    'To update observations, download the Excel template, fill-in the desired observations and then upload the file.'
  )();
  backTitle = i18n.t('Go back')();

  templateBaseName = 'observations-update';
  importUrl: string;

  loading = ko.observable(true);
  mmId = ko.observable<string>(null);
  mms = ko.observableArray<{ id: string; name: string }>();

  downloadTemplateEnabled = ko.pureComputed(() => {
    return !!this.mmId();
  });

  constructor(params: { id: string }) {
    this.trialId = params.id;
    this.importUrl = `/api/trials/${this.trialId}/import_update_facts/`;
    this.load();
  }

  private async load() {
    this.mms(
      [{ id: '', name: i18n.t('Select')() }].concat(
        (await trialsApi.getImportUpdateOptions(this.trialId)).mms
      )
    );
    this.loading(false);
  }

  downloadTemplate(): Promise<Blob> {
    return trialsApi.downloadImportUpdateTemplate(this.trialId, this.mmId());
  }
}

export const importUpdateFacts = {
  name: 'import-update-facts',
  viewModel: ImportUpdateFactsScreen,
  template,
};

ko.components.register(importUpdateFacts.name, importUpdateFacts);
