import {
  ListRequestParams,
  listRequest,
  request,
  ValidationResult,
  requestWithValidation,
  requestRemoveEntity,
  RemoveResult,
  listParamsToQueryString,
} from './request';
import { DocumentCategoryData } from './document_categories';
import { FileUploadEndpoint } from '../cloud_storage_upload';

export interface TrialDocumentData {
  id?: string;
  sync: boolean;
  categories: DocumentCategoryData[];
  file_name: string;
  file_url: string; // detail-only
  user_file_name: string;
  mime_type: string;
  // present only in list
  file_size?: number;
  modified?: string;
  created_by_name?: string;
  modified_by_name?: string;
}

export interface TrialDocumentFilters {
  category_ids: string | string[];
  sync: string;
  name: string;
}

export function list(
  trialId: string,
  params: ListRequestParams & TrialDocumentFilters
): Promise<TrialDocumentData[]> {
  return listRequest(`/api/trials/${trialId}/documents/`, params);
}

export function countList(trialId: string, params: TrialDocumentFilters): Promise<{ count: number }> {
  return request(
    'GET',
    `/api/trials/${trialId}/documents/count/?${listParamsToQueryString(params as {})}`,
    params
  );
}

export function retrieve(trialId: string, id: string): Promise<TrialDocumentData> {
  return request('GET', `/api/trials/${trialId}/documents/${id}/`);
}

export function save(trialId: string, data: TrialDocumentData): Promise<ValidationResult> {
  let endpoint = `/api/trials/${trialId}/documents/`;
  let method = 'POST';
  if (data.id) {
    endpoint += data.id + '/';
    method = 'PUT';
  }

  return requestWithValidation(method, endpoint, data);
}

export function remove(trialId: string, id: string): Promise<RemoveResult> {
  return requestRemoveEntity(`/api/trials/${trialId}/documents/${id}/`);
}

export function bulkRemove(trialId: string, params: TrialDocumentFilters): Promise<RemoveResult> {
  return request(
    'POST',
    `/api/trials/${trialId}/documents/bulk_remove/?${listParamsToQueryString(params as {})}`,
    params
  );
}

export function getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
  return request('POST', '/api/trials/0/documents/upload_url/', {
    content_type: contentType,
  });
}

export function addArchive(
  trialId: string,
  data: { archive_file_name: string; categories: DocumentCategoryData[] }
): Promise<ValidationResult> {
  return requestWithValidation('POST', `/api/trials/${trialId}/documents/add_archive/`, data);
}

export function downloadArchive(
  trialId: string,
  filters: TrialDocumentFilters
): Promise<{ task_id: string }> {
  return request(
    'POST',
    `/api/trials/${trialId}/documents/download_archive/?${listParamsToQueryString(filters as {})}`
  );
}
