import * as ko from 'knockout';
import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { I18nText, translate } from '../i18n_text';
import { sameIds, BoolDict, tryFormatDate, asArray } from '../utils';
import { createWithComponent, unwrap } from '../utils/ko_utils';
import { deflateList, deflateSingle, serializeDate } from '../api/serialization';
import { openEditFilterListDialog } from './edit_activatable_list_dialog';

let template = require('raw-loader!../../templates/components/list_filters.html').default;

/**
 * Creates a filter item from a filter delegate. A filter item has methods and
 * properties that are used by the list-filters template, whereas a filter
 * delegate is a plain data object.
 *
 * @param {FilterDelegate} delegate - The filter delegate.
 * @returns {FilterItem} the new filter item.
 */
function filterFactory(delegate: FilterDelegate): FilterItem {
  if (isRange(delegate)) {
    return new RangeFilter(delegate.minValue, delegate.maxValue, delegate.title, delegate.tooltip);
  } else if (isDate(delegate)) {
    return new DateFilter(delegate.value, delegate.title, delegate.tooltip);
  } else if (isText(delegate)) {
    return new TextFilter(delegate.textValue, delegate.title, delegate.tooltip);
  } else {
    return new Filter(delegate);
  }
}

class ListFilters {
  filters: FilterItem[];
  allowEdit = ko.observable(false);

  readonly initialDisplayedFilters = 5;

  constructor(params: { filters: FilterDelegate[]; allowEdit?: boolean }) {
    this.filters = params.filters.map(filterFactory);
    this.allowEdit(!!params.allowEdit);

    if (this.allowEdit()) {
      this.filters.slice(this.initialDisplayedFilters).forEach((filter) => filter.isVisible(false));
    }
  }

  dispose() {
    for (let filter of this.filters) {
      filter.dispose();
    }
  }

  selections = ko.pureComputed(() => {
    let res: Result<{}>[] = [];
    for (let f of this.filters) {
      for (let r of f.results()) {
        if (r.selected()) {
          res.push(r);
        }
      }
    }

    return res;
  });

  showReset = ko.pureComputed(() => {
    if (this.filters.length <= 1) {
      return false;
    }
    for (const filter of this.filters) {
      if (filter instanceof Filter && filter.isMulti()) {
        if (filter.results().some((result) => result.selected() !== result.selectedByDefault)) {
          return true;
        }
      } else if (filter instanceof Filter && isSelect(filter.delegate)) {
        if (filter.delegate.value() !== filter.delegate.choices[0]) {
          return true;
        }
      } else if (filter instanceof DateFilter) {
        if (!!filter.dateValue()) {
          return true;
        }
      } else if (filter instanceof TextFilter) {
        if (!!filter.textValue()) {
          return true;
        }
      } else if (filter instanceof RangeFilter) {
        if (!!filter.minValue() || !!filter.maxValue()) {
          return true;
        }
      }
    }
    return false;
  });

  editFiltersTooltipText = () => {
    return i18n.t('Edit filter list')();
  };

  resetAllFilters = () => {
    this.filters.forEach((filter) => filter.reset());
  };

  resetTooltipText = () => {
    return i18n.t('Reset the filters to default')();
  };

  editFilters = () => {
    const filtersState = this.filters.map((filter) => ({
      title: filter.getTitle(),
      isActive: filter.isVisible,
    }));
    openEditFilterListDialog(filtersState);
  };
}

abstract class FilterItem {
  /**
   * The filter type, used to identify it in the DOM.
   */
  readonly filterTypeName: 'select' | 'date' | 'text' | 'range';

  /**
   * Whether the filter is visible in the UI.
   */
  isVisible = ko.observable(true);

  /**
   * The results of the filter, used to display the selected items.
   * Only useful for search and select filters, but here for legacy reasons.
   */
  results: ko.Subscribable<any[]>;

  constructor() {
    ko.when(() => !this.isVisible(), this.onNotVisible);
  }

  /**
   * The underlaying observable contaning the value of the filter.
   */
  abstract getValueObservable(): ko.Subscribable<any>;

  /**
   * Returns the filter value as a parameter object to be used in a request.
   *
   * @param {string} parameterName - The name of the parameter.
   * @returns {object} the filter value as a parameter.
   */
  abstract asParameter(parameterName: string): Record<string, string | string[]>;

  /**
   * Returns the title of the filter.
   */
  abstract getTitle(): string;

  /**
   * Resets the filter to its default value.
   */
  abstract reset(): void;

  onNotVisible = () => {
    this.reset();
  };

  dispose() {}
}

export interface IdData {
  id?: string | ko.Observable<string>;
  name_json?: I18nText;
  name?: string;
  selected_by_default?: boolean;
}

interface FilterDelegateSearch<T extends IdData> {
  disableSearch?: boolean;
  list: (params: ListRequestParams) => Promise<T[]>;
  entities: ko.ObservableArray<T>;
  title: string;
  tooltip?: string;
}

interface FilterDelegateSelect<T extends IdData> {
  choices: T[];
  value: ko.Observable<T>;
  title: string;
  tooltip?: string;
}

interface FilterDelegateDate {
  value: ko.Observable<Date>;
  title: string;
  tooltip?: string;
}

interface FilterDelegateText {
  textValue: ko.Observable<string>;
  title: string;
  tooltip?: string;
}

interface FilterDelegateRange<T> {
  minValue: ko.Observable<T>;
  maxValue: ko.Observable<T>;
  title: string;
  tooltip?: string;
}

export type FilterDelegate =
  | FilterDelegateSearch<IdData>
  | FilterDelegateSelect<IdData>
  | FilterDelegateDate
  | FilterDelegateText
  | FilterDelegateRange<string | Date>;

function isSearch(delegate: FilterDelegate): delegate is FilterDelegateSearch<IdData> {
  return !!(<any>delegate).list;
}

function isSelect(delegate: FilterDelegate): delegate is FilterDelegateSelect<IdData> {
  return !!(<any>delegate).choices;
}

function isText(delegate: FilterDelegate): delegate is FilterDelegateText {
  return !!(<any>delegate).textValue;
}

function isDate(delegate: FilterDelegate): delegate is FilterDelegateDate {
  return !isSearch(delegate) && !isSelect(delegate) && !isText(delegate) && !isRange(delegate);
}

function isRange(delegate: FilterDelegate): delegate is FilterDelegateRange<string | Date> {
  return !!(<any>delegate).minValue && !!(<any>delegate).maxValue;
}

export function getFilterObservable(delegate: FilterDelegate): ko.Subscribable<{}> {
  return filterFactory(delegate).getValueObservable();
}

export function convertToParameter(delegate: FilterDelegate, parameterName: string) {
  return filterFactory(delegate).asParameter(parameterName);
}

export function updateFilterValue(delegate: FilterDelegate, value: string | string[]) {
  if (isText(delegate)) {
    delegate.textValue(asArray(value)[0]);
  } else if (isSelect(delegate)) {
    delegate.choices.forEach((choice) => {
      if (choice.id == value) {
        delegate.value(choice);
      }
    });
  } else if (isDate(delegate)) {
    delegate.value(new Date(asArray(value)[0]));
  }
}

class Result<T extends IdData> {
  name: string;
  selectedByDefault: boolean;

  constructor(private filter: Filter, public entity: T, selected: boolean, selectedByDefault?: boolean) {
    this.name = entity.name_json ? translate(entity.name_json) : entity.name ?? '';
    this.selectedByDefault = selectedByDefault || false;
    this.selected(selected);
  }

  selected = ko.observable(false);
  icon = ko.pureComputed(() => {
    if (this.selected()) {
      return this.filter.isMulti() ? 'check_box' : 'radio_button_checked';
    } else {
      return this.filter.isMulti() ? 'check_box_outline_blank' : 'radio_button_unchecked';
    }
  });

  toggle = () => {
    if (this.filter.isMulti()) {
      this.selected(!this.selected());
    } else if (!this.selected()) {
      this.selected(true);
      for (let res of this.filter.results()) {
        if (res !== this && res.selected()) {
          res.selected(false);
        }
      }
      this.filter.save();
    }
  };

  canRemove = ko.pureComputed(() => this.filter.isMulti());

  remove = () => {
    this.selected(false);
    this.filter.save();
  };
}

class RangeFilter<T> extends FilterItem {
  filterTypeName = 'range' as const;
  results = ko.observable([]);

  isOpen = ko.observable(false);
  icon = ko.pureComputed(() => (this.isOpen() ? 'keyboard_arrow_up' : 'keyboard_arrow_down'));

  isDate = ko.pureComputed(() =>
    this.minValue
      .rules()
      .map((validationRule) => validationRule.rule)
      .includes('date')
  );

  rangeToDisplay = ko.pureComputed(() => {
    const min = this.serialize(this.minValue);
    const max = this.serialize(this.maxValue);
    if (min && max) {
      return `[${min}, ${max}]`;
    } else if (min) {
      return `≥ ${min}`;
    } else if (max) {
      return `≤ ${max}`;
    } else {
      return '';
    }
  });

  validationError = ko.pureComputed(() => {
    if (!this.minValue()) {
      this.minValue.clearError();
    }
    if (!this.maxValue()) {
      this.maxValue.clearError();
    }

    return (
      (this.minValue.isModified() && !this.minValue.isValid() && this.minValue.error()) ||
      (this.maxValue.isModified() && !this.maxValue.isValid() && this.maxValue.error()) ||
      ''
    );
  });

  constructor(
    public minValue: ko.Observable<T>,
    public maxValue: ko.Observable<T>,
    public title: string,
    public tooltip: string | null = null
  ) {
    super();
  }

  open = () => {
    this.isOpen(true);
  };

  close = () => {
    this.isOpen(false);
  };

  reset = () => {
    this.minValue(null);
    this.maxValue(null);
    this.minValue.clearError();
    this.maxValue.clearError();
  };

  serialize(value: ko.Observable) {
    if (this.isDate()) {
      return value() ? serializeDate(value()) : '';
    }
    return value() ? value().toString() : '';
  }

  asParameter(parameterName: string) {
    return {
      ['min_' + parameterName]: this.serialize(this.minValue),
      ['max_' + parameterName]: this.serialize(this.maxValue),
    };
  }

  getTitle() {
    return this.title;
  }

  getValueObservable() {
    return this.rangeToDisplay;
  }
}

class DateFilter extends FilterItem {
  filterTypeName = 'date' as const;
  results = ko.observable([]);

  constructor(
    public dateValue: ko.Observable<Date>,
    public title: string,
    public tooltip: string | null = null
  ) {
    super();
  }

  reset = () => {
    this.dateValue(null);
  };

  asParameter(parameterName: string) {
    return { [parameterName]: serializeDate(this.dateValue()) };
  }

  getValueObservable() {
    return this.dateValue;
  }

  getTitle() {
    return this.title;
  }
}

class TextFilter extends FilterItem {
  filterTypeName = 'text' as const;
  results = ko.observable([]);

  constructor(
    public textValue: ko.Observable<string>,
    public title: string,
    public tooltip: string | null = null
  ) {
    super();
  }

  reset = () => {
    this.textValue('');
  };

  asParameter(parameterName: string) {
    return { [parameterName]: this.textValue() };
  }

  getValueObservable(): ko.Subscribable<any> {
    return this.textValue;
  }

  getTitle() {
    return this.title;
  }
}

class Filter extends FilterItem {
  // If the dropdown has more than 9 results, a scrollbar will appear,
  // and it'll be harder for to user to search the results. Therefore,
  // the search bar will be displayed.
  DISABLE_SEARCH_BAR_RESULTS_COUNT = 9;
  filterTypeName = 'select' as const;

  loading = ko.observable(false);
  isOpen = ko.observable(false);
  searchText = ko.observable('').extend({ rateLimit: 200 });
  lastSearchId = 0;
  hasMoreResults = ko.observable(false);

  isSearchBarVisible = ko.pureComputed(() => {
    return (
      (this.results() && this.results().length >= this.DISABLE_SEARCH_BAR_RESULTS_COUNT) ||
      this.searchText() != ''
    );
  });

  private searchResults = ko.observableArray<Result<IdData>>();

  placeholderText = i18n.t('Search')();

  private subscriptions: ko.Subscription[] = [];

  constructor(public delegate: FilterDelegate) {
    super();

    this.subscriptions.push(this.searchText.subscribe(this.onSearchChanged));
    if (isSearch(delegate)) {
      this.searchResults(
        delegate.entities().map((entity) => new Result(this, entity, true, entity.selected_by_default))
      );
      // Subscribe to changes in entities
      this.subscriptions.push(
        delegate.entities.subscribe((newEntities) => {
          this.searchResults(
            newEntities.map((entity) => new Result(this, entity, true, entity.selected_by_default))
          );
        })
      );
    }
  }

  asParameter(parameterName: string): Record<string, string | string[]> {
    if (isSearch(this.delegate)) {
      return { [parameterName]: deflateList(this.delegate.entities) };
    }
    if (isSelect(this.delegate)) {
      return { [parameterName]: deflateSingle(this.delegate.value()) };
    }

    return { [parameterName]: '' };
  }

  getValueObservable(): ko.Subscribable<any> {
    if (isSearch(this.delegate)) {
      return this.delegate.entities;
    }
    if (isSelect(this.delegate)) {
      return this.delegate.value;
    }
    return ko.observable('');
  }

  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
  }

  results: ko.Computed<Result<IdData>[]> = ko.pureComputed(() => {
    if (isSearch(this.delegate)) {
      return this.searchResults();
    } else if (isSelect(this.delegate)) {
      let value = this.delegate.value();
      return this.delegate.choices.map((entity) => new Result(this, entity, entity === value));
    } else {
      return [];
    }
  });

  title = ko.pureComputed(() => {
    if (isSelect(this.delegate)) {
      return (
        this.delegate.title +
        (this.delegate.value() ? ': ' + (this.delegate.value().name ?? '').toLocaleLowerCase() : '')
      );
    } else if (isDate(this.delegate)) {
      return (
        this.delegate.title + (this.delegate.value() ? ': ' + tryFormatDate(this.delegate.value()) : '')
      );
    } else {
      return this.delegate.title;
    }
  });

  isMulti = ko.pureComputed(() => {
    return isSearch(this.delegate);
  });

  canSearch = ko.pureComputed(() => {
    return isSearch(this.delegate) && !this.delegate.disableSearch;
  });

  private onSearchChanged = () => {
    if (!isSearch(this.delegate)) {
      return;
    }

    let term = this.searchText().trim().toLocaleLowerCase();
    let limit = 100;
    let results = this.delegate.list({
      offset: 0,
      limit: limit + 1,
      name_prefix: term,
    });

    this.loading(true);

    let lastSearchId = ++this.lastSearchId;
    return results
      .then((results) => {
        if (this.lastSearchId != lastSearchId) {
          return;
        }

        this.loading(false);

        this.hasMoreResults(results.length > limit);
        results = results.slice(0, limit);
        let known: BoolDict = {};
        let first = this.searchResults().filter((res) => res.selected());
        for (let res of first) {
          let id = unwrap(res.entity.id);
          if (id) {
            known[id] = true;
          }
        }
        results = results.filter((res) => !known[<string>res.id]);
        this.searchResults(
          first.concat(results.map((res) => new Result(this, res, false, res.selected_by_default)))
        );
      })
      .catch((e) => {
        this.loading(false);
        throw e;
      });
  };

  anySelected = ko.pureComputed(() =>
    isSearch(this.delegate) ? this.delegate.entities().length > 0 : !!getFilterObservable(this.delegate)()
  );
  selectionsCount = ko.pureComputed(() => (isSearch(this.delegate) ? this.delegate.entities().length : 0));
  icon = ko.pureComputed(() =>
    isSearch(this.delegate) && this.isOpen() ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
  );

  open = () => {
    this.isOpen(true);
    this.onSearchChanged();

    // wait for input to appear, then focus it
    setTimeout(() => {
      $('.search-input > input').focus();
    }, 0);
  };

  close = () => {
    this.isOpen(false);
    this.save();
  };

  reset = () => {
    if (isSelect(this.delegate)) {
      this.delegate.value(this.delegate.choices[0]);
    }
    if (!this.isMulti()) {
      return;
    }
    this.results().forEach((result) => result.selected(result.selectedByDefault));
    this.isOpen(false);
    this.save();
  };

  getTitle() {
    return this.title();
  }

  save() {
    let selected = this.results()
      .filter((res) => res.selected())
      .map((res) => res.entity);
    if (isSearch(this.delegate)) {
      if (!sameIds(this.delegate.entities(), selected)) {
        this.delegate.entities(selected);
      }
    } else {
      let value = this.getValueObservable();
      if (value() !== selected[0]) {
        value(selected[0]);
      }
    }
  }
}

ko.components.register('list-filters', {
  viewModel: createWithComponent(ListFilters),
  template: template,
});
