import { parseDate } from '../api/serialization';
import { session } from '../session';

export function getExpirationDays(): number {
  if (!session.tenant()) {
    return null;
  }

  let expiration = parseDate(session.tenant().expiration_date);
  let expirationWithGrace = expiration;
  const GRACE_PERIOD_DAYS = 7;
  if(!session.tenant().is_free_trial)
    expirationWithGrace.setDate(expiration.getDate() + GRACE_PERIOD_DAYS + 1);

  return Math.ceil((expirationWithGrace.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
}
