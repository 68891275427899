import * as ko from 'knockout';

import { DimensionMeta } from '../models/dimension_meta';
import { Crop } from '../models/crop';
import { CROP_SLUG } from '../api/dimension_metas';
import * as cropsApi from '../api/crops';
import { BaseDimensionRecordEditDelegate } from '../components/base_dimension_record_edit';
import { BaseForm } from './base_form';
import { ValidationResult } from '../api/request';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { canEditCrops } from '../permissions';

let template = require('raw-loader!../../templates/crop_edit.html').default;

class CropEditScreen implements BaseDimensionRecordEditDelegate<cropsApi.CropData, Crop> {
  disableApplications = true;
  allowAnonymize = true;
  allowLinkingCrops = false;
  anyoneCanEdit = false;

  dimensionMetaId = CROP_SLUG;
  dimensionId: string;
  result: Deferred<cropsApi.CropData>;
  private initialName: string;
  canEdit = ko.observable(canEditCrops());

  dimension = ko.observable<Crop>(null);

  constructor(params: { id: string; initialName?: string; result?: Deferred<cropsApi.CropData> }) {
    this.dimensionId = params.id;
    this.result = params.result;
    this.initialName = params.initialName;
  }

  fetch(dimensionMetaId: string, id: string) {
    return cropsApi.retrieve(id);
  }

  instantiate(data: cropsApi.CropData, dimensionMeta: DimensionMeta) {
    let crop = new Crop(data, dimensionMeta);
    if (!data) {
      if (this.initialName) {
        crop.nameJson(asI18nText(this.initialName));
      }
    }

    return crop;
  }

  onRemoteValidation(form: BaseForm<cropsApi.CropData>, validation: ValidationResult) {
    if (!validation.isValid && validation.errors) {
      form.applyModelServerErrors(this.dimension, validation.errors);
    }
  }

  saveRequest(data: cropsApi.CropData) {
    return cropsApi.save(data);
  }
}

export let cropEdit = {
  name: 'crop-edit',
  viewModel: CropEditScreen,
  template: template,
};

ko.components.register(cropEdit.name, cropEdit);
