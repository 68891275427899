import { PlotsSiteEditModel, SitePlotData } from './plots_edit_model';
import { appendChildIfNotExists, el, removeChildIfExists } from '../../../utils';
import i18n from '../../../i18n';
import { choose } from '../../../utils/dom';

export const plotColorClasses = [
  'light-green',
  'lime',
  'light-green lighten-2',
  'lime lighten-2',
  'light-green lighten-4',
  'lime lighten-4',
  'light-green darken-2',
  'lime darken-2',
  'light-green darken-4',
  'lime darken-4',
  'light-green lighten-1',
  'lime lighten-1',
  'light-green darken-1',
  'lime darken-1',
  'light-green lighten-3',
  'lime lighten-3',
  'light-green darken-3',
  'lime darken-3',
];

export interface PlotViewDelegate {
  onAddPlot(edit: PlotsSiteEditModel, col: number, row: number): void;
  onPlotEditDetails(edit: PlotsSiteEditModel, plot: SitePlotData): void;
  onPlotExpand(edit: PlotsSiteEditModel, plot: SitePlotData, column: number, row: number): void;
  onPlotShrink(edit: PlotsSiteEditModel, plot: SitePlotData, column: number, row: number): void;
  onMouseOverPlot(element: HTMLElement, plot: SitePlotData): void;
  nColumns(): number;
  nRanges(): number;
}

export interface PlotViewParams {
  edit?: PlotsSiteEditModel;
  plot?: SitePlotData;
  col?: number;
  row?: number;
}
type ExpandElements = { expandMoreIcon: Element; expandLessIcon: Element; helpMessage: Element };

const clearChangeSizeActions = (root: HTMLElement, elements: ExpandElements) => {
  const { expandMoreIcon, helpMessage, expandLessIcon } = elements;
  removeChildIfExists(root, expandMoreIcon);
  removeChildIfExists(root, helpMessage);
  removeChildIfExists(root, expandLessIcon);
};

const applyChangeSizeActions = (root: HTMLElement, elements: ExpandElements, viewParams: PlotViewParams) => {
  const { expandMoreIcon, helpMessage, expandLessIcon } = elements;
  const { edit, plot } = viewParams;
  root.parentElement.setAttribute('rowspan', plot.length.toString());
  if (edit.plotDesign === 'manual') {
    removeChildIfExists(root, expandMoreIcon);
    removeChildIfExists(root, expandLessIcon);
    removeChildIfExists(root, helpMessage);
    return;
  }
  if (!edit.plotExpandable(plot)) {
    removeChildIfExists(root, expandMoreIcon);
  } else if (edit.plotExpandable(plot)) {
    appendChildIfNotExists(root, expandMoreIcon);
  }

  if (!edit.plotShrinkable(plot)) {
    removeChildIfExists(root, expandLessIcon);
  } else if (edit.plotShrinkable(plot)) {
    appendChildIfNotExists(root, expandLessIcon);
  }

  if (plot.length > 1) {
    appendChildIfNotExists(root, helpMessage);
  } else {
    removeChildIfExists(root, helpMessage);
  }
};

export function plotView(delegate?: PlotViewDelegate) {
  const root = el('div');

  const expandMoreIcon = el('i', 'material-icons expand-more');
  expandMoreIcon.textContent = 'expand_more';

  const expandLessIcon = el('i', 'material-icons expand-less');
  expandLessIcon.textContent = 'expand_less';

  const helpMessage = el('div', 'plot-not-draggable');
  const infoIcon = el('i', 'material-icons');
  infoIcon.textContent = 'info_outline';

  helpMessage.append(infoIcon);
  helpMessage.append(i18n.t('To change the position of the plot, shrink size to 1')());

  const contentContainer = el('div');
  const contentView = choose(
    contentContainer,
    ({ edit, plot }: PlotViewParams) => {
      if (!plot || plot.excluded) {
        return 'excluded';
      }
      return 'name';
    },
    {
      excluded: () => plotName(i18n.t('Removed plot')()),
      name: () => plotName(),
    }
  );

  root.appendChild(expandMoreIcon);
  root.appendChild(expandLessIcon);
  root.appendChild(contentContainer);

  const expandElemets: ExpandElements = { expandLessIcon, expandMoreIcon, helpMessage };
  return {
    root,
    update: (params: PlotViewParams) => {
      let { edit, plot, row, col } = params;

      root.style.display = plot ? null : 'none';
      if (!plot) {
        return;
      }

      if (!plot.excluded) {
        applyChangeSizeActions(root, expandElemets, { edit, plot });
        root.parentElement.setAttribute('rowspan', plot.length.toString());
      } else {
        clearChangeSizeActions(root, expandElemets);
        root.style.display = null;
      }

      let css = plot.excluded ? 'excluded ' : '';
      css += plotColorClasses[plot.colorIdx % plotColorClasses.length];
      if (root.classList.contains('gu-transit')) {
        css += ' gu-transit';
      }
      root.className = 'plot-widget ' + css;

      if (delegate) {
        root.onmouseover = (evt) => {
          const plotWithDimensions = { ...plot, dimensionNames: edit.dimensionNamesExSite(plot) };
          delegate.onMouseOverPlot(root, plotWithDimensions);
        };
        root.onmouseleave = (evt) => {
          delegate.onMouseOverPlot(null, null);
        };
        root.onclick = (evt) => {
          evt.stopPropagation();
          delegate.onPlotEditDetails(edit, plot);
        };
        expandMoreIcon.onclick = (evt) => {
          evt.stopPropagation();
          delegate.onPlotExpand(edit, plot, col, row);
        };
        expandLessIcon.onclick = (evt) => {
          evt.stopPropagation();
          delegate.onPlotShrink(edit, plot, col, row);
        };
      }

      contentView.update(params);
    },
  };
}
export function plotPreview(delegate?: PlotViewDelegate) {
  const root = el('div');

  const contentContainer = el('div');
  let showDimensionNames = true;
  let showPlotName = true;
  let showTreatmentName = true;
  if (delegate) {
    if (delegate.nColumns() > 10) {
      showDimensionNames = false;
      showTreatmentName = false;
    }
    if (delegate.nColumns() > 30) {
      showPlotName = false;
    }
  }

  const contentView = choose(
    contentContainer,
    ({ plot }: PlotViewParams) => {
      if (!plot || plot.excluded) {
        return 'excluded';
      }
      return 'name';
    },
    {
      excluded: () => plotName(i18n.t('Removed plot')()),
      name: () => plotNamePreview(showDimensionNames, showPlotName, showTreatmentName),
    }
  );

  root.appendChild(contentContainer);

  return {
    root,
    update: (params: PlotViewParams) => {
      let { edit, plot } = params;

      root.style.display = plot ? null : 'none';
      if (!plot) {
        return;
      }

      if (!plot.excluded) {
        root.parentElement.setAttribute('rowspan', plot.length.toString());
      } else {
        root.style.display = null;
      }
      let css = plot.excluded ? 'excluded ' : '';
      css += plotColorClasses[plot.colorIdx % plotColorClasses.length];
      if (root.classList.contains('gu-transit')) {
        css += ' gu-transit';
      }
      if (edit.nCols() <= 10) {
        css += ' dashed-border';
      }
      root.className = 'preview-plot-widget ' + css;

      contentView.update(params);
    },
  };
}

function plotName(nameOverride?: string) {
  const root = el('div', 'plot-widget-content');
  const name = el('div', 'plot-number');
  const dimensions = el('div', 'plot-subheadings');
  const treatmentName = el('div', 'plot-subheadings');

  root.appendChild(name);
  root.appendChild(dimensions);
  root.appendChild(treatmentName);

  return {
    root,
    update: ({ edit, plot }: PlotViewParams) => {
      if (nameOverride) {
        name.textContent = nameOverride;
      } else if (edit.isLoadingName(plot)) {
        name.textContent = i18n.t('Loading…')();
      } else {
        name.textContent = edit.plotName(plot);
      }
      dimensions.textContent = edit.dimensionNamesExSite(plot);
      treatmentName.textContent = edit.treatmentName(plot);
    },
  };
}

function plotNamePreview(
  showDimensions: boolean = true,
  showName: boolean = true,
  showTreatmentName: boolean = true,
  nameOverride?: string
) {
  const root = el('div', 'preview-plot-widget-content');
  const name = el('div', 'plot-number');
  const dimensions = el('div', 'plot-subheadings');
  const treatmentName = el('div', 'plot-subheadings');

  if (showName) root.appendChild(name);
  if (showDimensions) root.appendChild(dimensions);
  if (showTreatmentName) root.appendChild(treatmentName);

  return {
    root,
    update: ({ edit, plot }: PlotViewParams) => {
      if (nameOverride) {
        name.textContent = nameOverride;
      } else if (edit.isLoadingName(plot)) {
        name.textContent = i18n.t('Loading…')();
      } else {
        const plotName = edit.plotName(plot);
        name.textContent = plotName;
      }
      dimensions.textContent = edit.dimensionNamesExSite(plot);
      treatmentName.textContent = edit.treatmentName(plot);
    },
  };
}

export function plotNotPlantedView(delegate?: PlotViewDelegate) {
  let root = el('div', 'plot-not-planted');
  let content = el('span');

  content.textContent = i18n.t('Click to add')();
  root.appendChild(content);
  return {
    root,
    update: ({ edit, col, row }: PlotViewParams) => {
      if (delegate) {
        root.onclick = () => delegate.onAddPlot(edit, col, row);
      }
    },
  };
}

export function plotNotPlantedPreview(delegate?: PlotViewDelegate) {
  let root = el('div', 'preview-plot-not-planted');

  return {
    root,
    update: () => {},
  };
}

export const defineCellAttributes = (root: Element, viewParams: PlotViewParams) => {
  const { edit, plot, col, row } = viewParams;

  // grid, somehow can not be presented, then we hide cells by default
  if (edit.grid.length == 0 || edit.grid.length < col) {
    root.classList.remove('display-none');
    root.setAttribute('rowspan', '1');
  }

  if (plot === undefined && edit.grid.length > 0 && Object.keys(edit.grid).includes(col.toString())) {
    if (row >= 1) {
      // find previous plot in a grid, empty plots not presented
      let previousPlotCarry = 1;
      while (edit.grid[col][row - previousPlotCarry] === undefined) {
        if (row - previousPlotCarry < 0) {
          break;
        }
        previousPlotCarry += 1;
      }
      if (row - previousPlotCarry >= 0) {
        // previous plot exists
        const previousCellLength = edit.grid[col][row - previousPlotCarry].length;
        if (previousCellLength > previousPlotCarry) {
          // if previous cell is longer then a carry -> hiding current cell
          root.classList.add('display-none');
        } else {
          root.classList.remove('display-none');
        }
      }
    }
  } else if (plot !== undefined && plot.length > 1) {
    // adjusting plot length to a grid size if there is overlapping
    if (plot.length + row > edit.nRows()) {
      plot.length = edit.nRows() - row;
      root.setAttribute('rowspan', `${plot.length} `);
    }
  }
};
