import * as ko from 'knockout';
import page from 'page';

import { MaybeKO, asObservable } from '../utils/ko_utils';
import { session } from '../session';
import { getExpirationDays } from '../models/tenant';
import { showNeedPageReload, showRetryInSeconds } from '../api/base_request';
import { validationErrorScroller } from '../utils/validation_error';
import i18n from '../i18n';
import { openQRCodeScanner } from './qr_code_scanner';
import { processQRCodeUUID } from '../utils/qr_code_utils';
import { RTL_LANGUAGES } from '../app';

let template = require('raw-loader!../../templates/components/navigation_bar.html').default;

export class NavGroup {
  expanded = ko.observable(false);

  constructor(
    public title: string,
    public items: NavItem[]
  ) {}
}

export interface NavItem {
  title: string;
  icon: string;
  href: string;
  important?: boolean;
  htmlId?: string;
}

class NavigationBar {
  private dismissed = ko.observable(false);
  isVerticalMenuOpen = ko.observable(false);
  isVerticalMenuOpenRTL = ko.observable(false);
  isTutorialSubmenuExpanded = ko.observable(false);

  groups: MaybeKO<NavGroup[]>;
  isSfsaPartner: boolean;
  isQt: boolean;
  username: string;
  email: string;
  tenantName: string;
  homePagePath: string;
  isS2BIMApp = (process.env.APP_NAME === 'sfsa-s2bim');

  hasPlayStore = !!SERVER_INFO.PLAY_STORE_URL;
  hasAppStore = !!SERVER_INFO.APP_STORE_URL;
  mobileAppTutorialURL = APP_CONFIG.MOBILE_APP_TUTORIAL_URL;
  webAppTutorialURL = APP_CONFIG.WEB_APP_TUTORIAL_URL;
  doesTenantHaveSubscription= !session.tenant().is_free_trial;

  showNeedPageReload = showNeedPageReload;

  expirationDaysText = ko.pureComputed(() =>
    i18n.t('Your free trial will expire in {{ expirationDays }} days.', {
      expirationDays: this.expirationDays(),
    })()
  );
  showNetworkOutage = ko.observable<(retry: boolean) => void>(null);
  showRetryInSeconds = showRetryInSeconds;
  validationErrorScroller = validationErrorScroller;

  constructor(params: { groups: MaybeKO<NavGroup[]> }) {
    const tenant = session.tenant();

    this.groups = asObservable(params.groups);
    this.username = session.getName();
    this.tenantName = tenant.name;
    this.isSfsaPartner = session.isSfsaPartner();
    this.email = session.getEmail();
    this.isQt = process.env.APP_NAME === 'quicktrials';
    this.homePagePath = process.env.APP_NAME === 'sfsa-s2bim' ? '/dashboards/internal/' : '/trials/';

    if (tenant.role === 'data_entry') {
      this.mobileAppTutorialURL = null;
      this.webAppTutorialURL = null;
    }
  }
  showExpiration = ko.pureComputed(() => {
    let expirationDays = this.expirationDays();

    return (
      !this.dismissed() &&
      session.tenant() &&
      session.tenant().is_free_trial &&
      expirationDays != null &&
      expirationDays >= 0 &&
      expirationDays <= 5
    );
  });

  expirationDays = ko.pureComputed(() => getExpirationDays());

  goToValidationErrorElement = () => {
    if (this.validationErrorScroller) {
      const { elementToFocus, elementToScroll, topCoordinate, leftCoordinate } =
        validationErrorScroller()[0];
      elementToScroll.scrollTop = topCoordinate;
      elementToScroll.scrollLeft = leftCoordinate;
      setTimeout(() => {
        elementToFocus.click();
      }, 200);
    } else {
      validationErrorScroller([]);
    }
  };

  toggleTutorialSubmenu = () => {
    this.isTutorialSubmenuExpanded(!this.isTutorialSubmenuExpanded());
  }

  toggle = (toggled: NavGroup) => {
    for (let group of ko.unwrap(this.groups)) {
      group.expanded(group === toggled && !toggled.expanded());
    }
  };

  showQRCodeScanner = () => {
    openQRCodeScanner(processQRCodeUUID);
    this.closeVerticalMenu();
  };

  dismiss = () => {
    this.dismissed(true);
  };

  isCurrentLanguageRTL = () => {
    return RTL_LANGUAGES.includes(localStorage.getItem('userLanguage'));
  };

  handleVerticalMenuOpenByLanguage = (value: boolean) => {
    if (this.isCurrentLanguageRTL()) {
      this.isVerticalMenuOpenRTL(value);
    } else {
      this.isVerticalMenuOpen(value);
    }
  };

  openVerticalMenu = () => {
    this.handleVerticalMenuOpenByLanguage(true);

    return false; // stop anchor link
  };

  closeVerticalMenu = () => {
    this.handleVerticalMenuOpenByLanguage(false);
  };

  selected = () => {
    this.handleVerticalMenuOpenByLanguage(false);
    return true; // allow children to handle click event
  };

  reload = () => {
    page.stop();
    location.href = location.href;
  };
}
// export let bar = new NavigationBar({ groups: {}});
ko.components.register('navigation-bar', {
  viewModel: NavigationBar,
  template: template,
});
