import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { app } from '../app';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/components/view_video.html').default;

class ViewVideo {
  url: string;
  result: Deferred<{}>;

  constructor(params: { url: string; result?: Deferred<{}> }) {
    this.url = params.url;
    this.result = params.result;
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

const name = 'view-video';

ko.components.register(name, { viewModel: ViewVideo, template: template });

export function viewVideo(url: string) {
  app.formsStackController.push({
    title: i18n.t('Video')(),
    name,
    isBig: true,
    params: { url, result: new Deferred<{}>() },
  });
}
