import * as ko from 'knockout';

import { timeAgo } from '../utils';

ko.bindingHandlers['timeAgo'] = {
  update: (element: Element, valueAccessor: () => KnockoutObservable<Date | string> | Date | string) => {
    let value = ko.unwrap(valueAccessor());
    element.textContent = value ? timeAgo(value) : '-';
  },
};
