import * as ko from 'knockout';
import * as firebaseui from 'firebaseui';
import firebase from 'firebase/app';
import 'firebaseui/dist/firebaseui.css';

ko.bindingHandlers['firebase-tenant-login'] = {
  init: (element: Element, valueAccessor: () => any, allBindingsAccessor: () => any) => {
    let uiConfig: firebaseui.auth.Config = {
      callbacks: {
        signInSuccessWithAuthResult: () => false,
      },
      signInFlow: 'popup',
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      signInOptions: [],
    };

    const mode = allBindingsAccessor().mode;

    const tenantId = ko.unwrap(valueAccessor());
    if (tenantId) {
      // Tenant-scope login is using email/password only.
      uiConfig.signInOptions.push({
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        disableSignUp: { status: true },
      });
    } else {
      // Project-level (tenant-independent) login is using federated identity providers only.
      uiConfig.signInOptions.push({
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: ['email'],
        customParameters: {
          prompt: 'select_account',
        },
      });
      // Don't show Microsoft sign in option in sign up flow because of email verification.
      if (mode === 'login') {
        uiConfig.signInOptions.push({
          provider: 'microsoft.com',
          providerName: 'Microsoft',
          loginHintKey: 'login_hint',
        });
      }
    }

    firebase.auth().tenantId = tenantId;
    let ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    ui.reset();
    ui.start(element, uiConfig);
  },
};
