import * as ko from 'knockout';

import { BaseLoadingScreen } from './base_loading_screen';
import { ValidationResult } from '../api/request';
import { Deferred } from '../utils/deferred';
import { focusFirst } from '../utils';

export class BaseForm<T extends { id?: string | number}> extends BaseLoadingScreen {
  saving = ko.observable(false);
  protected result: Deferred<T>;

  constructor(params: { result?: Deferred<T> }) {
    super();
    this.result = params.result;
  }

  close() {
    if (this.result) {
      this.result.reject();
    } else {
      history.back();
    }
  }

  cancel = () => {
    this.close();
  };

  focusFirst(containerElement: Node) {
    focusFirst(containerElement);
  }

  validateLocal(entity: {} | KnockoutObservable<{}>): boolean {
    let entityValue = ko.unwrap(entity);

    this.clearModelServerErrors(entityValue);

    return this.validate(ko.validation.group(entity, { deep: true }));
  }

  validate(validation: KnockoutValidationErrors): boolean {
    if (validation().length > 0) {
      validation.showAllMessages();
      return false;
    }

    this.saving(true);

    return true;
  }

  executeSaveRequest<T>(request: Promise<T>): Promise<T> {
    request.catch(() => {
      this.saving(false);
    });

    return request;
  }

  onRemoteValidation(resultData: T, entity: any, validation: ValidationResult, onClose?: (data: T) => void) {
    this.saving(false);

    if (validation.isValid) {
      resultData.id = validation.entityId;

      if (onClose) {
        onClose(resultData);
      } else if (this.result) {
        this.result.resolve(resultData);
      } else {
        this.close();
      }
    } else {
      this.applyModelServerErrors(entity, validation.errors);
    }
  }

  clearModelServerErrors(entity: {}) {
    clearModelServerErrors(entity);
  }

  applyModelServerErrors(entity: any, errors: { [field: string]: string[] }) {
    applyModelServerErrors(entity, errors);
  }

  setServerError(obsv: KnockoutObservable<{}>, errors: string[]) {
    setServerError(obsv, errors);
  }
}

export function clearModelServerErrors(entity: {}) {
  if (entity) {
    for (let field in entity) {
      let obsv = <KnockoutObservable<{}>>(<any>entity)[field];
      if (ko.isObservable(obsv) && obsv.serverError) {
        obsv.serverError(null);
      }
    }
  }
}

export function applyModelServerErrors(entity: any, errors: { [field: string]: string[] }) {
  for (let field in errors) {
    let entityField = snakeCaseToCamelCase(field);
    if (ko.isObservable(entity[entityField]) && entity[entityField].serverError) {
      setServerError(entity[entityField], errors[field]);
    } else if (ko.isObservableArray(entity[entityField])) {
      setServerError(entity[entityField], errors[field]);
    }
  }

  // very special case
  if ((errors['name_json'] || errors['name']) && entity['nameJson'] && entity['nameJson'].serverError) {
    setServerError(entity['nameJson'], errors['name_json'] || errors['name']);
  }
}

function setServerError(obsv: KnockoutObservable<{}>, errors: string[]) {
  obsv.serverError(errors.join('. '));
}

function snakeCaseToCamelCase(value: string): string {
  let newParts = [];
  for (let part of value.split('_')) {
    if (newParts.length > 0 && part.length > 0) {
      part = part.charAt(0).toUpperCase() + part.slice(1);
    }
    newParts.push(part);
  }

  return newParts.join('');
}
