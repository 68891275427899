import * as ko from 'knockout';

import { app } from '../app';
import { Deferred } from '../utils/deferred';
import i18n from '../i18n';
import { bulkDeleteFacts, countFactSummaryData, FactSummaryFilter } from '../api/datasets';

const template = require('raw-loader!../../templates/components/remove_facts_dialog.html').default;

class RemoveFactsDialog {
  private datasetId: string;
  private filters: FactSummaryFilter;
  private result: Deferred<void>;

  loading = ko.observable(true);
  deleting = ko.observable(false);
  nToDelete = ko.observable(0);

  constructor(params: { datasetId: string; filters: FactSummaryFilter; result: Deferred<void> }) {
    this.datasetId = params.datasetId;
    this.filters = params.filters;
    this.result = params.result;

    countFactSummaryData(this.datasetId, this.filters).then((res) => {
      this.nToDelete(res.count);
      this.loading(false);
    });
  }

  onConfirm = async () => {
    this.deleting(true);

    try {
      await bulkDeleteFacts(this.datasetId, this.filters);
      this.result.resolve();
    } finally {
      this.deleting(false);
    }
  };

  onCancel = () => {
    this.result.reject();
  };
}

const name = 'remove-facts-dialog';

ko.components.register(name, { viewModel: RemoveFactsDialog, template });

export function showRemoveFactsDialog(datasetId: string, filters: FactSummaryFilter): Promise<void> {
  return app.formsStackController.push({
    title: i18n.t('Confirm')(),
    name,
    params: { datasetId, filters, result: new Deferred<void>() },
  });
}
