import * as ko from 'knockout';

import { MaybeKO } from '../utils/ko_utils';
import { DynamicAttribute } from '../models/attribute_meta';

let template = require('raw-loader!../../templates/components/attribute_value.html').default;

class AttributeValue {
  attribute: MaybeKO<DynamicAttribute>;
  enable: MaybeKO<boolean>;

  constructor(params: { attribute: MaybeKO<DynamicAttribute>; enable?: MaybeKO<boolean> }) {
    this.attribute = params.attribute;
    this.enable = params.enable === undefined ? true : params.enable;
  }
}

ko.components.register('attribute-value', {
  viewModel: AttributeValue,
  template: template,
});
