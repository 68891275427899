import * as ko from 'knockout';
import i18n from '../i18n';

import { app } from '../app';
import { BaseForm } from './base_form';
import { DimensionMeta } from '../models/dimension_meta';
import { AttributeMeta } from '../models/attribute_meta';
import * as dimensionMetasApi from '../api/dimension_metas';
import { FormNestedEntitiesConfiguration } from '../components/form_nested_entities';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/dimension_meta_edit.html').default;

class DimensionMetaEditScreen extends BaseForm<dimensionMetasApi.DimensionMetaData> {
  dimensionMeta = ko.observable<DimensionMeta>(null);
  selectedAttributeMeta = ko.observable<AttributeMeta>(null);

  allowEdit = ko.pureComputed(() => {
    return !this.dimensionMeta() || !this.dimensionMeta().id();
  });

  nestedAttributesConfig: FormNestedEntitiesConfiguration<AttributeMeta> = {
    title: i18n.t('Attributes:'),
    addTitle: i18n.t('Add Attribute'),
    missingTitle: i18n.t('No Attribute Name'),

    entities: ko.computed(() => {
      if (this.dimensionMeta()) {
        return this.dimensionMeta().attributeMetas();
      } else {
        return [];
      }
    }),

    canDisable: () => false,
    isTrialActive: () => false, // Since canDisable is false this will never be visible

    disabled: () => false,

    disable: () => {},

    canRemove: (entity) => true,

    add: () => this.dimensionMeta().orderedAttributes.add(new AttributeMeta()),

    remove: (entity) => this.dimensionMeta().orderedAttributes.remove(entity),

    hasErrors: (entity) => entity.hasErrors(),

    showErrors: (entity) => entity.errors.showAllMessages(),

    actions: [
      {
        icon: 'keyboard_arrow_up',
        callback: (entity) => {
          this.dimensionMeta().orderedAttributes.moveUp(entity);
        },
      },
      {
        icon: 'keyboard_arrow_down',
        callback: (entity) => {
          this.dimensionMeta().orderedAttributes.moveDown(entity);
        },
      },
    ],

    getSummaryName: (entity) => entity.nameJson(),
  };

  disableDate: boolean;

  constructor(
    params: {
      id: string;
      initialName?: string;
      disableDate?: boolean;
      autoAddLocation?: boolean;
      result?: Deferred<dimensionMetasApi.DimensionMetaData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    this.disableDate = params.disableDate;

    if (params.id) {
      let promise = dimensionMetasApi.retrieve(params.id).then((data) => {
        this.dimensionMeta(new DimensionMeta(data));
        if (!data && params.initialName) {
          this.dimensionMeta().nameJson(asI18nText(params.initialName));
        }

        if (params.autoAddLocation) {
          let partialAttr = new AttributeMeta();
          partialAttr.type('gps');
          this.nestedAttributesConfig.initialSelection = partialAttr;
          this.dimensionMeta().orderedAttributes.add(partialAttr);
        }
      });
      this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
    } else {
      this.dimensionMeta(new DimensionMeta());
      if (params.initialName) {
        this.dimensionMeta().nameJson(asI18nText(params.initialName));
      }
      this.loaded();

      this.focusFirst(componentInfo.element);
    }
  }

  dispose() {
    if (this.dimensionMeta()) {
      this.dimensionMeta().dispose();
    }
  }

  save = () => {
    if (!this.dimensionMeta()) {
      return;
    }

    if (this.validateLocal(this.dimensionMeta)) {
      let data = this.dimensionMeta().toData();
      this.executeSaveRequest(dimensionMetasApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.dimensionMeta(), validation);

        if (!validation.isValid) {
          app.showFormSubmissionErrorModal(validation.errors[0] as unknown as string);
          this.applyAttributeMetasServerErrors(validation.errors);
        }
      });
    }
  };

  private applyAttributeMetasServerErrors(errors: { [key: string]: any[] }) {
    let attrErrors = errors['attribute_metas'] || [];
    let attrs = this.dimensionMeta().attributeMetas();

    for (let i = 0; i < attrErrors.length; i++) {
      this.applyModelServerErrors(attrs[i], attrErrors[i]);
    }
  }
}

export let dimensionMetaEdit = {
  name: 'dimension-meta-edit',
  viewModel: createWithComponent(DimensionMetaEditScreen),
  template: template,
};

ko.components.register(dimensionMetaEdit.name, dimensionMetaEdit);
