import * as ko from 'knockout';
import i18n from '../../i18n';

import { BaseTrialStep } from './base';
import { WizardController } from '../../screens/trial_wizard';
import { confirmDialog } from '../confirm_dialog';
import { WalkOrder, StartingCorner } from '../../api/trials';
import { I18nText, asI18nText, translate } from '../../i18n_text';
import { encodeArrayBufferToBase64, indexOf, scrollToElement } from '../../utils';
import { ImportEntitiesDelegate } from '../import_entities';
import { ImportedPlotLayout, downloadPlotsLayoutImportTemplate, PlotImportData } from '../../api/datasets';
import { showSelectPlot } from './select_plot';
import { plotsOrderView, PlotsOrderViewDelegate } from './plots_edit/plots_order_view';
import { PlotsGridView, PlotsGridViewDelegate } from './plots_edit/plots_grid_view';
import { PlotsSiteEditModel, SitePlotData } from './plots_edit/plots_edit_model';
import { RenderedView } from '../../utils/dom';
import { openPlotEditDetails } from './plot_edit_details';
import { PlotsGridPreview } from './plots_edit/plots_grid_preview';
import { app } from '../../app';
import { Trial } from '../../models/trial';
import { canEditTrialLayout } from '../../permissions';
import { session } from '../../session';

let template = require('raw-loader!../../../templates/components/trial_wizard/trial_layout.html').default;

interface StartingCornerOption {
  name: string;
  value: StartingCorner;
}

interface WalkOrderOption {
  name: string;
  value: WalkOrder;
}

interface Site {
  id: () => string;
  nameJson: () => I18nText;
}
type ZoomLevel = 'small' | 'medium' | 'large';

const LayoutTableID = 'layout-table';

export const getTooManyPlotsText = (trial: Trial) => (SERVER_INFO.USE_FACTORS_NAMING || session.isTreatmentManagementEnabledForTrial(trial))
  ? i18n.t(
      'Attention: the number of treatment factor combinations for plots is too high. Plots cannot be (re)generated.'
    )
  : i18n.t(
      'Attention: the number of test subject combinations for plots is too high. Plots cannot be (re)generated.'
    );

class TrialLayout extends BaseTrialStep implements PlotsGridViewDelegate, PlotsOrderViewDelegate {
  // @ts-ignore
  private title = session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial()) ?
    i18n.t('Step 6 - Customize the plots layout')() : i18n.t('Step 5 - Customize the plots layout')();

  startingCorners: StartingCornerOption[] = [
    {
      name: i18n.t('Top-left')(),
      value: 'top_left',
    },
    {
      name: i18n.t('Top-right')(),
      value: 'top_right',
    },
    {
      name: i18n.t('Bottom-left')(),
      value: 'bottom_left',
    },
    {
      name: i18n.t('Bottom-right')(),
      value: 'bottom_right',
    },
  ];

  walkOrders: WalkOrderOption[] = [
    {
      name: i18n.t('Left-right')(),
      value: 'left_right',
    },
    {
      name: i18n.t('Top-down')(),
      value: 'top_down',
    },
    {
      name: i18n.t('Zig-zag horizontal')(),
      value: 'zig_zag_horizontal',
    },
    {
      name: i18n.t('Zig-zag vertical')(),
      value: 'zig_zag_vertical',
    },
    {
      name: i18n.t('Zig-zag horizontal (two rows at once)')(),
      value: '2rows_horizontal',
    },
  ];

  allowEditTrialLayout = ko.pureComputed(() => {
    let wizard = this.trialWizard();
    if (!wizard || !wizard.trial().id()) {
      return true;
    }
    return canEditTrialLayout(wizard.userData, wizard.trial());
  });

  tooManyPlotsText = getTooManyPlotsText(this.trialWizard().trial());
  estimatedAreaText = ko.pureComputed(() =>
    i18n.t('Estimated total planted area: {{ totalPlantedArea }}m²', {
      totalPlantedArea: this.totalPlantedArea(),
    })()
  );
  currentPlotInfo = ko.pureComputed(() => {
    const name = this.currentPlot()['plot']?.name || this.currentPlot()['plot']?.number;
    const dimensionsNames = this.currentPlot()['plot']?.dimensionNames;
    const plotInfo = `${name ?? ''} ${dimensionsNames ?? ''}`;
    return plotInfo.trim() ? `Plot: ${plotInfo.trim()}` : '';
  });

  showEditGrid = ko.observable(false);
  showGridSizeEditModal = ko.observable(false);
  maxGridSize = ko.observable(0);
  nColumns = ko.observable(0);
  nRanges = ko.observable(0);
  currentPlot = ko.observable<{ element: HTMLElement | null; plot: SitePlotData }>({
    element: null,
    plot: null,
  }); // last plot that was onmouseover

  nPlantedPlots = ko.observable(0);
  nTotalPlots = ko.observable(0);
  plotsOrderViews = new Map<string, RenderedView<PlotsSiteEditModel>>();
  plotsGridPreviews = new Map<string, PlotsGridPreview>();
  plotsGridViews = new Map<string, PlotsGridView>();

  generatingPlots = ko.observable<boolean>(false);
  generatingPlotsText = i18n.t('Generating plots...');
  tooManyPlots = ko.observable<boolean>(false);
  namesHidden = ko.observable<boolean>(false);

  // two different views into the same plots,
  // isInSortingMode controls which one is being used
  isInSortingMode = ko.observable<boolean>(false);
  selectedSiteId = ko.observable('');
  canSelectSite = ko.pureComputed(() => this.trialWizard().sites().length > 0);
  selectedSiteName = ko.pureComputed(() =>
    translate(
      this.trialWizard()
        .sites()
        .find((item) => item.id() === this.selectedSiteId())
        .nameJson()
    )
  );
  selectedGridDOM = ko.pureComputed(() => {
    let siteId = this.selectedSiteId();
    if (!this.plotsGridPreviews.has(siteId)) {
      this.plotsGridPreviews.set(siteId, new PlotsGridPreview(this));
    }

    return this.plotsGridPreviews.get(siteId).root;
  });
  editGridDOM = ko.pureComputed(() => {
    let siteId = this.selectedSiteId();
    if (!this.plotsGridViews.has(siteId)) {
      this.plotsGridViews.set(siteId, new PlotsGridView(this));
    }

    return this.plotsGridViews.get(siteId).root;
  });
  selectedOrderDOM = ko.pureComputed(() => {
    let siteId = this.selectedSiteId();
    if (!this.plotsOrderViews.has(siteId)) {
      this.plotsOrderViews.set(siteId, plotsOrderView(this, this.allowEditTrialLayout()));
    }

    return this.plotsOrderViews.get(siteId).root;
  });

  copyFromId = ko.observable<string>(null);
  copyToId = ko.observable<string>(null);

  totalPlantedArea = ko.pureComputed(() => {
    let trial = this.trialWizard().trial();
    let plotsCount = this.nPlantedPlots();
    let area: number;

    if (trial.squareMetersPerPlot()) {
      area = plotsCount * trial.squareMetersPerPlot();
    } else {
      if (
        !trial.squareMetersPerPlot() &&
        (!trial.plants() || !trial.spaceBetweenRows() || !trial.spaceInsideRows())
      ) {
        return null;
      }

      area = plotsCount * trial.plants() * trial.spaceBetweenRows() * trial.spaceInsideRows();
    }

    return isNaN(area) ? null : area.toFixed(2);
  });

  canRemovePlots = ko.pureComputed(
    () => this.trialWizard().trial().plotDesign() !== 'rcb' || APP_CONFIG.CAN_REMOVE_RCBD_PLOT
  );

  private lastGenerationId = 0;
  private subscriptions: KnockoutSubscription[] = [];

  isCustomerDesign = ko.pureComputed(() => this.trialWizard().trial().plotDesign() === 'customer');

  showImportLayout = ko.observable(false);
  sidebarShown = ko.observable(false);

  importDelegate: ImportEntitiesDelegate<ImportedPlotLayout> = {
    title: i18n.t('Import custom layout'),
    description: i18n.t(
      'Download the Excel template and upload the modified file containing your custom layout.'
    )(),
    backTitle: '',
    templateBaseName: 'layout',
    downloadTemplate: () => downloadPlotsLayoutImportTemplate(this.getPlotImportData()),
    importUrl: '/api/trials/0/datasets/import_plots_layout/',
    prepareFileContents: (fileContents) =>
      JSON.stringify({
        file_contents: encodeArrayBufferToBase64(fileContents),
        ...this.getPlotImportData(),
      }),
    onSuccess: async (plots) => {
      let plotsModel = this.trialWizard().plotsModel;

      if (!this.canRemovePlots()) {
        let errors = plotsModel.validateImportMissing(plots);
        if (errors.length > 0) {
          return errors.map((error) => ({
            sheet: '',
            cell: '',
            message: error,
          }));
        }
      }

      plotsModel.applyImport(plots);

      this.refreshPlotViews();
      this.showImportLayout(false);

      return [];
    },
  };

  constructor(params: { controller: WizardController }) {
    super(params);

    this.ready(true);
    document.onkeydown = this.handleKeyDown;
  }

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      document.getElementById('cancel')?.click();
      this.sidebarShown(false);
    } else if (e.altKey && e.code === 'Digit1') {
      this.zoomLargest();
    } else if (e.altKey && e.code === 'Digit2') {
      this.zoomMedium();
    } else if (e.altKey && e.code === 'Digit3') {
      this.zoomSmallest();
    }
  };
  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
    this.subscriptions = [];
    document.body.style.overflow = 'initial';
  }

  private getPlotImportData(): PlotImportData {
    let wizard = this.trialWizard();

    return {
      plot_design: wizard.trial().plotDesign(),
      replications: wizard.replications(),
      dataset_dimension_metas: wizard.testSubjectsWithDimensionMeta().map((ddm) => ddm.toData(true)),
      sites: wizard.sites().map((site) => site.id()),
      treatments: wizard.treatments().map((treatment) => treatment.toData()),
      plots: wizard.plotsModel.toData(),
      trial_id: parseInt(wizard.trial().id()),
    };
  }

  reload() {
    // reload subscriptions, because we might be dealing with different trial wizard
    this.dispose();

    if (
      !this.selectedSiteId() ||
      indexOf(this.sortedSites(), (s) => s.id() === this.selectedSiteId()) === -1
    ) {
      this.selectedSiteId(this.sortedSites()[0].id());
    }

    this.subscriptions.push(this.trialWizard.subscribe(() => this.reload()));

    this.subscriptions.push(this.trialWizard().trial().startingCorner.subscribe(this.onWalkOrderChanged));
    this.subscriptions.push(this.trialWizard().trial().walkOrder.subscribe(this.onWalkOrderChanged));
    this.subscriptions.push(this.selectedSiteId.subscribe(this.refreshPlotViews));
    this.subscriptions.push(this.trialWizard().excludeFromGenerationCombinationsOfControlAndNonControlTestSubjects.subscribe(() => {
      this.generatePlots({ fullReset: true });
    }));


    if (this.trialWizard().forceRegeneratePlots() !== 'no') {
      this.generatePlots({ fullReset: false });
    } else {
      this.refreshPlotViews();
    }
    for (let index = 0; index < this.nColumns(); index++) {
      this.refreshPlotViewAfterSizeChanged(index, 0);
    }
  }

  private refreshPlotViews = () => {
    let plotsModel = this.trialWizard().plotsModel;

    this.nTotalPlots(plotsModel.countTotal());
    this.nPlantedPlots(plotsModel.countPlanted());

    let siteId = this.selectedSiteId();
    let siteModel = plotsModel.site(siteId);
    this.nColumns(siteModel.nCols());
    this.nRanges(siteModel.nRows());
    this.maxGridSize(siteModel.nPlots());

    if (!this.plotsOrderViews.has(siteId)) {
      this.plotsOrderViews.set(siteId, plotsOrderView(this, this.allowEditTrialLayout()));
    }
    if (!this.plotsGridPreviews.has(siteId)) {
      this.plotsGridPreviews.set(siteId, new PlotsGridPreview(this));
    }
    if (!this.plotsGridViews.has(siteId)) {
      this.plotsGridViews.set(siteId, new PlotsGridView(this));
    }
    this.plotsOrderViews.get(siteId).update(siteModel);
    this.plotsGridPreviews.get(siteId).update(siteModel, this.allowEditTrialLayout());
    this.plotsGridViews.get(siteId).update(siteModel, this.allowEditTrialLayout());
  };
  selectedPlot = ko.observable<Element>(null);
  zoomLevel = ko.observable<ZoomLevel>('large');
  zoomLevelName = ko.pureComputed(() => {
    const nameMap = { small: '10%', medium: '50%', large: '100%' };
    return nameMap[this.zoomLevel()];
  });

  zoomSmallest = () => {
    this.zoomLevel('small');
    let table = document.getElementById(LayoutTableID);
    table?.classList.add('small-size');
    table?.classList.remove('mid-size', 'max-size');
    if (this.selectedPlot()) scrollToElement(this.selectedPlot(), 200);
  };
  zoomMedium = () => {
    let table = document.getElementById(LayoutTableID);
    table?.classList.add('mid-size');
    table?.classList.remove('max-size', 'small-size');
    if (this.zoomLevel() === 'small') {
      const elementToScroll = this.selectedPlot() || this.currentPlot()['element'];
      scrollToElement(elementToScroll, 200);
    } else {
      if (this.selectedPlot()) scrollToElement(this.selectedPlot(), 200);
    }
    this.zoomLevel('medium');
  };
  zoomLargest = (resetZoom: boolean = true) => {
    let table = document.getElementById(LayoutTableID);
    table?.classList.add('max-size');
    table?.classList.remove('mid-size', 'small-size');
    if (this.zoomLevel() === 'medium' || this.zoomLevel() === 'small') {
      const elementToScroll = this.selectedPlot() || this.currentPlot()['element'];
      scrollToElement(elementToScroll, 200);
    }
    this.zoomLevel('large');
  };

  focusPlotElement = (edit: PlotsSiteEditModel, plot: SitePlotData) => {
    const { colIdx, rowIdx } = edit.getCoordinates(plot);
    const domElement = document.querySelector(`td[row="${rowIdx}"][col="${colIdx}"]`);
    domElement.classList.add('highlight-plot');
    scrollToElement(domElement, 800);
    return domElement;
  };
  focusPlotElementByCoordinates = (rowIdx: number, colIdx: number) => {
    const domElement = document.querySelector(`td[row="${rowIdx}"][col="${colIdx}"]`);
    domElement.classList.add('highlight-plot');
    scrollToElement(domElement, 800);
    return domElement;
  };
  /**
   * Sites sorted by name. A default empty site is added if there are no sites.
   */
  sortedSites = ko.pureComputed((): Site[] => {
    let sites =
      this.trialWizard().sites().length > 0
        ? this.trialWizard().sites().slice()
        : [{ id: (): string => '', nameJson: () => asI18nText('') }];
    sites.sort((a, b) => translate(a.nameJson()).localeCompare(translate(b.nameJson())));

    return sites;
  });

  siteOptions = ko.pureComputed(() => {
    return this.sortedSites()
      .filter((site) => site.id() !== this.copyFromId())
      .map((site) => {
        return {
          id: site.id(),
          name: translate(site.nameJson()),
        };
      });
  });

  openImportLayout = () => {
    this.showImportLayout(true);
  };

  closeImportLayout = () => {
    this.showImportLayout(false);
  };

  removeAllPlots = async () => {
    let title = i18n.t('Removing ALL plots')();
    let message = i18n.t([
      'removing_all_plots_customized_warning',
      'You are about to remove all the plots from the layout. Your customized layout will be lost. Are you sure you want to continue?',
    ])();

    await confirmDialog(title, message);
    this.trialWizard().plotsModel.removeAll();
    this.refreshPlotViews();
  };

  resetPlots = async () => {
    let title = i18n.t('Resetting plots')();
    let message = i18n.t(
      'You are about to reset all the plots. Your customized layout will be lost. Are you sure you want to continue?'
    )();

    await confirmDialog(title, message);
    this.trialWizard().forceRegeneratePlots('full');
    this.generatePlots({ fullReset: true });
  };

  removeSitePlots = async () => {
    let title = i18n.t('Removing all site plots')();
    let message = i18n.t([
      'removing_site_plots_customized_warning',
      'You are about to remove all the plots for this site from the layout. Your customized layout will be lost. Are you sure you want to continue?',
    ])();

    await confirmDialog(title, message);
    let siteId = this.selectedSiteId();
    this.trialWizard().plotsModel.site(siteId).removeAll();
    this.refreshPlotViews();
  };

  switchToGridView = () => {
    this.isInSortingMode(false);
  };

  switchToSortingMode = () => {
    this.isInSortingMode(true);
  };

  private onWalkOrderChanged = () => {
    let wizard = this.trialWizard();
    let trial = this.trialWizard().trial();

    wizard.plotsModel.setWalkingOrder(trial.startingCorner(), trial.walkOrder());

    this.refreshPlotViews();
  };

  private generatePlots = (options: { fullReset: boolean }) => {
    let generationId = this.lastGenerationId + 1;
    let forceRegen = this.trialWizard().forceRegeneratePlots() === 'full';

    this.lastGenerationId = generationId;
    this.generatingPlots(true);
    this.tooManyPlots(false);

    this.trialWizard()
      .generatePlotsRequest({
        fullReset: options.fullReset,
        useCustom: !forceRegen,
      })
      .then((res) => {
        if (generationId != this.lastGenerationId) {
          return;
        }

        if (forceRegen) {
          this.trialWizard().forceRegeneratePlots('no');
          this.trialWizard().customPlotNumbers(false);
          this.trialWizard().customPlotPosition(false);
        }

        this.generatingPlots(false);
        this.tooManyPlots(false);

        if (res.isValid) {
          this.trialWizard().forceRegeneratePlots('no');
          this.trialWizard().setPlots(res.plots, true);
          this.refreshPlotViews();
        } else {
          if (res.errors['too_many_plots']) {
            this.tooManyPlots(true);
          }
        }
      })
      .catch(() => {
        if (generationId != this.lastGenerationId) {
          return;
        }

        this.generatingPlots(false);
      });
  };

  onCopyFrom = () => {
    const siteId = this.selectedSiteId();
    if (siteId) {
      this.copyFromId(siteId);
      this.copyToId(this.siteOptions()[0]?.id ?? '');
    }
  };

  copy = () => {
    this.trialWizard().plotsModel.copy(this.copyFromId(), this.copyToId());
    this.refreshPlotViews();

    this.copyFromId(null);
  };

  cancelCopy = () => {
    this.copyFromId(null);
  };

  toggleGridSizeSelect = () => {
    this.showGridSizeEditModal(!this.showGridSizeEditModal());
  };

  toggleEditGrid = () => {
    if (this.showEditGrid()) {
      this.sidebarShown(false);
      const zoomWrapper = document.getElementById(LayoutTableID);
      this.adjustZoomWrapperWidth(zoomWrapper);
    }

    this.showEditGrid(!this.showEditGrid());

    if (this.showEditGrid()) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
      app.formsStackController.clear();
      this.unfocusPlotElement(this.selectedPlot());
    }
  };

  submitGridSize = () => {
    this.showGridSizeEditModal(false);
    let model = this.trialWizard().plotsModel.site(this.selectedSiteId());
    model.setGridSize(this.nColumns(), this.nRanges());

    this.refreshPlotViews();
  };

  // delegates

  async onAddPlot(edit: PlotsSiteEditModel, colIdx: number, rowIdx: number) {
    const plot = await showSelectPlot(edit);
    edit.swapRemovedPlot(plot, colIdx, rowIdx);
    this.refreshPlotViews();
    this.refreshPlotViewAfterSizeChanged(colIdx, 0);
  }

  onSwap(
    edit: PlotsSiteEditModel,
    colIdx1: number,
    rowIdx1: number,
    colIdx2: number,
    rowIdx2: number
  ): void {
    edit.swap(colIdx1, rowIdx1, colIdx2, rowIdx2);
    this.refreshPlotViews();
  }

  onSwapWithRemoved(edit: PlotsSiteEditModel, excludedIdx: number, sourceX: number, sourceY: number) {
    edit.swapRemoved(excludedIdx, sourceX, sourceY);
    this.refreshPlotViews();
  }

  onDragStart(edit: PlotsSiteEditModel, params: { col?: number; row?: number }) {
    edit.drag.start(params);
  }

  onDragOver(edit: PlotsSiteEditModel, params: { col?: number; row?: number }) {
    if (edit.drag.over(params)) {
    }
  }

  onDragEnd(edit: PlotsSiteEditModel) {
    edit.drag.end(edit);
  }

  onDragCancel(edit: PlotsSiteEditModel) {
    edit.drag.cancel();
  }

  onPlotExpand(edit: PlotsSiteEditModel, plot: SitePlotData, column: number, row: number): void {
    plot.length += 1;
    edit.needsSave();
    this.refreshPlotViews();
    this.refreshPlotViewAfterSizeChanged(column, row, plot.length);
  }

  onPlotShrink(edit: PlotsSiteEditModel, plot: SitePlotData, column: number, row: number): void {
    plot.length -= 1;
    edit.needsSave();
    this.refreshPlotViews();
    this.refreshPlotViewAfterSizeChanged(column, row, plot.length);
  }

  refreshPlotViewAfterSizeChanged = (column: number, row: number, length?: number) => {
    const allColumnCells = document.querySelectorAll<HTMLElement>(`td[col="${column}"]`);
    let needToHideElements = 0;
    for (let index = 0; index < allColumnCells.length; index++) {
      const element = allColumnCells[index];
      const elementRow = parseInt(element.getAttribute('row'));
      if (elementRow < row) {
        // we don't need to recalculate elements before row that changed
        continue;
      }

      let rowSpan = 0;
      if (length && elementRow == row) {
        rowSpan = length - 1;
      } else {
        rowSpan = (parseInt(element.getAttribute('rowspan')) || 1) - 1;
      }
      if (rowSpan > 0 && needToHideElements == 0) {
        // recalculate how many cells should be hidden
        // skipping element if we need to hide next elements in column
        needToHideElements = rowSpan;
        continue;
      }

      if (needToHideElements > 0) {
        element.classList.add('display-none');
        needToHideElements -= 1;
      } else {
        element.classList.remove('display-none');
        element.setAttribute('rowspan', '1');
      }
    }
  };

  unfocusPlotElement = (element?: Element) => {
    if (!element) return;
    element.classList?.remove('highlight-plot');
    element.classList.add('unhighlight-plot');
    setInterval(() => {
      element.classList?.remove('unhighlight-plot');
    }, 1000);
  };
  onMouseOverPlot = (element: HTMLElement, plot: SitePlotData) => {
    this.currentPlot({ element: element, plot: plot });
  };
  adjustZoomWrapperWidth = (wrapperElement: HTMLElement) => {
    const bodyRect = document.body.getBoundingClientRect();
    if (this.sidebarShown()) {
      wrapperElement.style.width = `${bodyRect['width'] - 340}px`;
    } else {
      wrapperElement.style.width = null;
    }
  };

  async onPlotEditDetails(edit: PlotsSiteEditModel, plot: SitePlotData) {
    app.formsStackController.clear();
    if (this.selectedPlot()) {
      this.unfocusPlotElement(this.selectedPlot());
    }

    const { rowIdx, colIdx } = edit.getCoordinates(plot);
    const element = this.focusPlotElementByCoordinates(rowIdx, colIdx);
    this.selectedPlot(element);

    const zoomWrapper = document.getElementById(LayoutTableID);
    this.sidebarShown(true);
    this.adjustZoomWrapperWidth(zoomWrapper);

    const result = await openPlotEditDetails({ ...plot, column: colIdx, row: rowIdx });
    this.sidebarShown(false);
    this.adjustZoomWrapperWidth(zoomWrapper);
    this.unfocusPlotElement(this.selectedPlot());
    this.selectedPlot(null);

    if (result !== null && result.excluded) {
      element.setAttribute('rowspan', '1');
      const coord = edit.getCoordinates(plot);
      this.onSwapWithRemoved(edit, edit.nExcluded(), coord.colIdx, coord.rowIdx);
      this.refreshPlotViews();
      this.refreshPlotViewAfterSizeChanged(coord.colIdx, 0);
    } else if (result !== null) {
      edit.setExternalId(plot, result.externalId);
      edit.setName(plot, result.name);
      this.refreshPlotViews();
    }
    this.plotsGridPreviews.get(edit.siteId).focus();
  }

  onPlotOrderChanged(edit: PlotsSiteEditModel, oldIndex: number, newIndex: number): void {
    edit.movePlotOrder(oldIndex, newIndex);
    this.refreshPlotViews();
  }
}

ko.components.register('trial-layout', {
  viewModel: TrialLayout,
  template: template,
});
