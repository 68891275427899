import * as ko from 'knockout';
import { tryFormatDate, tryFormatDateTime } from '../utils';

ko.bindingHandlers['formattedDate'] = {
  update: (element: Element, valueAccessor: () => KnockoutObservable<Date | string> | Date | string) => {
    element.textContent = tryFormatDate(ko.unwrap(valueAccessor()));
  },
};

ko.bindingHandlers['formattedDateTime'] = {
  update: (element: Element, valueAccessor: () => KnockoutObservable<Date | string> | Date | string) => {
    element.textContent = tryFormatDateTime(ko.unwrap(valueAccessor()));
  },
};
