import * as ko from 'knockout';
import { app } from '../app';
import * as dashboardApi from '../api/dashboard';
import { Deferred } from '../utils/deferred';
import { setGlobalError } from '../screens/base_edit';
import * as factsApi from '../api/facts';
import {
  DynamicAttribute,
  makeDynamicAttribute,
  factChangeReasons,
  ChangeReason,
} from '../models/attribute_meta';
import { sortBy, mapValues, groupBy } from 'lodash';
import i18n from '../i18n';
import { asI18nText, translate } from '../i18n_text';
import { MeasurementMeta } from '../models/measurement_meta';
import { DatasetFactSummaryData } from '../api/datasets';
import { FileUploadDelegate } from './basic_widgets';
import { CloudStorageUploadDelegate, CloudStorageUpload, FileUploadEndpoint } from '../cloud_storage_upload';
import { BaseForm } from '../screens/base_form';
import { session } from '../session';

import { EditExtraObservableData } from '../screens/fact_edit';
import { tryFormatDateTime, STORAGE_KEYS } from '../utils';

const template = require('raw-loader!../../templates/components/fact_edit_and_history_dialog.html').default;

enum Tab {
  EDIT = 'edit',
  HISTORY = 'history',
}

class FactEditAndHistoryDialog
  extends BaseForm<DatasetFactSummaryData>
  implements FileUploadDelegate, CloudStorageUploadDelegate
{
  canReuseEndpoint = false;
  // Alias to access the enum in the template
  tab = Tab;

  private subscriptions: KnockoutSubscription[] = [];

  loading = ko.observable(true);
  saving = ko.observable(false);
  globalError = ko.observable('');

  factId = ko.observable<string>('');
  measurementMetaId = ko.observable<string>('');

  historicValues = ko.observableArray<dashboardApi.FactHistoricValueData>();
  atLeastOnePictureComment = ko.observable<boolean>(false);

  // Header information
  plotName = ko.observable('');
  treatmentName = ko.observable('');
  referenceDimName = ko.observable('');
  description = ko.observable('');

  // UI state
  activeTab = ko.observable<Tab>(Tab.EDIT);
  isObservationHistoryEnabled = ko.observable<boolean>(session.tenant().observation_history_enabled);
  actionsConfig = ko.observable<ActionsConfig>();
  actionsDisplayConfig = ko.observable<ActionsDisplayConfig>();
  openFileMenusIndexes = ko.observableArray<number>([]);
  error = ko.observable('');

  // Fact Edit Form
  selectedAttrs = ko.observable<DynamicAttribute[]>(); // For the selected measurement meta instead of the whole fact.
  attrs = ko.observable<DynamicAttribute[]>();
  changeReasons = factChangeReasons;
  extras = ko.observable<Record<string, ko.ObservableArray<EditExtraObservableData>>>();
  initialValues: Record<string, any> = {};
  changedAttributes = new Set();

  comment = ko.observable('');
  keepCollectionMetadata = ko.observable(false);
  keepCollectionMetadataHelpText = i18n.t(
    "Check this option to preserve the observation collection date and user information. Otherwise, the reviewer's data will be used."
  )();
  restoreCollectionMetadataHelpText = i18n.t(
    'Restore the observation collection date and user information from the selected observation.'
  )();
  fact_metadata_id: string | null = null;
  onChangeKeepCollectionMetadata = ko.computed(() => {
    if (this.keepCollectionMetadata()) {
      this.fact_metadata_id = this.getFactMetadataIdToKeep();
    } else {
      this.fact_metadata_id = null;
    }
  });

  constructor(
    private params: {
      factId: string;
      measurementMetaId: string;
      allowFactEditing: boolean;
      actionsConfig: ActionsConfig;
      actionsDisplayConfig: ActionsDisplayConfig;
      result?: Deferred<DatasetFactSummaryData>;
    }
  ) {
    super(params);

    this.actionsConfig(params.actionsConfig);
    this.actionsDisplayConfig(params.actionsDisplayConfig);

    this.setDefaultTab();
    this.onFactAndMmIdChange(params.factId, params.measurementMetaId);
    this.subscriptions.push(
      this.activeTab.subscribe((tab) => {
        localStorage.setItem(STORAGE_KEYS.OVERVIEW_CLICK_ACTION, tab);
      })
    );
  }

  /**
   *
   */
  private init = () => {
    factsApi.retrieve(this.factId()).then((data) => {
      if (this.isObservationHistoryEnabled()) {
        dashboardApi.history(this.factId(), this.measurementMetaId()).then((data) => {
          // The sorting here allows us to display the documents first, and
          // then the images
          const orderedData = data.map((factLog) => ({
            ...factLog,
            files_extras: sortBy(factLog.files_extras, (fileExtra) => this.isImage(fileExtra.mime_type)),
          }));

          this.historicValues(orderedData);

          this.atLeastOnePictureComment(
            orderedData.some((factLog) => {
              return factLog.files_extras.some((fileExtra) => fileExtra.comment);
            })
          );
        });
      }
      this.plotName(data.plot_name);
      this.treatmentName(data.treatment_name);
      this.referenceDimName(data.reference_dim_name);
      this.description(data.dimensions.map((data) => data.dm_name + ': ' + data.dim_name).join(', '));
      const groupedExtras = mapValues(
        groupBy(
          sortBy(data.extras, ['mime_type', 'file_url']).map(
            (extra: factsApi.EditExtraData) => new EditExtraObservableData(extra)
          ),
          (extra: EditExtraObservableData) => extra.measurement_meta_id()
        ),
        (extras: EditExtraObservableData[]) => ko.observableArray(extras)
      );

      this.extras(groupedExtras);

      this.attrs(
        data.mms
          .map((mm) => {
            let attr = makeDynamicAttribute(
              new MeasurementMeta(
                {
                  allowDimensionMeta: false,
                  allowRanking: true,
                  management: false,
                  requireMeasurementType: false,
                  validateLimitTo: false,
                },
                null,
                mm
              ),
              {}
            );
            if (data.unit_names[mm.id]) {
              attr.nameJson(asI18nText(translate(attr.nameJson()) + ' (' + data.unit_names[mm.id] + ')'));
            }

            // If there are no extras, then create one.
            if (groupedExtras[attr.id] === undefined) {
              groupedExtras[attr.id] = ko.observableArray([this.createEmptyExtra(Number(attr.id))]);
            }

            // If there is no "comment-only" extra, then create one so that the "Comment" still appears in the UI.
            // According to the UI requirements it must appear first, so it's added to the beginning of the array.
            if (!groupedExtras[attr.id]().some((extra) => extra.file_url() === '')) {
              groupedExtras[attr.id].unshift(this.createEmptyExtra(Number(attr.id)));
            }
            return attr;
          })
          .filter((attr) => !!attr)
      );

      this.selectedAttrs(
        this.measurementMetaId
          ? this.attrs().filter((attr) => attr.id === this.measurementMetaId())
          : this.attrs()
      );

      for (let attr of this.attrs()) {
        attr.deserialize(data[attr.getMesName()]);
        this.initialValues[attr.id] = attr.flatValue();
        attr.value.subscribe(() => this.changedAttributes.add(attr.id));
      }

      this.loading(false);
    });
  };

  private createEmptyExtra = (measurement_meta_id: number) =>
    new EditExtraObservableData({
      id: null,
      file_name: '',
      file_url: '',
      mime_type: '',
      user_file_name: '',
      measurement_meta_id: measurement_meta_id,
      comment: '',
    });

  private setDefaultTab = () => {
    const lastOpenedTab = localStorage.getItem(STORAGE_KEYS.OVERVIEW_CLICK_ACTION);

    if (
      this.isObservationHistoryEnabled() &&
      (lastOpenedTab === Tab.HISTORY || !this.params.allowFactEditing)
    ) {
      this.activeTab(Tab.HISTORY);
    } else {
      this.activeTab(Tab.EDIT);
    }
  };

  private onFactAndMmIdChange = (factId: string, measurementMetaId: string) => {
    this.factId(factId);
    this.measurementMetaId(measurementMetaId);

    this.init();
  };

  isImage(data: string | EditExtraObservableData) {
    if (typeof data === 'string') {
      return data.startsWith('image/');
    }

    return data.mime_type() && data.mime_type().indexOf('image/') === 0;
  }

  isChangeReasonRequired(attr: DynamicAttribute) {
    if (this.initialValues[attr.id] === undefined) {
      return false;
    }
    const value = attr.flatValue() == '' ? undefined : attr.flatValue();
    return this.initialValues[attr.id] !== value;
  }

  removeExtra = (extra: EditExtraObservableData) => {
    this.extras({
      ...this.extras(),
      [extra.measurement_meta_id()]: ko.observableArray(
        this.extras()
          [extra.measurement_meta_id()]()
          .filter((existingExtra) => existingExtra.id() !== extra.id())
      ),
    });
  };

  executeSaveRequest<T>(request: Promise<T>): Promise<T> {
    request.catch(() => {
      this.saving(false);
    });

    return request;
  }

  save = () => {
    for (let attr of this.attrs()) {
      if (this.isChangeReasonRequired(attr) && attr.changeReason() === '') {
        attr.value.serverError(i18n.t('Change reason should be specified')());
        return;
      }
      if (attr.value.serverError) {
        attr.value.serverError(null);
      }
    }
    let group = ko.validation.group(
      this.attrs().map((attr) => attr.value),
      { deep: false }
    );
    if (group().length > 0) {
      group.showAllMessages();
      return;
    }

    this.saving(true);

    this.executeSaveRequest(factsApi.update(this.toData())).then((res) => {
      this.saving(false);

      if (res.isValid) {
        this.result.resolve(res.entity);
      } else {
        setGlobalError(this.globalError, { ...res, entityId: '' });

        for (let attr of this.attrs()) {
          let errors = res.errors[attr.getMesName()];
          if (errors) {
            this.setServerError(attr.value, errors);
          }
        }
      }
    });
  };

  toData(): factsApi.EditFactData {
    let changeReasons = [];

    let data: factsApi.EditFactData = {
      id: this.factId(),
      comment: this.comment(),
      extras: Object.values(this.extras())
        .map((obsValue: ko.ObservableArray<EditExtraObservableData>) => obsValue())
        .flat()
        .map((extraObs: EditExtraObservableData) => extraObs.toData() as factsApi.EditExtraData)
        .filter((extra: factsApi.EditExtraData) => extra.comment || extra.file_url),
      metadata_id: this.fact_metadata_id,
    };
    for (let attr of this.attrs()) {
      data[attr.getMesName()] = attr.serialize();
      if (attr.changeReason()) {
        changeReasons.push({ [attr.id]: attr.changeReason() });
      }
    }
    data['change_reasons'] = changeReasons;
    return data;
  }

  onFileUploaded(
    userFileName: string,
    fileName: string,
    publicURL: string,
    contentType: string,
    context: any
  ): void {
    if (this.extras()[context.measurement_meta_id as number]) {
      this.extras()[context.measurement_meta_id].push(
        new EditExtraObservableData({
          id: null,
          file_name: fileName,
          file_url: publicURL,
          mime_type: contentType,
          user_file_name: userFileName,
          comment: '',
          measurement_meta_id: context.measurement_meta_id,
        }) as unknown as EditExtraObservableData
      );
      return;
    }
  }

  private cloudUpload = new CloudStorageUpload(this);
  fileUploadError = this.cloudUpload.fileUploadError;

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest,
    context: any
  ) => {
    return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR, context);
  };

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return factsApi.getExtraUploadEndpoint(contentType);
  }

  switchToEditTab = () => {
    if (this.activeTab() === Tab.EDIT || !this.params.allowFactEditing) {
      return;
    }

    this.activeTab(Tab.EDIT);
  };

  switchToHistoryTab = () => {
    if (this.activeTab() === Tab.HISTORY || !this.isObservationHistoryEnabled()) {
      return;
    }

    this.activeTab(Tab.HISTORY);
  };

  onNextClick = () => {
    if (!this.actionsDisplayConfig().isNextButtonEnabled()) {
      return;
    }
    const [factId, measurementMetaId] = this.actionsConfig().getNextFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onPrevClick = () => {
    if (!this.actionsDisplayConfig().isPrevButtonEnabled()) {
      return;
    }
    const [factId, measurementMetaId] = this.actionsConfig().getPrevFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onNextSkipClick = () => {
    if (!this.actionsDisplayConfig().isNextSkipButtonEnabled()) {
      return;
    }

    const [factId, measurementMetaId] = this.actionsConfig().getNextSkipFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onPrevSkipClick = () => {
    if (!this.actionsDisplayConfig().isPrevSkipButtonEnabled()) {
      return;
    }
    const [factId, measurementMetaId] = this.actionsConfig().getPrevSkipFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  getChangeReasonTitle = (value: dashboardApi.FactHistoricValueData) => {
    return factChangeReasons.find((c) => c.value === value.reason)?.title;
  };

  getGPSCoordinates = (value: dashboardApi.FactHistoricValueData) => {
    if (value.latitude && value.longtitude) {
      return `${value.latitude},${value.longtitude}`;
    }
    return null;
  };

  getGPSCoordinatesLink = (value: dashboardApi.FactHistoricValueData) => {
    if (value.latitude && value.longtitude) {
      return `https://www.google.com/maps/search/${value.latitude},${value.longtitude}`;
    }
    return null;
  };

  getGPSWarningMessage = (value: dashboardApi.FactHistoricValueData) => {
    return `${i18n.t('GPS was valid at')()} ${tryFormatDateTime(value.location_valid_at)}`;
  };

  getCollectionMetadataIndex = (metadata_id: string) => {
    return this.historicValues().findIndex((value) => value.id === metadata_id);
  };

  getChangeValue = (index: number) => {
    return this.historicValues().length - index;
  };

  getFactMetadataIdToKeep = () => {
    if (!this.keepCollectionMetadata() || !this.historicValues()) {
      return null;
    }

    const lastHistoricValue = this.historicValues()[this.historicValues().length - 1];
    return lastHistoricValue.metadata_id || lastHistoricValue.id;
  };

  isFileMenuOpen = (valueIndex: number) => {
    return this.openFileMenusIndexes().includes(valueIndex);
  };

  closeFileMenu = (valueIndex: number) => {
    this.openFileMenusIndexes.remove(valueIndex);
  };

  openFileMenu = (valueIndex: number) => {
    if (this.openFileMenusIndexes().includes(valueIndex)) {
      return;
    }
    this.openFileMenusIndexes.push(valueIndex);
  };

  close = () => {
    for (const subscription of this.subscriptions) {
      subscription.dispose();
    }

    this.params.result.reject();
  };

  restore = async (valueIndex: number) => {
    if (valueIndex === null || valueIndex === undefined || this.saving()) {
      return;
    }

    this.saving(true);

    try {
      const factLog = this.historicValues()[valueIndex];
      const extraLogIds = [];
      if (factLog.comment_only_extra) {
        extraLogIds.push(factLog.comment_only_extra.id);
      }
      if (factLog.files_extras) {
        extraLogIds.push(...factLog.files_extras.map((f) => f.id));
      }

      const res = await dashboardApi.restoreHistory(factLog.id, this.params.measurementMetaId, extraLogIds);
      if (!res.isValid) {
        setGlobalError(this.error, res);
      } else {
        this.params.result.resolve(null);
      }
    } finally {
      this.closeFileMenu(valueIndex);
      this.saving(false);
    }
  };

  restoreMetadata = (valueIndex: number) => {
    this.fact_metadata_id = this.historicValues()[valueIndex].id;
    this.selectedAttrs().forEach((attr) => {
      attr.changeReason(ChangeReason.OTHER);
    });
    this.save();
  };

  getCommentOnlyExtra = (value: dashboardApi.FactHistoricValueData) => {
    if (value.comment_only_extra) {
      return value.comment_only_extra.comment;
    }
    return '-';
  };
}

const name = 'fact-edit-and-history-dialog';

ko.components.register(name, {
  viewModel: FactEditAndHistoryDialog,
  template,
});

interface ActionsConfig {
  getNextFactIdAndMmId?: () => [string, string];
  getPrevFactIdAndMmId?: () => [string, string];
  getNextSkipFactIdAndMmId?: () => [string, string];
  getPrevSkipFactIdAndMmId?: () => [string, string];
}

interface ActionsDisplayConfig {
  isNextButtonEnabled: () => boolean;
  isPrevButtonEnabled: () => boolean;
  isNextSkipButtonEnabled: () => boolean;
  isPrevSkipButtonEnabled: () => boolean;
}

export function openFactEditAndHistoryDialog(
  factId: string,
  measurementMetaId: string,
  allowFactEditing: boolean,
  actionsConfig?: ActionsConfig,
  actionsDisplayConfig?: ActionsDisplayConfig
): Promise<void> {
  return app.formsStackController.push({
    title: i18n.t('Observation history')(),
    name,
    params: {
      factId,
      measurementMetaId,
      allowFactEditing,
      actionsConfig,
      actionsDisplayConfig,

      result: new Deferred<void>(),
    },
    isBig: true,
    showNav: false,
  });
}
