import { el, range } from '../../../utils';
import { PlotsSiteEditModel } from './plots_edit_model';
import {
  PlotViewDelegate,
  plotView,
  PlotViewParams,
  plotNotPlantedPreview,
  plotPreview,
  defineCellAttributes,
} from './plot_view';
import { diffList, choose } from '../../../utils/dom';

export interface PlotsGridViewDelegate extends PlotViewDelegate {
  onAddPlot(edit: PlotsSiteEditModel, colIdx: number, rowIdx: number): void;
  onSwap(edit: PlotsSiteEditModel, colIdx1: number, rowIdx1: number, colIdx2: number, rowIdx2: number): void;
  onSwapWithRemoved(edit: PlotsSiteEditModel, excludedIdx: number, sourceX: number, sourceY: number): void;
  onDragStart(edit: PlotsSiteEditModel, params: { col?: number; row?: number }): void;
  onDragOver(edit: PlotsSiteEditModel, params: { col?: number; row?: number }): void;
  onDragEnd(edit: PlotsSiteEditModel): void;
  onDragCancel(edit: PlotsSiteEditModel): void;
}

const HIDE_EVERY_5TH_COLUMNS_NUM = 50;
const HIDE_EVERY_10TH_COLUMNS_NUM = 100;
const HIDE_EVERY_50TH_COLUMNS_NUM = 300;

export class PlotsGridPreview {
  readonly root = el('div');

  private headRow: HTMLElement;
  private tbody = el('tbody');
  private removed = el('div', 'remove-area-target');

  private prevNCol = -1;
  private prevnRow = -1;

  private edit: PlotsSiteEditModel;
  private _update: (edit: PlotsSiteEditModel, allowEditAny: boolean) => void;

  constructor(private delegate: PlotsGridViewDelegate) {
    let container = el('div');

    let wrapper = el('div', 'preview-table-wrapper');
    wrapper.id = 'preview-table-wrapper';

    let table = el('table');
    table.id = 'preview-layout-table';

    wrapper.appendChild(table);

    let thead = el('thead');
    this.headRow = el('tr');

    thead.appendChild(this.headRow);
    table.appendChild(thead);
    table.appendChild(this.tbody);

    container.appendChild(wrapper);

    this.root.appendChild(container);
  }

  focus() {
    const input = this.root.querySelector('input');
    if (input) {
      input.focus();
      input.setSelectionRange(0, input.value.length);
    }
  }

  update(edit: PlotsSiteEditModel, allowEditAny: boolean) {
    if (
      !this._update ||
      edit !== this.edit ||
      this.prevNCol !== edit.nCols() ||
      this.prevnRow !== edit.nRows()
    ) {
      this.prevNCol = edit.nCols();
      this.prevnRow = edit.nRows();

      this.edit = edit;
      this._update = this.recreate(edit, allowEditAny);
    }
    this._update(edit, allowEditAny);
  }

  private calcColumnNumber(edit: PlotsSiteEditModel, columnIndex: number) {
    // we should hide every 5 column if there is more then 50 and less then 100 columns
    // if more then 100 - hide every 10th column

    if (columnIndex === 0 || edit.nCols() <= HIDE_EVERY_5TH_COLUMNS_NUM) {
      return (columnIndex + 1).toString();
    }
    let carriage = 0;
    if (edit.nCols() < HIDE_EVERY_10TH_COLUMNS_NUM) {
      carriage = 5;
    } else {
      if (edit.nCols() < HIDE_EVERY_50TH_COLUMNS_NUM) {
        carriage = 10;
      } else {
        carriage = 50;
      }
    }
    if ((columnIndex + 1) % carriage === 0) {
      return (columnIndex + 1).toString();
    }
    return '';
  }

  private recreate(edit: PlotsSiteEditModel, allowEditAny: boolean) {
    let updates: ((params: PlotViewParams) => void)[][] = [];

    this.headRow.innerHTML = '';

    this.headRow.appendChild(el('th'));
    let colHeadElems: HTMLElement[] = [];
    for (let i = 0; i < edit.nCols(); i++) {
      let head = el('th');

      const columnNumber = this.calcColumnNumber(edit, i);
      head.textContent = columnNumber;
      if (columnNumber && edit.nCols() >= HIDE_EVERY_5TH_COLUMNS_NUM)
        head.classList.add('bold-bottom-border');

      colHeadElems.push(head);
      this.headRow.appendChild(head);

      const params = { col: i };
      head.onmousedown = (evt) => {
        if (evt.button === 0) {
          this.delegate.onDragStart(edit, params);
        }
      };
    }

    this.tbody.innerHTML = '';

    let rowElems: HTMLElement[] = [];
    let expandRows = [];
    for (let j = 0; j < edit.nRows(); j++) {
      updates.push([]);

      const row = el('tr');
      if (edit.vSplit(j - 1)) {
        row.classList.add('vsplit-top');
      }
      const rowHead = el('th');
      rowHead.textContent = (j + 1).toString();

      const params = { row: j };
      rowHead.onmousedown = (evt) => {
        if (evt.button === 0) {
          this.delegate.onDragStart(edit, params);
        }
      };

      rowElems.push(row);
      row.appendChild(rowHead);

      let expandCols: number[] = [];
      for (let i = 0; i < edit.nCols(); i++) {
        const { root, update } = renderCell(allowEditAny ? this.delegate : null);

        row.appendChild(root);
        updates[j].push(update);
      }
      expandRows.push(expandCols);

      this.tbody.appendChild(row);
    }

    this.removed.innerHTML = '';

    const removedPlots = el('ul', 'drag-container');
    const updateExcludedList = diffList(removedPlots, {
      create: () => {
        const li = el('li', 'draggable-li');
        const { root, update } = plotView();
        li.appendChild(root);

        return { root: li, update };
      },
      update: (view, idx: number) => {
        const plot = edit.excludedPlot(idx);

        view.root.style.display = plot ? null : 'none';
        view.update({ edit, plot });
      },
    });

    const removeTargetArea = el('div', 'drag-container');
    removeTargetArea.setAttribute('data-drag', 'disable');

    const target = el('div', 'plot-remove-target');
    const targetContent = el('div');
    const targetIcon = el('i', 'material-icons left');
    targetIcon.textContent = 'delete_outline';

    targetContent.appendChild(targetIcon);
    target.appendChild(targetContent);
    removeTargetArea.appendChild(target);

    this.removed.appendChild(removedPlots);
    this.removed.appendChild(removeTargetArea);

    return (edit: PlotsSiteEditModel, allowEditAny: boolean) => {
      (this.headRow.firstChild as Element).classList.toggle(
        'col-drag-target',
        edit.drag.isTarget({ col: -1 })
      );
      for (let i = 0; i < edit.nCols(); i++) {
        colHeadElems[i].classList.toggle('col-drag-target', edit.drag.isTarget({ col: i }));
      }
      this.headRow.classList.toggle('row-drag-target', edit.drag.isTarget({ row: -1 }));
      for (let j = 0; j < edit.nRows(); j++) {
        (rowElems[j].firstChild as Element).classList.toggle(
          'col-drag-target',
          edit.drag.isTarget({ col: -1 })
        );
        rowElems[j].classList.toggle('row-drag-target', edit.drag.isTarget({ row: j }));
      }

      for (let j = 0; j < edit.nRows(); j++) {
        for (let i = 0; i < edit.nCols(); i++) {
          updates[j][i]({ edit, plot: edit.plot(i, j), col: i, row: j });
        }
      }

      updateExcludedList(range(edit.nExcluded()), null);

      removeTargetArea.style.display = allowEditAny && edit.canRemove() ? 'block' : 'none';
    };
  }
}

function getPaddingClassPostfixByNumberOfColumns(numberOfColumns?: number): string {
  let postfix = '3';
  if (!numberOfColumns) {
    return postfix;
  }
  if (numberOfColumns >= HIDE_EVERY_5TH_COLUMNS_NUM && numberOfColumns < HIDE_EVERY_10TH_COLUMNS_NUM) {
    postfix = '2';
  } else if (numberOfColumns >= HIDE_EVERY_10TH_COLUMNS_NUM) {
    postfix = '1';
  }
  return postfix;
}

function renderCell(delegate?: PlotViewDelegate) {
  const td = el('td', 'preview-drag-container');

  const classPostfix = getPaddingClassPostfixByNumberOfColumns(delegate?.nColumns());

  const { root, update } = choose<PlotViewParams>(td, (params) => (params.plot ? 'planted' : 'notPlanted'), {
    planted: () => plotPreview(delegate),
    notPlanted: () => plotNotPlantedPreview(delegate),
  });
  return {
    root,
    update: (params: PlotViewParams) => {
      defineCellAttributes(root, params);
      root.setAttribute('col', params.col.toString());
      root.setAttribute('row', params.row.toString());
      root.classList.toggle(`pl${classPostfix}`, params.edit.hSplit(params.col - 1));
      root.classList.toggle(`pr${classPostfix}`, params.edit.hSplit(params.col));
      update(params);
    },
  };
}
