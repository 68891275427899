import * as ko from 'knockout';

import { DimensionMeta } from '../models/dimension_meta';
import { TRIAL_TYPE_SLUG } from '../api/dimension_metas';
import * as trialTypesApi from '../api/trial_types';
import { BaseDimensionRecordEditDelegate } from '../components/base_dimension_record_edit';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { TrialType } from '../models/trial_type';

let template = require('raw-loader!../../templates/trial_type_edit.html').default;

class TrialTypeEditScreen
  implements BaseDimensionRecordEditDelegate<trialTypesApi.TrialTypeData, TrialType>
{
  disableApplications = true;
  allowAnonymize = true;
  allowLinkingCrops = false;
  anyoneCanEdit = false;

  dimensionMetaId = TRIAL_TYPE_SLUG;
  dimensionId: string;
  result: Deferred<trialTypesApi.TrialTypeData>;
  private initialName: string;

  dimension = ko.observable<TrialType>(null);

  constructor(params: { id: string; initialName?: string; result?: Deferred<trialTypesApi.TrialTypeData> }) {
    this.dimensionId = params.id;
    this.result = params.result;
    this.initialName = params.initialName;
  }

  fetch(dimensionMetaId: string, id: string) {
    return trialTypesApi.retrieve(id);
  }

  instantiate(data: trialTypesApi.TrialTypeData, dimensionMeta: DimensionMeta) {
    let stage = new TrialType(data, dimensionMeta);
    if (!data) {
      if (this.initialName) {
        stage.nameJson(asI18nText(this.initialName));
      }
    }

    return stage;
  }

  saveRequest(data: trialTypesApi.TrialTypeData) {
    return trialTypesApi.save(data);
  }
}

export let trialTypeEdit = {
  name: 'trial-type-edit',
  viewModel: TrialTypeEditScreen,
  template: template,
};

ko.components.register(trialTypeEdit.name, trialTypeEdit);
