import * as ko from 'knockout';
import i18n from '../i18n';

import { ValueMetaData, ValueMetaValidationData } from '../api/value_meta_data';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as measurementTypesApi from '../api/measurement_types';
import * as attribuceChoiceListsApi from '../api/attribute_choice_lists';
import * as unitsApi from '../api/units';
import { DatasetDimensionMetaData } from '../api/datasets';
import { parseDate, parseDateTime, serializeDate, serializeDateTime } from '../api/serialization';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { DimensionsTableConfig } from '../components/dimensions_table';
import { Trial } from '../models/trial';
import { DatasetDimensionMeta, LimitToDimension } from '../models/dataset_dimension_meta';
import { DimensionMeta } from '../models/dimension_meta';
import { slugValidation, SlugGenerator } from '../ko_bindings/slug_validation';
import { I18nText, copyI18nText, asI18nText, translate } from '../i18n_text';
import { UserData } from '../api/users';
import { canEditMeasurementType, canEditTrial, canEditAttributeChoiceList } from '../permissions';
import { CropData } from '../api/crops';
import { getUnitSearchConfig } from '../components/configs/search_configs';

interface ValueMetaOptions {
  allowRequired: boolean;
  selectUnitForTrial: Trial;
  types: { name: KnockoutComputed<string>; value: string }[];
  hasHelpText: boolean;
  allowEditNameSlug: boolean;
  useCropPrefix: boolean;
  requireMeasurementType: boolean;
}

export const MAX_DIGITS = 17;
export const MAX_DECIMALS = 6;
export const INT_REGEXP = /^[-+]?(\d+)$/;
export const INT_REGEXP_DIGITS_GROUP = 1;
// DEC_REGEXP matches the empty string, but that's OK since we're checking for it before using it
export const DEC_REGEXP = /^[-+]?(\d+)?(\.(\d+))?$/;
export const DEC_REGEXP_INT_DIGITS_GROUP = 1;
export const DEC_REGEXP_DEC_DIGITS_GROUP = 3;

export abstract class ValueMeta {
  private slugGenerator: SlugGenerator;
  protected subscriptions: KnockoutSubscription[] = [];
  // this is true for DDMs added because they're needed
  // by ranking measurements, or for legacy trials
  // where DDMs could be defined without being plot or required
  isDDMEntity = false;

  id = ko.observable<string>(null);
  crops = ko.observableArray<CropData>(); // MM only
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  nameSlug = ko.observable('').extend(slugValidation);
  order = ko.observable<number>(null);
  type = ko.observable('string').extend({ serverError: true });
  unit = ko.observable<unitsApi.UnitData>(null);
  needsMeasurementType = ko.pureComputed(() => {
    return this.type() === 'choice';
  });
  measurementType = ko.observable<measurementTypesApi.MeasurementTypeData>(null).extend({
    required: {
      onlyIf: () => this.options.requireMeasurementType && this.needsMeasurementType(),
    },
  });
  needsChoiceList = ko.pureComputed(() => {
    return this.type() === 'attr_choice_list';
  });
  choiceList = ko.observable<attribuceChoiceListsApi.AttributeChoiceListData>(null).extend({
    required: {
      onlyIf: () => this.needsChoiceList(),
    },
  });
  needsDimensionMeta = ko.pureComputed(() => {
    return this.type() === 'ranking';
  });
  // NOTE: this is a DimensionMetaData instead of a DatasetDimensionMeta because we allow
  // the user to pick any DimensionMeta, and then it's responsibility of the DatasetEditScreen
  // to ensure this DimensionMeta always corresponds to a DatasetDimensionMeta
  dimensionMeta = ko.observable<dimensionMetasApi.DimensionMetaData>(null).extend({
    required: {
      onlyIf: () => this.needsDimensionMeta(),
    },
  });
  isEntity = ko.pureComputed(() => {
    return this.type() === 'entity';
  });
  entityDimensionMeta = ko.observable<dimensionMetasApi.DimensionMetaData>(null).extend({
    serverError: true,
    required: {
      onlyIf: () => this.isEntity(),
    },
  });
  entityLimitTo = ko.observableArray<LimitToDimension>();
  entityAllowCreate = ko.observable(false);
  autoFill = ko.observable(false);
  includeInSummary = ko.observable(true);
  useForPlantingDate = ko.observable(false);
  excludeFromApp = ko.observable(false);
  gpsPrecisionCheckDisabled = ko.observable(false);
  helpTextJson = ko.observable<I18nText>();
  validationRequired = ko.observable(false);
  validationNumberMinValue = ko.observable<string>(null).extend({
    number: true,
    validation: {
      validator: (value: string): boolean => {
        return !value || !value.trim() || !!value.trim().match(INT_REGEXP);
      },
      message: i18n.t('Min value must be an integer')(),
      onlyIf: () => this.type() === 'integer',
    },
  });
  validationNumberMaxValue = ko.observable<string>(null).extend({
    number: true,
    validation: {
      validator: (value: string): boolean => {
        return !value || !value.trim() || !!value.trim().match(INT_REGEXP);
      },
      message: i18n.t('Max value must be an integer')(),
      onlyIf: () => this.type() === 'integer',
    },
  });
  validationDateMinValue = ko.observable<Date>(null);
  validationDateMaxValue = ko.observable<Date>(null);
  validationDateTimeMinValue = ko.observable<Date>(null).extend({
    validatable: true,
  });
  validationDateTimeMaxValue = ko.observable<Date>(null).extend({
    validatable: true,
  });
  validationMaxDecimals = ko.observable<string>(null).extend({
    digit: true,
    min: 0,
    max: MAX_DIGITS - 1,
  });

  isDate = ko.pureComputed(() => {
    return this.type() === 'date';
  });

  isLocation = ko.pureComputed(() => {
    return this.type() === 'location';
  });

  canValidateNumberRange = ko.pureComputed(() => {
    return this.type() === 'integer' || this.type() == 'decimal';
  });

  canValidateDateRange = ko.pureComputed(() => {
    return this.type() === 'date';
  });

  canValidateDateTimeRange = ko.pureComputed(() => {
    return this.type() === 'timestamp';
  });

  canValidateNumberPrecision = ko.pureComputed(() => {
    return this.type() === 'decimal';
  });

  canSelectUnit = ko.pureComputed(() => {
    return this.type() == 'integer' || this.type() == 'decimal';
  });

  needsHelpText = ko.pureComputed(() => {
    return !this.isDDMEntity && this.options.hasHelpText;
  });

  errors = ko.validation.group(this);

  hasErrors(): boolean {
    return (
      this.errors().length > 0 ||
      !!this.entityDimensionMeta.serverError() ||
      !!this.nameJson.serverError() ||
      !!this.nameSlug.serverError()
    );
  }

  showErrors() {
    this.errors.showAllMessages();
  }

  unitSearchConfig = getUnitSearchConfig(this.unit);

  dimensionMetaSearchConfig: FormSelectSearchConfiguration<dimensionMetasApi.DimensionMetaData> = {
    getSummaryName: (entity) => {
      return entity.name_json;
    },

    list: (params) => {
      return dimensionMetasApi.listExcludingDates(params);
    },

    entity: this.dimensionMeta,

    create: {
      title: i18n.t('Entity')(),
      componentName: 'dimension-meta-edit',
      extraParams: { disableDate: true },
    },
  };

  entityDimensionMetaSearchConfig: FormSelectSearchConfiguration<dimensionMetasApi.DimensionMetaData> = {
    getSummaryName: (entity) => {
      return entity.name_json;
    },

    list: (params) => {
      return this.isDDMEntity
        ? dimensionMetasApi.list(params)
        : dimensionMetasApi.listExcludingDates(params);
    },

    entity: this.entityDimensionMeta,

    create: {
      title: i18n.t('Dimension')(),
      componentName: 'dimension-meta-edit',
      extraParams: { disableDate: !this.isDDMEntity },
    },
  };

  getDimensionsConfig(user: UserData, allowEdit: boolean, allowEditAny: boolean): DimensionsTableConfig {
    let trial = this.options.selectUnitForTrial;

    return {
      user: user,

      canReorder: true,
      addDimensionText: i18n.t('Add selectable records')(),

      dimensionMeta: new DimensionMeta(this.entityDimensionMeta()),
      dimensions: this.entityLimitTo,
      crops: trial ? trial.crop : this.crops,
      country: trial && trial.country,
      region: trial && trial.region,

      allowAnonymize: true,
      allowEditControl: false,
      allowEditDisable: false,
      allowEditOptional: false,
      allowEdit: ko.observable(this.canEdit(allowEdit)),
      allowEditAny: ko.observable(allowEditAny && (trial ? canEditTrial(user, trial) : true)),
    };
  }

  getMeasurementTypeSearchConfig(
    user: UserData
  ): FormSelectSearchConfiguration<measurementTypesApi.MeasurementTypeData> {
    return {
      getSummaryName: (entity) => {
        if (!entity.crop) {
          return entity.name_json;
        }

        return translate(entity.crop.name_json) + ' - ' + translate(entity.name_json);
      },

      list: (params) => {
        let crop = this.getCrop();
        let extendedParams: measurementTypesApi.MeasurementTypeListRequestParams = {
          offset: params.offset,
          limit: params.limit,
          name_prefix: params.name_prefix,
          crop_ids: crop ? [crop.id] : undefined,
          include_null_crop: true,
          null_crop_last: true,
        };

        return measurementTypesApi.list(extendedParams);
      },

      entity: this.measurementType,

      create: canEditMeasurementType(user)
        ? {
            title: i18n.t('Measurement Type')(),
            componentName: 'measurement-type-edit',
            extraParams: {
              initialCrop: this.getCrop(),
            },
          }
        : undefined,
    };
  }

  private uniqueCrop = ko.pureComputed(() => {
    if (this.crops().length === 1) {
      return this.crops()[0];
    }

    return null;
  });

  private getCrop() {
    return this.uniqueCrop() || (this.options.selectUnitForTrial && this.options.selectUnitForTrial.crop());
  }

  getChoiceListConfig(): FormSelectSearchConfiguration<attribuceChoiceListsApi.AttributeChoiceListData> {
    return {
      getSummaryName: (entity) => {
        return entity.name_json;
      },

      list: (params) => {
        return attribuceChoiceListsApi.list(params);
      },

      entity: this.choiceList,

      create: canEditAttributeChoiceList()
        ? {
            title: i18n.t('Attribute Choice')(),
            componentName: 'attribute-choice-list-edit',
          }
        : undefined,
    };
  }

  canEdit = (allowEdit: boolean) => {
    return allowEdit || !this.id();
  };

  constructor(private options: ValueMetaOptions, data?: ValueMetaData) {
    this.options = options;

    if (data) {
      this.setValueMetaData(data);
    }

    this.subscriptions.push(
      this.needsDimensionMeta.subscribe((value) => {
        if (!value) {
          this.dimensionMeta(null);
        }
      })
    );

    this.subscriptions.push(
      this.entityDimensionMeta.subscribe(() => {
        let dm = this.entityDimensionMeta();

        if (this.isDDMEntity) {
          if (dm) {
            this.nameJson(copyI18nText(dm.name_json));
          } else {
            this.nameJson(asI18nText(''));
          }
        }

        this.entityLimitTo.removeAll();
      })
    );

    let slugPrefix = options.useCropPrefix ? this.uniqueCrop : null;
    this.slugGenerator = new SlugGenerator(this.nameJson, slugPrefix, this.nameSlug, {
      canEdit: options.allowEditNameSlug,
      fillIfEmpty: true,
    });
  }

  setValueMetaData(data: ValueMetaData) {
    this.id(data.id);
    this.order(data.order);
    this.type(data.type);
    this.nameJson(data.name_json);
    this.nameSlug(data.name_slug);

    this.validationRequired(false);
    this.validationNumberMinValue(null);
    this.validationNumberMaxValue(null);
    this.validationDateMinValue(null);
    this.validationDateMaxValue(null);
    this.validationDateTimeMinValue(null);
    this.validationDateTimeMaxValue(null);
    this.validationMaxDecimals(null);

    if (data.validation) {
      this.validationRequired(!!data.validation.required);
      if (this.canValidateNumberRange()) {
        if (data.validation.number_min_value !== null && data.validation.number_min_value !== undefined) {
          this.validationNumberMinValue('' + data.validation.number_min_value);
        }
        if (data.validation.number_max_value !== null && data.validation.number_max_value !== undefined) {
          this.validationNumberMaxValue('' + data.validation.number_max_value);
        }
      }
      if (this.canValidateDateRange()) {
        if (data.validation.date_min_value) {
          this.validationDateMinValue(parseDate(data.validation.date_min_value));
        }
        if (data.validation.date_max_value) {
          this.validationDateMaxValue(parseDate(data.validation.date_max_value));
        }
      }
      if (this.canValidateDateTimeRange()) {
        if (data.validation.timestamp_min_value) {
          this.validationDateTimeMinValue(parseDateTime(data.validation.timestamp_min_value));
        }
        if (data.validation.timestamp_max_value) {
          this.validationDateTimeMaxValue(parseDateTime(data.validation.timestamp_max_value));
        }
      }
      if (data.validation.precision && this.canValidateNumberPrecision()) {
        if (
          data.validation.precision.decimals !== undefined &&
          data.validation.precision.decimals !== null
        ) {
          this.validationMaxDecimals('' + data.validation.precision.decimals);
        }
      }
    }
  }

  initFromDatasetDimensionMeta(ddm: DatasetDimensionMeta) {
    this.isDDMEntity = true;

    this.id(ddm.id());
    this.type('entity');
    this.entityDimensionMeta(ddm.dimensionMeta().toData());
    this.entityLimitTo(ddm.limitTo());
    this.entityAllowCreate(ddm.allowCreate());
  }

  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
    this.slugGenerator.dispose();
  }

  toDatasetDimensionMetaData(): DatasetDimensionMetaData {
    if (!this.isEntity()) {
      throw new Error("can't serialize, this isn't a DDM");
    }

    return {
      id: this.id(),
      dimension_meta_id: this.entityDimensionMeta(),
      order: this.order(),
      limit_to: this.entityLimitTo().map((dim) => dim.toData()),
      visibility: 'visible',
      use_for_plot: false,
      required: false,
      allow_create: this.entityAllowCreate(),
    };
  }

  toData(): ValueMetaData {
    if (this.isDDMEntity) {
      throw new Error("can't serialize, this is actually a DDM");
    }

    let validation: ValueMetaValidationData = {
      required: this.validationRequired(),
    };

    if (this.canValidateNumberRange()) {
      if (this.validationNumberMinValue()) {
        validation.number_min_value = +this.validationNumberMinValue();
      }
      if (this.validationNumberMaxValue()) {
        validation.number_max_value = +this.validationNumberMaxValue();
      }
    }

    if (
      this.canValidateDateRange() &&
      (this.validationDateMinValue() !== null || this.validationDateMaxValue() !== null)
    ) {
      validation.date_min_value = serializeDate(this.validationDateMinValue());
      validation.date_max_value = serializeDate(this.validationDateMaxValue());
    }

    if (
      this.canValidateDateTimeRange() &&
      (this.validationDateTimeMinValue() !== null || this.validationDateTimeMaxValue() !== null)
    ) {
      validation.timestamp_min_value = serializeDateTime(this.validationDateTimeMinValue());
      validation.timestamp_max_value = serializeDateTime(this.validationDateTimeMaxValue());
    }

    if (this.canValidateNumberPrecision()) {
      const precision = parseInt(this.validationMaxDecimals(), 10)
      validation.precision = {
        decimals: precision != NaN ? precision : null,
      };
    }

    return {
      id: this.id(),
      name_json: this.nameJson(),
      order: this.order(),
      type: this.type(),
      name_slug: this.nameSlug(),
      validation: validation,
    };
  }
}
