import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { countriesApi, CountryData } from '../api/countries';
import { Country } from '../models/country';
import { session } from '../session';
import { canCreateCountry } from '../permissions';
import { Action } from '../components/basic_widgets';
import { addChangelogAction, addChangelogListAction } from './changelog';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/countries.html').default;

class CountriesScreen implements ListLoaderDelegate<CountryData, Country> {
  showRegions = session.isS2bim();
  canCreate = canCreateCountry();
  listActions = ko.observableArray<ListHeaderAction>([]);

  constructor() {
    if (this.canCreate) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/countries/new/',
        tooltipTitle: i18n.t('Add country')(),
      });
    }
    this.listActions.push(...addChangelogListAction('country'));
  }
  fetch(params: ListRequestParams) {
    return countriesApi.list(params);
  }

  instantiate(data: CountryData) {
    return new Country(data);
  }

  getEditUrl(entity: Country) {
    return entity.editUrl();
  }

  remove(id: string) {
    return countriesApi.remove(id);
  }

  canRemove(entity: Country) {
    return entity.canEdit();
  }

  getActions(entity: Country): Action[] {
    return addChangelogAction('country', entity.id());
  }
}

export let countries = {
  name: 'countries',
  viewModel: CountriesScreen,
  template: template,
};

ko.components.register(countries.name, countries);
