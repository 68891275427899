import * as ko from 'knockout';

import i18n from '../i18n';
import * as tppsApi from '../api/tpps';
import * as usersApi from '../api/users';
import * as countriesApi from '../api/countries';
import * as dimensionsApi from '../api/dimensions';
import * as agroRegionsApi from '../api/agro_regions';
import * as regionsApi from '../api/regions';
import * as cropVarietiesApi from '../api/crop_varieties';
import * as staticOptionsApi from '../api/static';
import * as traitsCategoryApi from '../api/trait_categories';
import * as mmApi from '../api/measurement_metas';
import * as clientTypesApi from '../api/client_types';
import * as driversApi from '../api/drivers';
import { I18nText, translate } from '../i18n_text';
import {
  getUserSearchConfig,
  getCountrySearchConfig,
  getCropSearchConfig,
  getAgroRegionSearchConfig,
  getCropVarietyListSearchConfig,
  getProjectSearchConfig,
  getRegionSearchConfig,
  getStaticListSearchConfig,
} from '../components/configs/search_configs';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { MeasurementMetaData } from '../api/datasets';
import { TraitCategoryData } from '../api/trait_categories';
import { CropData } from '../api/crops';
import { NameData } from '../api/names';
import { readDecimal, emptyToNull, indexOf } from '../utils';
import { parseDate, serializeDate } from '../api/serialization';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { CROP_VARIETY_SLUG, TRIAL_TYPE_SLUG } from '../api/dimension_metas';
import { Point } from '../ko_bindings/map';
import { COUNTRY_CENTERS } from '../utils/country_centers';
import { FileUploadDelegate } from '../components/basic_widgets';
import { CloudStorageUploadDelegate, CloudStorageUpload, FileUploadEndpoint } from '../cloud_storage_upload';
import { ListRequestParams } from '../api/request';
import { MM_AGG_OPTIONS, ATTRIBUTE_TYPES_WITH_DDM } from './measurement_meta';
import { TrialTypeData } from '../api/trial_types';
import { DriverData } from '../api/drivers';
import { ClientTypeData } from '../api/client_types';
import { canEditTPP } from '../permissions';

export class TPP implements FileUploadDelegate, CloudStorageUploadDelegate {
  stateOptions = [
    { id: 'active', title: i18n.t('Active')() },
    { id: 'discarded', title: i18n.t('Discarded')() },
    { id: 'completed', title: i18n.t('Completed')() },
  ];

  marketTrendOptions = [
    { id: '', title: i18n.t('Select')() },
    { id: 'small_inc', title: i18n.t('Small & Inc')() },
    { id: 'large_inc', title: i18n.t('Large & Inc')() },
    { id: 'small_stable', title: i18n.t('Small & Stable')() },
    { id: 'large_stable', title: i18n.t('Large & Stable')() },
    { id: 'unknown', title: i18n.t('Unknown')() },
  ];

  expectedLevelOptions = [
    { id: '', title: i18n.t('Select')() },
    { id: 'low', title: i18n.t('Low')() },
    { id: 'medium', title: i18n.t('Medium')() },
    { id: 'high', title: i18n.t('High')() },
  ];

  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  author = ko.observable<{ id?: string; name: string }>().extend({
    required: true,
  });
  country = ko.observable<countriesApi.CountryData>().extend({
    required: true,
  });
  externalID = ko.observable<string>(null);
  regions = ko.observableArray<regionsApi.RegionData>();
  countryLocation = ko.observable<Point>(COUNTRY_CENTERS['CH']);
  crop = ko.observable<CropData>().extend({
    required: true,
  });
  marketPositioning = ko.observable('');
  marketScale = ko.observableArray<staticOptionsApi.StaticList>();
  cropUse = ko.observable('');
  cropCharacteristics = ko.observable('');
  processingType = ko.observable('');
  targetAgroRegions = ko.observableArray<agroRegionsApi.AgroRegionData>().extend({
    required: true,
  });
  seedValue = ko.pureComputed(() => {
    let price = parseFloat(this.seedPrice());
    let need = this.certSeedNeedForSegment();

    if (isNaN(price) || need === null) {
      return null;
    }

    return (price * need) / 1000;
  });
  marketSizeSegment = ko.observable('').extend({
    number: true,
    serverError: true,
  });
  marketSizeSegmentActual = ko.observable('').extend({
    number: true,
    serverError: true,
  });
  marketTrend = ko.observable<tppsApi.TPPMarketTrend>('');

  seedNeedForSegment = ko.pureComputed(() => {
    let rate = parseFloat(this.plantingRate());
    let surface = parseFloat(this.marketSizeSegmentActual());

    if (isNaN(rate) || isNaN(surface)) {
      return null;
    }

    return rate * surface;
  });
  seedAcreageNeedForSegment = ko.pureComputed(() => {
    let need = this.certSeedNeedForSegment();
    let avgYield = null;
    if (this.typicalYieldRangeSegmentMin() && this.typicalYieldRangeSegmentMax()) {
      avgYield =
        (parseFloat(this.typicalYieldRangeSegmentMin()) * 1 +
          parseFloat(this.typicalYieldRangeSegmentMax()) * 1) /
        2;
    }

    if (need === null || isNaN(avgYield) || Math.abs(avgYield) < 0.000001) {
      return null;
    }

    return need / avgYield;
  });
  certSeedNeedForSegment = ko.pureComputed<number | null>(() => {
    let seedNeed = this.seedNeedForSegment();
    let rate = parseFloat(this.rate()) / 100;

    if (isNaN(rate) || seedNeed === null) {
      return null;
    }

    return seedNeed * rate;
  });
  seedMarketSizeSegment = ko.observable('').extend({ number: true, serverError: true });
  seedPrice = ko.observable('').extend({ number: true, serverError: true });
  plantingRate = ko.observable('').extend({ number: true, serverError: true });
  typicalYieldRangeSegmentMin = ko.observable('').extend({ number: true, serverError: true });
  typicalYieldRangeSegmentMax = ko.observable('').extend({ number: true, serverError: true });
  actualGrainProductionSegment = ko.observable('').extend({ number: true, serverError: true });
  rate = ko.observable('').extend({ number: true, serverError: true });
  cropRange = ko.observable('');
  competitionStrength = ko.observable('');
  dominantVarieties = ko.observableArray<cropVarietiesApi.CropVarietyData>();
  strategy = ko.observable('');
  targetNVarieties = ko.observable('').extend({
    required: true,
    digit: true,
    serverError: true,
  });
  project = ko.observable<NameData>(null).extend({ required: true });
  marketInfoUrl = ko.observable('').extend({ serverError: true });
  marketInfoFileName = ko.observable<string>(null);
  marketInfoFileUrl = ko.observable('');
  marketInfoUserFileName = '';
  farmerGroups = ko.observable('');
  nFarmersMin = ko.observable('').extend({ digit: true });
  nFarmersMax = ko.observable('').extend({ digit: true });
  femaleFarmersPercMin = ko.observable('').extend({ digit: true });
  femaleFarmersPercMax = ko.observable('').extend({ digit: true });
  productionSystem = ko.observableArray<staticOptionsApi.StaticList>();
  croppingSystem = ko.observableArray<staticOptionsApi.StaticList>();
  cropProtectionExpectedInputLevel = ko.observable('');
  fertilizerExpectedInputLevel = ko.observable('');
  growthHabit = ko.observable('');
  mechanizationSegment = ko.observable('');
  state = ko.observable<tppsApi.TPPState>('active');
  stateChange = ko.observable<Date>(null).extend({
    required: { onlyIf: () => this.state() !== 'active' },
  });
  traits = ko.observableArray<TPPTrait>();
  team = ko.observableArray<TPPTeamMember>();

  created = ko.observable<Date>(new Date());
  modified = ko.observable<Date>(new Date());

  authorSearchConfig = getUserSearchConfig(this.author);
  countrySearchConfig = getCountrySearchConfig(this.country);
  regionSearchConfig = getRegionSearchConfig(this.regions, this.country);
  cropSearchConfig = getCropSearchConfig(this.crop);
  targetAgroRegionsSearchConfig = getAgroRegionSearchConfig(this.targetAgroRegions, this.country);
  dominantVarietySearchConfig: FormSelectSearchConfiguration<cropVarietiesApi.CropVarietyData>;
  projectSearchConfig = getProjectSearchConfig(this.project);
  productionSystemConfig = getStaticListSearchConfig(
    this.productionSystem,
    staticOptionsApi.productionSystemChoices
  );
  croppingSystemConfig = getStaticListSearchConfig(
    this.croppingSystem,
    staticOptionsApi.croppingSystemChoices
  );
  marketScaleConfig = getStaticListSearchConfig(this.marketScale, staticOptionsApi.marketScaleChoices);
  /*
   * Scale up: at least one variety at stage 7
   * Develop: at least one variety at stage 6
   * Innovate: at least one variety at stage 5
   * Discard: take from TPP state
   */
  private trialCVStages: tppsApi.CVStageData[] = [];
  stage = ko.pureComputed(() => {
    if (this.state() === 'discarded') {
      return i18n.t('Discarded')();
    }

    let checks = this.dominantVarieties().map((cv) => cv.id);
    for (let trait of this.traits()) {
      for (let traitCheck of trait.benchmarkVarieties()) {
        checks.push(traitCheck.id);
      }
    }
    let curState = 'initial';
    for (let cvStage of this.trialCVStages) {
      if (checks.indexOf(cvStage.cv_id) > -1) {
        continue;
      }

      if (cvStage.stage_name === 'Stage 5' && curState === 'initial') {
        curState = 'innovate';
      }
      if (cvStage.stage_name === 'Stage 6' && (curState === 'initial' || curState === 'innovate')) {
        curState = 'develop';
      }
      if (
        cvStage.stage_name === 'Stage 7' &&
        (curState === 'initial' || curState === 'innovate' || curState === 'develop')
      ) {
        curState = 'scale_up';
        break; // done, no further state changes possible
      }
    }

    let formatted: { [key: string]: string } = {
      initial: i18n.t('Initial')(),
      innovate: i18n.t('Innovate')(),
      develop: i18n.t('Develop')(),
      scale_up: i18n.t('Scale up')(),
    };

    return formatted[curState];
  });

  private coverErrorGroup = ko.validation.group([
    this.author,
    this.nameJson,
    this.project,
    this.targetNVarieties,
    this.country,
    this.crop,
    this.targetAgroRegions,
    this.cropUse,
    this.cropCharacteristics,
    this.dominantVarieties,
    this.strategy,
    this.marketTrend,
    this.marketInfoUrl,
    this.stateChange,
    this.state,
    this.stage,
    this.competitionStrength,
    this.farmerGroups,
    this.seedPrice,
    this.rate,
    this.plantingRate,
    this.nFarmersMin,
    this.nFarmersMax,
    this.femaleFarmersPercMin,
    this.femaleFarmersPercMax,
    this.marketSizeSegmentActual,
    this.actualGrainProductionSegment,
    this.team,
    this.typicalYieldRangeSegmentMin,
    this.typicalYieldRangeSegmentMax,
    this.cropProtectionExpectedInputLevel,
    this.fertilizerExpectedInputLevel,
  ]);

  traitsError = ko.pureComputed(() => {
    let seen: { [key: string]: number } = {};
    let error: string[] = [];
    let requiredErrors: string[] = [];
    for (let trait of this.traits()) {
      let mm = trait.measurementMeta();
      if (mm) {
        if (seen[mm.id] === 1) {
          error.push(translate(mm.name_json));
        }
        seen[mm.id] = (seen[mm.id] || 0) + 1;

        if (trait.coverErrorGroup().length > 0) {
          requiredErrors.push(translate(mm.name_json));
        }
      }
    }
    if (error.length > 0) {
      return i18n.t('The following traits cannot be selected multiple times:')() + ' ' + error.join(', ');
    }
    if (requiredErrors.length > 0) {
      return i18n.t('The following traits cannot be saved:')() + ' ' + requiredErrors.join(', ');
    }
    return '';
  });

  groupedTraits = ko.pureComputed(() => {
    let pointStyles = [
      'circle',
      'cross',
      'crossRot',
      'dash',
      'line',
      'rect',
      'rectRounded',
      'rectRot',
      'star',
      'triangle',
    ];
    let colors = [
      '#1f77b4',
      '#ff7f0e',
      '#2ca02c',
      '#d62728',
      '#9467bd',
      '#8c564b',
      '#e377c2',
      '#7f7f7f',
      '#bcbd22',
      '#17becf',
    ];
    let idx = 0;

    let groups: {
      catName: string;
      pointStyle: string;
      color: string;
      traits: TPPTrait[];
    }[] = [];
    let traits = this.traits().filter((trait) => trait.traitCategory() && trait.measurementMeta());
    traits.sort((a, b) =>
      translate(a.traitCategory().name_json).localeCompare(translate(b.traitCategory().name_json))
    );

    let prev: string = undefined;
    for (let trait of this.traits()) {
      let catName = trait.traitCategory() ? translate(trait.traitCategory().name_json) : '';
      if (catName !== prev) {
        // new group
        groups.push({
          catName,
          pointStyle: pointStyles[idx],
          color: colors[idx],
          traits: [],
        });
        idx++;
        prev = catName;
      }

      groups[groups.length - 1].traits.push(trait);
    }

    return groups;
  });

  private subscriptions: KnockoutSubscription[] = [];

  constructor(user: usersApi.UserData, data?: tppsApi.TPPData) {
    this.dominantVarietySearchConfig = getCropVarietyListSearchConfig(this.dominantVarieties, this.crop);

    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.author(data.author);
      this.country(data.country);
      this.regions(data.regions);
      this.crop(data.crop);
      this.marketPositioning(data.market_positioning);
      this.marketScale(
        staticOptionsApi.marketScaleChoices.filter((choice) => (data.market_scale || []).includes(choice.id))
      );
      this.externalID(data.external_id);
      this.cropUse(data.crop_use);
      this.cropCharacteristics(data.crop_characteristics);
      this.processingType(data.processing_type);
      this.targetAgroRegions(data.target_agro_regions);
      this.marketSizeSegment(readDecimal(data.market_size_segment));
      this.marketSizeSegmentActual(readDecimal(data.market_size_segment_actual));
      this.marketTrend(data.market_trend);
      this.seedPrice(readDecimal(data.seed_price));
      this.plantingRate(readDecimal(data.planting_rate));
      this.typicalYieldRangeSegmentMin(readDecimal(data.typical_yield_range_segment_min));
      this.typicalYieldRangeSegmentMax(readDecimal(data.typical_yield_range_segment_max));
      this.actualGrainProductionSegment(readDecimal(data.actual_grain_production_segment));
      this.rate(readDecimal(data.rate));
      this.cropRange(data.crop_range);
      this.competitionStrength(data.competition_strength);
      this.dominantVarieties(data.dominant_varieties);
      this.targetNVarieties(data.target_n_varieties ? data.target_n_varieties.toString() : '');
      this.project(data.project);
      this.marketInfoUrl(data.market_info_url);
      this.marketInfoFileName(data.market_info_file_name);
      this.marketInfoFileUrl(data.market_info_file_url);
      this.marketInfoUserFileName = data.market_info_user_file_name;
      this.farmerGroups(data.farmer_groups);
      this.nFarmersMin(data.n_farmers_min?.toString() ?? '');
      this.nFarmersMax(data.n_farmers_max?.toString() ?? '');
      this.femaleFarmersPercMin(data.female_farmers_perc_min?.toString() ?? '');
      this.femaleFarmersPercMax(data.female_farmers_perc_max?.toString() ?? '');
      this.productionSystem(
        staticOptionsApi.productionSystemChoices.filter((choice) =>
          (data.production_system || []).includes(choice.id)
        )
      );
      this.croppingSystem(
        staticOptionsApi.croppingSystemChoices.filter((choice) =>
          (data.cropping_system || []).includes(choice.id)
        )
      );
      this.cropProtectionExpectedInputLevel(data.crop_protection_expected_input_level);
      this.fertilizerExpectedInputLevel(data.fertilizer_expected_input_level);
      this.growthHabit(data.growth_habit);
      this.mechanizationSegment(data.mechanization_segment);
      this.state(data.state);
      this.stateChange(parseDate(data.state_change));
      this.traits((data.traits || []).map((traitData) => new TPPTrait(this.crop, traitData)));

      const teamData = data.team || [];
      teamData.sort((a, b) => a.order - b.order);
      this.team(teamData.map((data) => new TPPTeamMember(data)));

      this.created(parseDate(data.created));
      this.modified(parseDate(data.modified));

      this.trialCVStages = data.trial_cv_stages || [];
    } else {
      this.author(user);
    }

    this.onCountryChanged();
    this.subscriptions.push(this.country.subscribe(this.onCountryChanged));
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
    this.traits().forEach((trait) => trait.dispose());
  }

  onCountryChanged = () => {
    let location = this.country() && COUNTRY_CENTERS[this.country().iso_country_code];
    if (location) {
      this.countryLocation(location);
    }
  };

  hasCoverError() {
    return this.coverErrorGroup().length > 0;
  }

  hasProfileInputError() {
    return this.traitsError() || ko.validation.group(this.traits, { deep: true })().length > 0;
  }

  addTrait() {
    this.traits.push(new TPPTrait(this.crop));
  }

  removeTrait(trait: TPPTrait) {
    if (trait.isLocked()) {
      return;
    }
    trait.dispose();
    this.traits.remove(trait);
  }

  addTeamMember() {
    this.team.push(new TPPTeamMember());
  }

  removeTeamMember = (tm: TPPTeamMember) => {
    this.team.remove(tm);
  };

  marketInfoUpload = new CloudStorageUpload(this);
  canReuseEndpoint = true;
  fileUploadError = this.marketInfoUpload.fileUploadError;

  onFileContents(
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest
  ): void {
    return this.marketInfoUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
  }

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return tppsApi.tppUploadEndpoint(contentType);
  }

  onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
    this.marketInfoUserFileName = userFileName;
    this.marketInfoFileName(fileName);
    this.marketInfoFileUrl(publicURL);
  }

  toData(): tppsApi.TPPData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      author: this.author(),
      country: this.country(),
      regions: this.regions(),
      crop: this.crop(),
      market_positioning: this.marketPositioning(),
      market_scale: this.marketScale().map((el) => el.id),
      crop_use: this.cropUse(),
      crop_characteristics: this.cropCharacteristics(),
      processing_type: this.processingType(),
      target_agro_regions: this.targetAgroRegions(),
      market_size_segment: emptyToNull(this.marketSizeSegment()),
      market_size_segment_actual: emptyToNull(this.marketSizeSegmentActual()),
      market_trend: this.marketTrend(),
      seed_price: emptyToNull(this.seedPrice()),
      planting_rate: emptyToNull(this.plantingRate()),
      typical_yield_range_segment_min: emptyToNull(this.typicalYieldRangeSegmentMin()),
      typical_yield_range_segment_max: emptyToNull(this.typicalYieldRangeSegmentMax()),
      actual_grain_production_segment: emptyToNull(this.actualGrainProductionSegment()),
      rate: this.rate() || null,
      crop_range: this.cropRange(),
      competition_strength: this.competitionStrength(),
      dominant_varieties: this.dominantVarieties(),
      target_n_varieties: emptyToNull(this.targetNVarieties()),
      project: this.project(),
      market_info_url: this.marketInfoUrl(),
      market_info_file_name: this.marketInfoFileName(),
      market_info_user_file_name: this.marketInfoUserFileName,
      farmer_groups: this.farmerGroups(),
      n_farmers_min: emptyToNull(this.nFarmersMin()),
      n_farmers_max: emptyToNull(this.nFarmersMax()),
      female_farmers_perc_min: emptyToNull(this.femaleFarmersPercMin()),
      female_farmers_perc_max: emptyToNull(this.femaleFarmersPercMax()),
      production_system: this.productionSystem().map((el) => el.id),
      cropping_system: this.croppingSystem().map((el) => el.id),
      fertilizer_expected_input_level: this.fertilizerExpectedInputLevel(),
      crop_protection_expected_input_level: this.cropProtectionExpectedInputLevel(),
      growth_habit: this.growthHabit(),
      mechanization_segment: this.mechanizationSegment(),
      state: this.state(),
      state_change: serializeDate(this.stateChange()),
      traits: this.traits().map((trait) => trait.toData()),
      team: this.team().map((member, order) => member.toData(order)),
      external_id: this.externalID(),
    };
  }
}

const scoreValidation = {
  validator: (value: string) => {
    let score = parseInt(value, 10);
    return value && value.match(/^[1-9][0-9]*$/) && score >= 1 && score <= 4;
  },
  message: i18n.t('Must be a value between 1 and 4')(),
};

export class TPPTrait {
  requiredOptions = ['<', '≤', '=', '>', '≥'];
  preferenceGroupOptions: {
    title: string;
    value: tppsApi.TPPTraitPreferenceGroup;
  }[] = [
    { title: i18n.t('Women')(), value: 'W' },
    { title: i18n.t('Men')(), value: 'M' },
    { title: i18n.t('Youth')(), value: 'Y' },
    { title: i18n.t('All')(), value: 'ALL' },
  ];
  traitDemandClassificationOptions: {
    title: string;
    value: tppsApi.TPPTraitDemandClassification;
  }[] = [
    { title: i18n.t('Select')(), value: '' },
    { title: i18n.t('Essential/"must have"')(), value: '1' },
    { title: i18n.t('Niche opportunity')(), value: '2' },
    { title: i18n.t('Added-value')(), value: '3' },
    { title: i18n.t('Winning trait')(), value: '4' },
  ];

  clientType = ko.observable<ClientTypeData>(null);
  driver = ko.observable<DriverData>(null);
  traitCategory = ko.observable<TraitCategoryData>(null);
  measurementMeta = ko.observable<MeasurementMetaData>(null).extend({
    required: true,
  });
  trialTypes = ko.observableArray<TrialTypeData>();
  preferenceGroup = ko.observable<tppsApi.TPPTraitPreferenceGroup>('ALL');
  indicativeTargetValue = ko.observable('').extend({
    required: { onlyIf: () => this.measurementMeta()?.type !== 'choice' },
    number: true,
  });
  displayRangeMax = ko.observable('').extend({
    // required: { onlyIf: () => this.displayRangeRequired() },
    number: true,
    ...getDisplayRangeMaxValidation(this.indicativeTargetValue),
  });
  displayRangeMin = ko.observable('').extend({
    // required: { onlyIf: () => this.displayRangeRequired() },
    number: true,
    ...getDisplayRangeMinValidation(this.indicativeTargetValue, this.displayRangeMax),
  });
  indicativeTargetChoiceId = ko.observable('').extend({
    required: { onlyIf: () => this.measurementMeta()?.type === 'choice' },
  });
  classification = ko.observable('').extend({
    required: true,
    validation: scoreValidation,
  });
  benchmarkVarieties = ko.observableArray<cropVarietiesApi.CropVarietyData>();
  required = ko.observable<tppsApi.TPPTraitRequired>('>');
  id = ko.observable<string>();

  choiceOptions = ko.pureComputed(() => {
    const mm = this.measurementMeta();
    if (mm) {
      return [{ id: '', name: i18n.t('Select')() }].concat(
        mm.mt_choices.map((data) => {
          return {
            id: data.id,
            name: mm.mt_rating
              ? `${translate(data.name_json)} - ${readDecimal(data.value).toLocaleString()}`
              : translate(data.name_json),
          };
        })
      );
    } else {
      return [];
    }
  });

  traitUnit = ko.pureComputed(() => {
    let mm = this.measurementMeta();
    if (!mm) {
      return '';
    }

    if (mm.normalize) {
      if (mm.type === 'date') {
        return i18n.t(['days_lowercase', 'days'])();
      }
      if (mm.trait_unit_num && mm.trait_unit_den) {
        return mm.trait_unit_num.name + '/' + mm.trait_unit_den.name;
      }
      if (mm.unit && !mm.normalization_mm) {
        return mm.unit.name + '/ha';
      }
      if (mm.unit && mm.normalization_mm && mm.normalization_mm.unit) {
        return mm.unit.name + '/' + mm.unit.name;
      }
    }

    if (mm.unit) {
      return mm.unit.name;
    }

    return '';
  });

  trialTypesNames = ko.pureComputed(() => {
    if (this.trialTypes().length === 0) {
      return i18n.t('Select')();
    } else {
      const names = this.trialTypes().map((tp) => translate(tp.name_json));
      names.sort((a, b) => a.localeCompare(b));
      return names.join(', ');
    }
  });

  benchmarkVarietiesNames = ko.pureComputed(() => {
    if (this.benchmarkVarieties().length === 0) {
      return i18n.t('Select')();
    } else {
      return this.benchmarkVarieties()
        .map((cv) => translate(cv.name_json))
        .join(', ');
    }
  });

  summary = ko.pureComputed(() => {
    let mm = this.measurementMeta();
    if (!mm) {
      return '';
    }

    let res = '';
    let typeIdx = indexOf(ATTRIBUTE_TYPES_WITH_DDM, (opt) => opt.value === mm.type);
    if (typeIdx >= 0) {
      res += i18n.t('Type')() + ': ' + ATTRIBUTE_TYPES_WITH_DDM[typeIdx].value;
    }
    if (mm.description) {
      res += '\n' + mm.description;
    }
    let help = translate(mm.help_text_json);
    if (help) {
      res += '\n' + help;
    }
    let unit = this.traitUnit();
    if (unit) {
      res += '\n' + i18n.t('Unit')() + ': ' + unit;
    }
    let choices: string[] = null;
    if (mm.type === 'entity') {
      choices = mm.limit_to.map((choice) => translate(choice.name_json));
    } else if (mm.type === 'choice') {
      choices = mm.mt_choices.map(
        (choice) =>
          translate(choice.name_json) + (choice.value == null ? '' : ' (' + readDecimal(choice.value) + ')')
      );
    }
    if (choices) {
      res += '\n' + i18n.t('Choices:')() + ' ' + choices.join(', ');
    }
    if (mm.aggregation) {
      let idx = indexOf(MM_AGG_OPTIONS, (opt) => opt.value === mm.aggregation);
      if (idx >= 0) {
        res += '\n' + i18n.t('Plot aggregation')() + ': ' + MM_AGG_OPTIONS[idx].name();
      }
    }

    return res;
  });

  public coverErrorGroup = ko.validation.group([
    this.indicativeTargetValue,
    this.measurementMeta,
    this.classification,
  ]);

  private subs: KnockoutSubscription[] = [];

  constructor(private crop: KnockoutObservable<dimensionsApi.DimensionData>, data?: tppsApi.TPPTraitData) {
    if (data) {
      this.clientType(data.measurement_meta.trait_category?.driver?.client_type);
      this.driver(data.measurement_meta.trait_category?.driver);
      this.traitCategory(data.measurement_meta.trait_category);
      this.measurementMeta(data.measurement_meta);
      this.trialTypes(data.trial_types);
      this.preferenceGroup(data.preference_group);
      this.indicativeTargetValue(readDecimal(data.indicative_target_value));
      this.indicativeTargetChoiceId(data.indicative_target_choice_id);
      this.classification(data.classification?.toString());
      this.benchmarkVarieties(data.benchmark_varieties);
      this.required(data.required);
      this.id(data.id);
    }
    this.subs.push(
      this.clientType.subscribe((ct) => {
        if (ct?.id !== this.driver()?.client_type.id) {
          this.driver(null);
        }
      })
    );
    this.subs.push(
      this.driver.subscribe((dr) => {
        if (dr?.id !== this.traitCategory()?.driver?.id) {
          this.traitCategory(null);
        }
        if (dr && dr.client_type?.id !== this.clientType()?.id) {
          this.clientType(dr.client_type);
        }
      })
    );
    this.subs.push(
      this.traitCategory.subscribe((cat) => {
        if (cat?.id !== this.measurementMeta()?.trait_category?.id) {
          this.measurementMeta(null);
        }
        if (cat && cat.driver?.id !== this.driver()?.id) {
          this.driver(cat.driver);
        }
      })
    );
    this.subs.push(
      this.measurementMeta.subscribe((mm) => {
        if (mm && mm.trait_category?.id !== this.traitCategory()?.id) {
          this.traitCategory(mm.trait_category);
        }

        if (mm && mm.type === 'choice') {
          this.indicativeTargetValue('');
          if (mm.measurement_type.rating) {
            this.displayRangeMin('');
            this.displayRangeMax('');
          }
        }
        this.indicativeTargetChoiceId('');
      })
    );
  }

  dispose() {
    this.subs.forEach((sub) => sub.dispose());
  }

  isLocked() {
    return this.measurementMeta()?.normalize && this.measurementMeta().deleted;
  }

  selectClientType = async () => {
    if (!canEditTPP()) {
      return;
    }
    this.clientType(
      await app.formsStackController.push({
        title: i18n.t('Search')(),
        name: 'name-advanced-search',
        isBig: true,
        params: {
          list: clientTypesApi.list,
          selection: this.clientType(),
          result: new Deferred<ClientTypeData>(),
        },
      })
    );
  };

  selectDriver = async () => {
    if (!canEditTPP()) {
      return;
    }
    let list = (params: ListRequestParams) => {
      let clientTypeIds = this.clientType() ? [this.clientType().id] : [];
      return driversApi.list({ client_type_ids: clientTypeIds, ...params });
    };

    this.driver(
      await app.formsStackController.push({
        title: i18n.t('Search')(),
        name: 'name-advanced-search',
        isBig: true,
        params: {
          list,
          selection: this.driver(),
          result: new Deferred<DriverData>(),
        },
      })
    );
  };

  selectTraitCategory = async () => {
    if (!canEditTPP()) {
      return;
    }
    let list = (params: ListRequestParams) => {
      let clientTypeIds = this.clientType() ? [this.clientType().id] : [];
      let driverIds = this.driver() ? [this.driver().id] : [];
      return traitsCategoryApi.list({
        client_type_ids: clientTypeIds,
        driver_ids: driverIds,
        ...params,
      });
    };

    this.traitCategory(
      await app.formsStackController.push({
        title: i18n.t('Search')(),
        name: 'name-advanced-search',
        isBig: true,
        params: {
          list,
          selection: this.traitCategory(),
          result: new Deferred<TraitCategoryData>(),
        },
      })
    );
  };

  selectMeasurementMeta = () => {
    if (!canEditTPP()) {
      return;
    }
    let list = (params: ListRequestParams) => {
      let cropIds = this.crop() ? [this.crop().id] : [];
      let clientTypeIds = this.clientType() ? [this.clientType().id] : [];
      let driverIds = this.driver() ? [this.driver().id] : [];
      let traitCategoryIds = this.traitCategory() ? [this.traitCategory().id] : [];

      return mmApi.list({
        crop_ids: cropIds,
        client_type_ids: clientTypeIds,
        driver_ids: driverIds,
        trait_category_ids: traitCategoryIds,
        only_for: 'numeric_date',
        management: false,
        ...params,
      });
    };

    app.formsStackController
      .push({
        title: i18n.t('Search')(),
        name: 'name-advanced-search',
        isBig: true,
        params: {
          list,
          selection: this.measurementMeta(),
          result: new Deferred<MeasurementMetaData>(),
        },
      })
      .then((res: MeasurementMetaData) => {
        this.measurementMeta(res);
      });
  };

  selectTrialTypes = () => {
    if (!canEditTPP()) {
      return;
    }
    app.formsStackController
      .push({
        title: i18n.t('Advanced search')(),
        name: 'dimension-advanced-search',
        isBig: true,
        params: {
          dimensionMetaId: TRIAL_TYPE_SLUG,
          initialName: '',
          allowMultipleSelections: true,
          initialMultipleSelections: this.trialTypes(),
          result: new Deferred(),
        },
      })
      .then((res: TrialTypeData[]) => {
        this.trialTypes(res);
      });
  };

  selectBenchmarkVarieties = () => {
    if (!canEditTPP()) {
      return;
    }
    app.formsStackController
      .push({
        title: i18n.t('Advanced search')(),
        name: 'dimension-advanced-search',
        isBig: true,
        params: {
          dimensionMetaId: CROP_VARIETY_SLUG,
          initialCrop: this.crop,
          initialName: '',
          allowMultipleSelections: true,
          initialMultipleSelections: this.benchmarkVarieties(),
          result: new Deferred<cropVarietiesApi.CropVarietyData>(),
        },
      })
      .then((res: cropVarietiesApi.CropVarietyData[]) => {
        this.benchmarkVarieties(res);
      });
  };

  toData(): tppsApi.TPPTraitData {
    return {
      measurement_meta: this.measurementMeta(),
      trial_types: this.trialTypes(),
      preference_group: this.preferenceGroup(),
      indicative_target_value: emptyToNull(this.indicativeTargetValue()),
      display_range_min: emptyToNull(this.displayRangeMin()),
      display_range_max: emptyToNull(this.displayRangeMax()),
      indicative_target_choice_id: emptyToNull(this.indicativeTargetChoiceId()),
      classification: this.classification(),
      benchmark_varieties: this.benchmarkVarieties(),
      required: this.required(),
      id: this.id(),
    };
  }
}

export function getDisplayRangeMinValidation(
  indicativeTargetValue: ko.Observable<string>,
  displayRangeMax: ko.Observable<string>
) {
  return {
    validation: {
      validator: (value: string) => {
        const targetValue = parseFloat(indicativeTargetValue());
        const minValue = parseFloat(value);
        const maxValue = parseFloat(displayRangeMax());

        return (
          (isNaN(targetValue) || isNaN(minValue) || minValue <= targetValue) &&
          (isNaN(minValue) || isNaN(maxValue) || minValue < maxValue)
        );
      },
      message: i18n.t(
        'Must be less than the indicative target value and strictly less than the max range.'
      )(),
    },
  };
}

export function getDisplayRangeMaxValidation(indicativeTargetValue: ko.Observable<string>) {
  return {
    validation: {
      validator: (value: string) => {
        const targetValue = parseFloat(indicativeTargetValue());
        const maxValue = parseFloat(value);

        return isNaN(targetValue) || isNaN(maxValue) || maxValue >= targetValue;
      },
      message: i18n.t('Must be greater than the indicative target value.')(),
    },
  };
}

class TPPTeamMember {
  id: string = null;
  name = ko.observable('').extend({ required: true, serverError: true });
  areaOfExpertise = ko.observable('').extend({ serverError: true });
  organization = ko.observable('').extend({ serverError: true });

  constructor(data?: tppsApi.TPPTeamMemberData) {
    if (data) {
      this.id = data.id;
      this.name(data.name);
      this.areaOfExpertise(data.area_of_expertise);
      this.organization(data.organization);
    }
  }

  toData(order: number): tppsApi.TPPTeamMemberData {
    return {
      id: this.id,
      name: this.name(),
      area_of_expertise: this.areaOfExpertise(),
      organization: this.organization(),
      order,
    };
  }
}
