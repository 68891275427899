import * as ko from 'knockout';

import { FormSelectSearchConfiguration } from './form_select_search';
import { MaybeKO, KOMaybeArray } from '../utils/ko_utils';

export interface FormSelectFilteredConfig<
  FilterData extends { id?: string },
  ValueData extends { id?: string }
> {
  getFilter(value: ValueData): FilterData;
  getFilterSearchConfig(obs: KOMaybeArray<FilterData>): FormSelectSearchConfiguration<FilterData>;
  getValueSearchConfig(
    obs: KnockoutObservable<ValueData>,
    obsFilter: KnockoutObservable<FilterData>
  ): FormSelectSearchConfiguration<ValueData>;
}

export interface FormSelectFilteredParams<
  FilterData extends { id?: string },
  ValueData extends { id?: string }
> {
  value: KnockoutObservable<ValueData>;
  filter?: KnockoutObservable<FilterData>;
  enable?: MaybeKO<boolean>;
}

export class FormSelectFiltered<FilterData extends { id?: string }, ValueData extends { id?: string }> {
  private filter: KnockoutObservable<FilterData>;
  private value: KnockoutObservable<ValueData>;

  filterSearchConfig: FormSelectSearchConfiguration<FilterData>;
  valueSearchConfig: FormSelectSearchConfiguration<ValueData>;
  enable: MaybeKO<boolean>;

  private subscriptions: KnockoutSubscription[] = [];

  constructor(
    params: FormSelectFilteredParams<FilterData, ValueData>,
    private config: FormSelectFilteredConfig<FilterData, ValueData>
  ) {
    this.filter = params.filter || ko.observable(null);
    this.value = params.value;
    this.enable = params.enable;

    this.filterSearchConfig = this.config.getFilterSearchConfig(this.filter);
    this.valueSearchConfig = this.config.getValueSearchConfig(this.value, this.filter);

    this.onValueChanged(this.value());

    this.subscriptions.push(this.filter.subscribe(this.onFilterChanged));
    this.subscriptions.push(this.value.subscribe(this.onValueChanged));
  }

  dispose() {
    for (let sub of this.subscriptions) {
      sub.dispose();
    }
    this.subscriptions = [];
  }

  private onFilterChanged = (crop: FilterData) => {
    if (this.value() && (!crop || this.config.getFilter(this.value()).id !== crop.id)) {
      this.value(null);
    }
  };

  private onValueChanged = (value: ValueData) => {
    let filter = this.filter();
    if (value && (!filter || filter.id !== this.config.getFilter(value).id)) {
      this.filter(this.config.getFilter(value));
    }
  };
}
