import * as ko from 'knockout';
import { PlotCountPerSite, StaffPermissionData, StaffSitePermissionData } from '../../api/trials';
import { getUserSearchConfig } from '../../components/configs/search_configs';
import { UserData } from '../../api/users';
import { SiteData } from '../../api/sites';
import { I18nText } from '../../i18n_text';
import { confirmStaffAssignmentToTrialSite } from '../../utils/plot_count_utils';

export class StaffPermission {
  trialName: I18nText;
  plotCountPerSite: PlotCountPerSite[];
  sites: StaffSitePermission[];

  notifyUsers = ko.observableArray<NotifyUser>();

  constructor(data: StaffPermissionData) {
    this.trialName = data.trial_name;
    this.plotCountPerSite = data.plot_count_per_site;
    this.sites = data.sites.map(
      (site_data) => new StaffSitePermission(site_data, this.updateNotifyUsers, this.confirmStaffAssignment)
    );
    this.updateNotifyUsers();
  }

  dispose() {
    this.sites.forEach((site) => site.dispose());
  }

  toData(): StaffPermissionData {
    return {
      sites: this.sites.map((site) => site.toData()),
      notify_users: this.notifyUsers()
        .filter((n) => n.send())
        .map((n) => n.user),
    };
  }

  /**
   * Allows the user to confirm the assignment of a staff member to a trial site,
   * potentially warning the user if the plot count sum across all its sites is
   * above a limit.
   *
   * @param {ko.utils.ArrayChange<UserData>[]} changes - array of changes in users.
   * @param {StaffSitePermission} site - site to which users are being assigned.
   */
  private confirmStaffAssignment = (
    changes: ko.utils.ArrayChange<UserData>[],
    site: StaffSitePermission
  ) => {
    const onCancelUserAssignment = () => site.users.pop();
    changes.forEach((change) => {
      if (change.status === 'added') {
        confirmStaffAssignmentToTrialSite(
          this.sites,
          this.plotCountPerSite,
          change.value,
          onCancelUserAssignment
        );
      }
    });
  };

  private updateNotifyUsers = () => {
    let toNotify = new Set<string>();
    for (let notify of this.notifyUsers()) {
      if (notify.send()) {
        toNotify.add(notify.user.id);
      }
    }

    let seen = new Set<string>();
    let newValue: NotifyUser[] = [];
    for (let site of this.sites) {
      for (let user of site.users()) {
        if (!seen.has(user.id)) {
          seen.add(user.id);
          newValue.push(new NotifyUser(user, toNotify.has(user.id)));
        }
      }
    }

    this.notifyUsers(newValue);
  };
}

class NotifyUser {
  send = ko.observable(false);

  constructor(public user: UserData, send: boolean) {
    this.send(send);
  }
}

export class StaffSitePermission {
  site: SiteData;
  users = ko.observableArray<UserData>();
  userSearch = getUserSearchConfig(this.users);

  private subscriptions: KnockoutSubscription[];

  constructor(
    data: StaffSitePermissionData,
    onUpdate: () => void,
    confirmChangeUsers: (changes: ko.utils.ArrayChange<UserData>[], site: StaffSitePermission) => void
  ) {
    this.site = data.site;
    this.users(data.users);

    this.subscriptions = [
      this.users.subscribe(onUpdate),
      this.users.subscribe(
        (changes: ko.utils.ArrayChange<UserData>[]) => confirmChangeUsers(changes, this),
        null,
        'arrayChange'
      ),
    ];
  }

  dispose() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
    this.subscriptions = [];
  }

  toData(): StaffSitePermissionData {
    return {
      site: this.site,
      users: this.users(),
    };
  }
}
