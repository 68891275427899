import * as ko from 'knockout';
import { MaybeKO } from '../utils/ko_utils';

ko.bindingHandlers['rawDOM'] = {
  init: (element: Element, valueAccessor: () => any) => {
    return { controlsDescendantBindings: true };
  },
  update: (element: Element, valueAccessor: () => MaybeKO<HTMLElement>) => {
    let content = ko.unwrap(valueAccessor());
    ko.virtualElements.setDomNodeChildren(element, [content]);
  },
};

ko.virtualElements.allowedBindings['rawDOM'] = true;
