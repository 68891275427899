import { BoolDict } from '../utils';

function isReallyNaN(a: any) {
  // NaN is the only value which is both equal and different to itself
  // note that we can't use isNaN, since isNaN({}) === true
  return !(a === a) && a !== a;
}

export function deepEquals(a: any, b: any) {
  if (a === b || (isReallyNaN(a) && isReallyNaN(b))) {
    return true;
  }

  if (a === null || a === undefined || b === null || b === undefined) {
    return false;
  }

  if (
    Object.prototype.toString.call(a) === '[object Array]' &&
    Object.prototype.toString.call(b) === '[object Array]'
  ) {
    if (a.length === b.length) {
      for (let i = 0; i < a.length; i++) {
        if (!deepEquals(a[i], b[i])) {
          return false;
        }
      }

      return true;
    } else {
      return false;
    }
  }

  if (
    Object.prototype.toString.call(a) === '[object Object]' &&
    Object.prototype.toString.call(b) === '[object Object]'
  ) {
    let aKeys: BoolDict = {};

    for (let k in a) {
      aKeys[k] = true;

      if (!deepEquals(a[k], b[k])) {
        return false;
      }
    }

    for (let k in b) {
      if (!aKeys[k]) {
        return false;
      }
    }

    return true;
  }

  return false;
}
