import { MeasurementMetaData } from './datasets';
import {
  listParamsToQueryString,
  makeDefaultApi,
  request,
  requestRaw,
  requestWithGetParams,
  requestWithValidation,
} from './request';

export interface MeasurementMetaLibraryData extends MeasurementMetaData {
  crop_names: string;
  ontology_trait?: { trait_id: string; link: string }; // list-only
}

export type LibraryTypeFilter = 'date' | 'area' | 'numeric_date';

interface ListParams {
  crop_ids: string[];
  client_type_ids?: string[];
  driver_ids?: string[];
  trait_category_ids?: string[];
  mm_tag_ids?: string[];
  only_for: LibraryTypeFilter;
  management: boolean;
}
export const { list, retrieve, save, remove } = makeDefaultApi<
  MeasurementMetaLibraryData,
  ListParams,
  MeasurementMetaData
>('measurement_metas');

export function exportLibrary(params: ListParams & { name_prefix: string }): Promise<Blob> {
  return requestRaw('GET', '/api/measurement_metas/export/?' + listParamsToQueryString(params as {}));
}

export function listDependencies(params: {
  for_ids: string[];
  crop_id: string;
}): Promise<{ valid: MeasurementMetaData[]; invalid: MeasurementMetaData[] }> {
  return requestWithGetParams('/api/measurement_metas/list_dependencies/', params);
}

export type SelectedTraitData = Pick<
  MeasurementMetaData,
  | 'id'
  | 'template_id'
  | 'name_json'
  | 'name_slug'
  | 'trait_category'
  | 'normalize'
  | 'type'
  | 'trait_unit_num'
  | 'trait_unit_den'
  | 'mt_rating'
  | 'tags'
  | 'trial_id'
  | 'data_collection_details'
>;
export function listTrialLibrary(params: { mm_ids: string[] }): Promise<SelectedTraitData[]> {
  return request('POST', '/api/measurement_metas/list_trial_library/', params);
}

export interface FormulaSuggestion {
  name: string;
  code: string;
  unit: string;
}

export function librarySuggestions(options?: { trialId: string }): Promise<FormulaSuggestion[]> {
  return request('GET', `/api/measurement_metas/library_suggestions/?trial_id=${options?.trialId ?? ''}`);
}

export interface FormulaValidationParams {
  formula: string;
  collect_for_replication: boolean;
  collect_for_site: boolean;
  collect_for_test_subjects: boolean;
  dimension_meta_ids: string[];
  crops?: any[];
  check_dependencies?: boolean;
}

export function validateFormula(data: FormulaValidationParams) {
  return requestWithValidation('POST', '/api/measurement_metas/validate_formula/', data);
}

export interface TrialTraitDetailData {
  name: string;
  code: string;
  trait_category_name: string;
  type: string;
  description: string;
  choice_descriptions: string[];
  unit_name: string;
  number_min_value: number;
  number_max_value: number;
  date_min_value: string;
  date_max_value: string;
  help_text: string;
  pictures: { url: string; description: string }[];
  scheduled_visit_names: string[];
  collect_for_site: boolean;
  collect_for_replication: boolean;
  collect_for_test_subjects: boolean;
  mdms: TrialTraitDetailMDM[];
}

export interface TrialTraitDetailMDM {
  dm_name: string;
  dim_names: string[];
}

export function trialTraitDetail(id: string): Promise<TrialTraitDetailData> {
  return request('GET', `/api/measurement_metas/${id}/trial_trait_detail/`);
}
