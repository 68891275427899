import * as ko from 'knockout';

import i18n from '../i18n';
import * as tppsApi from '../api/tpps';
import * as cvApi from '../api/crop_varieties';
import * as overviewApi from '../api/overview';
import { registrationHistoryApi } from '../api/registrations';
import { emptyToNull, range, readDecimal } from '../utils';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { ListLoaderDelegate, ListLoader } from '../components/list_loader';
import { RegistrationHistoryData } from '../api/registrations';
import { RegistrationHistory } from '../models/registration_history';
import { RemoveResult, ListRequestParams } from '../api/request';
import { registrationHistoryEdit } from './registration_history_edit';
import { registrationEdit } from './registration_edit';
import { CropVarietyData } from '../api/crop_varieties';
import { getDisplayRangeMaxValidation, getDisplayRangeMinValidation, TPP } from '../models/tpp';
import { StageChangeChartData } from '../api/overview';
import { SpiderChartConfig } from '../ko_bindings/spider_chart';
import { translate } from '../i18n_text';
import { confirmNavigation } from '../utils/routes';
import { confirmDialog } from '../components/confirm_dialog';
import { NamedRecord } from '../api/tpps';
import { TPPTechnicalScorecard } from '../models/tppScorecard';
import { canEditTPP } from '../permissions';


const template = require('raw-loader!../../templates/tpp_evaluation.html').default;

const cvDetailTemplate =
  require('raw-loader!../../templates/components/tpp_evaluation_cv_detail.html').default;

const technicalScorecardTableTemplate =
  require('raw-loader!../../templates/components/tpp_technical_scorecard_table.html').default;

class RegistrationHistories implements ListLoaderDelegate<RegistrationHistoryData, RegistrationHistory> {
  noItemsText = i18n.t('No registrations')();

  private loader: ListLoader<RegistrationHistoryData, RegistrationHistory>;
  private subs: KnockoutSubscription[] = [];
  cropVariety = ko.observable<CropVarietyData>();

  constructor(private cvId: KnockoutObservable<string>, private tpp: KnockoutObservable<TPP>) {
    this.subs.push(cvId.subscribe(this.reload));
    this.subs.push(tpp.subscribe(this.reload));
  }

  dispose() {
    this.subs.forEach((sub) => sub.dispose());
  }

  private reload = () => {
    if (this.loader) {
      
      this.loader.refresh();
    }
  };

  onReady(loader: ListLoader<RegistrationHistoryData, RegistrationHistory>) {
    this.loader = loader;
  }

  fetch(params: ListRequestParams): Promise<RegistrationHistoryData[]> {
    let cvId = this.cvId();
    let tpp = this.tpp();

    if (!cvId || !tpp) {
      return Promise.resolve([]);
    }

    let fetchCV = cvApi.retrieve(cvId);
    let fetchHistory = registrationHistoryApi.list({
      country_ids: [tpp.country().id],
      region_ids: [],
      crop_ids: [],
      crop_variety_ids: [cvId],
      phase_ids: [],
      regional: 'all',
      min_date: undefined,
      max_date: undefined,
      portofolio_item_ids: [],
      ...params,
    });

    return Promise.all([fetchCV, fetchHistory]).then(([cv, history]) => {
      this.cropVariety(cv);
      return history;
    });
  }

  instantiate(data: RegistrationHistoryData): RegistrationHistory {
    return new RegistrationHistory(data.registration, data);
  }

  remove(id: string): Promise<RemoveResult> {
    return Promise.reject(null);
  }

  canRemove(entity: RegistrationHistory): boolean {
    return false;
  }

  openNextPhase = (entity: RegistrationHistory) => {
    let result = new Deferred();
    app.formsStackController.push({
      title: i18n.t('Next Phase')(),
      name: registrationHistoryEdit.name,
      params: {
        result,
        registrationId: entity.registration.id,
      },
    });

    result.promise.then(this.reload);
  };

  addRegistration = () => {
    let tpp = this.tpp();
    let cv = this.cropVariety();
    if (!tpp || !cv) {
      return;
    }

    let result = new Deferred();
    app.formsStackController.push({
      title: i18n.t('Create Registration')(),
      name: registrationEdit.name,
      params: {
        result,
        initialCropVariety: cv,
        initialCountry: tpp.country(),
      },
    });

    result.promise.then(this.reload);
  };
}

export interface AnovaTrait {
  trait: tppsApi.TechnicalScorecardTraitData;
  // sites
  maxNotShared: number;
  shared: NamedRecord[];
  byTrial: Map<string, NamedRecord[]>;
}

export class TPPEvaluation {
  tppId: string;

  title = APP_CONFIG.USE_TPP_MEETING_NAMING
    ? i18n.t('Target product profiles - Advancement meeting')()
    : i18n.t('Target product profiles - Evaluation')();

  tpp = ko.observable<TPP>();
  stages = ko.observableArray<tppsApi.TPPStage>();
  stageRows = ko.observableArray<number>();
  stageChangeChart = ko.observable<StageChangeChartData>();

  selectedStage = ko.observable<EditMultiStage>(null);
  selectedCVId = ko.observable('');
  technicalScorecard = ko.observable<tppsApi.TechnicalScorecardData>();
  trialsWithSelectedCV = ko.computed(() =>
    this.technicalScorecard()?.trials?.filter((trial) => trial.cv_ids.includes(this.selectedCVId()))
  );
  tppTraits = ko.observable<Record<string, string>[]>([]);
  selectedTraitsForStats = ko.observableArray<string>([]);
  loadingTechnicalScorecard= ko.observable(false);
  isOpen = ko.observable(false);
  canEdit = ko.observable(canEditTPP())

  loadStatistics = async (cropVarieties: string[]) => {
    this.loadingTechnicalScorecard(true);
    if(this.selectedTraitsForStats().length > 0) {
      const previousTraitsForStats = JSON.parse(localStorage.getItem('traitsForStatsTpp'));
      previousTraitsForStats[this.tppId] = this.selectedTraitsForStats()
      localStorage.setItem('traitsForStatsTpp', JSON.stringify(previousTraitsForStats))
    }
    try {
      const scorecard = await tppsApi.technicalScorecard(this.tppId, {cvOfInterest: cropVarieties, traitIds: this.selectedTraitsForStats()})
      this.technicalScorecard(scorecard);
    } finally {
      this.loadingTechnicalScorecard(false);
    }
  };

  toggle = (data: any) => {
    if (!data) {
      return;
    }
    if (this.selectedTraitsForStats().includes(data.trait_id)) {
      this.selectedTraitsForStats(this.selectedTraitsForStats().filter((id) => id !== data.trait_id));
    } else {
      this.selectedTraitsForStats().push(data.trait_id);
      this.selectedTraitsForStats(this.selectedTraitsForStats());
    }
  };
  close = () => {
    this.isOpen(false);
  };
  open = () => {
    this.isOpen(true);
    setTimeout(() => {
      $('.search-input > input').focus();
    }, 0);
  };
  icon = (data: any) => {
    if (this.selectedTraitsForStats().includes(data.trait_id)) {
      return 'check_box';
    } else {
      return 'check_box_outline_blank';
    }
  };

  selectedSiteId = ko.observable('');
  showTraitsChartPopup = ko.observable(false);
  showEditTraits = ko.observable(false);

  selectedCV = ko.pureComputed(() => {
    if (!this.selectedCVId()) {
      return null;
    }

    for (let stage of this.stages()) {
      for (let cv of stage.crop_varieties) {
        if (this.selectedCVId() === cv.id) {
          return {
            name: cv.name,
            stage: stage.name,
          };
        }
      }
    }

    return null;
  });

  registrationHistories = new RegistrationHistories(this.selectedCVId, this.tpp);
  editTraits: EditRadarTraits;

  constructor(params: { id: string, cvId: string } ) {
    this.tppId = params.id;
    this.selectedCVId(params.cvId);
    this.editTraits = new EditRadarTraits(params.id, this.onDoneEditRadarTraits);
    tppsApi.fetchTechnicalScorecardTraits(this.tppId).then((traits) => {
      this.tppTraits(traits);
    });
    if(params.cvId) {
      const previousTraitsForStats = JSON.parse(localStorage.getItem('traitsForStatsTpp'));
      if (previousTraitsForStats) {
        if(previousTraitsForStats[this.tppId]?.length) {
          this.selectedTraitsForStats(previousTraitsForStats[this.tppId])
          this.loadStatistics([this.selectedCVId()])
        } else {
          this.loadStatsWithDefaultTraits([this.selectedCVId()])
        }
      } else {
        localStorage.setItem('traitsForStatsTpp', JSON.stringify({}))
        this.loadStatsWithDefaultTraits([this.selectedCVId()])
      }
    }

    this.reload()
    confirmNavigation(
      i18n.t(
        'There are unsaved changes. If you leave the page you will lose them. Are you sure you want to continue?'
      )(),
      this.hasUnsavedChanges
    );
  }

  async loadStatsWithDefaultTraits(cropVarieties: string[]) {
    const traits = await tppsApi.fetchTechnicalScorecardDefaultTraits(this.tppId)
    this.selectedTraitsForStats(traits.map((t: any) => t.trait_id))
    await this.loadStatistics(cropVarieties)
  }

  private hasUnsavedChanges = () => {
    const edit = this.selectedStage();
    return edit !== null && edit.hasUnsavedChanges();
  };

  reload() {
    this.tpp(null);

    let tpp = tppsApi.retrieve(this.tppId);
    let stages = tppsApi.stages(this.tppId);
    let chartsData = overviewApi.varietiesCharts({
      from_planting_date: null,
      to_planting_date: null,
      crop: null,
      commercial: 'both',
      customer: null,
      public_cv: 'any',
      crop_variety_name: '',
      cv_stage: null,
      cv_country: null,
      owner: null,
      trial_year: null,
      cv_stage_year: null,
      cv_control: 'both',
      external: 'both',
      tpp: this.tppId,
      project: null,
    });

    Promise.all([tpp, stages, chartsData]).then(([tpp, stages, chartsData]) => {
      let maxRows = Math.max.apply(
        this,
        stages.map((stage) => stage.crop_varieties.length)
      );
      this.stageRows(range(maxRows));
      this.tpp(new TPP(null, tpp));
      this.stages(stages);
      this.stageChangeChart(chartsData.stage_changes);
    });
  }

  traitsChartConfig = ko.pureComputed<SpiderChartConfig>(() =>
    getTraitsChartConfig(this.technicalScorecard(), this.selectedCVId(), null, null)
  );
  popupTraitsChartConfig = ko.pureComputed<SpiderChartConfig>(() =>
    getTraitsChartConfig(this.technicalScorecard(), this.selectedCVId(), null, this.selectedSiteId() || null)
  );
  trialTraitCharts = ko.pureComputed<{ name: string; chart: SpiderChartConfig; sites: string[] }[]>(() => {
    const scorecard = this.technicalScorecard();
    if (!scorecard?.trials) {
      return [];
    }

    const cvId = this.selectedCVId();
    const trials = scorecard.trials.filter((trial) => trial.cv_ids.includes(this.selectedCVId()));

    return trials.map((trial) => ({
      name: trial.name,
      chart: {
        aspectRatio: 1,
        ...getTraitsChartConfig(scorecard, this.selectedCVId(), trial.id, this.selectedSiteId() || null),
      },
      sites: trial.sites.filter((s) => !!s.id && (!cvId || !!s.values[cvId])).map((s) => s.name),
    }));
  });

  openTraitsChartPopup = () => {
    this.showTraitsChartPopup(true);
  };
  closeTraitsChartPopup = () => this.showTraitsChartPopup(false);

  openEditTraits = () => {
    this.showEditTraits(true);
    this.editTraits.load();
  };
  closeEditTraits = () => this.showEditTraits(false);

  private onDoneEditRadarTraits = async () => {
    this.loadStatistics(this.selectedStage()?.stage.crop_varieties.map((cv) => cv.id))
    this.selectedStage()?.reload(this.technicalScorecard());
    this.closeEditTraits();
  };

  onSelected(cv: NamedRecord) {
    if (!cv) {
      return;
    }

    this.selectedCVId(cv.id);
  }

  closeSelectedCV = () => {
    this.selectedCVId(null);
  };

  openDataset = (trialId: string, siteId: string, valueIndex: number) => {
    app.formsStackController.push({
      title: i18n.t('Observations')(),
      name: 'tpp-facts-popup',
      isBig: true,
      params: {
        trialId,
        siteId,
        measurementMetaId: this.technicalScorecard().traits[valueIndex].measurement_meta_id,
        cvId: this.selectedCVId(),
        result: new Deferred<{}>(),
      },
    });
  };

  promote = () => {
    let title = i18n.t('Promote')();
    app.formsStackController
      .push({
        title,
        name: 'add-cv-stage',
        params: {
          title,
          cvId: this.selectedCVId(),
          country: this.tpp().country(),
          result: new Deferred<{}>(),
        },
      })
      .then(() => {
        this.reload();
      });
  };

  onOpenStage = async (stage: tppsApi.TPPStage) => {
    if (!stage.id) {
      return;
    }
    const previousTraitsForStats = JSON.parse(localStorage.getItem('traitsForStatsTpp'));
    const cropVarietiesIds = stage.crop_varieties.map((cv) => cv.id)
    if (previousTraitsForStats) {
      if(previousTraitsForStats[this.tppId]) {
        this.selectedTraitsForStats(previousTraitsForStats[this.tppId])
        await this.loadStatistics(cropVarietiesIds)
      } else {
        await this.loadStatsWithDefaultTraits(cropVarietiesIds)
      }
    } else {
      localStorage.setItem('traitsForStatsTpp', JSON.stringify({}))
      await this.loadStatsWithDefaultTraits(cropVarietiesIds)
    }
    this.selectedStage(
      new EditMultiStage(this, this.technicalScorecard(), stage, () => {
        this.selectedStage(null);
        this.reload();
      })
    );

  };

  reloadStage = async (stage: tppsApi.TPPStage) => {
    if (!stage.id) {
      return;
    }
    await this.loadStatistics(stage.crop_varieties.map((cv) => cv.id))
    this.selectedStage(
      new EditMultiStage(this, this.technicalScorecard(), stage, () => {
        this.selectedStage(null);
        this.reload();
      })
    );

  };
  siteOptions = ko.pureComputed(() => {
    const scorecard = this.technicalScorecard();
    const seenSites = new Set<string>();
    const sites = [{ id: '', name: i18n.t('All')() }];

    if (!scorecard?.trials) {
      return sites;
    }

    const cvId = this.selectedCVId();
    for (const trial of scorecard.trials) {
      for (const site of trial.sites) {
        if (!site.id || seenSites.has(site.id) || (cvId && !site.values[cvId])) {
          continue;
        }

        sites.push(site);
        seenSites.add(site.id);
      }
    }

    return sites;
  });
}

export function getTraitsChartConfig(
  scorecard: tppsApi.TechnicalScorecardData,
  cvId: string,
  trialId: string | null,
  siteId: string | null
): SpiderChartConfig {
  if (!scorecard || !scorecard.trials) {
    return null;
  }
  let trials = scorecard.trials.filter((trial) => trial.cv_ids.includes(cvId));
  if (trialId != null) {
    trials = trials.filter((t) => t.id === trialId);
  }

  const eqPerc = (traitIdx: number, target: number, cvIds: string[]): number | null => {
    let nEq = 0;
    let n = 0;

    for (const trial of trials) {
      const site = trial.sites.filter((site) => site.id === siteId)[0];
      if (!site) {
        continue;
      }

      for (const cvId of cvIds) {
        for (const value of site.values[cvId]?.[traitIdx]?.raw?.map(r => r.value) ?? []) {
          if (value === target) {
            nEq++;
          }
          n++;
        }
      }
    }

    if (n === 0) {
      return null;
    }

    return (nEq / n) * 100;
  };

  const trialsAvg = (traitIdx: number, cvIds: string[]): number | null => {
    let sum = 0;
    let n = 0;

    for (const trial of trials) {
      const site = trial.sites.filter((site) => site.id === siteId)[0];
      if (!site) {
        continue;
      }

      for (const cvId of cvIds) {
        const agg = site.values[cvId]?.[traitIdx]?.aggregated;
        if (!agg) {
          continue;
        }

        sum += parseFloat(agg.toString());
        n++;
      }
    }

    if (n === 0) {
      return null;
    }

    return sum / n;
  };

  return {
    testNames: [i18n.t('Test')()],
    datasets: scorecard.traits
      .map((trait, traitIdx) => {
        const cvIds = [cvId];
        const benchmarkIds = scorecard.traits[traitIdx].internal_benchmarks.map((b) => b.id);

        const value = trait.is_qualitative
          ? eqPerc(traitIdx, trait.indicative_target_choice_value, cvIds)
          : trialsAvg(traitIdx, cvIds);
        const controlValue = trait.is_qualitative
          ? eqPerc(traitIdx, trait.indicative_target_choice_value, benchmarkIds)
          : trialsAvg(traitIdx, benchmarkIds);
        const targetValue = trait.is_qualitative
          ? 100
          : trait.indicative_target_choice_value ?? trait.indicative_target_value;

        const scaleMin = 0.1;
        const scaleMax = 1;

        let min: number, max: number;
        if (!trait.is_qualitative && trait.rating_min != null) {
          min = trait.rating_min;
          max = trait.rating_max;
        } else {
          min = trait.display_range_min;
          max = trait.display_range_max;
        }

        const scale = (x: number) => {
          if (x == null) {
            return null;
          }
          const res = Math.max(
            scaleMin,
            Math.min(
              scaleMax,
              scaleMin + ((parseFloat(x.toString()) - min) / (max - min)) * (scaleMax - scaleMin)
            )
          );
          if (trait.invert) {
            return scaleMax - (res - scaleMin);
          } else {
            return res;
          }
        };

        return {
          label: trait.name,
          values: [scale(value)],
          unscaledValues: [value],
          controlValue: scale(controlValue),
          unscaledControlValue: controlValue,
          targetValue: scale(targetValue),
          unscaledTargetValue: targetValue,
        };
      })
      .filter((x) => x !== null),
  };
}

class EditRadarTraits {
  loading = ko.observable(true);
  saving = ko.observable(false);
  errorSaving = ko.observable(false);
  traits = ko.observableArray<RadarTrait>();

  constructor(private tppId: string, private onDone: () => Promise<void>) {}

  async load() {
    this.loading(true);

    try {
      this.traits((await tppsApi.getTPPRadarTraits(this.tppId)).traits.map((data) => new RadarTrait(data)));
    } finally {
      this.loading(false);
    }
  }

  async save() {
    this.errorSaving(false);

    const errors = ko.validation.group(this.traits, { deep: true });
    if (errors().length > 0) {
      errors.showAllMessages();
      return;
    }

    this.saving(true);
    try {
      const data: tppsApi.TPPRadarData = {
        traits: this.traits().map((trait) => trait.toData()),
      };
      const res = await tppsApi.updateTPPRadarTraits(this.tppId, data);
      if (!res.isValid) {
        this.errorSaving(true);
      } else {
        await this.onDone();
      }
    } finally {
      this.saving(false);
    }
  }
}

class RadarTrait {
  private id: string;
  private ratingMin: number;
  private ratingMax: number;

  traitName: string;
  traitType: 'numeric' | 'qualitative' | 'rating';
  formattedTraitType: string;
  choiceOptions: NamedRecord[];

  indicativeTargetValue: ko.Observable<string>;
  displayRangeMin: ko.Observable<string>;
  displayRangeMax: ko.Observable<string>;
  indicativeTargetChoiceId: ko.Observable<string>;

  optimalRange = ko.pureComputed(() => {
    const displayMin = parseFloat(this.displayRangeMin());
    const displayMax = parseFloat(this.displayRangeMax());

    let min: number, max: number;
    if (this.traitType !== 'qualitative' && this.traitType === 'rating') {
      if (isNaN(this.ratingMin) || isNaN(this.ratingMax)) {
        return '';
      }

      min = this.ratingMin;
      max = this.ratingMax;
    } else {
      if (isNaN(displayMin) || isNaN(displayMax)) {
        return '';
      }

      min = displayMin;
      max = displayMax;
    }

    return `${min.toLocaleString()} - ${max.toLocaleString()}`;
  });

  constructor(data: tppsApi.TPPTraitRadarData) {
    this.id = data.id;
    this.ratingMin = data.rating_min;
    this.ratingMax = data.rating_max;
    this.traitName = data.trait_name;
    this.traitType = data.trait_type;
    this.choiceOptions = data.trait_choices.map((data) => {
      return {
        id: data.id,
        name:
          this.traitType === 'rating'
            ? `${translate(data.name_json)} - ${readDecimal(data.value).toLocaleString()}`
            : translate(data.name_json),
      };
    });

    if (data.trait_type === 'qualitative') {
      this.formattedTraitType = i18n.t('Qualitative')();
    } else if (data.trait_type === 'rating') {
      this.formattedTraitType = i18n.t('Rating')();
    } else {
      this.formattedTraitType = i18n.t('Numeric')();
    }

    this.indicativeTargetValue = ko.observable(readDecimal(data.indicative_target_value)).extend({
      required: this.traitType === 'numeric',
      number: true,
    });
    this.displayRangeMax = ko.observable(readDecimal(data.display_range_max)).extend({
      required: this.traitType !== 'rating',
      number: true,
      ...getDisplayRangeMaxValidation(this.indicativeTargetValue),
    });
    this.displayRangeMin = ko.observable(readDecimal(data.display_range_min)).extend({
      required: this.traitType !== 'rating',
      number: true,
      ...getDisplayRangeMinValidation(this.indicativeTargetValue, this.displayRangeMax),
    });
    this.indicativeTargetChoiceId = ko.observable(data.indicative_target_choice_id).extend({
      required: this.traitType !== 'numeric',
    });
  }

  toData(): tppsApi.TPPTraitRadarData {
    return {
      id: this.id,
      indicative_target_value: emptyToNull(this.indicativeTargetValue()),
      display_range_min: emptyToNull(this.displayRangeMin()),
      display_range_max: emptyToNull(this.displayRangeMax()),
      indicative_target_choice_id: this.indicativeTargetChoiceId(),
    };
  }
}

class EditMultiStageItem {
  compare = ko.observable(false);
  promotedTo = ko.observable<cvApi.CropVarietyStageItemData & { stage_name: string }>(null);

  title = ko.pureComputed(() => `${this.cv.name} (${this.promotedTo()?.stage_name ?? this.stage.name})`);

  evaluation: TPPEvaluation;
  cv: NamedRecord;
  constructor(
    evaluation: TPPEvaluation,
    private stage: tppsApi.TPPStage,
    cv: NamedRecord,
    public chart: ko.PureComputed<SpiderChartConfig>
  ) {
    this.evaluation = evaluation;
    this.cv = cv;
  }

  promote = () => {
    let title = i18n.t('Promote')();
    app.formsStackController
      .push({
        title,
        name: 'add-cv-stage',
        params: {
          title,
          cvId: this.cv.id,
          country: this.evaluation.tpp().country(),
          dontSave: true,
          result: new Deferred<cvApi.CropVarietyStageData>(),
        },
      })
      .then((data: cvApi.CropVarietyStageData) => {
        this.promotedTo({
          crop_variety_id: this.cv.id,
          stage_id: data.stage.id,
          stage_name: translate(data.stage.name_json),
          start_at: data.start_at,
          external: data.external,
          comment: data.comment,
        });
      });
  };

  undoPromote = () => {
    this.promotedTo(null);
  };
}

class EditMultiStage {
  saving = ko.observable(false);
  canEdit = ko.observable(canEditTPP())

  canCompare = ko.pureComputed(() => {
    const n = this.singleColumn().reduce((acc, v) => (v.compare() ? acc + 1 : acc), 0);
    return n >= 2 && n <= 3;
  });
  canSave = ko.pureComputed(() => !this.saving() && this.hasUnsavedChanges());

  singleColumn = ko.observableArray<EditMultiStageItem>();
  private scorecard = ko.observable<tppsApi.TechnicalScorecardData>();

  constructor(
    private evaluation: TPPEvaluation,
    scorecard: tppsApi.TechnicalScorecardData,
    public stage: tppsApi.TPPStage,
    private done: () => void
  ) {
    this.scorecard(scorecard);
    this.singleColumn(
      stage.crop_varieties.map(
        (cv) =>
          new EditMultiStageItem(
            evaluation,
            stage,
            cv,
            ko.pureComputed(() => getTraitsChartConfig(this.scorecard(), cv.id, null, null))
          )
      )
    );
  }

  hasUnsavedChanges() {
    for (let sc of this.singleColumn()) {
      if (sc.promotedTo()) {
        return true;
      }
    }

    return false;
  }

  reload(scorecard: tppsApi.TechnicalScorecardData) {
    this.scorecard(scorecard);
  }

  save = async () => {
    if (!this.canSave()) {
      return;
    }

    this.saving(true);
    try {
      await cvApi.updateStages({
        country_id: this.evaluation.tpp().country().id,
        items: this.singleColumn()
          .map((sc) => sc.promotedTo())
          .filter((x) => !!x),
      });
      this.done();
    } finally {
      this.saving(false);
    }
  };

  close = async () => {
    if (this.saving()) {
      return;
    }

    if (this.hasUnsavedChanges()) {
      await confirmDialog(
        i18n.t('Confirm')(),
        i18n.t(
          'There are unsaved changes. If you leave the page you will lose them. Are you sure you want to continue?'
        )()
      );
    }

    this.done();
  };
}

class CVDetailPageVM {
  editTraits: any;
  selectedCVId = ko.observable('');
  data = ko.observable<TPPEvaluation>(); ;
  onDoneEditRadarTraits: any;
  popup: boolean;
  currentCV: any;

  constructor(params: { id: string, cvId: string }) {
    this.editTraits = new EditRadarTraits(params.id, this.onDoneEditRadarTraits);
    this.selectedCVId(params.cvId);
    this.data(new TPPEvaluation(params))
    this.popup = false;
    this.currentCV = params.cvId;
  };
}


class TechnicalScorecardTableVM {
  data: TPPTechnicalScorecard;
  currentCV: any;

  constructor(params: { data: TPPEvaluation, currentCV: any}) {
    if (typeof(params.currentCV) === 'function' ) {
      params.currentCV = params.currentCV();
    }
    this.data = new TPPTechnicalScorecard(params);
  }
}

export let tppEvaluation = {
  name: 'tpp-evaluation',
  viewModel: TPPEvaluation,
  template: template,
};

export let tppEvaluationCVDetail = {
  name: 'tpp-evaluation-detail',
  viewModel: CVDetailPageVM,
  template: cvDetailTemplate,
};

export let tppTechnicalScorecardTable = {
  name: 'technical-scorecard-table',
  viewModel: TechnicalScorecardTableVM,
  template: technicalScorecardTableTemplate,
};

ko.components.register(tppEvaluation.name, tppEvaluation);
ko.components.register(tppEvaluationCVDetail.name, tppEvaluationCVDetail);
ko.components.register(tppTechnicalScorecardTable.name, tppTechnicalScorecardTable);