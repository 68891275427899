import * as ko from 'knockout';

export type MaybeKO<T> = KnockoutObservable<T> | T;
export type KOMaybeArray<T> = KnockoutObservable<T> | KnockoutObservableArray<T>;

export function isKOArray<T>(observable: KOMaybeArray<T>): observable is KnockoutObservableArray<T> {
  return 'push' in observable;
}

export function asObservable<T>(value: MaybeKO<T>): KnockoutObservable<T> {
  if (ko.isSubscribable(value)) {
    return <KnockoutObservable<T>>value;
  } else {
    return ko.observable(<T>value);
  }
}

interface ComponentConstructor {
  new(params: {}, componentInfo: KnockoutComponentTypes.ComponentInfo): {};
}

export function createWithComponent(constructor: ComponentConstructor) {
  return {
    createViewModel: (params: {}, componentInfo: KnockoutComponentTypes.ComponentInfo): {} => {
      return new constructor(params, componentInfo);
    },
  };
}

export function makeHasError(...items: KnockoutObservable<{}>[]) {
  let group = ko.validation.group(items, { deep: true });
  return ko.pureComputed(() => {
    if ((<any>group).isAnyMessageShown()) {
      return true;
    }

    for (let item of items) {
      if (item.serverError && item.serverError()) {
        return true;
      }
    }

    return false;
  });
}

export function unwrap<T>(value: null | undefined | T | KnockoutObservable<T>): T | null | undefined {
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return undefined;
  }

  return ko.unwrap(value);
}

export function moveUp<T>(items: ko.ObservableArray<T>, item: T) {
  const idx = items.indexOf(item);
  if (idx > 0) {
    items.splice(idx, 1);
    items.splice(idx - 1, 0, item);
  }
}

export function moveDown<T>(items: ko.ObservableArray<T>, item: T) {
  const idx = items.indexOf(item);
  if (idx >= 0 && idx < items().length - 1) {
    items.splice(idx, 1);
    items.splice(idx + 1, 0, item);
  }
}

export function swap<T>(items: ko.ObservableArray<T> | Array<T>, x: number, y: number) {
  const arr = ko.isObservable(items) ? items() : items;
  arr[x] = arr.splice(y, 1, arr[x])[0];
  if (ko.isObservable(items)) {
    items(arr);
  }
}

type GenericObject = Record<string, unknown>
type GenericObservableObject = Record<string, ko.Observable<unknown>>

export function objToObservable(obj: GenericObject): GenericObservableObject {
  return Object.keys(obj).reduce((newObsObj: GenericObservableObject, key) => {
    if (Array.isArray(obj[key])) {
      throw Error('Mapping array keys is not supported.')
    }
    newObsObj[key] = ko.observable(obj[key])
    return newObsObj;
  }, {});
}

export function observableToObj(obj: GenericObservableObject): GenericObject {
  return Object.keys(obj).reduce((newObsObj: GenericObject, key) => {
    if (Array.isArray(obj[key]())) {
      throw Error('Mapping array keys is not supported.')
    }
    newObsObj[key] = obj[key]();
    return newObsObj;
  }, {});
}
