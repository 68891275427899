import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { AttributeChoiceList } from '../models/attribute_choice_list';
import * as attributeChoiceListsApi from '../api/attribute_choice_lists';
import { canEditAttributeChoiceList } from '../permissions';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/attribute_choice_lists.html').default;

class AttributeChoiceListsScreen
  implements ListLoaderDelegate<attributeChoiceListsApi.AttributeChoiceListData, AttributeChoiceList>
{
  canEdit = canEditAttributeChoiceList();
  listActions = ko.observableArray<ListHeaderAction>([]);

  constructor() {
    if (this.canEdit) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/attribute_choice_lists/new/',
        tooltipTitle: i18n.t('Add attribute choice')(),
      });
    }
  }
  fetch(params: ListRequestParams) {
    return attributeChoiceListsApi.list(params);
  }

  instantiate(userData: attributeChoiceListsApi.AttributeChoiceListData) {
    return new AttributeChoiceList(userData);
  }

  remove(id: string) {
    return attributeChoiceListsApi.remove(id);
  }

  canRemove(measurementType: AttributeChoiceList) {
    return this.canEdit;
  }
}

export let attributeChoiceLists = {
  name: 'attribute-choice-lists',
  viewModel: AttributeChoiceListsScreen,
  template: template,
};

ko.components.register(attributeChoiceLists.name, attributeChoiceLists);
