import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { AgroRegion } from '../models/agro_region';
import * as agroRegionsApi from '../api/agro_regions';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent, MaybeKO } from '../utils/ko_utils';
import { CountryData } from '../api/countries';
import { canCreateCountry } from '../permissions';

let template = require('raw-loader!../../templates/agro_region_edit.html').default;

class AgroRegionEditScreen extends BaseForm<agroRegionsApi.AgroRegionData> {
  agroRegion = ko.validatedObservable<AgroRegion>(null);
  canEdit = ko.observable(canCreateCountry());

  constructor(
    params: {
      id: string;
      initialName?: string;
      initialCountry?: MaybeKO<CountryData>;
      result?: Deferred<agroRegionsApi.AgroRegionData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let regionDataPromise = params.id ? agroRegionsApi.retrieve(params.id) : undefined;
    let promise = Promise.all([regionDataPromise]).then(([agroRegionData]) => {
      this.agroRegion(new AgroRegion(agroRegionData));
      if (!agroRegionData && params.initialName) {
        this.agroRegion().nameJson(asI18nText(params.initialName));
      }
      if (!agroRegionData && params.initialCountry) {
        this.agroRegion().country(ko.unwrap(params.initialCountry));
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (this.validateLocal(this.agroRegion)) {
      let data = this.agroRegion().toData();
      this.executeSaveRequest(agroRegionsApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.agroRegion(), validation);
      });
    }
  };
}

export let agroRegionEdit = {
  name: 'agro-region-edit',
  viewModel: createWithComponent(AgroRegionEditScreen),
  template: template,
};

ko.components.register(agroRegionEdit.name, agroRegionEdit);
