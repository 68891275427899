import * as ko from 'knockout';

import { app } from '../app';
import { Deferred } from '../utils/deferred';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/components/edit_activatable_list_dialog.html').default;

/** A dialog for editing a list of items that can be activated or deactivated. */
class EditActivatableList {
  /** The dialog title. */
  title = ko.observable<string>();

  /** The dialog subtitle. */
  subtitle = ko.observable<string>();

  /** The initial list of items to edit. */
  initialItems = ko.observableArray<ActivatableItem>();

  /** The new items as the user edits the initial list. */
  editedItems = ko.observableArray<ActivatableItem>();

  /** The dialog result required by the formsStackController. */
  private result: Deferred<{}>;

  constructor(params: { title: string; subtitle: string; items: ActivatableItem[]; result: Deferred<{}> }) {
    this.title(params.title);
    this.subtitle(params.subtitle);
    this.initialItems(params.items);
    this.editedItems(
      params.items.map((item) => {
        return {
          title: item.title,
          isActive: ko.observable(item.isActive()),
        };
      })
    );
    this.result = params.result;
  }

  onSave() {
    this.initialItems().forEach((item, index) => {
      item.isActive(this.editedItems()[index].isActive());
    });
    this.result.resolve({});
  }

  onCancel() {
    this.result.resolve({});
  }
}

ko.components.register('edit-filter-list-dialog', {
  viewModel: EditActivatableList,
  template: template,
});

interface ActivatableItem {
  title: string;
  isActive: ko.Observable<boolean>;
}

export function openEditFilterListDialog(items: ActivatableItem[]): Promise<{}> {
  const title = i18n.t('Edit filter list')();
  return app.formsStackController.push({
    title,
    name: 'edit-filter-list-dialog',
    params: {
      title,
      items,
      subtitle: i18n.t('Enable or disable filters available for this page')(),
      result: new Deferred<{}>(),
    },
  });
}
