import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { DocumentCategory } from '../models/document_category';
import * as documentCategoriesApi from '../api/document_categories';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';
import { canEditDocumentTags } from '../permissions';

let template = require('raw-loader!../../templates/document_category_edit.html').default;

class DocumentCategoryEditScreen extends BaseForm<documentCategoriesApi.DocumentCategoryData> {
  entity = ko.validatedObservable<DocumentCategory>(null);
  canEdit = ko.observable(canEditDocumentTags());

  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<documentCategoriesApi.DocumentCategoryData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let promise = Promise.all([params.id ? documentCategoriesApi.retrieve(params.id) : undefined]).then(
      ([data]) => {
        this.entity(new DocumentCategory(data));
        if (!data && params.initialName) {
          this.entity().nameJson(asI18nText(params.initialName));
        }
      }
    );
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(documentCategoriesApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let documentCategoryEdit = {
  name: 'document-category-edit',
  viewModel: createWithComponent(DocumentCategoryEditScreen),
  template: template,
};

ko.components.register(documentCategoryEdit.name, documentCategoryEdit);
