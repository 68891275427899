import * as ko from 'knockout';

import i18n from '../../i18n';
import { Deferred } from '../../utils/deferred';
import {
  getCropSearchConfig,
  getTraitCategorySearchConfig,
  getMMLibrarySearchConfig,
  getMMTagSearchConfig,
} from '../configs/search_configs';
import { MeasurementMetaData } from '../../api/datasets';
import { FormSelectSearchConfiguration } from '../form_select_search';
import { TraitCategoryData } from '../../api/trait_categories';
import { TPPTraitData } from '../../api/tpps';
import { BoolDict, findById } from '../../utils';
import { translate } from '../../i18n_text';
import { CropData } from '../../api/crops';
import { typeName } from '../../screens/measurement_meta_library';
import { ListLoaderDelegate, ListLoader } from '../list_loader';
import { TrialTypeData } from '../../api/trial_types';
import { listDependencies } from '../../api/measurement_metas';
import { EditMode } from '../../api/trials';
import { SelectedTrait } from '../../models/trial';
import { chunk } from 'lodash';

let template =
  require('raw-loader!../../../templates/components/trial_wizard/select_from_library.html').default;

export function filteredTraits(traits: TPPTraitData[], trialType: TrialTypeData): TPPTraitData[] {
  return traits.filter(
    (trait) =>
      !trait.measurement_meta.deleted &&
      (!trialType ||
        trait.trial_types.length === 0 ||
        trait.trial_types.some((tp) => tp.id === trialType.id))
  );
}

type PrepareResult = (
  mmSlugs: BoolDict,
  data: MeasurementMetaData[],
  cropId: string
) => Promise<MeasurementMetaData[]>;

class SelectFromLibrary {
  tppTitle: string;
  title: string;
  editMode: EditMode | undefined;
  private result: Deferred<MeasurementMetaData[]>;
  private mmSlugs: BoolDict;
  private prepareResult: PrepareResult;
  private single: boolean;

  tppTraitsToAdd: TPPTraitSelection[] = [];
  tppTraitsAdded: TPPTraitSelection[] = [];

  private cropFilter = ko.observable<CropData>(null);
  cropSearchConfig = getCropSearchConfig(this.cropFilter, {
    disableCreate: true,
  });

  private traitCategoryFilter = ko.observable<TraitCategoryData>(null);
  traitCategorySearchConfig = getTraitCategorySearchConfig(this.traitCategoryFilter);

  private tagFilter = ko.observable<TraitCategoryData>(null);
  tagSearchConfig = getMMTagSearchConfig(this.tagFilter, {
    disableCreate: true,
  });

  nameSearch = ko.observable('').extend({ throttle: 300 });

  private selections = ko.observableArray<MeasurementMetaData>();
  private copyAllInProgress = ko.observable(false);
  private alreadySelected = ko.observableArray<{ id: string }>();

  private mmSearchConfig: FormSelectSearchConfiguration<MeasurementMetaData>;
  private mmListLoader: ListLoader<MeasurementMetaData, MeasurementMetaData>;
  mmListDelegate: ListLoaderDelegate<MeasurementMetaData> = {
    onReady: (loader) => (this.mmListLoader = loader),
    fetch: (params) => this.mmSearchConfig.list({ ...params, name_prefix: this.nameSearch() }),
    instantiate: (data) => data,
    remove: () => Promise.resolve(null),
    canRemove: () => false,
  };

  private subscriptions: KnockoutSubscription[] = [];

  preparingResult = ko.observable(false);
  private disposed = false;

  constructor(params: {
    template: boolean;
    editMode?: EditMode;
    traits: TPPTraitData[];
    crop: CropData;
    trialType: TrialTypeData;
    mmSlugs: BoolDict;
    single: boolean;
    prepareResult: PrepareResult;
    selectedTraits: SelectedTrait[];
    result: Deferred<MeasurementMetaData[]>;
  }) {
    this.tppTitle = params.template
      ? i18n.t('Add from target product profile')()
      : i18n.t('Copy from target product profile')();
    this.title = params.template
      ? i18n.t('Add traits from library')()
      : i18n.t('Copy traits from library')();

    this.editMode = params.editMode;
    this.result = params.result;
    this.mmSlugs = params.mmSlugs;
    this.prepareResult = params.prepareResult;
    this.single = params.single;

    this.cropFilter(params.crop || null);
    this.mmSearchConfig = getMMLibrarySearchConfig(
      null,
      this.cropFilter,
      this.traitCategoryFilter,
      this.tagFilter,
      { management: null }
    );
    if (params.traits) {
      for (let trait of filteredTraits(params.traits, params.trialType)) {
        this.addTraitSelection(params.mmSlugs, trait.measurement_meta);
        this.addTraitSelection(params.mmSlugs, trait.measurement_meta.normalization_mm);
      }
    }

    for (let trait of params.selectedTraits) {
      this.alreadySelected.push({ id: trait.mmId });
    }
    this.subscriptions = [
      this.cropFilter.subscribe(this.onUpdateFilters),
      this.traitCategoryFilter.subscribe(this.onUpdateFilters),
      this.tagFilter.subscribe(this.onUpdateFilters),
      this.nameSearch.subscribe(this.onUpdateFilters),
    ];
  }

  dispose() {
    this.disposed = true;
    this.subscriptions.forEach((sub) => sub.dispose());
  }

  private addTraitSelection(mmSlugs: BoolDict, mm: MeasurementMetaData) {
    if (!mm) {
      return;
    }

    let traitSelection = new TPPTraitSelection(mm);
    if (mmSlugs[mm.name_slug]) {
      this.tppTraitsAdded.push(traitSelection);
    } else {
      this.tppTraitsToAdd.push(traitSelection);
    }
  }

  onConfirmTPP = () => {
    this.confirm(this.getSelected(this.tppTraitsToAdd).concat(this.getSelected(this.tppTraitsAdded)));
  };

  private getSelected(selections: TPPTraitSelection[]) {
    return selections.filter((trait) => trait.selected()).map((trait) => trait.measurementMeta);
  }

  private onUpdateFilters = () => {
    this.mmListLoader?.refresh();
  };

  onItemSelected = (item: MeasurementMetaData) => {
    if (findById(this.alreadySelected(), item.id)) {
      return;
    }
    let selection = findById(this.selections(), item.id);
    if (selection) {
      this.selections.remove(selection);
    } else {
      this.selections.push(item);
      if (this.single) {
        this.onConfirm();
      }
    }
  };

  isItemSelected = (item: MeasurementMetaData) => {
    return !!findById(this.selections(), item.id);
  };

  isItemAlreadySelected = (item: MeasurementMetaData) => {
    return !!findById(this.alreadySelected(), item.id);
  };

  typeName = typeName;

  onConfirm = () => {
    this.confirm(this.selections());
  };

  onConfirmAll = async () => {
    // Do not do anything if we are already copying all
    if (this.copyAllInProgress()) {
      return;
    }

    this.copyAllInProgress(true);
    const data = await this.mmListDelegate.fetch({ limit: undefined });

    for (let item of data) {
      if (findById(this.alreadySelected(), item.id)) {
        continue;
      }
      this.selections.push(item);
    }

    this.confirm(this.selections());
    this.copyAllInProgress(false);
  };

  private async confirm(toAdd: MeasurementMetaData[]) {
    if (toAdd.length === 0) {
      this.result.resolve([]);
      return;
    }

    try {
      this.preparingResult(true);
      const preparedResult = await this.prepareResult(this.mmSlugs, toAdd, this.cropFilter()?.id);
      if (this.disposed) {
        return;
      }

      this.result.resolve(preparedResult);
    } finally {
      this.preparingResult(false);
    }
  }

  onCancel = () => {
    this.result.resolve([]);
  };
}

export async function addDependencies(
  mmSlugs: BoolDict,
  toAdd: MeasurementMetaData[],
  cropId: string
): Promise<{ valid: MeasurementMetaData[]; invalid: MeasurementMetaData[] }> {
  // If the user adds a lot of traits, the request URI becomes too large and the server returns 414.
  // To avoid this, we need to split the request into multiple requests.
  const dependenciesPromises = chunk(toAdd, 200).map((toAddChunk) =>
    listDependencies({
      for_ids: toAddChunk.map((mm) => mm.id),
      crop_id: cropId,
    })
  );
  const dependenciesChunks = await Promise.all(dependenciesPromises);

  let filtered: MeasurementMetaData[] = [];
  let invalid: MeasurementMetaData[] = [];
  for (const dependencies of dependenciesChunks) {
    filtered.concat(
      dependencies.valid.filter(
        (dep) => !mmSlugs[dep.name_slug] && toAdd.every((mm) => mm.name_slug !== dep.name_slug)
      )
    );
    invalid.concat(dependencies.invalid);
  }

  return { valid: toAdd.concat(filtered), invalid: invalid };
}

class TPPTraitSelection {
  name: string;
  selected = ko.observable(false);

  measurementMeta: MeasurementMetaData;

  constructor(mm: MeasurementMetaData) {
    this.name = translate(mm.observation_name) || translate(mm.name_json);
    this.measurementMeta = mm;
  }
}

ko.components.register('select-from-library', {
  viewModel: SelectFromLibrary,
  template: template,
});
