import * as ko from 'knockout';

import { ChartGroup } from '../models/chart_group';
import { AutoChart, Chart } from '../models/chart';
import { openAutoChartOptionsEdit } from './auto_chart_options_edit';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/components/chart_group_list.html').default;

export interface ChartGroupListDelegate {
  chartGroups: KnockoutSubscribable<ChartGroup[]>;
}

class ChartGroupList {
  delegate: ChartGroupListDelegate;
  fullscreenChart = ko.observable<Chart>();

  editText = i18n.t('Edit')();
  downloadSummaryText = i18n.t('Download summary')();
  downloadExcelText = i18n.t('Download to Excel')();
  downloadImageText = i18n.t('Download image')();
  fullscreenText = i18n.t('Enter fullscreen')();
  fullscreenExitText = i18n.t('Exit fullscreen')();
  removeText = i18n.t('Remove')();

  constructor(params: { delegate: ChartGroupListDelegate }) {
    this.delegate = params.delegate;
  }

  isGroupVisible(group: ChartGroup): boolean {
    let fullscreenChart = this.fullscreenChart();
    if (!fullscreenChart) {
      return true;
    }

    for (let groupChart of group.charts()) {
      if (groupChart === fullscreenChart) {
        return true;
      }
    }

    return false;
  }

  isChartVisible(chart: Chart): boolean {
    return !this.fullscreenChart() || this.isChartFullScreen(chart);
  }

  isChartFullScreen(chart: Chart): boolean {
    return this.fullscreenChart() === chart;
  }

  exitFullscreen = (chart: Chart) => {
    chart.setScale(1);
    this.fullscreenChart(null);
  };

  enterFullscreen = (chart: Chart) => {
    chart.setScale(2);
    this.fullscreenChart(chart);
  };

  editOptions = async (chart: Chart) => {
    if (!(chart instanceof AutoChart)) {
      return;
    }

    await openAutoChartOptionsEdit({
      mmId: chart.measurementMetaId,
      chartType: chart.selectedChartType(),
    });

    chart.refresh();
  };
}

let chartGroupList = {
  name: 'chart-group-list',
  viewModel: ChartGroupList,
  template: template,
};

ko.components.register(chartGroupList.name, chartGroupList);
