import * as ko from 'knockout';
import i18n from '../i18n';

import { RemoveResult } from '../api/request';
import { ListLoaderDelegate, ListFilter } from './list_loader';
import { Deferred } from '../utils/deferred';
import { defaultRateLimit } from '../models/attribute_meta';
import { I18nText } from '../i18n_text';
import * as trialsApi from '../api/trials';
import { getUserSearchConfig } from './configs/search_configs';
import { UserData } from '../api/users';
import { TrialSelectData } from '../api/base_trials';
import { findById } from '../utils';

let template = require('raw-loader!../../templates/components/trial_advanced_search.html').default;

interface AdvancedSearchModel<TData, TModel> extends ListLoaderDelegate<TData, TModel> {
  allowMultipleSelections: boolean;
  loading: KnockoutObservable<boolean>;

  getItemName: (item: TModel) => I18nText | string;

  cancel: () => void;
  selectAll: () => void;
  done: () => void;
  onItemSelected: (item: TModel) => void;
  isItemSelected: (item: TModel) => boolean;
}

export type TrialAdvancedSearchResult = { selected: TrialSelectData[]; deleted: TrialSelectData[] };
class TrialAdvancedSearch implements AdvancedSearchModel<trialsApi.TrialData, trialsApi.TrialData> {
  filters: ListFilter[] = [
    {
      name: i18n.t('Name')(),
      slug: 'name_prefix',
      type: 'text',
      value: ko.observable('').extend({ rateLimit: defaultRateLimit }),
    },
    {
      name: i18n.t('Owners')(),
      slug: 'user_ids',
      type: 'select',
      config: getUserSearchConfig(ko.observable<UserData>(null)),
    },
  ];

  private result: Deferred<TrialAdvancedSearchResult>;
  private entities: trialsApi.TrialData[] = [];
  private selections = ko.observableArray<TrialSelectData>();
  private deletions = ko.observableArray<TrialSelectData>();

  allowMultipleSelections = true;

  loading = ko.observable(false);

  constructor(params: {
    allowMultipleSelections: boolean;
    initialMultipleSelections: TrialSelectData[];
    result: Deferred<TrialAdvancedSearchResult>;
  }) {
    this.allowMultipleSelections = params.allowMultipleSelections;
    this.selections(params.initialMultipleSelections.filter((item) => item !== null) || []);
    this.result = params.result;
  }

  getItemName(item: trialsApi.TrialData): I18nText {
    return item.name_json;
  }

  getOwnerNames(item: trialsApi.TrialData) {
    return item.owners.map((owner) => owner.name).join(', ');
  }
  cancel = () => {
    this.result.reject();
  };

  selectAll = () => {
    this.entities.map((item) =>
      this.selections.push({ id: item.id, name_json: item.name_json, crop_id: item.crop.id })
    );
  };

  done = () => {
    this.result.resolve({ selected: this.selections(), deleted: this.deletions() });
  };

  onItemSelected = (item: trialsApi.TrialData) => {
    const selected = findById(this.selections(), item.id);
    const deleted = findById(this.deletions(), item.id);

    const trialSelect: TrialSelectData = { id: item.id, name_json: item.name_json, crop_id: item.crop.id };
    if (selected) {
      this.deletions.push(selected);
      this.selections.remove(selected);
    } else {
      this.selections.push(trialSelect);
      if (deleted) this.deletions.remove(deleted);
    }
  };

  isItemSelected = (item: trialsApi.TrialData) => {
    return !!findById(this.selections(), item.id);
  };
  mergeEntities = (items: trialsApi.TrialData[]) => {
    const ids = this.entities.length > 0 ? new Set(this.entities.map((entity) => entity.id)) : new Set([]);
    const merged = [...this.entities, ...items.filter((item) => !ids.has(item.id))];
    return merged;
  };

  async fetch(params: trialsApi.TrialListRequestParams): Promise<trialsApi.TrialData[]> {
    params.name_prefix = this.filters[0].value() as string;
    const userEntity = this.filters[1].config?.entity();
    params.user_ids = userEntity ? [userEntity?.id as string] : [];
    params.template = false;

    const fetchedItems = await trialsApi.list(params);

    if (params.offset === 0) {
      this.entities = fetchedItems;
    } else {
      this.entities = this.mergeEntities(fetchedItems);
    }
    return fetchedItems;
  }

  instantiate(data: trialsApi.TrialData): trialsApi.TrialData {
    return data;
  }

  remove(id: string): Promise<RemoveResult> {
    return Promise.reject(null);
  }

  canRemove(entity: trialsApi.TrialData): boolean {
    return false;
  }
}

export let trialAdvancedSearch = {
  name: 'trial-advanced-search',
  viewModel: TrialAdvancedSearch,
  template: template,
};

ko.components.register(trialAdvancedSearch.name, trialAdvancedSearch);
