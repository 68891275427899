import { request, ListRequestParams, requestRaw, makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';
import { FileUploadEndpoint } from '../cloud_storage_upload';
import { CropData } from './crops';

export interface MeasurementChoiceData {
  id: string;
  name_json: I18nText;
  value: number;
  code: string;
  file_name: string;
  file_public_url?: string; // read-only
}

export interface TraitActionChoiceOptionData extends Pick<MeasurementChoiceData, 'id' | 'name_json'> {
  value: string;
}

export interface MeasurementTypeData {
  id?: string;
  name_json: I18nText;
  crop: CropData;
  rating: boolean;
  embedded?: boolean; // read-only
  choices?: MeasurementChoiceData[];
}

export interface MeasurementTypeListRequestParams extends ListRequestParams {
  crop_ids?: string[];
  include_null_crop?: boolean;
  null_crop_last?: boolean;
}

export const { list, retrieve, save, remove } = makeDefaultApi<
  MeasurementTypeData,
  MeasurementTypeListRequestParams
>('measurement_types');

export function getImageUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
  return request('POST', '/api/measurement_types/image_upload_url/', {
    content_type: contentType,
  });
}

export function exportAll(): Promise<Blob> {
  return requestRaw('GET', '/api/measurement_types/export/');
}
