import * as ko from 'knockout';
import i18n from '../i18n';

i18n.onTranslationReady(() => {
  (<any>ko.validation.rules)['geoPt'] = {
    validator: (val: string) => {
      if (val) {
        let parts = val.split(',');

        if (parts.length != 2) {
          return false;
        }

        for (let part of parts) {
          if (!part.trim().match(/^(-|\+)?\d+(\.\d+)?$/)) {
            return false;
          }
        }

        let lat = parseFloat(parts[0]);
        let lon = parseFloat(parts[1]);

        if (lat < -90 || lat > 90 || lon < -180 || lon > 180) {
          return false;
        }
      }

      return true;
    },
    message: i18n.t('Invalid GPS coordinates')(),
  };
});
