import * as ko from 'knockout';
import { isIE11 } from '../utils';

function setupMaterialSelect(element: Element, value: KnockoutObservable<{}>) {
  let $element = $(element);

  (<any>$element.material_select)(() => {
    if (value) {
      value($(element).val());
    }
  });

  if (isIE11()) {
    // on IE 11, clicking on the scrollbar make the input lose focus,
    // which closes the dropdown.

    // tells if we should re-focus the input immediately
    let skipNext = false;

    $element.siblings('ul.select-dropdown').on('mousedown', function (evt: JQueryEventObject) {
      // if the click offset is larger than the clientWidth,
      // it means the user clicked on the scroll bar
      if (evt.offsetX >= evt.target.clientWidth) {
        skipNext = true;
        // if the user clicks multiple times in rapid succession,
        // this event handler might be called both before
        // and after the input blur, which would lead
        // to the skipNext flag never being cleared.
        // So we always clear it after giving the blur event handler
        // a chance to run.
        setTimeout(() => {
          skipNext = false;
        }, 10);
      } else {
        // ensure we're not skipping this event, if it happens within the 10ms window
        skipNext = false;
      }
    });

    // remove materialize blur, and replace it with our own to take skipNext into account
    $element
      .siblings('input.select-dropdown')
      .off('blur')
      .on('blur', function () {
        if (skipNext) {
          skipNext = false;
          $(this).focus();
          return;
        }

        // this is materialize default behavior for single-choice selects
        $(this).trigger('close');
        $(this).siblings('ul').find('li.selected').removeClass('selected');
      });
  }
}

ko.bindingHandlers['materialSelect'] = {
  init: (
    element: Element,
    valueAccessor: () => {
      value: KnockoutObservable<string>;
      options: KnockoutObservable<{}>;
      enable: KnockoutObservable<boolean>;
    }
  ) => {
    let config = valueAccessor();
    let optionsSub: KnockoutSubscription, valueSub: KnockoutSubscription, enableSub: KnockoutSubscription;

    let setEnabled = (val: boolean) => {
      if (!val) {
        element.setAttribute('disabled', 'disabled');
      } else {
        element.removeAttribute('disabled');
      }
    };

    if (ko.isObservable(config.options)) {
      optionsSub = config.options.subscribe(() => {
        setupMaterialSelect(element, config.value);
      });
    }

    if (ko.isObservable(config.value)) {
      $(element).val(config.value());
      valueSub = config.value.subscribe((val) => {
        $(element).val(val);
        setupMaterialSelect(element, config.value);
      });
    }

    if (ko.isObservable(config.enable)) {
      setEnabled(config.enable());
      enableSub = (<KnockoutObservable<boolean>>config.enable).subscribe((val) => {
        setEnabled(val);
        setupMaterialSelect(element, config.value);
      });
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      if (optionsSub) {
        optionsSub.dispose();
      }
      if (valueSub) {
        valueSub.dispose();
      }
      if (enableSub) {
        enableSub.dispose();
      }

      $(element).material_select('destroy');
    });

    setupMaterialSelect(element, config.value);
  },
  update: (
    element: Element,
    valueAccessor: () => {
      value: KnockoutObservable<{}>;
      options: KnockoutObservable<{}>;
    }
  ) => {
    let config = valueAccessor();

    if (config.value) {
      setupMaterialSelect(element, config.value);
    }
  },
};
