import * as ko from 'knockout';
import i18n from '../i18n';
import { I18nText, translate } from '../i18n_text';

i18n.onTranslationReady(() => {
  (<any>ko.validation.rules)['slug'] = {
    validator: (val: string) => {
      if (val) {
        return !!val.match(/^[A-Za-z_][A-Za-z_0-9]*$/);
      }

      return true;
    },
    message: i18n.t(
      'Codes can only contain digits, latin letters and underscores, and cannot start with a digit.'
    )(),
  };

  (<any>ko.validation.rules)['slugPrefix'] = {
    validator: (val: string) => {
      if (val) {
        val = val.toLowerCase();
        for (let prefix of SERVER_INFO.SLUG_CONFIG.RESERVED_PREFIXES) {
          if (val.indexOf(prefix) === 0) {
            return false;
          }
        }
      }

      return true;
    },
    message: i18n.t('Codes cannot start with: ')() + SERVER_INFO.SLUG_CONFIG.RESERVED_PREFIXES.join(', '),
  };

  (<any>ko.validation.rules)['slugSuffix'] = {
    validator: (val: string) => {
      if (val) {
        val = val.toLowerCase();
        for (let suffix of SERVER_INFO.SLUG_CONFIG.RESERVED_SUFFIXES) {
          if (val.length >= suffix.length && val.indexOf(suffix) === val.length - suffix.length) {
            return false;
          }
        }
      }

      return true;
    },
    message: i18n.t('Codes cannot end with: ')() + SERVER_INFO.SLUG_CONFIG.RESERVED_SUFFIXES.join(', '),
  };

  (<any>ko.validation.rules)['slugReserved'] = {
    validator: (val: string) => {
      if (!val) {
        return true;
      }

      val = val.toLowerCase();

      return SERVER_INFO.SLUG_CONFIG.RESERVED_SLUGS.indexOf(val) === -1;
    },
    message: i18n.t('This code is reserved')(),
  };
});

export const slugValidation: { [key: string]: {} } = {
  slug: true,
  slugPrefix: true,
  slugSuffix: true,
  slugReserved: true,
  maxLength: SERVER_INFO.SLUG_CONFIG.MAX_LEN,
  required: true,
  serverError: true,
};

export class SlugGenerator {
  private subcriptions: KnockoutSubscription[] = [];
  private previousName = '';
  private previousPrefix: string = null;

  constructor(
    private name: KnockoutObservable<string | I18nText>,
    private prefix:
      | ko.PureComputed<{ anonymized_code: string }>
      | KnockoutObservable<{ anonymized_code: string }>,
    private nameSlug: KnockoutObservable<string>,
    options: { canEdit: boolean; fillIfEmpty: boolean }
  ) {
    this.previousName = translate(name());
    this.previousPrefix = prefix?.()?.anonymized_code;

    if (options.canEdit) {
      this.subcriptions = [name.subscribe(this.onChanged)];
      if (prefix) {
        this.subcriptions.push(prefix.subscribe(this.onChanged));
      }
      if (!this.nameSlug() && options.fillIfEmpty) {
        this.nameSlug(generateSlug(this.previousName, this.previousPrefix));
      }
    }
  }

  dispose() {
    this.subcriptions.forEach((sub) => sub.dispose());
  }

  private onChanged = () => {
    let name = this.name();
    let prefix = this.prefix?.()?.anonymized_code;

    let previousSlug = generateSlug(this.previousName, this.previousPrefix);
    this.previousName = translate(name);
    this.previousPrefix = prefix;

    if (this.nameSlug() && previousSlug !== this.nameSlug()) {
      // slug manually edited
      return;
    }

    this.nameSlug(generateSlug(this.previousName, this.previousPrefix));
  };
}

export function generateSlug(name: string, prefix: string = ''): string {
  let slug = name
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/\-/g, '_');
  if (prefix) {
    slug = prefix + '_' + slug;
  }

  if (slug.match(/^[0-9]/)) {
    slug = '_' + slug;
  }

  for (let reservedPrefix of SERVER_INFO.SLUG_CONFIG.RESERVED_PREFIXES) {
    if (slug.toLowerCase().indexOf(reservedPrefix) === 0) {
      slug = '_' + slug;
    }
  }

  for (let suffix of SERVER_INFO.SLUG_CONFIG.RESERVED_SUFFIXES) {
    if (slug.length >= suffix.length && slug.toLowerCase().indexOf(suffix) === slug.length - suffix.length) {
      slug = slug + '_';
    }
  }

  if (SERVER_INFO.SLUG_CONFIG.RESERVED_SLUGS.indexOf(slug.toLowerCase()) !== -1) {
    slug = '_' + slug;
  }

  slug = slug.slice(0, SERVER_INFO.SLUG_CONFIG.MAX_LEN);

  return slug;
}
