import * as ko from 'knockout';
import { ActionType, SoundName, TraitActionData, TraitActionOptionsData } from '../api/trait_actions';
import { TraitActionChoiceOptionData } from '../api/measurement_types';
import i18n from '../i18n';

export class TraitAction {
  id = ko.observable<string>(null);
  actionType = ko.observable<ActionType>('set_value').extend({ required: true });

  options = ko.observable<{
    sources: TraitActionOptionsData['sources'];
    targets: TraitActionOptionsData['targets'];
    actionTypes: TraitActionOptionsData['action_types'];
    soundNames: TraitActionOptionsData['sound_names'];
  }>();

  sourceTraitId = ko.observable<string>(null).extend({
    required: true,
    deferred: true,
    validation: {
      validator: (value) => {
        return this.options() && this.options().sources.some((option) => option.id === value);
      },
      message: i18n.t('Please select one of the options.')(),
    },
  });
  targetTraitId = ko.observable<string>(null).extend({
    required: true,
    deferred: true,
    validation: {
      validator: (value) => {
        return this.options() && this.options().targets.some((option) => option.id === value);
      },
      message: i18n.t('Please select one of the options.')(),
    },
  });
  targetValue = ko.observable<string>(null).extend({
    required: true,
    deferred: true,
    validation: {
      validator: (value) => {
        return this.targetValueOptions().some((option) => option.id === value);
      },
      message: i18n.t('Please select one of the options.')(),
    },
  });
  soundName = ko.observable<SoundName>('success_sound').extend({
    required: {
      onlyIf: () => this.actionType() === 'play_sound',
    },
  });

  targetValueOptions = ko.pureComputed<TraitActionChoiceOptionData[]>(() => {
    if (!this.options() || !this.targetTraitId()) {
      return [];
    }
    return this.options().targets.find((trait) => trait.id === this.targetTraitId())?.value_choices ?? [];
  });

  private errors = ko.validation.group(this);

  hasErrors = ko.pureComputed(() => {
    return this.errors().length > 0;
  });

  constructor(data?: TraitActionData) {
    if (data) {
      this.id(data.id);
      this.actionType(data.action_type);
      this.sourceTraitId(data.source_measurement_meta_id);
      this.targetTraitId(data.target_measurement_meta_id);
      this.targetValue(data.target_measurement_value);
      this.soundName(data.sound_name);
    }
  }

  setOptions(options: TraitActionOptionsData) {
    this.options({
      sources: options.sources,
      targets: options.targets,
      actionTypes: options.action_types,
      soundNames: options.sound_names ?? [],
    });
  }

  getSourceOptions() {
    return this.options()?.sources ?? [];
  }

  getActionTypeOptions() {
    return this.options()?.actionTypes ?? [];
  }

  getTargetOptions() {
    return this.options()?.targets ?? [];
  }

  getSoundNameOptions() {
    return this.options()?.soundNames ?? [];
  }

  toData(): TraitActionData {
    return {
      action_type: this.actionType(),
      source_measurement_meta_id: this.sourceTraitId(),
      target_measurement_meta_id: this.targetTraitId(),
      target_measurement_value: this.targetValue(),
      sound_name: this.soundName(),
      ...(this.id() ? { id: this.id() } : {}),
    };
  }

  showErrors() {
    this.errors.showAllMessages();
  }

  usesTrait(traitId: string) {
    return this.sourceTraitId() === traitId || this.targetTraitId() === traitId;
  }
}
