import * as ko from 'knockout';

import { ListLoaderDelegate } from '../../components/list_loader';
import { DataEntryTrialData, listDataEntryTrials } from '../data_entry_api';
import { NO_PAGINATION } from '../../components/list_loader';
import i18n from '../../i18n';

const template = require('raw-loader!./data_entry_trials.html').default;

class DataEntryTrialsScreen implements ListLoaderDelegate<DataEntryTrialData> {
  pageSize = NO_PAGINATION;
  noItemsText = i18n.t("You haven't been assigned to any trial.")();

  fetch() {
    return listDataEntryTrials();
  }

  instantiate(data: DataEntryTrialData) {
    return data;
  }

  canRemove() {
    return false;
  }

  remove() {
    return Promise.resolve(null);
  }
}

export const dataEntryTrials = {
  name: 'data-entry-trials',
  viewModel: DataEntryTrialsScreen,
  template,
};

ko.components.register(dataEntryTrials.name, dataEntryTrials);
