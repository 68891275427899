import * as ko from 'knockout';

import * as internalApi from '../../api/internal';
import { ChartList } from '../../ko_bindings/bar_chart';
import { ALL_PLANS } from './tenant_subscription_edit';
import { Grid, h, html } from 'gridjs';
import { TColumn } from 'gridjs/dist/src/types';
import { tryFormatDate } from '../../utils';
import { FilterDelegate } from '../../components/list_filters';
import 'gridjs/dist/theme/mermaid.css';

let template = require('raw-loader!../../../templates/internal/tenants_usage.html').default;

const DISPLAY_CHART_BUTTON_MESSAGE = {
  loading: 'Loading Chart...',
  display: 'Display Chart',
  hide: 'Hide Chart',
  unavailable: 'Chart Not Available',
};

const SUBSCRIPTION_TABLE_COLUMNS: TColumn[] = [
  { id: 'id', name: 'id', hidden: true },
  {
    id: 'name',
    name: 'Customer',
    sort: { enabled: true },
    attributes: (column) => {
      if (column) {
        return { class: 'gridjs-td left-align-column' };
      }
    },
    formatter: (cell, row) => html(`<a href="/tenants-usage/edit/${row.cells[0].data}/">${cell}</a>`),
  },
  {
    id: 'subscription_plan',
    name: 'Plan',
    sort: { enabled: true },
    width: '105px',
    formatter: (cell: string) => (cell ? ALL_PLANS.find((p) => p.id === cell).name : 'Free Trial'),
  },
  {
    id: 'expiration_date',
    name: 'Expiration',
    width: '155px',
    sort: { enabled: true },
    formatter: (cell: string) => tryFormatDate(cell),
  },
  {
    id: 'non_discounted_monthly_price',
    name: 'Monthly $',
    sort: { enabled: true },
    width: '110px',
    attributes: (column) => {
      if (column !== null) {
        return { class: 'gridjs-td right-align-column' };
      }
    },
    formatter: (cell) => parseFloat(cell?.toString())?.toLocaleString(),
  },
  {
    id: 'discount',
    name: 'Discount %',
    sort: { enabled: true },
    width: '115px',
    attributes: (column) => {
      if (column !== null) {
        return { class: 'gridjs-td right-align-column' };
      }
    },
    formatter: (cell) => parseFloat(cell?.toString())?.toLocaleString(),
  },
  {
    id: 'subscription_add_on_price',
    name: 'Addons $',
    sort: { enabled: true },
    width: '105px',
    attributes: (column) => {
      if (column !== null) {
        return { class: 'gridjs-td right-align-column' };
      }
    },
    formatter: (cell) => (cell ? parseFloat(cell?.toString()).toLocaleString() : '-'),
  },
  {
    id: 'annual_price',
    name: 'Total annual $',
    sort: { enabled: true },
    width: '135px',
    attributes: (column) => {
      if (column !== null) {
        return { class: 'gridjs-td right-align-column' };
      }
    },
    formatter: (cell) => parseFloat(cell?.toString()).toLocaleString(),
  },
  {
    id: 'automatic_payment',
    name: 'Stripe',
    sort: { enabled: true },
    width: '105px',
    formatter: (cell) => (cell ? 'Yes' : 'No'),
  },
  { id: 'subscription_comment', name: 'Comment' },
];

const DISPLAY_SUBSCRIPTION_OPTIONS = [
  {
    id: 'ACTIVE',
    value: 'active',
  },
  {
    id: 'TRIAL',
    value: 'trial',
  },
  {
    id: 'ALL',
    value: 'all',
  },
  {
    id: 'EXPIRED',
    value: 'expired',
  },
];
type ListageOptions = 'all' | 'active' | 'trial' | 'expired';

class TenantsUsageScreen {
  subs: internalApi.TenantSubscriptionData[];
  charts = ko.observable<ChartList>({});
  loading = ko.observable({
    charts: true,
    subscriptions: true,
  });
  chartsDisplayed = ko.observable(0);
  currentView = ko.observable<ListageOptions>('active');
  viewOptions = DISPLAY_SUBSCRIPTION_OPTIONS;

  private tenantFeatureSelectedEntities = ko.observableArray();

  tenantFeatureFilter: FilterDelegate[] = [
    {
      title: 'Enabled Tenant Features',
      entities: this.tenantFeatureSelectedEntities,
      list: (_) => {
        return Promise.resolve([
          { id: 'observation_history_enabled', name: 'Observation History tab' },
          { id: 'observation_group_tab_enabled', name: 'Observation Group tab' },
          { id: 'barcode_scan_action_enabled', name: 'Barcode scan action' },
          { id: 'manual_trials_enabled', name: 'Manual Trials' },
          { id: 'map_visualization_enabled', name: 'Map Visualization' },
          { id: 'data_warehouse_access_enabled', name: 'Data Warehouse Access' },
          { id: 'api_integration_enabled', name: 'API Integration' },
          { id: 'treatment_management_enabled', name: 'Treatment Management' },
          { id: 'custom_reports_enabled', name: 'Custom Reports' },
          { id: 'limit_traits_to_dimensions_enabled', name: 'Limit traits to dimensions' },
          { id: 'mobile_derived_traits_enabled', name: 'Mobile derived traits' },
        ]);
      },
    },
  ];

  constructor() {
    this.currentView.subscribe(this.toggleView);
    this.tenantFeatureSelectedEntities.subscribe(this.onFiltersChange);
    const subsPromise = internalApi.tenantsSubscriptions('active');
    const chartPromise = internalApi.tenantsUsage();
    subsPromise.then((value) => {
      this.subs = value;
      this.loading({ ...this.loading(), subscriptions: false });
      this.renderUsageTable();
    });

    chartPromise.then((tenantsChartData) => {
      const newCharts: ChartList = {};
      tenantsChartData.forEach(
        (tenant) =>
          (newCharts[tenant.tenant] = {
            type: 'bar' as 'bar',
            scaleType: 'time' as 'time',
            title: tenant.tenant,
            yTitle: 'N. of facts',
            xTitle: '',
            labels: tenant.labels,
            fontSize: 14,
            timeUnit: 'month' as 'month',
            datasets: [
              {
                label: '',
                data: tenant.values,
              },
            ],
            visible: false,
          })
      );
      this.charts(newCharts);
      this.setDisplayChartButtonGlobalMessage(DISPLAY_CHART_BUTTON_MESSAGE.display);
      this.loading({ ...this.loading(), charts: false });
    });
  }

  onFiltersChange = () => {
    this.loading({ ...this.loading(), subscriptions: true });

    const selectedIds = this.tenantFeatureSelectedEntities().map((entity) => entity.id);

    let subs = internalApi.tenantsSubscriptions(this.currentView(), {
      observation_history_enabled: selectedIds.includes('observation_history_enabled'),
      observation_group_tab_enabled: selectedIds.includes('observation_group_tab_enabled'),
      barcode_scan_action_enabled: selectedIds.includes('barcode_scan_action_enabled'),
      manual_trials_enabled: selectedIds.includes('manual_trials_enabled'),
      map_visualization_enabled: selectedIds.includes('map_visualization_enabled'),
      data_warehouse_access_enabled: selectedIds.includes('data_warehouse_access_enabled'),
      api_integration_enabled: selectedIds.includes('api_integration_enabled'),
      treatment_management_enabled: selectedIds.includes('treatment_management_enabled'),
      custom_reports_enabled: selectedIds.includes('custom_reports_enabled'),
      limit_traits_to_dimensions_enabled: selectedIds.includes('limit_traits_to_dimensions_enabled'),
      mobile_derived_traits_enabled: selectedIds.includes('mobile_derived_traits_enabled')
    });
    subs.then((data) => {
      this.subs = data;
      this.loading({ ...this.loading(), subscriptions: false });
      this.renderUsageTable();
    });
  };

  renderUsageTable = () => {
    const DISPLAY_CHART_COLUMNS: TColumn = {
      id: 'display_chart_button',
      name: 'Chart',
      sort: { enabled: true },
      width: '105px',
      attributes: (column) => {
        if (column !== null) {
          return { class: 'gridjs-td right-align-column' };
        }
      },
      formatter: (cell, row) => {
        const tenantName = row.cells[1].data as string;
        const initialMessage = this.loading().charts ? 'Loading Charts...' : this.getMessage(tenantName);
        const mustBeDisabled = initialMessage === DISPLAY_CHART_BUTTON_MESSAGE.unavailable;

        return h(
          'button',
          {
            onClick: (event: any) => this.toggleChart(event, tenantName),
            className: 'display-chart-button',
            'data-tenantname': tenantName,
            disabled: mustBeDisabled,
          },
          initialMessage
        );
      },
    };
    const grid = new Grid({
      columns: [...SUBSCRIPTION_TABLE_COLUMNS, DISPLAY_CHART_COLUMNS],
      data: this.subs as {}[],
      search: true,
      style: {
        td: {
          'text-align': 'center',
        },
      },
      language: {
        search: {
          placeholder: '🔍 Search...',
        },
      },
    });

    grid.render(document.getElementById('tenants-subscription'));
  };
  toggleView = () => {
    this.loading({ ...this.loading(), subscriptions: true });

    let subs = internalApi.tenantsSubscriptions(this.currentView());
    subs.then((data) => {
      this.subs = data;
      this.loading({ ...this.loading(), subscriptions: false });
      this.renderUsageTable();
      this.setDisplayChartButtonGlobalMessage(DISPLAY_CHART_BUTTON_MESSAGE.display);
    });
  };

  toggleAllCharts = () => {
    const newCharts: ChartList = {};
    let totalChartsDisplayed = this.chartsDisplayed();
    this.loading({ ...this.loading(), charts: true });
    const shouldDisplay = totalChartsDisplayed === 0;

    for (const [key, value] of Object.entries(this.charts())) {
      if (value.visible !== shouldDisplay) shouldDisplay ? totalChartsDisplayed++ : totalChartsDisplayed--;

      value.visible = shouldDisplay;
      newCharts[key] = value;
    }

    this.setDisplayChartButtonGlobalMessage(
      shouldDisplay ? DISPLAY_CHART_BUTTON_MESSAGE.hide : DISPLAY_CHART_BUTTON_MESSAGE.display
    );
    this.charts(newCharts);
    this.chartsDisplayed(totalChartsDisplayed);
    this.loading({ ...this.loading(), charts: false });
  };

  setDisplayChartButtonGlobalMessage = (message: string) => {
    const displayChartButtons = $('.display-chart-button');
    displayChartButtons.each((index, button) => {
      const tenantName = $(button).data('tenantname');
      if (tenantName in this.charts()) {
        $(button).text(message);
        $(button).prop('disabled', false);
      } else if (this.loading().charts) {
        $(button).text(DISPLAY_CHART_BUTTON_MESSAGE.unavailable);
        $(button).prop('disabled', true);
      }
    });
  };

  getMessage = (tenantName: string) => {
    if (tenantName in this.charts()) {
      return this.charts()[tenantName].visible
        ? DISPLAY_CHART_BUTTON_MESSAGE.hide
        : DISPLAY_CHART_BUTTON_MESSAGE.display;
    }

    return DISPLAY_CHART_BUTTON_MESSAGE.unavailable;
  };

  toggleChart = (event: MouseEvent, tenantName: string) => {
    if (this.loading().charts) return;
    (event.target as HTMLInputElement).textContent =
      (event.target as HTMLInputElement).textContent === DISPLAY_CHART_BUTTON_MESSAGE.display
        ? DISPLAY_CHART_BUTTON_MESSAGE.hide
        : DISPLAY_CHART_BUTTON_MESSAGE.display;

    const chartsHolder = this.charts();
    const shouldDisplay = !this.charts()[tenantName].visible;

    // Need to create a new object instead of simply changing chartsHolder[tenantName].visible
    // otherwise knockout will not compute the change and update the UI
    chartsHolder[tenantName] = {
      ...chartsHolder[tenantName],
      visible: shouldDisplay,
    };

    this.charts(chartsHolder);
    this.chartsDisplayed(this.chartsDisplayed() + (shouldDisplay ? 1 : -1));
  };
}

export let tenantsUsage = {
  name: 'tenants-usage',
  viewModel: TenantsUsageScreen,
  template: template,
};

ko.components.register(tenantsUsage.name, tenantsUsage);
