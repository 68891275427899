import { makeDefaultApi } from './request';
import { BaseGroupData } from './base_groups';
export { BaseGroupData } from './base_groups';
import { UserData } from './users';
import { DimensionData } from './dimensions';
import { TrialDatasetData } from './datasets';
import { TrialSelectData } from './base_trials';
import { DimensionMetaData } from './dimension_metas';

export interface GroupData extends BaseGroupData {
  name_slug: string;
  users: UserData[];
  managers: UserData[];
  data_entry_permissions: DataEntryPermissionData[];
  editable_trials: TrialSelectData[];
}

export interface DataEntryPermissionData {
  group_id: BaseGroupData;
  dataset_id: TrialDatasetData;
  trial_id: TrialSelectData;
  exclude: boolean;
  dimension_metas?: DimensionMetaData[]; // read-only
  conditions: DataEntryPermissionConditionData[];
}

export interface DataEntryPermissionConditionData {
  dimension_meta_id: string;
  dimension_ids: DimensionData[];
}

interface GroupRequestParams {
  include_trial_plot_counts?: boolean;
}

export const { list, retrieve, save, remove } = makeDefaultApi<BaseGroupData, GroupRequestParams, GroupData>(
  'groups'
);
