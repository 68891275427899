import * as ko from 'knockout';

import i18n from '../i18n';
import { ImportEntitiesDelegate } from '../components/import_entities';
import { BaseLoadingScreen } from './base_loading_screen';
import { downloadRegionImportTemplate } from '../api/regions';

const template = require('raw-loader!../../templates/components/import_entities_container.html').default;

class ImportRegionsScreen extends BaseLoadingScreen implements ImportEntitiesDelegate<void> {
  title = ko.observable('Import administrative regions');
  description = i18n.t(
    'To import administrative regions, download the Excel template, fill-in the desired records and then upload the file.'
  )();
  backTitle = i18n.t('Back to list')();

  templateBaseName = 'administrative_regions';
  importUrl = '/api/regions/import_records/';

  constructor() {
    super();
    this.loaded();
  }

  downloadTemplate = downloadRegionImportTemplate;
}

export const importRegions = {
  name: 'import-regions',
  viewModel: ImportRegionsScreen,
  template,
};

ko.components.register(importRegions.name, importRegions);
