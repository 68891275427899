import * as ko from 'knockout';

import { Dimension } from './dimension';
import { CropData } from '../api/crops';
import { NameI18nData } from '../api/names';
import { DimensionMeta } from './dimension_meta';
import { getCropCategorySearchConfig, getMMTraitsSearchConfig } from '../components/configs/search_configs';

export class Crop extends Dimension {
  category = ko.observable<NameI18nData>(null);
  traits = ko.observableArray<NameI18nData>(null).extend({ serverError: true });

  editUrl = ko.pureComputed(() => {
    return '/crops/' + this.id() + '/';
  });

  categorySearch = getCropCategorySearchConfig(this.category);
  traitSearch = getMMTraitsSearchConfig(this.traits);

  constructor(data?: CropData, dimensionMeta?: DimensionMeta) {
    super(data, dimensionMeta);

    if (data) {
      this.category(data.category);
      this.traits(data.traits);
    }
  }

  toData(): CropData {
    let data = <CropData>super.toData();

    data.category = this.category();
    data.traits = this.traits();

    return data;
  }
}
