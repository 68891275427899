import * as ko from 'knockout';

import { app } from '../app';
import i18n from '../i18n';
import { Deferred } from '../utils/deferred';

let template = require('raw-loader!../../templates/components/confirm_dialog.html').default;

class ConfirmDialog {
  title: string;
  text: string[];
  css: string;
  disableCancel: boolean;
  confirmText: string;
  cancelText: string;
  private result: Deferred<{}>;

  constructor(params: {
    title: string;
    text: string | string[];
    css: string;
    disableCancel: boolean;
    confirmText: string;
    cancelText: string;
    result: Deferred<{}>;
  }) {
    this.title = params.title;
    this.text = typeof params.text === 'string' ? [params.text] : params.text;
    this.css = params.css || '';
    this.disableCancel = params.disableCancel;
    this.confirmText = params.confirmText || i18n.t('Confirm')();
    this.cancelText = params.cancelText || i18n.t('Cancel')();
    this.result = params.result;
  }

  onConfirm = () => {
    this.result.resolve(null);
  };

  onCancel = () => {
    this.result.reject();
  };
}

ko.components.register('confirm-dialog', {
  viewModel: ConfirmDialog,
  template: template,
});

export function confirmDialog(
  title: string,
  text: string | string[],
  css?: string,
  disableCancel?: boolean,
  confirmText?: string,
  cancelText?: string
): Promise<{}> {
  return app.formsStackController.push({
    title: title,
    name: 'confirm-dialog',
    params: {
      title,
      text,
      css,
      disableCancel,
      confirmText,
      cancelText,
      result: new Deferred<{}>(),
    },
  });
}
