import * as ko from 'knockout';
export const showNeedPageReload = ko.observable(false);

export const showRetryInSeconds = ko.observable(0);
export function requestWithStatus<ResultType>(
  method: string,
  path: string,
  authToken?: string,
  params?: {},
  timeout?: number
): Promise<{ status: number; result: ResultType; headers: string }> {
  return sendRetrying(
    method,
    params,
    () => makeRequest(method, path, authToken),
    (req) => {
      let result = <ResultType>{};
      if (
        req.status == 200 ||
        req.status == 201 ||
        req.status == 204 ||
        req.status == 400 ||
        req.status == 403 ||
        req.status == 401
      ) {
        try {
          result = JSON.parse(req.responseText);
        } catch (e) {
          result = <ResultType>{};
        }
      }

      return { status: req.status, result: result, headers: req.getAllResponseHeaders() };
    },
    timeout
  );
}

export function requestWithStatusRetry<ResultType>(
  method: string,
  path: string,
  authToken?: string,
  params?: {},
  timeout?: number
): Promise<{ status: number; result: ResultType }> {
  return sendAlwaysRetry(
    method,
    params,
    () => makeRequest(method, path, authToken),
    (req) => {
      let result = <ResultType>{};
      if (
        req.status == 200 ||
        req.status == 201 ||
        req.status == 204 ||
        req.status == 400 ||
        req.status == 403 ||
        req.status == 401
      ) {
        try {
          result = JSON.parse(req.responseText);
        } catch (e) {
          result = <ResultType>{};
        }
      }

      return { status: req.status, result: result };
    },
    timeout
  );
}

export function requestRawWithStatus(
  method: string,
  path: string,
  authToken: string,
  params?: {}
): Promise<{ status: number; data: Blob }> {
  return sendRetrying(
    method,
    params,
    () => makeRequest(method, path, authToken, 'blob'),
    (req) => ({ status: req.status, data: req.response })
  );
}

function sendAlwaysRetry<T>(
  method: string,
  params: {},
  prepareRequest: () => XMLHttpRequest,
  onLoad: (req: XMLHttpRequest) => T,
  timeout?: number
): Promise<T> {
  return new Promise((resolve, reject) => {
    let attempts = 1;

    let send = () => {
      attempts++;

      let req = prepareRequest();
      if (timeout) {
        req.timeout = timeout;
        req.ontimeout = () => {
          let retryMs = 1000 * Math.pow(2, attempts - 2);
          // maximum wait for retry = 15s
          if (retryMs > 15000) {
            retryMs = 15000;
          }
          if (showRetryInSeconds() < retryMs / 1000) {
            showRetryInSeconds(retryMs / 1000);
          }
          setTimeout(send, retryMs);
        };
      }
      req.onload = () => {
        let serverVersion = req.getResponseHeader('X-Version');
        showNeedPageReload(serverVersion && VERSION !== serverVersion && VERSION !== 'development');
        resolve(onLoad(req));
        showRetryInSeconds(0);
      };
      req.onerror = () => {
        let retryMs = 1000 * Math.pow(2, attempts - 2);
        // maximum wait for retry = 15s
        if (retryMs > 15000) {
          retryMs = 15000;
        }
        if (showRetryInSeconds() < retryMs / 1000) {
          showRetryInSeconds(retryMs / 1000);
        }
        setTimeout(send, retryMs);
      };
      if (params) {
        req.send(JSON.stringify(params));
      } else {
        req.send();
      }
    };
    send();
  });
}

function sendRetrying<T>(
  method: string,
  params: {},
  prepareRequest: () => XMLHttpRequest,
  onLoad: (req: XMLHttpRequest) => T,
  timeout?: number
): Promise<T> {
  return new Promise((resolve, reject) => {
    const maxAttempts = method === 'GET' ? 5 : 1;
    let attempts = 1;

    let send = () => {
      attempts++;

      let req = prepareRequest();
      if (timeout) {
        req.timeout = timeout;
        req.ontimeout = () => {
          reject();
        };
      }
      req.onload = () => {
        let serverVersion = req.getResponseHeader('X-Version');
        showNeedPageReload(serverVersion && VERSION !== serverVersion && VERSION !== 'development');
        resolve(onLoad(req));
      };
      req.onerror = () => {
        if (attempts <= maxAttempts) {
          setTimeout(send, 1000 * Math.pow(2, attempts - 2));
        } else {
          reject();
        }
      };
      if (params) {
        req.send(JSON.stringify(params));
      } else {
        req.send();
      }
    };
    send();
  });
}

function makeRequest(
  method: string,
  path: string,
  authToken: string,
  responseType?: XMLHttpRequestResponseType
) {
  let req = new XMLHttpRequest();

  if (responseType) {
    req.responseType = responseType;
  }

  // avoid ajax caching in IE
  if (path.indexOf('?') === -1) {
    path = path + '?';
  }
  const tz = new Intl.DateTimeFormat().resolvedOptions().timeZone ?? 'UTC'; // default to UTC for IE 11
  path = path + '&tz=' + tz + '&cache=' + new Date().getTime() + '&version=' + VERSION;

  req.open(method, path);
  req.setRequestHeader('Content-Type', 'application/json');
  req.setRequestHeader('Accept', 'application/json');
  if (authToken) {
    req.setRequestHeader('Authorization', authToken);
  }
  if (localStorage.getItem('userLanguage') !== null) {
    req.setRequestHeader('X-User-Language', localStorage.getItem('userLanguage'));
  }

  return req;
}
