import { makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';

export interface AttributeChoiceData {
  id: string;
  code: string;
  name_json: I18nText;
}

export interface AttributeChoiceListData {
  id?: string;
  name_json: I18nText;
  choices?: AttributeChoiceData[];
}

export const { list, retrieve, save, remove } =
  makeDefaultApi<AttributeChoiceListData>('attribute_choice_lists');
