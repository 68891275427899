import * as ko from 'knockout';

import * as usersApi from '../api/users';

let template = require('raw-loader!../../templates/change_password.html').default;

class ChangePasswordScreen {
  private firebaseTenantId: string;
  private resetCode: string; // Firebase-generated password reset code

  saving = ko.observable(false);
  newPassword = ko.observable('').extend({ required: true, serverError: true });
  private errors = ko.validation.group([this.newPassword], { deep: true });

  constructor(params: { oobCode: string; tenantId: string }) {
    this.firebaseTenantId = params.tenantId;
    this.resetCode = params.oobCode;
  }

  save = async () => {
    if (this.errors().length > 0) {
      this.errors.showAllMessages();
      return;
    }

    this.newPassword.serverError('');
    this.saving(true);
    try {
      const result = await usersApi.changePassword(
        this.firebaseTenantId,
        this.resetCode,
        this.newPassword()
      );
      if (result.isValid) {
        location.href = '/'; // reload
      } else {
        this.newPassword.serverError((result.errors['new_password'] ?? []).join(', '));
      }
    } finally {
      this.saving(false);
    }
  };
}

export let changePasswordScreen = {
  name: 'change-password',
  viewModel: ChangePasswordScreen,
  template: template,
};

ko.components.register(changePasswordScreen.name, changePasswordScreen);
