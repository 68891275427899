import * as ko from 'knockout';

import { UserData } from '../api/users';
import { GroupData, BaseGroupData } from '../api/groups';
import * as trialsApi from '../api/trials';
import { DataEntryCollection } from './data_entry_permission';
import { slugValidation, SlugGenerator } from '../ko_bindings/slug_validation';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { TrialSelectData } from '../api/base_trials';

export class Group {
  private slugGenerator: SlugGenerator;

  simplePermissions = SERVER_INFO.SIMPLE_PERMISSIONS;

  id = ko.observable<string>(null);
  name = ko.observable('').extend({
    required: true,
    serverError: true,
  });
  users = ko.observableArray<UserData>();
  managers = ko.observableArray<UserData>();
  dataEntryPermissions = new DataEntryCollection({
    allowSelect: 'trial',
    trial: null,
  });
  editableTrials = ko.observableArray<TrialSelectData>();
  nameSlug = ko.observable('').extend(slugValidation);

  editUrl = ko.pureComputed(() => {
    return '/groups/' + this.id();
  });

  editableTrialSearchConfig: FormSelectSearchConfiguration<TrialSelectData> = {
    getSummaryName: (trial) => {
      return trial.name_json;
    },

    list: (params) => {
      return trialsApi.listForSelect({
        template: undefined,
        crop_ids: [],
        trial_type_ids: [],
        ...params,
      });
    },

    entities: this.editableTrials,
  };

  constructor(public canEditEditableTrials: boolean, data?: GroupData | BaseGroupData) {
    if (data) {
      let groupData = <GroupData>data;

      this.id(groupData.id);
      this.name(groupData.name);
      this.users(groupData.users);
      this.managers(groupData.managers);
      if (groupData.data_entry_permissions) {
        this.dataEntryPermissions.initFrom(groupData.data_entry_permissions);
      }
      if (groupData.editable_trials) {
        this.editableTrials(groupData.editable_trials);
      }
      this.nameSlug(groupData.name_slug);
    }

    this.slugGenerator = new SlugGenerator(this.name, null, this.nameSlug, {
      canEdit: true,
      fillIfEmpty: true,
    });
  }

  dispose() {
    this.slugGenerator.dispose();
  }

  toData(): GroupData {
    return {
      id: this.id(),
      name: this.name(),
      users: this.users(),
      managers: this.managers(),
      data_entry_permissions: this.dataEntryPermissions.toData(),
      name_slug: this.nameSlug(),
      editable_trials: this.editableTrials(),
    };
  }
}
