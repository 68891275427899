import { PlotListData, ImportedPlotLayout } from '../../../api/datasets';
import { WalkOrder, StartingCorner } from '../../../api/trials';
import { TrialWizard } from '../../../models/trial';
import i18n from '../../../i18n';
import { flatten, pad } from '../../../utils';
import { translate } from '../../../i18n_text';
import { DatasetDimensionMeta } from '../../../models/dataset_dimension_meta';

export interface SitePlotData {
  id: string;
  name: string;
  customName: boolean;
  externalId: string;
  referenceDimId: string;
  dims: number[];
  colorIdx: number;
  number: number;
  excluded: boolean;
  treatmentId: number | null;
  length?: number;
  column?: number;
  row?: number;
  dimensionNames?: string;
  treatmentName?: string;
}

export type TrialAttributeMeta = {
  ddmId: string;
  metaId: string;
  name: string;
  id: string;
};
export type TrialAttributeValue = {
  attrId: string;
  limitId: string;
  metaId: string;
  name: string;
  value: number | string | null | {};
};

export class PlotsEditModel {
  private sites = new Map<string, PlotsSiteEditModel>();
  private defaultNamePromise: Promise<Map<string, string>> = null;

  needsSave = false;
  constructor(private data: PlotListData, private wizard: TrialWizard, needsSave: boolean) {
    let siteDmIdx = -1;
    let repDmIdx = -1;
    const siteDmId = wizard.siteDM.id();
    const repDmId = wizard.replicationDM.id();
    data.dms.forEach((dm, idx) => {
      if (dm.id === repDmId) {
        repDmIdx = idx;
      } else if (dm.id === siteDmId) {
        siteDmIdx = idx;
      }
    });

    const blockWidth = wizard.blockWidth();
    const blockHeight = wizard.blockHeight();

    const sites = wizard.sites().slice();
    sites.sort((s1, s2) => translate(s1.nameJson()).localeCompare(translate(s2.nameJson())));
    for (let site of sites) {
      this.sites.set(
        site.id(),
        new PlotsSiteEditModel(
          site.id(),
          data.dms,
          data.dims,
          wizard,
          repDmIdx,
          siteDmIdx,
          blockWidth,
          blockHeight,
          this
        )
      );
    }
    if (wizard.sites().length === 0) {
      this.sites.set(
        '',
        new PlotsSiteEditModel(
          '',
          data.dms,
          data.dims,
          wizard,
          repDmIdx,
          siteDmIdx,
          blockWidth,
          blockHeight,
          this
        )
      );
    }

    for (let i = 0; i < data.plots.id.length; i++) {
      let siteId =
        siteDmIdx === -1 ? '' : data.dims[siteDmIdx][data.plots.dimensions_index[i][siteDmIdx]].id;
      this.sites.get(siteId)?.add(data, i);
    }

    this.needsSave = needsSave;
  }

  emptyData(): PlotListData {
    return {
      dms: this.data.dms,
      dims: this.data.dims,
      treatments: this.data.treatments,
      plots: {
        id: [],
        name: [],
        custom_name: [],
        external_id: [],
        number: [],
        length: [],
        internal_number: [],
        excluded: [],
        treatment_id: [],
        reference_dim_id: [],
        dimensions_index: [],
        column_position: [],
        range_position: [],
      },
    };
  }

  toData(): PlotListData {
    const res = this.emptyData();
    this.sites.forEach((val) => val.toData(res));
    return res;
  }

  site(siteId: string): PlotsSiteEditModel {
    return this.sites.get(siteId);
  }

  countPlanted(): number {
    let res = 0;
    this.sites.forEach((val) => (res += val.nPlots() - val.countExcluded()));
    return res;
  }

  countTotal(): number {
    let res = 0;
    this.sites.forEach((val) => (res += val.nPlots()));
    return res;
  }

  removeAll() {
    this.sites.forEach((val) => val.removeAll());
  }

  setWalkingOrder(startingCorner: StartingCorner, walkOrder: WalkOrder) {
    this.sites.forEach((val) => val.setWalkingOrder(startingCorner, walkOrder));
  }

  copy(fromSiteId: string, toSiteId: string) {
    this.sites.get(toSiteId).copyFrom(this.sites.get(fromSiteId));
  }

  applyImport(data: ImportedPlotLayout) {
    this.sites.forEach((siteModel) => siteModel.applyImport(data));
  }

  validateImportMissing(data: ImportedPlotLayout) {
    let errors: string[] = [];
    this.sites.forEach((siteModel) => siteModel.validateImportMissing(data, errors));
    return errors;
  }

  updateOrderDependencies() {
    let offset = 0;
    // note that Map preserves the insertion order
    this.sites.forEach((val) => {
      offset = val.updateNumbers(offset);
      val.updateNames();
    });
  }

  getDefaultNames(): Promise<Map<string, string>> {
    if (!this.defaultNamePromise) {
      this.defaultNamePromise = this._getDefaultNames();
    }

    return this.defaultNamePromise;
  }

  private async _getDefaultNames(): Promise<Map<string, string>> {
    const pList = (await this.wizard.generateFreshPlotsRequest()).plots;
    const defaultNames = new Map();
    for (let i = 0; i < pList.plots.name.length; i++) {
      const dimIds = pList.plots.dimensions_index[i].map((dimIdx, dmIdx) => pList.dims[dmIdx][dimIdx].id);
      defaultNames.set(plotDefaultNameKey(dimIds), pList.plots.name[i]);
    }

    return defaultNames;
  }
}

export class PlotsSiteEditModel {
  grid: SitePlotData[][] = [];
  private excluded: SitePlotData[] = [];
  private sorted: SitePlotData[] = [];

  private _nCols = 1;
  private _nRows = 1;
  private _maxRows = 0;
  // these are shared
  private walkOrder: WalkOrder;
  private startingCorner: StartingCorner;
  plotDesign: string;
  private editingName: string;
  private editing: SitePlotData = null;

  private loadingNames = new Set<SitePlotData>();

  drag = new PlotsSiteGridDrag();
  sortPlotsOnAddBy = ko.observableArray([]);
  datasetDimensionsMeta: DatasetDimensionMeta[] = [];

  treatmentNameGroupedByDimensionIds: Map<string, string>;

  constructor(
    public readonly siteId: string,
    private dms: { id: string; name: string }[],
    private dims: { id: string; name: string }[][],
    public readonly wizard: TrialWizard,
    private repDmIdx: number,
    private siteDmIdx: number,
    private blockWidth: number,
    private blockHeight: number,
    private model: PlotsEditModel
  ) {
    this.walkOrder = wizard.trial().walkOrder();
    this.startingCorner = wizard.trial().startingCorner();
    this.plotDesign = wizard.trial().plotDesign();
    this.datasetDimensionsMeta = wizard.testSubjects();
    this.treatmentNameGroupedByDimensionIds = this.getTreatmentNameGroupedByDimensionIds();
  }

  copyFrom(other: PlotsSiteEditModel) {
    if (this.siteDmIdx === -1) {
      return;
    }

    const plotToIdx = new Map<SitePlotData, number>();
    other.sorted.forEach((plot, idx) => plotToIdx.set(plot, idx));

    this.sorted = other.sorted.map((plot, idx) => ({
      ...plot,
      id: this.sorted[idx]?.id ?? null,
      dims: plot.dims.slice(),
    }));
    this.excluded = other.excluded.map((plot) => (plot ? this.sorted[plotToIdx.get(plot)] : null));
    this.grid = other.grid.map((col) => col.map((plot) => (plot ? this.sorted[plotToIdx.get(plot)] : null)));

    // update plot.dims to reflect the new site
    let siteDimIdx = -1;
    let i = 0;
    for (let site of this.dims[this.siteDmIdx]) {
      if (site.id === this.siteId) {
        siteDimIdx = i;
      }
      i++;
    }

    for (let plot of this.sorted) {
      plot.dims[this.siteDmIdx] = siteDimIdx;
    }

    this._nCols = other._nCols;
    this._nRows = other._nRows;

    this.finishSwaps();
  }

  add(data: PlotListData, idx: number) {
    if (this._maxRows == 0) {
      this._maxRows = Math.max(this._nRows, Math.max.apply(null, data.plots.range_position) - 1);
    }
    const dims = this.getPlotDimensionIndexes(data, idx);
    let plot = {
      id: data.plots.id[idx],
      name: data.plots.name[idx],
      treatmentId: data.plots.treatment_id[idx],
      excluded: data.plots.excluded[idx],
      customName: data.plots.custom_name[idx],
      externalId: data.plots.external_id[idx],
      number: data.plots.number[idx],
      length: data.plots.length[idx],
      referenceDimId: data.plots.reference_dim_id[idx],
      dims,
      colorIdx: this.getColorIdx(dims, data),
      treatmentName: this.getTreatmentName(dims),
    };
    this.sorted.push(plot);
    if (plot.excluded) {
      this.excluded.push(plot);
    } else {
      let col = data.plots.column_position[idx] - 1;
      let row = data.plots.range_position[idx] - 1;

      this.setPlot(plot, col, row);

      this._nCols = Math.max(this._nCols, col + 1);
      this._nRows = Math.max(this._nRows, row + 1);
      if (row + 1 === this._maxRows && plot.length > 1) {
        this._nRows = Math.max(this._nRows, row + plot.length);
      }
    }

    this.needsSave();
  }

  private getPlotDimensionIndexes(data: PlotListData, idx: number) {
    return data.plots.dimensions_index[idx].slice();
  }

  private getColorIdx(dims: number[], data: PlotListData) {
    let colorIdx = 0;
    let m = 1;
    for (let dmIdx = 0; dmIdx < dims.length; dmIdx++) {
      if ((this.plotDesign !== 'crd' && dmIdx === this.repDmIdx) || dmIdx === this.siteDmIdx) {
        continue;
      }

      colorIdx += dims[dmIdx] * m;
      m *= data.dims[dmIdx].length;
    }
    return colorIdx;
  }

  private getTreatmentName(dims: number[]) {
    const dimensionIds = this.dms
      .map((_, dmIdx) => dmIdx)
      .filter((dmIdx) => dmIdx !== this.siteDmIdx && dmIdx !== this.repDmIdx)
      .map((dmIdx) => this.dims[dmIdx][dims[dmIdx]].id)
      .sort();
    return this.treatmentNameGroupedByDimensionIds.get(JSON.stringify(dimensionIds));
  }

  toData(into: PlotListData) {
    const byPlot = new Map<SitePlotData, { col: number; row: number }>();

    this.grid.forEach((col, colIdx) => {
      if (!col) {
        return;
      }

      col.forEach((plot, rowIdx) => {
        if (!plot) {
          return;
        }

        byPlot.set(plot, { col: colIdx + 1, row: rowIdx + 1 });
      });
    });

    for (let plot of this.sorted) {
      into.plots.id.push(plot.id);
      into.plots.name.push(plot.name);
      into.plots.treatment_id.push(plot.treatmentId);
      into.plots.custom_name.push(plot.customName);
      into.plots.external_id.push(plot.externalId);
      into.plots.number.push(plot.number);
      into.plots.length.push(plot.length);
      into.plots.internal_number.push(into.plots.id.length);
      into.plots.excluded.push(plot.excluded);
      into.plots.reference_dim_id.push(plot.referenceDimId);
      into.plots.dimensions_index.push(plot.dims);
      into.plots.column_position.push(byPlot.get(plot)?.col ?? -1);
      into.plots.range_position.push(byPlot.get(plot)?.row ?? -1);
    }
  }

  dmExSite() {
    return this.dms
      .map((dm, idx) => ({ id: dm.id, name: dm.name, idx }))
      .filter((dm) => dm.idx !== this.siteDmIdx);
  }

  trialAttributeMetas(): TrialAttributeMeta[] {
    const attributeMetas = this.datasetDimensionsMeta.map((ddm) =>
      ddm
        .dimensionMeta()
        .attributeMetas()
        .map((attributeMeta) => {
          return {
            ddmId: ddm.id(),
            metaId: ddm.dimensionMeta().id(),
            name: translate(attributeMeta.nameJson()),
            id: `attr_${attributeMeta.id()}`,
          };
        })
    );
    return flatten(attributeMetas);
  }

  trialAttributeValues() {
    let attributes: TrialAttributeValue[] = [];
    this.datasetDimensionsMeta.forEach((meta) => {
      meta.limitTo().forEach((limit) => {
        limit.attributes().forEach((attr) => {
          attributes.push({
            attrId: `attr_${attr.id}`,
            limitId: limit.id(),
            metaId: limit.dimensionMetaId(),
            name: translate(attr.nameJson()),
            value: attr.value(),
          });
        });
      });
    });
    return attributes;
  }

  attributeValuesByPlot(plot: SitePlotData) {
    const plotAttributeIds = this.plotAttributeIds(plot);
    let attributeValues: Record<string, string | number | {} | null> = {};
    plotAttributeIds.forEach((id) => {
      this.trialAttributeValues()
        .filter((value) => value.limitId === id)
        .forEach((attr) => {
          attributeValues[attr.attrId] = attr.value;
        });
    });
    return attributeValues;
  }
  nCols() {
    return this._nCols;
  }

  nRows() {
    return this._nRows;
  }

  nExcluded() {
    // also counts empty spots necessary to support drag-drop
    return this.excluded.length;
  }

  countExcluded() {
    // only counts actual plots
    return this.excluded.reduce((acc, plot) => (plot ? acc + 1 : acc), 0);
  }

  nPlots() {
    return this.sorted.length;
  }

  vSplit(row: number): boolean {
    return row >= 0 && (row + 1) % this.blockHeight === 0;
  }

  hSplit(col: number): boolean {
    return col >= 0 && (col + 1) % this.blockWidth === 0;
  }

  plot(col: number, row: number): SitePlotData {
    return (this.grid[col] || [])[row];
  }

  getCoordinates(plot: SitePlotData): { colIdx: number | null; rowIdx: number | null } {
    let res: { colIdx: number | null; rowIdx: number | null } = { colIdx: null, rowIdx: null };

    for (let colIdx = 0; colIdx < this.nCols(); colIdx++) {
      for (let rowIdx = 0; rowIdx < this.nRows(); rowIdx++) {
        if (typeof this.grid[colIdx] !== 'undefined' && typeof this.grid[colIdx][rowIdx] !== 'undefined') {
          if (this.grid[colIdx][rowIdx].id === plot.id) return { colIdx, rowIdx };
        }
      }
    }

    return res;
  }

  isCellFree(colIdx: number, rowIdx: number) {
    return typeof this.grid[colIdx][rowIdx] === 'undefined';
  }

  plotShrinkable(plot: SitePlotData): boolean {
    if (plot.excluded) return false;
    if (plot.length === 1) return false;
    return true;
  }

  plotExpandable(plot: SitePlotData): boolean {
    if (plot.excluded) return false;
    const { colIdx, rowIdx } = this.getCoordinates(plot);
    if (rowIdx + plot.length >= this.nRows()) return false;

    if (typeof this.grid[colIdx][rowIdx + 1] === 'undefined') {
      return this.isCellFree(colIdx, rowIdx + plot.length);
    }

    return false;
  }

  needsSave = () => {
    this.model.needsSave = true;
  };

  private setPlot(plot: SitePlotData, col: number, row: number) {
    this.grid[col] = this.grid[col] || [];
    this.grid[col][row] = plot;
  }

  excludedPlot(idx: number) {
    return this.excluded[idx];
  }

  sortedPlot(idx: number) {
    return this.sorted[idx];
  }

  canRemove(): boolean {
    return this.plotDesign !== 'rcb' || APP_CONFIG.CAN_REMOVE_RCBD_PLOT;
  }

  isEditing(plot: SitePlotData): boolean {
    return this.editing === plot;
  }

  startEditing(plot: SitePlotData, onLoadedNames: () => void) {
    this.stopEditing(onLoadedNames);
    this.editingName = this.plotName(plot);
    this.editing = plot;
  }

  updateEditing(name: string) {
    this.editingName = name;
  }

  stopEditing(onLoadedNames: () => void) {
    if (this.editing && this.editingName !== this.plotName(this.editing)) {
      if (this.editingName.trim() === '') {
        this.editing.name = '';
        this.editing.customName = false;

        const trial = this.wizard.trial();
        const naming = trial.plotNaming();

        if (naming === 'default' && !trial.hasCustomerDesign()) {
          const plot = this.editing;
          const nameKey = plotDefaultNameKey(this.dimensionIds(plot));

          this.loadingNames.add(plot);
          this.model.getDefaultNames().then((names) => {
            plot.name = names.get(nameKey) ?? '';
            this.loadingNames.delete(plot);
            onLoadedNames();
          });
        } else {
          this.updateNames();
        }
      } else {
        this.editing.name = this.editingName;
        this.editing.customName = true;
      }

      this.needsSave();
    }
    this.editingName = '';
    this.editing = null;
  }

  setExternalId(plot: SitePlotData, externalId: string) {
    if (plot.externalId !== externalId) {
      plot.externalId = externalId;
      this.needsSave();
    }
  }

  setExcluded(plot: SitePlotData) {
    if (plot) {
      this.excluded.push(plot);
      plot.excluded = true;
      plot.length = 1;
      this.needsSave();
      this.finishSwaps();
    }
  }

  setName(plot: SitePlotData, name: string) {
    if (plot.name !== name) {
      plot.name = name;
      if (plot.name.trim() !== '') plot.customName = true;
      this.needsSave();
    }
  }

  isLoadingName(plot: SitePlotData) {
    return this.loadingNames.has(plot);
  }

  plotNameBeingEdited(): string {
    return this.editingName;
  }

  plotName(plot: SitePlotData): string {
    return plot.name || plot.number.toString();
  }

  dimensionNamesExSite(plot: SitePlotData, separator = ', '): string {
    let parts: string[] = [];
    for (let dmIdx = 0; dmIdx < this.dms.length; dmIdx++) {
      if (dmIdx !== this.siteDmIdx) {
        parts.push(this.dimensionName(plot, dmIdx));
      }
    }

    return parts.join(separator);
  }

  treatmentName(plot: SitePlotData): string {
    return plot.treatmentName || '';
  }

  getTreatmentNameGroupedByDimensionIds(): Map<string, string> {
    const treatmentMap = new Map<string, string>();

    this.wizard.treatments().forEach((treatment) => {
      const dimensionIds = treatment.factors().map((factor) => factor.dimension().id());
      const dimensionIdsKey = JSON.stringify(dimensionIds.sort());
      treatmentMap.set(dimensionIdsKey, translate(treatment.nameJson()));
    });

    return treatmentMap;
  }

  dimensionsByPlot(plot: SitePlotData) {
    let dimensions: Record<string, string> = {};
    for (let dmIdx = 0; dmIdx < this.dms.length; dmIdx++) {
      if (dmIdx !== this.siteDmIdx) {
        const dimensionId = this.dms[dmIdx].id;
        dimensions[dimensionId] = this.dimensionName(plot, dmIdx);
      }
    }
    return dimensions;
  }

  plotAttributeIds(plot: SitePlotData) {
    let attributeIds: string[] = [];
    for (let dmIdx = 0; dmIdx < this.dms.length; dmIdx++) {
      if (dmIdx !== this.siteDmIdx) {
        attributeIds.push(this.attributeId(plot, dmIdx));
      }
    }
    return attributeIds;
  }

  private dimensionIds(plot: SitePlotData): string[] {
    let ids: string[] = [];
    for (let dmIdx = 0; dmIdx < this.dms.length; dmIdx++) {
      ids.push(this.dims[dmIdx][plot.dims[dmIdx]].id);
    }

    return ids;
  }

  dimensionNames(plot: SitePlotData): string {
    let parts: string[] = [];
    for (let dmIdx = 0; dmIdx < this.dms.length; dmIdx++) {
      parts.push(this.dimensionName(plot, dmIdx));
    }

    return parts.join(', ');
  }

  dimensionName(plot: SitePlotData, dmIdx: number): string {
    return this.dims[dmIdx][plot.dims[dmIdx]].name;
  }

  attributeId(plot: SitePlotData, dmIdx: number): string {
    return this.dims[dmIdx][plot.dims[dmIdx]].id;
  }

  dimensionId(plot: SitePlotData, dmIdx: number): string {
    return this.dims[dmIdx][plot.dims[dmIdx]].id;
  }

  setGridSize(nCols: number, nRows: number) {
    this._nCols = nCols;
    this._nRows = nRows;

    this.grid.forEach((col, colIdx) => {
      col.forEach((plot, rowIdx) => {
        if (plot && (colIdx >= nCols || rowIdx >= nRows)) {
          this.excluded.push(plot);
          plot.excluded = true;
          plot.length = 1;
        }
      });

      if (nRows < col.length) {
        col.splice(nRows, col.length - nRows);
      }
    });
    if (nCols < this.grid.length) {
      this.grid.splice(nCols, this.grid.length - nCols);
    }

    this.finishSwaps();
  }

  setWalkingOrder(startingCorner: StartingCorner, walkOrder: WalkOrder) {
    this.wizard.customPlotNumbers(false);
    this.startingCorner = startingCorner;
    this.walkOrder = walkOrder;

    this.sorted = [];

    let nColumns = this.nCols();
    let nRanges = this.nRows();

    let iStart = 0;
    let jStart = 0;
    let iEnd = nColumns - 1;
    let jEnd = nRanges - 1;
    let iDir = 1;
    let jDir = 1;

    if (startingCorner === 'bottom_left' || startingCorner === 'bottom_right') {
      jStart = nRanges - 1;
      jEnd = 0;
      jDir = -1;
    }
    if (startingCorner === 'top_right' || startingCorner === 'bottom_right') {
      iStart = nColumns - 1;
      iEnd = 0;
      iDir = -1;
    }

    let i = iStart;
    let j = jStart;
    while (i < nColumns && j < nRanges && i >= 0 && j >= 0) {
      let plot = this.plot(i, j);
      if (plot) {
        this.sorted.push(plot);
      }

      if (walkOrder === 'left_right') {
        i += iDir;
        if (i >= nColumns || i < 0) {
          i = iStart;
          j += jDir;
        }
      } else if (walkOrder === 'top_down') {
        j += jDir;
        if (j >= nRanges || j < 0) {
          j = jStart;
          i += iDir;
        }
      } else if (walkOrder === 'zig_zag_horizontal') {
        if (j % 2 === jStart % 2) {
          i += iDir;
          if (i >= nColumns || i < 0) {
            i = iEnd;
            j += jDir;
          }
        } else {
          i -= iDir;
          if (i < 0 || i >= nColumns) {
            i = iStart;
            j += jDir;
          }
        }
      } else if (walkOrder === 'zig_zag_vertical') {
        if (i % 2 === iStart % 2) {
          j += jDir;
          if (j >= nRanges || j < 0) {
            j = jEnd;
            i += iDir;
          }
        } else {
          j -= jDir;
          if (j < 0 || j >= nRanges) {
            j = jStart;
            i += iDir;
          }
        }
      } else if (walkOrder === '2rows_horizontal') {
        if (j % 4 === jStart % 4) {
          j += jDir;
          if (j >= nRanges || j < 0) {
            j = jEnd;
            i += iDir;
          }
        } else if (j % 4 === (jStart + jDir) % 4) {
          i += iDir;
          if (i >= nColumns || i < 0) {
            i = iEnd;
            j += jDir;
          } else {
            j -= jDir;
          }
        } else if (j % 4 === (jStart + 2 * jDir) % 4) {
          j += jDir;
          if (j >= nRanges || j < 0) {
            j = jEnd;
            i -= iDir;
          }
        } else {
          i -= iDir;
          if (i >= nColumns || i < 0) {
            i = iStart;
            j += jDir;
          } else {
            j -= jDir;
          }
        }
      }
    }

    for (let plot of this.excluded) {
      if (plot) {
        this.sorted.push(plot);
      }
    }

    this.model.updateOrderDependencies();

    this.needsSave();
  }

  updateNumbers(offset: number): number {
    let n = offset;
    for (let plot of this.sorted) {
      if (!plot.excluded) {
        plot.number = ++n;
      }
    }

    this.needsSave();

    return n;
  }

  updateNames() {
    // make sure this is kept up-to-date with its server-side counterpart
    const trial = this.wizard.trial();
    const naming = trial.plotNaming();
    if (naming === 'default' || trial.hasCustomerDesign()) {
      return;
    }
    const mirrorRange = trial.designSupportsMirroring() && trial.rangeDirection() === 'bottom_up';

    const countDigits = (n: number) => Math.floor(Math.log10(Math.max(1, n))) + 1;
    const getCode = (index: number, nDigits: number) => pad(index + 1, nDigits);

    if (naming === 'walking_order') {
      let plotNumber = 1;
      for (let plot of this.sorted) {
        if (!plot.customName) {
          plot.name = plotNumber.toString();
          plotNumber += 1;
        }
      }
    } else if (naming === 'coordinates') {
      let rowDigits = countDigits(this._nRows);
      let colDigits = countDigits(this._nCols);
      this.grid.forEach((col, colIdx) => {
        col.forEach((plot, rowIdx) => {
          if (!plot || plot.customName) {
            return;
          }

          let rowCode = getCode(mirrorRange ? this._nRows - rowIdx - 1 : rowIdx, rowDigits);
          let colCode = getCode(colIdx, colDigits);
          plot.name = `${rowCode}0${colCode}`;
        });
      });
    } else if (naming === 'sequential_in_replication') {
      let nReps = parseInt(this.wizard.replications().toString(), 10);
      if (isNaN(nReps)) {
        return;
      }
      let plotsWithRep: {
        plot: SitePlotData;
        col: number;
        row: number;
        rep: number;
      }[] = [];
      this.grid.forEach((col, colIdx) => {
        col.forEach((plot, rowIdx) => {
          if (plot) {
            let rep = parseInt(this.dimensionName(plot, this.repDmIdx), 10) || 1;
            plotsWithRep.push({ plot, col: colIdx, row: rowIdx, rep });
          }
        });
      });
      plotsWithRep.sort((a, b) => {
        if (a.rep !== b.rep) {
          return a.rep - b.rep;
        }
        if (a.row !== b.row) {
          return (mirrorRange ? -1 : 1) * (a.row - b.row);
        }
        return a.col - b.col;
      });
      let counter = new Map<number, number>();
      for (let { rep } of plotsWithRep) {
        counter.set(rep, (counter.get(rep) || 0) + 1);
      }
      let plotsPerRep = 1;
      counter.forEach((value) => {
        plotsPerRep = Math.max(value, plotsPerRep);
      });

      let repDigits = countDigits(nReps);
      let plotDigits = countDigits(plotsPerRep);

      let posInRep = 0;
      let prevRep = -1;
      for (let { plot, rep } of plotsWithRep) {
        if (prevRep !== rep) {
          posInRep = 0;
          prevRep = rep;
        }
        posInRep += 1;

        if (plot.customName) {
          continue;
        }

        let repCode = getCode(rep - 1, repDigits);
        let plotCode = getCode(posInRep - 1, plotDigits);
        plot.name = `${repCode}0${plotCode}`;
      }
    }

    this.needsSave();
  }

  movePlotOrder(oldIndex: number, newIndex: number) {
    this.wizard.customPlotNumbers(true);

    let [plot] = this.sorted.splice(oldIndex, 1);
    this.sorted.splice(newIndex, 0, plot);

    this.model.updateOrderDependencies();

    this.needsSave();
  }

  swap(colIdx1: number, rowIdx1: number, colIdx2: number, rowIdx2: number) {
    if (colIdx1 === colIdx2 && rowIdx1 === rowIdx2) {
      return;
    }

    let tmp = this.plot(colIdx1, rowIdx1);
    this.setPlot(this.plot(colIdx2, rowIdx2), colIdx1, rowIdx1);
    this.setPlot(tmp, colIdx2, rowIdx2);
    this.finishSwaps();
  }

  // afterColIdx can be -1 to indicate first position
  moveColumn(colIdx: number, afterColIdx: number) {
    this.move(this.grid, colIdx, afterColIdx);
    this.finishSwaps();
  }

  // afterRowIdx can be -1 to indicate first position
  moveRow(rowIdx: number, afterRowIdx: number) {
    for (let colIdx = 0; colIdx < this._nCols; colIdx++) {
      this.move(this.grid[colIdx], rowIdx, afterRowIdx);
    }
    this.finishSwaps();
  }

  private move<T>(items: T[], idx: number, afterIdx: number) {
    // moves while taking into account partial arrays
    if (!items || items.length === 0) {
      // nothing to do
      return;
    }

    const addAtIdx = idx > afterIdx ? afterIdx + 1 : afterIdx;
    const item = items.splice(idx, 1)[0];
    if (addAtIdx >= items.length) {
      items[addAtIdx] = item;
    } else {
      items.splice(addAtIdx, 0, item);
    }

    this.needsSave();
  }

  swapRemoved(excludedIdx: number, col: number, row: number) {
    this.swapRemovedItem(excludedIdx, col, row);
    this.finishSwaps();
  }

  swapRemovedPlot(plot: SitePlotData, col: number, row: number) {
    let idx = this.excluded.map((item) => item?.id).indexOf(plot?.id);
    if (idx >= 0) {
      this.swapRemoved(idx, col, row);
    }
  }

  private swapRemovedItem(excludedIdx: number, col: number, row: number) {
    let toRemove = this.plot(col, row);

    let [toAdd] = this.excluded.splice(excludedIdx, 1);
    this.setPlot(toAdd, col, row);
    if (toAdd) {
      toAdd.excluded = false;
    }

    this.excluded.splice(excludedIdx, 0, toRemove);
    if (toRemove) {
      toRemove.excluded = true;
      toRemove.length = 1;
    }
  }

  private finishSwaps() {
    if (this.wizard.customPlotNumbers()) {
      this.model.updateOrderDependencies();
    } else {
      this.setWalkingOrder(this.startingCorner, this.walkOrder);
    }
    this.wizard.customPlotPosition(true);

    this.needsSave();
  }

  removeAll() {
    this.grid = [];
    this.excluded = this.sorted.slice();
    for (let plot of this.sorted) {
      plot.excluded = true;
      plot.length = 1;
    }

    this.needsSave();
  }

  applyImport(data: ImportedPlotLayout) {
    this._nCols = this._nRows = 1;

    this.excluded = [];
    this.grid = [];
    for (let plot of this.sorted) {
      let imported = data[plot.id];

      // set custom names
      if (imported && imported.name) {
        plot.name = imported.name;
        plot.customName = true;
      }

      plot.excluded = !imported;
      if (plot.excluded) {
        this.excluded.push(plot);
      } else {
        this._nCols = Math.max(this._nCols, imported.column_position);
        this._nRows = Math.max(this._nRows, imported.range_position);

        this.setPlot(plot, imported.column_position - 1, imported.range_position - 1);
      }
    }

    this.finishSwaps();
  }

  validateImportMissing(data: ImportedPlotLayout, errors: string[]) {
    for (let plot of this.sorted) {
      if (!data[plot.id]) {
        errors.push(
          i18n.t('Missing plot: {{plot}}', {
            plot: this.dimensionNames(plot),
          })()
        );
      }
    }
  }
}

function plotDefaultNameKey(dimIds: string[]): string {
  const parts = dimIds.slice();
  parts.sort();

  return parts.join(',');
}

class PlotsSiteGridDrag {
  private sourceCol: number | null = null;
  private sourceRow: number | null = null;
  private targetCol: number | null = null;
  private targetRow: number | null = null;

  isDragging(params: { col?: number; row?: number }) {
    return (
      (this.sourceCol !== null && params.col === this.sourceCol) ||
      (this.sourceRow !== null && params.row === this.sourceRow)
    );
  }

  isTarget(params: { col?: number; row?: number }) {
    return (
      (this.sourceCol !== null && this.targetCol !== null && params.col === this.targetCol) ||
      (this.sourceRow !== null && this.targetRow !== null && params.row === this.targetRow)
    );
  }

  start(params: { col?: number; row?: number }) {
    this.targetCol = this.sourceCol = params.col ?? null;
    this.targetRow = this.sourceRow = params.row ?? null;
  }

  // col, row can be -1
  over(params: { col?: number; row?: number }) {
    const willChange =
      (this.sourceCol !== null && params.col !== this.targetCol) ||
      (this.sourceRow !== null && params.row !== this.targetRow);
    this.targetCol = params.col ?? null;
    this.targetRow = params.row ?? null;

    return willChange;
  }

  end(edit: PlotsSiteEditModel) {
    if (this.sourceCol !== null && this.targetCol !== null && this.sourceCol !== this.targetCol) {
      edit.moveColumn(this.sourceCol, this.targetCol);
    }
    if (this.sourceRow !== null && this.targetRow !== null && this.sourceRow !== this.targetRow) {
      edit.moveRow(this.sourceRow, this.targetRow);
    }
    this.sourceCol = this.sourceRow = this.targetCol = this.targetRow = null;
  }

  cancel() {
    this.sourceCol = this.sourceRow = this.targetCol = this.targetRow = null;
  }
}
