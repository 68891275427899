import * as ko from 'knockout';
import i18n from '../../i18n';
import { I18nText, translate } from '../../i18n_text';
import { DatasetDimensionMeta, LimitToDimension } from '../../models/dataset_dimension_meta';
import { Treatment, TreatmentFactor } from '../../models/treatment';
import { CustomLayoutData, TreatmentFactorData, downloadCustomLayoutImportTemplate } from '../../api/trials';
import { WizardController } from '../../screens/trial_wizard';
import { Options } from '../../components/more_dropdown_menu';
import { confirmDialog } from '../../components/confirm_dialog';
import { BaseTrialStep } from './base';
import 'lodash.product';
import { asI18nText } from '../../i18n_text';
import { AnonymizeOptions } from '../../models/dataset_dimension_meta';
import { openTreatmentEdit } from './treatment_edit_modal';
import { sortBy, sum, product, isEqual } from 'lodash';
import { encodeArrayBufferToBase64, trimObjectValues } from '../../utils';
import { CUSTOM_LAYOUT_VALID_PLOT_DESIGNS } from '../../models/trial';
import { TrialState } from '../../models/TrialState';

import * as dragula from 'dragula';
import { EDITABLE_TRIAL_STATES } from '../../models/TrialState';
import { ImportEntitiesDelegate } from '../import_entities';
import { session } from '../../session';

let template =
  require('raw-loader!../../../templates/components/trial_wizard/trial_treatments.html').default;

enum CheckboxType {
  CONTROL = 'control',
  DISABLED = 'disabled',
}

interface Option {
  value: string;
  title: string;
}

class TrialTreatments extends BaseTrialStep {
  private drake: dragula.Drake;
  title = i18n.t('Step 3 - Define the trial treatments');

  // Alias to make it available in the template
  checkboxType = CheckboxType;
  globalError = ko.observable('');
  testSubjectsIdToOrderMapping = new Map<number, number>();
  limitTosGroupedById: Map<string, LimitToDimension> = new Map();
  isTrialInEditableState = ko.computed(() => {
    return EDITABLE_TRIAL_STATES.includes(this.trialWizard().trial().state());
  });
  isTrialInActiveState = ko.computed(() => {
    return this.trialWizard().trial().state() == TrialState.Active;
  });
  numberOfTestSubjectsSelected = ko.computed(() => {
    return (
      sum(
        this.trialWizard()
          .testSubjects()
          .map((testSubject) => testSubject.limitTo().length)
      ) || 0
    );
  });

  newTreatmentName = ko.observable<I18nText>().extend({ serverError: true, required: true });
  newTreatmentFactors: ko.ObservableArray<ko.Observable<string | null>> = ko.observableArray();

  isGeneratingTreatments = ko.observable(false);
  generatingTreatmentsText = i18n.t('Generating treatments...')();
  translateNameJson = translate;
  validationError = ko.observable<string>();
  sortedTreatmentsByOrder = ko.computed(() =>
    sortBy(this.trialWizard().treatments(), (treatment) => treatment.order())
  );
  areAllAttributesExpanded = ko.observable(false);
  expandedAttributesOrders = ko.observableArray<number>([]);
  expandCollapseIcon = ko.computed(() => {
    return this.trialWizard().treatments().length == this.expandedAttributesOrders().length
      ? 'expand_less'
      : 'expand_more';
  });

  showImportCustomLayout = ko.observable(false);
  importCustomLayoutDelegate: ImportEntitiesDelegate<CustomLayoutData> = {
    title: i18n.t('Import custom layout'),
    description: i18n.t(
      'Download the Excel template and upload the modified file containing your custom layout.'
    )(),
    backTitle: '',
    templateBaseName: 'layout',
    downloadTemplate: () => {
      const subjects = this.trialWizard().testSubjects();
      const controlIds: string[] = [];
      for (let ddm of subjects) {
        for (let dim of ddm.limitTo()) {
          if (dim.control()) {
            controlIds.push(dim.id());
          }
        }
      }

      return downloadCustomLayoutImportTemplate({
        dm_ids: subjects.map((ts) => ts.dimensionMeta()?.id()),
        control_dim_ids: controlIds,
        existing: this.trialWizard().plotGuides(),
        trial_id: this.trialWizard().trial().id(),
      });
    },
    importUrl: '/api/trials/import_custom_layout/',
    prepareFileContents: (fileContents) =>
      JSON.stringify({
        file_contents: encodeArrayBufferToBase64(fileContents),
        crop_id: this.trialWizard().trial().crop()?.id ?? null,
        dm_ids: this.trialWizard()
          .testSubjects()
          .map((ts) => ts.dimensionMeta()?.id()),
        trial_id: this.trialWizard().trial().id(),
      }),
    onSuccess: async (data) => {
      const errors = await this.trialWizard().applyCustomLayout(data);
      return errors.map((message) => ({ sheet: '', cell: '', message }));
    },
  };

  constructor(params: { controller: WizardController }) {
    super(params);
    this.ready(true);

    this.initDrake();
    this.trialWizard()
      .testSubjects()
      .forEach((datasetDimensionMeta: DatasetDimensionMeta, index: number) => {
        this.testSubjectsIdToOrderMapping.set(parseInt(datasetDimensionMeta.dimensionMeta().id()), index);
      });

    this.sortedTreatmentsByOrder.subscribe((treatments) => {});

    // Fill the Treatment factors with empty observables
    this.fillTreatmentFactors();

    this.trialWizard().testSubjects.subscribe((testSubjects) => {
      this.fillTreatmentFactors();
    });
  }

  async reload() {
    super.reload();

    if (this.trialWizard().testSubjects().length == 1 && this.trialWizard().treatments().length == 0) {
      await this.createFactorCombinations();
    }
  }

  initDrake = () => {
    if (this.drake) {
      this.drake.destroy();
    }

    this.drake = dragula([document.getElementById('trial-treatments-rows')], {
      revertOnSpill: true,
      moves: (el, container, handle) => {
        if (!this.isTrialInEditableState()) {
          return false;
        }
        return handle.classList.contains('treatments-drag-indicator-handle');
      },
    });

    this.drake.on('drag', (el: HTMLTableRowElement) => {
      el.classList.add('treatments-table-dragging');
    });
    this.drake.on('dragend', (el: HTMLTableRowElement) => {
      el.classList.remove('treatments-table-dragging');
    });

    this.drake.on('drop', this.onRowDrop);
  };

  applyLocalValidation(): boolean {
    const treatmentNames: I18nText[] = [];
    let isValid = true;
    this.globalError('');

    for (let treatment of this.trialWizard().treatments()) {
      for (let treatmentName of treatmentNames) {
        if (isEqual(treatment.nameJson(), treatmentName)) {
          isValid = false;
          break;
        }
      }

      treatmentNames.push(treatment.nameJson());
      if (!isValid) {
        break;
      }
    }

    if (!isValid) {
      this.globalError(i18n.t('Treatment names must be unique.')());
      document
        .getElementById('treatments-global-error')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      return false;
    }

    if (this.trialWizard().treatments().length == 0) {
      this.globalError(i18n.t('Please create at least one treatment.')());
      document
        .getElementById('treatments-global-error')
        ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
      return false;
    }

    return true;
  }

  hasErrors(): boolean {
    return !this.applyLocalValidation();
  }

  fillTreatmentFactors = (): void => {
    this.newTreatmentFactors.removeAll();

    this.trialWizard()
      .testSubjects()
      .forEach(() =>
        this.newTreatmentFactors.push(ko.observable(null).extend({ serverError: true, required: true }))
      );
  };

  onRowDrop = (
    element: HTMLTableRowElement,
    target: HTMLTableElement,
    source: HTMLTableElement,
    sibling: HTMLTableRowElement
  ) => {
    this.setNewOrders();

    this.trialWizard().forceRegeneratePlots('full');
  };

  handleExpandCollapseAllAttributes = () => {
    // If all the rows are expanded, it means that we need to collapse them
    if (this.expandedAttributesOrders().length == this.trialWizard().treatments().length) {
      this.expandedAttributesOrders([]);
    }
    // Otherwise, expand all the rows
    else {
      this.expandedAttributesOrders(
        this.trialWizard()
          .treatments()
          .map((treatment) => treatment.order())
      );
    }
  };

  handleExpandCollapseSingleRow = (order: number) => {
    const expandedAttributesOrders = this.expandedAttributesOrders();
    if (expandedAttributesOrders.includes(order)) {
      this.expandedAttributesOrders(expandedAttributesOrders.filter((o) => o != order));
    } else {
      this.expandedAttributesOrders([...expandedAttributesOrders, order]);
    }
  };

  getExpandCollapseIconForRow = (order: number) => {
    return this.getExpandCollapseStateForRow(order) ? 'expand_less' : 'expand_more';
  };

  getExpandCollapseStateForRow = (order: number) => {
    return this.expandedAttributesOrders().includes(order);
  };

  setNewOrders = () => {
    const treatmentTableElementChildren = Array.from(
      document.getElementById('trial-treatments-rows').children
    );
    const childrenIndexes = treatmentTableElementChildren.map((element: HTMLTableRowElement) =>
      parseInt(element.getAttribute('id'))
    );

    for (let treatment of this.trialWizard().treatments()) {
      // Find the index of the treatment in the table
      const treatmentElementIndex = childrenIndexes.findIndex((order) => order == treatment.order());

      // The new order matches the index of the element in the table
      treatment.order(treatmentElementIndex);
    }
  };

  getOptionsForTestSubject = (testSubject: DatasetDimensionMeta): Option[] => {
    const options = testSubject.limitTo().map((limitTo) => ({
      value: limitTo.id(),
      title: translate(limitTo.nameJson()),
    }));

    return [
      { value: 'default', title: i18n.t(`Select ${translate(testSubject.nameJson())}`)() },
      ...options,
    ];
  };

  getFactorAttributes = (factor: TreatmentFactor): {}[][] => {
    const datasetDimensionMeta = this.trialWizard()
      .testSubjects()
      .find(
        (testSubject) => parseInt(testSubject.dimensionMeta().id()) == factor.dimension().dimension_meta_id()
      );

    const dimension = datasetDimensionMeta
      ?.limitTo()
      ?.find((limitTo) => limitTo.id() == factor.dimension().id());

    if (!dimension) {
      return [];
    }

    const attributesTextsArray = dimension
      .attributes()
      .map((attribute) => [translate(attribute.nameJson()), attribute.value()]);

    return attributesTextsArray;
  };

  sortTreatmentFactors = (factors: ko.ObservableArray<TreatmentFactor>) => {
    // The sorted factors array must be cloned because the backend
    // might send the the factors in a different order, and our Trial Wizard
    // logic to compare the factors that were already saved to the backend
    // with the factors that are being changed will fail, because the order
    // of the factors will be different.
    return ko.observableArray(factors()).sort(this.sortByTestSubjectOrder);
  };

  deleteAllTreatments = async () => {
    if (!this.isTrialInEditableState()) {
      return;
    }

    try {
      await confirmDialog(
        i18n.t('Delete all treatments')(),
        [
          i18n.t([
            'delete_all_treatments_confirmation',
            'Are you sure you want to delete all treatments?',
          ])(),
          i18n.t('This action cannot be undone.')(),
        ],
        undefined,
        false,
        i18n.t('Delete')()
      );
    } catch (e) {
      return;
    }

    this.trialWizard().treatments.removeAll();
    this.trialWizard().generateFreshPlotsRequest();
  };

  sortByTestSubjectOrder = (a: TreatmentFactor, b: TreatmentFactor) => {
    return (
      this.testSubjectsIdToOrderMapping.get(a.dimension().dimension_meta_id()) -
      this.testSubjectsIdToOrderMapping.get(b.dimension().dimension_meta_id())
    );
  };

  validateName = (nameObservable: ko.Observable<I18nText>, treatment: Treatment | null) => {
    nameObservable.serverError(null);
    nameObservable.isModified(true);

    // Validate that the name is not empty
    if (translate(nameObservable()).trim() === '') {
      nameObservable.serverError(i18n.t('This field is required.')());
      throw Error('This field is required.');
    }

    // Validate that the name is unique among all treatments
    for (let otherTreatment of this.trialWizard().treatments()) {
      // Skip if the updated treatment is the same treatment
      if (otherTreatment == treatment) {
        continue;
      }

      for (let languageName of Object.keys(nameObservable())) {
        // Skip the 'default' key as it's not really a language
        if (languageName == 'default') {
          continue;
        }

        // Compare the names in the current language
        if (
          otherTreatment.nameJson().hasOwnProperty(languageName) &&
          otherTreatment.nameJson()[languageName] == nameObservable()[languageName]
        ) {
          nameObservable.serverError(i18n.t('This name is already in use')());
          throw Error('This name is already in use');
        }
      }
    }
  };

  groupTestSubjectsLimitTosById = (testSubjects: DatasetDimensionMeta[]): Map<string, LimitToDimension> => {
    const limitTosGroupedById = new Map();

    testSubjects.forEach((testSubject) => {
      testSubject.limitTo().forEach((limitTo) => {
        limitTosGroupedById.set(limitTo.id(), limitTo);
      });
    });

    return limitTosGroupedById;
  };

  validateFactors = (
    newTreatmentFactors: ko.Observable[],
    validationError: ko.Observable<string>,
    existingCombiation: Set<string>
  ) => {
    newTreatmentFactors.forEach((observable) => observable.isModified(true));

    if (!newTreatmentFactors.every((observable) => observable() != 'default')) {
      validationError(i18n.t('Please select all factors.')());
      throw Error('Please select all factors.');
    }

    const existingFactorCombinations = this.trialWizard()
      .treatments()
      .map((treatment) => {
        return new Set(treatment.factors().map((factor: TreatmentFactor) => factor.dimension().id()));
      })
      .filter((combination) => !isEqual(combination, existingCombiation));
    const newFactorCombination = new Set(newTreatmentFactors.map((observable) => observable()));

    for (let existingFactorCombination of existingFactorCombinations) {
      if (isEqual(existingFactorCombination, newFactorCombination)) {
        validationError(
          i18n.t([
            'combination_of_factors_is_already_in_use',
            'This combination of factors is already in use.',
          ])()
        );
        throw Error('This combination of factors is already in use.');
      }
    }
  };

  convertFactorsToTreatmentFactorsData = (factors: ko.Observable[]): TreatmentFactorData[] => {
    const groupedLimitTos = this.groupTestSubjectsLimitTosById(this.trialWizard().testSubjects());
    const limitTos = factors.map((observable) => groupedLimitTos.get(observable()));

    return limitTos.map((limitTo) => ({
      id: null,
      dimension: {
        id: limitTo.id(),
        name: translate(limitTo.nameJson()),
        dimension_meta_id: parseInt(limitTo.dimensionMetaId()),
      },
    }));
  };

  convertFactorsToTreatmentFactors = (factors: ko.Observable[]): TreatmentFactor[] => {
    const groupedLimitTos = this.groupTestSubjectsLimitTosById(this.trialWizard().testSubjects());
    const limitTos = factors.map((observable) => groupedLimitTos.get(observable()));

    return limitTos.map(
      (limitTo) =>
        new TreatmentFactor({
          id: null,
          dimension: {
            id: limitTo.id(),
            name: translate(limitTo.nameJson()),
            dimension_meta_id: parseInt(limitTo.dimensionMetaId()),
          },
        })
    );
  };

  addTreatment = () => {
    this.newTreatmentName(trimObjectValues(this.newTreatmentName()));
    this.newTreatmentName.serverError(null);
    this.newTreatmentName.isModified(true);
    this.validationError(null);

    this.newTreatmentFactors().forEach((observable) => observable.isModified(true));

    try {
      this.validateName(this.newTreatmentName, null);
      this.validateFactors(this.newTreatmentFactors(), this.validationError, new Set());
    } catch (e) {
      return;
    }

    const factors = this.convertFactorsToTreatmentFactorsData(this.newTreatmentFactors());

    const newTreatment = new Treatment({
      id: null,
      name_json: this.newTreatmentName(),
      disabled: false,
      control: false,
      order: this.trialWizard().treatments().length,
      factors: factors,
    });

    this.trialWizard().treatments.push(newTreatment);
    this.newTreatmentName(asI18nText(''));
    this.newTreatmentFactors().forEach((observable) => observable(null));
    this.initDrake();
    this.trialWizard().forceRegeneratePlots('full');

    this.applyLocalValidation();
  };

  onTreatmentUpdate =
    (originalTreatment: Treatment) =>
    (
      newName: ko.Observable<I18nText>,
      newTreatmentFactors: ko.Observable[],
      validationError: ko.Observable
    ) => {
      this.validateName(newName, originalTreatment);
      const existingCombination = new Set(
        originalTreatment.factors().map((factor) => factor.dimension().id())
      );
      this.validateFactors(newTreatmentFactors, validationError, existingCombination);

      originalTreatment.nameJson(newName());
      originalTreatment.factors(this.convertFactorsToTreatmentFactors(newTreatmentFactors));

      this.trialWizard().forceRegeneratePlots('full');
    };

  createMenuOptionsForTreatment = (treatment: Treatment): Options => {
    return [
      {
        label: i18n.t('Edit')(),
        action: () => {
          openTreatmentEdit(
            treatment,
            this.onTreatmentUpdate(treatment),
            this.trialWizard(),
            this.getOptionsForTestSubject,
            Promise.resolve()
          );
        },
        disabled: !this.isTrialInEditableState() && !this.isTrialInActiveState(),
      },
      {
        label: i18n.t('Delete')(),
        action: () => this.deleteTreatment(treatment),
        disabled: !this.isTrialInEditableState(),
      },
    ];
  };

  deleteTreatment = async (treatment: Treatment) => {
    if (!this.isTrialInEditableState()) {
      return;
    }

    try {
      await confirmDialog(
        i18n.t(`Delete ${translate(treatment.nameJson())}`)(),
        [
          i18n.t(['are_you_sure_delete_treatments', 'Are you sure you want to delete this treatment?'])(),
          i18n.t('If you proceed, all data collected for it will be lost. This action cannot be undone.')(),
        ],
        undefined,
        false,
        i18n.t('Delete')()
      );
    } catch (e) {
      return;
    }

    this.trialWizard().treatments.remove(treatment);

    document.getElementById(treatment.order().toString())?.remove();

    this.setNewOrders();
    this.initDrake();
    this.trialWizard().forceRegeneratePlots('full');
  };

  createFactorCombinations = async () => {
    if (!this.isTrialInEditableState() || this.isGeneratingTreatments()) {
      return;
    }

    if (this.trialWizard().treatments().length > 0) {
      try {
        await confirmDialog(
          i18n.t('Overwrite existing treatments?')(),
          [
            i18n.t(
              'You are about to automatically create treatments. However, since you have already created some, those will be overwritten.'
            )(),
            i18n.t([
              'are_you_sure_treatments',
              'Are you sure you want to overwrite the existing treatments?',
            ])(),
          ],
          undefined,
          false,
          i18n.t('CREATE AND OVERWRITE')()
        );
      } catch (e) {
        return;
      }
    }

    this.isGeneratingTreatments(true);

    const testSubjectLimitToArrays = this.trialWizard()
      .testSubjectsWithDimensionMeta()
      .map((testSubject) => {
        return testSubject
          .limitTo()
          .filter((limitTo: LimitToDimension) => limitTo.includeInFactorialCombinations());
      });

    // Store the treatments in an array to avoid generating a lot of notifications
    // when items are being pushed to the observable array
    const treatmentsToCreate = [];
    const factorsGroups = product(...testSubjectLimitToArrays);

    const isSingleFactor = this.trialWizard().testSubjects().length == 1;
    const singleFactorAnonymizeOption = isSingleFactor
      ? (this.trialWizard().testSubjects()[0].anonymize() as AnonymizeOptions)
      : null;

    for (let index = 0; index < factorsGroups.length; index++) {
      const factors = factorsGroups[index];

      const name_json = isSingleFactor
        ? this.generateSingleFactorTrialTreatmentName(singleFactorAnonymizeOption, factors, index)
        : asI18nText(`T${index + 1}`);

      treatmentsToCreate.push(
        new Treatment({
          id: null,
          name_json: name_json,
          disabled: false,
          control: false,
          order: index,
          factors: factors.map((limitTo, index) => ({
            id: null,
            dimension: {
              id: limitTo.id(),
              name: translate(limitTo.nameJson()),
              dimension_meta_id: parseInt(limitTo.dimensionMetaId()),
            },
          })),
        })
      );
    }

    this.trialWizard().treatments(treatmentsToCreate);
    this.initDrake();
    this.isGeneratingTreatments(false);
    this.trialWizard().forceRegeneratePlots('full');

    this.applyLocalValidation();
  };

  generateSingleFactorTrialTreatmentName = (
    anonymizeOption: AnonymizeOptions,
    factors: LimitToDimension[],
    index: number
  ): I18nText => {
    const factor = factors[0];
    switch (anonymizeOption) {
      case AnonymizeOptions.ANONYMIZE:
        return asI18nText(`T${index + 1}`);
      case AnonymizeOptions.ANONYMIZE_PARTIAL:
        return asI18nText(factor.anonymizedCode());
      default:
        return factor.nameJson();
    }
  };

  createOnChangeHandler(observable: ko.Observable<boolean>) {
    return (event: Event) => {
      let input = event.target as HTMLInputElement;
      observable(!!input.value);
    };
  }

  createIdForCheckbox = (index: number, checkboxType: CheckboxType) => {
    return `${checkboxType}-checkbox-${index}`;
  };

  disableCustomLayoutActions = ko.pureComputed(() => {
    const subjects = this.trialWizard()?.testSubjects() ?? [];
    const plotDesign = this.trialWizard()?.trial()?.plotDesign();
    return !(
      this.allowEditAny() &&
      subjects.length > 0 &&
      subjects.every((ts) => !!ts.dimensionMeta()) &&
      CUSTOM_LAYOUT_VALID_PLOT_DESIGNS.indexOf(plotDesign) > -1
    );
  });

  openImportCustomLayout = async () => {
    if (this.disableCustomLayoutActions()) {
      return;
    }

    if (!this.trialWizard().canSafelyRegeneratePlots()) {
      let title = i18n.t('Changing plots')();
      let message = i18n.t(
        'You have customized the plots. After importing a new layout your customizations will be lost. Are you sure you want to continue?'
      )();

      await confirmDialog(title, message);
    }

    this.showImportCustomLayout(true);
  };

  closeImportCustomLayout = () => {
    this.showImportCustomLayout(false);
  };

  resetCustomLayout = async () => {
    if (this.disableCustomLayoutActions()) {
      return;
    }

    await this.confirmTSChange();
  };

  private async confirmTSChange(): Promise<{}> {
    let text =
      SERVER_INFO.USE_FACTORS_NAMING ||
      session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial())
        ? i18n.t(['treatment_factor_lowercase', 'treatment factors'])
        : i18n.t('test subjects');
    await this.trialWizard().confirmChangeAffectingPlots(text(), !!this.trialWizard().plotGuides());
    this.trialWizard().plotGuides(null);
    this.trialWizard().savePlotGuides = true;

    return null;
  }
}

ko.components.register('trial-treatments', {
  viewModel: TrialTreatments,
  template: template,
});
