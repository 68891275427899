import i18n from '../i18n';

export interface StaticList {
  id?: string;
  name: string;
}

export const productionSystemChoices: StaticList[] = [
  { id: 'open_field', name: i18n.t('Open field')() },
  { id: 'plastic_tunnel', name: i18n.t('Plastic tunnel')() },
  { id: 'glasshouse', name: i18n.t('Glasshouse')() },
  { id: 'hydroponics', name: i18n.t('Hydroponics')() },
];

export const croppingSystemChoices: StaticList[] = [
  { id: 'continuous_monocrop', name: i18n.t('Continuous monocrop')() },
  { id: 'rotated_intercrop', name: i18n.t('Rotated intercrop')() },
  {
    id: 'intercrop_mixed_cropping',
    name: i18n.t('Intercrop mixed cropping')(),
  },
];

export const marketScaleChoices: StaticList[] = [
  { id: 'households', name: i18n.t('Households')() },
  { id: 'local', name: i18n.t('Local')() },
  { id: 'regional', name: i18n.t('Regional')() },
  { id: 'national', name: i18n.t('National')() },
  { id: 'international_markets', name: i18n.t('International markets')() },
];

export const tppStatesChoices: StaticList[] = [
  { id: 'active', name: i18n.t('Active')() },
  { id: 'discarded', name: i18n.t('Discarded')() },
  { id: 'completed', name: i18n.t('Completed')() },
];
