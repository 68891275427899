import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { app } from '../app';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/components/view_image.html').default;

class ViewImage {
  urls: string[];
  result: Deferred<{}>;

  constructor(params: { urls: string[]; result?: Deferred<{}> }) {
    this.urls = params.urls;
    this.result = params.result;
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

const name = 'view-image';

ko.components.register(name, { viewModel: ViewImage, template: template });

export function viewImages(urls: string[]) {
  app.formsStackController.push({
    title: i18n.t('Image')(),
    name,
    isBig: true,
    params: { urls, result: new Deferred<{}>() },
  });
}
