import * as ko from 'knockout';
import { session } from '../session';
import { ProjectData } from '../api/projects';
import {
  getUserSearchConfig,
} from '../components/configs/search_configs';
import { parseDate, serializeDate } from '../api/serialization';
import { UserData } from '../api/users';

export class Project {
  seasonNumberOptions = [1, 2, 3];

  id = ko.observable<string>(null);
  name = ko.observable('').extend({ required: true });
  fromDate = ko.observable<Date>(null).extend({
    required: true,
    serverError: true,
  });
  toDate = ko.observable<Date>(null).extend({ required: true });
  users = ko.observableArray<UserData>();
  canEdit = ko.pureComputed(() => session.isAtLeastEditor());
  canEditUsers = session.isS2bim();

  editUrl: string;

  usersSearch = getUserSearchConfig(this.users, ['partner']);

  constructor(public data?: ProjectData) {
    if (data) {
      this.id(data.id);
      this.name(data.name);
      this.fromDate(parseDate(data.from_date));
      this.toDate(parseDate(data.to_date));

      if (data.users && session.isS2bim()) {
        this.users(data.users);
      }
    }

    this.editUrl = '/projects/' + this.id();
  }

  toData(): ProjectData {
    return {
      id: this.id(),
      name: this.name(),
      from_date: serializeDate(this.fromDate()),
      to_date: serializeDate(this.toDate()),
      users: this.users(),
    };
  }
}