import { OverviewData } from '../api/dashboard';
import {
  listParamsToQueryString,
  RemoveResult,
  request,
  requestBackgroundRetry,
  requestRaw,
  requestRemoveEntity,
} from '../api/request';
import { FileUploadEndpoint } from '../cloud_storage_upload';

export interface DataEntryTrialData {
  id: string;
  name: string;
  crop_name: string;
  count_taken: number;
  count_total: number;
  count_due_today: number;
  data_collection_status: string;
}

export interface DataEntryBasicTrialInfo {
  id: number;
  name_slug: string;
}

export function listDataEntryTrials(): Promise<DataEntryTrialData[]> {
  return request('GET', '/api/data_entry/trials/');
}

export interface DataEntryVisitData {
  id: string;
  sv_id: string;
  name: string;
  site_id: string;
  site_name: string;
  scheduled_date: string;
  visit_date: string;
  count_taken: number;
  count_total: number;
  count_due_today: number;
  data_collection_status: string;
  can_add: boolean;
  can_delete: boolean;
  marked_completed: boolean;
}

export function listDataEntryVisits(
  trialId: string,
  hideCompleted?: boolean,
  siteId?: string
): Promise<DataEntryVisitData[]> {
  let url = `/api/data_entry/visits/?trial_id=${trialId}&completed=${!hideCompleted}`;
  url += siteId ? `&site_id=${siteId}` : '';
  return requestBackgroundRetry('GET', url);
}

export function removeDataEntryVisit(visitId: string): Promise<RemoveResult> {
  return requestRemoveEntity(`/api/data_entry/remove_visit/?visit_id=${visitId}`);
}

export function markDataEntryVisitCompleted(visitData: DataEntryVisitData): Promise<RemoveResult> {
  return requestBackgroundRetry('PUT', `/api/data_entry/mark_visit_completed/`, {
    visit_id: visitData.id,
    marked_completed: visitData.marked_completed,
    site_id: visitData.site_id,
    sv_id: visitData.sv_id,
  });
}

export function fetchDataEntryOverview(
  trialId: string,
  filters: {
    sv_id: string;
    visit_id: string;
    site_id: string;
    optional_dim_ids: string[];
    optional_traits_ids: string[];
    exclude_mobile?: string;
    language_code?: string;
  },
  unitFilters: Record<number, number>
): Promise<OverviewData> {
  return requestBackgroundRetry(
    'GET',
    `/api/data_entry/${trialId}/overview/?` + listParamsToQueryString({ ...filters, ...unitFilters })
  );
}

interface DataEntryOptionalDm {
  dm_name: string;
  dimensions: DataEntryOptionalEntity[];
}

export interface DataEntryOptionalEntity {
  id: string;
  name: string;
}

export function getDataEntryOptionalDims(trialId: string): Promise<DataEntryOptionalDm[]> {
  return requestBackgroundRetry('GET', `/api/data_entry/optional_dimensions/?trial_id=${trialId}`);
}

export function getDataEntryOptionalTraits(trialId: string): Promise<DataEntryOptionalEntity[]> {
  return requestBackgroundRetry('GET', `/api/data_entry/optional_traits/?trial_id=${trialId}`);
}

export interface SaveObservationsRequest {
  trial_id: string;
  sv_id: string;
  site_id: string;
  visit_id: string;
  values: {
    [factId: string]: {
      // if not null, factId was created on the client and the server needs to make up a new one
      // it's the index into fact ids
      client: number | null;
      order: number;
      dataset_id: string;
      plot_id: string;
      dims: string[]; // it's safe to specify dims not valid for current dataset, they'll be ignored
      mms: {
        [mmId: string]: {};
      };
    };
  };
}

export interface SaveObservationsRespone {
  // 'client' is the same as sent in SaveObservationsRequest
  // fact_id is the new server id
  // returned only for not-null values
  fact_ids: [
    {
      client: number;
      fact_id: string;
    }
  ];
}

export function saveObservations(data: SaveObservationsRequest): Promise<SaveObservationsRespone> {
  return requestBackgroundRetry('POST', '/api/data_entry/save_observations/', data, null, 5000);
}

export function getFactUploadEndpoint(
  contentType: string,
  params?: {
    file_name: string;
  }
): Promise<FileUploadEndpoint> {
  const queryParameter = params ? `?${listParamsToQueryString(params)}` : '';
  return requestBackgroundRetry('POST', `/api/data_entry/fact_upload_url/${queryParameter}`, {
    content_type: contentType,
  });
}

export function getDataEntryExtraUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
  return requestBackgroundRetry('POST', '/api/data_entry/extra_upload_url/', {
    content_type: contentType,
  });
}

export function saveVisitDate(data: {
  trial_id: string;
  sv_id: string;
  site_id: string;
  visit_id: string;
  date: string;
}): Promise<{}> {
  return requestBackgroundRetry('POST', '/api/data_entry/save_visit_date/', data);
}

export interface DataEntryExtraData {
  id: string;
  file_name: string;
  user_file_name: string;
  file_url: string;
  mime_type: string;
  comment: string;
  measurement_meta_id: number;
}

export function listDataEntryExtra(
  factId: string,
  measurementMetaId: number
): Promise<DataEntryExtraData[]> {
  return requestBackgroundRetry(
    'GET',
    `/api/data_entry/extras/?fact_id=${factId}&measurement_meta_id=${measurementMetaId}`
  );
}

export interface SaveExtraRequest {
  fact_id: string;
  measurement_meta_id: number;
  extras: DataEntryExtraData[];
}

export function saveDataEntryExtra(data: SaveExtraRequest): Promise<void> {
  return requestBackgroundRetry('POST', `/api/data_entry/save_extras/`, data);
}

export interface DataEntryImportParams {
  trial_id: string;
  sv_id: string;
  site_id: string;
  visit_id: string;
  language: string;
  dataLanguage?: string;
}

export function downloadDataEntryImportTemplate(data: DataEntryImportParams): Promise<Blob> {
  return requestRaw('GET', `/api/data_entry/import_template/?${listParamsToQueryString(data as {})}`);
}

export function getBasicTrialInfo(trial_id: string): Promise<DataEntryBasicTrialInfo> {
  return request('GET', `/api/data_entry/${trial_id}/basic_trial_info/`);
}
