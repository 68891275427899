import { request, ValidationResult, requestWithValidation } from './request';
import { GeoJSON } from './datasets';

type ReviewRecordType = 'fact' | 'fact_ranking' | 'extra';
export type ReviewAction = 'server' | 'user';

export interface ReviewRecordKey {
  record_type: ReviewRecordType;
  id: number;
  dataset_id: number; // negative trial id for visits, which don't have a dataset
}

export function reviewRecordKeyHash(key: ReviewRecordKey): string {
  return key.record_type + ':' + key.id;
}

export function reviewRecordKeyEq(a: ReviewRecordKey, b: ReviewRecordKey): boolean {
  return a.record_type === b.record_type && a.id === b.id;
}

export interface ReviewHeader {
  trials: { id: number; name: string }[];
  datasets: ReviewDataset[];
  records: ReviewRecordKey[];
}

export interface ReviewDataset {
  id: number;
  trial_id: number;
  name: string;
}

export function header(): Promise<ReviewHeader> {
  return request('GET', '/api/review/header/');
}

export interface ReviewMergeRecord extends ReviewRecordKey {
  user_name: string;
  plot_name: string;
  reference_dim_name: string;
  dimensions: string;
  server_timestamp: string;
  user_timestamp: string;
  attributes: ReviewMergeAttribute[];
}

export type Value = number | string | GeoJSON;

export interface ReviewMergeAttribute {
  id: string;
  title: string;
  type: 'geo' | 'url' | 'url_list' | 'other';
  server_value: Value;
  user_value: Value;
}

export function record(key: ReviewRecordKey): Promise<ReviewMergeRecord> {
  return request('GET', '/api/review/record/?review_id=' + key.id + '&record_type=' + key.record_type);
}

export interface MergeRecordAction {
  record_type: ReviewRecordType;
  review_id: number;
  attribute_actions: MergeAttributeAction[];
}

export interface MergeAttributeAction {
  id: string;
  action: ReviewAction;
}

export function merge(data: MergeRecordAction): Promise<ValidationResult> {
  return requestWithValidation('POST', '/api/review/merge/', data);
}

export function mergeAll(): Promise<void> {
  return request('POST', '/api/review/merge_all/');
}
