import * as dragula from 'dragula';

import { el, range } from '../../../utils';
import { PlotsSiteEditModel } from './plots_edit_model';
import { plotView } from './plot_view';
import { diffList } from '../../../utils/dom';

export interface PlotsOrderViewDelegate {
  onPlotOrderChanged(edit: PlotsSiteEditModel, oldIndex: number, newIndex: number): void;
}

export function plotsOrderView(delegate: PlotsOrderViewDelegate, allowEditAny: boolean) {
  const setupSortable = (element: Element) => {
    const drake = dragula([element], { revertOnSpill: true });
    let originalIndex = -1;
    let onUpdate: (idx1: number, idx2: number) => void = null;

    drake.on('drag', (dragElement: Element, sourceContainer: Element) => {
      originalIndex = Array.prototype.indexOf.call(sourceContainer.children, dragElement);
    });

    drake.on('drop', (dropElement: Element, targetContainer: Element) => {
      if (originalIndex === -1) {
        return;
      }

      const indexInTarget = Array.prototype.indexOf.call(targetContainer.children, dropElement);
      onUpdate?.(originalIndex, indexInTarget);

      originalIndex = -1;
    });

    return (edit: PlotsSiteEditModel) => {
      onUpdate = (originalIndex, indexInTarget) =>
        delegate.onPlotOrderChanged(edit, originalIndex, indexInTarget);
    };
  };

  const root = el('ul');
  const updateSortable = allowEditAny ? setupSortable(root) : null;

  const updateList = diffList(root, {
    create: () => {
      const li = el('li', 'draggable-li');
      const { root, update } = plotView();

      li.appendChild(root);

      return { root: li, update };
    },
    update: (view, idx: number, edit: PlotsSiteEditModel) => {
      view.update({ edit, plot: edit.sortedPlot(idx) });
    },
  });

  return {
    root,
    update: (edit: PlotsSiteEditModel) => {
      updateSortable?.(edit);
      updateList(range(edit.nPlots()), edit);
    },
  };
}
