import * as ko from 'knockout';

import { I18nText } from '../i18n_text';
import { CountryData } from '../api/countries';
import { AgroRegionData } from '../api/agro_regions';
import { getCountrySearchConfig } from '../components/configs/search_configs';

export class AgroRegion {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  country = ko.observable<CountryData>(null).extend({
    required: true,
  });

  countrySearchConfig = getCountrySearchConfig(this.country);

  editUrl = ko.pureComputed(() => {
    return '/agro_regions/' + this.id() + '/';
  });

  constructor(data?: AgroRegionData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.country(data.country);
    }
  }

  toData(): AgroRegionData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      country: this.country(),
    };
  }
}
