import * as ko from 'knockout';

import * as tppsApi from '../api/tpps';
import { Deferred } from '../utils/deferred';
import { downloadBlob } from '../utils';

const template = require('raw-loader!../../templates/components/tpp_download_product_profile.html').default;

class TPPDownloadProductProfile {
  constructor(params: { id: string; name: string; result: Deferred<{}> }) {
    this.download(params.id, params.name, params.result);
  }

  private async download(id: string, name: string, result: Deferred<{}>) {
    try {
      const data = await tppsApi.exportProductProfile(id);
      downloadBlob(data, `${name}.xlsx`);
    } finally {
      result.resolve(null);
    }
  }
}

export let tppDownloadProductProfile = {
  name: 'tpp-download-product-profile',
  viewModel: TPPDownloadProductProfile,
  template: template,
};

ko.components.register(tppDownloadProductProfile.name, tppDownloadProductProfile);
