import * as dragula from 'dragula';

import { el, range } from '../../../utils';
import { PlotsSiteEditModel } from './plots_edit_model';
import i18n from '../../../i18n';
import {
  PlotViewDelegate,
  plotView,
  PlotViewParams,
  plotNotPlantedView,
  defineCellAttributes,
} from './plot_view';
import { diffList, choose, indexInParent } from '../../../utils/dom';

export interface PlotsGridViewDelegate extends PlotViewDelegate {
  onAddPlot(edit: PlotsSiteEditModel, colIdx: number, rowIdx: number): void;
  onSwap(edit: PlotsSiteEditModel, colIdx1: number, rowIdx1: number, colIdx2: number, rowIdx2: number): void;
  onSwapWithRemoved(edit: PlotsSiteEditModel, excludedIdx: number, sourceX: number, sourceY: number): void;
  onDragStart(edit: PlotsSiteEditModel, params: { col?: number; row?: number }): void;
  onDragOver(edit: PlotsSiteEditModel, params: { col?: number; row?: number }): void;
  onDragEnd(edit: PlotsSiteEditModel): void;
  onDragCancel(edit: PlotsSiteEditModel): void;
}

// first element in each row is a label
const TD_Y_OFFSET = 1;

export class PlotsGridView {
  readonly root = el('div');

  private headRow: HTMLElement;
  private tbody = el('tbody');
  private removed = el('div', 'remove-area-target');

  private drake: dragula.Drake;

  private prevNCol = -1;
  private prevnRow = -1;

  private edit: PlotsSiteEditModel;
  private _update: (edit: PlotsSiteEditModel, allowEditAny: boolean) => void;

  tableLayout: HTMLElement;

  constructor(private delegate: PlotsGridViewDelegate) {
    let container = el('div');

    let tableWrapper = el('div', 'table-wrapper');
    tableWrapper.id = 'table-wrapper';

    let zoomWrapper = el('div', 'zoom-wrapper');

    let table = el('table', 'max-size');
    table.id = 'layout-table';
    this.tableLayout = table;

    zoomWrapper.appendChild(table);

    tableWrapper.appendChild(zoomWrapper);

    let thead = el('thead');
    this.headRow = el('tr');

    thead.appendChild(this.headRow);
    table.appendChild(thead);
    table.appendChild(this.tbody);

    container.appendChild(tableWrapper);

    this.root.appendChild(container);

    const tableHeight = window.innerHeight - 100;
    table.style.height = `${tableHeight}px`;

    table.onmouseup = () => this.delegate.onDragEnd(this.edit);
    table.onmouseleave = () => this.delegate.onDragCancel(this.edit);

    table.onmousemove = (evt) => {
      let col: number | null = null;
      let row: number | null = null;

      let rowElem: Element = evt.target as Element;
      while (rowElem && rowElem.tagName !== 'TR' && rowElem !== table) {
        rowElem = rowElem.parentElement;
      }
      if (rowElem && rowElem.parentElement?.tagName === 'THEAD') {
        row = -1;
      } else if (rowElem && rowElem.parentElement?.tagName === 'TBODY') {
        row = indexInParent(rowElem);
      }

      let colElem: Element = evt.target as Element;
      while (colElem && !(colElem.tagName === 'TD' || colElem.tagName === 'TH') && colElem !== table) {
        colElem = colElem.parentElement;
      }
      if (colElem?.tagName === 'TD' || colElem?.tagName === 'TH') {
        col = indexInParent(colElem) - TD_Y_OFFSET;
      }

      this.delegate.onDragOver(this.edit, { col, row });

      // Calculate height to always show horizontal scroll
      // in a section with fixed position.
      if (this.edit.nRows() * 150 > window.innerHeight) {
        const zoomWrapperHeight = window.innerHeight - 100;
        zoomWrapper.style.height = `${zoomWrapperHeight}px`;
      } else {
        zoomWrapper.style.height = null;
      }
    };
  }

  focus() {
    const input = this.root.querySelector('input');
    if (input) {
      input.focus();
      input.setSelectionRange(0, input.value.length);
    }
  }

  update(edit: PlotsSiteEditModel, allowEditAny: boolean) {
    if (
      !this._update ||
      edit !== this.edit ||
      this.prevNCol !== edit.nCols() ||
      this.prevnRow !== edit.nRows()
    ) {
      this.prevNCol = edit.nCols();
      this.prevnRow = edit.nRows();

      this.edit = edit;
      this._update = this.recreate(edit, allowEditAny);
    }

    this._update(edit, allowEditAny);
  }

  private recreate(edit: PlotsSiteEditModel, allowEditAny: boolean) {
    let updates: ((params: PlotViewParams) => void)[][] = [];
    if (this.drake) {
      this.drake.destroy();
    }

    this.headRow.innerHTML = '';

    this.headRow.appendChild(el('th'));
    let colHeadElems: HTMLElement[] = [];
    for (let i = 0; i < edit.nCols(); i++) {
      let head = el('th');
      head.textContent = (i + 1).toString();
      colHeadElems.push(head);
      this.headRow.appendChild(head);

      const params = { col: i };
      head.onmousedown = (evt) => {
        if (evt.button === 0) {
          this.delegate.onDragStart(edit, params);
        }
      };
    }

    this.tbody.innerHTML = '';

    let rowElems: HTMLElement[] = [];
    let expandRows = [];
    for (let j = 0; j < edit.nRows(); j++) {
      updates.push([]);

      const row = el('tr');
      if (edit.vSplit(j - 1)) {
        row.classList.add('vsplit-top');
      }
      const rowHead = el('th');
      rowHead.textContent = (j + 1).toString();

      const params = { row: j };
      rowHead.onmousedown = (evt) => {
        if (evt.button === 0) {
          this.delegate.onDragStart(edit, params);
        }
      };

      rowElems.push(row);
      row.appendChild(rowHead);

      let expandCols: number[] = [];
      for (let i = 0; i < edit.nCols(); i++) {
        const { root, update } = renderCell(allowEditAny ? this.delegate : null);

        row.appendChild(root);
        updates[j].push(update);
      }
      expandRows.push(expandCols);

      this.tbody.appendChild(row);
    }

    this.removed.innerHTML = '';

    const removedPlots = el('ul', 'drag-container');
    const updateExcludedList = diffList(removedPlots, {
      create: () => {
        const li = el('li', 'draggable-li');
        const { root, update } = plotView();
        li.appendChild(root);

        return { root: li, update };
      },
      update: (view, idx: number) => {
        const plot = edit.excludedPlot(idx);

        view.root.style.display = plot ? null : 'none';
        view.update({ edit, plot });
      },
    });

    const removeTargetArea = el('div', 'drag-container');
    removeTargetArea.setAttribute('data-drag', 'disable');

    const target = el('div', 'plot-remove-target');
    const targetContent = el('div');
    const targetIcon = el('i', 'material-icons left');
    targetIcon.textContent = 'delete_outline';
    const text = el('span');
    text.textContent = i18n.t('Drag here to remove')();

    targetContent.appendChild(targetIcon);
    targetContent.appendChild(text);
    target.appendChild(targetContent);
    removeTargetArea.appendChild(target);

    this.removed.appendChild(removedPlots);
    this.removed.appendChild(removeTargetArea);

    if (allowEditAny) {
      this.drake = this.setupSwappableGrid(this.root, edit);
    }

    return (edit: PlotsSiteEditModel, allowEditAny: boolean) => {
      (this.headRow.firstChild as Element).classList.toggle(
        'col-drag-target',
        edit.drag.isTarget({ col: -1 })
      );
      for (let i = 0; i < edit.nCols(); i++) {
        colHeadElems[i].classList.toggle('col-drag-target', edit.drag.isTarget({ col: i }));
      }
      this.headRow.classList.toggle('row-drag-target', edit.drag.isTarget({ row: -1 }));
      for (let j = 0; j < edit.nRows(); j++) {
        (rowElems[j].firstChild as Element).classList.toggle(
          'col-drag-target',
          edit.drag.isTarget({ col: -1 })
        );
        rowElems[j].classList.toggle('row-drag-target', edit.drag.isTarget({ row: j }));
      }

      for (let j = 0; j < edit.nRows(); j++) {
        for (let i = 0; i < edit.nCols(); i++) {
          updates[j][i]({ edit, plot: edit.plot(i, j), col: i, row: j });
        }
      }

      updateExcludedList(range(edit.nExcluded()), null);

      removeTargetArea.style.display = allowEditAny && edit.canRemove() ? 'block' : 'none';
    };
  }

  private setupSwappableGrid(element: Element, edit: PlotsSiteEditModel) {
    let draggedListIndex: number = null;

    let swap = (targetContainer: Element, sourceContainer: Element) => {
      const rowspan = parseInt(sourceContainer.getAttribute('rowspan')) || 1;
      if (rowspan > 1) return;
      if (targetContainer.classList.contains('highlight-plot')) return;
      if (sourceContainer.classList.contains('highlight-plot')) return;
      if (sourceContainer.tagName == 'TD' && targetContainer.tagName == 'TD') {
        let sourceY = indexInParent(sourceContainer.parentElement);
        let sourceX = indexInParent(sourceContainer) - TD_Y_OFFSET;
        let targetY = indexInParent(targetContainer.parentElement);
        let targetX = indexInParent(targetContainer) - TD_Y_OFFSET;

        this.delegate.onSwap(edit, sourceX, sourceY, targetX, targetY);
      }

      if (
        sourceContainer.tagName == 'TD' &&
        (targetContainer.tagName == 'UL' || targetContainer.tagName == 'DIV')
      ) {
        let sourceY = indexInParent(sourceContainer.parentElement);
        let sourceX = indexInParent(sourceContainer) - TD_Y_OFFSET;

        draggedListIndex = draggedListIndex ?? edit.nExcluded();

        this.delegate.onSwapWithRemoved(edit, draggedListIndex, sourceX, sourceY);
      }

      if (sourceContainer.tagName == 'UL' && targetContainer.tagName == 'TD') {
        let targetY = indexInParent(targetContainer.parentElement);
        let targetX = indexInParent(targetContainer) - TD_Y_OFFSET;

        draggedListIndex = draggedListIndex ?? edit.nExcluded();

        this.delegate.onSwapWithRemoved(edit, draggedListIndex, targetX, targetY);
      }
    };
    let drake = dragula($(element).find('.drag-container').toArray(), {
      revertOnSpill: true,
      copy: true,
      moves: (element: Element) => {
        const rowspan = parseInt(element.parentElement.getAttribute('rowspan')) || 1;
        return rowspan === 1 && $(element).data('drag') !== 'disable';
      },
      accepts: (element: Element, target: Element, source: Element, sibling: Element) => {
        const rowspan = parseInt(target.getAttribute('rowspan')) || 1;
        return rowspan === 1;
      },
    });
    drake.on('drag', (element: HTMLElement, source: HTMLElement) => {
      // tableLayout.className can be max-size, mid-size, small-size
      element.classList.add(`${this.tableLayout.className}-plot-moved`);
      if (element.tagName == 'LI') {
        draggedListIndex = indexInParent(element);
      } else {
        draggedListIndex = null;
      }
    });

    drake.on('cancel', (element: HTMLElement, container: HTMLElement, source: HTMLElement) => {
      const sourceChild = source?.firstChild as HTMLElement;
      // tableLayout.className can be max-size, mid-size, small-size
      element.classList.remove(`${this.tableLayout.className}-plot-moved`);
      sourceChild.classList.remove(`${this.tableLayout.className}-plot-moved`);
    });

    drake.on('dragend', (element: HTMLElement) => {
      // tableLayout.className can be max-size, mid-size, small-size
      element?.classList.remove(`${this.tableLayout.className}-plot-moved`);
    });

    drake.on(
      'drop',
      (element: Element, targetContainer: Element, sourceContainer: Element, sibling: Element) => {
        element?.classList.remove(`${this.tableLayout.className}-plot-moved`);
        swap(targetContainer, sourceContainer);
        drake.cancel();
      }
    );

    return drake;
  }
}

function renderCell(delegate?: PlotViewDelegate) {
  const { root, update } = choose<PlotViewParams>(
    el('td', 'drag-container'),
    (params) => (params.plot ? 'planted' : 'notPlanted'),
    {
      planted: () => plotView(delegate),
      notPlanted: () => plotNotPlantedView(delegate),
    }
  );
  return {
    root,
    update: (params: PlotViewParams) => {
      defineCellAttributes(root, params);
      root.setAttribute('col', params.col.toString());
      root.setAttribute('row', params.row.toString());
      root.classList.toggle('hsplit-left', params.edit.hSplit(params.col - 1));
      root.classList.toggle('hsplit-right', params.edit.hSplit(params.col));
      root.classList.toggle(
        'row-drag-source',
        params.edit.drag.isDragging({ col: params.col, row: params.row })
      );
      root.classList.toggle('col-drag-target', params.edit.drag.isTarget({ col: params.col }));
      update(params);
    },
  };
}
