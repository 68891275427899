import * as ko from 'knockout';
import { trialTraitDetail, TrialTraitDetailData, TrialTraitDetailMDM } from '../../api/measurement_metas';
import { app } from '../../app';
import i18n from '../../i18n';
import { ATTRIBUTE_TYPES_WITH_DDM } from '../../models/measurement_meta';
import { TrialWizard } from '../../models/trial';
import { measurementMetaLibraryEdit } from '../../screens/measurement_meta_library_edit';
import { Deferred } from '../../utils/deferred';
import * as datasetsApi from '../../api/datasets';

const template =
  require('raw-loader!../../../templates/components/trial_wizard/trial_trait_details.html').default;

class TrialTraitDetailsScreen {
  loading = ko.observable(true);
  result: Deferred<{}>;
  entity = ko.observable<TrialTraitDetailData>();

  constructor(params: { id: string; result: Deferred<{}> }) {
    this.result = params.result;
    this.load(params.id);
  }

  private async load(id: string) {
    this.entity(await trialTraitDetail(id));
    this.loading(false);
  }

  formattedType(): string {
    for (let attr of ATTRIBUTE_TYPES_WITH_DDM) {
      if (attr.value === this.entity().type) {
        return attr.name();
      }
    }
  }

  formatDimNames(data: TrialTraitDetailMDM): string {
    return data.dim_names.join(', ');
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

const trialTraitDetailsScreen = {
  name: 'trial-trait-details',
  viewModel: TrialTraitDetailsScreen,
  template,
};

ko.components.register(trialTraitDetailsScreen.name, trialTraitDetailsScreen);

export function openTrialTraitDetails(id: string) {
  app.formsStackController.push({
    title: i18n.t('Trait details')(),
    name: trialTraitDetailsScreen.name,
    isBig: true,
    showNav: true,
    params: { id, result: new Deferred() },
  });
}

export function openTrialTraitEdit(
  id: string,
  wizard?: TrialWizard,
  onSaved?: (data: datasetsApi.MeasurementMetaData) => void
) {
  app.formsStackController.push({
    title: i18n.t('Trait edit')(),
    name: measurementMetaLibraryEdit.name,
    isBig: false,
    showNav: true,
    params: {
      management: false,
      forEdit: true,
      id,
      result: new Deferred(),
      wizard: wizard,
      onSaved: onSaved,
    },
  });
}
