import * as ko from 'knockout';

import i18n from '../i18n';
import { orgRegionApi } from '../api/org_regions';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { session } from '../session';
import { NameData } from '../api/names';
import { CountryData, UserCountryData } from '../api/countries';
import { SeasonData } from '../api/seasons';
import { getSeasonSearchConfig, getUserSearchConfig } from '../components/configs/search_configs';
import { UserData, UserRole } from '../api/users';
import { MONTH_OPTIONS } from '../utils';
import { COUNTRY_CODES_MAP } from '../utils/country_codes';
export const ROLE_OPTIONS: { name: string; value: UserRole }[] = [
  { name: i18n.t('Head')(), value: 'head' },
  { name: i18n.t('Editor')(), value: 'editor' },
  { name: i18n.t('Viewer')(), value: 'viewer' },
];

export class Country {
  months = MONTH_OPTIONS;

  id = ko.observable<string>(null);
  name = ko.observable('').extend({
    required: true,
    serverError: true,
  });
  isoCountryCode = ko.observable('').extend({
    required: true,
    serverError: true,
  });
  regions = ko.observableArray<NameData>().extend({
    required: session.isS2bim(),
  });
  season1 = ko.observable<SeasonData>(null).extend({ serverError: true });
  monthOfHarvest1 = ko.observable(1);
  season2 = ko.observable<SeasonData>(null).extend({ serverError: true });
  monthOfHarvest2 = ko.observable(1);
  season3 = ko.observable<SeasonData>(null).extend({ serverError: true });
  monthOfHarvest3 = ko.observable(1);
  season4 = ko.observable<SeasonData>(null).extend({ serverError: true });
  monthOfHarvest4 = ko.observable(1);
  season5 = ko.observable<SeasonData>(null).extend({ serverError: true });
  monthOfHarvest5 = ko.observable(1);

  canEdit = ko.pureComputed(() => {
    if (session.isReadOnlyAdmin()) {
      return false;
    }
    return !this.id() || this.regions().length === 0 || session.isAtLeastEditorFor({ id: this.id() });
  });

  userRoles = ko.observableArray<UserCountry>();
  canEditRoles = false;

  editUrl = ko.pureComputed(() => {
    return '/countries/' + this.id();
  });

  regionNames = ko.pureComputed(() => {
    return this.regions()
      .map((region) => region.name)
      .join(', ');
  });

  regionsSearchConfig: FormSelectSearchConfiguration<NameData> = {
    getSummaryName: (region) => {
      return region.name;
    },
    list: (params) => {
      return orgRegionApi.list({ role: 'editor', ...params });
    },
    entities: this.regions,
  };

  season1SearchConfig = getSeasonSearchConfig(this.season1);
  season2SearchConfig = getSeasonSearchConfig(this.season2);
  season3SearchConfig = getSeasonSearchConfig(this.season3);
  season4SearchConfig = getSeasonSearchConfig(this.season4);
  season5SearchConfig = getSeasonSearchConfig(this.season5);
  subscriptions: KnockoutSubscription[] = [];

  constructor(public data?: CountryData) {
    if (data) {
      this.id(data.id);
      this.name(data.name);
      this.isoCountryCode(data.iso_country_code);
      this.regions(data.regions);
      this.season1(data.season_1 || null);
      this.monthOfHarvest1(data.month_of_harvest_1 || 1);
      this.season2(data.season_2 || null);
      this.monthOfHarvest2(data.month_of_harvest_2 || 1);
      this.season3(data.season_3 || null);
      this.monthOfHarvest3(data.month_of_harvest_3 || 1);
      this.season4(data.season_4 || null);
      this.monthOfHarvest4(data.month_of_harvest_4 || 1);
      this.season5(data.season_5 || null);
      this.monthOfHarvest5(data.month_of_harvest_5 || 1);
      if (data.user_roles) {
        this.userRoles(data.user_roles.map((data) => new UserCountry(data)));
      }
      this.canEditRoles =
        session.isS2bim() && (session.isAdmin() || session.hasCountryRole(data.id, 'head'));
    } else {
      this.subscriptions.push(
        this.name.subscribe(() => {
          let parsedCountryName: string = this.name().toLowerCase().trim();
          let isoCountryCode: string = COUNTRY_CODES_MAP[parsedCountryName];
          if (isoCountryCode) {
            this.isoCountryCode(COUNTRY_CODES_MAP[parsedCountryName]);
          }
        })
      );
      this.canEditRoles = session.isS2bim() && session.isAdmin();
    }

    if (session.isAdmin()) {
      this.regionsSearchConfig.create = {
        title: i18n.t('Region')(),
        componentName: 'org-region-edit',
      };
    }
  }

  addRole = () => {
    this.userRoles.push(new UserCountry());
  };

  removeRole = (role: UserCountry) => {
    this.userRoles.remove(role);
  };

  dispose() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }

  toData(): CountryData {
    return {
      id: this.id(),
      name: this.name(),
      iso_country_code: this.isoCountryCode(),
      regions: this.regions(),
      season_1: this.season1(),
      month_of_harvest_1: this.monthOfHarvest1(),
      season_2: this.season2(),
      month_of_harvest_2: this.monthOfHarvest2(),
      season_3: this.season3(),
      month_of_harvest_3: this.monthOfHarvest3(),
      season_4: this.season4(),
      month_of_harvest_4: this.monthOfHarvest4(),
      season_5: this.season5(),
      month_of_harvest_5: this.monthOfHarvest5(),
      user_roles: this.userRoles().map((role) => role.toData()),
    };
  }
}

export class UserCountry {
  user = ko.observable<UserData>(null).extend({
    required: true,
  });
  role = ko.observable<UserRole>('editor');

  roleOptions = ROLE_OPTIONS;

  userSearchConfig = getUserSearchConfig(this.user);

  constructor(data?: UserCountryData) {
    if (data) {
      this.user(data.user);
      this.role(data.role);
    }
  }

  toData(): UserCountryData {
    return {
      user: this.user(),
      role: this.role(),
    };
  }
}
