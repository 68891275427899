import * as dashboardApi from '../../api/dashboard';
import { parseDate, serializeDateTime } from '../../api/serialization';
import { FileUploadEndpoint } from '../../cloud_storage_upload';
import { omit, isMatch, isNil } from 'lodash';
import i18n from '../../i18n';
import { translate } from '../../i18n_text';
import {
  DEC_REGEXP,
  DEC_REGEXP_DEC_DIGITS_GROUP,
  DEC_REGEXP_INT_DIGITS_GROUP,
  INT_REGEXP,
  INT_REGEXP_DIGITS_GROUP,
  MAX_DECIMALS,
  MAX_DIGITS,
} from '../../models/value_meta';
import { tryFormatDate } from '../../utils';
import { Deferred } from '../../utils/deferred';
import { uuid4 } from '../../utils/uuid';
import { validationErrorScroller } from '../../utils/validation_error';
import {
  DataEntryExtraData,
  getDataEntryExtraUploadEndpoint,
  getFactUploadEndpoint,
  listDataEntryExtra,
  saveDataEntryExtra,
  saveObservations,
  SaveObservationsRequest,
  SaveObservationsRespone,
} from '../data_entry_api';
import { openChangeReasonDialog } from '../../components/change_reason_dialog';
import { ChangeReason } from '../../models/attribute_meta';

interface DataEntryRef {
  trialId: string;
  svId: string;
  siteId: string;
  visitId: string;
}

export interface DataEntryEditModelSubscriber {
  onDataEntryEditModelChanged(edit: DataEntryEditModel, changeOriginator: string): void;
  onDataEntryEditModelSaved?(edit: DataEntryEditModel): void;
}

function parseSparseMatrix<T>(arr: (number | T)[], nCols: number): T[][] {
  let res: T[][] = [];

  for (let i = 0; i < arr.length; i += 2) {
    const idx = arr[i] as number;
    const value = arr[i + 1] as T;
    const row = Math.floor(idx / nCols);
    const col = idx % nCols;

    res[row] = res[row] || [];
    res[row][col] = value;
  }

  return res;
}

export class OverviewSparseData {
  ids: string[][];
  values: {}[][];
  historic_values: {}[][];
  reason_values: {}[][];
  validationErrors: string[][] = []; // one per value

  constructor(private data: dashboardApi.OverviewData) {
    this.ids = parseSparseMatrix(data.fact_ids, data.mm_ids.length);
    this.values = parseSparseMatrix(data.values, data.mm_ids.length);
    this.historic_values = parseSparseMatrix(data.historic_values, data.mm_ids.length);
    this.reason_values = parseSparseMatrix(data.reason_values, data.mm_ids.length);
  }

  valueAt<T>(arr: T[][], rowIdx: number, colIdx: number): T {
    return this.rowAt(arr, rowIdx, colIdx)?.[colIdx];
  }

  setValueAt<T>(arr: T[][], rowIdx: number, colIdx: number, value: T): void {
    this.ensureRowAt(arr, rowIdx, colIdx)[colIdx] = value;
  }

  idAt(rowIdx: number, colIdx: number): string {
    return this.rowAt(this.ids, rowIdx, colIdx)?.[this.data.value_col_fact_idxs[colIdx]];
  }

  plotDimensionIdsAt(rowIdx: number): string[] {
    return this.data.rows[rowIdx].ordered_plot_dimension_ids;
  }

  setIdAt(rowIdx: number, colIdx: number, value: string): void {
    this.ensureRowAt(this.ids, rowIdx, colIdx)[this.data.value_col_fact_idxs[colIdx]] = value;
  }

  mmIdAt(colIdx: number) {
    return this.data.mm_ids[colIdx];
  }

  isDataEntryAllowedAt(rowIdx: number, colIdx: number): boolean {
    const mmId = this.mmIdAt(colIdx);
    const plotDimensions = this.plotDimensionIdsAt(rowIdx);
    const limitToDimensionIdsByDimensionMetaId = this.data.mm_trial_limit_to_by_dimension_meta_id[mmId];

    for (const [dimensionMetaId, limitToDimensionIds] of Object.entries(
      limitToDimensionIdsByDimensionMetaId
    )) {
      if (limitToDimensionIds.length === 0) {
        continue;
      }
      const dimensionIndex = this.data.ordered_plot_dimensions_meta_ids.indexOf(Number(dimensionMetaId));
      const plotDimensionId = plotDimensions[dimensionIndex];
      if (!limitToDimensionIds.includes(plotDimensionId)) {
        return false;
      }
    }

    return true;
  }

  private ensureRowAt<T>(arr: T[][], rowIdx: number, colIdx: number): T[] {
    const dsId = this.data.dataset_ids[colIdx];
    const idx = this.data.rows[rowIdx].value_row_idx[dsId];

    arr[idx] = arr[idx] || [];

    return arr[idx];
  }

  private rowAt<T>(arr: T[][], rowIdx: number, colIdx: number): T[] | undefined {
    const dsId = this.data.dataset_ids[colIdx];
    const idx = this.data.rows[rowIdx].value_row_idx[dsId];

    return arr[idx];
  }
}

export type DataEntryMoveDirection = 'up' | 'down' | 'left' | 'right';

const SAVE_EVERY_MS = 1000;

export class DataEntryEditModel {
  private selection: number | null = null;
  private subscribers: DataEntryEditModelSubscriber[] = [];

  private nCols: number;
  private mmNames: string[] = [];

  private lastEditAction = new Date();
  private lastSaveTask: Deferred<void> | null = null;
  private clientIds = new Set<string>();
  private nextFactOrder = 0;
  private saveQueue: SaveObservationsRequest;
  private saveToken: number;
  public changed: Record<string, string>;
  private enforceChangeReasonForEditingObservations: boolean = false;

  // fact id -> DataEntryExtraData[]
  // used as read-only in grid, re-fetched right before editing to avoid stale data
  private cachedExtra = new Map<string, DataEntryExtraData[]>();

  private tableToScroll: Element | HTMLElement = null;
  constructor(
    private ref: DataEntryRef,
    public data: dashboardApi.OverviewData,
    public sparseData: OverviewSparseData
  ) {
    this.nCols = data.mm_ids.length;
    if (data.header_groups.length > 0) {
      for (let mms of data.header_groups[data.header_group_mm_idx]?.headers) {
        for (let i = 0; i < mms.size; i++) {
          this.mmNames.push(mms.title);
        }
      }
    }
    this.saveQueue = this.emptySaveQueue();
    this.saveToken = setInterval(this.autoSave, SAVE_EVERY_MS) as any;
    this.changed = {};
    this.tableToScroll = null;

    this.enforceChangeReasonForEditingObservations = data.enforce_reason_for_editing_observations;
  }

  private emptySaveQueue() {
    return {
      trial_id: this.ref.trialId,
      sv_id: this.ref.svId || null,
      site_id: this.ref.siteId || null,
      visit_id: this.ref.visitId || null,
      values: {},
    };
  }

  dispose() {
    if (this.saveToken) {
      clearInterval(this.saveToken);
      this.saveToken = null;
    }
    validationErrorScroller([]);
  }

  async saveNow() {
    if (this.lastSaveTask) {
      await this.lastSaveTask.promise;
    }
    await this.autoSave();
  }

  isSelected(rowIdx: number, colIdx: number) {
    const idx = rowIdx * this.nCols + colIdx;
    return idx === this.selection;
  }

  colForSelection(selection: number) {
    return selection % this.nCols;
  }

  rowForSelection(selection: number) {
    return Math.floor(selection / this.nCols);
  }

  selectedCol() {
    return this.colForSelection(this.selection);
  }

  selectedRow() {
    return this.rowForSelection(this.selection);
  }

  selectedPlotName() {
    if (this.selection === null) {
      return '';
    }

    return this.data.rows[this.selectedRow()].plot_name;
  }

  selectedPlotDimNames() {
    if (this.selection === null) {
      return '';
    }

    return this.data.rows[this.selectedRow()].plot_dimensions.map((dim) => translate(dim)).join(', ');
  }

  selectedTitle() {
    if (this.selection === null) {
      return '';
    }

    const plotName = this.selectedPlotName();
    const mmName = this.mmNames[this.selectedCol()];

    return `${plotName} - ${mmName}`;
  }

  selectedMeasurementMetaId() {
    return parseInt(this.data.mm_ids[this.selectedCol()]);
  }

  selectedType(): string {
    if (this.selection === null) {
      return 'none';
    }

    return this.data.value_types[this.selectedCol()];
  }

  selectedValue(): {} {
    return this.selectedCell(this.sparseData.values);
  }

  selectedValidationError(): string {
    return this.selectedCell(this.sparseData.validationErrors) ?? '';
  }

  validationError(rowIdx: number, colIdx: number): string {
    return this.sparseData.valueAt(this.sparseData.validationErrors, rowIdx, colIdx) ?? '';
  }

  private selectedCell<T>(cells: T[][]): T | null {
    if (this.selection === null) {
      return null;
    }

    return this.sparseData.valueAt(cells, this.selectedRow(), this.selectedCol());
  }

  selectedOptions(): { id: string; name: string }[] {
    if (this.selection === null) {
      return [];
    }

    const validation = this.data.validations[this.data.mm_ids[this.selectedCol()]];
    if (validation?.options) {
      return [{ id: '', name: i18n.t('Select')() }].concat(
        validation.options.sort((a, b) => (a.value || 0) - (b.value || 0))
      );
    }

    return [];
  }

  async setValue(value: {}, originator: string, valueUnitId: number | null) {
    if (this.selection === null) {
      return;
    }

    this.lastEditAction = new Date();

    const row = this.selectedRow();
    const col = this.selectedCol();
    // The function is called when the input goes out of focus, so we need
    // to check if the value is the same as the one in the sparseData
    if (this.sparseData.valueAt(this.sparseData.values, row, col) === value) {
      return;
    }

    let changeReason = null;
    let shouldOpenChangeReasonDialog = false;
    const existingValue = this.sparseData.valueAt(this.sparseData.values, row, col);
    if (this.enforceChangeReasonForEditingObservations && !isNil(existingValue)) {
      shouldOpenChangeReasonDialog = true;
    }
    this.sparseData.setValueAt(this.sparseData.values, row, col, value);

    const error = this.validateSelected();
    if (!error) {
      if (shouldOpenChangeReasonDialog) {
        try {
          changeReason = (await openChangeReasonDialog()) as ChangeReason;
        } catch (e) {
          // The user cancelled the dialog, so we should revert the change
          this.sparseData.setValueAt(this.sparseData.values, row, col, existingValue);
          // Makes sure that in the case of a cancelled dialog, the data entry bar is changed

          this.emit(originator);
          return;
        }
      }
      this.queueChange(row, col, value, valueUnitId, changeReason);
    }
    this.emit(originator);
  }

  private validateSelected() {
    if (this.selection === null) {
      return;
    }

    const col = this.selectedCol();
    const row = this.selectedRow();
    const value = this.selectedValue();

    let error = '';
    if (value) {
      const type = this.data.value_types[col];
      const validation = this.data.validations[this.data.mm_ids[col]];
      if (type === 'integer') {
        const match = value.toString().match(INT_REGEXP);
        if (!match) {
          error = i18n.t('Invalid integer')();
        } else if (match[INT_REGEXP_DIGITS_GROUP].length > MAX_DIGITS) {
          error = i18n.t('Too many digits')();
        }
      }
      if (type === 'decimal') {
        const match = value.toString().match(DEC_REGEXP);
        const maxDecimals = validation.max_decimals ?? MAX_DECIMALS;
        if (!match) {
          error = i18n.t('Invalid number')();
        } else if (
          (match[DEC_REGEXP_INT_DIGITS_GROUP]?.length ?? 0) +
            (match[DEC_REGEXP_DEC_DIGITS_GROUP]?.length ?? 0) >
          MAX_DIGITS
        ) {
          error = i18n.t('Too many digits')();
        } else if ((match[DEC_REGEXP_DEC_DIGITS_GROUP]?.length ?? 0) > maxDecimals) {
          error = i18n.t('Too many decimal digits (max {{max}})', {
            max: maxDecimals,
          })();
        }
      }
      if (type === 'integer' || type === 'decimal') {
        const parsed = type === 'integer' ? parseInt(value.toString(), 10) : parseFloat(value.toString());
        if (!isNaN(parsed)) {
          if (validation.max_number != null && parsed > validation.max_number) {
            error = i18n.t('Observation cannot be greater than {{max}}', {
              max: validation.max_number,
            })();
          } else if (validation.min_number != null && parsed < validation.min_number) {
            error = i18n.t('Observation cannot be lower than {{min}}', {
              min: validation.min_number,
            })();
          }
        }
      } else if (type === 'date') {
        const time = parseDate(value as string)?.getTime();
        const min = parseDate(validation.min_date);
        const max = parseDate(validation.max_date);

        if (time != null && min != null && time < min.getTime()) {
          error = i18n.t('Observation cannot be lower than {{min}}', {
            min: tryFormatDate(min),
          })();
        } else if (time != null && max != null && time > max.getTime()) {
          error = i18n.t('Observation cannot be greater than {{max}}', {
            max: tryFormatDate(max),
          })();
        }
      }
    }
    this.sparseData.setValueAt(this.sparseData.validationErrors, row, col, error);

    const element = document.querySelector(`td[rowIdx="${row}"][colIdx="${col}"]`) as HTMLElement;
    const uniqueID = `${row}-${col}`;

    if (error) {
      if (!this.tableToScroll) {
        this.tableToScroll = document.querySelector('div.overview-table-container');
      }
      validationErrorScroller.remove((item) => item.uniqueID === uniqueID);
      validationErrorScroller.push({
        elementToFocus: element,
        elementToScroll: this.tableToScroll,
        topCoordinate: this.tableToScroll.scrollTop,
        leftCoordinate: this.tableToScroll.scrollLeft,
        uniqueID: uniqueID,
      });
    } else {
      validationErrorScroller.remove((item) => item.uniqueID === uniqueID);
    }
    return error;
  }

  hasUnsavedChanges() {
    return (
      !!this.lastSaveTask || this.hasValidationErrors() || Object.keys(this.saveQueue.values).length > 0
    );
  }

  private shouldSave() {
    if (new Date().getTime() - this.lastEditAction.getTime() < SAVE_EVERY_MS) {
      return false;
    }

    return !this.lastSaveTask && Object.keys(this.saveQueue.values).length > 0;
  }

  private hasValidationErrors(): boolean {
    for (let row of this.sparseData.validationErrors) {
      if (row) {
        for (let error of row) {
          if (error) {
            return true;
          }
        }
      }
    }

    return false;
  }
  private queueChange(
    rowIdx: number,
    colIdx: number,
    value: {},
    valueUnitId: number | null,
    changeReason: ChangeReason | null
  ) {
    let mmId = this.data.mm_ids[colIdx];
    this.queueEmptyFactChange(this.saveQueue, rowIdx, colIdx)[mmId] = [value, valueUnitId, changeReason];
  }

  isRowColChanged(factId: string, mm: string) {
    const res = this.changed[`${factId}-${mm}`];
    return res !== undefined && res !== null;
  }

  private queueEmptyFactChange(
    saveQueue: SaveObservationsRequest,
    rowIdx: number,
    colIdx: number
  ): { [mmId: string]: {} } {
    let dsId = this.data.dataset_ids[colIdx];
    let dims = this.data.col_dim_ids[colIdx];
    let plotId = this.data.rows[rowIdx].plot_id;
    let treatmentId = this.data.rows[rowIdx].plot_treatment_id;

    let factId = this.factId(rowIdx, colIdx);
    if (!factId) {
      factId = uuid4();
      this.clientIds.add(factId);
      this.sparseData.setIdAt(rowIdx, colIdx, factId);
    }

    let client: number = null;
    if (this.clientIds.has(factId)) {
      // NOTE: any column with the same fact id is fine here
      client = rowIdx * this.nCols + colIdx;
    }

    let factChanges = saveQueue.values[factId] ?? {
      client,
      order: ++this.nextFactOrder,
      dataset_id: dsId,
      plot_id: plotId,
      treatment_id: treatmentId,
      dims,
      mms: {} as { [key: string]: {} },
    };
    saveQueue.values[factId] = factChanges;
    this.changed[`${factId}-${this.sparseData.mmIdAt(colIdx)}`] = '1';
    return factChanges.mms;
  }

  private autoSave = async () => {
    if (!this.shouldSave()) {
      return;
    }

    this.lastSaveTask = new Deferred();
    const toSave = this.saveQueue;
    this.saveQueue = this.emptySaveQueue();
    try {
      const response = await saveObservations(toSave);
      this.applySaveResult(response);
      this.showSaved(toSave);
      this.changed = {};
    } catch (e) {
      // couldn't save, merge save queue
      for (let factId of Object.keys(toSave.values)) {
        for (let mmId of Object.keys(toSave.values[factId].mms)) {
          if (this.saveQueue.values[factId] == null) {
            this.saveQueue.values[factId] = toSave.values[factId];
          } else if (this.saveQueue.values[factId]?.mms[mmId] == null) {
            this.saveQueue.values[factId].mms[mmId] = toSave.values[factId].mms[mmId];
          }
        }
      }
      throw e;
    } finally {
      this.lastSaveTask.resolve();
      this.lastSaveTask = null;
    }
  };
  private showSaved(saveResult: SaveObservationsRequest) {
    for (let factId of Object.keys(saveResult.values)) {
      const { mms } = saveResult.values[factId];
      for (let mm of Object.keys(mms)) {
        // find all cells which are changed
        document.querySelectorAll(`td[fact_id="${factId}"][mm="${mm}"]`).forEach((elem) => {
          if (elem && elem.classList.contains('loading-cell')) {
            elem.classList.remove('loading-cell');
            elem.classList.add('loaded-cell');
            setTimeout(function () {
              elem.classList.remove('loaded-cell');
            }, 1000);
          }
        });
      }
    }
    for (let sub of this.subscribers) {
      if (sub.onDataEntryEditModelSaved) {
        sub.onDataEntryEditModelSaved(this);
      }
    }
  }
  private applySaveResult(saveResult: SaveObservationsRespone) {
    const clientToFactId: string[] = [];
    for (let { client, fact_id } of saveResult.fact_ids) {
      clientToFactId[client] = fact_id;

      const rowIdx = Math.floor(client / this.nCols);
      const colIdx = client % this.nCols;
      this.sparseData.setIdAt(rowIdx, colIdx, fact_id);
    }

    for (let factId of Object.keys(this.saveQueue.values)) {
      const factValues = this.saveQueue.values[factId];
      const newFactId = clientToFactId[factValues.client];
      if (factValues.client !== null && newFactId) {
        this.saveQueue.values[newFactId] = {
          ...factValues,
          client: null,
        };
        delete this.saveQueue.values[factId];
      }
    }
  }

  async saveExtras(extras: DataEntryExtraData[]) {
    if (this.selection === null) {
      return;
    }

    const row = this.selectedRow();
    const col = this.selectedCol();

    let factId = this.factId(row, col);
    if (!factId) {
      if (extras.length === 0) {
        // nothing to do
        return;
      }

      // block auto save from running while we're creating an empty fact
      this.lastSaveTask = new Deferred();
      try {
        // create empty fact
        const toSave = this.emptySaveQueue();
        this.queueEmptyFactChange(toSave, row, col);
        this.applySaveResult(await saveObservations(toSave));
        // now the id is guaranteed to exist
        factId = this.factId(row, col);
      } finally {
        this.lastSaveTask.resolve();
        this.lastSaveTask = null;
      }
    }

    await saveDataEntryExtra({
      fact_id: factId,
      measurement_meta_id: this.selectedMeasurementMetaId(),
      extras,
    });

    if (this.cachedExtra.has(this.getCacheKey(factId, this.selectedMeasurementMetaId()))) {
      this.cachedExtra.delete(this.getCacheKey(factId, this.selectedMeasurementMetaId()));
    }

    this.emit('extraSave');
  }

  canEditInline(rowIdx: number, colIdx: number): boolean {
    return (
      ['integer', 'decimal', 'string', 'string_long', 'barcode'].indexOf(this.data.value_types[colIdx]) !==
        -1 && this.canEdit(rowIdx, colIdx)
    );
  }

  canEdit(rowIdx: number, colIdx: number): boolean {
    return (
      !!this.factId(rowIdx, colIdx) ||
      this.data.copy_indexes[colIdx] === 0 ||
      !!this.factId(rowIdx, this.data.prev_copy_first_cols[colIdx])
    );
  }

  private factId(rowIdx: number, colIdx: number) {
    return this.sparseData.idAt(rowIdx, colIdx);
  }

  select(rowIdx: number, colIdx: number) {
    this.selection = rowIdx * this.nCols + colIdx;
    this.validateSelected();
    this.emit('');
  }

  move(direction: DataEntryMoveDirection) {
    if (this.selection === null) {
      return;
    }

    let delta = 0;
    if (direction === 'up') {
      delta = -this.nCols;
    } else if (direction === 'down') {
      delta = this.nCols;
    } else if (direction === 'left') {
      delta = -1;
    } else if (direction === 'right') {
      delta = 1;
    }

    const newSelection = this.selection + delta;
    if (newSelection >= 0 && newSelection < this.nCols * this.data.rows.length) {
      // Avoid moving to cell where data entry is not allowed.
      const newRow = this.rowForSelection(newSelection);
      const newCol = this.colForSelection(newSelection);
      if (this.sparseData.isDataEntryAllowedAt(newRow, newCol)) {
        this.selection = newSelection;
        this.emit('');
      }
    }
  }

  extraPictureModel(): DataEntryPictureModel {
    return new ExtraDataEntryPictureModel();
  }

  getCacheKey(factId: string, measurementMetaId: number) {
    return `${factId}_${measurementMetaId}`;
  }

  async selectedExtras(options: { useCached: boolean }): Promise<DataEntryExtraData[]> {
    if (this.selection === null) {
      return [];
    }

    const factId = this.factId(this.selectedRow(), this.selectedCol());
    if (!factId || this.clientIds.has(factId)) {
      return [];
    }

    const measurementMetaId = this.selectedMeasurementMetaId();

    if (!this.cachedExtra.has(this.getCacheKey(factId, measurementMetaId)) || !options.useCached) {
      this.cachedExtra.set(
        this.getCacheKey(factId, measurementMetaId),
        await listDataEntryExtra(factId, measurementMetaId)
      );
    }

    return this.cachedExtra.get(this.getCacheKey(factId, measurementMetaId));
  }

  subscribe(subscriber: DataEntryEditModelSubscriber) {
    subscriber.onDataEntryEditModelChanged(this, '');
    if (subscriber.onDataEntryEditModelSaved) {
      subscriber.onDataEntryEditModelSaved(this);
    }
    this.subscribers.push(subscriber);
  }

  unsubscribe(subscriber: DataEntryEditModelSubscriber) {
    const idx = this.subscribers.indexOf(subscriber);
    if (idx > -1) {
      this.subscribers.splice(idx, 1);
    }
  }

  private emit(originator: string) {
    for (let sub of this.subscribers) {
      sub.onDataEntryEditModelChanged(this, originator);
    }
  }
}

export interface DataEntryPictureData {
  id: string;
  file_name: string;
  user_file_name: string;
  file_url: string;
  mime_type: string;
  comment: string;
  created: string;
  measurement_meta_id: number;
}

export interface DataEntryPictureModel {
  acceptMimeType: string;
  canDelete(idx: number): boolean;
  canEditPicture(): boolean;
  pictures(editModel: DataEntryEditModel): Promise<DataEntryPictureData[]>;
  save(editModel: DataEntryEditModel, data: DataEntryPictureData[]): Promise<void>;
  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint>;
}

export interface MultiPicturesData {
  id: string;
  created: string;
  name: string;
  url: string;
  comment: string;
  modified: string;
  measurement_meta_id: number;
}

export class MultiPicturesModel implements DataEntryPictureModel {
  private data: MultiPicturesData[];

  constructor(data: MultiPicturesData[] | null) {
    this.data = data || [];
  }

  urls(): string[] {
    return this.existing().map((x) => x.url);
  }

  private existing(): MultiPicturesData[] {
    const filtered = this.data.filter((x) => x.created && x.url);
    filtered.sort((a, b) => a.created.localeCompare(b.created));

    return filtered;
  }

  // DataEntryPictureModel

  acceptMimeType = 'image/*';

  canDelete(idx: number) {
    return true;
  }

  canEditPicture() {
    return false;
  }

  pictures(): Promise<DataEntryPictureData[]> {
    return Promise.resolve(
      this.existing().map((mp) => ({
        id: mp.id,
        file_name: mp.name,
        user_file_name: mp.name,
        file_url: mp.url,
        mime_type: 'image/*',
        comment: mp.comment,
        created: mp.created,
        measurement_meta_id: mp.measurement_meta_id,
      }))
    );
  }

  async save(editModel: DataEntryEditModel, newData: DataEntryPictureData[]): Promise<void> {
    const newById = new Map<string, DataEntryPictureData>();
    for (const data of newData) {
      newById.set(data.id, data);
    }

    const added = new Set<string>();
    const updatedValue: MultiPicturesData[] = [];
    for (const pic of this.data) {
      updatedValue.push({
        id: pic.id,
        created: newById.has(pic.id) ? pic.created : null,
        name: pic.name,
        url: pic.url,
        comment: newById.get(pic.id)?.comment ?? pic.comment,
        measurement_meta_id: pic.measurement_meta_id,
        modified:
          newById.get(pic.id)?.comment !== pic.comment ? serializeDateTime(new Date()) : pic.modified,
      });
      added.add(pic.id);
    }
    for (const data of newData) {
      if (data.id && added.has(data.id)) {
        continue;
      }

      updatedValue.push({
        id: data.id ?? uuid4(),
        created: data.created,
        name: data.file_name,
        url: data.file_url,
        comment: data.comment ? data.comment : undefined,
        modified: data.comment ? serializeDateTime(new Date()) : undefined,
        measurement_meta_id: data.measurement_meta_id || undefined,
      });
    }

    const isSameData =
      updatedValue.length == this.data.length &&
      updatedValue.every((newPicture) =>
        isMatch(
          newPicture,
          omit(
            this.data.find((oldPic) => oldPic.id == newPicture.id),
            ['measurement_meta_id']
          )
        )
      );

    this.data = updatedValue;
    if (!isSameData) {
      await editModel.setValue(updatedValue, '', null);
    }

    return Promise.resolve();
  }

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return getFactUploadEndpoint(contentType);
  }
}

class ExtraDataEntryPictureModel implements DataEntryPictureModel {
  acceptMimeType = '*/*';

  canDelete(idx: number) {
    return idx !== 0;
  }

  canEditPicture() {
    return true;
  }

  async pictures(editModel: DataEntryEditModel): Promise<DataEntryPictureData[]> {
    let res = (await editModel.selectedExtras({ useCached: false })).map((data) => ({
      ...data,
      created: null,
    }));

    const commentOnlyExtra = res.find((data) => data.file_url == '' && data.comment !== '');

    if (res.length === 0 || !commentOnlyExtra) {
      res = [
        {
          id: null,
          file_name: '',
          user_file_name: '',
          file_url: '',
          mime_type: '',
          comment: '',
          created: null,
          measurement_meta_id: null,
        },
        ...res,
      ];
    }

    return res;
  }

  save(editModel: DataEntryEditModel, data: DataEntryPictureData[]): Promise<void> {
    if (data.length === 1 && !data[0].file_url && !data[0].comment && !data[0].id) {
      data = [];
    }

    return editModel.saveExtras(data);
  }

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return getDataEntryExtraUploadEndpoint(contentType);
  }
}
