import * as ko from 'knockout';

import { DimensionMeta } from '../models/dimension_meta';
import { CropVariety } from '../models/crop_variety';
import { CROP_VARIETY_SLUG } from '../api/dimension_metas';
import * as cropVarietiesApi from '../api/crop_varieties';
import { BaseDimensionRecordEditDelegate } from '../components/base_dimension_record_edit';
import { Deferred } from '../utils/deferred';
import { getCropSearchConfig } from '../components/configs/search_configs';
import { asI18nText } from '../i18n_text';
import { CropData } from '../api/crops';
import { ValidationResult } from '../api/request';
import { BaseForm } from './base_form';
import { KOMaybeArray } from '../utils/ko_utils';
import { asArray } from '../utils';
import i18n from '../i18n';
import { canEditCropVariety } from '../permissions';
import { session } from '../session';

let template = require('raw-loader!../../templates/crop_variety_edit.html').default;

class CropVarietyEditScreen
  implements BaseDimensionRecordEditDelegate<cropVarietiesApi.CropVarietyData, CropVariety>
{
  disableApplications = true;
  allowAnonymize = true;
  allowLinkingCrops = false; // Only one crop for Crop Variety
  anyoneCanEdit = false;
  canEdit = ko.observable(canEditCropVariety(session.tenant()));
  anonymizedCodeText = APP_CONFIG.AUTO_CV_CODES
    ? i18n.t('Code (leave empty for automatic code)')()
    : undefined;

  dimensionMetaId = CROP_VARIETY_SLUG;
  dimensionId: string;
  result: Deferred<cropVarietiesApi.CropVarietyData>;
  private initialName: string;
  private initialCrop: KOMaybeArray<CropData>;
  private selectCreatedEntity: (entity: cropVarietiesApi.CropVarietyData) => void;

  dimension = ko.observable<CropVariety>(null);

  cropSearchConfig = ko.pureComputed(() => {
    return getCropSearchConfig(this.dimension() ? this.dimension().crop : undefined);
  });

  addAnother: (data: cropVarietiesApi.CropVarietyData, dm: DimensionMeta) => void = undefined;

  onNameOverrideChange: (name: string) => void;
  nameOverride: string;

  constructor(params: {
    id: string;
    initialName?: string;
    initialCrop?: KOMaybeArray<CropData>;
    multi?: boolean;
    result?: Deferred<cropVarietiesApi.CropVarietyData>;
    onNameOverrideChange?: (name: string) => void;
    nameOverride?: string;
    selectCreatedEntity: (entity: cropVarietiesApi.CropVarietyData) => void;
  }) {
    this.dimensionId = params.id;
    this.result = params.result;
    this.initialName = params.initialName;
    this.initialCrop = params.initialCrop;
    this.selectCreatedEntity = params.selectCreatedEntity;
    this.onNameOverrideChange = params.onNameOverrideChange;
    this.nameOverride = params.nameOverride;

    if (params.multi) {
      this.addAnother = (data, dm) => {
        if (this.selectCreatedEntity) {
          this.selectCreatedEntity(data);
        }

        let cv = this.instantiate(undefined, dm);
        cv.crop(this.dimension().crop());
        this.dimension(cv);
      };
    }
  }

  fetch(dimensionMetaId: string, id: string) {
    return cropVarietiesApi.retrieve(id);
  }

  instantiate(data: cropVarietiesApi.CropVarietyData, dimensionMeta: DimensionMeta) {
    let cv = new CropVariety(data, dimensionMeta);
    if (!data) {
      if (this.initialName) {
        cv.nameJson(asI18nText(this.initialName));
      }
      if (this.initialCrop) {
        let crops = asArray(this.initialCrop());
        if (crops.length === 1) {
          cv.crop(crops[0]);
        }
      }
    }

    return cv;
  }

  saveRequest(data: cropVarietiesApi.CropVarietyData) {
    return cropVarietiesApi.save(data);
  }

  onBeforeSave(form: BaseForm<cropVarietiesApi.CropVarietyData>) {
    for (let stage of this.dimension().stages()) {
      form.clearModelServerErrors(stage);
    }
  }

  onRemoteValidation(form: BaseForm<cropVarietiesApi.CropVarietyData>, validation: ValidationResult) {
    if (!validation.isValid && validation.errors['stages']) {
      let stagesErrors: any = validation.errors['stages'];

      for (let i = 0; i < stagesErrors.length; i++) {
        form.applyModelServerErrors(this.dimension().stages()[i], stagesErrors[i]);
      }
    }
  }
}

export let cropVarietyEdit = {
  name: 'crop-variety-edit',
  viewModel: CropVarietyEditScreen,
  template: template,
};

ko.components.register(cropVarietyEdit.name, cropVarietyEdit);
