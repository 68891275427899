import * as ko from 'knockout';

ko.bindingHandlers['google-sign-in'] = {
  init: (element: Element, valueAccessor: () => any) => {
    // the google sign in button doesn't really support
    // being removed, and even creating it on the fly is
    // problematic. The most reliable way to to handle it
    // is to pre-render it in index.html, inside a hidden container.
    // We then move the element from that container and back
    // when creating/removing a google-sign-in binding.
    // Of course, this means you can't have more than 1
    // of these bindings on a page, which I guess is not an issue.

    element.appendChild(document.getElementById('sign-in'));

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      document.getElementById('sign-in-container').appendChild(document.getElementById('sign-in'));
    });

    return { controlsDescendantBindings: true };
  },
};
