import * as ko from 'knockout';

import i18n from '../i18n';
import { OrganizationTypeData } from '../api/organizations';
import { session } from '../session';
import { I18nText } from '../i18n_text';

export class OrganizationType {
  canSeeProducers = APP_CONFIG.CAN_SEE_PRODUCERS;

  publicTypeOptions = [
    { value: 'public', name: i18n.t('Public')() },
    { value: 'private', name: i18n.t('Private')() },
  ];

  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  publicType = ko.observable<'public' | 'private'>('private');
  useForBreeders = ko.observable(true);
  useForProducers = ko.observable(true);
  useForCustomers = ko.observable(true);
  canEdit = session.isAdmin;

  publicTypeName = ko.pureComputed(() => {
    return this.publicType() === 'public' ? i18n.t('Public')() : i18n.t('Private')();
  });

  editUrl = ko.pureComputed(() => {
    return '/organization_types/' + this.id();
  });

  constructor(public data?: OrganizationTypeData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.publicType(data.public ? 'public' : 'private');
      this.useForBreeders(data.use_for_breeders);
      this.useForProducers(data.use_for_producers);
      this.useForCustomers(data.use_for_customers);
    }
  }

  toData(): OrganizationTypeData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      public: this.publicType() === 'public',
      use_for_breeders: this.useForBreeders(),
      use_for_producers: this.useForProducers(),
      use_for_customers: this.useForCustomers(),
    };
  }
}
