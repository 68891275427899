import * as ko from 'knockout';
import i18n from '../i18n';
import { ItemSelectionTracker } from './list_loader';

const template = require('raw-loader!../../templates/components/bulk_remove_action.html').default;

class BulkRemoveActionViewSet<T> {
  selectionTracker: ItemSelectionTracker<T>;
  isDeleteEnabled = ko.observable<boolean>(false);
  onDelete: () => void;
  tooltipTitle = ko.observable<string>('');

  selectAllText = ko.pureComputed(() => {
    return i18n.t(
      [
        'bulk_remove_action_select_all_in_current_search',
        'Select all{{ total }} items in current search / filtering.',
      ],
      {
        total: this.selectionTracker.total() ? ' ' + this.selectionTracker.total().toString() : '',
      }
    )();
  });
  clearSelectionText = i18n.t(['bulk_remove_action_clear_selection', 'Clear selection'])();
  shouldDisplaySelectAllText = ko.pureComputed(() =>
    this.selectionTracker
      ? this.selectionTracker.allVisibleSelected() && !this.selectionTracker.allSelected()
      : false
  );
  shouldDisplayClearSelectionText = ko.pureComputed(() =>
    this.selectionTracker ? this.selectionTracker.allSelected() : false
  );

  constructor(params: {
    selectionTracker: ItemSelectionTracker<T>;
    isDeleteEnabled: boolean;
    onDelete: () => void;
    tooltipTitle: string;
  }) {
    this.selectionTracker = params.selectionTracker;
    this.isDeleteEnabled(params.isDeleteEnabled);
    this.onDelete = params.onDelete;
    this.tooltipTitle(params.tooltipTitle);
  }

  selectAll = () => {
    this.selectionTracker.selectAll();
  };

  clear = () => {
    this.selectionTracker.clear();
  };
}

ko.components.register('bulk-remove-action', {
  viewModel: BulkRemoveActionViewSet,
  template,
});
