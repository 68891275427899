import * as ko from 'knockout';

import i18n from '../i18n';
import { DataEntryPermissionData, BaseGroupData } from '../api/groups';
import { TrialDatasetData, listWithTrials, listDataEntryPermDms as datasetPermDms } from '../api/datasets';
import { DataEntryPermissionCondition } from './data_entry_permission_condition';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { listDataEntryPermDms as trialPermDms } from '../api/trials';
import { DimensionMetaData } from '../api/dimension_metas';
import { I18nText } from '../i18n_text';
import { getTrialSearchConfig, getGroupSearchConfig } from '../components/configs/search_configs';
import { FormNestedEntitiesConfiguration } from '../components/form_nested_entities';
import { ValidationResult } from '../api/request';
import { TrialSelectData } from '../api/base_trials';
import { deflateSingle } from '../api/serialization';
import { confirmTrialAssignment } from '../utils/plot_count_utils';

export class DataEntryCollection {
  perms = ko.observableArray<DataEntryPermission>();

  constructor(private options: { allowSelect: 'group' | 'trial'; trial: TrialSelectData }) {}

  initFrom(data: DataEntryPermissionData[]) {
    if (data) {
      this.perms(data.map((permData) => new DataEntryPermission(this.options, permData)));
    }
  }

  toData(): DataEntryPermissionData[] {
    let data = this.perms().map((perm) => perm.toData());
    if (this.options.trial) {
      for (let permData of data) {
        if (!permData.dataset_id) {
          permData.trial_id = this.options.trial;
        }
      }
    }

    return data;
  }

  applyServerErrors(validation: ValidationResult) {
    let dataEntryPermissionErrors = <any>validation.errors['data_entry_permissions'];
    if (dataEntryPermissionErrors) {
      for (let i = 0; i < dataEntryPermissionErrors.length; i++) {
        let permission = this.perms()[i];
        let groupError = dataEntryPermissionErrors[i]['group_id'];
        if (groupError) {
          this.setServerError(permission.group, groupError);
        }
        let trialError = dataEntryPermissionErrors[i]['trial_id'];
        if (trialError) {
          this.setServerError(permission.trial, trialError);
        }
        let datasetError = dataEntryPermissionErrors[i]['dataset_id'];
        if (datasetError) {
          this.setServerError(permission.dataset, datasetError);
        }
      }
    }
  }

  private setServerError(obsv: KnockoutObservable<{}>, errors: string[]) {
    obsv.serverError(errors.join('. '));
  }

  config: FormNestedEntitiesConfiguration<DataEntryPermission> = {
    title:
      this.options.allowSelect === 'trial'
        ? SERVER_INFO.SIMPLE_PERMISSIONS
          ? i18n.t('Trials access')
          : i18n.t('Data entry permissions on trials:')
        : '',
    addTitle: this.options.allowSelect === 'trial' ? i18n.t('Add trial access') : i18n.t('Add group access'),
    missingTitle: this.options.allowSelect === 'trial' ? i18n.t('Select a trial') : i18n.t('Select a group'),
    infoText:
      this.options.allowSelect === 'trial'
        ? SERVER_INFO.SIMPLE_PERMISSIONS
          ? ''
          : i18n.t([
              'data_entry_permissions_help_text',
              'Users in this group will be able to enter results only for the trials and datasets selected here.',
            ])
        : '',

    entities: this.perms,
    actions: [],

    canDisable: () => false,
    isTrialActive: () => false, // Since canDisable is false this will never be visible
    disabled: () => false,
    disable: () => {},

    canRemove: (entity) => true,
    add: this.addDataEntryPermission.bind(this),
    remove: this.removeDataEntryPermisson.bind(this),
    hasErrors: (entity) => entity.hasErrors(),
    showErrors: (entity) => entity.showErrors(),

    getSummaryName: (entity) => {
      return entity.getSummaryName();
    },
  };

  addDataEntryPermission() {
    let permission = new DataEntryPermission(this.options);
    if (this.options.allowSelect === 'group') {
      permission.trial(this.options.trial);
    }
    this.perms.push(permission);

    return permission;
  }

  removeDataEntryPermisson(permission: DataEntryPermission) {
    this.perms.remove(permission);
    permission.dispose();
  }
}

class DataEntryPermission {
  private subscriptions: KnockoutSubscription[] = [];
  private dimensionMetas: DimensionMetaData[];
  private ignoreNextChange = false;

  simplePermissions = SERVER_INFO.SIMPLE_PERMISSIONS;

  loading = ko.observable(false);
  group = ko.observable<BaseGroupData>(null).extend({
    serverError: true,
  });
  trial = ko.observable<TrialSelectData>(null).extend({
    required: false,
    serverError: true,
  });
  dataset = ko.observable<TrialDatasetData>(null).extend({
    required: false,
    serverError: true,
  });
  exclude = ko.observable(false);
  conditions = ko.observableArray<DataEntryPermissionCondition>();

  private errors = ko.validation.group(this);

  hasErrors = ko.pureComputed(() => {
    if (this.errors().length > 0) {
      return true;
    }

    for (let condition of this.conditions()) {
      if (condition.hasErrors()) {
        return true;
      }
    }

    if (this.group.serverError() || this.trial.serverError() || this.dataset.serverError()) {
      return true;
    }

    return false;
  });

  showErrors() {
    this.errors.showAllMessages();

    for (let condition of this.conditions()) {
      condition.showErrors();
    }
  }

  constructor(private options: { allowSelect: 'group' | 'trial' }, data?: DataEntryPermissionData) {
    if (options.allowSelect === 'group') {
      this.group = this.group.extend({ required: true });
    }

    if (data) {
      let trialId = data.trial_id ? data.trial_id.id : undefined;
      let datasetId = data.dataset_id ? data.dataset_id.id : undefined;

      this.group(data.group_id);
      this.trial(data.trial_id);
      this.dataset(data.dataset_id);
      this.exclude(data.exclude);
      this.dimensionMetas = data.dimension_metas;
      this.conditions(
        data.conditions.map((conditionData) => {
          return new DataEntryPermissionCondition(trialId, datasetId, this.dimensionMetas, conditionData);
        })
      );
    }

    this.subscriptions.push(this.trial.subscribe(this.onTrialChanged.bind(this)));
    this.subscriptions.push(this.dataset.subscribe(this.onDatasetChanged.bind(this)));
    this.subscriptions.push(this.group.subscribe(this.onGroupChanged.bind(this)));
  }

  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
    for (let condition of this.conditions()) {
      condition.dispose();
    }
  }

  getSummaryName(): string | I18nText {
    if (this.options.allowSelect === 'trial' && this.trial()) {
      return this.trial().name_json;
    }
    if (this.options.allowSelect === 'group' && this.group()) {
      let base = this.group().name;
      if (this.dataset()) {
        return base + ' - ' + this.dataset().name;
      }
      return base;
    } else if (this.dataset()) {
      return this.dataset().name;
    } else {
      return null;
    }
  }

  addCondition = () => {
    if (!this.dataset() && !this.trial()) {
      return;
    }
    let trialId = this.trial() ? this.trial().id : undefined;
    let datasetId = this.dataset() ? this.dataset().id : undefined;

    let condition = new DataEntryPermissionCondition(trialId, datasetId, this.dimensionMetas);
    this.conditions.push(condition);
  };

  removeCondition = (condition: DataEntryPermissionCondition) => {
    this.conditions.remove(condition);
    condition.dispose();
  };

  groupSearchConfig = getGroupSearchConfig(this.group);
  trialSearchConfig = getTrialSearchConfig(this.trial, { template: false, include_plot_count: true });

  datasetSearchConfig: FormSelectSearchConfiguration<TrialDatasetData> = {
    getSummaryName: (dataset) => {
      return dataset.name;
    },

    list: (params) => {
      if (this.options.allowSelect === 'group') {
        return listWithTrials({
          trial: deflateSingle(this.trial()),
          ...params,
        });
      }
      return listWithTrials(params);
    },

    entity: this.dataset,
  };

  private onDatasetChanged() {
    if (this.ignoreNextChange) {
      return;
    }

    this.reset();
    this.ignoreNextChange = true;
    this.trial(null);
    this.ignoreNextChange = false;

    let dataset = this.dataset();
    if (dataset) {
      this.reloadDms(datasetPermDms(dataset.trial_id, dataset.id));
    }
  }

  private onTrialChanged() {
    if (this.ignoreNextChange) {
      return;
    }

    this.reset();
    this.ignoreNextChange = true;
    this.dataset(null);
    this.ignoreNextChange = false;

    if (this.isAssigningTrialPermissionToGroup()) {
      confirmTrialAssignment(
        {
          name_json: this.trial().name_json,
          plot_count: this.trial().plot_count,
        },
        () => this.trial(null)
      );
    }

    let trial = this.trial();
    if (trial) {
      this.reloadDms(trialPermDms(trial.id));
    }
  }

  private isAssigningTrialPermissionToGroup() {
    return this.options.allowSelect === 'trial' && this.trial();
  }

  private onGroupChanged() {
    if (this.isAssigningGroupPermissionToTrial()) {
      confirmTrialAssignment(
        {
          name_json: this.trial().name_json,
          plot_count: this.trial().plot_count,
        },
        () => this.group(null)
      );
    }
  }

  private isAssigningGroupPermissionToTrial() {
    return this.options.allowSelect === 'group' && this.group() && this.trial();
  }

  private reset() {
    this.group.serverError(null);
    this.trial.serverError(null);
    this.dataset.serverError(null);
    this.dimensionMetas = [];
    this.conditions([]);
  }

  private reloadDms(promise: Promise<DimensionMetaData[]>) {
    this.loading(true);
    promise
      .then((dms) => {
        this.dimensionMetas = dms;
        this.loading(false);
      })
      .catch(() => {
        this.loading(false);
      });
  }

  toData(): DataEntryPermissionData {
    return {
      group_id: this.group(),
      trial_id: this.trial(),
      dataset_id: this.dataset(),
      exclude: this.exclude(),
      conditions: this.conditions().map((condition) => condition.toData()),
    };
  }
}
