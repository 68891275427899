import { UnitData } from '../api/units';

export const createSelectOption = (value: string, content: string, selected: boolean) => {
  let selectOption = document.createElement('option');

  selectOption.value = value;
  selectOption.textContent = content;
  selectOption.selected = selected

  return selectOption
}

export const createUnitDropdownOption = (unit: UnitData, selectedUnitId: string) => {
  return createSelectOption(
    unit.id.toString(),
    `${unit.name} (${unit.description})`,
    unit.id === selectedUnitId
  )
};
