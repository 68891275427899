import * as ko from 'knockout';

import { listTraitActionOptions } from '../../api/trait_actions';
import { SelectedTrait } from '../../models/trial';
import { TraitAction } from '../../models/trait_action';

export class ScanCodeViewModel {
  isEnabled = ko.observable(true);
  switchOn = ko.observable(false);
  loadingTraitActionOptions = ko.observable(true);
  loadingNewTraitAction = ko.observable(false);

  selectedTraitIds: ko.PureComputed<string[]>;

  traitActions = ko.observableArray<TraitAction>([]);

  validationError: ko.PureComputed<string>;

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: {
    initialTraitActions: ko.ObservableArray<TraitAction>;
    selectedTraits: ko.ObservableArray<SelectedTrait>;
  }) {
    this.selectedTraitIds = ko.pureComputed(() =>
      params
        .selectedTraits()
        .filter((trait) => this._shouldIncludeTrait(trait, params.initialTraitActions()))
        .map((trait) => trait.mmId)
    );
    this.refreshTraitActionOptions(params.initialTraitActions());
    this.setupTraitActions(params.initialTraitActions());

    this.validationError = ko.pureComputed(() => {
      return (this.traitActions.error && this.traitActions.error()) || '';
    });

    this.subscriptions.concat([
      this.isEnabled.subscribe(
        (curValue) => {
          if (curValue) {
            // Turn off switch before disabling it
            this.switchOn(false);
          }
        },
        this,
        'beforeChange'
      ),
      this.switchOn.subscribe(
        (curValue) => {
          if (curValue) {
            // Turning off the switch
            this.traitActions.removeAll();
          } else if (this.traitActions().length === 0) {
            this.addNewAction();
          }
        },
        this,
        'beforeChange'
      ),
      this.selectedTraitIds.subscribe(() => {
        this.refreshTraitActionOptions(this.traitActions());
      }),
      params.initialTraitActions.subscribe((newTraitActions) => {
        if (newTraitActions !== this.traitActions()) {
          this.setupTraitActions(newTraitActions);
        }
      }),
    ]);
  }

  _shouldIncludeTrait(trait: SelectedTrait, initialTraitActions: TraitAction[]) {
    // Besides the saved traits, also include the traits that are used in the
    // initialTraitActions. That is required because when the trial has just been
    // created from a template, no trait is saved yet.
    return (
      trait.isSaved ||
      initialTraitActions.some((action) => action.sourceTraitId() === trait.mmId) ||
      initialTraitActions.some((action) => action.targetTraitId() === trait.mmId)
    );
  }

  addNewAction() {
    const newTraitAction = new TraitAction();
    this.loadingNewTraitAction(true);
    this.fetchTraitOptions(this.selectedTraitIds())
      .then((options) => {
        this.traitActions.push(newTraitAction);
        newTraitAction.setOptions(options);
      })
      .finally(() => {
        this.loadingNewTraitAction(false);
      });
  }

  removeAction(traitAction: TraitAction) {
    this.traitActions.remove(traitAction);
    if (this.traitActions().length === 0) {
      this.switchOn(false);
    }
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
    this.subscriptions = [];
  }

  private fetchTraitOptions(selectedTraitIds: string[]) {
    return listTraitActionOptions({ measurement_meta_ids: selectedTraitIds });
  }

  private async refreshTraitActionOptions(traitActions: TraitAction[]) {
    try {
      this.loadingTraitActionOptions(true);
      const options = await this.fetchTraitOptions(this.selectedTraitIds());
      traitActions.forEach((traitAction) => traitAction.setOptions(options));
      this.isEnabled(options.sources.length > 0 && options.targets.length > 0);
    } catch (error) {
      console.error(error);
    } finally {
      this.loadingTraitActionOptions(false);
    }
  }

  private setupTraitActions(initialTraitActions: TraitAction[]) {
    this.traitActions(initialTraitActions);
    this.switchOn(initialTraitActions.length > 0);
  }
}

ko.components.register('scan-code', {
  viewModel: ScanCodeViewModel,
  template: require('raw-loader!../../../templates/components/trial_wizard/scan_code.html').default,
});
