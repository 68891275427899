import * as ko from 'knockout';

ko.bindingHandlers['formattedNumber'] = {
  update: (element: Element, valueAccessor: () => KnockoutObservable<number | string> | number | string) => {
    updateWithNumber(element, ko.unwrap(valueAccessor()), undefined);
  },
};

ko.bindingHandlers['formattedCurrency'] = {
  update: (element: Element, valueAccessor: () => KnockoutObservable<number | string> | number | string) => {
    updateWithNumber(element, ko.unwrap(valueAccessor()), 2);
  },
};

ko.bindingHandlers['formattedInt'] = {
  update: (element: Element, valueAccessor: () => KnockoutObservable<number | string> | number | string) => {
    updateWithNumber(element, ko.unwrap(valueAccessor()), 0);
  },
};

function updateWithNumber(element: Element, value: number | string, precision: number) {
  if (value === '' || value === null || value === undefined) {
    element.textContent = '—';
  } else {
    let options =
      precision === undefined
        ? undefined
        : {
            minimumFractionDigits: precision,
            maximumFractionDigits: precision,
          };
    let parsedValue = typeof value === 'number' ? value : parseFloat(value);
    if (isNaN(parsedValue)) {
      element.textContent = '—';
    } else {
      element.textContent = parsedValue.toLocaleString(undefined, options);
    }
  }
}
