import * as ko from 'knockout';

import { request } from '../api/request';
import { escape } from '../utils';
import { GeoJSON } from '../api/datasets';
import { COUNTRY_CENTERS } from '../utils/country_centers';
import { getColor } from '../utils';
import { session } from '../session';
import { polygonContains } from 'd3-polygon';


declare const google: any;
declare const MarkerClusterer: any;
declare const OverlappingMarkerSpiderfier: any;

const SITE_STROKE_COLOR = 'rgba(255, 238, 85, 1)';
const SITE_STROKE_WEIGHT = 3;
const SITE_FILL_COLOR = 'rgba(104, 159, 56, 0.5)';
const SITE_FILL_OPACITY = 0.5;
const SHAPE_FILL_COLOR = '#FFFFFF';
const SHAPE_FILL_OPACITY = 0.3;
const SHAPE_STROKE_WEIGHT = 2;


export interface Point {
  lat: number;
  lng: number;
}

interface FactDetails {
  observations: { title: string; value: string }[];
  dimensions: { title: string; value: string; dimension_meta_slug: string }[];
  site_name: string;
  treatment_name: string;
  user_name: string;
  created: string;
}

function getFactDetails(url: string): Promise<FactDetails> {
  return request('GET', url, {}, { injectTenant: true, version: 2 });
}

function renderLines(line: { title: string; value: string }): string {
  return `<div><span>${escape(line.title)}</span>: <span>${escape(line.value)}</span></div>`;
}

function renderDimensionLines(line: { title: string; value: string; dimension_meta_slug: string }): string {
  const icon = line.dimension_meta_slug == 'crop_variety' ? 'eco' : 'format_list_numbered';
  return `<div class="metadata-dimension-row"><i class="material-icons">${icon}</i>${escape(
    line.title
  )}: ${escape(line.value)}</div>`;
}

let currentOverlays: any[] = [];
let map: any;
let popup: any;
let mClusterer: any;
let clusterer: any;
let pinPoints: any[] = [];
let disableClustering: boolean = false;
let setPointColorBasedOnUser: boolean = false;

export function resetOverlays() {
  mClusterer.clearMarkers();
  clusterer.forgetAllMarkers();
  for (let overlay of currentOverlays) {
    google.maps.event.clearInstanceListeners(overlay);
    overlay.setMap(null);
  }
  currentOverlays = [];
}

export function addMarkers(markers: any[]) {
  for (let marker of markers) {
    currentOverlays.push(marker);
    if (!disableClustering) {
      clusterer.trackMarker(marker);
    } else {
      pinPoints.push(marker);
    }
  }
  if (!disableClustering) {
    mClusterer.addMarkers(markers);
  }
}

export function fitMap(coords: Point[]) {
  if (!map) {
    return;
  }

  let bounds = new google.maps.LatLngBounds();
  for (let coord of coords) {
    bounds.extend(coord);
  }
  map.setCenter(bounds.getCenter());
  map.fitBounds(bounds);
  map.setZoom(map.getZoom() + 1);
}

export function initMap(element: Element): any {
  let mapElem = document.getElementById('map');
  ko.cleanNode(mapElem);
  element.appendChild(mapElem);

  if (!map) {
    map = new google.maps.Map(mapElem, {
      mapTypeId: 'hybrid',
      tilt: 0, // a 45 deg tilt shows polylines in the wrong place
      zoom: 18,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
    });

    mClusterer = new MarkerClusterer(map, [], { imagePath: '/static/m' });
    mClusterer.setMaxZoom(18);
    clusterer = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true,
      markersWontHide: true,
      keepSpiderfied: true,
    });
    clusterer.addListener('format', (marker: any, status: any) => {
      if (status === OverlappingMarkerSpiderfier.markerStatus.SPIDERFIABLE) {
        marker.setLabel('+');
        marker.setIcon({
          url: '/static/m1.png',
          anchor: new google.maps.Point(26, 26),
        });
      } else {
        marker.setLabel('');
        marker.setIcon(null);
      }
    });

    google.maps.event.addListener(map, 'idle', () => {
      // see https://github.com/jawj/OverlappingMarkerSpiderfier/issues/103
      Object.getPrototypeOf(clusterer).h.call(clusterer);
    });

    popup = new google.maps.InfoWindow({
      content: '',
      pixelOffset: 0,
      maxWidth: 300,
    });
  } else {
    map.setOptions({ draggableCursor: 'pointer' });
    map.controls[google.maps.ControlPosition.TOP_RIGHT].clear();
  }

  ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
    document.getElementById('map-container').appendChild(mapElem);
  });

  return map;
}

interface Props {
  currentlyRenderedPointsIds?: Set<any>;
}

const formatDate = (date: string) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  return new Date(date).toLocaleDateString('en-US', options);
};

ko.bindingHandlers['map'] = {
  init: (element: Element & Props, valueAccessor: () => any) => {
    initMap(element);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      resetOverlays();
      map = null;
    });
    setPointColorBasedOnUser = ko.unwrap(valueAccessor())?.['setPointColorBasedOnUser'] || false;
  },

  update: (element: Element & Props, valueAccessor: () => any) => {
    const featuresToRender = ko.unwrap(valueAccessor()).mapPoints;
    const isRefreshable = ko.unwrap(valueAccessor())?.['isRefreshable'];
    disableClustering = ko.unwrap(valueAccessor())?.['disableClustering'] || false;
    setPointColorBasedOnUser = ko.unwrap(valueAccessor())?.['setPointColorBasedOnUser'] || false;

    const visitsPanelElement = document.getElementById('playback-visits-container');
    const bigPlayButton = document.getElementById('big-play-button');
    const playbackElement = document.getElementById('playback-container');
    if (
      setPointColorBasedOnUser &&
      visitsPanelElement &&
      bigPlayButton &&
      playbackElement &&
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].length === 0
    ) {
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(playbackElement);
      map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(visitsPanelElement);
      map.controls[google.maps.ControlPosition.LEFT_CENTER].push(bigPlayButton);
    }

    const popUps = ko.unwrap(valueAccessor())['popUps'];

    if (!isRefreshable) {
      resetOverlays();
    }

    let currentlyRenderedPointsIds = element.currentlyRenderedPointsIds || new Set<any>();
    if (!featuresToRender || featuresToRender.length === 0) {
      removeMarkers([], currentlyRenderedPointsIds);
      return;
    }
    let markers: any[] = [];
    let allCoords: { lat: number; lng: number }[] = [];
    let usersIds: number[] = [];
    const pointsToRender = featuresToRender.filter((feature: GeoJSON) => feature.geometry.type === 'Point');

    const shapesToRender = featuresToRender.filter((feature: GeoJSON) => feature.geometry.type !== 'Point');
    for (let index = 0; index < featuresToRender.length; index++) {
      const feature = featuresToRender[index];
      if (feature.metaData?.user_id && !usersIds.includes(feature.metaData?.user_id)) {
        usersIds.push(feature.metaData.user_id);
      }
      // Check if coordinates are already rendered
      if (isRefreshable) {
        let currentFeatureId = feature.properties.id;
        if (currentlyRenderedPointsIds.has(currentFeatureId)) {
          continue;
        }
        currentlyRenderedPointsIds.add(currentFeatureId);
      }

      let marker: any;
      let shape: any;
      if (feature.geometry.type === 'Point') {
        let [lng, lat] = feature.geometry.coordinates as number[];
        let position = { lat, lng };
        allCoords.push(position);
        marker = new google.maps.Marker({
          position,
          id: feature.properties.id,
        });
        if (setPointColorBasedOnUser) {
          marker.setIcon({
            path: 'M12 12C12.55 12 13.0208 11.8042 13.4125 11.4125C13.8042 11.0208 14 10.55 14 10C14 9.45 13.8042 8.97917 13.4125 8.5875C13.0208 8.19583 12.55 8 12 8C11.45 8 10.9792 8.19583 10.5875 8.5875C10.1958 8.97917 10 9.45 10 10C10 10.55 10.1958 11.0208 10.5875 11.4125C10.9792 11.8042 11.45 12 12 12ZM12 22C9.31667 19.7167 7.3125 17.5958 5.9875 15.6375C4.6625 13.6792 4 11.8667 4 10.2C4 7.7 4.80417 5.70833 6.4125 4.225C8.02083 2.74167 9.88333 2 12 2C14.1167 2 15.9792 2.74167 17.5875 4.225C19.1958 5.70833 20 7.7 20 10.2C20 11.8667 19.3375 13.6792 18.0125 15.6375C16.6875 17.5958 14.6833 19.7167 12 22Z',
            fillColor: getColor(usersIds.indexOf(feature.metaData?.user_id)),
            fillOpacity: 1,
            strokeColor: 'white',
            anchor: new google.maps.Point(26, 26),
          });
          marker.setMap(map);
        }
      } else {
        let coords = (feature.geometry.coordinates[0] as number[][]).map(([lng, lat]) => ({ lat, lng }));
        // if there is only one shape and it's site we want to use it as fitMap borders
        if (
          (featuresToRender.length < 2 && feature.properties?.iconType === 'site') ||
          pointsToRender.length === 0
        ) {
          allCoords = allCoords.concat(coords);
        }
        const siteShapePolygon: [number, number][] = coords.map((coord: Point) => [coord.lng, coord.lat]);
        let fillColor, fillOpacity, strokeColor, strokeWeight;
        if (feature.properties?.iconType === 'site') {
          const isAnyShapeInsideOfSite = shapesToRender
            .filter((shape: GeoJSON) => shape.properties.id !== feature.properties.id)
            .some((shape: GeoJSON) => {
              const shapeCoords = (shape.geometry.coordinates[0] as number[][]).map(([lng, lat]) => ({
                lat,
                lng,
              }));
              return shapeCoords.some((shapeCoord: Point) => {
                return polygonContains(siteShapePolygon, [shapeCoord.lng, shapeCoord.lat]);
              });
          });
          fillOpacity = 0;
          strokeColor = SITE_STROKE_COLOR;
          strokeWeight = SITE_STROKE_WEIGHT;
          if (!isAnyShapeInsideOfSite) {
            fillOpacity = SITE_FILL_OPACITY;
            fillColor = SITE_FILL_COLOR;
          }
        } else {
          fillOpacity = SHAPE_FILL_OPACITY;
          strokeColor = SHAPE_FILL_COLOR
          fillColor = SHAPE_FILL_COLOR;
          strokeWeight = SHAPE_STROKE_WEIGHT;
        }
        shape = addShape(coords, { clickable: true, fillColor, strokeColor, strokeWeight, fillOpacity });

        let polygonBounds = new google.maps.LatLngBounds();
        for (let coord of coords) {
          polygonBounds.extend(coord);
        }
      }

      let props = feature.properties;
      if (props && props.info) {
        let lines = props.info || [];

        let content_prefix = `
                    <div class="map-popup-title">${escape(props.title) || 'Observation'}</div>
                    `;
        let content = content_prefix;
        if (props.lazy_load_url) {
          content += ` <div class="map-popup-info">Loading...</div> `;
        } else {
          let linesHTML = lines.map(renderLines).join('');
          content += ` <div class="map-popup-info">${linesHTML}</div> `;
        }

        const renderPopUp = (event?: any) => {
          if (props.lazy_load_url) {
            getFactDetails(props.lazy_load_url).then((res) => {
              let observationsLinesHTML = res.observations.map(renderLines).join('');
              let dimensionsLinesHTML = res.dimensions.map(renderDimensionLines).join('');
              let siteRowHTML = res.site_name
                ? `<div class="metadata-row"><i class="material-icons">location_on</i>${res.site_name}</div>`
                : '';

              let treatmentRowHTML =
                session.tenant().treatment_management_enabled && res.treatment_name
                  ? `<div class="metadata-row"><i class="material-icons">info</i>Treatment: ${res.treatment_name}</div>`
                  : '';

              let content = `
                        <div class="map-popup-container">
                          ${content_prefix}
                          ${treatmentRowHTML}
                          ${dimensionsLinesHTML}
                          ${siteRowHTML}
                          <div class="metadata-row"><i class="material-icons">person</i>${
                            res.user_name
                          }</div>
                          <div class="metadata-row"><i class="material-icons">today</i>${formatDate(
                            res.created
                          )}</div>
                          <div class="map-popup-info">${observationsLinesHTML}</div>
                        </div>
                        `;
              popup.setContent(content);
            });
          }
          popup.setContent(content);
          if(event) {
            popup.setPosition(event.latLng);
          }
          if (marker) {
            popup.open(map, marker);
          } else if (shape) {
            popup.open(map);
          }
        };
        if (marker) {
          google.maps.event.addListener(marker, disableClustering ? 'click' : 'spider_click', renderPopUp);
        } else if (shape) {
          google.maps.event.addListener(shape, 'click', renderPopUp);
        }
        if (popUps) {
          popUps.push(() => renderPopUp());
        }
      }

      if (marker) {
        markers.push(marker);
      }
    }
    if (!isRefreshable || (isRefreshable && !element.currentlyRenderedPointsIds?.size)) {
      fitMap(allCoords);
    }
    addMarkers(markers);
    element.currentlyRenderedPointsIds = currentlyRenderedPointsIds;
    removeMarkers(featuresToRender, currentlyRenderedPointsIds);
  },
};

const removeMarkers = (pointsToRender: GeoJSON[], currentlyRenderedPointsIds: Set<string>) => {
  const markers = disableClustering ? pinPoints : mClusterer.getMarkers().map((marker: any) => marker);
  for (let marker of markers) {
    if (!pointsToRender.find((point: GeoJSON) => point.properties.id === marker.id)) {
      mClusterer.removeMarker(marker, undefined, disableClustering);
      if (!disableClustering) {
        clusterer.forgetMarker(marker);
      }
      marker.setMap(null);
      currentlyRenderedPointsIds.delete(marker.id);
    }
  }
};


interface MapEditParams {
  value: KnockoutObservable<GeoJSON>;
  defaultLocation?: KnockoutObservable<Point>;
}

ko.bindingHandlers['mapEdit'] = {
  init: (element: Element, valueAccessor: () => MapEditParams) => {
    ko.bindingHandlers['map'].init(element, () => valueAccessor().value, undefined, undefined, undefined);

    let params = valueAccessor();
    let coords = getCoords(params.value);
    let listeners: any[] = [];
    let partialShape: Point[] = [];
    let partialShapePath = new google.maps.MVCArray();
    let drawingMode = !coords;
    let drawBtn: HTMLElement;
    let dragBtn: HTMLElement;
    const createIcon: string = 'create';
    const clearIcon: string = 'clear';
    const panTool: string = 'pan_tool';

    let setDrawingMode = (mode: boolean) => {
      drawingMode = mode;
      if (drawingMode) {
        drawBtn.classList.add('map-btn-active');
        drawBtn.innerText = createIcon;
        drawBtn.title = 'Draw';
        dragBtn.classList.remove('map-btn-active');
      } else {
        drawBtn.classList.remove('map-btn-active');

        if (!!coords) {
          drawBtn.innerText = clearIcon;
          drawBtn.title = 'Clear';
        }

        dragBtn.classList.add('map-btn-active');
      }
      map.setOptions({
        draggableCursor: drawingMode ? 'crosshair' : 'pointer',
      });

      if (drawingMode) {
        partialShape = [];
        partialShapePath.clear();
        resetOverlays();
        addShape(partialShapePath, { clickable: false, polyline: true });
      } else {
        drawCurrent(params);
      }
    };
    let addBtn = (icon: string, onClick: () => void) => {
      let btn = document.createElement('i');
      btn.classList.add('material-icons', 'map-btn');
      btn.innerText = icon;
      btn.onclick = onClick;

      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(btn);

      return btn;
    };

    dragBtn = addBtn(panTool, () => setDrawingMode(false));
    drawBtn = addBtn(createIcon, () => setDrawingMode(true));

    let snap = (point: Point) => partialShape.length >= 2 && visualDistance(point, partialShape[0]) < 32;

    listeners.push(
      map.addListener('click', (event: any) => {
        if (!drawingMode) {
          return;
        }

        let point = toPoint(event.latLng);
        if (snap(point)) {
          setCoords(params.value, partialShape);
          coords = getCoords(params.value);
          setDrawingMode(false);
        } else {
          partialShape.push(point);
          partialShapePath.push(new google.maps.LatLng(point));
          partialShapePath.push(new google.maps.LatLng(point)); // will be moved
        }
      })
    );
    listeners.push(
      map.addListener('mousemove', (event: any) => {
        if (!drawingMode || partialShape.length === 0) {
          return;
        }

        let point = toPoint(event.latLng);
        if (snap(point)) {
          point = partialShape[0];
        }
        partialShapePath.setAt(partialShapePath.getLength() - 1, new google.maps.LatLng(point));
      })
    );

    setDrawingMode(drawingMode);
    if (coords) {
      fitMap(coords);
    } else {
      map.setCenter(params.defaultLocation?.() ?? COUNTRY_CENTERS['CH']);
      map.setZoom(6);
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      listeners.forEach((l) => google.maps.event.removeListener(l));
    });
  },

  update: (element: Element, valueAccessor: () => MapEditParams) => {
    // NOTE: we don't support updating the value once the map is already shown
  },
};

function drawCurrent(params: MapEditParams) {
  let coords = getCoords(params.value);

  resetOverlays();
  if (coords) {
    addShape(coords, {
      clickable: false,
      polyline: false,
      onEdit: (coords) => setCoords(params.value, coords),
    });
  }
}

function getCoords(obs: KnockoutObservable<GeoJSON>): Point[] {
  let feature = obs();
  if (!feature || feature.geometry.type !== 'Polygon') {
    return null;
  }

  let coords = (feature.geometry.coordinates[0] as number[][]).map(([lng, lat]) => ({ lat, lng }));
  if (coords.length === 0) {
    return null;
  }

  return coords;
}

function setCoords(obs: KnockoutObservable<GeoJSON>, coords: Point[]) {
  // NOTE: open polygon, doesn't preserve properties
  if (coords.length === 0) {
    obs(null);
  } else {
    let coordinates = [coords.map((coord) => [coord.lng, coord.lat])];
    obs({
      type: 'Feature',
      geometry: { type: 'Polygon', coordinates },
      properties: {},
    });
  }
}

function addShape(
  path: any | Point[],
  options: {
    clickable: boolean;
    polyline?: boolean;
    onEdit?: (coords: Point[]) => void;
    fillColor?: string;
    strokeColor?: string;
    strokeWeight?: number;
    fillOpacity?: number;
  }
): void {
  if (options.fillOpacity === undefined) {
    options.fillOpacity = 0.3;
  }
  let shape = new (options.polyline ? google.maps.Polyline : google.maps.Polygon)({
    path,
    clickable: options.clickable,
    editable: !!options.onEdit,
    geodesic: true,
    strokeColor: options?.strokeColor || '#FFFFFF',
    strokeOpacity: 1,
    strokeWeight: options?.strokeWeight || 2,
    fillColor: options?.fillColor,
    fillOpacity: options?.fillOpacity,
  });
  shape.setMap(map);
  currentOverlays.push(shape);

  if (options.onEdit) {
    let path = shape.getPath();
    let callEdit = () => options.onEdit(path.getArray().map(toPoint));

    google.maps.event.addListener(path, 'set_at', callEdit);
    google.maps.event.addListener(path, 'remove_at', callEdit);
    google.maps.event.addListener(path, 'insert_at', callEdit);
  }
  return shape;
}

function toPoint(pt: any): Point {
  return { lat: pt.lat(), lng: pt.lng() };
}

function visualDistance(pt1: Point, pt2: Point): number {
  let p1 = map.getProjection().fromLatLngToPoint(new google.maps.LatLng(pt1));
  let p2 = map.getProjection().fromLatLngToPoint(new google.maps.LatLng(pt2));
  let pixelSize = Math.pow(2, -map.getZoom());

  return Math.sqrt((p1.x - p2.x) * (p1.x - p2.x) + (p1.y - p2.y) * (p1.y - p2.y)) / pixelSize;
}
