import * as ko from 'knockout';
import i18n from '../../i18n';

import { app } from '../../app';
import { Deferred } from '../../utils/deferred';
import { SITE_SLUG } from '../../api/dimension_metas';
import * as sitesApi from '../../api/sites';

let template =
  require('raw-loader!../../../templates/components/trial_wizard/trial_sites_import.html').default;

class TrialSitesImport {
  loading = ko.observable(false);
  siteSlug = SITE_SLUG;

  private result: Deferred<sitesApi.SiteData[]>;

  constructor(params: { result: Deferred<sitesApi.SiteData[]> }) {
    this.result = params.result;
  }

  onCancel = () => {
    this.result.resolve([]);
  };

  onSuccess = async (importedIds: string[]) => {
    if (importedIds.length > 0) {
      this.loading(true);
      try {
        this.result.resolve(await sitesApi.list({ ids: importedIds }));
      } finally {
        this.loading(false);
      }
    } else {
      this.result.resolve([]);
    }
  };
}

const name = 'trial-sites-import';

ko.components.register(name, {
  viewModel: TrialSitesImport,
  template: template,
});

export function openImportSites(): Promise<sitesApi.SiteData[]> {
  return app.formsStackController.push({
    title: i18n.t('Import new sites')(),
    name,
    showNav: true,
    className: name,
    params: {
      dmIdOrSlug: SITE_SLUG,
      result: new Deferred<sitesApi.SiteData[]>(),
    },
  });
}
