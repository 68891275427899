import * as ko from 'knockout';
import { Point, initMap, fitMap, resetOverlays, addMarkers } from './map';

declare const google: any;

interface Params {
  enable: boolean;
  obs: ko.Observable<Point>;
  mapCenterLocation?: Point;
}

ko.bindingHandlers['mapLocation'] = {
  init: (element: Element, valueAccessor: () => Params) => {
    let params = valueAccessor();
    let map = initMap(element);

    let location = ko.unwrap(params.obs);
    if (location) {
      map.setZoom(15);
    } else if (params.mapCenterLocation) {
      map.setCenter(params.mapCenterLocation);
      map.setZoom(15);
    } else {
      map.setCenter({ lat: 47.535, lng: 7.578 });
      map.setZoom(8);
    }

    let listener = map.addListener('click', function (event: any) {
      if (params.enable) {
        params.obs({ lat: event.latLng.lat(), lng: event.latLng.lng() });
      }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      google.maps.event.removeListener(listener);
    });
  },

  update: (element: Element, valueAccessor: () => Params) => {
    let params = valueAccessor();
    let position = ko.unwrap(params.obs);
    resetOverlays();
    if (position) {
      addMarkers([new google.maps.Marker({ position })]);
      fitMap([position]);
    }
  },
};
