import { makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';

export interface RegistrationPhaseData {
  id?: string;
  name_json: I18nText;
  type: 'REGIONAL' | 'NATIONAL';
}

export const registrationPhasesApi = makeDefaultApi<RegistrationPhaseData, { ids?: string[] }>(
  'registration_phases'
);
