import * as ko from 'knockout';

import i18n from '../i18n';
import { CountryData, countriesApi } from '../api/countries';
import { CropVarietyData } from '../api/crop_varieties';
import { ListLoaderDelegate } from '../components/list_loader';
import { RegistrationFiltersData, RegistrationData, registrationApi } from '../api/registrations';
import { updateLocationWithQueryString, parsePortofolioItemsNames } from '../utils';
import { Registration } from '../models/registration';
import { RegistrationPhase } from '../models/registration_phase';
import { ListRequestParams } from '../api/request';
import { translate, asI18nText } from '../i18n_text';
import * as cropVarietyApi from '../api/crop_varieties';
import * as cropsApi from '../api/crops';
import { RegistrationRegionData, regRegionsApi } from '../api/registration_regions';
import { RegistrationPhaseData, registrationPhasesApi } from '../api/registration_phases';
import { FilterDelegate } from '../components/list_filters';
import { safeParseDate, serializeDate } from '../api/serialization';
import { CropData } from '../api/crops';
import { ListHeaderAction } from '../components/list_header';
import { portofolioItemApi, PortofolioItemData } from '../api/portofolio_items';
import { addChangelogListAction } from './changelog';
import { canEditRegistrations } from '../permissions';

let template = require('raw-loader!../../templates/registrations.html').default;

export class RegistrationsFilters {
  private regionalChoices = [
    { name: i18n.t('All')(), id: 'all' },
    { name: i18n.t('No')(), id: 'no' },
    { name: i18n.t('Yes')(), id: 'yes' },
  ];

  countries = ko.observableArray<CountryData>();
  regions = ko.observableArray<RegistrationRegionData>();
  crops = ko.observableArray<CropData>();
  cropVarieties = ko.observableArray<CropVarietyData>();
  phases = ko.observableArray<RegistrationPhaseData>();
  portofolioItems = ko.observableArray<PortofolioItemData>();
  regional = ko.observable(this.regionalChoices[0]);
  minDate = ko.observable<Date>();
  maxDate = ko.observable<Date>();

  filters: FilterDelegate[];

  constructor() {
    this.filters = [
      {
        title: i18n.t('Country')(),
        entities: this.countries,
        list: (params) => countriesApi.list(params),
      },
      {
        title: i18n.t('Region')(),
        entities: this.regions,
        list: (params) => regRegionsApi.list(params),
      },
      {
        title: i18n.t('Crop')(),
        entities: this.crops,
        list: (params) => cropsApi.list(params),
      },
      {
        title: i18n.t('Crop variety')(),
        entities: this.cropVarieties,
        list: (params) => cropVarietyApi.list({ sort_by: null, ...params }),
      },
      {
        title: i18n.t('Phase')(),
        entities: this.phases,
        list: (params) =>
          registrationPhasesApi.list(params).then((phases) =>
            phases.map((phase) => ({
              ...phase,
              name_json: asI18nText(
                `${translate(phase.name_json)} (${
                  RegistrationPhase.TYPE_OPTIONS.find((option) => option.value === phase.type).name
                })`
              ),
            }))
          ),
      },
      {
        title: i18n.t('Regional')(),
        choices: this.regionalChoices,
        value: this.regional,
      },
      {
        title: i18n.t('PI')(),
        entities: this.portofolioItems,
        list: (params) =>
          portofolioItemApi
            .list(params)
            .then((portofolioItems) => parsePortofolioItemsNames(portofolioItems)),
      },
      { title: i18n.t('Min. date')(), value: this.minDate },
      { title: i18n.t('Max. date')(), value: this.maxDate },
    ];
  }

  setInitial(filters: RegistrationFiltersData): Promise<void> {
    let countries =
      (filters.country_ids?.length ?? 0) > 0 ? countriesApi.list({ ids: filters.country_ids }) : undefined;
    let regions =
      (filters.region_ids?.length ?? 0) > 0 ? regRegionsApi.list({ ids: filters.region_ids }) : undefined;
    let crops = (filters.crop_ids?.length ?? 0) > 0 ? cropsApi.list({ ids: filters.crop_ids }) : undefined;
    let cropVarieties =
      (filters.crop_variety_ids?.length ?? 0) > 0
        ? cropVarietyApi.list({ ids: filters.crop_variety_ids, sort_by: null })
        : undefined;
    let phases =
      (filters.phase_ids?.length ?? 0) > 0
        ? registrationPhasesApi.list({ ids: filters.phase_ids })
        : undefined;
    let portofolioItems =
      (filters.portofolio_item_ids?.length ?? 0) > 0
        ? portofolioItemApi
            .list({ ids: filters.portofolio_item_ids })
            .then((portofolioItems) => parsePortofolioItemsNames(portofolioItems))
        : undefined;

    return Promise.all([countries, regions, crops, cropVarieties, phases, portofolioItems]).then(
      ([countries, regions, crops, cropVarieties, phases, portofolioItems]) => {
        this.countries(countries ?? []);
        this.regions((regions ?? []) as RegistrationRegionData[]);
        this.crops((crops ?? []) as CropData[]);
        this.cropVarieties((cropVarieties ?? []) as CropVarietyData[]);
        this.phases(phases ?? []);
        this.regional(
          this.regionalChoices.filter((choice) => choice.id === filters.regional)[0] ??
            this.regionalChoices[0]
        );
        this.minDate(safeParseDate(filters.min_date));
        this.maxDate(safeParseDate(filters.max_date));
        this.portofolioItems(portofolioItems ?? []);
      }
    );
  }

  toData(): RegistrationFiltersData {
    return {
      country_ids: this.countries().map((c) => c.id),
      region_ids: this.regions().map((r) => r.id),
      crop_ids: this.crops().map((c) => c.id),
      crop_variety_ids: this.cropVarieties().map((c) => c.id),
      phase_ids: this.phases().map((p) => p.id),
      regional: this.regional().id,
      min_date: serializeDate(this.minDate()),
      max_date: serializeDate(this.maxDate()),
      portofolio_item_ids: this.portofolioItems().map((pi) => pi.id),
    };
  }
}

class RegistrationsScreen implements ListLoaderDelegate<RegistrationData, Registration> {
  canEdit = canEditRegistrations();

  private regFilters = new RegistrationsFilters();
  newFilters = this.regFilters.filters;
  listActions: ListHeaderAction[] = addChangelogListAction('registration', [
    {
      title: i18n.t('History')(),
      icon: 'history',
      href: '/registration_histories/',
    },
  ]);
  loading = ko.observable(true);

  constructor(params: { filters: RegistrationFiltersData }) {
    this.regFilters.setInitial(params.filters).then(() => {
      this.loading(false);
    });
  }

  fetch(params: ListRequestParams) {
    let data = this.regFilters.toData();
    updateLocationWithQueryString(data);
    return registrationApi.list({ ...data, ...params });
  }

  instantiate(data: RegistrationData) {
    return new Registration(data);
  }

  getName(entity: Registration) {
    return translate(entity.cropVariety().name_json) + ' - ' + entity.country().name;
  }

  getEditUrl(entity: Registration) {
    return this.canEdit && entity.editUrl;
  }

  remove(id: string) {
    return registrationApi.remove(id);
  }

  canRemove(entity: Registration) {
    return this.canEdit;
  }
}

export let registrations = {
  name: 'registrations',
  viewModel: RegistrationsScreen,
  template: template,
};

ko.components.register(registrations.name, registrations);
