import * as ko from 'knockout';

import { DimensionData, list } from '../api/dimensions';
import { DataEntryPermissionConditionData } from '../api/groups';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { DimensionMetaData } from '../api/dimension_metas';

export class DataEntryPermissionCondition {
  private subscriptions: KnockoutSubscription[] = [];

  dimensionMetas = ko.observableArray<DimensionMetaData>();
  dmId = ko.observable<string>(null);
  dimensions = ko.observableArray<DimensionData>();

  // populated/cleared by GroupEditScreen
  globalError = ko.observable('');
  hasErrors = ko.pureComputed(() => {
    return ko.validation.group(this, { deep: true })().length > 0 || this.globalError();
  });
  showErrors() {
    ko.validation.group(this, { deep: true }).showAllMessages();
  }

  constructor(
    private trialId: string | undefined,
    private datasetId: string | undefined,
    dimensionMetas: DimensionMetaData[],
    data?: DataEntryPermissionConditionData
  ) {
    this.dimensionMetas(dimensionMetas);

    if (data) {
      this.dmId(data.dimension_meta_id);
      this.dimensions(data.dimension_ids);
    } else {
      if (dimensionMetas.length > 0) {
        this.dmId(dimensionMetas[0].id);
      }
    }

    this.subscriptions.push(this.dmId.subscribe(this.onDmIdChanged));
  }

  dimensionSearchConfig = ko.pureComputed(() => {
    let res: FormSelectSearchConfiguration<DimensionData> = {
      list: (params) => {
        if (!this.dmId()) {
          return Promise.resolve([]);
        }

        return list(this.dmId(), { trial_id: this.trialId, dataset_id: this.datasetId }, params);
      },
      entities: this.dimensions,
      getSummaryName: (entity) => {
        return entity.name_json;
      },
    };

    return res;
  });

  onDmIdChanged = () => {
    this.dimensions([]);
  };

  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
  }

  toData(): DataEntryPermissionConditionData {
    return {
      dimension_meta_id: this.dmId(),
      dimension_ids: this.dimensions(),
    };
  }
}
