import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import * as driversApi from '../api/drivers';

let template = require('raw-loader!../../templates/drivers.html').default;

class DriversScreen implements ListLoaderDelegate<driversApi.DriverData> {
  fetch(params: ListRequestParams) {
    return driversApi.list(params);
  }

  instantiate(data: driversApi.DriverData) {
    return data;
  }

  getEditUrl(data: driversApi.DriverData) {
    return `/drivers/${data.id}/`;
  }

  remove(id: string) {
    return driversApi.remove(id);
  }

  canRemove(entity: driversApi.DriverData) {
    return true;
  }
}

export let drivers = {
  name: 'drivers',
  viewModel: DriversScreen,
  template: template,
};

ko.components.register(drivers.name, drivers);
