import * as ko from 'knockout';

import { DimensionMeta } from './dimension_meta';
import { Dimension } from './dimension';
import { StageData } from '../api/stages';
import { slugValidation } from '../ko_bindings/slug_validation';

export class Stage extends Dimension {
  canEditShowInS2bim = SERVER_INFO.ENABLE_S2BIM;

  countForStats = ko.observable(false);
  showInS2bim = ko.observable(false);

  editUrl = ko.pureComputed(() => {
    return '/stages/' + this.id() + '/';
  });

  constructor(data?: StageData, dimensionMeta?: DimensionMeta) {
    super(data, dimensionMeta);
    this.anonymizedCode = this.anonymizedCode.extend(slugValidation);

    if (data) {
      this.countForStats(data.count_for_stats);
      this.showInS2bim(data.show_in_s2bim);
    }
  }

  toData(): StageData {
    let data = <StageData>super.toData();

    data.count_for_stats = this.countForStats();
    data.show_in_s2bim = this.showInS2bim();

    return data;
  }
}
