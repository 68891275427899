import { requestAnyWithValidation, ValidationResult } from './request';

export interface CouponData extends ValidationResult {
  id: string;
  amount_off: number;
  percent_off: number;
  currency: string;
  duration: string;
  duration_in_months: number;
}

export interface CouponResponseData extends ValidationResult {
  coupon: CouponData;
}

export function retrieve(id: string): Promise<CouponResponseData> {
  return requestAnyWithValidation('GET', '/api/coupons/' + id + '/').then((res) => {
    if (res.status == 200) {
      return {
        isValid: true,
        entityId: null,
        coupon: res.result,
        errors: {},
      };
    } else {
      return {
        isValid: false,
        entityId: null,
        coupon: null,
        errors: res.result,
      };
    }
  });
}
