import i18n from '../i18n';

export enum TrialState {
  Draft = 'draft',
  Preview = 'preview',
  Approved = 'approved',
  Active = 'active',
  Completed = 'completed',
  DataValidated = 'data_validated',
  Archived = 'archived',
  Canceled = 'canceled',
}

export function getTrialStateLabel(state: TrialState | string): string {
  const trialStateLabels: Record<TrialState, string> = {
    [TrialState.Draft]: i18n.t('Draft')(),
    [TrialState.Preview]: i18n.t('Preview')(),
    [TrialState.Approved]: i18n.t('Approved')(),
    [TrialState.Active]: i18n.t('Active')(),
    [TrialState.Completed]: i18n.t('Completed')(),
    [TrialState.DataValidated]: i18n.t('Data validated')(),
    [TrialState.Archived]: i18n.t('Archived')(),
    [TrialState.Canceled]: i18n.t('Canceled')(),
  };

  return trialStateLabels[state as keyof typeof trialStateLabels] ?? '';
}

interface TrialStateChoice {
  id: TrialState;
  name: string;
}

export function getTrialStateChoices(): TrialStateChoice[] {
  return Object.entries(TrialState).map(([_, state]) => ({
    id: state,
    name: getTrialStateLabel(state),
  }));
}

export const EDITABLE_TRIAL_STATES = [TrialState.Draft, TrialState.Preview];
