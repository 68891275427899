import * as ko from 'knockout';

import { SavedPageFilterData , Page} from '../api/saved_page_filter';

export class SavedPageFilter {
  id = ko.observable<number>();
  page = ko.observable<Page>();
  trialId = ko.observable<number | null>(null);
  value = ko.observable<Record<string, any>>({});

  constructor(data?: SavedPageFilterData) {
    if (data) {
      this.id(data.id);
      this.page(data.page);
      this.trialId(data.trial);
      this.value(data.value);
    }
  }

  toData(): SavedPageFilterData {
    return {
      id: this.id(),
      page: this.page(),
      trial: this.trialId(),
      value: this.value(),
    };
  }
}
