import * as ko from 'knockout';

import * as trialsApi from '../api/trials';
import { BaseForm } from './base_form';
import { TrialSelectData } from '../api/base_trials';
import { DataEntryCollection } from '../models/data_entry_permission';

let template = require('raw-loader!../../templates/assign_trial.html').default;

class AssignTrialScreen extends BaseForm<trialsApi.AssignTrialData & { id?: string }> {
  trial: TrialSelectData;
  dataEntryPermissions: DataEntryCollection;

  constructor(params: { trialId: string }) {
    super({});

    let promise = trialsApi.retrieveAssigned(params.trialId).then((data) => {
      this.trial = data.trial;
      this.dataEntryPermissions = new DataEntryCollection({
        allowSelect: 'group',
        trial: data.trial,
      });
      this.dataEntryPermissions.initFrom(data.data_entry_permissions);
    });
    this.loadedAfter(promise);
  }

  save = () => {
    let data: trialsApi.AssignTrialData = {
      trial: this.trial,
      data_entry_permissions: this.dataEntryPermissions.toData(),
    };

    if (this.validateLocal(this) && data) {
      this.saving(true);
      this.executeSaveRequest(trialsApi.saveAssigned(data)).then((validation) => {
        this.onRemoteValidation(data, this, validation);

        if (!validation.isValid) {
          this.dataEntryPermissions.applyServerErrors(validation);
        }
      });
    }
  };
}

export let assignTrial = {
  name: 'assign-trial',
  viewModel: AssignTrialScreen,
  template: template,
};

ko.components.register(assignTrial.name, assignTrial);
