import { request } from '../request';

interface GeographicZoneResponse {
  grid_code: string;
  points_polygon: object;
  type: string;
}

export function getGeoZoneById(id: number): Promise<GeographicZoneResponse> {
  return request(
    'GET',
    `/api/v2/geographic-zones/${id}/`,
    {},
    { injectTenant: true, version: 2 }
  );
}
