import * as ko from 'knockout';
import page from 'page';

import i18n from '../i18n';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import {
  getCropSearchConfig,
  getTrialSearchConfig,
  getTrialTypeSearchConfig,
} from './configs/search_configs';
import { session } from '../session';
import { TrialSelectData } from '../api/base_trials';
import { CropData } from '../api/crops';
import { retrieveDefaultTemplate } from '../api/trials';
import { TrialTypeData } from '../api/trial_types';
import { getTrialEditModes } from '../models/trial';

let template = require('raw-loader!../../templates/components/create_trial_popup.html').default;

class CreateTrialPopup {
  template: boolean;
  private result: Deferred<{}>;

  editMode = ko.observable('library');
  resetText = (SERVER_INFO.USE_FACTORS_NAMING || session.tenant()?.treatment_management_enabled) && this.editMode() === 'library'
    ? i18n.t('Reset treatment factors, sites, plots and planting/reference date')()
    : i18n.t('Reset test subjects, sites, plots and planting/reference date')();
  forceTrialReset = APP_CONFIG.FORCE_TRIAL_RESET;

  loading = ko.observable(true);
  private defaultTemplate = ko.observable<TrialSelectData>(null);

  step = ko.observable<'select_trial' | 'select_crop'>('select_trial');

  fromTemplate = ko.observable<'yes' | 'no'>('no');
  resetTemplate = ko.observable(true);
  cropFilter = ko.observable<CropData>(null);
  trialTypeFilter = ko.observable<TrialTypeData>(null);
  trial = ko.observable<TrialSelectData>(null).extend({
    required: {
      onlyIf: () => this.fromTemplate() === 'yes',
    },
  });
  editModeOptions = getTrialEditModes();
  isManualTrialsOptionEnabled = ko.observable(session.tenant()?.manual_trials_enabled);
  crop = ko.observable<CropData>(null);
  private selectTrialErrors = ko.validation.group([this.trial]);
  private selectCropErrors = ko.validation.group([this.crop]);

  cropFilterSearchConfig = getCropSearchConfig(this.cropFilter, {
    disableCreate: true,
  });
  trialTypeSearchConfig = getTrialTypeSearchConfig(this.trialTypeFilter, {
    disableCreate: true,
  });
  templateSearchConfig = getTrialSearchConfig(this.trial, {
    template: true,
    crop: this.cropFilter,
    trial_type: this.trialTypeFilter,
  });

  cropSearchConfig = getCropSearchConfig(this.crop, { disableCreate: true });

  constructor(params: { template: boolean; result: Deferred<{}> }) {
    this.template = params.template;
    this.result = params.result;

    if (!this.template) {
      this.crop = this.crop.extend({ required: true });
    }

    retrieveDefaultTemplate().then((defaultTemplate) => {
      this.defaultTemplate(defaultTemplate.id ? defaultTemplate : null);
      this.loading(false);
    });
  }

  private requireCrop(): boolean {
    if (this.fromTemplate() === 'no') {
      return this.defaultTemplate() && !this.defaultTemplate().crop_id;
    } else {
      return this.trial() && !this.trial().crop_id;
    }
  }

  onSelectTrial = () => {
    if (this.selectTrialErrors().length > 0) {
      this.selectTrialErrors.showAllMessages();
      return;
    }

    if (this.requireCrop()) {
      this.step('select_crop');
    } else {
      this.create();
    }
  };

  onSelectCrop = () => {
    if (this.selectCropErrors().length > 0) {
      this.selectCropErrors.showAllMessages();
      return;
    }

    this.create();
  };

  private create() {
    let url = this.template ? '/trial_templates/new/' : '/trials/new/';
    let templateId: string;
    let resetTemplate: boolean;
    let fromBaseTemplate: boolean;

    if (this.fromTemplate() === 'yes') {
      templateId = this.trial().id;
      resetTemplate = this.resetTemplate();
      fromBaseTemplate = false;
    } else {
      templateId = this.defaultTemplate() ? this.defaultTemplate().id : null;
      resetTemplate = true;
      fromBaseTemplate = !!this.defaultTemplate();
    }

    if (templateId) {
      url +=
        '?from_template=' +
        templateId +
        '&reset_template=' +
        resetTemplate +
        '&from_base_template=' +
        fromBaseTemplate;
      if (this.crop()) {
        url += '&crop=' + this.crop().id;
      }
    } else {
      url += '?edit_mode=' + this.editMode();
    }

    page(session.toTenantPath(url));
  }

  onCancel = () => {
    this.result.reject();
  };
}

ko.components.register('create-trial-popup', {
  viewModel: CreateTrialPopup,
  template: template,
});

export function createTrialPopup(params: { template: boolean }) {
  app.formsStackController.push({
    title: template ? i18n.t('Create trial template')() : i18n.t('Create trial')(),
    name: 'create-trial-popup',
    params: {
      template: params.template,
      result: new Deferred<{}>(),
    },
  });
}
