import { getColorForPValue } from '../utils/stats';
import { TPP } from './tpp';

import * as tppsApi from '../api/tpps';
import { AnovaTrait, TPPEvaluation } from '../screens/tpp_evaluation';
import { NamedRecord } from '../api/tpps';
import i18n from '../i18n';

const ALPHA = 0.05;

export class TPPTechnicalScorecard {
    tppId: string;
    tpp = ko.observable<TPP>();
    selectedCV = ko.observable<any>(null);
    selectedCVId = ko.computed<string>(() => this.selectedCV()?.id);      
    technicalScorecard = ko.observable<tppsApi.TechnicalScorecardData>();
    trialsWithSelectedCV = ko.computed(() =>
      this.technicalScorecard()?.trials?.filter((trial) => trial.cv_ids.includes(this.selectedCVId()))
    );
    loadingTechnicalScorecard= ko.observable(false);
    private collapsedTraits = ko.observableArray<AnovaTrait>();
    private collapsedVariety = ko.observableArray<any>();
    private collapsedTrials = ko.observableArray<tppsApi.TechnicalScorecardTrialData>();

    constructor(params: { data: TPPEvaluation, currentCV: NamedRecord } ) {
      this.tppId = params.data.tppId;
      this.technicalScorecard(params.data.technicalScorecard());
      this.selectedCV(params.currentCV);
      this.reload();
    }
  
    anovaTraits = ko.pureComputed<AnovaTrait[]>(() => {
      const res: AnovaTrait[] = [];
  
      const scorecard = this.technicalScorecard();
      if (!scorecard?.traits) {
        return res;
      }

      const varieties = this.analyzedVarieties();
      for (let traitIdx = 0; traitIdx < scorecard.traits.length; traitIdx++) {
        const siteCounts = new Map<string, number>();
        const sites = new Map<string, NamedRecord>();
        const siteTrialMappingWithValues = new Map<string, string>(); // site id -> trial id
  
        // forEach, because for ... of ... triggers a TS compiler bug:
        // the generated code mixes up the trial/site variables
        // with tmp variables used by "??" expressions.
        this.trialsWithSelectedCV()
          .forEach((trial) => {
            trial.sites.forEach((site) => {
              if (site.id === null) {
                return;
              }
              sites.set(site.id, site);
  
              for (const cv of varieties) {
                if ((site.values[cv.id]?.[traitIdx].raw.length ?? 0) > 0) {
                  siteTrialMappingWithValues.set(site.id, trial.id);
                  siteCounts.set(site.id, (siteCounts.get(site.id) ?? 0) + 1);
                  return;
                }
              }
            });
          });
  
        const shared: NamedRecord[] = [{ id: null, name: i18n.t('All')() }];
        // trial id -> sites
        const byTrial = new Map<string, NamedRecord[]>();
        let maxNotShared = 0;
        siteCounts.forEach((n, siteId) => {
          if (n == 1) {
            const trialId = siteTrialMappingWithValues.get(siteId);
            if (!byTrial.has(trialId)) {
              byTrial.set(trialId, []);
            }
            const trialSites = byTrial.get(trialId);
            trialSites.push(sites.get(siteId));
  
            maxNotShared = Math.max(maxNotShared, trialSites.length);
          }
          if (n > 1) {
            shared.push(sites.get(siteId));
          }
        });
  
        res.push({
          trait: scorecard.traits[traitIdx],
          maxNotShared,
          shared,
          byTrial,
        });
      }
  
      return res;
    });
  
    anovaAverages(trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number, cvId: string) {
      return this.trialAnovaSites(trial, traitIdx).map((site) => {
        const siteId = site == null ? 'unknown' : site.id;
        return {
          average: this.siteAverage(trial, traitIdx, siteId, cvId),
          label: this.lsdLabel(trial, traitIdx, siteId, cvId),
          rawValues: this.siteRawValues(trial, traitIdx, siteId, cvId),
        };
      });
    }
  
    trialSiteNames(trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number) {
      return this.trialAnovaSites(trial, traitIdx).map((site) => site?.name ?? '');
    }
  
    private trialAnovaSites(trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number) {
      const trait = this.anovaTraits()[traitIdx];
  
      const sites = trait.shared.concat(trait.byTrial.get(trial.id) ?? []);
      for (let i = sites.length; i < trait.maxNotShared + trait.shared.length; i++) {
        sites.push(null);
      }
  
      return sites;
    }
  
    isCollapsed(trait: AnovaTrait): boolean {
      return this.collapsedTraits().indexOf(trait) >= 0;
    }

    isVarietyTableCollapsed(selectedCVId: string): boolean {
      return this.collapsedVariety().indexOf(selectedCVId) >= 0;
    }

    toggleVarietyCollapse = (selectedCVId: string) => {
      if (this.isVarietyTableCollapsed(selectedCVId)) {
        this.collapsedVariety.remove(selectedCVId);
      } else {
        this.collapsedVariety.push(selectedCVId);
      }
    };
  
    toggleTraitCollapse = (trait: AnovaTrait) => {
      if (this.isCollapsed(trait)) {
        this.collapsedTraits.remove(trait);
      } else {
        this.collapsedTraits.push(trait);
      }
    };
  
    isTrialCollapsed(trial: tppsApi.TechnicalScorecardTrialData): boolean {
      return this.collapsedTrials().indexOf(trial) >= 0;
    }
  
    toggleTrialCollapse = (trial: tppsApi.TechnicalScorecardTrialData) => {
      if (this.isTrialCollapsed(trial)) {
        this.collapsedTrials.remove(trial);
      } else {
        this.collapsedTrials.push(trial);
      }
    };
  
    getGbyEPValue = (trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number) => {
      const cv_id = this.selectedCVId();
      const index = trial.ge_p_value[traitIdx].findIndex((item) => item.cv_id === cv_id);
      if (index === -1) {
        return '—';
      }
      if (index >= 0 && trial.ge_p_value[traitIdx]?.[index]?.p_value != null) {
        return parseFloat(trial.ge_p_value[traitIdx][index].p_value.toFixed(3)) || '< 0.001';
      }
      return '—';
    };
  
    getColorForPValue(trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number) {
      const cv_id = this.selectedCVId();
      const index = trial.ge_p_value?.[traitIdx]?.findIndex((item) => item.cv_id === cv_id);
      return getColorForPValue(trial.ge_p_value[traitIdx]?.[index]?.p_value);
    }
  
    isGSignificant = (trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number, siteId: string) => {
      return (
        Array.isArray(trial.g_p_value[traitIdx]) &&
        trial.g_p_value[traitIdx].filter((entry) => entry.site == siteId && entry.p_value < ALPHA).length > 0
      );
    };
  
    getGPValue(trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number) {
      const cv_id = this.selectedCVId();
      return this.trialAnovaSites(trial, traitIdx).map((site) => {
        if (
          !Array.isArray(trial.g_p_value[traitIdx]) ||
          trial.g_p_value[traitIdx].length === 0 ||
          site == null
        ) {
          return '—';
        }
        const res = trial.g_p_value[traitIdx]
          .filter((entry) => {
            if (entry.cv_id == cv_id && entry.site == site.id) {
              return entry;
            }
          })
          .map((entry) => (entry.p_value != null ? parseFloat(entry.p_value.toFixed(3)) || '< 0.001' : '—'));
        if (res.length > 0) {
          return res;
        }
        return ['—'];
      });
    }
  
    getStandardDeviation(trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number) {
      const prec = trial.precision[traitIdx];
      const cv_id = this.selectedCVId();
      return this.trialAnovaSites(trial, traitIdx).map((site) => {
        if (site == null) {
          return '—';
        }
        const siteObject = trial.sites.filter((s) => s.id === site.id)?.[0];
        const standardDeviation = siteObject?.standard_deviation?.[traitIdx]?.[cv_id];
        if (standardDeviation) {
          return parseFloat(standardDeviation?.toFixed(prec));
        }
        return '—';
      });
    }
  
    getLSDValue(trial: tppsApi.TechnicalScorecardTrialData, traitIdx: number) {
      const prec = trial.precision[traitIdx];
      const cv_id = this.selectedCVId();
      return this.trialAnovaSites(trial, traitIdx).map((site, index) => {
        if (trial.g_p_value[traitIdx].length === 0 || site == null) {
          return '—';
        }
        const gPValue = this.getGPValue(trial, traitIdx);
        if (gPValue[index] == '—') {
          return '—';
        }
  
        const siteObject = trial.sites.filter((s) => s.id === site.id)?.[0];
        const lsdValue = siteObject?.lsd_values?.[traitIdx]?.[cv_id] || {};
        if ('value' in lsdValue) {
          return parseFloat(lsdValue.value.toFixed(prec));
        } else if ('min' in lsdValue) {
          return `min: ${parseFloat(lsdValue.min.toFixed(prec))}, max: ${parseFloat(
            lsdValue.max.toFixed(prec)
          )}`;
        }
        return 'N/A';
      });
    }
  
    lsdLabel = (
      trial: tppsApi.TechnicalScorecardTrialData,
      traitIdx: number,
      siteId: string,
      cvId: string
    ): string => {
      if (!this.isGSignificant(trial, traitIdx, siteId)) {
        return '';
      }
  
      const site = trial.sites.filter((s) => s.id === siteId)[0];
      if (!site) {
        return '';
      }
      const lsdLabels = site?.lsd_labels?.[traitIdx]?.[this.selectedCVId()];
      return lsdLabels?.[cvId]?.join('') ?? '';
    };
  
    siteAverage = (
      trial: tppsApi.TechnicalScorecardTrialData,
      traitIdx: number,
      siteId: string,
      cvId: string
    ): string | null => {
      const prec = trial.precision[traitIdx];
      const parsedValue = parseFloat(this.siteValues(trial, traitIdx, siteId, cvId)?.aggregated)
      return isNaN(parsedValue) ? '—' : parsedValue.toFixed(prec);
    };
  
    siteRawValues = (
      trial: tppsApi.TechnicalScorecardTrialData,
      traitIdx: number,
      siteId: string,
      cvId: string
    ): number[] => {
      return this.siteValues(trial, traitIdx, siteId, cvId)?.raw?.map(r => r.value) ?? [];
    };
  
    private siteValues(
      trial: tppsApi.TechnicalScorecardTrialData,
      traitIdx: number,
      siteId: string,
      cvId: string
    ): tppsApi.ScorecardValue | null {
      const site = trial.sites.filter((s) => s.id === siteId)[0];
      if (!site) {
        return null;
      }
  
      return site.values[cvId]?.[traitIdx];
    }
  
    analyzedVarieties = ko.pureComputed(() => {
      const scorecard = this.technicalScorecard();
      if (!scorecard) {
        return [];
      }
  
      const seenCvIds = new Set<string>(['', this.selectedCVId()]);
      const cvs: NamedRecord[] = [{ id: this.selectedCVId(), name: i18n.t('Test')() }];
      const addCVs = (toAdd: NamedRecord[]) => {
        if (!toAdd) {
          return;
        }
        for (const cv of toAdd) {
          if (seenCvIds.has(cv.id)) {
            continue;
          }
          seenCvIds.add(cv.id);
  
          cvs.push(cv);
        }
      };
      if (scorecard.traits) {
        for (const trait of scorecard.traits) {
          addCVs(trait.internal_benchmarks);
        }
      }
      addCVs(this.technicalScorecard().external_benchmarks);
  
      return cvs;
    });
    reload() {
      this.tpp(null);
      let tpp = tppsApi.retrieve(this.tppId);
      Promise.all([tpp]).then(([tpp]) => {
        this.tpp(new TPP(null, tpp));
      });
    }
  }