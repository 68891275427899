import * as ko from 'knockout';

import i18n from '../i18n';
import { ImportEntitiesDelegate } from '../components/import_entities';
import { downloadCVStagesImportTemplate } from '../api/crop_varieties';

const template = require('raw-loader!../../templates/components/import_entities_container.html').default;

class ImportCVStagesScreen implements ImportEntitiesDelegate<string[]> {
  title = ko.observable('Import crop variety stages');
  description = i18n.t(
    'To import crop variety stages, download the Excel template, fill-in the desired records and then upload the file.'
  )();
  backTitle = i18n.t('Back to list')();

  templateBaseName = 'import_cv_stages';
  importUrl = '/api/crop_varieties/import_cv_stages/';

  downloadTemplate(): Promise<Blob> {
    return downloadCVStagesImportTemplate();
  }
}

export const importCVStages = {
  name: 'import-cv-stages',
  viewModel: ImportCVStagesScreen,
  template,
};

ko.components.register(importCVStages.name, importCVStages);
