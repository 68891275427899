import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { TraitCategory } from '../models/trait_category';
import * as traitCategoriesApi from '../api/trait_categories';
import { session } from '../session';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/trait_categories.html').default;

class TraitCategoriesScreen
  implements ListLoaderDelegate<traitCategoriesApi.TraitCategoryData, TraitCategory>
{
  showDriver = session.tenant().tpp_enabled;
  listActions = ko.observableArray<ListHeaderAction>([
    {
      title: i18n.t('Add')(),
      icon: 'add_circle',
      href: '/trait_categories/new/',
      tooltipTitle: i18n.t('Add Trait Category')(),
    },
  ]);
  fetch(params: ListRequestParams) {
    return traitCategoriesApi.list(params);
  }

  instantiate(data: traitCategoriesApi.TraitCategoryData) {
    return new TraitCategory(data);
  }

  remove(id: string) {
    return traitCategoriesApi.remove(id);
  }

  canRemove(entity: TraitCategory) {
    return true;
  }
}

export let traitCategories = {
  name: 'trait-categories',
  viewModel: TraitCategoriesScreen,
  template: template,
};

ko.components.register(traitCategories.name, traitCategories);
