import * as ko from 'knockout';

import i18n from '../i18n';
import { ImportEntitiesDelegate } from '../components/import_entities';
import { BaseLoadingScreen } from './base_loading_screen';
import { downloadOrganizationsImportTemplate } from '../api/organizations';

const template = require('raw-loader!../../templates/components/import_entities_container.html').default;

class ImportOrganizationsScreen extends BaseLoadingScreen implements ImportEntitiesDelegate<void> {
  title = ko.observable('');
  description: string;
  backTitle = i18n.t('Back to list')();

  templateBaseName: string;
  importUrl: string;

  constructor(private params: { type: 'breeders' | 'customers' | 'partners' }) {
    super();

    this.templateBaseName = params.type;
    this.importUrl = `/api/${params.type}/import_records/`;

    if (params.type === 'breeders') {
      this.title('Import breeders');
      this.description = i18n.t(
        'To import breeders, download the Excel template, fill-in the desired records and then upload the file.'
      )();
    } else if (params.type === 'customers') {
      this.title('Import customers');
      this.description = i18n.t(
        'To import customers, download the Excel template, fill-in the desired records and then upload the file.'
      )();
    } else if (params.type === 'partners') {
      this.title('Import partners');
      this.description = i18n.t(
        'To import partners, download the Excel template, fill-in the desired records and then upload the file.'
      )();
    }

    this.loaded();
  }

  downloadTemplate() {
    return downloadOrganizationsImportTemplate(this.params.type);
  }
}

export const importOrganizations = {
  name: 'import-organizations',
  viewModel: ImportOrganizationsScreen,
  template,
};

ko.components.register(importOrganizations.name, importOrganizations);
