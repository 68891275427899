import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { RegistrationPhase } from '../models/registration_phase';
import { RegistrationPhaseData, registrationPhasesApi } from '../api/registration_phases';
import { BaseEditScreen } from './base_edit';
import { canEditRegistrationPhases } from '../permissions';

let template = require('raw-loader!../../templates/registration_phase_edit.html').default;

class RegistrationPhaseEditScreen extends BaseEditScreen<RegistrationPhaseData, RegistrationPhase> {
  TYPE_OPTIONS = RegistrationPhase.TYPE_OPTIONS;

  canEdit = canEditRegistrationPhases();

  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<RegistrationPhaseData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super({ api: registrationPhasesApi, focus: true }, params, componentInfo);
  }

  instantiate(data: RegistrationPhaseData) {
    return new RegistrationPhase(data);
  }
}

export let registrationPhaseEdit = {
  name: 'registration-phase-edit',
  viewModel: createWithComponent(RegistrationPhaseEditScreen),
  template: template,
};

ko.components.register(registrationPhaseEdit.name, registrationPhaseEdit);
