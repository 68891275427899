import { confirmDialog } from '../components/confirm_dialog';
import i18n from '../i18n';
import * as groupsApi from '../api/groups';
import { I18nText, translate } from '../i18n_text';
import { PlotCountPerSite } from '../api/trials';
import { StaffSitePermission } from '../screens/assign_staff/staff_permission';
import { UserData } from '../api/users';

const MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT = 30000;

/**
 * Common function to show a warning dialog with the given title and message.
 *
 * @param {string} title - The title of the dialog.
 * @param {string | string[]} translated_message - The message of the dialog translated with 18n.
 * @param {Function} [onCancel] - Optional callback to execute if the user cancels.
 */
function showWarningDialog(message: string | string[], onCancel?: () => void) {
  return confirmDialog(
    i18n.t('Potential problem with too many plots')(),
    message,
    '',
    false,
    i18n.t('Proceed')(),
    i18n.t('Cancel')()
  ).catch(onCancel);
}

/**
 * Confirms the selection of a group, potentially warning the user if the group
 * has a trial plot count above the limit.
 *
 * @param {groupsApi.BaseGroupData} groupData - Data about the group.
 * @param {Function} [onCancel] - Optional callback to execute if the user cancels.
 * @param {number} plotCountLimit - The limit above which a warning is shown.
 * @default MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT
 */
export function confirmGroupSelection(
  groupData: groupsApi.BaseGroupData,
  onCancel?: () => void,
  plotCountLimit: number = MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT
) {
  const exceedsLimit = groupData.trial_plot_counts?.some((trial) => trial.plot_count > plotCountLimit);

  if (exceedsLimit) {
    const message = [
      i18n.t(
        [
          'large_plot_count_warning_on_group_selection',
          'The group {{ groupName }} is linked to Trials with over {{ plotCountLimit }} plots. ' +
            'Continuing may lead to performance issues or app instability on mobile devices.',
        ],
        {
          groupName: groupData.name,
          plotCountLimit: plotCountLimit.toLocaleString(),
        }
      )(),
    ];
    showWarningDialog(message, onCancel);
  }
}

interface TrialData {
  name_json: I18nText;
  plot_count: number;
}

/**
 * Confirms the selection of a trial, potentially warning the user if is has plot
 * count above the limit.
 *
 * @param {TrialData} trialData - Data about the trial.
 * @param {Function} [onCancel] - Optional callback to execute if the user cancels.
 * @param {number} plotCountLimit - The limit above which a warning is shown.
 * @default MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT
 */
export function confirmTrialAssignment(
  trialData: TrialData,
  onCancel?: () => void,
  plotCountLimit: number = MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT
) {
  const exceedsLimit = trialData.plot_count > plotCountLimit;

  if (exceedsLimit) {
    const message = [
      i18n.t(
        [
          'large_plot_count_warning_on_trial_assignment',
          'The trial {{ trialName }} has over {{ plotCountLimit }} plots. \
            Continuing may lead to performance issues or app instability on mobile devices.',
        ],
        {
          trialName: translate(trialData.name_json),
          plotCountLimit: plotCountLimit.toLocaleString(),
        }
      )(),
    ];
    showWarningDialog(message, onCancel);
  }
}

/**
 * Potentially warns the user if a trial has a number of plots above the limit.
 *
 * @param {PlotCountPerSite[]} plotCountPerSite - The number of plots per site.
 * @param {number} plotCountLimit - The limit above which a warning is shown.
 * @default MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT
 */
export function warnIfPlotCountExceeded(
  plotCountPerSite: PlotCountPerSite[],
  plotCountLimit: number = MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT
) {
  const siteExceedsLimit = plotCountPerSite.some((site) => site.plot_count > plotCountLimit);

  if (!siteExceedsLimit) {
    return;
  }

  const listSitesExceedingPlotLimit = () => {
    return plotCountPerSite
      .filter((site) => site.plot_count > plotCountLimit)
      .map((site) => `- ${site.site_name}`);
  };

  const warningMessage = [
    i18n.t(
      [
        'warn_user_about_large_plot_count_per_site',
        'Please be aware that having a site with more than {{ maxNumPlots }} \
          plots can create memory issues on a mobile device when the trial is \
          synced. This problem can be avoided by using multiple sites (each \
          site should have less than {{ maxNumPlots }} plots) and ensuring that \
          field staff are only associated to one site (so the staff synchronizes \
          less than {{ maxNumPlots }} plots to their device at a time).',
      ],
      {
        maxNumPlots: plotCountLimit.toLocaleString(),
      }
    )(),
    i18n.t([
      'warn_user_about_large_plot_count_per_site_list',
      'The following sites exceed the recommended number of plots: ',
    ])(),
    ...listSitesExceedingPlotLimit(),
  ];

  confirmDialog(
    i18n.t('Potential problem with too many plots')(),
    warningMessage,
    '',
    true,
    i18n.t('Acknowledge')()
  );
}

/**
 * Confirms the assignment of a staff member to a trial site, potentially warning
 * the user if the plot count sum across all its sites is above a limit.
 *
 * @param {StaffSitePermission[]} allSitePermissions - The site permissions.
 * @param {PlotCountPerSite[]} plotCountPerSite - The number of plots per site.
 * @param {UserData} user - The user being assigned.
 * @param {Function} [onCancel] - Optional callback to execute if the user cancels.
 * @param {number} plotCountLimit - The limit above which a warning is shown.
 * @default MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT
 */
export function confirmStaffAssignmentToTrialSite(
  allSitePermissions: StaffSitePermission[],
  plotCountPerSite: PlotCountPerSite[],
  user: UserData,
  onCancel?: () => void,
  plotCountLimit: number = MAX_SAFE_TRIAL_PLOT_COUNT_LIMIT
) {
  const userSiteIds = allSitePermissions
    .filter((sitePermission) => sitePermission.users().some((u) => u.id === user.id))
    .map((sitePermission) => sitePermission.site.id);

  const userPlotCountAcrossSites = plotCountPerSite
    .filter((site) => userSiteIds.includes(site.site_id))
    .reduce((acc, site) => acc + site.plot_count, 0);

  if (userPlotCountAcrossSites > plotCountLimit) {
    const message = [
      i18n.t(
        [
          'large_plot_count_warning_on_staff_assignment_to_trial_site',
          'Please be aware that {{ userName }} is being assigned to sites with \
            a combined plot count greater than {{ plotCountLimit }} plots. \
            This can create memory issues on a mobile device when the trial is synced. \
            This problem can be avoided by using multiple sites (each site should have \
            less than {{ plotCountLimit }} plots) and ensuring that field staff are \
            only associated to one site (so the staff synchronizes less than \
            {{ plotCountLimit }} plots to their device at a time).',
        ],
        {
          userName: user.name,
          plotCountLimit: plotCountLimit.toLocaleString(),
        }
      )(),
    ];
    return showWarningDialog(message, onCancel);
  }

  // Need to return a promise, otherwise the caller will try to call .then() on
  // the result, which will fail if we don't return a promise.
  return new Promise((resolve) => resolve(true));
}
