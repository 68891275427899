import { injectTenantInURL } from './request';
import { session } from '../session';
import { requestWithStatus } from './base_request';

interface TaskStatus {
  status: string;
  completion_perc: number;
  download_url: string;
  error_msg: string;
}

export function pollTask(taskId: string): Promise<TaskStatus> {
  // ignore errors when polling
  let url = injectTenantInURL('/api/tasks/' + taskId + '/poll_task/');
  return session
    .getToken()
    .then((token) => requestWithStatus<TaskStatus>('GET', url, token))
    .then((res) => {
      if (res.status == 200) {
        return res.result;
      }
    });
}
