import * as ko from 'knockout';

import { BaseForm } from '../../screens/base_form';
import { TrialWizard } from '../../models/trial';
import { WizardController } from '../../screens/trial_wizard';
import { canEditTrial, canEditTrialLimited } from '../../permissions';

export class BaseTrialStep extends BaseForm<{}> {
  // data is shared amongst all wizard steps through wizard controller member
  trialWizard: KnockoutObservable<TrialWizard>;

  allowEdit = ko.pureComputed(() => {
    if (!this.allowEditAny()) {
      return false;
    }

    return this.trialWizard().trial().isDraft();
  });
  allowEditAny = ko.pureComputed(() => {
    let wizard = this.trialWizard();

    if (!wizard || !wizard.trial().id()) {
      return true;
    }

    return canEditTrial(wizard.userData, wizard.trial());
  });
  allowEditAnyLimited = ko.pureComputed(() => {
    const wizard = this.trialWizard();
    if (!wizard) {
      return false;
    }
    return canEditTrialLimited(wizard.userData, wizard.trial());
  });
  canEditMMLibrary: KnockoutObservable<boolean>;
  ready: KnockoutObservable<boolean>;
  showAdvanced: KnockoutObservable<boolean>;

  constructor(params: { controller: WizardController }) {
    super({});

    // attach to the mediator
    params.controller.bind(this);
  }

  openAdvanced = () => {
    localStorage.setItem('trial-wizard-advanced', 'true');
    this.showAdvanced(true);
  };

  closeAdvanced = () => {
    localStorage.removeItem('trial-wizard-advanced');
    this.showAdvanced(false);
  };

  close() {
    // redefine to no-op
  }

  /**
   * Override to perform operations when the step is shown to the user.
   */
  reload() {
    this.ready(true);
  }

  /**
   * Tells if the current step has a validation error (either local or server-side).
   *
   * Intended to be overridden.
   */
  hasErrors() {
    return false;
  }

  /**
   * Show any validation errors (e.g. local KO validation errors that are currently hidden)
   *
   * Intended to be overridden.
   */
  showErrors() {}

  /**
   * Applies local validation, clearing any previous errors set by this same method.
   *
   * @return true if validation passed, false otherwise.
   *
   * Intended to be overridden.
   */
  applyLocalValidation(): boolean {
    return true;
  }

  /**
   * Clear additional validation logic errors applied in applyServerErrors.
   *
   * Should be call whenever user submits data to stop showing server errors.
   *
   * Intended to be overridden.
   */
  clearServerErrors() {}

  /**
   * Apply additional validation logic after remote call has failed
   *
   * @param errors  map of key => list of error strings
   *
   * Intended to be overridden.
   */
  applyServerErrors(errors: any) {}

  /**
   * Called after the wizard has been successfully saved.
   *
   * Intended to be overridden.
   */
  didSave() {}
}
