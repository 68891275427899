import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import * as factsApi from '../api/facts';
import { DatasetFactSummaryData } from '../api/datasets';
import { MeasurementMeta } from '../models/measurement_meta';
import { makeDynamicAttribute, DynamicAttribute, factChangeReasons } from '../models/attribute_meta';
import { asI18nText, translate } from '../i18n_text';
import { FileUploadDelegate } from '../components/basic_widgets';
import { openObservationHistoryDialog } from '../components/observation_history_dialog';
import { CloudStorageUploadDelegate, CloudStorageUpload, FileUploadEndpoint } from '../cloud_storage_upload';
import { app } from '../app';
import i18n from '../i18n';
import { groupBy, mapValues, sortBy } from 'lodash';
import { setGlobalError } from './base_edit';
import { OverviewClickAction} from './trial_facts'
import { STORAGE_KEYS } from '../utils';
import { session } from '../session';

let template = require('raw-loader!../../templates/fact_edit.html').default;

export class EditExtraObservableData {
  id = ko.observable('');
  file_name = ko.observable('');
  file_url = ko.observable('');
  mime_type = ko.observable('');
  user_file_name = ko.observable('');
  comment = ko.observable('');
  measurement_meta_id = ko.observable<number | null>(null);

  isFileMenuOpen = ko.observable(false);

  constructor(extra: factsApi.EditExtraData) {
    this.id(extra.id);
    this.file_name(extra.file_name);
    this.file_url(extra.file_url);
    this.mime_type(extra.mime_type);
    this.user_file_name(extra.user_file_name);
    this.measurement_meta_id(extra.measurement_meta_id);
    this.comment(extra.comment);
  }

  toData() {
    return {
      id: this.id(),
      file_name: this.file_name(),
      file_url: this.file_url(),
      mime_type: this.mime_type(),
      user_file_name: this.user_file_name(),
      measurement_meta_id: this.measurement_meta_id(),
      comment: this.comment(),
    };
  }

  onOpenFileMenu = () => this.isFileMenuOpen(true);
  onCloseFileMenu = () => this.isFileMenuOpen(false);
}

class FactEditScreen
  extends BaseForm<DatasetFactSummaryData>
  implements FileUploadDelegate, CloudStorageUploadDelegate
{
  canReuseEndpoint = false;

  private factId = ko.observable('');
  private measurementMetaId = ko.observable();

  plotName = ko.observable('');
  referenceDimName = ko.observable('');
  description = ko.observable('');
  attrs = ko.observable<DynamicAttribute[]>();
  selectedAttrs = ko.observable<DynamicAttribute[]>(); // For the selected measurement meta instead of the whole fact.
  comment = ko.observable('');
  extras = ko.observable<Record<string, ko.ObservableArray<EditExtraObservableData>>>();
  globalError = ko.observable('');
  changedAttributes = new Set();
  changeReasons = factChangeReasons;
  actionsConfig = ko.observable<ActionsConfig>();
  actionsDisplayConfig = ko.observable<ActionsDisplayConfig>();

  isObservationHistoryEnabled = ko.observable<boolean>(false);
  historyButtonTooltipText = ko.observable<string>('');

  initialValues: Record<string, any> = {};

  constructor(
    params: {
      factId: string;
      measurementMetaId?: string;
      actionsConfig?: ActionsConfig;
      actionsDisplayConfig?: ActionsDisplayConfig;
      result?: Deferred<DatasetFactSummaryData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    this.actionsConfig(params.actionsConfig);
    this.actionsDisplayConfig(params.actionsDisplayConfig);
    this.isObservationHistoryEnabled(session.tenant().observation_history_enabled);

    if (!session.tenant().observation_history_enabled) {
      this.historyButtonTooltipText(
        i18n.t(
          'The history of edits (audit log) is normally only available on Enterprise subscriptions. Please contact your administrator or QuickTrials support for more information.'
        )()
      );
    }

    this.onFactAndMmIdChange(params.factId, params.measurementMetaId);
  }

  onNextClick = () => {
    if (!this.actionsDisplayConfig().isNextButtonEnabled()) {
      return;
    }

    const [factId, measurementMetaId] = this.actionsConfig().getNextFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onPrevClick = () => {
    if (!this.actionsDisplayConfig().isPrevButtonEnabled()) {
      return;
    }

    const [factId, measurementMetaId] = this.actionsConfig().getPrevFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onNextSkipClick = () => {
    if (!this.actionsDisplayConfig().isNextSkipButtonEnabled()) {
      return;
    }

    const [factId, measurementMetaId] = this.actionsConfig().getNextSkipFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  onPrevSkipClick = () => {
    if (!this.actionsDisplayConfig().isPrevSkipButtonEnabled()) {
      return;
    }

    const [factId, measurementMetaId] = this.actionsConfig().getPrevSkipFactIdAndMmId();
    this.onFactAndMmIdChange(factId, measurementMetaId);
  };

  private onFactAndMmIdChange = (factId: string, measurementMetaId: string) => {
    this.factId(factId);
    this.measurementMetaId(measurementMetaId);

    this.init();
  };

  private createEmptyExtra = (measurement_meta_id: number) =>
    new EditExtraObservableData({
      id: null,
      file_name: '',
      file_url: '',
      mime_type: '',
      user_file_name: '',
      measurement_meta_id: measurement_meta_id,
      comment: '',
    });

  init = () => {
    let promise = factsApi.retrieve(this.factId()).then((data) => {
      const groupedExtras = mapValues(
        groupBy(
          sortBy(data.extras, ['mime_type', 'file_url']).map(
            (extra: factsApi.EditExtraData) => new EditExtraObservableData(extra)
          ),
          (extra: EditExtraObservableData) => extra.measurement_meta_id()
        ),
        (extras: EditExtraObservableData[]) => ko.observableArray(extras)
      );

      this.extras(groupedExtras);

      this.attrs(
        data.mms
          .map((mm) => {
            let attr = makeDynamicAttribute(
              new MeasurementMeta(
                {
                  allowDimensionMeta: false,
                  allowRanking: true,
                  management: false,
                  requireMeasurementType: false,
                  validateLimitTo: false,
                },
                null,
                mm
              ),
              {}
            );
            if (data.unit_names[mm.id]) {
              attr.nameJson(asI18nText(translate(attr.nameJson()) + ' (' + data.unit_names[mm.id] + ')'));
            }

            // If there are no extras, then create one.
            if (groupedExtras[attr.id] === undefined) {
              groupedExtras[attr.id] = ko.observableArray([this.createEmptyExtra(Number(attr.id))]);
            }

            // If there is no "comment-only" extra, then create one so that the "Comment" still appears in the UI.
            // According to the UI requirements it must appear first, so it's added to the beginning of the array.
            if (!groupedExtras[attr.id]().some((extra) => extra.file_url() === '')) {
              groupedExtras[attr.id].unshift(this.createEmptyExtra(Number(attr.id)));
            }
            return attr;
          })
          .filter((attr) => !!attr)
      );

      this.selectedAttrs(
        this.measurementMetaId
          ? this.attrs().filter((attr) => attr.id === this.measurementMetaId())
          : this.attrs()
      );

      for (let attr of this.attrs()) {
        attr.deserialize(data[attr.getMesName()]);
        this.initialValues[attr.id] = attr.flatValue();
        attr.value.subscribe(() => this.changedAttributes.add(attr.id));
      }

      this.plotName(data.plot_name);
      this.referenceDimName(data.reference_dim_name);
      this.description(data.dimensions.map((data) => data.dm_name + ': ' + data.dim_name).join(', '));
      this.comment(data.comment);
    });
    this.loadedAfter(promise);
  };

  isChangeReasonRequired(attr: DynamicAttribute) {
    if (this.initialValues[attr.id] === undefined) {
      return false;
    }
    const value = attr.flatValue() == '' ? undefined : attr.flatValue();
    return this.initialValues[attr.id] !== value;
  }

  isImage(extra: EditExtraObservableData) {
    return extra.mime_type() && extra.mime_type().indexOf('image/') === 0;
  }

  removeExtra = (extra: EditExtraObservableData) => {
    this.extras({
      ...this.extras(),
      [extra.measurement_meta_id()]: ko.observableArray(
        this.extras()
          [extra.measurement_meta_id()]()
          .filter((existingExtra) => existingExtra.id() !== extra.id())
      ),
    });
  };

  save = () => {
    for (let attr of this.attrs()) {
      if (this.isChangeReasonRequired(attr) && attr.changeReason() === '') {
        attr.value.serverError(i18n.t('Change reason should be specified')());
        return;
      }
      if (attr.value.serverError) {
        attr.value.serverError(null);
      }
    }
    let group = ko.validation.group(
      this.attrs().map((attr) => attr.value),
      { deep: false }
    );
    if (group().length > 0) {
      group.showAllMessages();
      return;
    }

    this.saving(true);

    this.executeSaveRequest(factsApi.update(this.toData())).then((res) => {
      this.saving(false);

      if (res.isValid) {
        this.result.resolve(res.entity);
      } else {
        setGlobalError(this.globalError, { ...res, entityId: '' });

        for (let attr of this.attrs()) {
          let errors = res.errors[attr.getMesName()];
          if (errors) {
            this.setServerError(attr.value, errors);
          }
        }
      }
    });
  };

  toData(): factsApi.EditFactData {
    let changeReasons = [];

    let data: factsApi.EditFactData = {
      id: this.factId(),
      comment: this.comment(),
      extras: Object.values(this.extras())
        .map((obsValue: ko.ObservableArray<EditExtraObservableData>) => obsValue())
        .flat()
        .map((extraObs: EditExtraObservableData) => extraObs.toData() as factsApi.EditExtraData)
        .filter((extra: factsApi.EditExtraData) => extra.comment || extra.file_url),
    };
    for (let attr of this.attrs()) {
      data[attr.getMesName()] = attr.serialize();
      if (attr.changeReason()) {
        changeReasons.push({ [attr.id]: attr.changeReason() });
      }
    }
    data['change_reasons'] = changeReasons;
    return data;
  }

  private cloudUpload = new CloudStorageUpload(this);
  fileUploadError = this.cloudUpload.fileUploadError;

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest,
    context: any
  ) => {
    return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR, context);
  };

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return factsApi.getExtraUploadEndpoint(contentType);
  }

  goToHistory = () => {
    // The feature can be disabled, so the Go To History button shouldn't
    // do anything in that case
    if (!this.actionsConfig() || !session.tenant().observation_history_enabled) {
      return;
    }
    openObservationHistoryDialog(
      this.factId(),
      this.measurementMetaId(),
      this.actionsConfig(),
      this.actionsDisplayConfig()
    )
      .then(() => {
        this.loading(true);
        this.init();
      })
      .catch(() => {
        this.result.reject();
      });
  };

  onFileUploaded(
    userFileName: string,
    fileName: string,
    publicURL: string,
    contentType: string,
    context: any
  ): void {
    if (this.extras()[context.measurement_meta_id as number]) {
      this.extras()[context.measurement_meta_id].push(
        new EditExtraObservableData({
          id: null,
          file_name: fileName,
          file_url: publicURL,
          mime_type: contentType,
          user_file_name: userFileName,
          comment: '',
          measurement_meta_id: context.measurement_meta_id,
        }) as unknown as EditExtraObservableData
      );
      return;
    }
  }
}

export let factEdit = {
  name: 'fact-edit',
  viewModel: createWithComponent(FactEditScreen),
  template: template,
};

ko.components.register(factEdit.name, factEdit);

interface ActionsConfig {
  getNextFactIdAndMmId?: () => [string, string];
  getPrevFactIdAndMmId?: () => [string, string];
  getNextSkipFactIdAndMmId?: () => [string, string];
  getPrevSkipFactIdAndMmId?: () => [string, string];
}

interface ActionsDisplayConfig {
  isNextButtonEnabled: () => boolean;
  isPrevButtonEnabled: () => boolean;
  isNextSkipButtonEnabled: () => boolean;
  isPrevSkipButtonEnabled: () => boolean;
}

export function openFactEdit(
  factId: string,
  measurementMetaId?: string,
  actionsConfig?: ActionsConfig,
  actionsDisplayConfig?: ActionsDisplayConfig
): Promise<DatasetFactSummaryData> {
  localStorage.setItem(STORAGE_KEYS.OVERVIEW_CLICK_ACTION, OverviewClickAction.EDIT)

  return app.formsStackController.push({
    isBig: session.tenant().observation_history_enabled,
    className: 'fact-edit-popup',
    showNav: false,
    title: i18n.t('Edit Observation')(),
    name: factEdit.name,
    params: {
      factId,
      measurementMetaId,
      actionsConfig,
      actionsDisplayConfig,
      result: new Deferred<DatasetFactSummaryData>(),
    },
  });
}
