import * as ko from 'knockout';
import i18n from '../i18n';

import * as dmsApi from '../api/dimension_metas';
import {
  MeasurementMetaData,
  MeasurementMetaDimensionMetaData,
  MeasurementMetaPictureData,
} from '../api/datasets';
import { getMeasurementImageUploadEndpoint } from '../api/trials';
import { ValueMeta } from './value_meta';
import { DatasetDimensionMeta, LimitToDimension } from './dataset_dimension_meta';
import { DimensionMeta } from './dimension_meta';
import { Trial, TrialWizard } from './trial';
import { ImageUpload, ImageUploadDelegate } from '../image_upload';
import { I18nText, isI18nTextEmpty } from '../i18n_text';
import { session } from '../session';
import { TraitCategoryData } from '../api/trait_categories';
import { FileUploadEndpoint } from '../cloud_storage_upload';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { getMMLibrarySearchConfig, getUnitSearchConfig } from '../components/configs/search_configs';
import { UnitData } from '../api/units';
import { MeasurementChoice } from './measurement_type';
import { EditMeasurementChoicesDelegate } from '../components/edit_measurement_choices';
import { NameI18nData } from '../api/names';
import { MeasurementMetaTagData } from '../api/measurement_meta_tags';

let ATTRIBUTE_TYPES = [
  { name: i18n.t('Integer Number'), value: 'integer' },
  { name: i18n.t('Number'), value: 'decimal' },
  { name: i18n.t('Date'), value: 'date' },
  { name: i18n.t('Choice from a list'), value: 'choice' },
  { name: i18n.t('Ranking'), value: 'ranking' },
  { name: i18n.t('Text (single line)'), value: 'string' },
  { name: i18n.t('Text (multiple lines)'), value: 'string_long' },
  { name: i18n.t('Barcode'), value: 'barcode' },
  { name: i18n.t('Picture'), value: 'picture' },
  { name: i18n.t('Multiple pictures'), value: 'multi_pictures' },
  { name: i18n.t('Video'), value: 'video' },
  { name: i18n.t('Signature'), value: 'signature' },
  { name: i18n.t('Location on map'), value: 'location' },
  { name: i18n.t('Shape on map'), value: 'geo' },
  { name: i18n.t('Derived trait'), value: 'derived' },
  { name: i18n.t('File'), value: 'file' },
];

export let ATTRIBUTE_TYPES_WITH_DDM = ATTRIBUTE_TYPES.concat([
  { name: i18n.t('Record selection'), value: 'entity' },
]);

function getAttributeTypes(allow: { dimensionMeta: boolean; ranking: boolean; integer: boolean }) {
  let types = allow.dimensionMeta ? ATTRIBUTE_TYPES_WITH_DDM : ATTRIBUTE_TYPES;
  if (!allow.ranking) {
    types = types.filter((type) => type.value !== 'ranking');
  }
  if (!allow.integer) {
    types = types.filter((type) => type.value !== 'integer');
  }
  if (session.tenant() && session.tenant().map_visualization_enabled) {
    return types;
  } else {
    return types.filter((type) => type.value !== 'geo' && type.value !== 'location');
  }
}

export const MM_AGG_OPTIONS = [
  { name: i18n.t('Mean'), value: 'mean' },
  { name: i18n.t('Sum'), value: 'sum' },
  { name: i18n.t('Min'), value: 'min' },
  { name: i18n.t('Max'), value: 'max' },
];

export class MeasurementMeta extends ValueMeta {
  aggOptions = MM_AGG_OPTIONS;
  isMobileDerivedTraitsEnabled = session.tenant() && session.tenant().mobile_derived_traits_enabled;

  calculationOptions = [
    { name: i18n.t('Date difference')(), value: 'date_diff' },
    { name: i18n.t('Division by area')(), value: 'area_div' },
  ];

  copiedFromId: string = undefined;

  traitCategory = ko.observable<TraitCategoryData>();
  description = ko.observable('');
  templateId = ko.observable<string>();
  trialId = ko.observable<null | string>();
  aggregation = ko.observable('mean');
  normalize = ko.observable(false);
  normalizationMM = ko.observable<MeasurementMetaData>(null).extend({
    required: {
      onlyIf: () => this.normalize() && this.calculation() === 'date_diff',
    },
  });
  observationName = ko.observable<I18nText>(null).extend({
    i18nTextRequired: { onlyIf: () => this.normalize() },
  });
  management = false;
  calculation = ko.observable<'date_diff' | 'area_div'>('area_div');
  traitUnitNum = ko.observable<UnitData>(null);
  traitUnitDen = ko.observable<UnitData>(null);
  mtRating = ko.observable(false);
  mtChoices = ko.observableArray<MeasurementChoice>().extend({ serverError: true });
  isDerived = ko.pureComputed(() => this.type() == 'derived');
  formula = ko.observable('').extend({
    required: { onlyIf: () => this.isDerived() },
    serverError: true,
  });
  unitFormula = ko.observable('').extend({ serverError: true });
  syncToMobile = ko.observable(false);
  calculateOnMobile = ko.observable(false);

  collectForSite = ko.observable(true);
  collectForReplication = ko.observable(true);
  collectForTestSubjects = ko.observable(true);
  optional = ko.observable(false);
  mdms = ko.observableArray<MeasurementMetaDimensionMeta>();
  trialTestSubjectLimitTos = ko.observableArray<MeasurementMetaTrialLimitTo>();
  trialReplicationLimitTo = ko.observable<MeasurementMetaTrialLimitTo>();
  trialSiteLimitTo = ko.observable<MeasurementMetaTrialLimitTo>();
  scheduledVisits = ko.observableArray<NameI18nData>().extend({ serverError: true });
  tags = ko.observableArray<MeasurementMetaTagData>();

  normalizationMMSearchConfig: FormSelectSearchConfiguration<MeasurementMetaData>;
  enableMMLibrary: boolean;

  pictures = ko.observableArray<MeasurementMetaPicture>();

  unitSearchConfig = getUnitSearchConfig(this.unit, {
    getCategories: () => (this.traitUnitNum() ? [this.traitUnitNum().unit_category] : []),
  });

  editChoices: EditMeasurementChoicesDelegate = {
    choices: this.mtChoices,
    globalError: ko.observable(''),
  };

  private preferEmbeddedMT = session.tenant().prefer_embedded_mt;

  constructor(
    options: {
      allowDimensionMeta: boolean;
      allowRanking: boolean;
      management: boolean;
      requireMeasurementType: boolean;
      validateLimitTo: boolean;
    },
    trial: Trial,
    data?: MeasurementMetaData
  ) {
    // disable ranking for new measurements
    super(
      {
        types: getAttributeTypes({
          dimensionMeta: options.allowDimensionMeta,
          // allow ranking/integer only for already existing observations,
          // they're deprecated now
          ranking: options.allowRanking && data && data.type === 'ranking',
          integer: data && data.type === 'integer',
        }),
        allowRequired: false,
        selectUnitForTrial: trial,
        hasHelpText: true,
        allowEditNameSlug:
          !data || !data.id || !trial || trial.isDraft() || (data && data.id && data.type === 'derived'),
        useCropPrefix: !trial,
        requireMeasurementType: options.requireMeasurementType,
      },
      data
    );

    this.entityLimitTo = this.entityLimitTo.extend({
      required: {
        onlyIf: () => !!(this.isEntity() && options.validateLimitTo),
      },
    });
    this.unit = this.unit.extend({
      required: { onlyIf: () => this.normalize() && this.type() !== 'date' },
    });

    this.management = options.management;
    let normType = ko.pureComputed(() => (this.type() === 'date' ? 'date' : 'area'));
    this.normalizationMMSearchConfig = getMMLibrarySearchConfig(
      this.normalizationMM,
      this.crops,
      null,
      null,
      { onlyFor: normType, management: null }
    );
    this.enableMMLibrary = trial && trial.canEditMMLibrary;

    if (data) {
      this.copiedFromId = data.copied_from_id;
      this.setMMData(data);
    } else {
      this.type('decimal');
    }

    this.subscriptions.push(this.normalize.subscribe(this.onUpdateNormalize));
    this.subscriptions.push(this.calculation.subscribe(this.updateType));
    this.subscriptions.push(this.traitUnitNum.subscribe(this.onUnitNumChanged));
    this.subscriptions.push(this.syncToMobile.subscribe(this.onSyncToMobileChanged));
    this.subscriptions.push(this.calculateOnMobile.subscribe(this.onCalculateOnMobileChanged));

    this.subscriptions.push(
      this.type.subscribe((newType) => {
        this.updateTraitUnits();

        if (this.normalize() && this.normalizationMM()) {
          if (newType === 'date' && this.normalizationMM().type !== 'date') {
            this.normalizationMM(null);
          } else if (newType !== 'date' && this.normalizationMM().type === 'date') {
            this.normalizationMM(null);
          }
        }

        if (newType !== 'choice') {
          this.mtChoices([]);
        }
      })
    );
  }

  setData(data: MeasurementMetaData) {
    this.setValueMetaData(data);
    this.setMMData(data);
  }

  private setMMData(data: MeasurementMetaData) {
    this.measurementType(data.measurement_type);
    this.dimensionMeta(data.ranking_dimension_meta_id);
    this.unit(data.unit);
    this.helpTextJson(data.help_text_json);
    this.formula(data.formula);
    this.unitFormula(data.unit_formula);
    this.syncToMobile(data.sync_to_mobile);
    this.calculateOnMobile(data.calculate_on_mobile);
    if (data.dimension_meta_id) {
      let dimensionMeta = new DimensionMeta(data.dimension_meta_id);
      this.entityDimensionMeta(data.dimension_meta_id);
      this.entityLimitTo(
        data.limit_to ? data.limit_to.map((data) => new LimitToDimension(null, dimensionMeta, data)) : []
      );
      this.entityAllowCreate(data.allow_create_dimension);
    } else {
      this.entityDimensionMeta(null);
      this.entityLimitTo([]);
      this.entityAllowCreate(false);
    }
    this.autoFill(data.auto_fill);
    this.includeInSummary(data.include_in_summary);
    this.useForPlantingDate(data.use_for_planting_date);
    this.excludeFromApp(data.exclude_from_app);
    this.gpsPrecisionCheckDisabled(data.disable_gps_precision_check);

    if (data.pictures) {
      this.pictures(data.pictures.map((picData) => new MeasurementMetaPicture(picData)));
    } else {
      this.pictures([]);
    }
    this.crops(data.crops || []);
    this.traitCategory(data.trait_category);
    this.description(data.description);
    this.templateId(data.template_id);
    this.trialId(data.trial_id);
    this.aggregation(data.aggregation || 'mean');
    this.normalize(data.normalize);
    this.normalizationMM(data.normalization_mm);
    this.observationName(data.observation_name);
    this.traitUnitNum(data.trait_unit_num);
    this.traitUnitDen(data.trait_unit_den);
    this.mtRating(data.mt_rating || false);
    this.mtChoices(
      (data.mt_choices || [])
        .map((choice) => new MeasurementChoice(choice))
        .sort((a, b) => Number(a.value()) - Number(b.value()))
    );

    this.collectForSite(data.collect_for_site);
    this.collectForReplication(data.collect_for_replication);
    this.collectForTestSubjects(data.collect_for_test_subjects);
    this.optional(data.optional);
    this.mdms((data.mdms || []).map((mdm) => new MeasurementMetaDimensionMeta(mdm)));
    this.mdms.sort((a, b) => a.order - b.order);
    this.scheduledVisits(data.scheduled_visits || []);
    this.tags(data.tags ?? []);

    if (data.normalize) {
      this.calculation(data.type === 'date' ? 'date_diff' : 'area_div');
    } else {
      this.calculation('area_div');
    }
  }

  clearTrialTestSubjectLimitTo() {
    this.trialTestSubjectLimitTos().forEach((testSubjectLimit) => testSubjectLimit.limitTo([]));
  }

  clearTrialReplicationLimitTo() {
    this.trialReplicationLimitTo().limitTo([]);
  }

  clearTrialSiteLimitTo() {
    this.trialSiteLimitTo().limitTo([]);
  }

  setTrialDimensionLimitTo(
    trialDimensionLimitToIds: string[],
    trialWizard: TrialWizard,
    replicationLimitToDimensionOptions: LimitToDimension[]
  ) {
    if (!trialWizard) {
      return;
    }

    const trialLimitToDimensionIds = new Set<string>(trialDimensionLimitToIds);
    let testSubjectLimits: MeasurementMetaTrialLimitTo[] = [];

    for (const testSubject of trialWizard.testSubjects()) {
      let testSubjectLimitTo = new MeasurementMetaTrialLimitTo(testSubject.dimensionMeta());
      for (const limitTo of testSubject.limitTo()) {
        if (trialLimitToDimensionIds.has(limitTo.id())) {
          testSubjectLimitTo.limitTo.push(limitTo);
        }
      }
      testSubjectLimits.push(testSubjectLimitTo);
    }

    let trialReplicationLimitTo = new MeasurementMetaTrialLimitTo(trialWizard.replicationDM);
    for (const replicationLimitTo of replicationLimitToDimensionOptions) {
      if (trialLimitToDimensionIds.has(replicationLimitTo.id())) {
        trialReplicationLimitTo.limitTo.push(replicationLimitTo);
      }
    }

    let trialSiteLimitTo = new MeasurementMetaTrialLimitTo(trialWizard.siteDM);
    for (const siteLimitTo of trialWizard.sites()) {
      if (trialLimitToDimensionIds.has(siteLimitTo.id())) {
        trialSiteLimitTo.limitTo.push(siteLimitTo);
      }
    }

    this.trialTestSubjectLimitTos(testSubjectLimits);
    this.trialReplicationLimitTo(trialReplicationLimitTo);
    this.trialSiteLimitTo(trialSiteLimitTo);
  }

  private onUnitNumChanged = () => {
    this.unit(null);
    this.updateType();
  };

  private onSyncToMobileChanged = () => {
    if (this.syncToMobile()) {
      this.calculateOnMobile(false);
    }
  };

  private onCalculateOnMobileChanged = () => {
    if (this.calculateOnMobile()) {
      this.syncToMobile(false);
    }
  };

  private updateType = () => {
    if (!this.normalize()) {
      return;
    }

    if (this.calculation() === 'date_diff') {
      this.type('date');
    } else {
      this.type('decimal');
    }
  };

  private onUpdateNormalize = () => {
    this.updateTraitUnits();
    this.updateType();
  };

  private updateTraitUnits() {
    if (!this.normalize() || !this.isDerived()) {
      this.traitUnitNum(null);
      this.traitUnitDen(null);
    }
  }

  static fromDDM(trial: Trial, ddm: DatasetDimensionMeta): MeasurementMeta {
    let mm = new MeasurementMeta(
      {
        allowDimensionMeta: true,
        allowRanking: true,
        management: false,
        requireMeasurementType: false,
        validateLimitTo: false,
      },
      trial,
      null
    );
    mm.initFromDatasetDimensionMeta(ddm);

    return mm;
  }

  toData(): MeasurementMetaData {
    let data: MeasurementMetaData = <MeasurementMetaData>super.toData();

    if (this.needsMeasurementType()) {
      // take a copy, the resulting data can be modified (e.g. by unlinkEmbeddedMT)
      const mt = this.measurementType();
      data.measurement_type = mt ? { ...mt } : mt;
      data.mt_rating = this.mtRating();
      data.mt_choices = this.mtChoices().map((choice) => choice.toData());
    } else {
      data.measurement_type = null;
      data.mt_rating = false;
      data.mt_choices = [];
    }
    if (this.needsDimensionMeta() && this.dimensionMeta()) {
      data.ranking_dimension_meta_id = this.dimensionMeta();
    } else {
      data.ranking_dimension_meta_id = null;
    }
    if (this.canSelectUnit()) {
      data.unit = this.unit();
    } else {
      data.unit = null;
    }
    data.help_text_json = this.helpTextJson();
    data.formula = this.formula();
    data.unit_formula = this.unitFormula();
    data.sync_to_mobile = this.syncToMobile();
    data.calculate_on_mobile = this.calculateOnMobile();
    data.dimension_meta_id = this.entityDimensionMeta();
    data.limit_to = this.entityLimitTo().map((dim, i) => dim.toMMData(i));
    data.allow_create_dimension = this.entityAllowCreate();
    data.auto_fill = this.autoFill();
    data.include_in_summary = this.includeInSummary();
    data.use_for_planting_date = this.useForPlantingDate();
    data.exclude_from_app = this.excludeFromApp();
    data.disable_gps_precision_check = this.gpsPrecisionCheckDisabled();
    data.copied_from_id = this.copiedFromId;
    data.pictures = this.pictures()
      .filter((pic) => !pic.isEmpty())
      .map((pic) => pic.toData());
    data.crops = this.crops();
    data.trait_category = this.traitCategory();
    data.description = this.description();
    data.template_id = this.templateId();
    data.aggregation = this.aggregation();
    data.normalize =
      (this.type() === 'date' && !!this.normalizationMM()) ||
      ((this.type() === 'integer' || this.type() === 'decimal') && this.normalize());
    data.normalization_mm = data.normalize ? this.normalizationMM() : null;
    data.observation_name = this.observationName();
    data.management = this.management;
    data.trait_unit_num = this.traitUnitNum();
    data.trait_unit_den = this.traitUnitDen();
    data.collect_for_site = this.collectForSite();
    data.collect_for_replication = this.collectForReplication();
    data.collect_for_test_subjects = this.collectForTestSubjects();
    data.optional = this.optional();
    data.mdms = this.mdms().map((mdm, idx) => mdm.toData(idx));
    data.scheduled_visits = this.scheduledVisits();
    data.tags = this.tags();
    data.deleted = false;
    data.trial_limit_to = this.getTrialLimitToIds();

    return data;
  }

  getTrialLimitToIds() {
    const testSubjectLimitToIds = [].concat.apply(
      [],
      this.trialTestSubjectLimitTos().map((testSubjectLimits) =>
        testSubjectLimits.limitTo().map((limitTo) => limitTo.id())
      )
    );

    const replicationLimitToIds = this.trialReplicationLimitTo()
      ? this.trialReplicationLimitTo()
          .limitTo()
          .map((limitTo) => limitTo.id())
      : [];

    const siteLimitToIds = this.trialSiteLimitTo()
      ? this.trialSiteLimitTo()
          .limitTo()
          .map((limitTo) => limitTo.id())
      : [];

    return testSubjectLimitToIds.concat(replicationLimitToIds).concat(siteLimitToIds);
  }

  addPicture = () => {
    this.pictures.push(new MeasurementMetaPicture());
  };

  removePicture = (pic: MeasurementMetaPicture) => {
    this.pictures.remove(pic);
  };

  requiresEmbeddedMT() {
    let mt = this.measurementType();
    if (this.type() !== 'choice') {
      return false;
    }

    if (this.preferEmbeddedMT) {
      return !mt || (mt && mt.embedded);
    } else {
      return mt && mt.embedded;
    }
  }

  hasErrors(): boolean {
    if (super.hasErrors()) {
      return true;
    }
    if (this.requiresEmbeddedMT()) {
      if (this.choiceErrors()().length > 0) {
        return true;
      }
      for (let choice of this.editChoices.choices()) {
        if (choice.hasServerErrors()) {
          return true;
        }
      }
      if (this.editChoices.globalError()) {
        return true;
      }
    }
    if (this.formula.serverError()) {
      return true;
    }

    return false;
  }

  showErrors() {
    super.showErrors();

    if (this.requiresEmbeddedMT()) {
      this.choiceErrors().showAllMessages();
    }
  }

  private choiceErrors() {
    return ko.validation.group(this.mtChoices, { deep: true });
  }
}

class MeasurementMetaPicture implements ImageUploadDelegate {
  private id: string;
  descriptionJson = ko.observable<I18nText>();
  imageUpload = new ImageUpload(this);

  constructor(data?: MeasurementMetaPictureData) {
    if (data) {
      this.id = data.id;
      this.descriptionJson(data.description_json);
      this.imageUpload.fileName = data.file_name;
      this.imageUpload.picturePublicURL(data.file_public_url);
    }
  }

  isEmpty(): boolean {
    return isI18nTextEmpty(this.descriptionJson()) && !this.imageUpload.picturePublicURL();
  }

  toData(): MeasurementMetaPictureData {
    return {
      id: this.id,
      description_json: this.descriptionJson(),
      file_name: this.imageUpload.fileName,
      // used if toData() has been called to copy the parent observation, it's ignored by the server
      file_public_url: this.imageUpload.picturePublicURL(),
    };
  }

  getImageUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return getMeasurementImageUploadEndpoint(contentType);
  }
}

export function unlinkMM(mm: MeasurementMetaData, template: boolean) {
  mm.id = null;
  if (!template) {
    mm.template_id = null;
  }
  for (let pic of mm.pictures) {
    pic.id = null;
  }

  // don't unlink scheduled visits: they're present
  // only when unlinking an mm in the library, and
  // they refer to library svs

  if (mm.mdms) {
    for (const mdm of mm.mdms) {
      mdm.id = null;
    }
  }

  if (!template) {
    unlinkEmbeddedMT(mm);
  }
}

export function unlinkEmbeddedMT(data: MeasurementMetaData) {
  if (data.measurement_type && data.measurement_type.embedded) {
    data.measurement_type.id = null;
    for (let choice of data.mt_choices) {
      choice.id = null;
    }
  }
}

export class MeasurementMetaTrialLimitTo {
  dimensionMeta: DimensionMeta; // Split by dataset dimension meta for displaying in the UI.
  limitTo = ko.observableArray<LimitToDimension>();

  constructor(dimensionMeta: DimensionMeta) {
    this.dimensionMeta = dimensionMeta;
  }
}

export class MeasurementMetaDimensionMeta {
  id: string | null = null;
  dimensionMeta = ko.observable<DimensionMeta>().extend({
    required: true,
    serverError: true,
  });
  limitTo = ko.observableArray<LimitToDimension>().extend({
    required: true,
  });
  order = 0;

  dimensionMetaSearch: FormSelectSearchConfiguration<DimensionMeta> = {
    getSummaryName: (dm) => dm.nameJson(),
    list: (params) =>
      dmsApi
        .listExcludingDates({
          exclude_slugs: [dmsApi.REPETITION_SLUG, dmsApi.SITE_SLUG],
          ...params,
        })
        .then((result) => result.map((data) => new DimensionMeta(data))),
    entity: this.dimensionMeta,
  };

  nameJson = ko.pureComputed<string | I18nText>(() => {
    if (this.dimensionMeta()) {
      return this.dimensionMeta().nameJson();
    } else {
      return i18n.t('Not selected')();
    }
  });

  private errors = ko.validation.group(this);
  private subscriptions: KnockoutSubscription[] = [];

  constructor(data?: MeasurementMetaDimensionMetaData) {
    if (data) {
      const dm = new DimensionMeta(data.dimension_meta);

      this.id = data.id;
      this.dimensionMeta(dm);
      this.limitTo(
        data.mdm_limit_to.map(
          (l) =>
            new LimitToDimension(null, dm, {
              ...l.dimension,
              optional: l.optional,
            })
        )
      );
      this.order = data.order;
    }

    // Remove limit tos if the dimension meta is reset.
    this.subscriptions.push(
      this.dimensionMeta.subscribe((newDimensionMeta) => {
        if (newDimensionMeta === null) {
          this.limitTo([]);
        }
      })
    );
  }

  toData(order: number): MeasurementMetaDimensionMetaData {
    return {
      id: this.id,
      dimension_meta: this.dimensionMeta().toData(),
      mdm_limit_to: this.limitTo().map((x) => ({
        dimension: x.toData(),
        optional: x.optional(),
      })),
      order,
    };
  }

  hasErrors() {
    return this.errors().length > 0 || !!this.dimensionMeta.serverError();
  }

  showErrors() {
    this.errors.showAllMessages();
  }

  dispose() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
    this.subscriptions = [];
  }
}
