import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { TrialDocument } from '../models/trial_document';
import * as trialDocumentsApi from '../api/trial_documents';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { FileUploadDelegate } from '../components/basic_widgets';
import { CloudStorageUpload, CloudStorageUploadDelegate } from '../cloud_storage_upload';

let template = require('raw-loader!../../templates/trial_document_edit.html').default;

class TrialDocumentEditScreen
  extends BaseForm<trialDocumentsApi.TrialDocumentData>
  implements CloudStorageUploadDelegate
{
  private trialId: string;

  entity = ko.validatedObservable<TrialDocument>(null);

  canReuseEndpoint = false;
  getUploadEndpoint = trialDocumentsApi.getUploadEndpoint;

  onFileUploaded(userFileName: string, fileName: string, fileUrl: string, contentType: string): void {
    const entity = this.entity();

    entity.userFileName(userFileName);
    entity.fileName(fileName);
    entity.fileUrl(fileUrl);
    entity.mimeType(contentType);
  }

  private cloudUpload = new CloudStorageUpload(this);
  uploadDelegate: FileUploadDelegate = {
    fileUploadError: this.cloudUpload.fileUploadError,
    onFileContents: (
      userFileName: string,
      fileContents: ArrayBuffer,
      contentType: string,
      prepareXHR: () => XMLHttpRequest
    ) => {
      if (!contentType) {
        contentType = 'application/octet-stream';
      }

      return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
    },
  };

  constructor(
    params: {
      trialId: string;
      id: string;
      result?: Deferred<trialDocumentsApi.TrialDocumentData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    this.trialId = params.trialId;

    const promise = Promise.all([
      params.id ? trialDocumentsApi.retrieve(params.trialId, params.id) : undefined,
    ]).then(([data]) => {
      this.entity(new TrialDocument(data));
    });
    this.loadedAfter(promise);
  }

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(trialDocumentsApi.save(this.trialId, data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let trialDocumentEdit = {
  name: 'trial-document-edit',
  viewModel: createWithComponent(TrialDocumentEditScreen),
  template: template,
};

ko.components.register(trialDocumentEdit.name, trialDocumentEdit);
