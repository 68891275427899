import * as ko from 'knockout';

import { app } from '../../app';
import i18n from '../../i18n';
import { Deferred } from '../../utils/deferred';
import { getDataEntryOptionalDims, getDataEntryOptionalTraits } from '../data_entry_api';

const template = require('raw-loader!./data_entry_select_optional.html').default;
const templateTrait = require('raw-loader!./data_entry_select_optional_trait.html').default;

class SelectOptional {
  loading = ko.observable(true);
  items = ko.observableArray<{ dmName: string; dimensions: SelectOptionalItem[] }>();

  constructor(
    private params: {
      trialId: string;
      currentlySelected: Set<string>;
      result: Deferred<Set<string>>;
    }
  ) {
    getDataEntryOptionalDims(params.trialId).then((dms) => {
      this.items(
        dms.map((dm) => ({
          dmName: dm.dm_name,
          dimensions: dm.dimensions.map((dim) => new SelectOptionalItem(dim, params.currentlySelected)),
        }))
      );
      this.loading(false);
    });
  }

  cancel = () => {
    this.params.result.resolve(this.params.currentlySelected);
  };

  confirm = () => {
    const res = new Set<string>();
    for (const item of this.items()) {
      for (const dimItem of item.dimensions) {
        if (dimItem.selected()) {
          res.add(dimItem.dim.id);
        }
      }
    }

    this.params.result.resolve(res);
  };
}

class SelectOptionalItem {
  selected = ko.observable(false);

  constructor(public dim: { id: string; name: string }, selected: Set<string>) {
    this.selected(selected.has(dim.id));
  }
}

const selectOptional = {
  name: 'data-entry-select-optional',
  viewModel: SelectOptional,
  template,
};

export function showSelectOptional(trialId: string, currentlySelected: Set<string>): Promise<Set<string>> {
  return app.formsStackController.push({
    title: i18n.t('Enable optional dimensions')(),
    name: selectOptional.name,
    showNav: true,
    isBig: true,
    params: {
      trialId,
      currentlySelected: currentlySelected,
      result: new Deferred<Set<string>>(),
    },
  });
}

class SelectOptionalTraits {
  loading = ko.observable(true);
  items = ko.observableArray<SelectOptionalTraitItem>();

  constructor(
    private params: {
      trialId: string;
      currentlySelected: Set<string>;
      result: Deferred<Set<string>>;
    }
  ) {
    getDataEntryOptionalTraits(params.trialId).then((traits) => {
      this.items(
        traits.map((trait) => new SelectOptionalTraitItem(trait.id, trait.name, params.currentlySelected))
      );
      this.loading(false);
    });
  }

  cancel = () => {
    this.params.result.resolve(this.params.currentlySelected);
  };

  confirm = () => {
    const res = new Set<string>();
    for (const item of this.items()) {
      if (item.selected()) {
        res.add(item.id);
      }
    }

    this.params.result.resolve(res);
  };
}

class SelectOptionalTraitItem {
  selected = ko.observable(false);

  constructor(public id: string, public name: string, selected: Set<string>) {
    this.selected(selected.has(id));
  }
}

const selectOptionalTrait = {
  name: 'data-entry-select-optional-trait',
  viewModel: SelectOptionalTraits,
  template: templateTrait,
};

ko.components.register(selectOptional.name, selectOptional);
ko.components.register(selectOptionalTrait.name, selectOptionalTrait);

export function showSelectOptionalTraits(
  trialId: string,
  currentlySelected: Set<string>
): Promise<Set<string>> {
  return app.formsStackController.push({
    title: i18n.t('Enable optional traits')(),
    name: selectOptionalTrait.name,
    showNav: true,
    isBig: true,
    params: {
      trialId,
      currentlySelected: currentlySelected,
      result: new Deferred<Set<string>>(),
    },
  });
}
