import * as ko from 'knockout';

const template = require('raw-loader!../../templates/components/progress_bar.html').default;

class ProgressBar {
  progress: ko.Observable<number>;
  points: number;
  pointWidth: number;

  interval: NodeJS.Timeout | null;

  playbackIsRunning: ko.Observable<boolean>;
  numberOfSteps: ko.Observable<number>;
  progressBarWidthUnit: number;
  progressBarWidth: ko.Observable<number>;
  subscriptions: ko.Subscription[];

  constructor(params: {
    progress: KnockoutObservable<number>;
    playbackIsRunning: KnockoutObservable<boolean>;
    numberOfSteps: KnockoutObservable<number>;
  }) {
    this.progress = params.progress;
    this.progressBarWidth = ko.observable(0);
    this.playbackIsRunning = params.playbackIsRunning;
    this.numberOfSteps = params.numberOfSteps;
    this.progressBarWidthUnit = 100 / params.numberOfSteps();
    this.subscriptions = [
      this.playbackIsRunning.subscribe((value) => {
        if (value) {
          this.startInterval();
        } else {
          this.stopInterval();
        }
      }),
      this.progress.subscribe((value) => {
        params.progress(value);
        this.progressBarWidth(this.progressBarWidthUnit * value);
      })
    ];
  }
  // clear interval when compoenent is removed
  dispose() {
    this.stopInterval();
    for(let sub of this.subscriptions) {
      sub.dispose();
    }
  }

  startInterval() {
    const intervalStep = 10000 / (this.numberOfSteps() - 1);
    this.interval = setInterval(() => {
      if (this.progress() < this.numberOfSteps()) {
        this.progress(this.progress() + 1);
      }
      if (this.progress() >= this.numberOfSteps()) {
        this.stopInterval();
      }
    }, intervalStep);
  }

  stopInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.playbackIsRunning(false);
      this.interval = null;
    }
  }

  startDrag(data: any, event: any) {
    this.stopInterval();

    const progressBar = event.currentTarget;
    const progressBarRect = progressBar.getBoundingClientRect();
    const progressBarWidth = progressBar.offsetWidth;

    const moveHandler = (event: any) => {
      const cursorX = event.clientX - progressBarRect.left;
      const newProgressBarWidth = Math.max(0, Math.min(100, (cursorX / progressBarWidth) * 100));
      const newProgress = Math.round(newProgressBarWidth / this.progressBarWidthUnit);
      this.progress(newProgress);
      this.progressBarWidth(Math.min(100, this.progressBarWidthUnit * newProgress));
    };

    const upHandler = () => {
      document.removeEventListener('mousemove', moveHandler);
      document.removeEventListener('mouseup', upHandler);
    };

    document.addEventListener('mousemove', moveHandler);
    document.addEventListener('mouseup', upHandler);
  }

  handleClick(data: any, event: any) {
    this.stopInterval();

    const progressBar = event.currentTarget;
    const progressBarWidth = progressBar.offsetWidth;
    const clickX = event.offsetX;
    const newProgressBarWidth = (clickX / progressBarWidth) * 100;
    const newProgress = Math.round(newProgressBarWidth / this.progressBarWidthUnit);
    this.progress(newProgress);
    this.progressBarWidth(Math.min(100, this.progressBarWidthUnit * newProgress));
  }
}

ko.components.register('progress-bar', {
  viewModel: ProgressBar,
  template,
});
