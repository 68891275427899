import * as ko from 'knockout';

import { Deferred } from '../../utils/deferred';
import { DatasetWizard } from '../../models/trial';
import { I18nText } from '../../i18n_text';

let template = require('raw-loader!../../../templates/components/trial_wizard/select_dataset.html').default;

class SelectDataset {
  selectedIndex = ko.observable<string>('0');

  title: string;
  selectLabel: string;
  datasets: { dataset: DatasetWizard; index: number; nameJson: I18nText }[] = [];

  private result: Deferred<DatasetWizard>;

  constructor(params: {
    title: string;
    selectLabel: string;
    datasets: DatasetWizard[];
    result: Deferred<DatasetWizard>;
  }) {
    this.title = params.title;
    this.selectLabel = params.selectLabel;

    this.datasets = params.datasets.map((dataset, index) => {
      return {
        dataset: dataset,
        index: index,
        nameJson: dataset.nameJson(),
      };
    });
    this.result = params.result;
  }

  onConfirm = () => {
    this.result.resolve(this.datasets[parseInt(this.selectedIndex(), 10)].dataset);
  };

  onCancel = () => {
    this.result.reject();
  };
}

ko.components.register('select-dataset', {
  viewModel: SelectDataset,
  template: template,
});
