import * as ko from 'knockout';

import { User } from '../models/user';
import { app } from '../app';
import i18n from '../i18n';
import { Deferred } from '../utils/deferred';
import { resendInvite } from '../api/users';

let template = require('raw-loader!../../templates/components/resend_invite.html').default;

class ResendInvite {
  user: User;
  sending = ko.observable(false);

  private result: Deferred<{}>;

  constructor(params: { user: User; result: Deferred<{}> }) {
    this.user = params.user;
    this.result = params.result;
  }

  send = async () => {
    this.sending(true);
    try {
      await resendInvite(this.user.id());
    } finally {
      this.sending(false);
    }
    this.result.resolve(null);
  };

  cancel = () => {
    this.result.resolve(null);
  };
}

const name = 'resend-invite';

ko.components.register(name, { viewModel: ResendInvite, template: template });

export function openResendInvite(user: User) {
  return app.formsStackController.push({
    title: i18n.t('Resend invite')(),
    name,
    params: { user, result: new Deferred<{}>() },
  });
}
