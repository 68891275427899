export function getColorForPValue(value: number) {
    const pValue = parseFloat(String(value))

    if(!pValue) {
      return null;
    }
    let color = 'green'
    if(pValue > 0.01 && pValue <= 0.05) {
      color = 'orange'
    } else if (pValue < 0.01) {
      color = 'red'
    }
    return color
}
