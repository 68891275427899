import * as ko from 'knockout';

import i18n from '../i18n';
import { downloadBlob } from '../utils';
import { session } from '../session';
import { injectTenantInURL } from '../api/request';
import { asObservable } from '../utils/ko_utils';
import { FileUploadDelegate } from './basic_widgets';

let template = require('raw-loader!../../templates/components/import_entities.html').default;

export interface ImportEntitiesDelegate<T = {}> {
  title: ko.Computed<string> | ko.Observable<string>;
  description: string;
  backTitle: string;
  loading?: KnockoutObservable<boolean>;
  downloadTemplateEnabled?: KnockoutSubscribable<boolean>;
  links?: { href: string; title: string }[];

  templateBaseName: string | ko.Observable<string>;
  downloadTemplate: () => Promise<Blob>;
  importUrl: string | (() => string);

  prepareFileContents?: (fileContents: ArrayBuffer) => ArrayBuffer | string;
  onSuccess?: (data: T) => Promise<ImportError[]>;
}

type ImportState = 'initial' | 'preparing' | 'importing' | 'imported';

export interface ImportError {
  sheet: string;
  cell: string;
  message: string;
}

class ImportEntities implements FileUploadDelegate {
  private delegate: KnockoutObservable<ImportEntitiesDelegate>;

  private defaultError = i18n.t(
    "Couldn't upload Excel file. Please check your internet connection and try again."
  )();
  preparingText = i18n.t('Preparing Excel template...');
  importingText = i18n.t('Importing Excel file...');

  loading = ko.pureComputed(() => (this.delegate().loading ? this.delegate().loading() : false));

  state = ko.observable<ImportState>('initial');
  errors = ko.observableArray<ImportError>();
  excelUploadError = ko.observable('');

  downloadTemplateEnabled = ko.pureComputed(() => {
    return this.delegate().downloadTemplateEnabled?.() ?? true;
  });

  constructor(
    params: { delegate: KnockoutObservable<ImportEntitiesDelegate> },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    this.delegate = asObservable(params.delegate);
  }

  dispose() {}

  goBack = () => {
    history.back();
  };

  downloadTemplate = () => {
    if (!this.downloadTemplateEnabled()) {
      return;
    }

    this.state('preparing');

    this.delegate()
      .downloadTemplate()
      .then((data) => {
        this.state('initial');
        const templateName = ko.unwrap(this.delegate().templateBaseName)
        downloadBlob(data, `${templateName} - ${new Date().toLocaleString()}.xlsx`);
      })
      .catch(() => {
        this.state('initial');
      });
  };

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest
  ) => {
    this.excelUploadError('');
    this.errors([]);

    let delegate = this.delegate();
    let data: ArrayBuffer | string = fileContents;
    if (delegate.prepareFileContents) {
      data = delegate.prepareFileContents(fileContents);
    }
    const delegateImportUrl = this.delegate().importUrl;
    const importUrl = typeof delegateImportUrl === 'string' ? delegateImportUrl : delegateImportUrl();

    session
      .getToken()
      .then((token) => {
        $.ajax(injectTenantInURL(importUrl), {
          method: 'PUT',
          data,
          processData: !(data instanceof ArrayBuffer),
          contentType: data instanceof ArrayBuffer ? undefined : 'application/json',
          headers: {
            Authorization: token,
            'X-User-Language': localStorage.getItem('userLanguage'),
            'Content-Disposition': 'attachment; filename="file"',
          },
          dataType: 'json',
          xhr: () => {
            let xhr = prepareXHR();
            xhr.upload.addEventListener('load', (event: ProgressEvent) => {
              let percent = Math.ceil((event.loaded / event.total) * 100);

              if (percent >= 100) {
                this.state('importing');
              }
            });

            return xhr;
          },
          success: async (res: { data: {}; errors: ImportError[] }) => {
            let errors = res.errors;

            if (errors.length === 0) {
              try {
                let onSuccess = this.delegate().onSuccess;
                if (onSuccess) {
                  errors = await onSuccess(res.data);
                }
                this.state('imported');
              } catch (e) {
                this.state('initial');
                throw e;
              }
            }
            if (errors.length > 0) {
              this.state('initial');
              this.excelUploadError(
                i18n.t(
                  'The uploaded Excel file is invalid. Please correct the following errors and re-upload the file:'
                )()
              );
              this.errors(errors);
            }
          },
          error: (xhr) => {
            if (xhr.status === 400 && xhr.responseJSON instanceof Array) {
              const errorMessage = xhr.responseJSON.join(' ');
              this.excelUploadError(errorMessage);
            } else {
              this.excelUploadError(this.defaultError);
            }
            this.state('initial');
          },
        });
      })
      .catch(() => {
        this.excelUploadError(this.defaultError);
      });
  };
}

let importEntities = {
  name: 'import-entities',
  viewModel: ImportEntities,
  template: template,
};

ko.components.register(importEntities.name, importEntities);
