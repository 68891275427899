import * as ko from 'knockout';

import { app } from '../app';
import { Deferred } from '../utils/deferred';

let template = require('raw-loader!../../templates/components/apply_unit_changes_to_all_columns_dialog.html').default;

const LOCAL_STORAGE_DONT_SHOW_AGAIN_KEY = 'applyUnitChangesDialogDontShowAgain';

class ApplyUnitChangesToAllColumnsDialog {
  disableCancel: boolean;
  fromUnitName: string;
  toUnitName: string;

  dontShowAgain = ko.observable<boolean>(false);

  private result: Deferred<{}>;

  constructor(params: {
    fromUnitName: string;
    toUnitName: string;
    text: string | string[];
    disableCancel: boolean;
    result: Deferred<{}>;
  }) {
    this.disableCancel = params.disableCancel;
    this.fromUnitName = params.fromUnitName;
    this.toUnitName = params.toUnitName;

    this.result = params.result;
  }

  onConfirm = () => {
    localStorage.setItem(LOCAL_STORAGE_DONT_SHOW_AGAIN_KEY, this.dontShowAgain().toString())
    this.result.resolve(true);
  };


  onCancel = () => {
    localStorage.setItem(LOCAL_STORAGE_DONT_SHOW_AGAIN_KEY, this.dontShowAgain().toString())
    this.result.resolve(false);
  };
}

ko.components.register('apply-unit-changes-to-all-columns-dialog', {
  viewModel: ApplyUnitChangesToAllColumnsDialog,
  template: template,
});

export function showApplyUnitChangesToAllColumnsDialog(
  fromUnitName: string,
  toUnitName: string,
): Promise<{}> {

  const shouldNotDisplayModal = localStorage.getItem(LOCAL_STORAGE_DONT_SHOW_AGAIN_KEY) === 'true'
  if(shouldNotDisplayModal) {
    return Promise.resolve(false);
  }
  return app.formsStackController.push({
    title: 'Apply',
    name: 'apply-unit-changes-to-all-columns-dialog',
    params: {
      fromUnitName,
      toUnitName,
      result: new Deferred<{}>(),
    },
  });
}
