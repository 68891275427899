/// <reference path="../type_definitions/globals.d.ts"/>

import page from 'page';

import { app } from '../app';
import { session } from '../session';
import { requireAuthorized } from '../screens/login';
import { NameEntityConfig } from '../models/name';
import { nameEdit } from '../screens/name_edit';
import { names } from '../screens/names';
import { showNeedPageReload } from '../api/base_request';
import * as qs from 'querystring';

const SPECIAL_AND_CHAR = '%20%26%20';

class ConfirmNavigation {
  static instance: ConfirmNavigation = null;

  constructor(public text: string, public check: () => boolean) {}
}

export function confirmNavigation(text: string, check: () => boolean) {
  ConfirmNavigation.instance = new ConfirmNavigation(text, check);
}

export function clearConfirmNavigation() {
  ConfirmNavigation.instance = null;
}

window.onbeforeunload = (event: Event) => {
  if (ConfirmNavigation.instance !== null && ConfirmNavigation.instance.check()) {
    event.returnValue = !!ConfirmNavigation.instance.text;
    return ConfirmNavigation.instance.text;
  }

  return undefined;
};

export function setupBaseRoutes() {
  let currentPositionInHistory = 0;
  let ignoreNextNavigationEvent = false;

  page('*', (ctx, next) => {
    // position in history excluding the navigation action currently being processed
    let previousPositionInHistory = currentPositionInHistory;
    let isNewLocation = false;

    if (!ctx.state.positionInHistory) {
      // user navigated to a new place (i.e. he didn't use back/forward buttons)
      isNewLocation = true;
      ctx.state.positionInHistory = ++currentPositionInHistory;
      if (ctx.init) {
        // initial page, save manually because
        // in this case the state is saved before
        // the dispatch, and not afterwards
        ctx.save();
      }
    }
    currentPositionInHistory = ctx.state.positionInHistory;

    if (ignoreNextNavigationEvent) {
      ignoreNextNavigationEvent = false;
      return;
    }

    if (
      ConfirmNavigation.instance !== null &&
      ConfirmNavigation.instance.check() &&
      !confirm(ConfirmNavigation.instance.text)
    ) {
      // cancel the current navigation action

      if (isNewLocation) {
        // normal navigation (i.e. not through back/forward buttons)
        ctx.handled = false; // block page.js from pushing a new state
        currentPositionInHistory = previousPositionInHistory;
      } else {
        // navigation through back/forward buttons:
        // undo the navigation by going back/forward
        // by the same number of positions and in
        // opposite direction. Mark the flag so we won't
        // render the page again (history.go triggers onpopstate).
        ignoreNextNavigationEvent = true;
        history.go(previousPositionInHistory - ctx.state.positionInHistory);
      }

      // don't render the page (i.e. don't call next())
      return;
    }

    session.updateTenant(ctx.pathname);
    app.formsStackController.clear();
    next();
  });

  page('*', (ctx, next) => {
    if (showNeedPageReload()) {
      page.stop();
      // note: location.href is still the previous page at this point,
      // use the url in the ctx instead since it's the new one
      location.href = ctx.canonicalPath;
    } else {
      next();
    }
  });

  page('/t/:tenantId/logout/', (ctx, next) => {
    session.logout().then(() => {
      page.redirect('/');
    });
  });

  page('/logout/', (ctx, next) => {
    session.logout().then(() => {
      page.redirect('/');
    });
  });
}

export function parseCanonicalPath(path: string) {
  let parts = path.split('?');
  if (parts.length < 2) {
    return {};
  }

  return parseQueryString(parts[1], decodeURIComponent);
}

export function parseQueryString(queryString: string, valueDecoder: (x: string) => string = (x) => x) {
  return window.location.href.includes(SPECIAL_AND_CHAR)
    ? qs.parse(window.location.href.split('?')[1])
    : qs.parse(queryString);
}

interface CURoute {
  baseName: string;
  listComponent: { name: string };
  editComponent?: { name: string };
  title: string;
  params?: {};
}

export function setupCreateUpdateRoutes(entities: CURoute[]) {
  for (let entity of entities) {
    page('/t/:tenantId/' + entity.baseName + '/', requireAuthorized, (ctx, next) => {
      app.currentPage({
        name: entity.listComponent.name,
        title: entity.title,
        params: { ...getFiltersParams(ctx), ...entity.params },
      });
    });

    if (entity.editComponent) {
      page('/t/:tenantId/' + entity.baseName + '/new/', requireAuthorized, (ctx, next) => {
        app.currentPage({
          name: entity.editComponent.name,
          title: entity.title,
          params: { id: null, ...entity.params },
        });
      });

      page('/t/:tenantId/' + entity.baseName + '/:id/', requireAuthorized, (ctx, next) => {
        app.currentPage({
          name: entity.editComponent.name,
          title: entity.title,
          params: { id: ctx.params.id, ...entity.params },
        });
      });
    }
  }
}

export function setupNameEntityRoutes(entities: NameEntityConfig[]) {
  for (let nameEntity of entities) {
    page('/t/:tenantId/' + nameEntity.baseName + '/new/', requireAuthorized, (ctx, next) => {
      app.currentPage({
        name: nameEdit.name,
        params: { id: null, ...nameEntity },
      });
    });

    page('/t/:tenantId/' + nameEntity.baseName + '/:id/', requireAuthorized, (ctx, next) => {
      app.currentPage({
        name: nameEdit.name,
        params: { id: ctx.params.id, ...nameEntity },
      });
    });

    page('/t/:tenantId/' + nameEntity.baseName + '/', requireAuthorized, (ctx, next) => {
      app.currentPage({
        name: names.name,
        params: {
          baseName: nameEntity.baseName,
          title: nameEntity.title,
          filters: parseQueryString(ctx.querystring),
        },
      });
    });
  }
}

export function getFiltersParams(ctx: any) {
  return { filters: parseQueryString(ctx.querystring) };
}
