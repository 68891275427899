import * as ko from 'knockout';
import page from 'page';

import { ListRequestParams, RemoveResult } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import {
  changelog,
  ChangelogData,
  ChangelogEntity,
  isChangelogAtom,
  ChangelogValue,
  isChangelogFK,
  isChangelogFKS,
  isChangelogI18n,
} from '../api/changelog';
import i18n from '../i18n';
import { tryFormatDate, toDict, MONTH_OPTIONS } from '../utils';
import { Action } from '../components/basic_widgets';
import { session } from '../session';
import { ListHeaderAction } from '../components/list_header';
import { ROLE_OPTIONS } from '../models/country';
import { LANGUAGES, translate } from '../i18n_text';
import { ftUserOptions, s2bimUserOptions } from '../models/user';
import { canViewChangelog } from '../permissions';

let template = require('raw-loader!../../templates/changelog.html').default;

const ENTITY_VERBOSE_NAMES: { [key: string]: string } = {
  sale: i18n.t('Sale')(),
  production: i18n.t('Production')(),
  country: i18n.t('Country')(),
  season: i18n.t('Season')(),
  user: i18n.t('User')(),
  assumption: i18n.t('Assumption')(),
  license: i18n.t('License')(),
  registration: i18n.t('Registration')(),
  breeder: i18n.t('Breeder')(),
  buyer: i18n.t('Buyer')(),
  producer: i18n.t('Producer')(),
  organization_type: i18n.t('Organization type')(),
  organizational_region: i18n.t('Organizational region')(),
  project: i18n.t('Program')(),
  portofolio_item: i18n.t('Portofolio Item')(),
  seed_treatment: i18n.t('Seed treatment')(),
  seed_treatment_sale: i18n.t('Seed treatment sale')(),
};

const ORG_ATTRS: { [key: string]: string } = {
  name: i18n.t('Name')(),
  country: i18n.t('Country')(),
  contact: i18n.t('Contact')(),
  address: i18n.t('Address')(),
  phone: i18n.t('Phone')(),
  email: i18n.t('Email')(),
  responsible: i18n.t('Internal point of contact')(),
  organization_type: i18n.t('Organization type')(),
  user_email: i18n.t('Login email')(),
};

const ATTR_VERBOSE_NAMES: { [key: string]: { [key: string]: string } } = {
  sale: {
    production: i18n.t('Production')(),
    country: i18n.t('Country')(),
    crop_variety: i18n.t('Crop variety')(),
    seller: i18n.t('Seller')(),
    buyer: i18n.t('Buyer')(),
    sale_date: i18n.t('Date of sale')(),
    quantity: i18n.t('Quantity actual (metric tons)')(),
    quantity_forecast: i18n.t('Quantity forecast (metric tons)')(),
    quantity_certified_seeds_actual: i18n.t('Quantity certified seeds actual (metric tons)')(),
    quantity_certified_seeds_forecast: i18n.t('Quantity certified seeds forecast (metric tons)')(),
    quantity_early_gen_actual: i18n.t('Quantity early gen actual (metric tons)')(),
    quantity_early_gen_forecast: i18n.t('Quantity early gen forecast (metric tons)')(),
    price: i18n.t('Price actual (USD/metric tons)')(),
    price_forecast: i18n.t('Price forecast (USD/metric tons)')(),
    price_certified_seeds_actual: i18n.t('Price certified seeds actual (USD/metric tons)')(),
    price_certified_seeds_forecast: i18n.t('Price certified seeds forecast (USD/metric tons)')(),
    price_early_gen_actual: i18n.t('Price early gen actual (USD/metric tons)')(),
    price_early_gen_forecast: i18n.t('Price early gen forecast (USD/metric tons)')(),
    portofolio_item: i18n.t('PI ID')(),
    comment: i18n.t('Comment')(),
    project: i18n.t('Program')(),
    yield_gain: i18n.t('New variety yield gain (%)')(),
    validated_by: i18n.t('Validated by')(),
  },
  production: {
    country: i18n.t('Country')(),
    crop_variety: i18n.t('Crop variety')(),
    producer: i18n.t('Producer')(),
    season_year: i18n.t('Year of harvest')(),
    season: i18n.t('Season')(),
    quantity: i18n.t('Quantity of certified seeds (metric tons)')(),
    quantity_early_gen: i18n.t('Quantity of early generation seeds (metric tons)')(),
    quantity_forecast: i18n.t('Quantity of certified seeds forecast (metric tons)')(),
    quantity_early_gen_forecast: i18n.t('Quantity of early generation seeds forecast (metric tons)')(),
    area_grown: i18n.t('Area of seeds grown actual (ha)')(),
    area_grown_forecast: i18n.t('Area of seeds grown forecast (ha)')(),
    area_grown_egs: i18n.t('Area of egs seeds grown (ha)')(),
    area_grown_egs_forecast: i18n.t('Area of egs seeds grown forecast (ha)')(),
    area_grown_certified_seeds: i18n.t('Area of certified seeds grown (ha)')(),
    area_grown_certified_seeds_forecast: i18n.t('Area of certified seeds grown forecast (ha)')(),
    portofolio_item: i18n.t('PI ID')(),
    project: i18n.t('Program')(),
    comment: i18n.t('Comment')(),
    validated_by: i18n.t('Validated by')(),
  },
  country: {
    name: i18n.t('Name')(),
    iso_country_code: i18n.t('Code')(),
    regions: i18n.t('Regions')(),
    season_1: i18n.t('1st season')(),
    month_of_harvest_1: i18n.t('1st season month of harvest')(),
    season_2: i18n.t('2nd season')(),
    month_of_harvest_2: i18n.t('2nd season month of harvest')(),
    season_3: i18n.t('3rd season')(),
    month_of_harvest_3: i18n.t('3rd season month of harvest')(),
    user_roles: i18n.t('User permissions')(),
  },
  user_country: {
    user: i18n.t('User')(),
    role: i18n.t('Role')(),
  },
  season: {
    name_json: i18n.t('Name')(),
    code: i18n.t('Code')(),
  },
  user: {
    name: i18n.t('Name')(),
    email: i18n.t('Email')(),
    role: i18n.t('Role')(),
    country_user_roles: i18n.t('Country roles')(),
  },
  country_user: {
    country: i18n.t('Country')(),
    role: i18n.t('Role')(),
  },
  assumption: {
    crop: i18n.t('Crop')(),
    country: i18n.t('Country')(),
    year: i18n.t('Valid from year')(),
    month: i18n.t('Valid from month')(),
    avg_plot_size: i18n.t('Average farmer plot size (ha)')(),
    annual_crop_area_grown: i18n.t('Annual crop area of seeds grown (ha)')(),
    seed_planting_density: i18n.t('Seed planting density (metric tons/ha)')(),
    seed_production: i18n.t('Seed production (metric tons)')(),
    seed_imports: i18n.t('Seed imports (metric tons)')(),
    seed_price: i18n.t('Seed price (USD/ton)')(),
    early_gen_seed_price: i18n.t('Average early generation seed price (USD/ton)')(),
    crop_price: i18n.t('Crop price (USD/ton)')(),
    gross_profit: i18n.t('Farmers gross profit (USD/ha)')(),
    baseline_yield: i18n.t('Check yield (metric tons/ha)')(),
    yield_gain: i18n.t('Average yield gain of new varieties (%)')(),
    check_variety: i18n.t('Check variety')(),
    comment: i18n.t('Comment')(),
    buying_frequencies: i18n.t('Buying intervals')(),
  },
  buying_frequency: {
    crop_variety_type: i18n.t('OPV/Hybrid')(),
    frequency: i18n.t('Buying interval (years)')(),
  },
  license: {
    crop_variety: i18n.t('Crop variety')(),
    producer: i18n.t('Producer')(),
    royalty: i18n.t('Royalty (%)')(),
    start_year: i18n.t('Start year')(),
    end_year: i18n.t('End year')(),
    support_by_sfsa: i18n.t('SFSA supported')(),
    portofolio_items: i18n.t('PIs')(),
  },
  registration: {
    crop_variety: i18n.t('Crop variety')(),
    country: i18n.t('Country')(),
    region: i18n.t('Region')(),
    support_by_sfsa: i18n.t('SFSA supported')(),
    portofolio_items: i18n.t('PIs')(),
  },
  breeder: ORG_ATTRS,
  buyer: ORG_ATTRS,
  producer: ORG_ATTRS,
  organization_type: {
    name_json: i18n.t('Name')(),
    public: i18n.t('Public/Private')(),
    use_for_breeders: i18n.t('Use for breeders')(),
    use_for_producers: i18n.t('Use for producers')(),
    use_for_customers: i18n.t('Use for customers')(),
  },
  organizational_region: {
    name: i18n.t('Name')(),
  },
  project: {
    name: i18n.t('Name')(),
    from_date: i18n.t('From')(),
    to_date: i18n.t('To')(),
    users: i18n.t('Partners')(),
    items: i18n.t('Crop/Country/Producer')(),
  },
  portofolio_item: {
    number: i18n.t('ID')(),
    name: i18n.t('Name')(),
    from_date: i18n.t('From')(),
    to_date: i18n.t('To')(),
    status: i18n.t('Status')(),
  },
  project_item: {
    crop: i18n.t('Crop')(),
    country: i18n.t('Country')(),
    producer: i18n.t('Producer')(),
  },
  seed_treatment: {
    name: i18n.t('Name')(),
    crop: i18n.t('Crop')(),
    owner: i18n.t('Owner')(),
    yield_gain: i18n.t('Yield gain (%)')(),
    application_rate: i18n.t('Application rate (grams of treatment per kg of seeds)')(),
  },
  seed_treatment_sale: {
    country: i18n.t('Country')(),
    seed_treatment: i18n.t('Seed treatment')(),
    seller: i18n.t('Seller')(),
    buyer: i18n.t('Buyer')(),
    season_year: i18n.t('Year of harvest')(),
    season: i18n.t('Season')(),
    quantity: i18n.t('Quantity actual (metric tons)')(),
    portofolio_item: i18n.t('PI ID')(),
    quantity_forecast: i18n.t('Quantity forecast (metric tons)')(),
    project: i18n.t('Program')(),
    price: i18n.t('Price actual (USD/metric tons)')(),
    price_forecast: i18n.t('Price forecast (USD/metric tons)')(),
  },
};

const MONTH_ENUM = toDict(MONTH_OPTIONS, (opt) => [opt.value, opt.name]);
const COUNTRY_ROLE_ENUM = toDict(ROLE_OPTIONS, (role) => [role.value, role.name]);
const ALL_ROLES = ftUserOptions.roleOptions
  .concat(ftUserOptions.adminRoleOptions)
  .concat(s2bimUserOptions.roleOptions)
  .concat(s2bimUserOptions.adminRoleOptions);
const ATOM_ENUMS: {
  [entityType: string]: { [attrName: string]: { [enumSlug: string]: string } };
} = {
  user_country: { role: COUNTRY_ROLE_ENUM },
  country: {
    month_of_harvest_1: MONTH_ENUM,
    month_of_harvest_2: MONTH_ENUM,
    month_of_harvest_3: MONTH_ENUM,
  },
  country_user: { role: COUNTRY_ROLE_ENUM },
  user: { role: toDict(ALL_ROLES, (role) => [role.id, role.name]) },
  assumption: { month: MONTH_ENUM },
  organization_type: {
    public: { true: i18n.t('Public')(), false: i18n.t('Private')() },
  },
};

export function addChangelogListAction(entityType: string, actions?: ListHeaderAction[]) {
  actions = actions || [];

  if (canViewChangelog(entityType)) {
    return actions.concat([
      {
        title: i18n.t('Changelog')(),
        icon: 'track_changes',
        href:
          '/changelog/?entityType=' +
          entityType +
          '&backRef=' +
          encodeURIComponent(location.pathname + location.search),
      },
    ]);
  } else {
    return actions;
  }
}

export function addChangelogAction(entityType: string, entityId: string, actions?: Action[]): Action[] {
  actions = actions || [];

  if (canViewChangelog(entityType)) {
    return actions.concat([
      {
        icon: 'track_changes',
        title: i18n.t('Changelog')(),
        cssClass: '',
        onClick: () =>
          page(
            session.toTenantPath(
              '/changelog/?entityType=' +
                entityType +
                '&entityId=' +
                entityId +
                '&backRef=' +
                encodeURIComponent(location.pathname + location.search)
            )
          ),
      },
    ]);
  } else {
    return actions;
  }
}

class ChangelogScreen implements ListLoaderDelegate<ChangelogData> {
  constructor(
    public params: {
      entityId?: string;
      entityType?: string;
      userId?: string;
      backRef?: string;
    }
  ) {}

  fetch(params: ListRequestParams) {
    return changelog({
      user_id: this.params.userId || null,
      entity_id: this.params.entityId || null,
      entity_type: this.params.entityType || null,
      ...params,
    });
  }

  instantiate(data: ChangelogData) {
    return data;
  }

  getEditUrl(entity: ChangelogData): string {
    return null;
  }

  canRemove(data: ChangelogData) {
    return false;
  }

  remove(id: string): Promise<RemoveResult> {
    return Promise.reject(null);
  }

  formatEntityName(diff: ChangelogEntity): string {
    return ENTITY_VERBOSE_NAMES[diff.name];
  }

  formatDiff(diff: ChangelogEntity): string {
    let msgs: string[] = [];

    if (diff.action === 'deleted') {
      msgs.push(i18n.t('Removed')());
    }
    if (diff.action === 'created') {
      msgs.push(i18n.t('Created')());
    }

    msgs = msgs.concat(formatDiffAttrs(diff));

    return msgs.join('\n');
  }
}

function formatDiffAttrs(diff: ChangelogEntity): string[] {
  let msgs: string[] = [];

  for (let attr of diff.attrs) {
    let attrName = (ATTR_VERBOSE_NAMES[diff.name] || {})[attr.name];
    if (!attrName) {
      continue;
    }
    let enums = (ATOM_ENUMS[diff.name] || {})[attr.name] || {};

    if (isChangelogAtom(attr) && (diff.action === 'created' || changelogValueEq(attr.new, attr.old))) {
      msgs.push(attrName + ': ' + formatAtomValue(attr.name, attr.new, enums));
    } else if (isChangelogAtom(attr) && diff.action === 'deleted') {
      msgs.push(attrName + ': ' + formatAtomValue(attr.name, attr.old, enums));
    } else if (isChangelogAtom(attr)) {
      msgs.push(
        attrName +
          ': ' +
          formatAtomValue(attr.name, attr.old, enums) +
          ' ➟ ' +
          formatAtomValue(attr.name, attr.new, enums)
      );
    } else if (isChangelogI18n(attr)) {
      for (let lang of LANGUAGES) {
        let trDiff = attr.translations[lang[0]];
        if (trDiff) {
          let prefix = attrName + ' (' + lang[0] + '): ';
          if (diff.action === 'created' || trDiff.new === trDiff.old) {
            msgs.push(prefix + formatAtomValue(attr.name, trDiff.new, enums));
          } else {
            msgs.push(
              prefix +
                formatAtomValue(attr.name, trDiff.old, enums) +
                ' ➟ ' +
                formatAtomValue(attr.name, trDiff.new, enums)
            );
          }
        }
      }
    } else if (isChangelogFKS(attr)) {
      for (let value of attr.added) {
        msgs.push(
          i18n.t('{{attrName}}: added {{added}}', {
            attrName,
            added: value.name,
            interpolation: { escapeValue: false },
          })()
        );
      }
      for (let value of attr.removed) {
        msgs.push(
          i18n.t('{{attrName}}: removed {{removed}}', {
            attrName,
            removed: value.name,
            interpolation: { escapeValue: false },
          })()
        );
      }
    } else {
      for (let entity of attr.entities) {
        msgs.push(attrName + ': ' + formatNestedDiff(entity));
      }
    }
  }

  return msgs;
}

function changelogValueEq(x: ChangelogValue, y: ChangelogValue): boolean {
  if (isChangelogFK(x) && isChangelogFK(y)) {
    return x.id === y.id;
  }

  return x === y;
}

function formatNestedDiff(diff: ChangelogEntity): string {
  let prefix: string;
  if (diff.action === 'deleted') {
    prefix = i18n.t(['removed_lowercase', 'removed'])();
  } else if (diff.action === 'created') {
    prefix = i18n.t('added')();
  } else {
    prefix = i18n.t('changed')();
  }

  return prefix + ' (' + formatDiffAttrs(diff).join(', ') + ')';
}

function formatAtomValue(attr: string, value: ChangelogValue, enums: { [key: string]: string }): string {
  if (value === '' || value === null || value === undefined) {
    return i18n.t(['empty_lowercase', 'empty'])();
  }
  if (isChangelogFK(value)) {
    return value.id === null ? i18n.t(['empty_lowercase', 'empty'])() : translate(value.name);
  }
  if (attr === 'season_year' || attr === 'year' || attr === 'start_year' || attr === 'end_year') {
    return value.toString(); // return unchanged
  }
  if (enums[value.toString()]) {
    return enums[value.toString()];
  }
  if (value === true) {
    return i18n.t('Yes')();
  }
  if (value === false) {
    return i18n.t('No')();
  }
  if (typeof value === 'number') {
    return value.toLocaleString();
  }

  return tryFormatDate(value);
}

export let changelogScreen = {
  name: 'changelog',
  viewModel: ChangelogScreen,
  template: template,
};

ko.components.register(changelogScreen.name, changelogScreen);
