import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { Season } from '../models/season';
import * as seasonsApi from '../api/seasons';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/season_edit.html').default;

class SeasonEditScreen extends BaseForm<seasonsApi.SeasonData> {
  entity = ko.validatedObservable<Season>(null);

  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<seasonsApi.SeasonData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let dataPromise = params.id ? seasonsApi.retrieve(params.id) : undefined;
    let promise = Promise.all([dataPromise]).then(([data]) => {
      this.entity(new Season(data));
      if (!data && params.initialName) {
        this.entity().nameJson(asI18nText(params.initialName));
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(seasonsApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let seasonEdit = {
  name: 'season-edit',
  viewModel: createWithComponent(SeasonEditScreen),
  template: template,
};

ko.components.register(seasonEdit.name, seasonEdit);
