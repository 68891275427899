import { request } from './request';

export interface UnitCategoryData {
  slug: string;
  name: string;
  canonical_unit: string;
}

export function list(): Promise<UnitCategoryData[]> {
  return request('GET', '/api/unit_categories/');
}
