import * as ko from 'knockout';
import page from 'page';

import { session } from './session';
import { FormsStackController } from './components/forms_stack';
import { NavGroup } from './components/navigation_bar';

const ENGLISH_LANGUAGE_CODE = 'en';

class AppViewModel {
  productName = SERVER_INFO.PRODUCT_NAME;

  currentPage = ko.observable<{ name: string; title?: string; params?: {} }>(null);

  authorized = ko.pureComputed(() => {
    return session.authorized();
  });
  showNavigationBar = ko.observable(true);

  tenantName = ko.pureComputed(() => {
    return session.tenant() ? session.tenant().name : '';
  });

  formsStackController = new FormsStackController();

  thirdPartyCookiesError = ko.observable(false);
  showLoggedOut = ko.observable(false);
  showServerError = ko.observable<(retry: boolean) => void>(null);
  showNetworkError = ko.observable<(retry: boolean) => void>(null);
  showEntityNotFound = ko.observable(false);
  formSubmissionErrorModalVisibility = ko.observable(false);
  showTourOverlay = ko.observable(false);
  formSubmissionErrorModalMessage = ko.observable<string>('');

  private getMenu: () => NavGroup[];

  initMenu(getMenu: () => NavGroup[]) {
    this.getMenu = getMenu;
  }

  confirmLoggedOut = () => {
    session.logout().then(() => {
      this.showLoggedOut(false);
      location.href = location.href; // reload the page to login again
    });
  };

  retryServerError = () => {
    this.showServerError()(true);
    this.showServerError(null);
  };

  closeServerError = () => {
    this.showServerError()(false);
    this.showServerError(null);
  };

  retryNetworkError = () => {
    this.showNetworkError()(true);
    this.showNetworkError(null);
  };

  closeNetworkError = () => {
    this.showNetworkError()(false);
    this.showNetworkError(null);
  };

  closeEntityNotFound = () => {
    this.showEntityNotFound(false);
    page.redirect(session.toTenantPath('/'));
  };

  showFormSubmissionErrorModal = (message: string) => {
    this.formSubmissionErrorModalVisibility(true);
    this.formSubmissionErrorModalMessage(message);
  };

  closeFormSubmissionErrorModal = () => {
    page.stop();
    location.href = location.href;
    this.formSubmissionErrorModalVisibility(false);
  };

  closeThirdPartyCookiesError = () => {
    page.stop();
    location.href = location.href;
  };

  renewSubscription = () => {
    // page.redirect doesn't play nicely here
    location.href = session.toTenantPath('/subscription/');
  };

  menu = ko.pureComputed(() => this.getMenu());
}

export let app = new AppViewModel();

export const RTL_LANGUAGES = ['ar'];
const userLanguage = localStorage.getItem('userLanguage');

export function setupApp(getMenu: () => NavGroup[]) {
  if (RTL_LANGUAGES.includes(userLanguage)) {
    document.body.style.direction = 'rtl';
    document.dir = 'rtl';
  } else {
    document.dir = 'ltr';
  }
  if (!localStorage.getItem('userLanguage')) {
    const supportedBrowserLanguage = SERVER_INFO.LANGUAGE_LIST.find((language) =>
      navigator.language.toLowerCase().includes(language.code)
    );
    localStorage.setItem(
      'userLanguage',
      supportedBrowserLanguage ? supportedBrowserLanguage.code : ENGLISH_LANGUAGE_CODE
    );
  }
  app.initMenu(getMenu);
  ko.applyBindings(app, document.body);
}
