import * as ko from 'knockout';

import * as mmTagsApi from '../api/measurement_meta_tags';
import { I18nText } from '../i18n_text';
import { SlugGenerator } from '../ko_bindings/slug_validation';

export class MeasurementMetaTag {
  private slugGenerator: SlugGenerator;

  id = ko.observable<string>(null);
  code = ko.observable<string>('').extend({
    required: true,
    serverError: true,
  });
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });

  constructor(data?: mmTagsApi.MeasurementMetaTagData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.code(data.code);
    }

    this.slugGenerator = new SlugGenerator(this.nameJson, null, this.code, {
      canEdit: !this.id(),
      fillIfEmpty: true,
    });
  }

  toData(): mmTagsApi.MeasurementMetaTagData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      code: this.code(),
    };
  }

  dispose() {
    this.slugGenerator.dispose();
  }
}
