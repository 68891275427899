import * as ko from 'knockout';
import * as couponsApi from '../api/coupons';
import i18n from '../i18n';

/**
 * Encapsulates Coupon data and logic.
 *
 * XXX: Currently we only work with USD. For amount_off coupons we assume they
 * XXX: are denominated in USD.
 */
export class Coupon {
  id = ko.observable<string>();
  amountOff = ko.observable<number>();
  percentOff = ko.observable<number>();
  currency = ko.observable<string>();
  duration = ko.observable<string>();
  durationInMonths = ko.observable<number>();

  constructor(data: couponsApi.CouponData) {
    this.id(data.id);
    this.amountOff(data.amount_off);
    this.percentOff(data.percent_off);
    this.currency(data.currency);
    this.duration(data.duration);
    this.durationInMonths(data.duration_in_months);
  }

  private formatAmount(amount: number) {
    let dollarAmount = amount / 100;

    return '$' + dollarAmount.toLocaleString();
  }

  private amountDescription(): string {
    if (this.amountOff()) {
      return this.formatAmount(this.amountOff());
    } else {
      return this.percentOff() + '%';
    }
  }

  discountDescription(yearly: boolean): string {
    let value: string;
    let options = {
      amount: this.amountDescription(),
      duration_in_months: this.durationInMonths(),
    };

    if (this.amountOff()) {
      // if this is one-off coupon, duration doesn't apply
      value = i18n.t('{{ amount }} off', options)();
    } else {
      if (this.duration() === 'forever') {
        value = i18n.t('{{ amount }} off for the whole duration of subscription', options)();
      } else if (this.duration() === 'once') {
        value = i18n.t('{{ amount }} off for the first purchase', options)();
      } else if (this.duration() === 'repeating') {
        // XXX: due to inconsistent Stripe behavior, for annual subscriptions
        // XXX: the coupon is applied to the whole year regardless of durationInMonths
        // XXX: https://groups.google.com/a/lists.stripe.com/forum/#!topic/api-discuss/SooBvdNPPJQ
        if (yearly) {
          value = i18n.t('{{ amount }} off for the first year', options)();
        } else {
          value = i18n.t('{{ amount }} off for {{ duration_in_months }} months', options)();
        }
      } else {
        // shouldn't happen
        value = '';
      }
    }

    return value;
  }

  /**
   * Apply this coupon's discount to the given amount.
   *
   * @param amount  base amount to apply the discount to
   */
  applyDiscount(amount: number) {
    if (this.amountOff()) {
      return amount - this.amountOff();
    } else {
      // even for yearly subscriptions, the discount is applied to the whole
      // amount contrary to the Stripe docs. see comment in durationDescription()
      return (amount * (100 - this.percentOff())) / 100;
    }
  }
}
