import * as ko from 'knockout';

import 'chartjs-adapter-moment';
import 'chart.js/auto';
import { Chart, ChartDataset, Plugin } from 'chart.js';

Chart.register({
  id: 'white-background',
  beforeDraw: function (chart, easing) {
    var ctx = chart.ctx;

    ctx.save();
    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
    ctx.restore();
  },
});

const errorBarPlugin: Plugin = {
  id: 'error-bar-plugin',
  afterDatasetDraw: (chart) => {
    let ctx = chart.ctx;

    for (let i = 0; i < chart.data.datasets.length; i++) {
      let metaData = chart.getDatasetMeta(i).data;

      let j = 0;
      for (let value of chart.data.datasets[i].data) {
        if (value === null || value === undefined || typeof value === 'number') {
          continue;
        }
        let point = value as { x?: number; y: number; error?: number };
        if (!point.error) {
          continue;
        }
        let yScale = (metaData[j] as any)._yScale as any;

        let centerX = (metaData[j] as any)._model.x;
        let centerY = yScale.getPixelForValue(point.y);
        let errPixSize = chart.chartArea.bottom - yScale.getPixelForValue(point.error);

        ctx.save();
        ctx.lineWidth = 1;
        ctx.setLineDash([4, 4]);

        ctx.beginPath();
        ctx.moveTo(centerX, centerY - errPixSize);
        ctx.lineTo(centerX, centerY + errPixSize);
        ctx.strokeStyle = 'rgba(200, 0, 0, 0.5)';
        ctx.stroke();

        ctx.setLineDash([]);
        ctx.beginPath();
        ctx.moveTo(centerX - 5, centerY - errPixSize);
        ctx.lineTo(centerX + 5, centerY - errPixSize);
        ctx.moveTo(centerX - 5, centerY + errPixSize);
        ctx.lineTo(centerX + 5, centerY + errPixSize);
        ctx.strokeStyle = 'rgba(200, 0, 0, 1)';
        ctx.stroke();

        ctx.restore();

        j++;
      }
    }
  },
};

function verticalMarkerPlugin(options: { title: string; x: string }): Plugin {
  if (!options) {
    return;
  }

  return {
    id: 'vertical-marker-plugin',
    beforeDraw: (chart) => {
      const xTimestamp = new Date(options.x).getTime();
      const x = (chart as any).scales['x'].getPixelForValue(xTimestamp);
      let ctx = chart.ctx;

      ctx.save();

      ctx.fillStyle = '#689F38';
      ctx.textBaseline = 'top';
      ctx.fillText(options.title, x + 5, chart.chartArea.top + 5);

      ctx.lineWidth = 1;
      ctx.strokeStyle = '#689F38';

      ctx.beginPath();
      ctx.moveTo(x, chart.chartArea.bottom);
      ctx.lineTo(x, chart.chartArea.top);
      ctx.stroke();

      ctx.restore();
    },
  };
}

export interface BarChartConfig {
  type: 'bar' | 'line' | 'scatter';
  scaleType?: 'category' | 'time' | 'linear';
  title: string;
  aspectRatio?: number;
  yTitle?: string;
  yTitle2?: string;
  xTitle: string;
  labels: string[];
  fontSize: number;
  timeUnit?: 'day' | 'month';
  hideLegend?: boolean;
  verticalLine?: { title: string; x: string };
  visible?: boolean;
  datasets: {
    type?: 'bar' | 'line' | 'scatter';
    label: string;
    stack?: string;
    color_key?: string;
    gradient?: number;
    color?: string;
    second_axis?: boolean;
    data: number[] | { x: number; y: number; error?: number }[];
  }[];
}

export interface ChartList {
  [key: string]: BarChartConfig;
}

function stripedPattern(element: HTMLCanvasElement, color: string): CanvasPattern {
  const pattern = document.createElement('canvas');
  pattern.width = 16;
  pattern.height = 6;

  const patternCtx = pattern.getContext('2d');

  patternCtx.strokeStyle = color;
  patternCtx.lineWidth = 1;
  patternCtx.moveTo(0, 0);
  patternCtx.lineTo(16, 0);
  patternCtx.stroke();

  const ctx = element.getContext('2d');
  return ctx.createPattern(pattern, 'repeat');
}

function getColor(canvas: HTMLCanvasElement, color: string, gradient: number): string | CanvasPattern {
  if (gradient === undefined || gradient === null) {
    return color;
  }

  if (gradient > 1) {
    return stripedPattern(canvas, color);
  } else {
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${gradient * 0.7 + 0.3})`;
  }
}

function setup(element: Element, config: BarChartConfig): Chart {
  let colors = [
    '#1f77b4',
    '#ff7f0e',
    '#2ca02c',
    '#d62728',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#bcbd22',
    '#17becf',
  ];
  let idx = 0;
  let assigned: { [key: string]: number } = {};
  if (config.datasets.length == 0) {
    return;
  }
  let datasets = config.datasets.map((ds) => {
    if (assigned[ds.color_key] === undefined) {
      assigned[ds.color_key] = idx;
      idx = (idx + 1) % colors.length;
    }
    let color = getColor(
      element as HTMLCanvasElement,
      ds.color || colors[assigned[ds.color_key]],
      ds.gradient
    );

    let res: ChartDataset<any> = {};
    if ((ds.type || config.type) === 'line') {
      res = {
        tension: 0,
        borderColor: color,
        borderWidth: 2,
        backgroundColor: 'transparent',
        ...ds,
      };
    } else if ((ds.type || config.type) === 'scatter') {
      res = {
        pointBorderColor: color,
        pointBorderWidth: 2,
        pointHoverBorderWidth: 2,
        pointBackgroundColor: 'transparent',
        pointRadius: 4,
        pointHoverRadius: 4,
        ...ds,
      };
    } else {
      res = { backgroundColor: color, ...ds };
    }
    res.maxBarThickness = 50;
    if (ds.second_axis) {
      res.yAxisID = 'y2';
    }

    return res;
  });

  let maxY = Number.MIN_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  for (let ds of datasets) {
    for (let pt of ds.data) {
      if (pt === null || pt === undefined || Array.isArray(pt)) {
        continue;
      }

      let value = typeof pt === 'number' ? pt : pt.y + ((pt as any).error || 0);
      maxY = Math.max(maxY, value);
      minY = Math.min(minY, value);
    }
  }

  let yAxes: Chart['config']['options']['scales'] = {};

  yAxes['y1'] = {
    display: true,
    position: 'left',
    suggestedMax: maxY,
    suggestedMin: minY,
    beginAtZero: true,
    title: {
      display: true,
      text: config.yTitle || config.title,
      font: { size: config.fontSize },
    },
    ticks: {
      maxTicksLimit: 5,
      font: { size: config.fontSize },
      format: {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
  };
  if (config.yTitle2) {
    yAxes['y1'].ticks.maxTicksLimit = 5;
    yAxes['y2'] = {
      position: 'right',
      display: true,
      beginAtZero: true,
      grid: {
        display: false,
      },
      title: {
        display: true,
        text: config.yTitle2,
        font: { size: config.fontSize },
      },
      ticks: {
        font: { size: config.fontSize },
        maxTicksLimit: 5,
        format: {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        },
      },
    };
  }
  const plugins = [errorBarPlugin];
  if (config.verticalLine) {
    plugins.push(verticalMarkerPlugin(config.verticalLine));
  }

  const xAxesType =
    config.scaleType || (config.type === 'bar' || config.type === 'scatter' ? 'category' : 'time');
  return new Chart(<HTMLCanvasElement>element, {
    type: config.type,
    data: {
      labels: config.labels,
      datasets,
    },
    options: {
      aspectRatio: config.aspectRatio ?? 2,
      plugins: {
        tooltip: {
          mode: 'index',
          callbacks: {
            label: (context: any) => {
              const dataset = context.chart.data.datasets[context.datasetIndex];
              const category = dataset.label;
              const value = dataset.data[context.dataIndex];
              let formattedValue: string;

              if (typeof value !== 'number') {
                const point = dataset.data[context.dataIndex];
                if (!point) {
                  return '';
                }
                const x = point.x;
                const y = point.y;

                if ((dataset.type || context.chart.config.type) === 'scatter') {
                  formattedValue = fmtNum(y) + ' / ' + fmtNum(x);
                } else {
                  formattedValue = fmtNum(y);
                }
              } else {
                formattedValue = fmtNum(value);
              }
              if (!category) {
                return formattedValue;
              }
              return category + ': ' + formattedValue;
            },
          },
        },
        title: {
          display: true,
          font: {
            size: Math.floor(config.fontSize * 1.5),
          },
          color: 'black',
          position: 'top',
          text: config.xTitle ? config.title + ' / ' + config.xTitle : config.title,
        },
        legend: {
          display: datasets.length > 1 && !config.hideLegend,
          position: 'right',
          labels: {
            color: 'rgba(0, 0, 0, 0.87)',
            font: { size: config.fontSize },
          },
        },
      },
      scales: {
        x: {
          type: xAxesType,
          title: {
            display: true,
            text: config.xTitle,
            font: { size: config.fontSize },
          },
          time: {
            unit: config.timeUnit || 'day',
            tooltipFormat: config.timeUnit === 'month' ? 'MMMM YYYY' : 'Do MMMM YYYY',
          },
          ticks: {
            font: { size: config.fontSize },
          },
        },
        ...yAxes,
      },
    },
    plugins,
  });
}

ko.bindingHandlers['barChart'] = {
  init: (element: Element, valueAccessor: () => KnockoutObservable<BarChartConfig>) => {
    renderGraph(element, valueAccessor);
  },
  update: (element: Element, valueAccessor: () => KnockoutObservable<BarChartConfig>) => {
    renderGraph(element, valueAccessor);
  },
};

function renderGraph(element: Element, valueAccessor: () => KnockoutObservable<BarChartConfig>) {
  if ((element as any)['chart']) {
    (element as any)['chart'].destroy();
  }
  const chart = setup(element, ko.unwrap(valueAccessor()));
  (element as any)['chart'] = chart;
}

function fmtNum(value: number) {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
}

ko.bindingHandlers['barChartLegendColor'] = {
  update: (
    canvas: HTMLCanvasElement,
    valueAccessor: () => KnockoutObservable<{
      color: string;
      gradient?: number;
    }>
  ) => {
    const config = ko.unwrap(valueAccessor());
    const color = getColor(canvas, config.color, config.gradient);

    canvas.width = 24;
    canvas.height = 12;

    let ctx = canvas.getContext('2d');
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 24, 12);
  },
};
