import * as ko from 'knockout';

import i18n from '../i18n';
import { NameI18nData } from '../api/names';
import { CropVarietyData } from '../api/crop_varieties';
import { getCropSearchConfig, getCropVarietySearchConfig } from './configs/search_configs';
import { FormSelectFiltered, FormSelectFilteredParams } from './form_select_filtered';

let template = require('raw-loader!../../templates/components/form_select_cv.html').default;

class FormSelectCV extends FormSelectFiltered<NameI18nData, CropVarietyData> {
  cropText = i18n.t('Crop')();
  cvText = i18n.t('Crop variety')();

  constructor(
    params: FormSelectFilteredParams<NameI18nData, CropVarietyData> & {
      cropText?: string;
      cvText?: string;
    }
  ) {
    super(params, {
      getFilter: (value) => value.crop_id,
      getFilterSearchConfig: getCropSearchConfig,
      getValueSearchConfig: getCropVarietySearchConfig,
    });

    if (params.cropText) {
      this.cropText = params.cropText;
    }
    if (params.cvText) {
      this.cvText = params.cvText;
    }
  }
}

ko.components.register('form-select-cv', {
  viewModel: FormSelectCV,
  template: template,
});
