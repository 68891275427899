import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';

let template = require('raw-loader!../../templates/components/subscription_weather_info.html').default;

class SubscriptionWeatherInfo {
  weatherAmountFormatted: string;
  weatherAdvancedAmountFormatted: string;
  result: Deferred<{}>;

  constructor(params: {
    weatherAmountFormatted: string;
    weatherAdvancedAmountFormatted: string;
    result: Deferred<{}>;
  }) {
    this.weatherAmountFormatted = params.weatherAmountFormatted;
    this.weatherAdvancedAmountFormatted = params.weatherAdvancedAmountFormatted;
    this.result = params.result;
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

export const SUBSCRIPTION_WEATHER_INFO_COMPONENT = 'subscription-weather-info';

ko.components.register(SUBSCRIPTION_WEATHER_INFO_COMPONENT, {
  viewModel: SubscriptionWeatherInfo,
  template: template,
});
