import * as ko from 'knockout';

function setupMaterialDropdown(element: Element) {
  $(element).dropdown();
}

ko.bindingHandlers['materialDropdown'] = {
  init: (element: Element, valueAccessor: () => { options: KnockoutObservable<{}> }) => {
    let config = valueAccessor();
    let optionsSub: KnockoutSubscription;

    if (ko.isObservable(config.options)) {
      optionsSub = config.options.subscribe(() => {
        setupMaterialDropdown(element);
      });
    }

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      if (optionsSub) {
        optionsSub.dispose();
      }
    });

    setupMaterialDropdown(element);
  },
};
