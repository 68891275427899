import * as ko from 'knockout';
import i18n from '../../i18n';

import { MaybeKO, asObservable } from '../../utils/ko_utils';
import { BaseTrialStep } from './base';
import { DatasetWizard, SelectedTrait, Trial, TrialWizard } from '../../models/trial';
import { MeasurementMeta, unlinkEmbeddedMT } from '../../models/measurement_meta';
import { app } from '../../app';
import { Deferred } from '../../utils/deferred';
import { FormTabbedEntitiesConfiguration } from '../form_tabbed_entities';
import { MeasurementMetaData } from '../../api/datasets';
import { OrderedEntities } from '../../models/helpers/ordered_entities';
import { BoolDict } from '../../utils';
import { TPPTraitData, TPPListData, retrieve as retrieveTPP } from '../../api/tpps';
import { isI18nTextEmpty, translate } from '../../i18n_text';
import { applyChoicesErrors, validateChoices } from '../edit_measurement_choices';
import { WizardController } from '../../screens/trial_wizard';
import { addDependencies, filteredTraits } from './select_from_library';
import { FormulaSuggestion } from '../../api/measurement_metas';
import { openTrialTraitDetails, openTrialTraitEdit } from './trial_trait_details';
import { session } from '../../session';
import { confirmDialog } from '../confirm_dialog';

let trialAssessmentsTemplate =
  require('raw-loader!../../../templates/components/trial_wizard/trial_assessments.html').default;
let dmmTemplate =
  require('raw-loader!../../../templates/components/trial_wizard/dataset_measurement_metas.html').default;

class TrialAssessments extends BaseTrialStep {
  // @ts-ignore
  private title = session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial()) ? i18n.t('Step 7 - Define traits')() : i18n.t('Step 6 - Define traits')();

  datasetBeingRemoved = ko.observable<DatasetWizard>(null);
  masterDatasetError = ko.observable(false);
  wasTppChanged = ko.observable(false);
  orderedDatasets = ko.pureComputed(() => new OrderedEntities(this.trialWizard().datasets));

  loadingLibraryMode = ko.pureComputed(() => !this.trialWizard().traits.loaded());

  isScanCodeActionEnabled = ko.observable<boolean>(false);

  trialId: string;
  traits = ko.observableArray<TPPTraitData>();
  private loadedTPPId: string = null;
  loadingTraits = ko.observable(false);
  private traitsReqIndex = 0;
  private openedDatasetIndex = ko.observable<number>(0);

  private isReloading = false;

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { controller: WizardController }) {
    super(params);
    this.ready(true);
    this.trialWizard()
      .trial()
      .tpp.subscribe(() => {
        this.wasTppChanged(true);
      });
    this.trialId = this.trialWizard().trial().id();
    this.isScanCodeActionEnabled(session.tenant()?.barcode_scan_action_enabled);
  }

  getConfigForDataset(dataset: DatasetWizard): DatasetMesurementMetasConfig {
    return {
      trialAssessments: this,
      trialWizard: this.trialWizard(),
      dataset: dataset,
      showAdvanced: this.showAdvanced,
    };
  }

  isDatasetOpen(dataset: DatasetWizard) {
    return this.openedDatasetIndex() === this.getDatasetIndex(dataset);
  }

  openDataset = (dataset: DatasetWizard) => {
    dataset.showErrors();

    let newIndex = this.getDatasetIndex(dataset);
    if (newIndex !== this.openedDatasetIndex()) {
      this.openedDatasetIndex(newIndex);
    }
  };

  moveDatasetLeft = (dataset: DatasetWizard) => {
    this.orderedDatasets().moveUp(dataset);
    this.openDataset(dataset);
  };

  moveDatasetRight = (dataset: DatasetWizard) => {
    this.orderedDatasets().moveDown(dataset);
    this.openDataset(dataset);
  };

  addDataset = () => {
    let ordered = this.orderedDatasets();
    let dw = new DatasetWizard(
      this.trialWizard(),
      undefined,
      i18n.t('Trait group')() + ' ' + (ordered.nextOrder() + 1)
    );
    if (!APP_CONFIG.RESTRICT_OBSERVATIONS_TO_LIBRARY) {
      dw.addEmptyMeasurementMeta();
    }

    ordered.add(dw);

    this.openedDatasetIndex(ordered.entities().length - 1);
  };

  getDatasetIndex(dataset: DatasetWizard): number {
    return this.trialWizard().datasets.indexOf(dataset);
  }

  canMoveDatasets = ko.pureComputed(() => {
    return this.trialWizard().datasets().length > 1;
  });

  canRemoveDataset(dataset: DatasetWizard) {
    return (
      this.trialWizard().datasets().length > 1 && (this.trialWizard().trial().isDraft() || !dataset.id())
    );
  }

  removeDataset = (dataset: DatasetWizard) => {
    this.datasetBeingRemoved(dataset);
  };

  confirmDatasetRemove = () => {
    let dataset = this.datasetBeingRemoved();
    if (!dataset) {
      return;
    }

    let index = this.getDatasetIndex(dataset);
    this.trialWizard().removeDataset(dataset);
    this.openedDatasetIndex(Math.max(0, index - 1));
    this.datasetBeingRemoved(null);
  };

  cancelDatasetRemove = () => {
    this.datasetBeingRemoved(null);
  };

  hasErrors() {
    for (let dataset of this.trialWizard().datasets()) {
      if (dataset.hasErrors()) {
        return true;
      }
    }

    return this.masterDatasetError() || this.trialWizard().traits.hasErrors();
  }

  showErrors() {
    for (let dataset of this.trialWizard().datasets()) {
      dataset.showErrors();
    }
  }

  reload() {
    if (this.isReloading) {
      return;
    }
    this.isReloading = true;

    super.reload();

    this.dispose();
    this.datasetBeingRemoved(null);

    let firstDataset = this.trialWizard().datasets()[0];
    if (
      firstDataset &&
      firstDataset.measurementMetas().length === 0 &&
      !APP_CONFIG.RESTRICT_OBSERVATIONS_TO_LIBRARY
    ) {
      firstDataset.addEmptyMeasurementMeta();
    }
    if (this.wasTppChanged()) {
      this.onTPPChanged(this.trialWizard().trial().tpp());
      this.wasTppChanged(false);
    }

    this.isScanCodeActionEnabled(session.tenant()?.barcode_scan_action_enabled);
    this.trialWizard()
      .traits.load()
      .finally(() => {
        this.subscriptions.push(this.trialWizard.subscribe(() => this.reload()));
        this.isReloading = false;
      });
  }

  dispose() {
    this.subscriptions.forEach((sub) => sub.dispose());
    this.subscriptions = [];
  }

  onTPPChanged = (tpp: TPPListData) => {
    let idx = ++this.traitsReqIndex;

    if (tpp && tpp.id === this.loadedTPPId) {
      return;
    }

    if (!tpp) {
      this.loadingTraits(false);
      this.loadedTPPId = null;
      this.traits([]);
      return;
    }

    this.loadingTraits(true);
    retrieveTPP(tpp.id).then((tpp) => {
      if (idx !== this.traitsReqIndex) {
        return;
      }

      this.loadedTPPId = tpp.id;
      this.traits(tpp.traits);
      if (
        this.trialWizard().editMode === 'library' &&
        this.trialWizard().trial().isDraft() &&
        !this.trialWizard().template()
      ) {
        this.addTppTraits(tpp.traits);
      }
      this.loadingTraits(false);
    });
  };

  private addIfNotPresent(mmBySlug: Map<string, MeasurementMetaData>, mm: MeasurementMetaData | null) {
    if (!mm) return;
    const slug = mm.name_slug;
    if (!mmBySlug.has(slug)) {
      mmBySlug.set(slug, mm);
    }
  }

  async addTppTraits(tppTraits: TPPTraitData[]) {
    let mmSlugs = this.mmSlugs();
    const filteredTppTraits = filteredTraits(tppTraits, this.trialWizard()?.trial()?.trialType()).filter(
      (trait) => !mmSlugs[trait.measurement_meta.name_slug]
    );
    let mmBySlug = new Map<string, MeasurementMetaData>();
    for (const tppTrait of filteredTppTraits) {
      const mm = tppTrait.measurement_meta;
      this.addIfNotPresent(mmBySlug, mm);
      this.addIfNotPresent(mmBySlug, mm.normalization_mm);
    }
    const mmsToAdd = await addDependencies(
      mmSlugs,
      Array.from(mmBySlug.values()),
      this.trialWizard().trial().crop().id
    );
    let nameBySlug = new Map<string, string>();
    for (const mm of mmsToAdd.valid) {
      nameBySlug.set(mm.name_slug, translate(mm.observation_name) || translate(mm.name_json));
    }
    this.trialWizard().traits.add(mmsToAdd.valid);
    this.trialWizard().tppTraitNameBySlug = nameBySlug;
    this.trialWizard().needToConfirmMissingTppTraits = true;
  }

  applyLocalValidation(): boolean {
    if (this.trialWizard().editMode === 'library') {
      return true;
    }

    let valid = true;

    if (!this.trialWizard().masterDataset()) {
      this.masterDatasetError(true);
      valid = false;
    }

    for (let dataset of this.trialWizard().datasets()) {
      for (let mm of dataset.measurementMetas()) {
        if (mm.requiresEmbeddedMT()) {
          valid = validateChoices(mm.editChoices) && valid;
        }
      }
    }

    return valid;
  }

  closeMasterDatasetError = () => {
    this.masterDatasetError(false);
  };

  clearServerErrors() {
    for (let dataset of this.trialWizard().datasets()) {
      this.clearModelServerErrors(dataset);

      for (let mm of dataset.measurementMetas()) {
        this.clearModelServerErrors(mm);
      }

      for (let ddm of dataset.requiredDatasetDimensionMetas()) {
        this.clearModelServerErrors(ddm);
      }
    }

    this.trialWizard().traits.applyServerErrors(null);
  }

  applyServerErrors(errors: any) {
    let datasetsErrors = errors['datasets'] || [];
    for (let i = 0; i < datasetsErrors.length; i++) {
      let dataset = this.trialWizard().datasets()[i];
      this.applyModelServerErrors(dataset, datasetsErrors[i]);
      this.applyMMServerErrors(dataset, datasetsErrors[i]);
      this.applyMeasurementDDMServerErrors(dataset, datasetsErrors[i]);
      this.applyRequiredDDMServerErrors(dataset, datasetsErrors[i]);
    }

    this.applyTraitActionsServerErrors(errors);
    this.trialWizard().traits.applyServerErrors(errors);
  }

  private applyMMServerErrors(dataset: DatasetWizard, errors: any) {
    let mmErrors = errors['measurement_metas'] || [];
    let mms = dataset.measurementMetas().filter((mm) => !mm.isDDMEntity);

    for (let i = 0; i < mmErrors.length; i++) {
      this.applyModelServerErrors(mms[i], mmErrors[i]);
      if (mmErrors[i]) {
        applyChoicesErrors(mms[i].editChoices, mmErrors[i]['mt_choices']);
      }
    }
  }

  private applyMeasurementDDMServerErrors(dataset: DatasetWizard, errors: any) {
    let mmDDMErrors = errors['measurement_dataset_dimension_metas'] || [];
    let entityMMs = dataset.measurementMetas().filter((mm) => mm.isDDMEntity);

    for (let i = 0; i < mmDDMErrors.length; i++) {
      let mm = entityMMs[i];
      let dmError = mmDDMErrors[i]['dimension_meta_id'];
      if (dmError) {
        this.setServerError(mm.entityDimensionMeta, dmError);
      }
    }
  }

  private applyRequiredDDMServerErrors(dataset: DatasetWizard, errors: any) {
    let ddmErrors = errors['required_dataset_dimension_metas'] || [];

    for (let i = 0; i < ddmErrors.length; i++) {
      let ddm = dataset.requiredDatasetDimensionMetas()[i];
      let dmError = ddmErrors[i]['dimension_meta_id'];
      if (dmError) {
        // TODO: this error is lost when switching tabs!
        this.setServerError(ddm.dimensionMeta, dmError);
      }
    }
  }

  private applyTraitActionsServerErrors(errors: any) {
    if (errors.trait_actions && errors.trait_actions.traits_validation_error) {
      this.trialWizard().traitActions.error(errors.trait_actions.traits_validation_error);
    }
  }

  mmSlugs = ko.pureComputed(() => {
    let mmSlugs: BoolDict = {};
    for (let dataset of this.trialWizard().datasets()) {
      for (let mm of dataset.measurementMetas()) {
        mmSlugs[mm.nameSlug()] = true;
      }
    }
    for (let libraryTrait of this.trialWizard().traits.selectedTraits()) {
      mmSlugs[libraryTrait.nameSlug] = true;
    }

    return mmSlugs;
  });

  tppCount = ko.pureComputed(() => {
    let traits = this.traits();

    if (this.loadingTraits()) {
      return i18n.t('Loading…')();
    }

    if (traits.length === 0) {
      return '';
    }

    let mmSlugs = this.mmSlugs();
    let n = 0;
    let nTotal = 0;
    for (let trait of filteredTraits(traits, this.trialWizard()?.trial()?.trialType())) {
      let normMM = trait.measurement_meta.normalization_mm;

      nTotal++;
      if (normMM) {
        nTotal++;
      }

      if (mmSlugs[trait.measurement_meta.name_slug]) {
        n++;
      }
      if (normMM && mmSlugs[normMM.name_slug]) {
        n++;
      }
    }

    return i18n.t('Traits from TPP')() + ': ' + n + '/' + nTotal;
  });

  selectFromLibrary(): Promise<{ valid: MeasurementMetaData[]; invalid: MeasurementMetaData[] }> {
    let trial = this.trialWizard().trial();
    return app.formsStackController.push({
      className: 'select-from-library-popup',
      isBig: true,
      title: i18n.t('Select observation to copy')(),
      name: 'select-from-library',
      params: {
        template: trial.template,
        editMode: this.trialWizard().editMode,
        traits: this.traits(),
        crop: trial.crop(),
        trialType: trial.trialType(),
        mmSlugs: this.mmSlugs(),
        prepareResult: addDependencies,
        selectedTraits: this.trialWizard().traits.selectedTraits(),
        result: new Deferred<{ valid: MeasurementMetaData[]; invalid: MeasurementMetaData[] }>(),
      },
    });
  }

  // library mode
  canRemoveTrait(trait: SelectedTrait) {
    return this.allowEdit() || (!trait.isSaved && this.allowEditAny());
  }

  canEditTrait() {
    return this.allowEditAny();
  }

  editTrait = (trait: SelectedTrait) => {
    // In a template, edit the library trait. In a trial, edit the trial's trait copy.
    openTrialTraitEdit(trait.templateId || trait.mmId, this.trialWizard(), () => {
      // The nested changes made to the trait are not tracked, so we have to trigger
      // a trial save manually. This is important because the trait collection level
      // impacts the trial's dataset, and the dataset is only updated when the trial
      // is. TODO update the dataset in the backend according to the trait change.
      this.trialWizard().isDirty(true);

      // We need to load again because if the user has removed a scheduled visit
      // from the trait, then the `initialScheduledVisitDays` of the wizard are
      // now outdated.
      this.trialWizard().traits.needsLoadLibrarySV = true;
    });
  };

  canAddTrait() {
    return this.allowEditAny();
  }

  canMoveTrait() {
    return this.allowEditAny();
  }

  addTrait = async () => {
    const result = await this.selectFromLibrary();

    if (result.valid?.length > 0) {
      this.trialWizard().traits.add(result.valid);
    }
  };

  openTraitDetails = (data: SelectedTrait) => {
    openTrialTraitDetails(data.mmId);
  };

  removeTrait = async (trait: SelectedTrait) => {
    const traitActions = this.trialWizard().traitActions.peek();

    if (!traitActions.some((traitAction) => traitAction.usesTrait(trait.mmId))) {
      // No trait actions use this trait, so we can just remove it.
      this.trialWizard().traits.remove(trait);
      return;
    }

    const relatedTraitIds = traitActions
      .filter((traitAction) => traitAction.usesTrait(trait.mmId))
      .map((traitAction) => {
        return traitAction.sourceTraitId() === trait.mmId
          ? traitAction.targetTraitId()
          : traitAction.sourceTraitId();
      })
      .filter((id) => !!id);

    if (relatedTraitIds.length === 0 || (await this.confirmTraitRemoveWithTraitAction(relatedTraitIds))) {
      this.trialWizard().traits.remove(trait);
      const newTraitActions = traitActions.filter((traitAction) => !traitAction.usesTrait(trait.mmId));
      this.trialWizard().traitActions(newTraitActions);
    }
  };

  private confirmTraitRemoveWithTraitAction = async (relatedTraitIds: string[]) => {
    try {
      const relatedTraits = relatedTraitIds.map((id) =>
        this.trialWizard()
          .traits.selectedTraits()
          .find((trait) => trait.mmId === id)
      );
      await confirmDialog(
        i18n.t('Remove trait?')(),
        i18n.t(
          [
            'confirm_trait_remove_warning_body',
            'This trait is linked to {{ relatedTraitNamesWithTypes }} trait(s), \
              and deleting it will compromise the functionality of the scan code \
              option in {{ relatedTraitNames }}. If you wish to proceed, the scan \
              option of {{ relatedTraitNames }} will be removed.',
          ],
          {
            relatedTraitNamesWithTypes: relatedTraits
              .map((trait) => (trait ? `${translate(trait.name)} (${trait.typeName})` : ''))
              .join(', '),
            relatedTraitNames: relatedTraits.map((trait) => (trait ? translate(trait.name) : '')).join(', '),
          }
        )(),
        '',
        false,
        i18n.t('Proceed with deletion')()
      );
      return true;
    } catch (e) {
      // User cancelled
      return false;
    }
  };
}

interface DatasetMesurementMetasConfig {
  trialAssessments: TrialAssessments;
  trialWizard: TrialWizard;
  dataset: DatasetWizard;
  showAdvanced: KnockoutObservable<boolean>;
}

class DatasetMesurementMetas {
  config: KnockoutObservable<DatasetMesurementMetasConfig>;
  measurementsConfig: FormTabbedEntitiesConfiguration<MeasurementMeta>;
  availableSuggestions = ko.pureComputed<FormulaSuggestion[]>(() => {
    const suggestions: FormulaSuggestion[] = [];

    for (let dataset of this.config().trialWizard.datasets()) {
      for (let mm of dataset.measurementMetas()) {
        if (mm.nameSlug()) {
          suggestions.push({
            name: translate(mm.nameJson()),
            code: mm.nameSlug(),
            unit: mm.unitFormula() || mm.unit()?.name,
          });
        }
      }
    }

    return suggestions;
  });

  constructor(params: { config: MaybeKO<DatasetMesurementMetasConfig> }) {
    this.config = asObservable(params.config);

    this.measurementsConfig = {
      title: '',
      addTitle: null,
      missingTitle: i18n.t('Describe the trait'),

      entities: ko.pureComputed(() => {
        return this.config().dataset.measurementMetas();
      }),

      canDisable: () => false,
      isTrialActive: () => false, // Since canDisable is false this will never be visible
      disabled: () => false,
      disable: () => {},

      canRemove: (entity) => {
        return this.allowEditAny() && (!entity.id() || this.allowEdit() || entity.isDerived());
      },

      add: () => {
        return this.config().dataset.addEmptyMeasurementMeta();
      },

      remove: (entity) => {
        this.config().trialWizard.removeMeasurementMeta(this.config().dataset, entity);
      },

      hasErrors: (entity) => {
        return entity.hasErrors();
      },

      showErrors: (entity) => {
        entity.showErrors();
      },

      actions: [],

      getSummaryName: (entity) => {
        return entity.nameJson();
      },
    };

    if (this.allowEditAny()) {
      if (!APP_CONFIG.RESTRICT_OBSERVATIONS_TO_LIBRARY) {
        this.measurementsConfig.addTitle = i18n.t('Add another trait');
      }
      this.measurementsConfig.actions = [
        {
          icon: 'content_copy',
          callback: (entity) => {
            this.showCopyMeasurementMeta(entity);
          },
        },
        {
          icon: 'keyboard_arrow_up',
          callback: (entity) => {
            this.config().dataset.orderedMeasurementMetas.moveUp(entity);
          },
        },
        {
          icon: 'keyboard_arrow_down',
          callback: (entity) => {
            this.config().dataset.orderedMeasurementMetas.moveDown(entity);
          },
        },
      ];
    }

    let template = this.getTrial().template;

    if (this.allowEditAny()) {
      this.measurementsConfig.secondaryAddTitle = template
        ? i18n.t('Add from library')
        : i18n.t('Copy from library');
      this.measurementsConfig.secondaryAddIcon = template ? 'insert_link' : 'library_books';
      this.measurementsConfig.secondaryAdd = this.showImportMeasurementMeta;
      this.measurementsConfig.description = this.config().trialAssessments.tppCount;
    }
  }

  allowEdit = ko.pureComputed(() => {
    if (!this.config().dataset.id()) {
      return true;
    }

    return this.config().trialAssessments.allowEdit();
  });
  allowEditAny = ko.pureComputed(() => this.config().trialAssessments.allowEditAny());
  allowEditCode = ko.pureComputed(() => !APP_CONFIG.RESTRICT_OBSERVATIONS_TO_LIBRARY && this.allowEdit());

  hasScheduledVisits = ko.pureComputed(() => {
    const wizard = this.config().trialAssessments.trialWizard();

    return wizard && wizard.scheduledVisits().length > 0;
  });

  copyAllMeasurements = () => {
    this.selectDataset({
      title: i18n.t('Copy all observations')(),
      selectLabel: i18n.t('Copy observations to:')(),
    }).then((dataset: DatasetWizard) => {
      // make copy, to avoid infinite loop if selected dataset is itself
      let toCopy = this.config().dataset.measurementMetas().slice();
      for (let mm of toCopy) {
        this.copyFromData(mm.toData(), dataset);
      }
      this.config().trialAssessments.openDataset(dataset);
    });
  };

  private showCopyMeasurementMeta(entity: MeasurementMeta) {
    this.selectDataset({
      title: i18n.t('Copy observation')(),
      selectLabel: i18n.t('Copy observation to:')(),
    }).then((dataset: DatasetWizard) => {
      this.copyFromData(entity.toData(), dataset);
      this.config().trialAssessments.openDataset(dataset);
    });
  }

  private selectDataset({
    title,
    selectLabel,
  }: {
    title: string;
    selectLabel: string;
  }): Promise<DatasetWizard> {
    return app.formsStackController.push({
      title,
      name: 'select-dataset',
      params: {
        title,
        selectLabel,
        datasets: this.config().trialAssessments.trialWizard().datasets(),
        result: new Deferred<DatasetWizard>(),
      },
    });
  }

  private copyFromData(data: MeasurementMetaData, dataset: DatasetWizard, linkMT = false) {
    let nameJson = isI18nTextEmpty(data.observation_name) ? data.name_json : data.observation_name;
    data = {
      ...data,
      id: undefined,
      name_json: nameJson,
      pictures: data.pictures.map((pic) => ({ ...pic, id: undefined })),
    };
    if (!linkMT) {
      unlinkEmbeddedMT(data);
    }
    return dataset.addMeasurementMeta(data);
  }

  private linkFromData(data: MeasurementMetaData, dataset: DatasetWizard) {
    let mm = this.copyFromData(data, dataset, true);
    mm.templateId(data.id);
    return mm;
  }

  private showImportMeasurementMeta = () => {
    const assessments = this.config().trialAssessments;

    if (assessments.loadingTraits()) {
      return Promise.resolve([]);
    }

    let addFn = (this.getTrial().template ? this.linkFromData : this.copyFromData).bind(this);
    return assessments
      .selectFromLibrary()
      .then((result) => result.valid?.map((mmData) => addFn(mmData, this.config().dataset)));
  };

  private getTrial(): Trial {
    return this.config().trialAssessments.trialWizard().trial();
  }
}

ko.components.register('trial-assessments', {
  viewModel: TrialAssessments,
  template: trialAssessmentsTemplate,
});
ko.components.register('dataset-measurement-metas', {
  viewModel: DatasetMesurementMetas,
  template: dmmTemplate,
});
