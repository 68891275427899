import * as ko from 'knockout';

import * as trialsApi from '../../api/trials';
import { BaseForm } from '../base_form';
import { StaffPermission } from './staff_permission';

let template = require('raw-loader!./assign_staff.html').default;

class AssignStaffScreen extends BaseForm<trialsApi.StaffPermissionData & { id?: string }> {
  private disposed = false;

  private trialId: string;
  permissions: StaffPermission;

  constructor(params: { trialId: string }) {
    super({});

    this.trialId = params.trialId;
    this.fetch();
  }

  dispose() {
    if (this.permissions) {
      this.permissions.dispose();
    }
    this.disposed = true;
  }

  private async fetch() {
    let data = await trialsApi.retrieveAssignStaff(this.trialId);
    if (!this.disposed) {
      this.permissions = new StaffPermission(data);
      this.loaded();
    }
  }

  save = async () => {
    if (!this.permissions) {
      return;
    }

    let data = this.permissions.toData();
    if (this.validateLocal(this.permissions)) {
      this.saving(true);
      let validation = await this.executeSaveRequest(trialsApi.saveAssignedStaff(this.trialId, data));
      this.onRemoteValidation(data, this, validation);
    }
  };
}

export let assignStaff = {
  name: 'assign-staff',
  viewModel: AssignStaffScreen,
  template: template,
};

ko.components.register(assignStaff.name, assignStaff);
