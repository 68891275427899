import * as ko from 'knockout';

import { DimensionMeta } from './dimension_meta';
import { Dimension } from './dimension';
import { TrialTypeData } from '../api/trial_types';

export class TrialType extends Dimension {
  allowDisableSites = ko.observable(true);

  editUrl = ko.pureComputed(() => {
    return '/trial_types/' + this.id() + '/';
  });

  constructor(data?: TrialTypeData, dimensionMeta?: DimensionMeta) {
    super(data, dimensionMeta);

    if (data) {
      this.allowDisableSites(data.allow_disable_sites);
    }
  }

  toData(): TrialTypeData {
    return {
      allow_disable_sites: this.allowDisableSites(),
      ...super.toData(),
    };
  }
}
