import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate, ListLoader } from '../components/list_loader';
import { NameI18n } from '../models/name';
import * as namesApi from '../api/names';
import { session } from '../session';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/names.html').default;

interface DefaultFilters {
  name_search?: string;
}

export class NamesScreen implements ListLoaderDelegate<namesApi.NameI18nData, NameI18n> {
  private baseName: string;
  private hrefBaseName: string;
  title: string;
  canCreate = session.isAtLeastEditor();
  listActions = ko.observableArray<ListHeaderAction>([]);
  nameFilter = ko.observable('').extend({ throttle: 300 });
  searchPlaceholder = ko.pureComputed(() => i18n.t(`Search ${this.title.toLowerCase()}`)());

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { baseName: string; title: string; hrefBaseName?: string; filters: DefaultFilters }) {
    this.baseName = params.baseName;
    this.hrefBaseName = params.hrefBaseName || params.baseName;
    this.title = params.title;

    this.nameFilter(params.filters.name_search || '');

    if (this.canCreate) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/' + this.hrefBaseName + '/new/',
      });
    }
  }

  fetch(params: ListRequestParams) {
    return namesApi.list(this.baseName, {
      ...params,
      name_search: this.nameFilter(),
    });
  }

  instantiate(nameData: namesApi.NameI18nData) {
    return new NameI18n(this.hrefBaseName, nameData);
  }

  getEditUrl(entity: NameI18n) {
    return entity.editUrl;
  }

  remove(id: string) {
    return namesApi.remove(this.baseName, id);
  }

  canRemove(name: NameI18n) {
    return name.canEdit;
  }

  onReady(loader: ListLoader<namesApi.NameI18nData, NameI18n>) {
    this.subscriptions.push(this.nameFilter.subscribe(() => loader.forceLoad()));
  }

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }
}

export let names = {
  name: 'names',
  viewModel: NamesScreen,
  template: template,
};

ko.components.register(names.name, names);
