import { request } from '../request';
import * as queryString from 'query-string'


export interface TraitItem {
    name: string;
    pk: number;
    unit: string | null;
    name_with_unit?: string;
    scheduled_visits: [
        {
            id: number;
            name: string;
        }
    ];
  }

export function getTrialTraitsForStats(trialId: string, search_term: string = ''): Promise<TraitItem[]> {
    return request(
            'GET',
            `/api/v2/measurement_metas/?${queryString.stringify({trial_id: trialId, for_statistics: true, search_term: search_term})}`,
            {},
            { injectTenant: true, version: 2 }
        );
}