import * as ko from 'knockout';

export class BaseLoadingScreen {
  loading = ko.observable(true);

  loadedAfter(promise: Promise<any>): Promise<any> {
    return promise.then(() => {
      this.loading(false);
    });
  }

  loaded() {
    this.loading(false);
  }
}
