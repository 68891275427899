import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { app } from '../app';
import i18n from '../i18n';
import * as trialsApi from '../api/trials';
import { KEYCODE_ESCAPE } from '../utils';
import { getTrialStateLabel } from '../models/TrialState';

let template = require('raw-loader!../../templates/components/measurement_library_trials.html').default;

class MeasurementLibraryTrials {
  loading = ko.observable(true);
  trials = ko.observableArray<{ id: string; name: string; state: string }>();
  result: Deferred<{}>;

  constructor(params: { mmId: string; result: Deferred<{}> }) {
    this.result = params.result;
    this.fetch(params.mmId);

    document.addEventListener('keyup', this.onKeyUp);
  }

  dispose() {
    document.removeEventListener('keyup', this.onKeyUp);
  }

  private async fetch(mmId: string) {
    try {
      this.trials(
        (await trialsApi.trialsUsingLibrary(mmId)).map(({ id, name, state }) => ({
          id,
          name,
          state: getTrialStateLabel(state),
        }))
      );
    } finally {
      this.loading(false);
    }
  }

  onKeyUp = (event: KeyboardEvent) => {
    if (event.which === KEYCODE_ESCAPE || event.keyCode === KEYCODE_ESCAPE) {
      this.onClose();
    }
  };

  onClose = () => {
    this.result.resolve(null);
  };
}

const name = 'measurement-library-trials';

ko.components.register(name, {
  viewModel: MeasurementLibraryTrials,
  template: template,
});

export function openMeasurementLibraryTrials(mmId: string) {
  app.formsStackController.push({
    title: i18n.t('Trials using this observation')(),
    name,
    isBig: true,
    params: { mmId, result: new Deferred<{}>() },
  });
}
