import * as ko from 'knockout';

import { BaseLoadingScreen } from './base_loading_screen';
import * as trialsApi from '../api/trials';
import { findById } from '../utils';

let template = require('raw-loader!../../templates/trial_field_book.html').default;

class TrialFieldBookScreen extends BaseLoadingScreen {
  data = ko.observable<trialsApi.FieldBookData>();
  siteId = ko.observable<string>();

  selectedSiteName = ko.pureComputed(() => {
    let site = findById(this.data().trial_sites, this.siteId());
    return site ? site.name : '';
  });

  constructor(params: { id: string }) {
    super();

    let promise = trialsApi.retrieveFieldBook(params.id).then((data) => {
      this.data(data);
      this.siteId(data.trial_sites.length > 0 ? data.trial_sites[0].id : null);
    });
    this.loadedAfter(promise);
  }

  goBack = () => {
    history.back();
  };

  print = () => {
    window.print();
  };
}

export let trialFieldBook = {
  name: 'trial-field-book',
  viewModel: TrialFieldBookScreen,
  template: template,
};

ko.components.register(trialFieldBook.name, trialFieldBook);
