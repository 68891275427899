import { makeDefaultApi, requestRaw } from './request';
import { CountryData } from './countries';
import { UserData } from './users';
import { I18nText } from '../i18n_text';
import { UserLogMixinData } from './changelog';

export interface OrganizationTypeData extends UserLogMixinData {
  id: string;
  name_json: I18nText;
  public: boolean;
  use_for_breeders: boolean;
  use_for_producers: boolean;
  use_for_customers: boolean;
}

interface OrganizationTypeFilters {
  use_for_breeders?: boolean;
  use_for_producers?: boolean;
  use_for_customers?: boolean;
}

export const organizationTypesApi = makeDefaultApi<OrganizationTypeData, OrganizationTypeFilters>(
  'organization_types'
);

export interface OrganizationData extends UserLogMixinData {
  id: string;
  name: string;
  country: CountryData;
  contact: string;
  address: string;
  phone: string;
  email: string;
  responsible: UserData;
  organization_type: OrganizationTypeData;
  users_emails: string[];
}

export interface BreederData extends OrganizationData {}

export interface BuyerData extends OrganizationData {}

export interface ProducerData extends OrganizationData {}

export interface CustomerData extends OrganizationData {}

export interface PartnerData extends OrganizationData {}

export const breedersApi = makeDefaultApi<BreederData, { country_id?: string; ids?: string[] }>('breeders');
export const buyersApi = makeDefaultApi<BuyerData, { country_id?: string }>('buyers');
export const producersApi = makeDefaultApi<
  ProducerData,
  { country_id?: string; restrict_country?: boolean; ids?: string[] }
>('producers');
export const customersApi = makeDefaultApi<CustomerData, { ids?: string[] }>('customers');
export const partnersApi = makeDefaultApi<PartnerData, { ids?: string[]; archived?: boolean }>('partners');

export function downloadOrganizationsImportTemplate(
  type: 'breeders' | 'customers' | 'partners'
): Promise<Blob> {
  return requestRaw('GET', `/api/${type}/import_template/`);
}
