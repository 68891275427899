import * as ko from 'knockout';

import i18n from '../i18n';
import { BaseForm } from '../screens/base_form';
import { Organization } from '../models/organization';
import {
  OrganizationData,
  breedersApi,
  buyersApi,
  producersApi,
  customersApi,
  partnersApi,
} from '../api/organizations';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { CountryData } from '../api/countries';

let template = require('raw-loader!../../templates/organization_edit.html').default;

interface EditParams {
  baseName: string;
  api: typeof breedersApi;
  title: string;
  createTitle: string;
  editTitle: string;
  id: string;
  initialName: string;
  initialCountry?: KnockoutObservable<CountryData>;
  result?: Deferred<OrganizationData>;
}

class OrganizationEditScreen extends BaseForm<OrganizationData> {
  private api: typeof breedersApi;
  public title: string;
  public createTitle: string;
  public editTitle: string;
  entity = ko.observable<Organization>(null);

  constructor(params: EditParams, componentInfo: KnockoutComponentTypes.ComponentInfo) {
    super(params);

    this.api = params.api;
    this.title = params.title;
    this.createTitle = params.createTitle;
    this.editTitle = params.editTitle;

    let request = params.id ? this.api.retrieve(params.id) : undefined;
    let promise = Promise.all([request]).then(([data]) => {
      this.entity(new Organization(params.baseName, data));

      if (!data && params.initialName) {
        this.entity().name(params.initialName);
      }
      if (!data && params.initialCountry) {
        this.entity().country(params.initialCountry());
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (!this.entity()) {
      return;
    }

    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(this.api.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let organizationEdit = {
  name: 'organization-edit',
  viewModel: createWithComponent(OrganizationEditScreen),
  template: template,
};

ko.components.register(organizationEdit.name, organizationEdit);

interface Params {
  id: string;
  initialName: string;
  initialCountry?: KnockoutObservable<CountryData>;
  result?: Deferred<OrganizationData>;
}

export let breederEdit = {
  name: 'breeder-edit',
  viewModel: {
    createViewModel: (params: Params, componentInfo: KnockoutComponentTypes.ComponentInfo) =>
      new OrganizationEditScreen(
        {
          baseName: 'breeders',
          api: breedersApi,
          title: i18n.t('Breeders')(),
          createTitle: i18n.t('Create Breeder')(),
          editTitle: i18n.t('Edit Breeder')(),
          ...params,
        },
        componentInfo
      ),
  },
  template: template,
};
ko.components.register(breederEdit.name, breederEdit);

export let buyerEdit = {
  name: 'buyer-edit',
  viewModel: {
    createViewModel: (params: Params, componentInfo: KnockoutComponentTypes.ComponentInfo) =>
      new OrganizationEditScreen(
        {
          baseName: 'buyers',
          api: buyersApi,
          title: i18n.t('Buyers')(),
          createTitle: i18n.t('Create Buyer')(),
          editTitle: i18n.t('Edit Buyer')(),
          ...params,
        },
        componentInfo
      ),
  },
  template: template,
};
ko.components.register(buyerEdit.name, buyerEdit);

export let producerEdit = {
  name: 'producer-edit',
  viewModel: {
    createViewModel: (params: Params, componentInfo: KnockoutComponentTypes.ComponentInfo) =>
      new OrganizationEditScreen(
        {
          baseName: 'producers',
          api: producersApi,
          title: i18n.t('Producers')(),
          createTitle: i18n.t('Create Producer')(),
          editTitle: i18n.t('Edit Producer')(),
          ...params,
        },
        componentInfo
      ),
  },
  template: template,
};
ko.components.register(producerEdit.name, producerEdit);

export let customerEdit = {
  name: 'customer-edit',
  viewModel: {
    createViewModel: (params: Params, componentInfo: KnockoutComponentTypes.ComponentInfo) =>
      new OrganizationEditScreen(
        {
          baseName: 'customers',
          api: customersApi,
          title: i18n.t('Customers')(),
          createTitle: i18n.t('Create Customer')(),
          editTitle: i18n.t('Edit Customer')(),
          ...params,
        },
        componentInfo
      ),
  },
  template: template,
};
ko.components.register(customerEdit.name, customerEdit);

export let partnerEdit = {
  name: 'partner-edit',
  viewModel: {
    createViewModel: (params: Params, componentInfo: KnockoutComponentTypes.ComponentInfo) =>
      new OrganizationEditScreen(
        {
          baseName: 'partners',
          api: partnersApi,
          title: i18n.t('Partners')(),
          createTitle: i18n.t('Create Partner')(),
          editTitle: i18n.t('Edit Partner')(),
          ...params,
        },
        componentInfo
      ),
  },
  template: template,
};
ko.components.register(partnerEdit.name, partnerEdit);
