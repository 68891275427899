import { ListRequestParams, listRequest, requestRaw, makeDefaultApi, request } from './request';
import { ValueMetaData } from './value_meta_data';
import { I18nText } from '../i18n_text';
import { AttributeChoiceListData } from './attribute_choice_lists';

export interface DimensionMetaData {
  id?: string;
  name_json: I18nText;
  slug?: string;
  name_slug: string;
  name_template?: string; // retrieve()-only
  is_date: boolean;
  attribute_metas: AttributeMetaData[];
}

export const CROP_SLUG = 'crop';
export const CROP_VARIETY_SLUG = 'crop_variety';
export const FERTILIZER_SLUG = 'fertilizer';
export const CHEMICAL_SLUG = 'chemical';
export const PROTOCOL_SLUG = 'protocol';
export const PARTNER_SLUG = 'partner';
export const CULTIVATION_TYPE_SLUG = 'cultivation_type';
export const FIELD_PREPARATION_TYPE_SLUG = 'field_preparation_type';
export const IRRIGATION_TYPE_SLUG = 'irrigation_type';
export const SITE_SLUG = 'site';
export const REPETITION_SLUG = 'repetition';
export const TRIAL_TYPE_SLUG = 'stage';
export const CV_STAGE_SLUG = 'cv_stage';

export interface AttributeMetaData extends ValueMetaData {
  choice_list: AttributeChoiceListData;
}

export const { list, retrieve, save, remove } = makeDefaultApi<DimensionMetaData>('dimension_metas');

export function listCustom(params: ListRequestParams): Promise<DimensionMetaData[]> {
  return listRequest('/api/dimension_metas/custom/', params);
}

export function listExcludingDates(
  params: ListRequestParams & { exclude_slugs?: string[] }
): Promise<DimensionMetaData[]> {
  return listRequest('/api/dimension_metas/?exclude_dates=true', params);
}

export function exportAll(): Promise<Blob> {
  return requestRaw('GET', '/api/dimension_metas/export/');
}

export function archive(id: string): Promise<{}> {
  return request('POST', '/api/dimension_metas/' + id + '/archive/');
}
