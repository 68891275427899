import { DimensionData, DimensionListFilters } from '../dimensions';
import { ListRequestParams, requestWithGetParams } from '../request';

export const cropVarietiesList = (
  filters: DimensionListFilters,
  params: ListRequestParams
): Promise<{ results: DimensionData[] }> => {
  return requestWithGetParams(
    `/api/v2/crop_varieties/`,
    { ...filters, ...params },
    { injectTenant: true, version: 2 }
  );
};
