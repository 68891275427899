import * as ko from 'knockout';

import { OrganizationTypeData, organizationTypesApi } from '../api/organizations';
import { OrganizationType } from '../models/organization_type';
import { ListLoaderDelegate } from '../components/list_loader';
import { ListRequestParams } from '../api/request';
import { session } from '../session';
import { addChangelogListAction, addChangelogAction } from './changelog';
import { Action } from '../components/basic_widgets';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/organization_types.html').default;

class OrganizationTypesScreen implements ListLoaderDelegate<OrganizationTypeData, OrganizationType> {
  canCreate = session.isAtLeastEditor();

  listActions = ko.observableArray<ListHeaderAction>([]);
  constructor() {
    if (this.canCreate) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/organization_types/new/',
        tooltipTitle: i18n.t('Add Organization Types')(),
      });
    }

    this.listActions.push(...addChangelogListAction('organization_type'));
  }
  fetch(params: ListRequestParams) {
    return organizationTypesApi.list(params);
  }

  instantiate(data: OrganizationTypeData) {
    return new OrganizationType(data);
  }

  getEditUrl(entity: OrganizationType) {
    return entity.editUrl();
  }

  remove(id: string) {
    return organizationTypesApi.remove(id);
  }

  canRemove(entity: OrganizationType) {
    return entity.canEdit();
  }

  getActions(entity: OrganizationType): Action[] {
    return addChangelogAction('organization_type', entity.id());
  }
}

export let organizationTypes = {
  name: 'organization-types',
  viewModel: OrganizationTypesScreen,
  template: template,
};

ko.components.register(organizationTypes.name, organizationTypes);
