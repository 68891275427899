import * as ko from 'knockout';
import { UserLogMixinData } from '../api/changelog';
import { canViewChangelog } from '../permissions';

let templateHeader = require('raw-loader!../../templates/components/user_log_header.html').default;
let templateRow = require('raw-loader!../../templates/components/user_log_row.html').default;

class ViewModel {
  visible = canViewChangelog(null);

  constructor(public params: UserLogMixinData) {}
}

ko.components.register('user-log-header', {
  template: templateHeader,
  viewModel: ViewModel,
});
ko.components.register('user-log-row', {
  template: templateRow,
  viewModel: ViewModel,
});
