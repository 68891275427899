import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { Region } from '../models/region';
import * as regionsApi from '../api/regions';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { canCreateCountry } from '../permissions';

let template = require('raw-loader!../../templates/regions.html').default;

class RegionsScreen implements ListLoaderDelegate<regionsApi.RegionData, Region> {
  canEdit = canCreateCountry();
  listActions = ko.observableArray<ListHeaderAction>([]);

  constructor() {
    if (this.canEdit) {
      this.listActions.push(
        {
          title: i18n.t('Add')(),
          icon: 'add_circle',
          href: '/regions/new/',
          tooltipTitle: i18n.t('Add Administrative Region')(),
        },
        {
          title: i18n.t('Import')(),
          icon: 'file_upload',
          href: '/regions/import/',
        }
      );
    }
  }

  fetch(params: ListRequestParams) {
    return regionsApi.list(params);
  }

  instantiate(regionData: regionsApi.RegionData) {
    return new Region(regionData);
  }

  remove(id: string) {
    return regionsApi.remove(id);
  }

  canRemove(region: Region) {
    return this.canEdit;
  }
}

export let regions = {
  name: 'regions',
  viewModel: RegionsScreen,
  template: template,
};

ko.components.register(regions.name, regions);
