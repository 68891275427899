import * as ko from 'knockout';
import { ProjectData, projectApi } from '../api/projects';
import { Project } from '../models/project';
import { createWithComponent } from '../utils/ko_utils';
import { BaseEditScreen, BaseEditParams } from './base_edit';

let template = require('raw-loader!../../templates/project_edit.html').default;

class ProjectEditScreen extends BaseEditScreen<ProjectData, Project> {
  constructor(params: BaseEditParams<ProjectData>, componentInfo: KnockoutComponentTypes.ComponentInfo) {
    super({ api: projectApi, focus: true }, params, componentInfo);
  }

  instantiate(data: ProjectData) {
    return new Project(data);
  }
}

export let projectEdit = {
  name: 'project-edit',
  viewModel: createWithComponent(ProjectEditScreen),
  template: template,
};

ko.components.register(projectEdit.name, projectEdit);
