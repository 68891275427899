import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { OrganizationTypeData, organizationTypesApi } from '../api/organizations';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { OrganizationType } from '../models/organization_type';
import { asI18nText } from '../i18n_text';

let template = require('raw-loader!../../templates/organization_type_edit.html').default;

class OrganizationTypeEditScreen extends BaseForm<OrganizationTypeData> {
  entity = ko.observable<OrganizationType>(null);

  constructor(
    params: {
      id: string;
      initialName: string;
      result?: Deferred<OrganizationTypeData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let request = params.id ? organizationTypesApi.retrieve(params.id) : undefined;
    let promise = Promise.all([request]).then(([data]) => {
      this.entity(new OrganizationType(data));
      if (!data && params.initialName) {
        this.entity().nameJson(asI18nText(params.initialName));
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (!this.entity()) {
      return;
    }

    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(organizationTypesApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let organizationTypeEdit = {
  name: 'organization-type-edit',
  viewModel: createWithComponent(OrganizationTypeEditScreen),
  template: template,
};

ko.components.register(organizationTypeEdit.name, organizationTypeEdit);
