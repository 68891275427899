// This import is a hack to make it work with ES5 modules
// Our project supports only ES5 modules, but the library
// support ES6 modules. Therefore, making them incomatible with eachother.
// The only solution I could find to make it work without
// the need of changing the imports throughout the app
// was this one.
// import { session } from './session';
import * as Sentry from '@sentry/browser';

export function setupErrorLogging() {
  if (process.env.NODE_ENV == 'production' && process.env.SENTRY_DSN_FRONTEND) {
    Sentry.init({
      dsn: process.env.SENTRY_DSN_FRONTEND,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      environment: process.env.APP_ENV,
    });
  }
}

export function loggingErrors<T>(fn: () => T) {
  try {
    return fn();
  } catch (e) {
    logError('', e);
    throw e;
  }
}

export function logError(msg: string, error: Error) {
  if (error && error.message === 'request') {
    return;
  }

  if (!error) {
    error = new Error(msg);
  }

  Sentry.captureException(error);
  // TODO: Enable this when we can set the ID instead of email
  // Sentry.setUser({ email: session.getEmail()});
}
