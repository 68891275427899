import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import * as clientTypesApi from '../api/client_types';

let template = require('raw-loader!../../templates/client_types.html').default;

class ClientTypesScreen implements ListLoaderDelegate<clientTypesApi.ClientTypeData> {
  fetch(params: ListRequestParams) {
    return clientTypesApi.list(params);
  }

  instantiate(data: clientTypesApi.ClientTypeData) {
    return data;
  }

  getEditUrl(data: clientTypesApi.ClientTypeData) {
    return `/client_types/${data.id}/`;
  }

  remove(id: string) {
    return clientTypesApi.remove(id);
  }

  canRemove(entity: clientTypesApi.ClientTypeData) {
    return true;
  }
}

export let clientTypes = {
  name: 'client-types',
  viewModel: ClientTypesScreen,
  template: template,
};

ko.components.register(clientTypes.name, clientTypes);
