import * as ko from 'knockout';
import i18n from '../i18n';

const template = require('raw-loader!../../templates/components/bulk_actions_panel.html').default;

class BulkActionsPanelViewSet {
  selectedItemsCount = ko.observable<number>(0);

  /**
   * Whether all items are selected, including those that are not visible due to pagination.
   */
  allSelected = ko.observable<boolean>(false);

  selectedItemsText = ko.pureComputed(() => {
    if (this.allSelected()) {
      return i18n.t('All {{ selectedItemsCount }} items in this search/filtering are selected.', {
        selectedItemsCount: this.selectedItemsCount(),
      })();
    } else {
      return i18n.t(
        ['bulk_actions_panel_selected_items', '{{ selectedItemsCount }} selected on this page.'],
        {
          selectedItemsCount: this.selectedItemsCount(),
        }
      )();
    }
  });

  constructor(params: { selectedItemsCount: number; allSelected: boolean }) {
    this.selectedItemsCount(params.selectedItemsCount);
    this.allSelected(params.allSelected);
  }
}

ko.components.register('bulk-actions-panel', {
  viewModel: BulkActionsPanelViewSet,
  template,
});
