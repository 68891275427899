export function scrollToFirstEmptyChild(element: HTMLElement | Element) {
  for (var i = 0; i < element.children.length; i++) {
    let child = element.children[i] as HTMLElement;
    if (child.children[0].classList.contains('overview-empty-value')) {
      child.click();
      child.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      break;
    }
  }
}
