import * as ko from 'knockout';
import { FormulaSuggestion } from '../api/measurement_metas';

import { UserData } from '../api/users';
import { MeasurementMeta } from '../models/measurement_meta';
import { MaybeKO, asObservable } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/measurement_meta_edit.html').default;

class MeasurementMetaEdit {
  user: UserData;
  measurementMeta: KnockoutObservable<MeasurementMeta>;
  allowEditType: KnockoutComputed<boolean>;
  allowEdit: KnockoutSubscribable<boolean>;
  allowEditAny: KnockoutComputed<boolean>;
  allowEditIncludingTrialState: KnockoutComputed<boolean>;
  showAdvanced: KnockoutObservable<boolean>;
  validationWarningText: KnockoutObservable<string>;
  editAsTrait: boolean;

  embeddedChoices = ko.pureComputed(() => this.editAsTrait || this.measurementMeta().requiresEmbeddedMT());

  canEditCode = ko.pureComputed(() => this.canEditLibraryLinks());
  canEditUnit = ko.pureComputed(() => this.canEditLibraryLinks());
  canEditType = ko.pureComputed(
    () =>
      this.allowEditIncludingTrialState() ??
      (this.allowEditAny() &&
        this.measurementMeta().canEdit(this.allowEditType()) &&
        !this.measurementMeta().isDDMEntity)
  );

  availableSuggestions: KnockoutSubscribable<FormulaSuggestion[]>;

  validateFormula?: (formula: string) => boolean;

  constructor(params: {
    measurementMeta: MaybeKO<MeasurementMeta>;
    user: UserData;
    allowEdit: KnockoutObservable<boolean>;
    allowEditAny: KnockoutObservable<boolean>;
    allowEditIncludingTrialState?: KnockoutObservable<boolean>;
    showAdvanced: KnockoutObservable<boolean>;
    editAsTrait?: boolean;
    availableSuggestions?: KnockoutSubscribable<FormulaSuggestion[]>;
    validateFormula?: (formula: string) => boolean;
    validationWarningText: KnockoutObservable<string>;
  }) {
    this.measurementMeta = asObservable(params.measurementMeta);
    this.user = params.user;
    this.allowEditAny = ko.pureComputed(() => !this.measurementMeta().templateId() && params.allowEditAny());
    // passed if user comes from trial Wizard.
    // we need to check trial state in this case.
    this.allowEditIncludingTrialState = ko.pureComputed(() => {
      let editIncludingTrialState = null;
      if (params.allowEditIncludingTrialState) {
        editIncludingTrialState = params.allowEditIncludingTrialState();
      }
      return editIncludingTrialState ?? null;
    });
    this.allowEdit = ko.pureComputed(() => {
      if (this.measurementMeta().isDerived()) {
        return this.allowEditAny();
      } else {
        return !this.measurementMeta().templateId() && (!this.measurementMeta().id() || params.allowEdit());
      }
    });
    this.allowEditType = ko.pureComputed(
      () => !this.measurementMeta().templateId() && (!this.measurementMeta().id() || params.allowEdit())
    );
    this.showAdvanced = params.showAdvanced;
    this.editAsTrait = params.editAsTrait;
    this.availableSuggestions = params.availableSuggestions ?? ko.observableArray();
    this.validateFormula = params.validateFormula;
    this.validationWarningText = params.validationWarningText;
  }

  private canEditLibraryLinks() {
    if (APP_CONFIG.RESTRICT_OBSERVATIONS_TO_LIBRARY && !this.editAsTrait) {
      return false;
    }

    return (
      this.allowEditIncludingTrialState() ??
      (this.allowEditAny() && this.measurementMeta().canEdit(this.allowEdit()))
    );
  }
}

ko.components.register('measurement-meta-edit', {
  viewModel: MeasurementMetaEdit,
  template: template,
});
