import * as ko from 'knockout';
import { MaybeKO } from '../utils/ko_utils';
import { StageChangeChartData, StageChangeVarietyData } from '../api/overview';
import { Quarter } from '../utils';

const colorPalette = [
  'rgb(31, 119, 180)',
  'rgb(255, 127, 14)',
  'rgb(44, 160, 44)',
  'rgb(214, 39, 40)',
  'rgb(148, 103, 189)',
  'rgb(140, 86, 75)',
  'rgb(227, 119, 194)',
  'rgb(127, 127, 127)',
  'rgb(188, 189, 34)',
  'rgb(23, 190, 207)',
];
const color = (i: number) => colorPalette[i % colorPalette.length];

let mouseEvents = new Map<Element, (x: number, y: number) => void>();

// allows to overdraw by up to this amount on top and bottom
const verticalMargin = 200;
// must match value in stage_change_chart.less
const canvasWidth = 800;

let draw = (
  parent: HTMLElement,
  canvas: HTMLCanvasElement,
  data: StageChangeChartData,
  highlightVarietyIndex: number
) => {
  let ctx = canvas.getContext('2d');

  const lineHeight = 6;
  const lineSpacing = 6;
  const stageVSpacing = 6;
  const axisSpacing = 6;
  const xLabelVSpace = 36;
  const yLabelHSpace = 80;
  const labelAxisSpacing = 12;
  const minLabelSpacing = 6;

  const minLinesPerStage = 10;
  let linesPerStage = data.stages.map((stage) => stage.levels.reduce((acc, v) => (v ? acc + 1 : acc), 0));

  let stageHeight = (i: number) =>
    lineSpacing + (lineHeight + lineSpacing) * Math.max(minLinesPerStage, linesPerStage[i]);
  let cumulativeStageHeight = 0;
  // cumulative height, from top (i === 0) to bottom (i === data.stages.length - 1)
  // each item includes the spacing from the item above in its height
  let revCumulativeHeights = data.stages.map((stage, i) => {
    cumulativeStageHeight += stageHeight(data.stages.length - i - 1) + stageVSpacing;
    return cumulativeStageHeight;
  });
  // i is from bottom (i === 0) to top (i === data.stages.length - 1)
  let stageOffsetY = (i: number) => {
    let reversedIdx = data.stages.length - i - 1;
    return (revCumulativeHeights[reversedIdx - 1] ?? 0) + stageVSpacing;
  };

  const height = cumulativeStageHeight + axisSpacing + xLabelVSpace;
  const contentWidth = canvasWidth - yLabelHSpace - axisSpacing * 2;

  const devicePixelRatio = window.devicePixelRatio ?? 1;

  canvas.width = canvasWidth * devicePixelRatio; // this also resets the canvas
  canvas.height = (height + verticalMargin * 2) * devicePixelRatio;
  canvas.style.height = height + verticalMargin * 2 + 'px';
  parent.style.height = height + 'px';

  ctx.scale(devicePixelRatio, devicePixelRatio);
  ctx.translate(0, verticalMargin);

  const boxQuadOffset = 9; // horizontal space for rounded borders
  const boxLineHeight = 18;

  let calcBoxSize = (lines: string[]) => {
    let width = 0;
    for (let line of lines) {
      width = Math.max(width, ctx.measureText(line).width);
    }

    return {
      width: width + boxQuadOffset * 2,
      height: boxLineHeight * lines.length,
    };
  };

  let drawBox = (
    lines: string[],
    x: number,
    y: number,
    width: number,
    color: string,
    strokeAlpha: number = 1
  ) => {
    ctx.save();

    let height = boxLineHeight * lines.length;

    ctx.beginPath();
    ctx.moveTo(x + boxQuadOffset, y);
    ctx.lineTo(x + width - boxQuadOffset, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + boxQuadOffset);
    ctx.lineTo(x + width, y + height - boxQuadOffset);
    ctx.quadraticCurveTo(x + width, y + height, x + width - boxQuadOffset, y + height);
    ctx.lineTo(x + boxQuadOffset, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - boxQuadOffset);
    ctx.lineTo(x, y + boxQuadOffset);
    ctx.quadraticCurveTo(x, y, x + boxQuadOffset, y);

    ctx.globalAlpha = strokeAlpha;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.fillStyle = '#FFF';
    ctx.globalAlpha = 1;
    ctx.fill();

    ctx.fillStyle = color;
    ctx.textBaseline = 'middle';
    lines.forEach((line, k) => {
      ctx.fillText(line, x + boxQuadOffset, y + boxLineHeight * k + boxLineHeight / 2);
    });

    ctx.restore();
  };

  // axis
  ctx.strokeStyle = '#979797';
  ctx.lineWidth = 1;
  ctx.moveTo(yLabelHSpace + 0.5, 0 + 0.5);
  ctx.lineTo(yLabelHSpace + 0.5, height - xLabelVSpace + 0.5);
  ctx.stroke();
  ctx.lineTo(canvasWidth + 0.5, height - xLabelVSpace + 0.5);
  ctx.stroke();

  // labels
  ctx.font = '14px Roboto, sans-serif';
  ctx.fillStyle = '#414141';
  // y
  ctx.textBaseline = 'middle';
  data.stages.forEach((stage, idx) => {
    let size = ctx.measureText(stage.name);
    let labelWidth = Math.min(size.width, yLabelHSpace - labelAxisSpacing);
    ctx.fillText(
      stage.name,
      yLabelHSpace - labelAxisSpacing - labelWidth,
      stageOffsetY(idx) + stageHeight(idx) / 2,
      labelWidth
    );
  });
  // x
  let minQuarter = new Quarter(8640000000000000);
  let maxQuarter = new Quarter(-8640000000000000);
  for (let stage of data.stages) {
    for (let variety of stage.levels) {
      if (!variety) {
        continue;
      }

      let quarterStart = new Quarter(variety.start_at);
      maxQuarter = maxQuarter.max(quarterStart);
      minQuarter = minQuarter.min(quarterStart);
      if (variety.end_at) {
        let quarterEnd = new Quarter(variety.end_at);
        maxQuarter = maxQuarter.max(quarterEnd);
        minQuarter = minQuarter.min(quarterEnd);
      }
      else {
        // in case end_at is null, add to Quarters also max planting date of trials so it shows up on the chart
        if (variety.trials.length === 0) {
          continue;
        }
        const trialsDates = variety.trials.filter(trial => trial.planting_date).map(trial => trial.planting_date);
        let maxTrialDate = Math.max(...trialsDates);
        let quarterEnd = new Quarter(maxTrialDate);
        maxQuarter = maxQuarter.max(quarterEnd);
        minQuarter = minQuarter.min(quarterEnd);
      }
    }
  }

  let nQuarters = maxQuarter.diff(minQuarter) + 1;
  let quarterLabelWidth = ctx.measureText(maxQuarter.format()).width;
  let canFitQuarters = Math.min(
    nQuarters,
    Math.max(1, Math.floor(contentWidth / (quarterLabelWidth + minLabelSpacing * 2)))
  );
  let quarterSpan = Math.ceil(nQuarters / canFitQuarters);
  let quarterCellWidth = contentWidth / Math.floor(nQuarters / quarterSpan);

  ctx.textBaseline = 'top';
  for (let i = 0, quarter = minQuarter; !quarter.gt(maxQuarter); quarter = quarter.add(quarterSpan), i++) {
    let boundX = yLabelHSpace + axisSpacing + i * quarterCellWidth + 0.5;
    ctx.fillText(
      quarter.format(),
      boundX + quarterCellWidth / 2 - quarterLabelWidth / 2,
      height - xLabelVSpace + labelAxisSpacing
    );

    ctx.beginPath();
    ctx.moveTo(boundX + quarterCellWidth, height - xLabelVSpace);
    ctx.lineTo(boundX + quarterCellWidth, 0);
    ctx.stroke();
  }

  // stage background
  ctx.fillStyle = 'rgba(216, 216, 216, 0.3)';
  for (let i = 0; i < data.stages.length; i++) {
    ctx.fillRect(yLabelHSpace + axisSpacing, stageOffsetY(i), contentWidth, stageHeight(i));
  }

  // varieties
  ctx.lineWidth = lineHeight;

  const xStart = minQuarter.getTimeStart();
  const xEnd = maxQuarter.getTimeEnd();

  const asX = (ts: number): number => {
    return ((ts - xStart) / (xEnd - xStart)) * contentWidth + yLabelHSpace + axisSpacing;
  };
  const fitInBounds = (x: number, width: number) => {
    let xStartDiff = x - asX(minQuarter.getTimeStart());
    let xEndDiff = x + width - asX(maxQuarter.getTimeEnd());
    if (xStartDiff < 0) {
      x -= xStartDiff;
    } else if (xEndDiff > 0) {
      x -= xEndDiff;
    }

    return x;
  };

  let mouseOverRects: {
    x: number;
    y: number;
    width: number;
    height: number;
    varietyIndex: number;
  }[] = [];
  let fillRect = (x: number, y: number, width: number, height: number, varietyIndex: number) => {
    ctx.clearRect(x, y, width - 0.5, height - 0.5);
    ctx.fillRect(x, y, width, height);
    // allow to mouseover with a 3px tolerance
    mouseOverRects.push({
      x: x - 3,
      y: y - 3,
      width: width + 6,
      height: height + 6,
      varietyIndex,
    });
  };

  let isDiscarded = (variety: StageChangeVarietyData, idx: number) =>
    variety.end_at && variety.end_at === data.levels[idx].discarded;

  // variety lines
  let prevStageY: number[] = [];
  let stageY = data.stages.map((stage, i) => {
    let nLines = linesPerStage[i];
    let curLine = 0;
    if (nLines < minLinesPerStage) {
      curLine = Math.floor((minLinesPerStage - nLines) / 2 - 0.5);
    }

    prevStageY = stage.levels.map((variety, j) => {
      if (!variety) {
        return prevStageY[j];
      }

      let startX = asX(variety.start_at);
      let endX = asX(variety.end_at || xEnd);
      let y = stageOffsetY(i) + lineSpacing + curLine * (lineHeight + lineSpacing);

      ctx.fillStyle = color(j);
      ctx.globalAlpha = highlightVarietyIndex === null || highlightVarietyIndex === j ? 1 : 0.2;

      fillRect(startX, y, endX - startX, lineHeight, j);

      if (prevStageY[j] !== null) {
        fillRect(startX - lineHeight / 2, y, lineHeight, prevStageY[j] - y + lineHeight, j);
      }

      if (isDiscarded(variety, j)) {
        ctx.strokeStyle = color(j);
        ctx.fillStyle = '#6E6E6E';
        ctx.lineWidth = lineHeight;
        ctx.strokeRect(endX - lineHeight * 1.25, y, lineHeight, lineHeight);
        ctx.fillRect(endX - lineHeight * 1.25, y, lineHeight, lineHeight);
      }

      curLine++;

      return y;
    });

    return prevStageY;
  });

  // trial markers
  data.stages.map((stage, i) => {
    stage.levels.forEach((variety, j) => {
      if (!variety) {
        return;
      }

      const radius = 5;

      let clustered: { names: string[]; x: number }[] = [];
      let prevX: number = null;
      for (let trial of variety.trials) {
        let x = asX(trial.planting_date);
        if (prevX !== null && Math.abs(prevX - x) < radius * 2) {
          clustered[clustered.length - 1].names.push(trial.name);
        } else {
          clustered.push({ names: [trial.name], x });
          prevX = x;
        }
      }

      let labelTop = true;
      for (let cluster of clustered) {
        ctx.globalAlpha = highlightVarietyIndex === null || highlightVarietyIndex === j ? 1 : 0.2;
        ctx.strokeStyle = color(j);
        ctx.fillStyle = '#FFF';
        ctx.lineWidth = 3;
        ctx.beginPath();
        ctx.ellipse(cluster.x, stageY[i][j] + lineHeight / 2, radius, radius, 0, 0, Math.PI * 2);
        ctx.stroke();
        ctx.fill();

        // trial labels
        if (j === highlightVarietyIndex) {
          ctx.save();
          ctx.font = '12px Roboto, sans-serif';

          let { width, height } = calcBoxSize(cluster.names);
          let spacing = 30;
          let yTop = stageY[i][j] + (labelTop ? -height - spacing : spacing + lineHeight);
          let xStart = fitInBounds(cluster.x - width / 2, width);

          drawBox(cluster.names, xStart, yTop, width, color(j));

          ctx.lineWidth = 4;
          ctx.beginPath();
          if (labelTop) {
            ctx.moveTo(cluster.x, stageY[i][j] - radius + lineHeight / 2);
            ctx.lineTo(cluster.x, yTop + height);
          } else {
            ctx.moveTo(cluster.x, stageY[i][j] + radius + lineHeight / 2);
            ctx.lineTo(cluster.x, yTop);
          }
          ctx.stroke();
          ctx.restore();

          labelTop = !labelTop;
        }
      }
    });
  });

  // variety labels
  ctx.textBaseline = 'middle';
  ctx.font = 'bold 14px Roboto, sans-serif';
  ctx.globalAlpha = 1;

  data.stages.map((stage, i) => {
    stage.levels.forEach((variety, j) => {
      if (!variety || j !== highlightVarietyIndex) {
        return;
      }

      const margin = 5;
      let name = data.levels[j].name;
      let { width, height } = calcBoxSize([name]);
      let y = stageY[i][j] - height - margin;

      let xLeft = fitInBounds(asX(variety.start_at), width);
      let xRight = fitInBounds(asX(variety.end_at || xEnd) - width - margin, width);

      if (variety.first) {
        drawBox([data.levels[j].name], xLeft, y, width, color(j), 0.5);
      }
      if (xRight > xLeft + width + margin && (!variety.end_at || isDiscarded(variety, j))) {
        drawBox([data.levels[j].name], xRight, y, width, color(j), 0.5);
      }
    });
  });

  return mouseOverRects;
};

ko.bindingHandlers['stageChangeChart'] = {
  init: (element: HTMLElement, valueAccessor: () => MaybeKO<StageChangeChartData>) => {
    let canvas = document.createElement('canvas');
    canvas.style.position = 'relative';
    canvas.style.top = -verticalMargin + 'px';
    canvas.style.pointerEvents = 'none';
    canvas.style.width = canvasWidth + 'px';
    element.appendChild(canvas);

    let onMouseMove = (evt: MouseEvent) => {
      let rect = element.getBoundingClientRect();
      let x = evt.clientX - rect.left;
      let y = evt.clientY - rect.top;

      if (mouseEvents.has(element)) {
        mouseEvents.get(element)(x, y);
      }
    };

    element.addEventListener('mousemove', onMouseMove);
    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      mouseEvents.delete(element);
      element.removeEventListener('mousemove', onMouseMove);
    });
  },

  update: (element: HTMLElement, valueAccessor: () => MaybeKO<StageChangeChartData>) => {
    let canvas = element.querySelector('canvas');
    if (!canvas) {
      return;
    }
    let data = ko.unwrap(valueAccessor());

    let prevHightlight: number | null = null;
    let mouseOverRects = draw(element, canvas, data, prevHightlight);
    mouseEvents.set(element, (x, y) => {
      let highlight: number | null = null;
      for (let rect of mouseOverRects) {
        if (x >= rect.x && x <= rect.x + rect.width && y >= rect.y && y <= rect.y + rect.height) {
          highlight = rect.varietyIndex;
          break;
        }
      }
      if (highlight !== prevHightlight) {
        prevHightlight = highlight;
        mouseOverRects = draw(element, canvas, data, highlight);
      }
    });
  },
};
