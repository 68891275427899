import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate, ListLoader } from '../components/list_loader';
import { DimensionMeta } from '../models/dimension_meta';
import * as dimensionMetasApi from '../api/dimension_metas';
import * as usersApi from '../api/users';
import { downloadBlob, updateLocationWithQueryString } from '../utils';
import { canEditDimension } from '../permissions';
import { BaseLoadingScreen } from './base_loading_screen';
import { ListHeaderAction } from '../components/list_header';
import { Action } from '../components/basic_widgets';
import { confirmDialog } from '../components/confirm_dialog';

let template = require('raw-loader!../../templates/dimension_metas.html').default;

class DimensionMetasScreen
  extends BaseLoadingScreen
  implements ListLoaderDelegate<dimensionMetasApi.DimensionMetaData, DimensionMeta>
{
  exporting = ko.observable(false);
  canEdit = false;
  listActions = ko.observableArray<ListHeaderAction>();
  private loader: ListLoader<dimensionMetasApi.DimensionMetaData, DimensionMeta>;

  private nameFilter = ko.observable('').extend({ throttle: 300 });
  searchPlaceholder = i18n.t('Search dimensions')();

  allowItemsPerLoadDisplay = true;

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { filters: { name_search: string } }) {
    super();

    this.nameFilter(params.filters.name_search || '');

    this.listActions.push({
      title: i18n.t('Export')(),
      icon: 'file_download',
      onClick: this.exportAll,
      loading: this.exporting,
    });

    let promise = usersApi.me().then(() => {
      this.canEdit = canEditDimension();

      if (this.canEdit) {
        this.listActions.splice(0, 0, {
          title: i18n.t('Add')(),
          icon: 'add_circle',
          href: '/dimensions/new/',
          tooltipTitle: i18n.t('Add dimension')(),
        });
      }
    });
    this.loadedAfter(promise);
  }

  onReady(loader: ListLoader<dimensionMetasApi.DimensionMetaData, DimensionMeta>) {
    this.loader = loader;
    this.subscriptions.push(this.nameFilter.subscribe(() => this.loader.forceLoad()));
  }

  fetch(params: ListRequestParams) {
    const filter = {
      name_search: this.nameFilter(),
    };
    updateLocationWithQueryString(filter);
    return dimensionMetasApi.listCustom({ ...params, ...filter });
  }

  instantiate(dimensionMetaData: dimensionMetasApi.DimensionMetaData) {
    return new DimensionMeta(dimensionMetaData);
  }

  remove(id: string) {
    return dimensionMetasApi.remove(id);
  }

  canRemove(dimensionMeta: DimensionMeta) {
    return this.canEdit && dimensionMeta.canRemove();
  }

  getActions(dimensionMeta: DimensionMeta): Action[] {
    if (!this.canRemove(dimensionMeta)) {
      return [];
    }

    return [
      {
        icon: 'archive',
        title: i18n.t('Archive')(),
        cssClass: '',
        onClick: () => {
          confirmDialog(i18n.t('Archive dimension?')(), [
            i18n.t('Archived dimensions will no longer be visible or selectable for new trials.')(),
            i18n.t('Are you sure you want to continue?')(),
          ]).then(() => this.archive(dimensionMeta));
        },
      },
    ];
  }

  private archive(dimensionMeta: DimensionMeta) {
    dimensionMetasApi.archive(dimensionMeta.id()).then(() => {
      if (this.loader) {
        this.loader.refresh();
      }
    });
  }

  exportAll = () => {
    this.exporting(true);

    dimensionMetasApi
      .exportAll()
      .then((data) => {
        this.exporting(false);
        downloadBlob(data, 'dimensions.xlsx');
      })
      .catch(() => {
        this.exporting(false);
      });
  };

  dispose() {
    this.subscriptions.forEach((s) => s.dispose());
    this.subscriptions = [];
  }
}

export let dimensionMetas = {
  name: 'dimension-metas',
  viewModel: DimensionMetasScreen,
  template: template,
};

ko.components.register(dimensionMetas.name, dimensionMetas);
