import * as ko from 'knockout';
import i18n from '../i18n';

import { UserData, UserDetailData, isUserDetailData, UserRole, CountryUserData } from '../api/users';
import { BaseGroupData } from '../api/base_groups';
import { CountryData } from '../api/countries';
import { getCountrySearchConfig } from '../components/configs/search_configs';
import { findById } from '../utils';
import { session } from '../session';
import { ROLE_OPTIONS } from './country';
import { parseDateTime } from '../api/serialization';

interface RoleOption {
  name: string;
  id: UserRole;
}

export interface UserOptions {
  defaultRole: UserRole;
  roleOptions: RoleOption[];
  adminRoleOptions: RoleOption[];
}

export const ftUserOptions: UserOptions = {
  defaultRole: 'admin',
  roleOptions: [
    { name: i18n.t('Admin')(), id: 'admin' },
    { name: i18n.t('Read-only admin')(), id: 'read_only_admin' },
    { name: i18n.t('Template editor')(), id: 'template_editor' },
    { name: i18n.t('Manager')(), id: 'manager' },
    { name: i18n.t('Restricted manager')(), id: 'restricted_manager' },
    { name: i18n.t('Editor')(), id: 'editor' },
    { name: i18n.t('Staff')(), id: 'data_entry' },
  ],
  adminRoleOptions: [],
};

export const s2bimUserOptions: UserOptions = {
  defaultRole: 'staff',
  roleOptions: [
    { name: i18n.t('Staff')(), id: 'staff' },
    { name: i18n.t('Partner')(), id: 'partner' },
  ],
  adminRoleOptions: [
    { name: i18n.t('Admin')(), id: 'admin' },
    { name: i18n.t('HQ (read-only)')(), id: 'read_only_admin' },
  ],
};

export class User {
  private DATA_ENTRY_SUMMARY: {
    [key: string]: { text: string; icon: string };
  } = {
    all: {
      text: i18n.t('Can enter results for all active trials')(),
      icon: 'phonelink_setup',
    },
    some: {
      text: i18n.t(['can_enter_for_active_trials', 'Can enter results for some active trials'])(),
      icon: 'phonelink_lock',
    },
    some_restricted: {
      text: i18n.t([
        'restricted_can_enter_for_active_trials',
        'Can enter results for some active trials (with restrictions)',
      ])(),
      icon: 'phonelink_lock',
    },
    none: {
      text: i18n.t("Can't enter results for any trial")(),
      icon: 'phonelink_erase',
    },
  };

  roleOptions: RoleOption[];

  id = ko.observable<string>(null);
  name = ko.observable('').extend({
    required: true,
  });
  email = ko.observable('').extend({
    required: true,
    serverError: true,
  });
  role = ko.observable<UserRole>();
  country = ko.observable<CountryData>(null);
  groups = ko
    .observableArray<BaseGroupData>()
    .extend({ required: { onlyIf: () => !session.isAdmin() }, serverError: true });
  countryRoles = ko.observableArray<CountryUser>();
  sendInvite = ko.observable(false);
  password = ko.observable('').extend({ serverError: true });
  canEditRoles = false;

  // read-only
  lastSyncTime: Date;
  lastSyncUploadTime: Date;
  lastSyncOS: string;
  lastSyncAppVersion: string;
  loadLevelClass: string;
  loadLevelTip: string;
  isActive: boolean;

  dataEntryPermissionsText: string;
  dataEntryPermissionsIcon: string;

  countrySearchConfig = getCountrySearchConfig(this.country);

  editUrl = ko.pureComputed(() => {
    return '/users/' + this.id();
  });

  canHaveCountryPermissions = ko.pureComputed(() => this.role() !== 'partner');

  constructor(options: UserOptions, public data?: UserData | UserDetailData) {
    this.role(options.defaultRole);
    this.roleOptions = options.roleOptions;

    if (!session.tenant() || !session.tenant().restricted_manager_enabled) {
      this.roleOptions = this.roleOptions.filter((opt) => opt.id !== 'restricted_manager');
    }

    if (!session.isAdmin()) {
      this.roleOptions = this.roleOptions.filter((opt) => opt.id === 'data_entry');
      this.role('data_entry');
    }

    if (data) {
      this.id(data.id);
      this.name(data.name);
      this.email(data.email);
      this.role(data.role);
      this.country(data.country);

      if (data.data_entry_permissions_summary) {
        this.dataEntryPermissionsText = this.DATA_ENTRY_SUMMARY[data.data_entry_permissions_summary].text;
        this.dataEntryPermissionsIcon = this.DATA_ENTRY_SUMMARY[data.data_entry_permissions_summary].icon;
      }

      if (isUserDetailData(data)) {
        this.groups(data.groups);
        this.countryRoles(data.country_roles.map((role) => new CountryUser(role)));
      }

      this.canEditRoles = session.isAdmin() || session.is('head');

      this.lastSyncTime = parseDateTime(data.last_sync_time);
      this.lastSyncUploadTime = parseDateTime(data.last_sync_upload_time);
      this.lastSyncOS = data.last_sync_os;
      this.lastSyncAppVersion = data.last_sync_app_version;

      this.isActive = data.is_active;
    } else {
      this.canEditRoles = session.isAdmin();
      this.sendInvite(true);
    }

    if (session.isAdmin()) {
      this.roleOptions = this.roleOptions.concat(options.adminRoleOptions);
    }
  }

  add = () => {
    this.countryRoles.push(new CountryUser());
  };

  remove = (role: CountryUser) => {
    this.countryRoles.remove(role);
  };

  toData(): UserDetailData {
    return {
      id: this.id(),
      name: this.name(),
      email: this.email(),
      role: this.role(),
      country: this.country(),
      groups: this.groups(),
      country_roles_write: this.canHaveCountryPermissions()
        ? this.countryRoles().map((role) => role.toData())
        : [],
      send_invite: this.sendInvite(),
      password: this.password(),
    };
  }

  formattedRole = ko.pureComputed(() => {
    let option = findById(this.roleOptions, this.role());
    return option ? option.name : '';
  });
}

export class CountryUser {
  country = ko.observable<CountryData>(null).extend({
    required: true,
  });
  role = ko.observable<UserRole>('editor');

  roleOptions = ROLE_OPTIONS;

  countrySearchConfig = getCountrySearchConfig(this.country, {
    disableCreate: false,
    role: 'head',
  });

  constructor(data?: CountryUserData) {
    if (data) {
      this.country(data.country);
      this.role(data.role);
    }
  }

  toData(): CountryUserData {
    return {
      country: this.country(),
      role: this.role(),
    };
  }
}
