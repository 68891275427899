import * as ko from 'knockout';

import { CustomReportData } from '../api/custom_report';

export class CustomReport {
  id = ko.observable<number>(null);
  name = ko.observable<string>(null).extend({
    serverError: true
  });
  description = ko.observable<string>(null);
  iframeUrl = ko.observable<string>(null).extend({
    serverError: true
  });
  trialId = ko.observable<number | null>(null);

  constructor(data?: CustomReportData) {
    if (data) {
      this.id(data.id);
      this.name(data.name);
      this.description(data.description);
      this.iframeUrl(data.iframe_url);
      this.trialId(data.trial);
    }
  }

  toData(): CustomReportData {
    return {
      id: this.id(),
      name: this.name(),
      description: this.description(),
      iframe_url: this.iframeUrl(),
      trial: this.trialId(),
    };
  }
}
