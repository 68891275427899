// ignore ts check because of the ko binding
// @ts-nocheck
import * as ko from 'knockout';

import 'chart.js/auto';
import { Chart, PointStyle, ChartColor } from 'chart.js';

import { flatten, groupBy, Counter } from '../utils';

export interface QuadDataset {
  pointStyle: PointStyle;
  color: ChartColor;
  label: string;
  data: DataPoint[];
}

interface DataPoint {
  x: number;
  y: number;
  label: string;
}

export interface QuadScatterChartConfig {
  xTitle: string;
  yTitle: string;
  topLeft: string;
  topRight: string;
  bottomLeft: string;
  bottomRight: string;
  fontSize: number;
  pointRadius: number;
  pointText: boolean;
  offsetBottom: number;
  datasets: QuadDataset[];
}

function setup(element: Element, config: QuadScatterChartConfig): Chart {
  let lineColors: string[] = [];
  for (let i = 0; i <= 10; i++) {
    lineColors[i] = i === 5 ? '#000000' : '#d9d9d9';
  }

  let datasets = config.datasets.map((ds) => ({
    ...ds,
    pointBackgroundColor: ds.color,
    pointBorderColor: ds.color,
    pointRadius: config.pointRadius,
    pointHoverRadius: config.pointRadius,
  }));

  // displace overlapping points in a circle to reduce overlapping
  const key = (point: DataPoint) => point.x.toString() + '-' + point.y.toString();
  let counts = groupBy(
    flatten(config.datasets.map((ds) => ds.data)),
    key,
    (val, acc: number) => (acc || 0) + 1
  );
  let counter = new Counter(key);
  for (let ds of datasets) {
    ds.data = ds.data.map((pt) => {
      let count = counts[key(pt)];
      let idx = counter.next(pt);
      let deg = (idx / count) * Math.PI * 2 + Math.PI / 2;
      let displacement = count > 1 ? 0.3 : 0;
      let x = pt.x + Math.cos(deg) * displacement;
      let y = pt.y + Math.sin(deg) * displacement;

      return { ...pt, x, y };
    });
  }

  return new Chart(<HTMLCanvasElement>element, {
    type: 'scatter',
    data: { datasets },
    plugins: [
      {
        id: 'quadScatterChart',
        afterDraw: (chart: Chart) => {
          let ctx: CanvasRenderingContext2D = chart.ctx;
          let canvas = chart.canvas;

          ctx.save();

          ctx.font = Math.floor(config.fontSize * 0.8) + 'px "Helvetica Neue", Helvetica, Arial, sans-serif';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';

          if (config.pointText) {
            for (let i = 0; i < datasets.length; i++) {
              ctx.fillStyle = datasets[i].color as string;
              let meta = chart.getDatasetMeta(i);
              for (let j = 0; j < datasets[i].data.length; j++) {
                let pos = (meta.data[j] as any).tooltipPosition();
                ctx.fillText((j + 1).toString(), pos.x + config.pointRadius, pos.y - config.pointRadius);
              }
            }
          }

          ctx.font = config.fontSize + 'px "Helvetica Neue", Helvetica, Arial, sans-serif';
          ctx.fillStyle = APP_CONFIG.PRIMARY_COLOR;
          ctx.fillText(config.topLeft, canvas.width / 4, 15);
          ctx.fillText(config.topRight, (canvas.width / 4) * 3, 15);
          ctx.fillText(config.bottomLeft, canvas.width / 4, canvas.height - config.offsetBottom);
          ctx.fillText(config.bottomRight, (canvas.width / 4) * 3, canvas.height - config.offsetBottom);

          ctx.restore();
        },
      },
    ],
    options: {
      aspectRatio: 1.5,
      animation: { duration: 0 },
      layout: {
        padding: { top: 24 },
      },
      plugins: {
        legend: { display: false },
        tooltip: {
          mode: 'point',
          callbacks: {
            label: (context) => {
              const dataset = context.chart.data.datasets[context.datasetIndex];
              const category = dataset.label;
              const value = (dataset.data[context.dataIndex] as { label?: string })?.label || '';
          
              return category ? value + ' (' + category + ')' : value;
            },
          },
        },
      },
      scales: {
        x: {
            title: {
              display: true,
              text: config.xTitle,
              font: {size: config.fontSize},
            },
            ticks: {
              fontSize: config.fontSize,
              beginAtZero: true,
              min: 0,
              max: 10.5,
              stepSize: 1,
              maxTicksLimit: 10,
            } as any,
            afterBuildTicks: (chart) => {
              return chart.ticks.splice(-1, 1);
            },
            grid: { color: lineColors },
          },
        y: {
          title: {
              display: true,
              text: config.yTitle,
              font: {size: config.fontSize},
            },
            ticks: {
              beginAtZero: true,
              fontSize: config.fontSize,
              min: 0,
              max: 10.5,
              stepSize: 1,
              maxTicksLimit: 10,
            } as any,
            afterBuildTicks: (chart) => {
              return chart.ticks.splice(0, 1);
            },
            grid: { color: lineColors },
          },
      },
    },
  });
}

ko.bindingHandlers['quadScatterChart'] = {
  init: (element: Element, valueAccessor: () => KnockoutObservable<QuadScatterChartConfig>) => {
    setup(element, ko.unwrap(valueAccessor()));
  },
  update: (element: Element, valueAccessor: () => KnockoutObservable<QuadScatterChartConfig>) => {
    setup(element, ko.unwrap(valueAccessor()));
  },
};

ko.bindingHandlers['chartPoint'] = {
  init: (
    element: Element,
    valueAccessor: () => KnockoutObservable<{
      pointStyle: PointStyle;
      color: string;
    }>
  ) => {
    let canvas = element as HTMLCanvasElement;
    canvas.width = canvas.height = 24;
    let ctx = canvas.getContext('2d');
    let point = ko.unwrap(valueAccessor());

    ctx.translate(0.5, 0.5); // for sharper lines
    ctx.fillStyle = point.color;
    ctx.strokeStyle = point.color;
    Chart.helpers.drawPoint(ctx, point.pointStyle, 10, 12, 12, 0);
  },
};
