import { pad } from '../utils';

import * as ko from 'knockout';

interface IdData {
  id?: string | KnockoutObservable<string>;
}

export function inflateModelListFrom<T extends { id: () => string }>(ids: string[], available: T[]) {
  if (!ids || !available) {
    return [];
  }

  return ids
    .map((id) => {
      return available.filter((data) => data.id() == id)[0];
    })
    .filter((data) => !!data);
}

export function inflateSingleFrom<T extends IdData>(id: string, available: T[]) {
  if (!id || !available) {
    return null;
  }

  return available.filter((data) => ko.unwrap(data.id) == id)[0];
}

export function inflateSingleModelFrom<T extends { id: () => string }>(id: string, available: T[]) {
  if (!id || !available) {
    return null;
  }

  return available.filter((data) => data.id() == id)[0];
}

export function deflateSingle(entity: IdData) {
  return entity ? ko.unwrap(entity.id) : undefined;
}

export function deflateList(entities: KnockoutObservableArray<IdData>) {
  return entities().map((entity) => ko.unwrap(entity.id));
}

const ISO_DATE_REGEXP = /^(\d\d\d\d)-(\d\d)-(\d\d)$/;

export function parseDate(dateString: string) {
  if (!dateString) {
    return null;
  }

  let match = dateString.match(ISO_DATE_REGEXP);
  if (!match) {
    match = dateString.match(ISO_DATE_TIME_REGEXP);
    if (!match) {
      throw new Error("Can't parse date: " + dateString);
    }
  }

  return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
}

export function safeParseDate(dateString: string) {
  if (!dateString) {
    return null;
  }

  let match = dateString.match(ISO_DATE_REGEXP);
  if (!match) {
    return null;
  }

  return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, parseInt(match[3], 10));
}

export const ISO_DATE_TIME_REGEXP = /^(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)\.(\d\d\d)Z$/;
export const DATE_TIME_REGEXP = /^(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)\.(\d\d\d\d\d\d)$/;

export function parseDateTime(dateString: string) {
  if (!dateString) {
    return null;
  }

  let match = dateString.match(ISO_DATE_TIME_REGEXP);
  if (!match) {
    match = dateString.match(DATE_TIME_REGEXP);
    if (!match) {
      throw new Error("Can't parse datetime: " + dateString);
    }
  }

  let milliseconds = parseInt(match[7], 10);
  if (milliseconds > 999) {
    milliseconds = Math.round(milliseconds / 1000);
  }

  return new Date(
    Date.UTC(
      parseInt(match[1], 10),
      parseInt(match[2], 10) - 1,
      parseInt(match[3], 10),
      parseInt(match[4], 10),
      parseInt(match[5], 10),
      parseInt(match[6], 10),
      milliseconds
    )
  );
}

export function serializeDate(date: Date) {
  if (!date) {
    return null;
  }

  return date.getFullYear() + '-' + pad(date.getMonth() + 1, 2) + '-' + pad(date.getDate(), 2);
}

export function serializeDateTime(date: Date) {
  if (!date) {
    return null;
  }

  return date.toISOString();
}

export function serializeNumber(value: string | number): number {
  if (typeof value === 'string' && !(<string>value).trim()) {
    return null;
  }

  return <number>value;
}
