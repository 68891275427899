import * as ko from 'knockout';

import { DataEntryCollection } from '../models/data_entry_permission';

let template = require('raw-loader!../../templates/components/data_entry_permissions_edit.html').default;

class DataEntryPermissionsEdit {
  collection: DataEntryCollection;

  constructor(params: { collection: DataEntryCollection }) {
    this.collection = params.collection;
  }
}

let dataEntryPermissionsEdit = {
  name: 'data-entry-permissions-edit',
  viewModel: DataEntryPermissionsEdit,
  template: template,
};

ko.components.register(dataEntryPermissionsEdit.name, dataEntryPermissionsEdit);
