import * as ko from 'knockout';

import { I18nText } from '../i18n_text';
import { SeasonData } from '../api/seasons';
import { session } from '../session';

export class Season {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  code = ko.observable('').extend({
    serverError: true,
  });

  canEdit = session.isAtLeastEditor();

  constructor(data?: SeasonData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.code(data.code);
    }
  }

  toData(): SeasonData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      code: this.code(),
    };
  }
}
