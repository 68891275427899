import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { app } from '../app';
import i18n from '../i18n';
import { GeoJSON } from '../api/datasets';
import { Point } from '../ko_bindings/map';

let template = require('raw-loader!../../templates/components/map_edit.html').default;

class MapEdit {
  placeholderText = i18n.t('Search location')();

  value: KnockoutObservable<GeoJSON>;
  defaultLocation: KnockoutObservable<Point>;
  result: Deferred<{}>;

  constructor(params: {
    value: KnockoutObservable<GeoJSON>;
    defaultLocation: KnockoutObservable<Point>;
    result?: Deferred<{}>;
  }) {
    this.value = params.value;
    this.defaultLocation = params.defaultLocation;
    this.result = params.result;
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

ko.components.register('map-edit', { viewModel: MapEdit, template: template });

export function selectLocationPopup(
  value: KnockoutObservable<GeoJSON>,
  defaultLocation: KnockoutObservable<Point>,
  isMapModalClosed?: KnockoutObservable<boolean>,
) {
  const result = new Deferred<{}>();
  if (isMapModalClosed !== undefined) {
    result.promise.then(() => {
      isMapModalClosed(true);
    });
  }
  app.formsStackController.push({
    title: i18n.t('Location')(),
    name: 'map-edit',
    isBig: true,
    params: {
      value,
      defaultLocation,
      result: result,
    },
  });
}
