import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { Region } from '../models/region';
import * as regionsApi from '../api/regions';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';
import { CountryData } from '../api/countries';
import { canCreateCountry } from '../permissions';

let template = require('raw-loader!../../templates/region_edit.html').default;

class RegionEditScreen extends BaseForm<regionsApi.RegionData> {
  region = ko.validatedObservable<Region>(null);
  canEdit = ko.observable(canCreateCountry());

  constructor(
    params: {
      id: string;
      initialName?: string;
      initialCountry?: CountryData;
      result?: Deferred<regionsApi.RegionData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let regionDataPromise = params.id ? regionsApi.retrieve(params.id) : undefined;
    let promise = Promise.all([regionDataPromise]).then(([regionData]) => {
      this.region(new Region(regionData));
      if (!regionData && params.initialName) {
        this.region().nameJson(asI18nText(params.initialName));
      }
      if (!regionData && params.initialCountry) {
        this.region().country(params.initialCountry);
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (this.validateLocal(this.region)) {
      let data = this.region().toData();
      this.executeSaveRequest(regionsApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.region(), validation);
      });
    }
  };
}

export let regionEdit = {
  name: 'region-edit',
  viewModel: createWithComponent(RegionEditScreen),
  template: template,
};

ko.components.register(regionEdit.name, regionEdit);
