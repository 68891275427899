import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { BaseForm } from '../screens/base_form';
import { CropVarietyStageData, addCVStage } from '../api/crop_varieties';
import { createWithComponent } from '../utils/ko_utils';
import { CropVarietyStage } from '../models/crop_variety';
import { CountryData } from '../api/countries';

let template = require('raw-loader!../../templates/components/add_cv_stage.html').default;

class AddCVStage extends BaseForm<CropVarietyStageData> {
  private cvId: string;
  private dontSave: boolean;

  title: string;
  entity = new CropVarietyStage();

  constructor(
    params: {
      title: string;
      cvId: string;
      country: CountryData;
      dontSave?: boolean;
      result?: Deferred<CropVarietyStageData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);
    this.title = params.title;
    this.cvId = params.cvId;
    this.entity.country(params.country);
    this.dontSave = !!params.dontSave;
    this.loaded();
  }

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity.toData();
      if (this.dontSave) {
        this.result.resolve(data);
      } else {
        this.executeSaveRequest(addCVStage(this.cvId, data)).then((validation) => {
          this.onRemoteValidation(data, this.entity, validation);
        });
      }
    }
  };
}

ko.components.register('add-cv-stage', {
  viewModel: createWithComponent(AddCVStage),
  template: template,
});
