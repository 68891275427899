import { FileUploadDelegate } from './components/basic_widgets';
import { CloudStorageUpload, FileUploadEndpoint, CloudStorageUploadDelegate } from './cloud_storage_upload';

export interface ImageUploadDelegate {
  getImageUploadEndpoint(contentType: string): Promise<FileUploadEndpoint>;
  onPictureClick?(): void;
}

export class ImageUpload implements FileUploadDelegate, CloudStorageUploadDelegate {
  canReuseEndpoint = true;

  fileName: string = null;
  picturePublicURL = ko.observable<string>(null);

  private cloudUpload = new CloudStorageUpload(this);
  fileUploadError = this.cloudUpload.fileUploadError;
  uploading = this.cloudUpload.uploading;

  constructor(private delegate: ImageUploadDelegate) {}

  onPictureClick() {
    this.delegate.onPictureClick?.();
  }

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest
  ) => {
    return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
  };

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return this.delegate.getImageUploadEndpoint(contentType);
  }

  onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
    this.fileName = fileName;
    // avoid caches, since the old image has the same URL
    this.picturePublicURL(publicURL + '?' + new Date().getTime());
  }
}
