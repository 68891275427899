import { listRequest, ListRequestParams } from './request';
import { I18nText } from '../i18n_text';

interface ChangelogRef {
  id: number | string;
  name: string | I18nText;
}

export type ChangelogValue = number | string | boolean | ChangelogRef;

interface ChangelogAtom {
  name: string;
  old: ChangelogValue;
  new: ChangelogValue;
}

interface ChangelogI18n {
  name: string;
  translations: { [key: string]: { old: string; new: string } };
}

interface ChangelogFKS {
  name: string;
  added: ChangelogRef[];
  removed: ChangelogRef[];
}

interface ChangelogEntityList {
  name: string;
  entities: ChangelogEntity[];
}

type ChangelogAttr = ChangelogAtom | ChangelogI18n | ChangelogFKS | ChangelogEntityList;

export function isChangelogAtom(data: ChangelogAttr): data is ChangelogAtom {
  return data && ((data as any).old !== undefined || (data as any).new !== undefined);
}

export function isChangelogI18n(data: ChangelogAttr): data is ChangelogI18n {
  return data && (data as any).translations !== undefined;
}

export function isChangelogFKS(data: ChangelogAttr): data is ChangelogFKS {
  return data && ((data as any).added || (data as any).removed);
}

export function isChangelogFK(data: ChangelogValue): data is ChangelogRef {
  return data && ((data as any).id !== undefined || (data as any).name !== undefined);
}

export interface ChangelogEntity {
  name: string;
  action: 'created' | 'updated' | 'deleted';
  attrs: ChangelogAttr[];
}

export interface ChangelogData {
  user_name: string;
  timestamp: string; // serialized datetime
  name_json: I18nText;
  diff: ChangelogEntity;
}

export function changelog(
  params: {
    user_id: string;
    entity_type: string;
    entity_id: string;
  } & ListRequestParams
): Promise<ChangelogData[]> {
  return listRequest('/api/changelog/', params as {});
}

export interface UserLogMixinData {
  // read-only
  created_by_user_name?: string;
  modified_by_user_name?: string;
}
