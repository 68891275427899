import * as ko from 'knockout';
import { app } from '../app';
import { factChangeReasons } from '../models/attribute_meta';
import { Deferred } from '../utils/deferred';
import i18n from '../i18n';

class ChangeReasonDialog {
  changeReasons = factChangeReasons;
  changeReason = ko.observable(factChangeReasons[0].value);

  isSaveButtonDisabled = ko.computed(() => {
    return this.changeReason() === factChangeReasons[0].value;
  })

  result: Deferred<string>;

  constructor(params: { result: Deferred<string> }) {
    this.result = params.result;
  }

  close = () => {
    this.result.reject();
  };

  save = () => {
    if (this.isSaveButtonDisabled()) {
      return;
    }

    this.result.resolve(this.changeReason());
  };
}
const template = require('raw-loader!../../templates/components/change_reason_dialog.html').default;
const name = 'change-reason-dialog';

ko.components.register(name, {
  viewModel: ChangeReasonDialog,
  template,
});

export function openChangeReasonDialog() {
  return app.formsStackController.push({
    name,
    className: 'change-reason-dialog',
    title: i18n.t('Change Reason required')(),
    params: {
      result: new Deferred<string>(),
    },
  });
}
