import { request } from './request';

export interface ReleaseNotesData {
  id: number;
  title: string;
  content: string;
  platform: string;
  created: Date;
  modified: Date;
  published: boolean;
}

export interface PaginatedResponse<T> {
  count: number;
  next?: any;
  previous?: any;
  results: T[];
}

export const releaseNotesList = (
  limit: number = 10,
  offset: number = 0
): Promise<PaginatedResponse<ReleaseNotesData>> => {
  let url = `/api/changelog/release-notes/?limit=${limit}&offset=${offset}`;

  return request('GET', url, {}, { injectTenant: false, version: 1 });
};
