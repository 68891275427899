import { PlotData, TrialVisits } from '../api/v2/interfaces';
import { plotDetails } from '../api/v2/plot';
import { trialVisits } from '../api/v2/trial';

import { session } from '../session';

type redirectParams = {
  trialId: number;
  siteId: string;
  plotId: string;
  svId?: number;
  visitId?: string;
};

export const getExtraPlotData = (id: string) => {
  return plotDetails(id).then((plotData) => {
    return trialVisits(plotData.trial_id, plotData.site_id).then((trialData) => {
      return { plot: plotData, trial: trialData };
    });
  });
};

export const processQRCodeUUID = (uuid: string) => {
  getExtraPlotData(uuid).then((data) => {
    // if trial have more then 1 visits -> redirect to a visits page
    // otherwise -> data entry page with plotId setted
    const { plot, trial } = data;
    chooseDataEntryPage(plot, trial);
  });
};

export const chooseDataEntryPage = (plot: PlotData, trial: TrialVisits) => {
  const params: redirectParams = {
    trialId: trial.trial.id,
    siteId: plot.site_id,
    plotId: plot.id,
  };
  if (trial.visits.length > 1 || trial.visits.length === 0) {
    goToVisitsPage(params);
  } else if (trial.visits) {
    const visit = trial.visits[0];
    goToEntryPage({ ...params, svId: visit.sv_id, visitId: visit.id });
  }
};

const goToVisitsPage = (params: redirectParams) => {
  const { trialId, siteId, plotId } = params;
  location.href = session.toTenantPath(`/data_entry/visits/${trialId}/?site_id=${siteId}&plot_id=${plotId}`);
};

const goToEntryPage = (params: redirectParams) => {
  const { trialId, siteId, plotId, svId, visitId } = params;
  location.href = session.toTenantPath(
    `/data_entry/edit/?trial_id=${trialId}&sv_id=${svId}&site_id=${siteId}&plot_id=${plotId}&visit_id=${visitId}`
  );
};
