import { makeDefaultApi, requestRaw } from './request';
import { I18nText } from '../i18n_text';
import { CountryData } from './countries';

export interface AgroRegionData {
  id?: string;
  name_json: I18nText;
  country: CountryData;
}

export const { list, retrieve, save, remove } = makeDefaultApi<AgroRegionData, { country_id?: string }>(
  'agro_regions'
);

export function downloadAgroRegionImportTemplate(): Promise<Blob> {
  return requestRaw('GET', '/api/agro_regions/import_template/');
}
