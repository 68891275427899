import * as ko from 'knockout';
import i18n from '../i18n';

import { BaseForm } from './base_form';
import { AttributeChoiceList, AttributeChoice } from '../models/attribute_choice_list';
import * as attributeChoiceListsApi from '../api/attribute_choice_lists';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';
import { canEditAttributeChoiceList } from '../permissions';

let template = require('raw-loader!../../templates/attribute_choice_list_edit.html').default;

class AttributeChoiceListEditScreen extends BaseForm<attributeChoiceListsApi.AttributeChoiceListData> {
  entity = ko.observable<AttributeChoiceList>(null);
  globalError = ko.observable<string>('');

  canEdit = canEditAttributeChoiceList();

  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<attributeChoiceListsApi.AttributeChoiceListData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    if (params.id) {
      let promise = attributeChoiceListsApi.retrieve(params.id).then((data) => {
        this.entity(new AttributeChoiceList(data));
      });
      this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
    } else {
      this.entity(new AttributeChoiceList());
      if (params.initialName) {
        this.entity().nameJson(asI18nText(params.initialName));
      }
      this.loaded();
      this.focusFirst(componentInfo.element);
    }
  }

  addChoice = () => {
    this.entity().choices.push(new AttributeChoice());
  };

  removeChoice = (choice: AttributeChoice) => {
    this.entity().choices.remove(choice);
  };

  save = () => {
    if (!this.entity() || !this.canEdit) {
      return;
    }

    for (let choice of this.entity().choices()) {
      this.clearModelServerErrors(choice);
    }
    this.globalError('');

    if (this.entity().choices().length == 0) {
      this.globalError(i18n.t('You must specify at least one choice')());
      return;
    }

    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(attributeChoiceListsApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);

        if (!validation.isValid && validation.errors['choices']) {
          let choiceErrors = <{ [idx: number]: { [field: string]: string[] } }>(
            (<any>validation.errors['choices'])
          );
          for (let k in choiceErrors) {
            if (choiceErrors.hasOwnProperty(k)) {
              let choice = this.entity().choices()[k];
              if (choice) {
                this.applyModelServerErrors(choice, choiceErrors[k]);
              }
            }
          }
        }
      });
    }
  };
}

export let attributeChoiceListEdit = {
  name: 'attribute-choice-list-edit',
  viewModel: createWithComponent(AttributeChoiceListEditScreen),
  template: template,
};

ko.components.register(attributeChoiceListEdit.name, attributeChoiceListEdit);
