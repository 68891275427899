import * as ko from 'knockout';
import i18n from '../../i18n';

import * as dimensionMetasApi from '../../api/dimension_metas';
import * as dimensionsApi from '../../api/dimensions';
import * as agroRegionsApi from '../../api/agro_regions';
import * as seasonsApi from '../../api/seasons';
import * as usersApi from '../../api/users';
import * as tppsApi from '../../api/tpps';

import { session } from '../../session';
import { BaseTrialStep } from './base';
import { DatasetWizard, Trial, getLocationAccuracies } from '../../models/trial';
import { EDITABLE_TRIAL_STATES, TrialState } from '../../models/TrialState';
import { FormSelectSearchConfiguration } from '../../components/form_select_search';
import { WizardController } from '../../screens/trial_wizard';
import {
  getCropSearchConfig,
  getSystemDimensionSearchConfig,
  getTrialTypeSearchConfig,
  getCountrySearchConfig,
  getSeasonSearchConfig,
  getAgroRegionSearchConfig,
  getUserSearchConfig,
  getProjectSearchConfig,
  getRegionSearchConfig,
  getPartnerSearchConfig,
} from '../configs/search_configs';
import { asArray } from '../../utils';
import { deflateSingle } from '../../api/serialization';
import { customersApi } from '../../api/organizations';
import { NameData } from '../../api/names';
import { TrialTypeData } from '../../api/trial_types';
import { trialEditableByUser } from '../../permissions';

let template =
  require('raw-loader!../../../templates/components/trial_wizard/trial_basic_data.html').default;

class TrialBasicData extends BaseTrialStep {
  trialFieldsSpecial = APP_CONFIG.TRIAL_FIELDS_SPECIAL;
  showTPP = ko.pureComputed(() => session.tenant()?.tpp_enabled && !this.trial().gateInnovationId());
  showGateInnovation = ko.pureComputed(() => !!this.trial().gateInnovationId());
  locationAccuracies = ko.observableArray(getLocationAccuracies());

  initialTrialState = ko.observable<TrialState>(null);

  cropSearchConfig = ko.observable<FormSelectSearchConfiguration<dimensionsApi.DimensionData>>(null);
  cultivationTypeSearchConfig =
    ko.observable<FormSelectSearchConfiguration<dimensionsApi.DimensionData>>(null);
  fieldPreparationTypeSearchConfig =
    ko.observable<FormSelectSearchConfiguration<dimensionsApi.DimensionData>>(null);
  irrigationTypeSearchConfig =
    ko.observable<FormSelectSearchConfiguration<dimensionsApi.DimensionData>>(null);
  trialTypeSearchConfig = ko.observable<FormSelectSearchConfiguration<TrialTypeData>>(null);

  userSearchConfig = ko.pureComputed<FormSelectSearchConfiguration<usersApi.UserData>>(() =>
    getUserSearchConfig(this.trial() ? this.trial().owners : null)
  );

  countrySearchConfig = ko.pureComputed(() =>
    getCountrySearchConfig(this.trial() ? this.trial().country : null)
  );

  regionSearchConfig = ko.pureComputed(() =>
    getRegionSearchConfig(this.trial()?.region, this.trial()?.country)
  );

  agroRegionSearchConfig = ko.pureComputed<FormSelectSearchConfiguration<agroRegionsApi.AgroRegionData>>(
    () => {
      return getAgroRegionSearchConfig(
        this.trial() ? this.trial().agroRegion : undefined,
        this.trial() ? this.trial().country() : undefined
      );
    }
  );

  seasonSearchConfig = ko.pureComputed<FormSelectSearchConfiguration<seasonsApi.SeasonData>>(() => {
    let trial = this.trial();
    if (!trial) {
      return null;
    }

    return getSeasonSearchConfig(trial.season, trial.country, {
      disableCreate: true,
    });
  });

  customersSearchConfig = ko.pureComputed(() => {
    let res: FormSelectSearchConfiguration<NameData> = {
      getSummaryName: (record) => record.name,
      list: (params) => customersApi.list(params),
      entities: this.trial() ? this.trial().customers : null,
      create: { title: i18n.t('Customers')(), componentName: 'customer-edit' },
    };

    return res;
  });

  tppSearchConfig = ko.pureComputed(() => {
    let res: FormSelectSearchConfiguration<tppsApi.TPPListData> = {
      getSummaryName: (tpp) => tpp.name_json,
      list: (params) =>
        tppsApi.list({
          country_ids: asArray(deflateSingle(this.trial().country())),
          crop_ids: asArray(deflateSingle(this.trial().crop())),
          ...params,
        }),
      entity: this.trial() ? this.trial().tpp : null,
    };

    return res;
  });
  projectSearchConfig = ko.pureComputed(() =>
    getProjectSearchConfig(this.trial() ? this.trial().project : null)
  );
  partnerSearchConfig = ko.pureComputed(() => getPartnerSearchConfig(this.trial()?.partner));

  canEditCode = ko.pureComputed(() => {
    return !this.trial()?.autoNameTrials && this.canEdit();
  });

  canEdit = ko.pureComputed(() => {
    return this.allowEditAny() && EDITABLE_TRIAL_STATES.includes(this.initialTrialState());
  });

  canEditCrop = ko.pureComputed(() => this.canEdit() && this.trial() && !this.trial().tpp());
  canEditCountry = ko.pureComputed(() => this.allowEditAny() && this.trial() && !this.trial().tpp());
  canEditProject = this.canEditCountry;
  canEditOwners = ko.pureComputed(
    () =>
      this.allowEditAny() ||
      (trialEditableByUser(this.trialWizard().userData, this.trial()) &&
        this.trial().state() === TrialState.Completed)
  );

  trial = ko.pureComputed(() => {
    return this.trialWizard().trial();
  });

  canEditFromTemplate = ko.pureComputed(() => {
    return !APP_CONFIG.TRIAL_FIELDS_SPECIAL || !this.trial().createdFromTemplateId;
  });

  private trialSubs: KnockoutSubscription[] = [];
  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { controller: WizardController }) {
    super(params);

    this.maybeCreateDataset();
    this.initialTrialState(this.trial().state());
    this.setupTrial();
    this.subscriptions.push(this.trial.subscribe(this.setupTrial.bind(this)));

    this.ready(true);
  }

  dispose() {
    this.trialSubs.forEach((sub) => sub.dispose());
    this.subscriptions.forEach((sub) => sub.dispose());
  }

  /**
   * Create single empty dataset if no datasets and not library mode
   */
  private maybeCreateDataset() {
    if (this.trialWizard().datasets().length > 0 || this.trialWizard().editMode === 'library') {
      return;
    }

    let dataset = new DatasetWizard(this.trialWizard());
    this.trialWizard().datasets.push(dataset);
  }

  private setupTrial() {
    let trial = this.trial();

    // keep value 5 only for trials that have it already set (legacy)
    if (trial && trial.requiredLocationAccuracy() === '5') {
      this.locationAccuracies(getLocationAccuracies());
    } else {
      this.locationAccuracies(getLocationAccuracies().filter((acc) => acc.value !== '5'));
    }

    this.setupTrialSubs(trial);

    let cropSearch = getCropSearchConfig(trial.crop);
    cropSearch.confirmEditEntity = () => {
      return this.trialWizard().confirmChangeAffectingPlots(i18n.t(['crop_lowercase', 'crop'])());
    };

    this.cropSearchConfig(cropSearch);
    this.cultivationTypeSearchConfig(
      getSystemDimensionSearchConfig(
        i18n.t('Cultivation Type')(),
        trial.cultivationType,
        dimensionMetasApi.CULTIVATION_TYPE_SLUG
      )
    );
    this.fieldPreparationTypeSearchConfig(
      getSystemDimensionSearchConfig(
        i18n.t(['field_preparation_type_title', 'Field Preparation Type'])(),
        trial.fieldPreparationType,
        dimensionMetasApi.FIELD_PREPARATION_TYPE_SLUG
      )
    );
    this.irrigationTypeSearchConfig(
      getSystemDimensionSearchConfig(
        i18n.t(['irrigation_type_title', 'Irrigation Type'])(),
        trial.irrigationType,
        dimensionMetasApi.IRRIGATION_TYPE_SLUG
      )
    );
    this.trialTypeSearchConfig(getTrialTypeSearchConfig(trial.trialType));
  }

  private setupTrialSubs(trial: Trial) {
    this.trialSubs.forEach((sub) => sub.dispose());
    this.trialSubs = [];
    this.trialSubs.push(trial.tpp.subscribe(this.onTPPChanged));
  }

  private onTPPChanged = (tpp: tppsApi.TPPListData) => {
    // NOTE: it's safe to change crop because the user can't select a TPP that has a different crop,
    // (and the crop can't be edited when a tpp is already set),
    // unless the trial crop is empty, in which case it's safe to change it
    if (tpp) {
      // avoid triggering crop change callbacks when it's the same,
      // it would reset crop varieties
      if (!this.trial().crop() || tpp.crop.id !== this.trial().crop().id) {
        this.trial().crop(tpp.crop);
      }
      this.trial().country(tpp.country);
      this.trial().project(tpp.project);
    }
  };

  hasErrors() {
    let trial = this.trial();
    let errors = ko.validation.group([
      trial.nameJson,
      trial.nameSuffix,
      trial.nameSlug,
      trial.owners,
      trial.crop,
      trial.trialType,
      trial.scheduledPlantingDate,
      trial.country,
      trial.agroRegion,
      trial.season,
      trial.isCommercial,
      trial.customers,
      trial.descriptionJson,
      trial.protocol,
      trial.region,
      trial.cultivationType,
      trial.plants,
      trial.fieldPreparationType,
      trial.spaceBetweenRows,
      trial.spaceInsideRows,
      trial.squareMetersPerPlot,
      trial.rowsPerPlot,
      trial.border,
      trial.fieldSelection,
      trial.plotLength,
      trial.plotWidth,
      trial.irrigationType,
    ]);

    return (
      errors().length > 0 ||
      !!trial.nameJson.serverError() ||
      !!trial.nameSlug.serverError() ||
      !!trial.plotDesign.serverError() ||
      !!trial.descriptionJson.serverError()
    );
  }

  clearServerErrors() {
    this.clearModelServerErrors(this.trial());
  }

  applyServerErrors(errors: any) {
    let trialErrors = errors['trial'] || {};

    this.applyModelServerErrors(this.trial(), trialErrors);
  }
}

ko.components.register('trial-basic-data', {
  viewModel: TrialBasicData,
  template: template,
});
