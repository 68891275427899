import * as ko from 'knockout';

type ValidationError = {
  elementToScroll: Element;
  elementToFocus: HTMLElement;
  topCoordinate?: number; // scrollToTop
  leftCoordinate?: number; // scrollToLeft
  uniqueID?: string; // id for find and remove elements
};
export const validationErrorScroller = ko.observableArray<ValidationError>([]);
