import * as ko from 'knockout';
import i18n from '../../i18n';

import { DimensionsTableConfig } from '../../components/dimensions_table';
import { BaseTrialStep } from './base';
import { WizardController } from '../../screens/trial_wizard';
import { openImportSites } from './trial_sites_import';
import { LimitToDimension } from '../../models/dataset_dimension_meta';
import { canAddSites } from '../../permissions';
import { session } from '../../session';

let template = require('raw-loader!../../../templates/components/trial_wizard/trial_sites.html').default;

class TrialSites extends BaseTrialStep {
  private canDisable = ko.pureComputed<boolean>(
    () => this.trialWizard()?.trial().allowDisableSites() ?? false
  );

  // @ts-ignore
  private title = session.isTreatmentManagementEnabledForTrial(this.trialWizard().trial()) ?
    i18n.t('Step 4 - Select the trial sites')() : i18n.t('Step 3 - Select the trial sites')()

  constructor(params: { controller: WizardController }) {
    super(params);
    this.ready(true);
  }

  hasErrors(): boolean {
    return this.trialWizard().sites().length === 0;
  }

  canAddSites = ko.pureComputed(() => canAddSites(this.trialWizard().userData, this.trialWizard().trial()));

  sitesConfig = ko.pureComputed<DimensionsTableConfig>(() => {
    return {
      user: this.trialWizard().userData,

      canReorder: false,
      addDimensionText: i18n.t('Search sites or create a new one')(),
      dimensionMeta: this.trialWizard().siteDM,
      dimensions: this.trialWizard().sites,
      allowAnonymize: false,
      allowEditControl: false,
      allowEditDisable: this.canDisable,
      allowEditOptional: false,
      allowEdit: this.allowEdit,
      allowEditAny: this.canAddSites,
      enableEditDimensionMeta: this.allowEditAny(),
      country: this.trialWizard().trial().country,
      region: this.trialWizard().trial().region,

      confirmChangeEntities: () => {
        this.confirmChange();
        return Promise.resolve(null);
      },
    };
  });

  private confirmChange() {
    // it's always safe to add/remove sites, but we must regenerate the plots
    // to get the new site plots
    if (this.trialWizard().forceRegeneratePlots() !== 'full') {
      this.trialWizard().forceRegeneratePlots('new_sites');
    }
  }

  openImportSites = async () => {
    let sites = await openImportSites();
    if (sites.length === 0) {
      return;
    }

    let wizard = this.trialWizard();
    const existingIds = new Map<string, LimitToDimension>();
    for (let site of wizard.sites()) {
      existingIds.set(site.id(), site);
    }
    for (let site of sites) {
      if (existingIds.has(site.id)) {
        existingIds.get(site.id).initFromData(site);
      } else {
        wizard.sites.push(new LimitToDimension(wizard, wizard.siteDM, site));
      }
    }
    this.confirmChange();
  };
}

ko.components.register('trial-sites', {
  viewModel: TrialSites,
  template: template,
});
