import * as ko from 'knockout';

import { BaseForm } from './base_form';
import * as customerProfilesApi from '../api/customer_profiles';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';
import { CustomerProfile } from '../models/customer_profile';

let template = require('raw-loader!../../templates/customer_profile_edit.html').default;

class CustomerProfileEditScreen extends BaseForm<customerProfilesApi.CustomerProfileData> {
  entity = ko.validatedObservable<CustomerProfile>(null);

  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<customerProfilesApi.CustomerProfileData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let requestPromise = params.id ? customerProfilesApi.retrieve(params.id) : undefined;
    let promise = Promise.all([requestPromise]).then(([data]) => {
      this.entity(new CustomerProfile(data));
      if (!data && params.initialName) {
        this.entity().nameJson(asI18nText(params.initialName));
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(customerProfilesApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let customerProfileEdit = {
  name: 'customer-profile-edit',
  viewModel: createWithComponent(CustomerProfileEditScreen),
  template: template,
};

ko.components.register(customerProfileEdit.name, customerProfileEdit);
