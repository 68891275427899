import * as ko from 'knockout';
import page from 'page';
import i18n from '../i18n';

import { session } from '../session';

let template = require('raw-loader!../../templates/welcome.html').default;

class WelcomeScreen {
  playstoreUrl = SERVER_INFO.PLAY_STORE_URL;

  title = i18n.t('Welcome to {{ productName }}', {
    productName: SERVER_INFO.PRODUCT_NAME,
  });

  constructor() {
    localStorage.setItem('welcomeTutorialShown', '1');
  }

  closeWindow = () => {
    page.redirect(session.toTenantPath('/'));
  };
}

export let welcome = {
  name: 'welcome',
  viewModel: WelcomeScreen,
  template: template,
};

ko.components.register(welcome.name, welcome);
