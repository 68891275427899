import * as ko from 'knockout';

import { StageChangeChartData } from '../api/overview';
import { MaybeKO } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/stage_change_chart.html').default;

class StageChangeChart {
  data: MaybeKO<StageChangeChartData>;

  constructor(public params: { data: MaybeKO<StageChangeChartData> }) {
    this.data = params.data;
  }
}

const stageChangeChart = {
  name: 'stage-change-chart',
  viewModel: StageChangeChart,
  template: template,
};

ko.components.register(stageChangeChart.name, stageChangeChart);
