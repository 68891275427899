import * as ko from 'knockout';

import * as regionsApi from '../api/regions';
import { I18nText } from '../i18n_text';
import { CountryData } from '../api/countries';
import { getCountrySearchConfig } from '../components/configs/search_configs';

export class Region {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  country = ko.observable<CountryData>(null);
  code = ko.observable('').extend({ serverError: true });

  countrySearchConfig = getCountrySearchConfig(this.country);

  editUrl = ko.pureComputed(() => {
    return '/regions/' + this.id() + '/';
  });

  constructor(data?: regionsApi.RegionData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.country(data.country);
      this.code(data.code);
    }
  }

  toData(): regionsApi.RegionData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      country: this.country(),
      code: this.code(),
    };
  }
}
