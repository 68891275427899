import { makeDefaultApi, request, requestRaw, requestWithValidation, ValidationResult } from './request';
import { I18nText } from '../i18n_text';
import { CountryData } from './countries';
import { AgroRegionData } from './agro_regions';
import { CropVarietyData } from './crop_varieties';
import { MeasurementMetaData } from './datasets';
import { CropData } from './crops';
import { NameData } from './names';
import { FileUploadEndpoint } from '../cloud_storage_upload';
import { TrialTypeData } from './trial_types';
import { RegionData } from './regions';
import { MeasurementChoiceData } from './measurement_types';
import * as queryString from 'query-string'
import { TrialStatsType } from './dashboard';

export type TPPTraitRequired = '<' | '≤' | '=' | '>' | '≥';
export type TPPTraitPreferenceGroup = 'M' | 'W' | 'Y' | 'ALL';
export type TPPTraitDemandClassification = '1' | '2' | '3' | '4' | '';

export interface TPPTraitData {
  id?: string;
  measurement_meta: MeasurementMetaData;
  trial_types: TrialTypeData[];
  preference_group: TPPTraitPreferenceGroup;
  indicative_target_value: string; // decimal
  display_range_min: string; // decimal
  display_range_max: string; // decimal
  indicative_target_choice_id: string;
  classification: number | string;
  benchmark_varieties: CropVarietyData[];
  required: TPPTraitRequired;
}

export interface TPPTeamMemberData {
  id?: string;
  name: string;
  area_of_expertise: string;
  organization: string;
  order: number;
}

export type TPPState = 'active' | 'discarded' | 'completed';
export type TPPMarketTrend = '' | 'small_inc' | 'large_inc' | 'small_stable' | 'large_stable' | 'unknown';
export type NamedRecord = Record<'id' | 'name', string>;

export interface TPPListData {
  id?: string;
  name_json: I18nText;
  author: { id?: string; name: string };
  country: CountryData;
  regions: RegionData[]; // detail-only
  crop: CropData;
  market_positioning: string;
  market_scale: string[];
  crop_use: string;
  crop_characteristics: string;
  processing_type: string;
  target_agro_regions: AgroRegionData[];
  market_size_segment: string; // decimal in a string
  market_size_segment_actual: string; // decimal in a string
  market_trend: TPPMarketTrend;
  seed_price: number | string;
  planting_rate: number | string;
  typical_yield_range_segment_min: number | string; // decimal in a string
  typical_yield_range_segment_max: number | string; // decimal in a string
  actual_grain_production_segment: number | string;
  rate: string; // decimal in a string
  crop_range: string;
  competition_strength: string;
  dominant_varieties: CropVarietyData[];
  target_n_varieties: number | string;
  project: NameData;
  market_info_url: string;
  market_info_file_name: string;
  market_info_user_file_name: string;
  market_info_file_url?: string; // read-only, detail-only
  farmer_groups: string;
  n_farmers_min: number | string;
  n_farmers_max: number | string;
  female_farmers_perc_min: number | string;
  female_farmers_perc_max: number | string;
  production_system: string[];
  cropping_system: string[];
  crop_protection_expected_input_level: string;
  fertilizer_expected_input_level: string;
  growth_habit: string;
  mechanization_segment: string;
  state: TPPState;
  traits: TPPTraitData[];
  state_change: string; // date in a string
  created?: string; // read-only
  modified?: string; // read-only
  n_trials?: number; // read-only, list-only
  n_test_varieties?: number; // read-only, list-only
  collected_observations?: number; // read-only, list-only
}

export interface CVStageData {
  cv_id: string;
  stage_name: string;
}

export interface TPPData extends TPPListData {
  external_id?: string;
  team: TPPTeamMemberData[];
  trial_cv_stages?: CVStageData[]; // read-only
}

export const { list, retrieve, save, remove } = makeDefaultApi<
  TPPListData,
  { country_ids?: string[]; crop_ids?: string[] },
  TPPData
>('tpps');

export interface TPPRadarData {
  traits: TPPTraitRadarData[];
}

export interface TPPTraitRadarData {
  id: string;
  trait_name?: string; // read-only
  trait_type?: 'rating' | 'qualitative' | 'numeric'; // read-only
  trait_choices?: MeasurementChoiceData[]; // read-only
  rating_min?: number; // read-only
  rating_max?: number; // read-only
  indicative_target_value: string; // decimal
  display_range_min: string; // decimal
  display_range_max: string; // decimal
  indicative_target_choice_id: string;
}

export function getTPPRadarTraits(tppId: string): Promise<TPPRadarData> {
  return request('GET', `/api/tpps/${tppId}/radar_traits/`);
}

export function updateTPPRadarTraits(tppId: string, data: TPPRadarData): Promise<ValidationResult> {
  return requestWithValidation('POST', `/api/tpps/${tppId}/update_radar_traits/`, data);
}

export interface TPPStage {
  id: string;
  name: string;
  crop_varieties: {
    id: string;
    name: string;
  }[];
}

export function stages(tppId: string): Promise<TPPStage[]> {
  return request('GET', '/api/tpps/' + tppId + '/stages/');
}

export interface ScorecardValue {
  aggregated: string;
  raw: {value: number, repetition: string}[];
}

export interface TechnicalScorecardData {
  traits: TechnicalScorecardTraitData[];
  external_benchmarks: NamedRecord[];
  trials: TechnicalScorecardTrialData[];
}

export interface TraitDataResponse {
  trait_name: string;
  trait_id: number;
  values?: TraitForPlotEntity[] | null;
}

export interface TraitCompareToControlResponse {
  trait_name: string;
  trait_id: number;
  max_value?: number;
  min_value?: number;
  unique_test_subject_dimensions?: string[][];
  site_dimension_name: string;
  values?: TraitForPlotEntity[] | null;
}

export interface TraitForPlotEntity {
  site_id: string;
  site_name: string;
  site_alias?: string;
  value: number | string;
  repetition_id?: string | null;
  repetition_name?: string | null;
  dimensions: {name: string, dimension_meta_name: string, control: boolean} [];
  dimensions_ids?: string[];
}

export interface InteractionPlotDimension {
  name: string;
  control: boolean;
  dimension_id: string;
}

export interface TraitDataInteractionPlot {
  trait_name: string;
  trait_id: number;
  tpp_target: string;
  values?: {
    site_id: string;
    site_name: string;
    value: number;
    dimensions: InteractionPlotDimension [];
    lsd_labels?: [string];
  }[];
  site_dimension_name: string;
  trial_test_subject_dimension_metas?: {
    id: number;
    name: string;
    index: number;
  }[];
}

export interface TechnicalScorecardTraitData {
  measurement_meta_id: string;
  name: string;
  indicative_target_value: number;
  indicative_target_choice_value: number;
  display_range_min: number;
  display_range_max: number;
  rating_min: number;
  rating_max: number;
  required: string;
  invert: boolean;
  is_qualitative: boolean;
  internal_benchmarks: NamedRecord[];
}

export interface TechnicalScorecardTrialData {
  id: string;
  name: string;
  can_add_sites: boolean;
  sites: {
    id: string;
    name: string;
    values: { [cv_id: string]: ScorecardValue[] };
    lsd_values?:
      | {
          value?: number | null;
          min?: number | null;
          max?: number | null;
        }
      | any;
    standard_deviation: any;
    lsd_labels?: {
      [cv_id: string]: { [cv_id: string]: string } | any;
    }[];
  }[];
  internal_benchmark_values: ScorecardValue[][]; // one array per trait
  external_benchmark_values: ScorecardValue[][];
  ge_p_value: { p_value: number; cv_id?: string }[][];
  g_p_value: {
    cv_id?: string;
    p_value: number;
    site: string;
  }[][];
  cv_ids: string[];
  precision: [];
  crop_varieties?: NamedRecord[];
  anova_type?: TrialStatsType;
}

export function technicalScorecard(tppId: string, params: Record<string, any>): Promise<TechnicalScorecardData> {
  return request('GET', '/api/tpps/' + tppId + `/technical_scorecard/?${queryString.stringify(params)}`);
}

export function fetchTechnicalScorecardTraits(tppId: string): Promise<any> {
  return request('GET', `/api/tpps/${tppId}/technical_scorecard_traits/`);
}

export function fetchTechnicalScorecardDefaultTraits(tppId: string): Promise<any> {
  return request('GET', `/api/tpps/${tppId}/technical_scorecard_default_traits/`);
}

export function tppPDF(id: string): Promise<Blob> {
  return requestRaw('GET', '/api/tpps/' + id + '/pdf/');
}

export function tppUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
  return request('POST', '/api/tpps/market_info_file_upload_url/', {
    content_type: contentType,
  });
}

export function exportProductProfile(id: string): Promise<Blob> {
  return requestRaw('GET', `/api/tpps/${id}/product_profile/`);
}
