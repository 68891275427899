import * as ko from 'knockout';

import i18n from '../i18n';
import * as trialsApi from '../api/trials';
import { translate } from '../i18n_text';
import { downloadBlob } from '../utils';
import { BaseLoadingScreen } from './base_loading_screen';

let template = require('raw-loader!../../templates/trial_export_plots.html').default;

class TrialExportPlotsScreen extends BaseLoadingScreen {
  private trial: trialsApi.TrialData;

  preparingExportText = i18n.t('Preparing export...')();
  loadingExport = ko.observable(false);
  name = ko.observable('');

  constructor(params: { id: string }) {
    super();

    let promise = trialsApi.retrieve(params.id).then((trialData) => {
      this.trial = trialData;
      this.name(translate(trialData.name_json));
    });
    this.loadedAfter(promise);
  }

  goBack = () => {
    history.back();
  };

  downloadExport = () => {
    this.loadingExport(true);

    trialsApi
      .exportPlots(this.trial.id)
      .then((data) => {
        this.loadingExport(false);
        downloadBlob(data, translate(this.trial.name_json) + ' (plots).xlsx');
      })
      .catch((e) => {
        this.loadingExport(false);
        throw e;
      });
  };
}

export let trialExportPlots = {
  name: 'trial-export-plots',
  viewModel: TrialExportPlotsScreen,
  template: template,
};

ko.components.register(trialExportPlots.name, trialExportPlots);
