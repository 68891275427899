import { ListRequestParams, listRequest, makeDefaultApi } from './request';
import { NameData } from './names';
import { SeasonData } from './seasons';
import { UserData, UserRole } from './users';
import { UserLogMixinData } from './changelog';

export interface UserCountryData {
  user: UserData;
  role: UserRole;
}

export interface CountryData extends UserLogMixinData {
  id: string;
  name: string;
  iso_country_code: string;
  regions: NameData[];
  // detail-only
  season_1?: SeasonData;
  month_of_harvest_1?: number;
  season_2?: SeasonData;
  month_of_harvest_2?: number;
  season_3?: SeasonData;
  month_of_harvest_3?: number;
  season_4?: SeasonData;
  month_of_harvest_4?: number;
  season_5?: SeasonData;
  month_of_harvest_5?: number;
  user_roles?: UserCountryData[];
}

export const countriesApi = makeDefaultApi<CountryData, { ids?: string[] }>('countries');

export function listForTrials(params: ListRequestParams): Promise<CountryData[]> {
  return listRequest<CountryData>('/api/countries/for_trials/', params);
}
