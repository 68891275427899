import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { regRegionsApi, RegistrationRegionData } from '../api/registration_regions';
import { canEditRegistrationRegions } from '../permissions';

let template = require('raw-loader!../../templates/registration_regions.html').default;

class RegistrationRegionsScreen implements ListLoaderDelegate<RegistrationRegionData> {
  canAdd = canEditRegistrationRegions();

  fetch(params: ListRequestParams) {
    return regRegionsApi.list(params);
  }

  instantiate(regionData: RegistrationRegionData) {
    return regionData;
  }

  getEditUrl(data: RegistrationRegionData) {
    return '/registration_regions/' + data.id + '/';
  }

  remove(id: string) {
    return regRegionsApi.remove(id);
  }

  canRemove(region: RegistrationRegionData) {
    return canEditRegistrationRegions();
  }
}

export let registrationRegions = {
  name: 'registration-regions',
  viewModel: RegistrationRegionsScreen,
  template: template,
};

ko.components.register(registrationRegions.name, registrationRegions);
