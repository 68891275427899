import * as ko from 'knockout';

import { RegistrationPhaseData } from '../api/registration_phases';
import { RegistrationData, RegistrationHistoryData, RegistrationPhaseType } from '../api/registrations';
import { getPhaseSearchConfig } from '../components/configs/search_configs';
import { parseDate, serializeDate } from '../api/serialization';
import { translate } from '../i18n_text';

export class RegistrationHistory {
  id = ko.observable<string>(null);
  registration: RegistrationData;
  phase = ko.observable<RegistrationPhaseData>(null).extend({ required: true });
  changed = ko.observable<Date>(new Date()).extend({ required: true });
  isCurrent = false;
  comment = ko.observable<string>('');

  phaseSearchConfig = getPhaseSearchConfig(this.phase);

  constructor(registration: RegistrationData, data?: RegistrationHistoryData) {
    if (data) {
      this.id(data.id);
      this.phase(data.phase);
      this.changed(parseDate(data.changed));
      this.comment(data.comment);
      this.isCurrent = !!data.is_current;
    }
    this.registration = registration;
  }

  regionCountryName = ko.pureComputed(() => {
    if (!this.registration) {
      return '';
    }

    return this.registration.region
      ? translate(this.registration.region.name_json)
      : this.registration.country.name;
  });

  hasErrors() {
    return ko.validation.group(this)().length > 0;
  }

  showErrors() {
    ko.validation.group(this).showAllMessages();
  }

  toData(): RegistrationHistoryData {
    return {
      id: this.id(),
      registration: this.registration,
      phase: this.phase(),
      changed: serializeDate(this.changed()),
      comment: this.comment(),
    };
  }
}

export class RegionalRegistrationHistory extends RegistrationHistory {
  phaseSearchConfig = getPhaseSearchConfig(this.phase, null, RegistrationPhaseType.REGIONAL);
}

export class NationalRegistrationHistory extends RegistrationHistory {
  phaseSearchConfig = getPhaseSearchConfig(this.phase, null, RegistrationPhaseType.NATIONAL);
}
