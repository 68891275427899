import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import * as documentCategoriesApi from '../api/document_categories';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { canEditDocumentTags } from '../permissions';

let template = require('raw-loader!../../templates/document_categories.html').default;

class DocumentCategoriesScreen implements ListLoaderDelegate<documentCategoriesApi.DocumentCategoryData> {
  canEdit = canEditDocumentTags();
  listActions = ko.observableArray<ListHeaderAction>([]);

  constructor() {
    if (this.canEdit) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/document_tags/new/',
        tooltipTitle: i18n.t('Add Document Tag')(),
      });
    }
  }

  fetch(params: ListRequestParams) {
    return documentCategoriesApi.list(params);
  }

  instantiate(data: documentCategoriesApi.DocumentCategoryData) {
    return data;
  }

  remove(id: string) {
    return documentCategoriesApi.remove(id);
  }

  canRemove(data: documentCategoriesApi.DocumentCategoryData) {
    return this.canEdit;
  }
}

export let documentCategories = {
  name: 'document-categories',
  viewModel: DocumentCategoriesScreen,
  template: template,
};

ko.components.register(documentCategories.name, documentCategories);
