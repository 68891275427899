import * as ko from 'knockout';

import { DimensionMeta } from '../models/dimension_meta';
import { Stage } from '../models/stage';
import { CV_STAGE_SLUG } from '../api/dimension_metas';
import * as stagesApi from '../api/stages';
import { BaseDimensionRecordEditDelegate } from '../components/base_dimension_record_edit';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/stage_edit.html').default;

class StageEditScreen implements BaseDimensionRecordEditDelegate<stagesApi.StageData, Stage> {
  disableApplications = true;
  allowAnonymize = true;
  allowLinkingCrops = false;
  anyoneCanEdit = false;
  anonymizedCodeText = i18n.t('Code to use when partially anonymized')();

  dimensionMetaId = CV_STAGE_SLUG;
  dimensionId: string;
  result: Deferred<stagesApi.StageData>;
  private initialName: string;

  dimension = ko.observable<Stage>(null);

  constructor(params: { id: string; initialName?: string; result?: Deferred<stagesApi.StageData> }) {
    this.dimensionId = params.id;
    this.result = params.result;
    this.initialName = params.initialName;
  }

  fetch(dimensionMetaId: string, id: string) {
    return stagesApi.retrieve(id);
  }

  instantiate(data: stagesApi.StageData, dimensionMeta: DimensionMeta) {
    let stage = new Stage(data, dimensionMeta);
    if (!data) {
      if (this.initialName) {
        stage.nameJson(asI18nText(this.initialName));
      }
    }

    return stage;
  }

  saveRequest(data: stagesApi.StageData) {
    return stagesApi.save(data);
  }
}

export let stageEdit = {
  name: 'stage-edit',
  viewModel: StageEditScreen,
  template: template,
};

ko.components.register(stageEdit.name, stageEdit);
