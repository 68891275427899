import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { User, ftUserOptions } from '../models/user';
import * as usersApi from '../api/users';
import * as groupsApi from '../api/groups';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import i18n from '../i18n';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { session } from '../session';
import { confirmGroupSelection } from '../utils/plot_count_utils';
import { canEditUsers } from '../permissions';

let template = require('raw-loader!../../templates/user_edit.html').default;

class UserEditScreen extends BaseForm<usersApi.UserData> {
  isAdmin = session.isAdmin;
  canEdit = ko.observable(canEditUsers());

  user = ko.validatedObservable<User>(null);
  globalError = ko.observable('');
  languageList = SERVER_INFO.LANGUAGE_LIST;
  allowPasswordLogin = session.tenant()?.allow_password_login;

  groupSearchConfig = ko.pureComputed(() => {
    let config: FormSelectSearchConfiguration<groupsApi.BaseGroupData> = {
      title: i18n.t(['groups_colons', 'Groups:'])(),
      getSummaryName: (group) => {
        return group.name;
      },

      list: (params) => {
        return groupsApi.list(params && { include_trial_plot_counts: true });
      },

      entities: this.user() ? this.user().groups : undefined,

      create: { title: 'Group', componentName: 'group-edit' },
    };

    return config;
  });

  private subscriptions: KnockoutSubscription[] = [];

  constructor(
    params: {
      id: string;
      initialName?: string;
      result?: Deferred<usersApi.UserData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    if (this.user() && this.user().groups) {
      this.subscriptions.push(this.user().groups.subscribe(this.onGroupsChanged, null, 'arrayChange'));
    }

    let userDataPromise = params.id ? usersApi.retrieve(params.id) : undefined;
    let promise = Promise.all([userDataPromise]).then(([userData]) => {
      this.user(new User(ftUserOptions, userData));

      if (!userData && params.initialName) {
        this.user().name(params.initialName);
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    this.globalError('');

    if (this.validateLocal(this.user)) {
      let data = { ...this.user().toData() };
      this.executeSaveRequest(usersApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.user(), validation);

        if (!validation.isValid && validation.errors['__all__']) {
          this.globalError(validation.errors['__all__'].join('. '));
        }
      });
    }
  };

  private onGroupsChanged = (changes: ko.utils.ArrayChange<groupsApi.BaseGroupData>[]) => {
    const onCancelGroupSelection = () => this.user().groups.pop();
    changes.forEach(function (change) {
      if (change.status === 'added') {
        confirmGroupSelection(change.value, onCancelGroupSelection);
      }
    });
  };

  dispose() {
    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }

    this.subscriptions = [];
  }
}

export let userEdit = {
  name: 'user-edit',
  viewModel: createWithComponent(UserEditScreen),
  template: template,
};

ko.components.register(userEdit.name, userEdit);
