import { makeDefaultApi } from "./request";

export interface PortofolioItemData {
    id: string;
    number: string;
    name: string;
    from_date: string;
    to_date: string;
    status: string;
}

export const portofolioItemApi = makeDefaultApi<PortofolioItemData, { ids?: string[] }>('portofolio_items');