import * as ko from 'knockout';

import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { TrialSelectData } from '../api/base_trials';
import * as trialsApi from '../api/trials';
import * as usersApi from '../api/users';
import { BaseLoadingScreen } from './base_loading_screen';
import { GeoJSON } from '../api/datasets';
import { serializeDate } from '../api/serialization';
import { factSummaryToGeoJSON } from '../components/facts_table';

let template = require('raw-loader!../../templates/map_activities.html').default;

class MapActivitiesScreen extends BaseLoadingScreen {
  loadingMap = ko.observable(false);

  fromDate = ko.observable<Date>(new Date(new Date().getFullYear(), 0, 1));
  toDate = ko.observable<Date>(new Date(new Date().getFullYear(), 11, 31));
  trial = ko.observable<TrialSelectData>();
  mapPoints = ko.observableArray<GeoJSON>();

  trialSearchConfig: FormSelectSearchConfiguration<TrialSelectData> = {
    getSummaryName: (trial) => {
      return trial.name_json;
    },

    list: (params) => {
      return trialsApi.listForSelect({
        template: false,
        crop_ids: [],
        trial_type_ids: [],
        ...params,
      });
    },

    entity: this.trial,
  };

  private user: usersApi.UserData;
  private subs: KnockoutSubscription[] = [];

  constructor(params: { id: string }) {
    super();

    let promise = usersApi.retrieve(params.id).then((userData) => {
      this.user = userData;
      this.subs.push(this.fromDate.subscribe(this.load));
      this.subs.push(this.toDate.subscribe(this.load));
      this.subs.push(this.trial.subscribe(this.load));
    });
    this.loadedAfter(promise);
    promise.then(this.load);
  }

  private load = () => {
    this.loadingMap(true);
    usersApi
      .listMapFactSummaryData(this.user.id, this.toData())
      .then((facts) => {
        this.loadingMap(false);
        this.mapPoints(factSummaryToGeoJSON(facts));
      })
      .catch((e) => {
        this.loadingMap(false);
        throw e;
      });
  };

  toData(): usersApi.UserMapFactSummaryParams {
    return {
      from_date: serializeDate(this.fromDate()),
      to_date: serializeDate(this.toDate()),
      trial_id: this.trial() ? this.trial().id : null,
    };
  }
}

export let mapActivities = {
  name: 'map-activities',
  viewModel: MapActivitiesScreen,
  template: template,
};

ko.components.register(mapActivities.name, mapActivities);
