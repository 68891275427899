import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { countriesApi, CountryData } from '../api/countries';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { Country } from '../models/country';
import { session } from '../session';

let template = require('raw-loader!../../templates/country_edit.html').default;

class CountryEditScreen extends BaseForm<CountryData> {
  showRegions = session.isS2bim();
  country = ko.observable<Country>(null);

  constructor(
    params: { id: string; initialName: string; result?: Deferred<CountryData> },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let request = params.id ? countriesApi.retrieve(params.id) : undefined;
    let promise = Promise.all([request]).then(([data]) => {
      this.country(new Country(data));
      if (!data && params.initialName) {
        this.country().name(params.initialName);
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (!this.country()) {
      return;
    }

    if (this.validateLocal(this.country)) {
      let data = this.country().toData();
      this.executeSaveRequest(countriesApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.country(), validation);
      });
    }
  };

  dispose = () => {
    this.country().dispose();
  }
}

export let countryEdit = {
  name: 'country-edit',
  viewModel: createWithComponent(CountryEditScreen),
  template: template,
};

ko.components.register(countryEdit.name, countryEdit);
