import * as ko from 'knockout';
import i18n from '../i18n';

import { MaybeKO, asObservable } from '../utils/ko_utils';
import { app } from '../app';
import { ListRequestParams } from '../api/request';
import { Deferred } from '../utils/deferred';
import { I18nText, translate } from '../i18n_text';
import { MAX_SAFE_INTEGER } from '../utils';

let formSelectSearchTemplate =
  require('raw-loader!../../templates/components/form_select_search.html').default;

interface IdData {
  id?: string | KnockoutObservable<string>;
}

export interface FormSelectCreate<TData, T> {
  title: string;
  componentName: string;
  extraParams?: {};
  instantiate?: (data: TData) => T;
  insert?: (value: T) => void;
  insertAll?: (values: T[]) => void;
}

export interface FormSelectSearchConfiguration<T extends IdData> {
  list: (params: ListRequestParams) => Promise<T[]>;

  resultsFilter?: (results: T[]) => T[];
  enableAddAll?: boolean;
  manuallyManageEntities?: boolean;
  entities?: KnockoutObservableArray<T>;
  entity?: KnockoutObservable<T>;

  title?: ko.Computed<string> | MaybeKO<string>;
  getSummaryName(entity: T): string | I18nText;
  getSelectedEntityClass?(entity: T): string;

  create?: FormSelectCreate<IdData, T>;

  confirmChangeEntities?: () => Promise<{}>;
  confirmEditEntity?: () => Promise<{}>;

  advancedSearch?: {
    componentName: string;
    extraParams?: {};
    instantiate: (data: IdData) => T;
    serialize: (instance: T) => IdData;
  };
}

interface FormSelectSearchInputParams<T extends IdData> {
  config: MaybeKO<FormSelectSearchConfiguration<T>>;
  enable?: KnockoutObservable<boolean>;
  enableRemove?: KnockoutObservable<boolean>;
  icon?: MaybeKO<string>;
}

class FormSelectSearchInput<T extends IdData> {
  htmlId = ko.observable<string>('');
  searchTerm = ko.observable('').extend({
    rateLimit: {
      timeout: 250,
      method: 'notifyWhenChangesStop',
    },
  });
  isSearching = ko.observable(false);
  addRemoveLock = false; // guards add/remove against focus handlers
  hasMoreResults = ko.observable(false);
  loading = ko.observable(false);
  config: KnockoutObservable<FormSelectSearchConfiguration<T>>;
  enable: KnockoutObservable<boolean>;
  enableRemove: KnockoutObservable<boolean>;
  icon: KnockoutObservable<string>;

  validationTarget = ko.observable<KnockoutObservable<any>>(null);

  lastSearchId = 0;

  private configSubscriptions: KnockoutSubscription[] = [];
  private subscriptions: KnockoutSubscription[] = [];

  isExactMatch = ko.pureComputed(() => {
    return this._isExactMatch(this.searchTerm());
  });

  searchResults = ko.observableArray<T>();

  isEntitySelected = (entity: IdData) => {
    if (!this.config().entities) {
      return false;
    }

    let found = false;

    this.config()
      .entities()
      .forEach((e) => {
        if (ko.unwrap(entity.id) === ko.unwrap(e.id)) {
          found = true;
          return;
        }
      });

    return found;
  };

  // searchResults minus already selected entities
  notSelectedSearchResults = ko.pureComputed(() => {
    return this.searchResults().filter((e) => !this.isEntitySelected(e));
  });

  isSearchTermInvalid = ko.pureComputed(() => {
    return this.searchTerm() != null && this.searchTerm().trim() != '' && this.searchResults().length === 0;
  });

  constructor(params: FormSelectSearchInputParams<T>, componentInfo: KnockoutComponentTypes.ComponentInfo) {
    let element = <Element>componentInfo.element;

    this.htmlId(element.getAttribute('input-id'));
    this.enable = params.enable || ko.observable(true);
    this.enableRemove = params.enableRemove || ko.observable(true);
    this.icon = asObservable(params.icon);
    if (!this.icon()) {
      this.icon('add');
    }

    this.config = asObservable(params.config);
    this.subscriptions.push(this.config.subscribe(this.setupConfig.bind(this)));
    this.setupConfig(ko.unwrap(params.config));

    this.subscriptions.push(this.searchTerm.subscribe(this.search));
    this.subscriptions.push(this.isSearching.subscribe(this.onSearchingStateChanged));
  }

  private setupConfig(config: FormSelectSearchConfiguration<T>) {
    for (let subscription of this.configSubscriptions) {
      subscription.dispose();
    }
    this.configSubscriptions = [];

    if (config.entity) {
      this.onEntityChanged(config.entity());
      this.configSubscriptions.push(config.entity.subscribe(this.onEntityChanged));
    }

    this.validationTarget(config.entity || config.entities);
  }

  dispose() {
    for (let subscription of this.configSubscriptions) {
      subscription.dispose();
    }

    for (let subscription of this.subscriptions) {
      subscription.dispose();
    }
  }

  static createViewModel<T extends IdData>(
    params: FormSelectSearchInputParams<T>,
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    return new FormSelectSearchInput(params, componentInfo);
  }

  onEntityChanged = (entity: T) => {
    if (entity) {
      this.searchTerm(this.name(entity));

      let obsv = this.config().entity;
      if (obsv && obsv.serverError) {
        obsv.serverError(null);
      }
    } else {
      this.searchTerm('');
    }
  };

  clearSearchTerm = () => {
    let config = this.config();

    if (config.entity) {
      if (config.confirmEditEntity) {
        config.confirmEditEntity().then(() => {
          config.entity(null);
          this.searchTerm('');
        });
        return;
      } else {
        config.entity(null);
      }
    }
    this.searchTerm('');
  };

  search = () => {
    this.findFirstEntitiesMatchingTerm(this.searchTerm());
  };

  /**
   * Handle focus changes of the search input.
   *
   * When the input is focused we immediately begin searching.
   * When the input is blurred we do some housekeeping to ensure consistent
   * state.
   */
  onSearchingStateChanged = (isSearching: boolean) => {
    if (isSearching) {
      this.search();
    }

    // make sure we continue only if there is entity, search is not in
    // progress and add/remove is not in progress
    if (!this.config().entity || isSearching || this.addRemoveLock) {
      return;
    }

    let term = this.searchTerm();

    if (this._isExactMatch(term)) {
      return;
    }

    if (this.config().confirmEditEntity) {
      this.config()
        .confirmEditEntity()
        .then(() => {
          this.updateEntityBySearchTerm(term);
        })
        .catch(() => {
          this.resetSearchTerm();
        });
    } else {
      this.updateEntityBySearchTerm(term);
    }
  };

  private updateEntityBySearchTerm(term: string) {
    if (term.trim() === '') {
      this.config().entity(null);
    }

    let entities = this.searchResults();

    if (entities.length === 1 && this.name(entities[0]) === term) {
      this.config().entity(entities[0]);
    } else {
      this.config().entity(null);
      this.searchTerm('');
    }
  }

  private findFirstEntitiesMatchingTerm(term: string): Promise<void> {
    if (this._isExactMatch(term)) {
      this.searchResults([this.config().entity()]);
      this.hasMoreResults(false);
      return Promise.resolve(null);
    }

    term = term.trim().toLocaleLowerCase();

    let config = this.config();
    let limit = config.enableAddAll ? MAX_SAFE_INTEGER - 1 : 100;

    let results = config.list({
      offset: undefined,
      limit: limit + 1,
      name_prefix: term,
    });

    this.loading(true);

    let lastSearchId = ++this.lastSearchId;

    return results.then((results) => {
      if (this.lastSearchId != lastSearchId) {
        return;
      }

      if (config.resultsFilter) {
        results = config.resultsFilter(results);
      }

      this.loading(false);

      if (results.length > limit) {
        results = results.slice(0, limit);
        this.hasMoreResults(true);
      } else {
        this.hasMoreResults(false);
      }
      this.searchResults(results);
    });
  }

  private _isExactMatch(term: string) {
    let config = this.config();
    return config.entity && config.entity() && this.name(config.entity()) === term;
  }

  canCreateEntity() {
    return !!this.config().create;
  }

  createEntity = () => {
    let initialName = this.searchTerm().trim(); // grab name before blur() makes it go away
    $('input:focus').blur();

    this.addRemoveLock = true;

    let selectCreatedEntity = (entity: T) => {
      this.addEntity(config.create.instantiate ? config.create.instantiate(entity) : entity);
    };

    let config = this.config();
    let promise = app.formsStackController.push({
      title: config.create.title,
      name: config.create.componentName,
      params: $.extend(
        {
          initialName,
          result: new Deferred<T>(),
          selectCreatedEntity: selectCreatedEntity,
        },
        config.create.extraParams
      ),
    });

    promise
      .then((entity: T) => {
        this.addRemoveLock = false;
        selectCreatedEntity(entity);
      })
      .catch(() => {
        this.resetSearchTerm();
        this.addRemoveLock = false;
      });
  };

  hasAdvancedSearch() {
    return !!this.config().advancedSearch;
  }

  showAdvancedSearch = () => {
    $('input:focus').blur();

    this.addRemoveLock = true;

    let config = this.config();
    let promise = app.formsStackController.push({
      title: i18n.t('Advanced search')(),
      name: config.advancedSearch.componentName,
      isBig: true,
      params: $.extend(
        {
          initialName: this.searchTerm().trim(),
          allowMultipleSelections: !!config.entities,
          initialMultipleSelections: config.entities
            ? config.entities().map(config.advancedSearch.serialize)
            : [],
          result: new Deferred<IdData | IdData[]>(),
        },
        config.advancedSearch.extraParams
      ),
    });

    promise
      .then((result: IdData | IdData[]) => {
        this.addEntities(result, config.advancedSearch.instantiate);
      })
      .catch(() => {
        this.resetSearchTerm();
        this.addRemoveLock = false;
      });
  };

  addAll = () => {
    let config = this.config();
    if (!config.entities) {
      return;
    }

    this.addEntities(config.entities().concat(this.notSelectedSearchResults()), (x: T) => x);
    this.searchTerm('');
    this.isSearching(false);
  };

  addEntities(entities: IdData | IdData[], instantiate: (x: IdData) => T) {
    let config = this.config();

    this.addRemoveLock = true;

    if (entities instanceof Array) {
      if (config.entities) {
        let newEntities = entities.map(instantiate);
        if (config.confirmChangeEntities) {
          config
            .confirmChangeEntities()
            .then(() => {
              this.insertEntities(newEntities);
              this.addRemoveLock = false;
            })
            .catch(() => {
              this.addRemoveLock = false;
            });
          return;
        } else {
          this.insertEntities(newEntities);
        }
      }
    } else if (config.entity) {
      this.addEntity(instantiate(entities));
    }

    this.addRemoveLock = false;
  }

  /**
   * Handle selecting entity from the autocomplete list.
   *
   * Either mark entity as selected or add it to the list of selected
   * entities depending on the config.
   */
  addEntity = (entity: T) => {
    this.addRemoveLock = true;
    let config = this.config();

    if (config.entities) {
      for (let existingEntity of config.entities()) {
        if (ko.unwrap(entity.id) == ko.unwrap(existingEntity.id)) {
          this.searchTerm('');
          return;
        }
      }
      if (config.confirmChangeEntities) {
        config.confirmChangeEntities().then(() => {
          this.insertEntity(entity);
        });
      } else {
        this.insertEntity(entity);
      }
      this.searchTerm('');
    } else if (config.entity) {
      if (config.confirmEditEntity) {
        config
          .confirmEditEntity()
          .then(() => {
            this.addRemoveLock = false;
            config.entity(entity);
          })
          .catch(() => {
            this.resetSearchTerm();
            this.addRemoveLock = false;
          });
        return;
      } else {
        config.entity(entity);
      }
    }

    this.addRemoveLock = false;
  };

  private insertEntities(entities: T[]) {
    let config = this.config();
    if (config.create && config.create.insert) {
      config.entities([]);
      config.create.insertAll(entities);
    } else {
      config.entities(entities);
    }
  }

  private insertEntity(entity: T) {
    let config = this.config();
    if (config.create && config.create.insert) {
      config.create.insert(entity);
    } else {
      config.entities.push(entity);
    }
  }

  private resetSearchTerm() {
    let config = this.config();

    if (!config.entity) {
      return;
    }

    if (config.entity()) {
      this.searchTerm(this.name(config.entity()));
    } else {
      this.searchTerm('');
    }
  }

  removeEntity = (entity: T) => {
    this.addRemoveLock = true;

    try {
      this.config().entities.remove(entity);
    } finally {
      this.addRemoveLock = false;
    }
  };

  getSelectedEntityClass(entity: T): string {
    const config = this.config();
    return config.getSelectedEntityClass?.(entity) ?? '';
  }

  private name(entity: T) {
    let name = this.config().getSummaryName(entity);
    return typeof name === 'string' ? name : translate(name);
  }
}

ko.components.register('form-select-search', {
  viewModel: { createViewModel: FormSelectSearchInput.createViewModel },
  template: formSelectSearchTemplate,
});
