import { makeDefaultApi } from './request';
import { CropVarietyData } from './crop_varieties';
import { CountryData } from './countries';
import { RegistrationRegionData } from './registration_regions';
import { RegistrationPhaseData } from './registration_phases';
import { PortofolioItemData } from './portofolio_items';

export interface RegistrationFiltersData {
  country_ids: string[];
  region_ids: string[];
  crop_ids: string[];
  crop_variety_ids: string[];
  phase_ids: string[];
  portofolio_item_ids: string[];
  regional: string; // yes, no, all
  min_date: string;
  max_date: string;
}

export enum RegistrationPhaseType {
  REGIONAL = "REGIONAL",
  NATIONAL = "NATIONAL",
}

export interface RegistrationData {
  id: string;
  crop_variety: CropVarietyData;
  country: CountryData;
  region: RegistrationRegionData;
  support_by_sfsa: boolean;
  history: RegistrationHistoryData[];
  portofolio_items: PortofolioItemData[];
}

export const registrationApi = makeDefaultApi<RegistrationData, RegistrationFiltersData>('registrations');

export interface RegistrationHistoryData {
  id: string;
  registration: RegistrationData;
  phase: RegistrationPhaseData;
  changed: string;
  comment: string;
  created_by?: string; // read-only
  is_current?: boolean; // read-only
}

export const registrationHistoryApi = makeDefaultApi<RegistrationHistoryData, RegistrationFiltersData>(
  'registration_histories'
);
