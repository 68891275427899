import { request, requestRaw } from './request';
import { TrialSiteLocationData } from './trials';
import { I18nText } from '../i18n_text';

export interface OverviewParams {
  from_planting_date: string;
  to_planting_date: string;
  crop: string;
  commercial: 'yes' | 'no' | 'both';
  customer: string;
  crop_variety_name: string;
  public_cv: string;
  cv_stage: string;
  cv_country: string;
  owner: string;
  trial_year: string;
  cv_stage_year: string;
  cv_control: 'yes' | 'no' | 'both';
  external: 'yes' | 'no' | 'both';
  tpp: string;
  project: string;
}

export type OverviewChartLegend = {
  label: string;
  color: string;
  gradient?: number;
}[];

export interface OverviewChartData {
  type: 'line' | 'bar';
  title: string;
  aspect_ratio?: number;
  x_title: string;
  x_labels: string[];
  data: OverviewData;
  legends?: OverviewChartLegend[];
}

export type OverviewData = {
  label: string;
  stack: string;
  gradient?: number;
  color?: string;
  color_key: string;
  color_range: number;
  data: { x: number; y: number }[];
}[];

export interface AllVarietiesItemData {
  id: string;
  name: I18nText;
  anonymized_code: string;
  crop: I18nText;
  owner: string;
  public_cv: string;
  stages: { [country_id: string]: I18nText };
  n_trials: { [country_id: string]: number };
  n_registrations: { [country_id: string]: number };
  n_sales: { [country_id: string]: number };
  n_productions: { [country_id: string]: number };
}

export interface AllVarietiesData {
  countries: { id: string; name: string }[];
  items: AllVarietiesItemData[];
}

export type TrialledVarietiesData = {
  id: string;
  name: string;
  trial_name: string;
  year: string;
  crop: string;
  country: string;
  stage: string;
  control: boolean;
  is_commercial: boolean;
}[];

export type StageTransitionsData = {
  country: string;
  crop: string;
  year: string;
  from_stage: string;
  to_stage: string;
  n_varieties: number;
}[];

export type PortfolioStatusData = {
  tpp_name: string;
  innovate: string;
  develop: string;
  scale_up: string;
  discarded: string;
  current: string;
}[];

export interface OverviewKPIData {
  title: string;
  value: number;
}

export interface StageChangeVarietyData {
  first: boolean | undefined;
  start_at: number;
  end_at: number | null;
  trials: {
    name: string;
    planting_date: number;
  }[];
}

export interface StageChangeChartData {
  // levels can be varieties (if the chart is for multiple varieties)
  // or countries (if the chart is for a single variety)
  levels: {
    name: string;
    discarded: number | null;
  }[];
  stages: {
    name: string;
    levels: (StageChangeVarietyData | null)[];
  }[];
}

export function trialsCharts(params: OverviewParams): Promise<OverviewChartData[]> {
  return request('POST', '/api/overview/trials_charts/', params);
}

export interface VarietiesChartsData {
  stage_changes: StageChangeChartData;
  charts: OverviewChartData[];
}

export function varietiesCharts(params: OverviewParams): Promise<VarietiesChartsData> {
  return request('POST', '/api/overview/varieties_charts/', params);
}

export function varietyStageChangeChart(id: string): Promise<StageChangeChartData> {
  return request('GET', '/api/overview/' + id + '/variety_stage_change_chart/');
}

export function kpi(): Promise<OverviewKPIData[]> {
  return request('GET', '/api/overview/kpi/');
}

export function trialLocations(params: OverviewParams): Promise<TrialSiteLocationData[]> {
  return request('POST', '/api/overview/trial_locations/', params);
}

export function allVarieties(params: OverviewParams): Promise<AllVarietiesData> {
  return request('POST', '/api/overview/all_varieties/', params);
}

export function allVarietiesExport(params: OverviewParams): Promise<Blob> {
  return requestRaw('POST', '/api/overview/all_varieties_export/', params);
}

export function trialledVarieties(params: OverviewParams): Promise<TrialledVarietiesData> {
  return request('POST', '/api/overview/trialled_varieties/', params);
}

export function trialledVarietiesExport(params: OverviewParams): Promise<Blob> {
  return requestRaw('POST', '/api/overview/trialled_varieties_export/', params);
}

export function stageTransitions(params: OverviewParams): Promise<StageTransitionsData> {
  return request('POST', '/api/overview/stage_transitions/', params);
}

export function stageTransitionsExport(params: OverviewParams): Promise<Blob> {
  return requestRaw('POST', '/api/overview/stage_transitions_export/', params);
}

export function trialsChartsExport(params: OverviewParams): Promise<Blob> {
  return requestRaw('POST', '/api/overview/trials_charts_data_export/', params);
}

export function portfolioStatus(params: OverviewParams): Promise<PortfolioStatusData> {
  return request('POST', '/api/overview/portfolio_status/', params);
}
