import * as ko from 'knockout';

import { MaybeKO, asObservable } from '../utils/ko_utils';
import { I18nText, translate } from '../i18n_text';

let template = require('raw-loader!../../templates/components/form_nested_entities.html').default;

interface FormNestedEntityAction<T> {
  callback(entity: T): void;
  icon: string;
}

export interface FormNestedEntitiesConfiguration<T> {
  initialSelection?: T;

  title: ko.Computed<string> | MaybeKO<string>;
  addTitle: ko.Computed<string> | MaybeKO<string>;
  missingTitle: ko.Computed<string> | MaybeKO<string>;
  infoText?: ko.Computed<string> | MaybeKO<string>;

  entities: ko.Computed<T[]> | KnockoutObservable<T[]>;

  canRemove(entity: T): boolean;
  canDisable(entity: T): boolean;
  isTrialActive(entity: T): boolean;

  add(): T;
  remove(entity: T): void;

  disable(entity: T): void;
  disabled(entity: T): boolean;

  hasErrors(entity: T): boolean;
  showErrors(entity: T): void;

  actions: FormNestedEntityAction<T>[];

  getSummaryName(entity: T): string | I18nText;

  selectedEntity?: ko.Observable<T>;
}

export class FormNestedEntities<T> {
  config: FormNestedEntitiesConfiguration<T>;
  enable: KnockoutObservable<boolean>;
  selectedEntity: ko.Observable<T>;

  constructor(params: { config: FormNestedEntitiesConfiguration<T>; enable?: MaybeKO<boolean> }) {
    this.config = params.config;

    this.selectedEntity = params.config.selectedEntity || ko.observable<T>(null);

    if (this.config.initialSelection) {
      this.selectedEntity(this.config.initialSelection);
    }
    if (params.enable !== undefined) {
      this.enable = asObservable(params.enable);
    } else {
      this.enable = ko.observable(true);
    }
  }

  add = () => {
    let entity = this.config.add();
    this.selectedEntity(entity);
  };

  canRemove = (entity: T) => {
    return this.config.canRemove(entity) && this.enable();
  };

  remove = (entity: T, event: Event) => {
    event.stopPropagation();

    if (this.selectedEntity() == entity) {
      this.selectedEntity(null);
    }

    this.config.remove(entity);
  };

  canDisable = (entity: T) => {
    return this.config.canDisable(entity);
  };
  isTrialActive = (entity: T) => {
    return this.config.isTrialActive(entity);
  };
  disabled = (entity: T) => {
    return this.config.disabled(entity);
  };
  disable = (entity: T, event: Event) => {
    event.stopPropagation();
    this.config.disable(entity);
  };

  invokeAction = (entity: T, action: FormNestedEntityAction<T>, evt: Event) => {
    evt.stopPropagation();
    action.callback(entity);
  };

  isSelected = (entity: T) => {
    return this.selectedEntity() == entity;
  };

  showErrorsInTitle = (entity: T) => {
    return !this.isSelected(entity) && this.config.hasErrors(entity);
  };

  toggleSelection = (entity: T) => {
    if (this.isSelected(entity)) {
      this.selectedEntity(null);
    } else {
      this.selectedEntity(entity);
      this.config.showErrors(entity);
    }
  };

  hasSummaryName(entity: T): boolean {
    let name = this.config.getSummaryName(entity);
    return name && (typeof name === 'string' || !!translate(name));
  }
}

ko.components.register('form-nested-entities', {
  viewModel: FormNestedEntities,
  template: template,
});
