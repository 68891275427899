import * as ko from 'knockout';

import { BaseLoadingScreen } from './base_loading_screen';
import * as trialsApi from '../api/trials';
import { DimensionMetaData } from '../api/dimension_metas';
import * as QRCode from 'qrious';
let template = require('raw-loader!../../templates/trial_print_plots.html').default;

class TrialPrintPlotsScreen extends BaseLoadingScreen {
  trialName = ko.observable<string>('');
  trialPlants = ko.observable<number>();
  showLayouts = ko.observable(true);
  showLabels = ko.observable(true);
  firstTestSubjectDM: DimensionMetaData;

  hasSites = false;
  layouts: trialsApi.PrintPlotsBySiteData[] = [];
  labels: trialsApi.PrintPlotsBySubjectData[] = [];
  showAnonymizedCode = ko.observable(false);

  constructor(params: { id: string }) {
    super();
    let trialPromise = trialsApi.retrievePrintPlots(params.id);

    let promise = trialPromise.then((data) => {
      this.trialName(data.trial.name);
      this.trialPlants(data.trial.plants);

      this.hasSites = data.by_site.length > 0;
      this.layouts = data.by_site;
      this.labels = data.by_subject;
      this.drawQR();
    });
    this.loadedAfter(promise);
  }
  drawQR = () => {
    this.labels.map((label) => {
      label.plots.map((plot) => {
        let qr = new QRCode({
          size: 80,
          padding: 0,
          element: document.getElementById(plot.id),
          value: plot.id,
        });
        plot.img = qr.toDataURL();
      });
    });
  };
  print = () => {
    window.print();
  };

  close = () => {
    history.back();
  };
}

export let trialPrintPlots = {
  name: 'trial-print-plots',
  viewModel: TrialPrintPlotsScreen,
  template: template,
};

ko.components.register(trialPrintPlots.name, trialPrintPlots);
