import {
  request,
  ValidationResult,
  requestWithValidation,
  RemoveResult,
  requestRemoveEntity,
  requestRaw,
  listParamsToQueryString,
} from './request';
import { I18nText } from '../i18n_text';
import { TraitCompareToControlResponse, TraitDataInteractionPlot, TraitDataResponse } from './tpps';
import * as queryString from 'query-string'

export interface DashboardMetaData {
  trial_name: string;
  can_edit: boolean;
  datasets: DashboardDatasetData[];
  scheduled_visits: DashboardScheduledVisitData[];
  custom_charts: CustomChartData[];
}

export interface DashboardDatasetData {
  id: string;
  name: string;
  trial_name: string;
  measurement_metas: DashboardMMData[];
  dataset_dimension_metas: DashboardDDMData[];
}

export interface DashboardScheduledVisitData {
  id: string;
  name: string;
  measurement_metas: DashboardMMData[];
}

export interface DashboardMMData {
  id: string;
  name: string;
  unit_name?: string;
  class_only: boolean;
  dataset_id: string;
}

export interface DashboardDDMData {
  dimension_meta_id: string;
  dimension_meta_name: string;
}

export function meta(trialId: string): Promise<DashboardMetaData> {
  return request('GET', '/api/dashboard/' + trialId + '/meta/');
}

export interface EditCustomChartData {
  id: string;
  chart_type: string;
  label: string;
  secondary_group_by_id: string;
  group_by: string[];
  stats: EditStatData[];
}

export type MesCompat = {
  [mesId: string]: string[]; // list of dm ids
};

export interface ChartOptionsData {
  dms: { id: string; name: string; slug: string }[];
  mes_options: {
    id: string;
    name: string;
    dataset_id: string;
    is_surface: boolean;
    class_only: boolean;
  }[];
  mes_compat: MesCompat;
  trial_name: string;
}

export interface ReadEditCustomChartData extends ChartOptionsData, EditCustomChartData { }

export interface EditStatData {
  calc: string;
  stat: string;
  measurement_meta_id: string;
  measurement_meta_2_id: string;
  yield_dim_id: string;
  area_mm_id: string;
}

export interface ChartFilter {
  dataset_id: string;
  scheduled_visit_id: string;
}

export interface ChartParams extends ChartFilter {
  charts: ChartDetailParams[];
}

export interface ChartDetailParams {
  measurement_meta_id: string;
  chart_type: string;
  group_by: string[]; // list of dimension meta ids
}

export interface CustomChartData {
  id: string;
  name: string;
  unit_name: string;
  has_summary: boolean;
}

export interface ChartSubgroupData {
  dm_name: string;
  dims: [string, number][];
}

export interface BoxPlotData {
  names: string[];
  top_whisker: number;
  box_top: number;
  median: number;
  box_bottom: number;
  bottom_whisker: number;
  outliers: number[];
  n_values: number;
  subgroup_counts: ChartSubgroupData[];
}

export interface AverageData {
  names: string[];
  average: number;
  stddev: number;
  n_values: number;
  subgroup_counts: ChartSubgroupData[];
}

export interface BarData {
  names: string[];
  value: number;
  label?: string;
  group_idx?: number;
  n_values: number;
  subgroup_counts: ChartSubgroupData[];
}

export interface RadarData {
  names: string[];
  stats: { id: string; name: string }[];
  points: { [stat: string]: RadarPointData };
}

export interface RadarPointData {
  value: number;
  norm_value: number;
}

export interface ScatterData {
  x: number;
  y: number;
}

export interface DimensionItem {
  dimension_id: string,
  dimension_name: string,
}

export interface DimensionItemsResponse {
  data: DimensionItem[][],
}

export enum TrialStatsType {
    SINGLE_FACTOR = "single_factor",
    MULTIFACTOR = "multifactor",
    SINGLE_FACTOR_ONE_REPETITION = "single_factor_one_repetition",
    MULTI_FACTOR_ONE_REPETITION = "multi_factor_one_repetition",
    ONLY_MEAN_VALUES = "only_mean_values"
}

export type ChartData = BoxPlotData | AverageData | BarData | RadarData | ScatterData;

export function isBoxPlotData(arg: ChartData): arg is BoxPlotData {
  return (<any>arg).median !== undefined;
}

export function isAverageData(arg: ChartData): arg is AverageData {
  return (<any>arg).average !== undefined;
}

export function isBarData(arg: ChartData): arg is BarData {
  return (<any>arg).value !== undefined;
}

export function isRadarData(arg: ChartData): arg is RadarData {
  return (<any>arg).points !== undefined;
}

export function isScatterData(arg: ChartData): arg is ScatterData {
  return (<any>arg).x !== undefined && (<any>arg).y !== undefined;
}

export function isBoxPlotDataArray(arg: ChartData[]): arg is BoxPlotData[] {
  return !arg || arg.length === 0 || isBoxPlotData(arg[0]);
}

export function isAverageDataArray(arg: ChartData[]): arg is AverageData[] {
  return !arg || arg.length === 0 || isAverageData(arg[0]);
}

export function isBarDataArray(arg: ChartData[]): arg is BarData[] {
  return !arg || arg.length === 0 || isBarData(arg[0]);
}

export function isRadarDataArray(arg: ChartData[]): arg is RadarData[] {
  return !arg || arg.length === 0 || isRadarData(arg[0]);
}

export function isScatterDataArray(arg: ChartData[]): arg is ScatterData[] {
  return !arg || arg.length === 0 || isScatterData(arg[0]);
}

export interface AutoChartData {
  threshold: number;
  data: ChartData[];
}

export function charts(params: ChartParams): Promise<AutoChartData[]> {
  return request('POST', '/api/dashboard/charts/', params);
}

export function customChart(id: string): Promise<ChartData[]> {
  return request('GET', '/api/dashboard/' + id + '/custom_chart/');
}

export function customChartExcel(id: string): Promise<Blob> {
  return exportFile('custom_chart_excel', id);
}

export function customChartSummary(id: string): Promise<Blob> {
  return exportFile('custom_chart_summary', id);
}

export function compactExport(trialId: string): Promise<Blob> {
  return exportFile('compact_export', trialId);
}

export type OverviewValueType =
  | 'integer'
  | 'decimal'
  | 'date'
  | 'choice'
  | 'string'
  | 'string_long'
  | 'picture'
  | 'video'
  | 'multi_pictures'
  | 'signature'
  | 'file'
  | 'barcode'
  | 'location'
  | 'geo'
  | 'date_derived'
  | 'number_derived';

export interface OverviewData {
  trial_name: string;

  enforce_reason_for_editing_observations: boolean;

  header_groups: {
    title: string;
    headers: { title: string; size: number; unit_id: number | null; measurement_meta_id: number | null }[];
  }[];
  header_group_ds_idx: number;
  header_group_mm_idx: number;
  rows: OverviewDataRow[];

  validations: { [mmId: string]: ValidationData };

  // one per column
  scheduled_visit_ids: string[];
  dataset_ids: string[];
  mm_ids: string[];
  mm_trial_limit_to_by_dimension_meta_id: Record<string, Record<number, string[]>>;
  ordered_plot_dimensions_meta_ids: number[];
  value_types: OverviewValueType[];
  errors: string[][]; // list of errors per column
  copy_indexes: number[];
  prev_copy_first_cols: number[];
  col_dim_ids: string[][];
  value_col_fact_idxs: number[];

  // sparse array representation:
  // sequence of: idx, id, idx, id, ...
  // id is the fact id
  // idx is value_row_idx * nCols + value_col_fact_idx
  // nCols can be > max(value_col_fact_idxs) and they're not really related, but it's an upper bound
  fact_ids: (number | string)[];
  fact_ids_with_comment_extras: Record<string, number[] | null>;
  fact_ids_with_picture_or_document_extras: Record<string, number[] | null>;
  fact_ids_with_edited_observations: Record<string, number[]>;
  // sparse array representation:
  // sequence of: idx, value, idx, value, ...
  // value is the raw observation value
  // idx is value_row_idx * nCols + col_idx
  // for pictures, the value is { name: string, url: string }
  values: {}[];

  historic_values: {}[];
  reason_values: {}[];
}

export interface ValidationData {
  min_number: number;
  max_number: number;
  min_date: string;
  max_date: string;
  max_decimals: number;
  options: { id: string; name: string; value?: number }[];
  rating: boolean;
}

export interface OverviewDataRow {
  plot_id: string;
  plot_name: string;
  plot_treatment_name: string;
  plot_treatment_id: string;
  plot_dimensions: I18nText[];
  ordered_plot_dimension_ids: string[];
  value_row_idx: { [dsId: string]: number };
}

export function fetchOverview(
  trialId: string,
  filters: { site_ids: string[]; sv_ids: string[]; ts_ids: string[]; trait_ids: string[] }
): Promise<OverviewData> {
  return request('GET', `/api/dashboard/${trialId}/overview/?${listParamsToQueryString(filters)}`);
}

type StatsRequestBody = {
  trait_id: number;
  scheduled_visit_id: number;
  visit_grouping_type: 'first_visit' | 'all_visits';
}[];

export function fetchTechnicalScorecard(trialId: string, params: any, body: StatsRequestBody): Promise<any> {
  return request('POST', `/api/v2/trial_statistics/${trialId}/technical_scorecard/?${queryString.stringify(params)}`, body,
  { injectTenant: true, version: 2 });
}

export function fetchTraitPlots(trialId: string, params: {}): Promise<TraitDataResponse> {
  return request('GET', `/api/v2/trial_statistics/${trialId}/trait_scatter_plot/?${queryString.stringify(params)}`, {},
  { injectTenant: true, version: 2 });
}

export function fetchTraitInteractionPlots(trialId: string, params: {}): Promise<TraitDataInteractionPlot> {
  return request('GET', `/api/v2/trial_statistics/${trialId}/trait_interaction_graph/?${queryString.stringify(params)}`, {},
  { injectTenant: true, version: 2 });
}

export function fetchTraitCompareToControlPlots(trialId: string, params: {}): Promise<TraitCompareToControlResponse> {
  return request('GET', `/api/v2/trial_statistics/${trialId}/trait_compare_to_control/?${queryString.stringify(params)}`, {},
  { injectTenant: true, version: 2 });
}

export function fetchTrialControlDimensions(trialId: string): Promise<DimensionItemsResponse> {
  return request('GET', `/api/v2/trial_statistics/${trialId}/trial_control_dimensions`, {},
  { injectTenant: true, version: 2 });
}

export function exportTechnicalScorecardFile(trialId: string, params: any, body: StatsRequestBody): Promise<Blob> {
  return requestRaw('POST', `/api/v2/trial_statistics/${trialId}/export_technical_scorecard/?${queryString.stringify(params)}`, body,
  { injectTenant: true, version: 2 });
}

export interface FactHistoricValueData {
  id: string;
  type: string;
  comment_only_extra: {
    id: string;
    comment: string;
  } | null;
  files_extras: {
    id: string;
    comment: string;
    file_url: string;
    file_name: string;
    mime_type: string;
  }[];
  value: {};
  username: string;
  latitude: number;
  longtitude: number;
  location_valid_at: string;
  show_gps_time_warning: boolean;
  metadata_id: string;
  reason?: string;
}

export function history(factId: string, mmId: string): Promise<FactHistoricValueData[]> {
  return request('GET', `/api/dashboard/history/?fact_id=${factId}&mm_id=${mmId}`);
}

export function restoreHistory(logId: string, mmId: string, extraLogsIds: string[]): Promise<ValidationResult> {
  return requestWithValidation('POST', `/api/dashboard/restore_history/`, {
    log_id: logId,
    extra_logs_ids: extraLogsIds, 
    mm_id: mmId,
  });
}

function exportFile(endpoint: string, id: string): Promise<Blob> {
  return requestRaw('GET', '/api/dashboard/' + id + '/' + endpoint + '/');
}

export function customChartPreview(trialId: string, data: EditCustomChartData): Promise<ChartData[]> {
  return request('POST', '/api/dashboard/' + trialId + '/custom_chart_preview/', data);
}

export function customChartExcelPreview(trialId: string, data: EditCustomChartData): Promise<Blob> {
  return exportFilePreview('custom_chart_excel_preview', trialId, data);
}

export function customChartSummaryPreview(trialId: string, data: EditCustomChartData): Promise<Blob> {
  return exportFilePreview('custom_chart_summary_preview', trialId, data);
}

function exportFilePreview(endpoint: string, trialId: string, data: EditCustomChartData): Promise<Blob> {
  return requestRaw('POST', '/api/dashboard/' + trialId + '/' + endpoint + '/', data);
}

export function retrieveChartOptions(trialId: string): Promise<ChartOptionsData> {
  return request('GET', '/api/trials/' + trialId + '/charts/options/');
}

export function retrieveEditCustomChart(trialId: string, id: string): Promise<ReadEditCustomChartData> {
  return request('GET', '/api/trials/' + trialId + '/charts/' + id + '/');
}

export function saveEditCustomChart(trialId: string, data: EditCustomChartData): Promise<ValidationResult> {
  let endpoint = '/api/trials/' + trialId + '/charts/';
  let method = 'POST';
  if (data.id) {
    endpoint += data.id + '/';
    method = 'PUT';
  }

  return requestWithValidation(method, endpoint, data);
}

export function removeCustomChart(trialId: string, id: string): Promise<RemoveResult> {
  return requestRemoveEntity('/api/trials/' + trialId + '/charts/' + id + '/');
}
