import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';

let template = require('raw-loader!../../templates/components/forms_stack.html').default;

export class FormsStackController {
  forms = ko.observableArray<{
    showNav?: boolean;
    isBig?: boolean;
    className?: string;
  }>();

  push<T, TP extends { result: Deferred<T> }>(args: {
    title: string;
    name: string;
    showNav?: boolean;
    isBig?: boolean;
    className?: string;
    params: TP;
  }): Promise<T> {
    // Handle the default case to keep the behavior the same
    // for all the other modals
    if(args.showNav === undefined) {
      args.showNav = false;
    }
    this.forms.push(args);

    return args.params.result.promise.then(
      (entity) => {
        this.pop();
        return entity;
      },
      () => {
        this.pop();
        return Promise.reject(null);
      }
    );
  }

  clear() {
    this.forms.removeAll();
  }

  pop() {
    this.forms.pop();
  }
}

class FormsStack {
  controller: FormsStackController;

  constructor(params: { controller: FormsStackController }) {
    this.controller = params.controller;
  }

  className = ko.pureComputed(() => {
    let len = this.controller.forms().length;
    let lastForm = len > 0 && this.controller.forms()[len - 1];

    if (!lastForm) {
      return '';
    }

    return lastForm.isBig ? `big ${lastForm.className || ''}` : lastForm.className || '';
  });

  showNav = ko.pureComputed(() => {
    let forms = this.controller.forms();

    return (
      (forms.length > 1 && forms.every((form) => form.showNav)) || (forms.length === 1 && forms[0].showNav)
    );
  });
}

ko.components.register('forms-stack', {
  viewModel: FormsStack,
  template: template,
});
