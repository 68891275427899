import * as ko from 'knockout';

ko.bindingHandlers['arrow'] = {
  init: (element: Element, valueAccessor: () => { fromSelector: string; toSelector: string }) => {
    let config = valueAccessor();

    let draw = () => {
      let arrow = $(element);
      let text = $(config.fromSelector);
      let btn = $(config.toSelector);

      if (text.length === 0 || btn.length === 0) {
        return;
      }

      let startX = text.offset().left + text.width() + 20;
      let startY = text.offset().top + text.height() / 2 - 10;

      let endX = btn.offset().left + btn.width() / 2;
      let endY = btn.offset().top - 20;

      let height = endY - startY + 30;
      let width = endX - startX + 40;

      arrow.css({
        position: 'absolute',
        left: startX,
        top: startY,
        height: height,
        width: width,
      });

      let arrowPath = arrow.find('.arrow');
      arrowPath.attr(
        'd',
        'M10,10 C' + (width - 40) + ',10 ' + (width - 40) + ',10' + ' ' + (width - 40) + ',' + (height - 60)
      );
    };

    setTimeout(draw, 0);
    $(window).on('resize', draw);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      $(window).off('resize', draw);
    });
  },
};
