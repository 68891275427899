import { makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';
import { DriverData } from './drivers';

export interface TraitCategoryData {
  id?: string;
  name_json: I18nText;
  driver: DriverData | null;
  code?: string;
}

export const { list, retrieve, save, remove } = makeDefaultApi<
  TraitCategoryData,
  { ids?: string[]; client_type_ids?: string[]; driver_ids?: string[] }
>('trait_categories');
