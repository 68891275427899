import * as ko from 'knockout';
import { app } from '../../app';
import i18n from '../../i18n';

import { Deferred } from '../../utils/deferred';
import { SitePlotData } from './plots_edit/plots_edit_model';

const template =
  require('raw-loader!../../../templates/components/trial_wizard/plot_edit_details.html').default;

type PlotEditDetailsResult = { externalId?: string; name?: string; excluded?: boolean } | null;

class PlotEditDetails {
  externalId = ko.observable('').extend({
    maxLength: 100,
  });
  name = ko.observable('').extend({
    maxLength: 100,
  });

  column = ko.observable();
  row = ko.observable();

  private result: Deferred<PlotEditDetailsResult>;

  constructor(params: { data: SitePlotData; result: Deferred<PlotEditDetailsResult> }) {
    this.externalId(params.data.externalId);
    this.name(params.data.name);
    this.result = params.result;
    this.column(params.data.column + 1);
    this.row(params.data.row + 1);
  }

  onConfirm = () => {
    if (this.externalId.isValid()) {
      this.result.resolve({ externalId: this.externalId(), name: this.name() });
    }
  };

  onCancel = () => {
    this.result.resolve(null);
  };

  onRemove = () => {
    this.result.resolve({ excluded: true });
  };
}

ko.components.register('plot-edit-details', {
  viewModel: PlotEditDetails,
  template,
});

export function openPlotEditDetails(data: SitePlotData): Promise<PlotEditDetailsResult> {
  return app.formsStackController.push({
    title: i18n.t('Plot details')(),
    name: 'plot-edit-details',
    className: 'sidebar-right',
    params: {
      data,
      result: new Deferred(),
    },
  });
}
