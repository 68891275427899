import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { AgroRegion } from '../models/agro_region';
import * as agroRegionsApi from '../api/agro_regions';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { canCreateCountry } from '../permissions';

const template = require('raw-loader!../../templates/agro_regions.html').default;

class AgroRegionsScreen implements ListLoaderDelegate<agroRegionsApi.AgroRegionData, AgroRegion> {
  canEdit = canCreateCountry();
  listActions = ko.observableArray<ListHeaderAction>([]);

  constructor() {
    if (this.canEdit) {
      this.listActions.push(
        {
          title: i18n.t('Add')(),
          icon: 'add_circle',
          href: '/agro_regions/new/',
          tooltipTitle: i18n.t('Add Agroclimatic Region')(),
        },
        {
          title: i18n.t('Import')(),
          icon: 'file_upload',
          href: '/agro_regions/import/',
        }
      );
    }
  }

  fetch(params: ListRequestParams) {
    return agroRegionsApi.list(params);
  }

  instantiate(regionData: agroRegionsApi.AgroRegionData) {
    return new AgroRegion(regionData);
  }

  remove(id: string) {
    return agroRegionsApi.remove(id);
  }

  canRemove(region: AgroRegion) {
    return this.canEdit;
  }
}

export const agroRegions = {
  name: 'agro-regions',
  viewModel: AgroRegionsScreen,
  template,
};

ko.components.register(agroRegions.name, agroRegions);
