import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { CustomReport } from '../models/custom_report';
import * as customReportsApi from '../api/custom_report';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/custom_report_edit.html').default;

class CustomReportEditScreen extends BaseForm<customReportsApi.CustomReportData> {
  entity = ko.validatedObservable<CustomReport>(null);

  constructor(
    params: {
      id: string;
      trialId: number | null;
      initialName?: string;
      result?: Deferred<customReportsApi.CustomReportData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    this.entity(new CustomReport());
    this.entity().trialId(params.trialId);
    // Only the addition operation is supported so far
    this.loadedAfter(Promise.resolve()).then(() => {
      this.focusFirst(componentInfo.element);
    });
  }

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(customReportsApi.createCustomReport(data)).then((validation) => {
        this.saving(false);
        if (!validation['id']) {
          this.applyModelServerErrors(this.entity(), validation as customReportsApi.ErrorResponse);
        } else {
          this.close()
        }
      });
    }
  };
}

export let customReportEdit = {
  name: 'custom-dashboard-edit',
  viewModel: createWithComponent(CustomReportEditScreen),
  template: template,
};

ko.components.register(customReportEdit.name, customReportEdit);
