import * as ko from 'knockout';

import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import {
  OrganizationData,
  breedersApi,
  buyersApi,
  producersApi,
  customersApi,
  partnersApi,
} from '../api/organizations';
import { Organization } from '../models/organization';
import { session } from '../session';
import { ListHeaderAction } from '../components/list_header';
import { addChangelogListAction, addChangelogAction } from './changelog';
import { Action } from '../components/basic_widgets';

let template = require('raw-loader!../../templates/organizations.html').default;

const baseNameToChangelog: { [key: string]: string } = {
  producers: 'producer',
  buyers: 'buyer',
  breeders: 'breeder',
};

class OrganizationsScreen implements ListLoaderDelegate<OrganizationData, Organization> {
  private baseName: string;
  private api: typeof breedersApi;
  title: string;
  newUrl: string;

  canCreate = session.isAtLeastEditor();

  listActions: ListHeaderAction[] = [];

  constructor(params: { baseName: string; api: typeof breedersApi; title: string }) {
    this.baseName = params.baseName;
    this.api = params.api;
    this.title = params.title;
    this.newUrl = '/' + this.baseName + '/new/';

    if (this.baseName === 'producers') {
      this.listActions.push({
        title: i18n.t('Producers tasks')(),
        icon: 'assignment_ind',
        href: '/tasks/',
      });
    }
    if (this.baseName === 'buyers') {
      this.listActions.push({
        title: i18n.t('Buyers tasks')(),
        icon: 'assignment_ind',
        href: '/buyer_tasks/',
      });
    }

    if (this.baseName === 'breeders' || this.baseName === 'customers' || this.baseName === 'partners') {
      if (this.canCreate) {
        this.listActions.push({
          title: i18n.t('Import')(),
          icon: 'file_upload',
          href: `/${this.baseName}/import/`,
        });
      }
    }
    if (this.canCreate) {
      this.listActions.splice(0, 0, {
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: this.newUrl,
      });
    }
    this.listActions = addChangelogListAction(baseNameToChangelog[this.baseName], this.listActions);
  }

  fetch(params: ListRequestParams) {
    return this.api.list(params);
  }

  instantiate(data: OrganizationData) {
    return new Organization(this.baseName, data);
  }

  getEditUrl(entity: Organization) {
    return entity.editUrl;
  }

  remove(id: string) {
    return this.api.remove(id);
  }

  canRemove(entity: Organization) {
    return entity.canEdit();
  }

  getActions(entity: Organization): Action[] {
    return addChangelogAction(baseNameToChangelog[this.baseName], entity.id());
  }
}

export let breeders = {
  name: 'breeders',
  viewModel: {
    createViewModel: () =>
      new OrganizationsScreen({
        baseName: 'breeders',
        api: breedersApi,
        title: i18n.t('Breeders')(),
      }),
  },
  template: template,
};
ko.components.register(breeders.name, breeders);

export let buyers = {
  name: 'buyers',
  viewModel: {
    createViewModel: () =>
      new OrganizationsScreen({
        baseName: 'buyers',
        api: buyersApi,
        title: i18n.t('Buyers')(),
      }),
  },
  template: template,
};
ko.components.register(buyers.name, buyers);

export let producers = {
  name: 'producers',
  viewModel: {
    createViewModel: () =>
      new OrganizationsScreen({
        baseName: 'producers',
        api: producersApi,
        title: i18n.t('Producers/Sellers')(),
      }),
  },
  template: template,
};
ko.components.register(producers.name, producers);

export let customers = {
  name: 'customers',
  viewModel: {
    createViewModel: () =>
      new OrganizationsScreen({
        baseName: 'customers',
        api: customersApi,
        title: i18n.t('Customers')(),
      }),
  },
  template: template,
};
ko.components.register(customers.name, customers);

export let partners = {
  name: 'partners',
  viewModel: {
    createViewModel: () =>
      new OrganizationsScreen({
        baseName: 'partners',
        api: partnersApi,
        title: i18n.t('Partners')(),
      }),
  },
  template: template,
};
ko.components.register(partners.name, partners);
