import * as ko from 'knockout';

let template = require('raw-loader!../../templates/components/columns_select.html').default;

export interface ColumnSelectItem {
  initialValue?: boolean;
  title: string;
}

export class ColumnSelectManager {
  columnsShown: { field: KnockoutObservable<boolean> }[] = [];

  constructor(public columns: ColumnSelectItem[]) {
    this.columnsShown = columns.map((item) => ({
      field: ko.observable(item.initialValue === undefined ? true : item.initialValue),
    }));
  }

  isShown(index: number) {
    return this.columnsShown[index].field();
  }
}

class ColumnSelect {
  showColumnSelect = ko.observable(false);
  manager: ColumnSelectManager;

  constructor(params: { manager: ColumnSelectManager }) {
    this.manager = params.manager;
  }

  toggleColumnSelect = () => {
    this.showColumnSelect(!this.showColumnSelect());
  };
}

let columnSelect = {
  name: 'column-select',
  viewModel: ColumnSelect,
  template: template,
};

ko.components.register(columnSelect.name, columnSelect);
