import {
  request,
  requestWithValidation,
  requestRemoveEntity,
  ValidationResult,
  RemoveResult,
  injectTenantInURL,
} from './request';
import { session } from '../session';
import { requestWithStatus } from './base_request';

export type WeatherSubscription = 'none' | 'basic' | 'advanced';

export interface PlanData {
  id: string;
  name: string;
  amount: number | null; // cents precision. amount == 100 is $1 (same as in Stripe)
  measurements: number;
  basic_reporting: boolean;
  advanced_reporting: boolean;
}

export interface ReadSubscriptionData {
  plan: string;
  yearly: boolean;
  weather: WeatherSubscription;
  available_plans: PlanData[];
  yearly_discount: number; // between 0 and 1
  weather_amount: number;
  advanced_weather_amount: number;
  current_year_measurements_count: number;
  actual_price: number;
  last_weather_activation: string; // timestamp
  weather_quota_usage: number;
  weather_quota_max: number;
  editable: boolean;
  subscription_special_conditions: string;
}

export interface CreateSubscriptionData {
  plan: string;
  yearly: boolean;
  weather: WeatherSubscription;
  payment_method: string;
  coupon: string;
  name: string;
  address: string;
  country_id: string;
  tax_id: string;
}

export interface CreateSubscriptionResult {
  status: 'failed' | 'requires_action' | 'succeeded';
  failure_code: string;
  client_secret: string;
}

export interface UpdateSubscriptionData {
  plan: string;
  yearly: boolean;
  weather: WeatherSubscription;
  coupon: string;
}

export function retrieve(): Promise<ReadSubscriptionData> {
  return request('GET', '/api/subscriptions/');
}

export function create(
  data: CreateSubscriptionData
): Promise<{ status: number; result: CreateSubscriptionResult }> {
  return session
    .getToken()
    .then((token) =>
      requestWithStatus<CreateSubscriptionResult>(
        'POST',
        injectTenantInURL('/api/subscriptions/'),
        token,
        data
      )
    );
}

export function confirm(): Promise<void> {
  return request('POST', '/api/subscriptions/confirm/');
}

export function update(data: UpdateSubscriptionData): Promise<ValidationResult> {
  return requestWithValidation('POST', '/api/subscriptions/change/', data);
}

export function remove(): Promise<RemoveResult> {
  return requestRemoveEntity('/api/subscriptions/cancel/');
}
