import * as ko from 'knockout';

import {
  MeasurementChoiceData,
  MeasurementTypeData,
  getImageUploadEndpoint,
} from '../api/measurement_types';
import { I18nText } from '../i18n_text';
import { ImageUpload, ImageUploadDelegate } from '../image_upload';
import { FileUploadEndpoint } from '../cloud_storage_upload';
import { CropData } from '../api/crops';

export class MeasurementChoice implements ImageUploadDelegate {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });

  value = ko.observable<string>(null).extend({
    required: true,
    number: true,
    serverError: true,
  });
  code = ko.observable('').extend({
    serverError: true,
  });

  imageUpload = new ImageUpload(this);

  constructor(data?: MeasurementChoiceData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.value(data.value.toString());
      this.code(data.code);
      this.imageUpload.fileName = data.file_name;
      this.imageUpload.picturePublicURL(data.file_public_url);
    }
  }

  getImageUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return getImageUploadEndpoint(contentType);
  }

  toData(): MeasurementChoiceData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      value: parseFloat(this.value()),
      code: this.code(),
      file_name: this.imageUpload.fileName,
    };
  }

  hasServerErrors(): boolean {
    return !!(this.nameJson.serverError() || this.value.serverError() || this.code.serverError());
  }
}

export class MeasurementType {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  crop = ko.observable<CropData>(null);
  rating = ko.observable(true);
  choices = ko.observableArray<MeasurementChoice>();

  editUrl = ko.pureComputed(() => {
    return '/measurement_types/' + this.id();
  });

  constructor(data?: MeasurementTypeData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.crop(data.crop);
      this.rating(data.rating);

      if (data.choices) {
        this.choices(data.choices.map((data) => new MeasurementChoice(data)));
      }
    }
  }

  toData(): MeasurementTypeData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      crop: this.crop(),
      rating: this.rating(),
      choices: this.choices().map((choice) => choice.toData()),
    };
  }
}
