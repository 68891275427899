import * as ko from 'knockout';
import { MaybeKO } from '../utils/ko_utils';

ko.bindingHandlers['stripePayment'] = {
  init: (
    element: Element,
    valueAccessor: () => MaybeKO<{
      card: any;
      error: KnockoutObservable<string>;
    }>
  ) => {
    let { card, error } = ko.unwrap(valueAccessor());
    card.mount(element);

    let onChange = (data: { error: { message: string } }) => {
      if (data.error) {
        error(data.error.message);
      } else {
        error('');
      }
    };
    card.addEventListener('change', onChange);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      card.removeEventListener('change', onChange);
    });
  },
  update: (element: Element, valueAccessor: () => void) => {},
};
