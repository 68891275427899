import * as ko from 'knockout';
import page from 'page';
import firebase from 'firebase/app';

let template = require('raw-loader!../../templates/email_verification.html').default;

class EmailVerifiedScreen {
  loading = ko.observable(true);
  success = ko.observable<boolean>();
  error = ko.observable<boolean>();

  constructor(params: { oobCode: string }) {
    const auth = firebase.auth();

    auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        // Email has already been verified
        this.loading(false);
        this.success(true);
        return;
      }

      auth
        .applyActionCode(params.oobCode)
        .then(() => {
          // Email address has been verified.
          this.success(true);
        })
        .catch(() => {
          // Code is invalid or expired. Ask the user to verify their email address again.
          this.error(true);
        })
        .finally(() => this.loading(false));
    });
  }

  start() {
    page('/');
  }
}

export let emailVerifiedScreen = {
  name: 'email-verification',
  viewModel: EmailVerifiedScreen,
  template: template,
};

ko.components.register(emailVerifiedScreen.name, emailVerifiedScreen);
