import * as ko from 'knockout';
import { MaybeKO, asObservable } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/list_header.html').default;

export interface ListHeaderAction {
  title: string;
  icon: string;
  iconClass?: string;
  href?: string;
  onClick?: () => void;
  loading?: KnockoutObservable<boolean>;
  chipText?: string;
  tooltipTitle?: string;
}

class ListHeader {
  actions: KnockoutObservable<ListHeaderAction[]>;
  pageTitle = ko.observable(null);
  constructor(params: { actions: MaybeKO<ListHeaderAction[]>; pageTitle?: string }) {
    this.actions = asObservable(params.actions);
    this.pageTitle(params.pageTitle);
  }

  onClick = (action: ListHeaderAction) => {
    if (action.loading && action.loading()) {
      return;
    }

    if (action.onClick) {
      action.onClick();
    }
  };
}

ko.components.register('list-header', {
  viewModel: ListHeader,
  template: template,
});
