require('es6-promise').polyfill();
window.MarkerClusterer = require('./js/vendor/markerclusterer');
window.OverlappingMarkerSpiderfier = require('./js/vendor/oms.min').OverlappingMarkerSpiderfier;
require('config.js');
require('./ts/index.ts');
require('./styles/index.less');
require('./styles/vendor/materialize.css');
require('./js/vendor/materialize.js');
require('./js/vendor/picker.js'); // must come after materialize, to override the one included there
require('./js/vendor/picker.date.js');
require('favicon.ico');
require('product_images/logo/logo_email.png');
require('images/app_store_email.png');
require('images/ic_person_pin_circle_black_24dp.png');
require('images/ic_place_black_24dp.png');
require('images/m1.png');
require('images/m2.png');
require('images/m3.png');
require('images/m4.png');
require('images/m5.png');
require('hopscotch/dist/css/hopscotch.min.css');
require('dragula/dist/dragula.min.css');
