/// <reference path="../type_definitions/ga.d.ts"/>

import * as ko from 'knockout';
import page from 'page';
import i18n from '../i18n';

import { session } from '../session';
import { BaseForm } from './base_form';
import * as tenantsApi from '../api/tenants';

let template = require('raw-loader!../../templates/sign_up.html').default;

function sendToGoogleAnaltyicsIfEnabled(command: string, extra_parameters_json: any): void {
  if (typeof ga !== 'undefined') {
    // we don't have ga on dev builds
    ga(command, extra_parameters_json); // necessary for hitType pageview to work as expected
  }
}

class SignUpScreen extends BaseForm<{}> {
  stepNumber = ko.observable(1);
  companyDetailsStep = ko.pureComputed(() => {
    return this.stepNumber() == 1;
  });
  loginStep = ko.pureComputed(() => {
    return this.stepNumber() == 2;
  });
  createAccountStep = ko.pureComputed(() => {
    return this.stepNumber() == 3;
  });
  userEmail = ko.observable('');

  userName = ko.observable('').extend({
    required: true,
    maxLength: 100,
  });
  companyName = ko.observable('').extend({
    maxLength: 100,
  });
  role = ko.observable('').extend({
    maxLength: 100,
  });
  phoneNumber = ko.observable('').extend({
    maxLength: 100,
  });
  private companyDetailsFormGroup = ko.validation.group([
    this.userName,
    this.companyName,
    this.role,
    this.phoneNumber,
  ]);

  name = ko.observable('').extend({
    required: true,
    serverError: true,
  });
  accepted = ko.observable(false).extend({
    validation: {
      validator: (val: boolean) => {
        return val;
      },
      message: i18n.t(
        'You must accept the terms and conditions and the privacy policy to create an account'
      )(),
    },
  });

  shouldCreateDemoData = ko.observable(true);

  stepTitle = ko.pureComputed(() =>
    i18n.t('Step {{ stepNumber }} of 3', { stepNumber: this.stepNumber() })()
  );

  private accountFormGroup = ko.validation.group([this.name, this.accepted]);

  supportEmail = SERVER_INFO.SUPPORT_EMAIL;
  termsAndConditionsURL = APP_CONFIG.TERMS_AND_CONDITIONS_URL;
  privacyPolicyURL = APP_CONFIG.PRIVACY_POLICY_URL;
  defaultAccountExpirationDays = SERVER_INFO.DEFAULT_ACCOUNT_EXPIRATION_DAYS;

  private planId: string;
  private subscription: KnockoutSubscription = null;

  constructor(params: { planId?: string }) {
    super({});

    this.planId = params.planId;

    this.subscription = session.loggedIn.subscribe(this.onLoginStateChange.bind(this));
    this.onLoginStateChange();

    this.loaded();
  }

  dispose() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  close() {}

  saveCompanyDetails = () => {
    if (this.companyDetailsFormGroup().length > 0) {
      this.companyDetailsFormGroup.showAllMessages();
      return;
    }

    this.nextStep();
  };

  private onLoginStateChange() {
    if (this.companyDetailsStep()) {
      return;
    }

    this.nextStep();
  }

  private nextStep() {
    if (session.loggedIn()) {
      this.userEmail(session.getEmail());

      let name = this.companyName() || session.getName();
      if (name && !this.name()) {
        this.name(name);
      }
      // Not true but Googla Analytics does not care
      sendToGoogleAnaltyicsIfEnabled('send', {
        hitType: 'pageview',
        page: '/sign-up/step3',
      });
      this.stepNumber(3);
    } else {
      // Not true but Googla Analytics does not care
      sendToGoogleAnaltyicsIfEnabled('send', {
        hitType: 'pageview',
        page: '/sign-up/step2',
      });
      this.stepNumber(2);
    }
  }

  save = () => {
    if (this.validate(this.accountFormGroup)) {
      let data = {
        name: this.name(),
        admin_email: session.getEmail(),
        admin_name: this.userName() || session.getName(),
        company_name: this.companyName(),
        role: this.role(),
        phone_number: this.phoneNumber(),
        should_create_demo_data: this.shouldCreateDemoData(),
      };

      this.executeSaveRequest(tenantsApi.save(data)).then((validation) => {
        this.onRemoteValidation({}, this, validation);

        if (validation.tenantData) {
          if (typeof ga !== 'undefined') {
            // we don't have ga on dev builds
            ga('send', { hitType: 'pageview', page: '/sign-up/completed' }); // Not true but Google Analytics does not care
          }
          if (this.planId) {
            page.redirect(
              session.toTenantPath('/subscription/?plan_id=' + this.planId, validation.tenantData)
            );
          } else {
            page.redirect(session.toTenantPath('/', validation.tenantData));
          }
        }
      });
    }
  };
}

sendToGoogleAnaltyicsIfEnabled('set', { hostname: location.hostname }); // necessary for hitType pageview to work as expected

export let signUp = {
  name: 'sign-up',
  viewModel: SignUpScreen,
  template: template,
};

ko.components.register(signUp.name, signUp);
