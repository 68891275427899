import * as ko from 'knockout';

import i18n from '../i18n';
import { BarChartConfig } from '../ko_bindings/bar_chart';
import {
  getCropSearchConfig,
  getCustomerSearchConfig,
  getCountrySearchConfig,
  getStageSearchConfig,
  getBreederSearchConfig,
  getProjectSearchConfig,
} from '../components/configs/search_configs';
import {
  kpi,
  OverviewParams,
  OverviewChartData,
  OverviewKPIData,
  trialLocations,
  AllVarietiesData,
  TrialledVarietiesData,
  StageTransitionsData,
  allVarieties,
  trialledVarieties,
  stageTransitions,
  trialsCharts,
  varietiesCharts,
  allVarietiesExport,
  trialledVarietiesExport,
  stageTransitionsExport,
  trialsChartsExport,
  PortfolioStatusData,
  portfolioStatus,
  VarietiesChartsData,
  OverviewChartLegend,
} from '../api/overview';
import { downloadBlob, updateLocationWithQueryString } from '../utils';
import * as dimensionsApi from '../api/dimensions';
import { CountryData, countriesApi } from '../api/countries';
import * as cropsApi from '../api/crops';
import * as tppsApi from '../api/tpps';
import { GeoJSON } from '../api/datasets';
import { getTrialLocationsMap } from './trials';
import { serializeDate, parseDate } from '../api/serialization';
import { session } from '../session';
import { customersApi, CustomerData, BreederData, breedersApi } from '../api/organizations';
import { ProjectData, projectApi } from '../api/projects';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { openVarietyStageChangeChart } from '../components/variety_stage_change_chart';
import { I18nText } from '../i18n_text';

let template = require('raw-loader!../../templates/overview.html').default;

type OverviewLinkParams = {
  [P in keyof OverviewParams]: string;
} & {
  selected: string;
};

function getYearOptions() {
  let res = [{ name: i18n.t('All')(), value: 'all' }];
  for (let i = 0; i < 10; i++) {
    let year = (new Date().getFullYear() - i).toString();
    res.push({ name: year, value: year });
  }

  return res;
}

function setYearOption(obs: KnockoutObservable<string>, value: string) {
  if (value === 'all' || !isNaN(parseInt(value, 10))) {
    obs(value);
  }
}

function toYearValue(obs: KnockoutObservable<string>) {
  return obs() === 'all' ? null : obs();
}

function asChartConfig(
  obs: ko.MaybeObservableArray<OverviewChartData>
): (BarChartConfig & { legends: OverviewChartLegend[] })[] {
  return ko.unwrap(obs).map((data) => {
    return {
      type: data.type,
      title: data.title,
      aspectRatio: data.aspect_ratio ?? 2,
      xTitle: data.x_title,
      fontSize: 12,
      timeUnit: 'month' as 'month',
      labels: data.x_labels,
      datasets: data.data,
      hideLegend: !!data.legends,
      legends: data.legends,
    };
  });
}

class OverviewScreen {
  private requestNumber = 0;
  private requestNumberKPI = 0;

  mapVisualizationFeatureEnabled = session.tenant() && session.tenant().map_visualization_enabled;
  showTPP = session.tenant().tpp_enabled;
  showS2bim = SERVER_INFO.ENABLE_S2BIM;

  loadingFilters = ko.observable(true);
  loading = ko.observable(false);
  loadingKPI = ko.observable(false);
  selected = ko.observable<
    | 'trials_charts'
    | 'varieties_charts'
    | 'map'
    | 'all_varieties'
    | 'trialled_varieties'
    | 'stage_transitions'
    | 'portfolio_status'
  >('trials_charts');
  trialsChartsData = ko.observableArray<OverviewChartData>();
  varietiesChartsData = ko.observable<VarietiesChartsData>();
  mapData = ko.observableArray<GeoJSON>();
  allVarietiesData = ko.observable<AllVarietiesData>();
  trialledVarietiesData = ko.observable<TrialledVarietiesData>();
  stageTransitionsData = ko.observable<StageTransitionsData>();
  portfolioStatusData = ko.observable<PortfolioStatusData>();

  exportingText = i18n.t('Exporting...')();
  exportingAllVarieties = ko.observable(false);
  exportingTrialledVarieties = ko.observable(false);
  exportingStageTransitions = ko.observable(false);
  exportingTrialsChartsData = ko.observable(false);

  kpiData = ko.observableArray<OverviewKPIData>();

  fromPlantingDate = ko.observable<Date>(null);
  toPlantingDate = ko.observable<Date>(null);
  crop = ko.observable<cropsApi.CropData>(null);
  commercial = ko.observable<'yes' | 'no' | 'both'>('both');
  customer = ko.observable<CustomerData>(null);
  cropVarietyName = ko.observable('');
  publicCV = ko.observable<'yes' | 'no' | 'mixed' | 'any'>('any');
  cvStage = ko.observable<dimensionsApi.DimensionData>(null);
  cvCountry = ko.observable<CountryData>(null);
  owner = ko.observable<BreederData>(null);
  trialYear = ko.observable('all');
  cvStageYear = ko.observable('all');
  cvControl = ko.observable<'yes' | 'no' | 'both'>('both');
  external = ko.observable<'yes' | 'no' | 'both'>('both');
  tpp = ko.observable<tppsApi.TPPListData>(null);
  project = ko.observable<ProjectData>(null);

  yearOptions = getYearOptions();

  boolOptions = [
    { name: i18n.t('Yes')(), value: 'yes' },
    { name: i18n.t('No')(), value: 'no' },
    { name: i18n.t('Both')(), value: 'both' },
  ];
  publicOptions = [
    { name: i18n.t('Yes')(), value: 'yes' },
    { name: i18n.t('No')(), value: 'no' },
    { name: i18n.t('Mixed')(), value: 'mixed' },
    { name: i18n.t('Any')(), value: 'any' },
  ];
  ownerOptions = ko.observableArray<{ name: string; value: string }>();
  cropSearchConfig = getCropSearchConfig(this.crop, { disableCreate: true });
  customerSearchConfig = getCustomerSearchConfig(this.customer, {
    disableCreate: true,
  });
  cvCountrySearchConfig = getCountrySearchConfig(this.cvCountry, {
    disableCreate: true,
  });
  cvStageSearchConfig = getStageSearchConfig(this.cvStage, {
    disableCreate: true,
  });
  ownerSearchConfig = getBreederSearchConfig(this.owner, true);
  tppSearchConfig: FormSelectSearchConfiguration<tppsApi.TPPListData> = {
    getSummaryName: (tpp) => tpp.name_json,
    list: (params) => tppsApi.list(params),
    entity: this.tpp,
  };
  projectSearchConfig = getProjectSearchConfig(this.project);

  trialsChartsConfigs = ko.pureComputed(() => asChartConfig(this.trialsChartsData));
  varietiesChartsConfigs = ko.pureComputed(() => asChartConfig(this.varietiesChartsData()?.charts ?? []));
  fullscreenChart = ko.observable<BarChartConfig>(null);
  downloadableChart = ko.observable<BarChartConfig>(null);
  stageChangeChart = ko.pureComputed(() => {
    if (!this.tpp()) {
      return null;
    }

    return this.varietiesChartsData()?.stage_changes;
  });

  private subscriptions: KnockoutSubscription[] = [];

  constructor(params: { filters: OverviewLinkParams }) {
    let filters = params.filters;
    if (
      [
        'trials_charts',
        'varieties_charts',
        'map',
        'all_varieties',
        'trialled_varieties',
        'stage_transitions',
        'portfolio_status',
      ].indexOf(filters.selected) == -1
    ) {
      filters.selected = 'trials_charts';
    }
    this.selected(filters.selected as any);
    if (filters.commercial) {
      this.commercial(filters.commercial === 'yes' ? 'yes' : filters.commercial === 'no' ? 'no' : 'both');
    }
    if (filters.crop_variety_name) {
      this.cropVarietyName(params.filters.crop_variety_name || '');
    }
    this.cropVarietyName = this.cropVarietyName.extend({ throttle: 300 });
    if (filters.public_cv) {
      this.publicCV(
        filters.public_cv === 'yes'
          ? 'yes'
          : filters.public_cv === 'no'
          ? 'no'
          : filters.public_cv === 'mixed'
          ? 'mixed'
          : 'any'
      );
    }
    if (filters.cv_control) {
      this.cvControl(filters.cv_control === 'yes' ? 'yes' : filters.cv_control === 'no' ? 'no' : 'both');
    }
    if (filters.from_planting_date) {
      this.fromPlantingDate(parseDate(filters.from_planting_date));
    }
    if (filters.to_planting_date) {
      this.toPlantingDate(parseDate(filters.to_planting_date));
    }
    setYearOption(this.trialYear, filters.trial_year);
    setYearOption(this.cvStageYear, filters.cv_stage_year);
    if (filters.external) {
      this.external(filters.external === 'yes' ? 'yes' : filters.external === 'no' ? 'no' : 'both');
    }

    let crop = filters.crop ? cropsApi.retrieve(filters.crop) : null;
    let customer = filters.customer ? customersApi.retrieve(filters.customer) : null;
    let cvStage = filters.cv_stage ? dimensionsApi.retrieve(null, filters.cv_stage) : null;
    let cvCountry = filters.cv_country ? countriesApi.retrieve(filters.cv_country) : null;
    let owner = filters.owner ? breedersApi.retrieve(filters.owner) : null;
    let tpp = filters.tpp ? tppsApi.retrieve(filters.tpp) : null;
    let project = filters.project ? projectApi.retrieve(filters.project) : null;

    Promise.all([crop, customer, cvStage, cvCountry, owner, tpp, project]).then(
      ([crop, customer, cvStage, cvCountry, owner, tpp, project]) => {
        this.crop(crop as cropsApi.CropData);
        this.customer(customer);
        this.cvStage(cvStage);
        this.cvCountry(cvCountry);
        this.owner(owner);
        this.tpp(tpp);
        this.project(project);

        this.loadingFilters(false);

        this.sub(this.fromPlantingDate);
        this.sub(this.toPlantingDate);
        this.sub(this.crop);
        this.sub(this.commercial);
        this.sub(this.customer);
        this.sub(this.cropVarietyName);
        this.sub(this.publicCV);
        this.sub(this.cvStage);
        this.sub(this.owner);
        this.sub(this.cvCountry);
        this.sub(this.trialYear);
        this.sub(this.cvStageYear);
        this.sub(this.cvControl);
        this.sub(this.external);
        this.sub(this.tpp);
        this.sub(this.project);

        this.sub(this.selected);

        this.onChange();
        this.onChangeKPI();
      }
    );
  }

  private sub(obs: KnockoutObservable<{}>) {
    this.subscriptions.push(obs.subscribe(this.onChange));
  }

  dispose() {
    for (let sub of this.subscriptions) {
      sub.dispose();
    }
  }

  onChange = () => {
    updateLocationWithQueryString(this.toLinkData());

    this.fetch('map', trialLocations, (data) => this.mapData(getTrialLocationsMap(data)));
    this.fetch('trials_charts', trialsCharts, this.trialsChartsData);
    this.fetch('varieties_charts', varietiesCharts, this.varietiesChartsData);
    this.fetch('all_varieties', allVarieties, this.allVarietiesData);
    this.fetch('trialled_varieties', trialledVarieties, this.trialledVarietiesData);
    this.fetch('stage_transitions', stageTransitions, this.stageTransitionsData);
    this.fetch('portfolio_status', portfolioStatus, this.portfolioStatusData);
  };

  private fetch<T>(tab: string, req: (params: OverviewParams) => Promise<T>, target: (param: T) => void) {
    if (this.selected() !== tab) {
      return;
    }

    let requestNumber = ++this.requestNumber;

    this.loading(true);
    req(this.toData())
      .then((data) => {
        if (requestNumber !== this.requestNumber) {
          return;
        }

        target(data);
        this.loading(false);
      })
      .catch((e) => {
        this.loading(false);
        throw e;
      });
  }

  onChangeKPI = () => {
    let requestNumberKPI = ++this.requestNumberKPI;
    this.loadingKPI(true);

    kpi()
      .then((data) => {
        if (requestNumberKPI !== this.requestNumberKPI) {
          return;
        }

        this.kpiData(data);
        this.loadingKPI(false);
      })
      .catch((e) => {
        this.loadingKPI(false);
        throw e;
      });
  };

  selectTrialsCharts = () => {
    this.selected('trials_charts');
  };

  selectVarietiesCharts = () => {
    this.selected('varieties_charts');
  };

  selectMap = () => {
    this.selected('map');
  };

  selectAllVarieties = () => {
    this.selected('all_varieties');
  };

  selectTrialledVarieties = () => {
    this.selected('trialled_varieties');
  };

  selectStageTransitions = () => {
    this.selected('stage_transitions');
  };

  selectPortfolioStatus = () => {
    this.selected('portfolio_status');
  };

  showFullscreenChart = (chart: BarChartConfig) => {
    this.downloadableChart({ ...chart, fontSize: chart.fontSize * 2 });
    this.fullscreenChart(chart);
  };

  hideFullscreenChart = () => {
    this.fullscreenChart(null);
    this.downloadableChart(null);
  };

  downloadChart = () => {
    let canvas = <HTMLCanvasElement>document.getElementById('download-chart');
    let fileName = this.fullscreenChart().title + '.png';
    if ((canvas as any).msToBlob) {
      downloadBlob((canvas as any).msToBlob(), fileName);
    } else {
      canvas.toBlob((blob) => {
        downloadBlob(blob, fileName);
      });
    }
  };

  showVarietyStageChangeChart = (variety: { id: string; name: string | I18nText }) => {
    openVarietyStageChangeChart(variety);
  };

  exportAllVarieties = () => {
    this.export(this.exportingAllVarieties, allVarietiesExport, 'all_varieties');
  };

  exportTrialledVarieties = () => {
    this.export(this.exportingTrialledVarieties, trialledVarietiesExport, 'trialled_varieties');
  };

  exportStageTransitions = () => {
    this.export(this.exportingStageTransitions, stageTransitionsExport, 'stage_transitions');
  };

  exportTrialsChartsData = () => {
    this.export(this.exportingTrialsChartsData, trialsChartsExport, 'trials_charts_data');
  };

  private export(
    obs: KnockoutObservable<boolean>,
    api: (params: OverviewParams) => Promise<Blob>,
    name: string
  ) {
    obs(true);
    api(this.toData())
      .then((data) => {
        obs(false);
        downloadBlob(data, name + '.xlsx');
      })
      .catch(() => {
        obs(false);
      });
  }

  private toLinkData() {
    let data: { [key: string]: {} } = {
      selected: this.selected(),
      ...this.toData(),
    };

    for (let k in data) {
      if (k === 'selected') {
        if (data[k] === 'trials_charts') {
          data[k] = undefined;
        }
        continue;
      }
      if (k === 'commercial' || k === 'public_cv' || k === 'cv_control') {
        if (data[k] === 'both' || data[k] === 'any') {
          data[k] = undefined;
        }
        continue;
      }
      if (data[k] === true) {
        data[k] = 'yes';
      }
      if (data[k] === false) {
        data[k] = undefined;
      }
    }

    return data;
  }

  private toData(): OverviewParams {
    return {
      from_planting_date: serializeDate(this.fromPlantingDate()),
      to_planting_date: serializeDate(this.toPlantingDate()),
      crop: extractId(this.crop()),
      commercial: this.commercial(),
      customer: extractId(this.customer()),
      crop_variety_name: this.cropVarietyName(),
      public_cv: this.publicCV(),
      cv_stage: extractId(this.cvStage()),
      cv_country: extractId(this.cvCountry()),
      owner: extractId(this.owner()),
      trial_year: toYearValue(this.trialYear),
      cv_stage_year: toYearValue(this.cvStageYear),
      cv_control: this.cvControl(),
      external: this.external(),
      tpp: extractId(this.tpp()),
      project: extractId(this.project()),
    };
  }
}

function extractId(x: { id?: string }) {
  return x ? x.id : null;
}

export let overview = {
  name: 'overview',
  viewModel: OverviewScreen,
  template: template,
};

ko.components.register(overview.name, overview);
