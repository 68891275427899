import * as ko from 'knockout';

import { AttributeChoiceListData, AttributeChoiceData } from '../api/attribute_choice_lists';
import { I18nText } from '../i18n_text';

export class AttributeChoice {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  code = ko.observable<string>().extend({
    serverError: true,
  });

  constructor(data?: AttributeChoiceData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
      this.code(data.code);
    }
  }

  toData(): AttributeChoiceData {
    const data = {
      id: this.id(),
      name_json: this.nameJson(),
      code: this.code(),
    };
    return data;
  }
}

export class AttributeChoiceList {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  choices = ko.observableArray<AttributeChoice>();

  editUrl = ko.pureComputed(() => {
    return '/attribute_choice_lists/' + this.id() + '/';
  });

  constructor(data?: AttributeChoiceListData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);

      if (data.choices) {
        this.choices(data.choices.map((data) => new AttributeChoice(data)));
      }
    }
  }

  toData(): AttributeChoiceListData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
      choices: this.choices().map((choice) => choice.toData()),
    };
  }
}
