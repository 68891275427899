/// <reference path="./type_definitions/pickadate.d.ts" />

import * as ko from 'knockout';
import * as i18nextko from '../js/i18next-ko.js';

import { requestWithStatus } from './api/base_request';

interface InterpolationOptions {
  escapeValue?: boolean;
  prefix?: string;
  suffix?: string;
  prefixEscaped?: string;
  suffixEscaped?: string;
  unescapeSuffix?: string;
  unescapePrefix?: string;
  nestingPrefix?: string;
  nestingSuffix?: string;
  nestingPrefixEscaped?: string;
  nestedSuffixEscaped?: string;
  defaultVariables?: any;
}

interface TranslationOptions {
  defaultValue?: string;
  count?: number;
  context?: any;
  replace?: any;
  lng?: string;
  lngs?: string[];
  fallbackLng?: string;
  ns?: string | string[];
  keySeparator?: string;
  nsSeparator?: string;
  returnObjects?: boolean;
  joinArrays?: string;
  postProcess?: string | any[];
  interpolation?: InterpolationOptions;
}

interface ResourceStore {
  [language: string]: ResourceStoreLanguage;
}
interface ResourceStoreLanguage {
  [namespace: string]: ResourceStoreKey;
}
interface ResourceStoreKey {
  [key: string]: any;
}

interface ResourceStoreResult {
  status: number;
  result: ResourceStoreKey;
}

interface TranslationFunction {
  (key: string | string[], options?: any): KnockoutComputed<string>;
}

interface I18NextKo {
  init(
    resourceStore: ResourceStore,
    language: string,
    ko?: {},
    jquery?: any,
    translationOptions?: TranslationOptions
  ): void;
  setLanguage(language: string): void;
  t: TranslationFunction;
}

type Language = 'es' | 'en' | 'fr' | 'pt-br' | 'ar' | 'de' | 'cs' | 'sk' | 'hr' | 'pl' | 'hu' | 'ro' | 'bg' | 'tr' | 'it' ;

// currently we don't support any dialect or cultures
// this need to download the correct translations file
// and to keep UI languages as-is
export const LANGUAGEMAP = {
  en: 'en',
  es: 'es',
  fr: 'fr',
  pt: 'pt-br',
  'pt-br': 'pt-br',
  ar: 'ar',
  de: 'de',
  cs: 'cs',
  sk: 'sk',
  hr: 'hr',
  pl: 'pl',
  hu: 'hu',
  ro: 'ro',
  bg: 'bg',
  tr: 'tr',
  it: 'it',
};

// A data language is a language that is used by the user to store data. For exampple,
// if the user wants to enter a Spanish variety name, the data language is Spanish.
//
// The language which can be set from the Settings page is a UI language. For a variety of
// reasons, we may not support all UI languages as data languages.
export const UNSUPPORTED_DATA_LANGUAGES = ['it'];

class MyI18n {
  i18nko: I18NextKo = i18nextko;
  languageCode: string;
  userInputLanguage = ko.observable<Language>('en');
  showDetails = ko.observable(localStorage.getItem('showTranslationsDetails') === 'true');
  isTranslationReady = ko.observable(false);
  private subscriptions: KnockoutSubscription[] = [];

  constructor() {
    this.initializeTranslationModule('en', null); // first we load english
    this.initializeTranslations().then((value) => {
      this.isTranslationReady(true);
    });
  }

  t(key: string | string[], options?: any): KnockoutComputed<string> {
    // as of now the only kown character that i18next messes up is the slash
    return ko.pureComputed(() => this.i18nko.t(key, options)().replace('&#x2F;', '/'));
  }

  setupKnockoutValidationLocalization() {
    let preferedLanguage = this.getUserPreferedLanguage();
    ko.validation.defineLocale(preferedLanguage, this.getKnockoutValidationSettings());
    ko.validation.locale(preferedLanguage);
  }

  private getKnockoutValidationSettings = (): KnockoutValidationLocalizationDictionary => {
    // you can use link below to validate translation validity for some languages
    // https://github.com/Knockout-Contrib/Knockout-Validation/blob/master/localization/
    return {
      required: this.t('This field is required.')(),
      min: this.t('Please enter a value greater than or equal to {0}.')(),
      max: this.t('Please enter a value less than or equal to {0}.')(),
      minLength: this.t('Please enter at least {0} characters.')(),
      maxLength: this.t('Please enter no more than {0} characters.')(),
      pattern: this.t('Please check this value.')(),
      step: this.t('The value must increment by {0}.')(),
      email: this.t('Please enter a proper email address.')(),
      date: this.t('Please enter a proper date.')(),
      dateISO: this.t('Please enter a proper date.')(),
      number: this.t('Please enter a number.')(),
      digit: this.t('Please enter a digit.')(),
      phoneUS: this.t('Please specify a valid phone number.')(),
      equal: this.t('Values must equal.')(),
      notEqual: this.t('Please choose another value.')(),
      unique: this.t('Please make sure the value is unique.')(),
    };
  };

  getDatePickerSettings = (): Pickadate.DateOptions => {
    // more available from https://github.com/amsul/pickadate.js/tree/3.5.6/lib/translations
    // this must be a special case or else the translators will for sure do something wrong
    // also, we would need this for firstDay anyway
    let languageCode = this.getUserPreferedLanguage();
    if (languageCode == 'pt-br') {
      return {
        monthsFull: [
          'janeiro',
          'fevereiro',
          'março',
          'abril',
          'maio',
          'junho',
          'julho',
          'agosto',
          'setembro',
          'outubro',
          'novembro',
          'dezembro',
        ],
        monthsShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
        weekdaysFull: [
          'domingo',
          'segunda-feira',
          'terça-feira',
          'quarta-feira',
          'quinta-feira',
          'sexta-feira',
          'sábado',
        ],
        weekdaysShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'],
        today: 'hoje',
        clear: 'limpar',
        close: 'fechar',
        format: 'dd de mmmm de yyyy',
        formatSubmit: 'yyyy/mm/dd',
      };
    }
    if (languageCode == 'fr') {
      return {
        monthsFull: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        monthsShort: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
        weekdaysFull: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        today: "Aujourd'hui",
        clear: 'Effacer',
        close: 'Fermer',
        firstDay: 1,
        format: 'dd mmmm yyyy',
        // formatSubmit: 'yyyy/mm/dd',
        labelMonthNext: 'Mois suivant',
        labelMonthPrev: 'Mois précédent',
        labelMonthSelect: 'Sélectionner un mois',
        labelYearSelect: 'Sélectionner une année',
      };
    }

    return {
      monthsFull: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      format: 'dd mmmm, yyyy',
    };
  };

  initializeTranslations = (): Promise<{}> => {
    // first we load english
    this.initializeTranslationModule('en', null);
    // then we asyncronisly load the rest
    let languageCode = this.getUserPreferedLanguage();
    if (languageCode) {
      // for user-translated strings, ignore the locale culture
      languageCode = languageCode.replace('-', '_').split('_')[0].toLowerCase() as Language;
    }
    this.userInputLanguage(languageCode);

    if (languageCode === 'en') {
      return Promise.resolve(null);
    }
    return this.loadTranslationFromServer(languageCode).then((langResult) => {
      this.initializeTranslationModule(languageCode, langResult);
      return null;
    }).catch((error) => {
      console.error('Error loading translations for ' + languageCode, error);
    });
  };

  setUserPreferedLanguage = (preferedLanguage: string): void => {
    localStorage.setItem('userLanguage', preferedLanguage);
  };

  getUserPreferedLanguage = (): Language => {
    return (localStorage.getItem('userLanguage') as Language) ?? 'en';
  };

  setShowDetails = (show: boolean): void => {
    localStorage.setItem('showTranslationsDetails', show ? 'true' : undefined);
    this.showDetails(show);
  };

  onTranslationReady = (callback: () => void): void => {
    if (this.isTranslationReady()) {
      callback();
    } else {
      this.subscriptions.push(this.isTranslationReady.subscribe((value) => {
        if (value) {
          callback();
        }
      })
      );
    }
  };

  dispose() {
    for (let sub of this.subscriptions) {
      sub.dispose();
    }
    this.subscriptions = [];
  }

  private loadTranslationFromServer(language: Language): Promise<ResourceStoreResult> {
    return requestWithStatus('GET', '/static/i18n/' + LANGUAGEMAP[language] + '.json', null);
  }

  private initializeTranslationModule = (languageCode: string, langResult: ResourceStoreResult) => {
    let languageObj: ResourceStore = {};
    let availableLanguageCode = languageCode;
    if (langResult !== null && langResult.status === 200) {
      languageObj[availableLanguageCode] = {
        translation: langResult.result,
      };
    } else {
      availableLanguageCode = 'en';
    }
    i18nextko.init(languageObj, availableLanguageCode, ko, false, {
      nsSeparator: false,
      keySeparator: false,
    });
    this.languageCode = languageCode;
  };
}

let i18n = new MyI18n();
export default i18n;
