import * as ko from 'knockout';

import i18n from '../i18n';
import * as trialsApi from '../api/trials';
import { ListLoaderDelegate } from '../components/list_loader';
import { ListRequestParams } from '../api/request';
import { I18nText } from '../i18n_text';
import { parseDateTime } from '../api/serialization';
import { BarChartConfig } from '../ko_bindings/bar_chart';

let template = require('raw-loader!../../templates/trial_activity_status.html').default;

type Tab = 'status' | 'active_trials';

class TrialActivityStatusScreen
  implements ListLoaderDelegate<trialsApi.TrialActivityStatusData, TrialActivityStatus>
{
  statusChart = ko.observable<BarChartConfig>();
  loadingStatusChart = ko.observable(true);
  selected = ko.observable<Tab>('status');

  constructor() {
    this.loadStatusChart();
  }

  private loadStatusChart() {
    this.loadingStatusChart(true);

    trialsApi.activitySummary().then((data) => {
      this.statusChart({
        type: 'bar',
        title: i18n.t('Active Trials')(),
        xTitle: '',
        fontSize: 12,
        labels: [i18n.t('Total active')(), i18n.t('No activity in 3 weeks')()],
        datasets: [
          {
            label: '',
            color_key: '',
            data: [
              { x: 0, y: data.total },
              { x: 1, y: data.inactive },
            ],
          },
        ],
      });
      this.loadingStatusChart(false);
    });
  }

  selectStatus = () => {
    this.selected('status');
    this.loadStatusChart();
  };

  selectActiveTrials = () => {
    this.selected('active_trials');
  };

  fetch(params: ListRequestParams) {
    return trialsApi.listActivityStatus(params);
  }

  instantiate(data: trialsApi.TrialActivityStatusData) {
    return new TrialActivityStatus(data);
  }

  remove(id: string) {
    return Promise.resolve(null);
  }

  canRemove(entity: TrialActivityStatus) {
    return false;
  }
}

class TrialActivityStatus {
  id: string;
  nameJson: I18nText;
  lastTimestamp: string;
  inactive: boolean;
  expanded = ko.observable(false);

  constructor(data: trialsApi.TrialActivityStatusData) {
    this.id = data.id;
    this.nameJson = data.name_json;
    this.lastTimestamp = data.last_timestamp;

    let time = parseDateTime(this.lastTimestamp);
    // inactive if last measurement more than 3 weeks ago
    this.inactive = !time || new Date().getTime() - time.getTime() > 3 * 7 * 24 * 60 * 60 * 1000;
  }

  toggleExpanded = () => {
    this.expanded(!this.expanded());
  };
}

export let trialActivityStatus = {
  name: 'trait-activity-status',
  viewModel: TrialActivityStatusScreen,
  template: template,
};

ko.components.register(trialActivityStatus.name, trialActivityStatus);
