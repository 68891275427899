import * as ko from 'knockout';
import 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, ChartConfiguration, TitleOptions } from 'chart.js';

Chart.register(annotationPlugin);

export const defaultTitle: Partial<TitleOptions> = {
  display: true,
  color: 'black',
  position: 'top',
  font: { size: 15},
  padding: 1,
}

function setup(element: Element, config: ChartConfiguration): Chart {
  let chart: Chart;
  if (config) {
    config.options = config.options || {};
    chart = new Chart(<HTMLCanvasElement>element, config);
    
  }
  return chart;
}

function updateChart(chart: Chart, config: ChartConfiguration): Chart {
  chart.data = config.data;
  chart.options = config.options;
  chart.update();
  return chart;
}



ko.bindingHandlers['regularChart'] = {
  init: (element: Element, valueAccessor: () => KnockoutObservable<any>) => {
    const chart = setup(element, ko.unwrap(valueAccessor()));
    (element as any)['chart'] = chart;
  },
  update: (element: Element, valueAccessor: () => KnockoutObservable<any>) => {
    const chart = updateChart((element as any)['chart'], ko.unwrap(valueAccessor()));
    (element as any)['chart'] = chart;
  },
};
