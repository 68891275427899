export class OrderedEntities<T extends { order: KnockoutObservable<number> }> {
  entities: KnockoutObservableArray<T>;

  constructor(entities: KnockoutObservableArray<T>) {
    this.entities = entities;
    this.sort();
    this.compactOrder();
  }

  private sort() {
    this.entities.sort((a, b) => {
      return a.order() - b.order();
    });
  }

  private compactOrder() {
    // ensure order is contiguous
    let order = 0;
    for (let entity of this.entities()) {
      entity.order(order);
      order++;
    }
  }

  nextOrder() {
    if (this.entities().length === 0) {
      return 0;
    } else {
      return (
        Math.max.apply(
          this,
          this.entities().map((entity) => entity.order())
        ) + 1
      );
    }
  }

  add(entity: T) {
    entity.order(this.nextOrder());
    this.entities.push(entity);
    this.sort();

    return entity;
  }

  remove(entity: T) {
    this.entities.remove(entity);
    this.sort();
    this.compactOrder();
  }

  moveUp(entity: T) {
    this.move(entity, -1);
  }

  moveDown(entity: T) {
    this.move(entity, +1);
  }

  private move(entity: T, direction: number) {
    let entities = this.entities();
    let newIdx = entity.order() + direction;

    if (newIdx < 0 || newIdx >= entities.length) {
      return;
    }

    let swapWithIdx = entities.indexOf(entity) + direction;
    if (swapWithIdx >= 0 && swapWithIdx < entities.length) {
      let swapWith = entities[swapWithIdx];
      swapWith.order(swapWith.order() - direction);
    }

    entity.order(newIdx);

    this.sort();
  }
}
