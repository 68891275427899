import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import i18n from '../i18n';
import { app } from '../app';
import { GeoZoneColored, SiteGeoZoneType } from '../api/sites';

let template = require('raw-loader!../../templates/components/map_zone.html').default;

class MapZone {
  placeholderText = i18n.t('Search location')();

  zoneId: string;
  zoneType: SiteGeoZoneType;
  sites: [];
  zonesColored: GeoZoneColored[];
  result: Deferred<{}>;
  loading: KnockoutObservable<boolean>;

  constructor(params: {zoneId: string, zoneType: SiteGeoZoneType, sites: [], zonesColored: GeoZoneColored[], result?: Deferred<{}>}) {
    this.zoneId = params.zoneId;
    this.zoneType = params.zoneType;
    this.sites = params.sites;
    this.zonesColored = params.zonesColored;
    this.result = params.result;
    this.loading = ko.observable(false);
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

ko.components.register('map-zone', { viewModel: MapZone, template: template });

export function selectLocationZonePopup(zoneId: string, zoneType: SiteGeoZoneType, sites: [], zonesColored: GeoZoneColored[]) {
  app.formsStackController.push({
    title: i18n.t('Location')(),
    name: 'map-zone',
    isBig: true,
    params: {
      zoneId,
      zoneType,
      sites,
      zonesColored,
      result: new Deferred<{}>(),
    },
  });
}
