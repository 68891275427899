import * as ko from 'knockout';
import page from 'page';

import { app } from '../app';
import { session } from '../session';
import { getExpirationDays } from '../models/tenant';
import * as tenantsApi from '../api/base_tenants';
import i18n from '../i18n';
import { waitForElement } from '../utils';

let template = require('raw-loader!../../templates/login.html').default;

interface LoginParams {
  motto: string;
  showFooter: boolean;
}

let loginParams: LoginParams;

export function setLoginParams(params: LoginParams) {
  loginParams = params;
}
class LoginScreen {
  allowSignUp = SERVER_INFO.ALLOW_SIGNUP;
  supportEmail = SERVER_INFO.SUPPORT_EMAIL;
  loading = ko.observable<boolean>(false);
  loggedIn = session.loggedIn;
  authorized = session.authorized;
  needsEmailVerification = session.needsEmailVerification;
  userEmail = ko.observable('');
  authorizedTenants = ko.pureComputed(() => {
    return this.getAuthorizedTenantsList();
  });
  firebaseTenantId = ko.observable<string>();
  tenantsByEmail = ko.observableArray<tenantsApi.TenantPublicData>();
  loadingTenantsByEmail = ko.observable(false);
  tenantsByEmailError = ko.observable('');
  loginEmail = ko.observable('').extend({ required: true, serverError: true });

  private subscription: KnockoutSubscription = null;
  public params: LoginParams;

  constructor() {
    this.params = loginParams;
    this.subscription = session.loggedIn.subscribe(this.onLoginStateChange.bind(this));
    this.onLoginStateChange(session.loggedIn());
  }

  async loadTenantsByEmail() {
    this.loadingTenantsByEmail(true);
    try {
      const tenants = await tenantsApi.tenantsByEmail(this.loginEmail());
      this.tenantsByEmail(tenants);
      if (tenants.length === 0) {
        this.tenantsByEmailError(i18n.t('Please check your email address.')());
      } else if (tenants.length === 1) {
        this.firebaseTenantId(tenants[0].firebase_id);
      }
      this.proceedToFirebasePasswordView();
    } finally {
      this.loadingTenantsByEmail(false);
    }
  }

  setSelectedTenant = (() => (tenant: tenantsApi.TenantPublicData) => {
    this.firebaseTenantId(tenant.firebase_id);
  })().bind(this);

  private getAuthorizedTenantsList() {
    let tenants = session.tenants();
    tenants.map((tenant) => {
      tenant.expirationTitle = tenant.is_expired
        ? i18n.t('Subscription of this organization is expired')()
        : '';
    });
    return tenants;
  }

  resendEmailVerification() {
    session.sendEmailVerification();
  }

  private async proceedToFirebasePasswordView() {
    // By default, Firebase asks to enter the email first and then the password, but we already know
    // the user's email from the previous step, so we auto-fill it and go straight to the password.
    const firebaseEmailInput = (await waitForElement('#ui-sign-in-email-input')) as HTMLInputElement;
    const firebaseEmailSubmit = (await waitForElement('.firebaseui-id-submit')) as HTMLElement;
    firebaseEmailInput.value = this.loginEmail();
    firebaseEmailSubmit.click();
  }

  private onLoginStateChange(loggedIn: boolean) {
    this.userEmail(session.getEmail());

    if (!loggedIn || session.needsEmailVerification()) {
      return;
    }

    this.loading(true);
    session
      .getToken()
      .then((token) => tenantsApi.authorizations(token, true))
      .then(({ tenants }) => {
        this.loading(false);

        if (!session.loggedIn()) {
          // user logged out while we were loading list
          return;
        }

        this.userEmail(session.getEmail());
        session.tenants(tenants);

        let slug = session.getLocationTenantSlug(location.pathname);
        if (slug) {
          var tenant: tenantsApi.TenantData = null;
          for (let aTenant of tenants) {
            if (aTenant.slug === slug) {
              tenant = aTenant;
              break;
            }
          }
          if (tenant) {
            this.openDirect(tenant);
          } else if (tenants.length > 0) {
            // user has selected a tenant he doesn't have access to
            // show him tenant list instead
            page('/');
          }
        } else {
          if (tenants.length === 1) {
            this.openDirect(tenants[0]);
          }
        }
      });
  }

  private openDirect(tenant: tenantsApi.TenantData) {
    if (location.pathname === '/') {
      (<any>page).replace(session.toTenantPath('/', tenant));
    } else {
      (<any>page).replace(location.pathname + location.search);
    }
  }

  dispose() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }
}

export let login = {
  name: 'login',
  viewModel: LoginScreen,
  template: template,
};

ko.components.register(login.name, login);

export function requireAuthorized(ctx: PageJS.Context, next: () => any) {
  if (session.authorized()) {
    let days = getExpirationDays();
    if (days !== null && days <= 0) {
      page.redirect(session.toTenantPath('/subscription/'));
    }
    next();
  } else {
    app.currentPage({ name: login.name });
  }
}

export function requireGDMAuthorized(ctx: PageJS.Context, next: () => any) {
  // only for GDM users
  if (session.authorized()) {
    let days = getExpirationDays();
    if (days !== null && days <= 0) {
      page.redirect(session.toTenantPath('/subscription/'));
    }

    if (session.tenant().slug === 'gdm_seeds') {
      next();
    } else {
      page.redirect('/');
    }
  } else {
    app.currentPage({ name: login.name });
  }
}

export function requireAuthorizedCanBeExpired(ctx: PageJS.Context, next: () => any) {
  if (session.authorized()) {
    next();
  } else {
    app.currentPage({ name: login.name });
  }
}

export function requireLoggedIn(ctx: PageJS.Context, next: () => any) {
  if (session.loggedIn()) {
    next();
  } else {
    page.redirect('/');
  }
}
