import * as ko from 'knockout';

import i18n from '../i18n';
import * as trialsApi from '../api/trials';
import { ImportEntitiesDelegate } from '../components/import_entities';
import { session } from '../session';
import {
  downloadDataEntryImportTemplate,
  DataEntryImportParams,
  getBasicTrialInfo,
} from '../data_entry/data_entry_api';
import { listParamsToQueryString } from '../api/request';
import { LANGUAGES } from '../i18n_text';

let template = require('raw-loader!../../templates/import_facts.html').default;

class ImportFactsScreen implements ImportEntitiesDelegate {
  private trialId: string;
  private visitParams: DataEntryImportParams;
  private trialCode: string;

  title = i18n.t('Import observations');
  description = i18n.t(
    'To import observations, download the Excel template, fill-in the desired observations and then upload the file.'
  )();
  backTitle = i18n.t('Go back')();
  links: { href: string; title: string }[] = [];
  languageList = SERVER_INFO.LANGUAGE_LIST;
  dataLanguageList = LANGUAGES.map((language) => ({
    code: language[0],
    name: language[2],
  }));
  selectedLanguage = ko.observable(i18n.getUserPreferedLanguage());
  selectedDataLanguage = ko.observable(i18n.getUserPreferedLanguage());

  templateBaseName = ko.observable('observations');
  importUrl: string;

  constructor(params: {
    id: string;
    importFilter: {
      data_entry?: boolean;
      sv_id?: string;
      site_id?: string;
      visit_id?: string;
    };
  }) {
    this.trialId = params.id;

    getBasicTrialInfo(this.trialId).then((basicTrialInfo) => {
      this.trialCode = basicTrialInfo.name_slug;
      this.computeTemplateBaseName();
    });

    if (params.importFilter.data_entry) {
      this.visitParams = {
        trial_id: this.trialId,
        sv_id: params.importFilter.sv_id,
        site_id: params.importFilter.site_id,
        visit_id: params.importFilter.visit_id,
        language: this.selectedLanguage(),
      };
      this.importUrl = `/api/data_entry/import_facts/?${listParamsToQueryString(this.visitParams as {})}`;
    } else {
      this.visitParams = null;
      this.importUrl = '/api/trials/' + this.trialId + '/import_facts/';
      this.links = [
        {
          title: i18n.t('Bulk update existing observations')(),
          href: session.toTenantPath(`/trials/${this.trialId}/import_update_observations/`),
        },
      ];
    }
  }

  computeTemplateBaseName() {
    const language = this.languageList.find((language) => language.code == this.selectedLanguage());
    this.templateBaseName(`Observations - ${language.name} - ${this.trialCode}`);
  }

  downloadTemplate(): Promise<Blob> {
    // Calling the compute of the since the value of the selectedLanguage can be changed
    this.computeTemplateBaseName();
    if (this.visitParams) {
      return downloadDataEntryImportTemplate({
        ...this.visitParams,
        language: this.selectedLanguage(),
        dataLanguage: this.selectedDataLanguage(),
      });
    } else {
      return trialsApi.downloadImportTemplate(
        this.trialId,
        this.selectedLanguage(),
        this.selectedDataLanguage()
      );
    }
  }
}

export let importFacts = {
  name: 'import-facts',
  viewModel: ImportFactsScreen,
  template: template,
};

ko.components.register(importFacts.name, importFacts);
