import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate, ListLoader } from '../components/list_loader';
import * as trialDocumentsApi from '../api/trial_documents';
import * as usersApi from '../api/users';
import i18n from '../i18n';
import { FilterDelegate } from '../components/list_filters';
import { DocumentCategoryData, list as listCategories } from '../api/document_categories';
import { ListHeaderAction } from '../components/list_header';
import { asArray, updateLocationWithQueryString } from '../utils';
import { app } from '../app';
import { trialDocumentsDownload } from '../components/trial_documents_download';
import { Deferred } from '../utils/deferred';
import { removeDialog } from '../components/remove_dialog';

let template = require('raw-loader!../../templates/trial_documents.html').default;

interface TrialDocumentFilters {
  name: string;
  category_ids: string | string[];
  created_by_ids: string | string[];
  modified_by_ids: string | string[];
  sync: string;
}

let currentFilters: TrialDocumentFilters | null = null;

class TrialDocumentsScreen implements ListLoaderDelegate<trialDocumentsApi.TrialDocumentData> {
  trialId: string;
  template: boolean;

  loading = ko.observable(true);

  private syncChoices = [
    { name: i18n.t('Both')(), id: '' },
    { name: i18n.t('No')(), id: 'false' },
    { name: i18n.t('Yes')(), id: 'true' },
  ];
  private loader: ListLoader<trialDocumentsApi.TrialDocumentData>;

  name = ko.observable('');
  categoriesFilter = ko.observableArray<DocumentCategoryData>();
  createdByFilter = ko.observableArray<usersApi.UserData>();
  modifiedByFilter = ko.observableArray<usersApi.UserData>();
  sync = ko.observable(this.syncChoices[0]);

  newFilters: FilterDelegate[] = [
    { title: i18n.t('Name')(), textValue: this.name },
    {
      title: i18n.t('Tag')(),
      entities: this.categoriesFilter,
      list: listCategories,
    },
    {
      title: i18n.t('Created by')(),
      entities: this.createdByFilter,
      list: usersApi.list,
    },
    {
      title: i18n.t('Modified by')(),
      entities: this.modifiedByFilter,
      list: usersApi.list,
    },
    {
      title: i18n.t('Visible on devices')(),
      choices: this.syncChoices,
      value: this.sync,
    },
  ];

  listActions = ko.observableArray<ListHeaderAction>();
  private loadingForBulkDelete = ko.observable(false);

  constructor(params: { trialId: string; template: boolean; filters: TrialDocumentFilters }) {
    this.trialId = params.trialId;
    this.template = params.template;

    this.listActions.push({
      title: i18n.t('Add archive')(),
      icon: 'unarchive',
      href: `/trials/${this.trialId}/documents/add_archive/`,
    });
    this.listActions.push({
      title: i18n.t('Download archive')(),
      icon: 'file_download',
      onClick: () => {
        app.formsStackController.push({
          title: i18n.t('Download documents archive')(),
          name: trialDocumentsDownload.name,
          params: {
            trialId: this.trialId,
            filters: this.getFilters(),
            result: new Deferred<{}>(),
          },
          showNav: true,
        });
      },
    });
    this.listActions.push({
      title: i18n.t('Bulk delete')(),
      icon: 'delete_outline',
      loading: this.loadingForBulkDelete,
      onClick: () => this.bulkRemove(),
    });

    const filters =
      currentFilters && Object.keys(params.filters).length === 0 ? currentFilters : params.filters;
    if (filters.name) {
      this.name(filters.name);
    }
    this.name = this.name.extend({ rateLimit: 300 });
    this.sync(this.syncChoices.filter((c) => c.id === filters.sync)[0] || this.syncChoices[0]);

    const categoryIds = asArray(filters.category_ids);
    const createdByIds = asArray(filters.created_by_ids);
    const modifiedByIds = asArray(filters.modified_by_ids);
    const categoryPromise =
      categoryIds.length > 0 ? listCategories({ ids: categoryIds }) : Promise.resolve([]);
    const createdByPromise =
      createdByIds.length > 0 ? usersApi.list({ ids: createdByIds }) : Promise.resolve([]);
    const modifiedByPromise =
      modifiedByIds.length > 0 ? usersApi.list({ ids: modifiedByIds }) : Promise.resolve([]);

    Promise.all([categoryPromise, createdByPromise, modifiedByPromise]).then(
      ([categories, createdBy, modifiedBy]) => {
        this.categoriesFilter(categories);
        this.createdByFilter(createdBy);
        this.modifiedByFilter(modifiedBy);

        this.loading(false);
      }
    );
  }

  onReady(loader: ListLoader<trialDocumentsApi.TrialDocumentData>): void {
    this.loader = loader;
  }

  fetch(params: ListRequestParams) {
    currentFilters = this.getFilters();
    updateLocationWithQueryString(currentFilters);

    return trialDocumentsApi.list(this.trialId, {
      ...params,
      ...currentFilters,
    });
  }

  private getFilters(): TrialDocumentFilters {
    return {
      name: this.name().trim(),
      category_ids: this.categoriesFilter().map((c) => c.id),
      created_by_ids: this.createdByFilter().map((u) => u.id),
      modified_by_ids: this.modifiedByFilter().map((u) => u.id),
      sync: this.sync().id,
    };
  }

  instantiate(data: trialDocumentsApi.TrialDocumentData) {
    return data;
  }

  remove(id: string) {
    return trialDocumentsApi.remove(this.trialId, id);
  }

  canRemove(data: trialDocumentsApi.TrialDocumentData) {
    return true;
  }

  fileType(data: trialDocumentsApi.TrialDocumentData): string {
    const mimeType = data.mime_type;

    if (
      mimeType == 'application/msword' ||
      mimeType == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return 'file-type-word';
    } else if (
      mimeType == 'application/vnd.ms-excel' ||
      mimeType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      return 'file-type-excel';
    } else if (
      mimeType == 'application/vnd.ms-powerpoint' ||
      mimeType == 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ) {
      return 'file-type-powerpoint';
    } else if (mimeType == 'application/pdf') {
      return 'file-type-pdf';
    } else if (mimeType.indexOf('image/') === 0) {
      return 'file-type-pic';
    } else {
      return 'file-type-other';
    }
  }

  getName(data: trialDocumentsApi.TrialDocumentData): string {
    return data.user_file_name;
  }

  formattedFileSize(data: trialDocumentsApi.TrialDocumentData): string {
    let size = data.file_size;
    let unit = 'B';
    const gb = 1024 * 1024 * 1024;
    const mb = 1024 * 1024;
    const kb = 1024;

    if (size > gb) {
      // GB
      size /= gb;
      unit = 'GB';
    } else if (size > mb) {
      size /= mb;
      unit = 'MB';
    } else if (size > kb) {
      size /= kb;
      unit = 'KB';
    }

    return `${size.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    })} ${unit}`;
  }

  private async bulkRemove() {
    const filters = this.getFilters();

    this.loadingForBulkDelete(true);
    try {
      const { count } = await trialDocumentsApi.countList(this.trialId, filters);
      const msgs = [
        i18n.t(
          ['permanently_delete_documents', 'You are about to permanently delete {{count}} documents.'],
          { count }
        )(),
        i18n.t('This operation cannot be undone.')(),
        i18n.t('Are you sure you want to continue?')(),
      ];
      await removeDialog(i18n.t('multiple documents')(), msgs, () =>
        trialDocumentsApi.bulkRemove(this.trialId, filters)
      );
      this.loader?.refresh();
    } finally {
      this.loadingForBulkDelete(false);
    }
  }
}

export const trialDocuments = {
  name: 'trial-documents',
  viewModel: TrialDocumentsScreen,
  template: template,
};

ko.components.register(trialDocuments.name, trialDocuments);
