import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { Group } from '../models/group';
import * as groupsApi from '../api/groups';
import * as usersApi from '../api/users';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import i18n from '../i18n';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { app } from '../app';
import { TrialAdvancedSearchResult } from '../components/trial_advanced_search';
import { canEditGroup } from '../permissions';

let template = require('raw-loader!../../templates/group_edit.html').default;

class GroupEditScreen extends BaseForm<groupsApi.BaseGroupData> {
  group = ko.observable<Group>(null);
  userSearchConfig = ko.observable<FormSelectSearchConfiguration<usersApi.UserData>>(null);
  managerSearchConfig = ko.observable<FormSelectSearchConfiguration<usersApi.UserData>>(null);
  canEdit = ko.observable(canEditGroup());
  canSetManagers = ko.observable(false);

  constructor(
    params: {
      id: string;
      initialName: string;
      result?: Deferred<groupsApi.BaseGroupData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let meRequest = usersApi.me();
    let groupRequest = params.id ? groupsApi.retrieve(params.id) : undefined;
    let promise = Promise.all([meRequest, groupRequest]).then(([currentUserData, groupData]) => {
      let isAdmin = currentUserData.role === 'admin';
      this.canSetManagers(this.canEdit() && isAdmin);
      this.group(new Group(isAdmin, groupData));
      this.userSearchConfig(this.createUserSearchConfig(isAdmin, this.group()));
      this.managerSearchConfig(this.createManagerSearchConfig(this.group()));

      if (!groupData && params.initialName) {
        this.group().name(params.initialName);
      }

      if (!groupData && !isAdmin) {
        this.group().managers.push(currentUserData);
      }
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  dispose() {
    if (this.group()) {
      this.group().dispose();
    }
  }

  updatePermissionsByTrialData = (data: TrialAdvancedSearchResult) => {
    const { selected, deleted } = data;

    const existingIds = this.group()
      .dataEntryPermissions.toData()
      .map((item) => item?.trial_id?.id);

    const permissionsToDelete = this.group()
      .dataEntryPermissions.config.entities()
      .filter(
        (permission) => permission.trial() && deleted.map((item) => item.id).includes(permission.trial()?.id)
      );

    permissionsToDelete.map((permission) => {
      this.group().dataEntryPermissions.removeDataEntryPermisson(permission);
    });

    selected
      .filter((item) => !existingIds.includes(item.id))
      .map((item) => {
        const newPermission = this.group().dataEntryPermissions.addDataEntryPermission();
        newPermission.trial(item);
      });
  };

  selectAddTrialAdvanced = () => {
    app.formsStackController
      .push({
        title: i18n.t('Advanced trial search')(),
        name: 'trial-advanced-search',
        isBig: true,
        params: {
          initialName: '',
          allowMultipleSelections: true,
          initialMultipleSelections: this.group()
            .dataEntryPermissions.toData()
            .map((permission) => permission.trial_id),
          result: new Deferred(),
        },
      })
      .then((res: TrialAdvancedSearchResult) => {
        this.updatePermissionsByTrialData(res);
      });
  };

  private createUserSearchConfig(
    isAdmin: boolean,
    group: Group
  ): FormSelectSearchConfiguration<usersApi.UserData> {
    let res: FormSelectSearchConfiguration<usersApi.UserData> = {
      title: i18n.t('Users in group:'),
      getSummaryName: (user) => {
        return user.name + ' (' + user.email + ')';
      },

      list: (params) => {
        return usersApi.list(params);
      },

      entities: group.users,
      advancedSearch: {
        componentName: 'user-advanced-search',
        extraParams: { multi: true },
        instantiate: (data: usersApi.UserData) => data,
        serialize: (data) => data,
      },
    };

    if (isAdmin) {
      res.create = { title: 'User', componentName: 'user-edit' };
    }

    return res;
  }

  private createManagerSearchConfig(group: Group): FormSelectSearchConfiguration<usersApi.UserData> {
    let res: FormSelectSearchConfiguration<usersApi.UserData> = {
      title: i18n.t('Group managers:'),
      getSummaryName: (user) => {
        return user.name + ' (' + user.email + ')';
      },

      list: (params) => {
        return usersApi.list({ ...params, roles: ['editor', 'manager', 'restricted_manager', 'admin'] });
      },

      entities: group.managers,
    };

    return res;
  }

  save = () => {
    if (!this.group()) {
      return;
    }

    if (this.validateLocal(this.group)) {
      let data = this.group().toData();
      this.executeSaveRequest(groupsApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.group(), validation);

        // propagate validation errors down
        if (!validation.isValid) {
          this.group().dataEntryPermissions.applyServerErrors(validation);
        }
      });
    }
  };
}

export let groupEdit = {
  name: 'group-edit',
  viewModel: createWithComponent(GroupEditScreen),
  template: template,
};

ko.components.register(groupEdit.name, groupEdit);
