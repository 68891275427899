import { makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';

export interface MeasurementMetaTagData {
  id?: string;
  name_json: I18nText;
  code?: string;
}

export const { list, retrieve, save, remove } = makeDefaultApi<MeasurementMetaTagData, { ids?: string[] }>(
  'measurement_meta_tags'
);
