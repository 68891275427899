import * as ko from 'knockout';
import { app } from '../../app';
import i18n from '../../i18n';
import { I18nText, translate } from '../../i18n_text';
import { Treatment } from '../../models/treatment';
import { TrialWizard } from '../../models/trial';
import { Deferred } from '../../utils/deferred';
import { EDITABLE_TRIAL_STATES } from '../../models/TrialState';
import { trimObjectValues } from '../../utils';
import { DatasetDimensionMeta } from '../../models/dataset_dimension_meta';

let template =
  require('raw-loader!../../../templates/components/trial_wizard/treatment_edit_modal.html').default;

interface Option {
  value: string;
  title: string;
}

type OnSave = (newTreatmentName: ko.Observable<I18nText>, newTreatmentFactors: ko.Observable[], validationError: ko.Observable<string>) => void;
type GetOptionsForTestSubject = (arg0: DatasetDimensionMeta) => Option[];

class TreatmentEditModal {
  originalTreatment: Treatment;
  result: Deferred<null>;
  trialWizard: TrialWizard;
  onSave: OnSave;
  saving = ko.observable(false);
  translateNameJson = translate;
  newTreatmentFactors: ko.Observable[] = [];
  newTreatmentName = ko.observable<I18nText>().extend({ serverError: true, required: true });
  validationError: ko.Observable<string> = ko.observable('');
  isTrialInEditableState = ko.computed(() => {
    if(!this.trialWizard) {
      return false;
    }
    return EDITABLE_TRIAL_STATES.includes(this.trialWizard.trial().state());
  });



  getOptionsForTestSubject: GetOptionsForTestSubject;

  constructor(params: {
    trialWizard: TrialWizard;
    treatment: Treatment;
    onSave: OnSave;
    getOptionsForTestSubject: GetOptionsForTestSubject;
    result: Deferred<null>;
  }) {
    this.newTreatmentName(params.treatment.nameJson());
    this.originalTreatment = params.treatment;
    this.trialWizard = params.trialWizard;
    this.getOptionsForTestSubject = params.getOptionsForTestSubject;

    const defaultValuesByTestSubject: Record<string, string> = this.originalTreatment
      .factors()
      .reduce<{ [index: number]: string }>((acc, factor) => {
        return {
          ...acc,
          [factor.dimension().dimension_meta_id()]: factor.dimension().id(),
        };
      }, {});

    // Fill the Treatment factors with empty observables
    this.newTreatmentFactors = this.trialWizard.testSubjects().map((testSubject: DatasetDimensionMeta) => {
      return ko
        .observable(defaultValuesByTestSubject[testSubject.dimensionMeta().id()])
        .extend({ serverError: true, required: true });
    });

    this.onSave = params.onSave;
    this.result = params.result;
  }

  save = async () => {
    this.saving(true);

    this.newTreatmentName(
      trimObjectValues(this.newTreatmentName())
    );

    try {
      this.onSave(this.newTreatmentName, this.newTreatmentFactors, this.validationError);

      this.result.resolve(null);
    } catch (e) {
      // Do nothing
    } finally {
      this.saving(false);
    }
  };

  close = () => {
    this.result.reject();
  };
}

export let treatmentEdit = {
  name: 'treatment-edit-modal',
  viewModel: TreatmentEditModal,
  template: template,
};

ko.components.register(treatmentEdit.name, treatmentEdit);

export function openTreatmentEdit(
  treatment: Treatment,
  onSave: OnSave,
  trialWizard: TrialWizard,
  getOptionsForTestSubject: GetOptionsForTestSubject,
  result: Promise<void>
) {
  return app.formsStackController.push({
    isBig: false,
    showNav: true,
    title: i18n.t(`Edit ${translate(treatment.nameJson())}`)(),
    name: treatmentEdit.name,
    params: {
      onSave: onSave,
      treatment: treatment,
      trialWizard: trialWizard,
      getOptionsForTestSubject,
      result: new Deferred<null>(),
    },
  });
}
