import { FileUploadDelegate } from './components/basic_widgets';
import { CloudStorageUpload, FileUploadEndpoint, CloudStorageUploadDelegate } from './cloud_storage_upload';
import i18n from './i18n';

export interface VideoUploadDelegate {
  getVideoUploadEndpoint(contentType: string): Promise<FileUploadEndpoint>;
  onVideoClick?(): void;
}

const SIZE_LIMIT = 50 * 1024 * 1024 // 50MB

export class VideoUpload implements FileUploadDelegate, CloudStorageUploadDelegate {
  canReuseEndpoint = true;

  fileName: string = null;
  videoPublicURL = ko.observable<string>(null);

  private cloudUpload = new CloudStorageUpload(this);
  fileUploadError = this.cloudUpload.fileUploadError;
  uploading = this.cloudUpload.uploading;
  fileValidationError = ko.observable('');

  constructor(private delegate: VideoUploadDelegate) {}

  onVideoClick() {
    this.delegate.onVideoClick?.();
  }

  onFileContents = (
    userFileName: string,
    fileContents: ArrayBuffer,
    contentType: string,
    prepareXHR: () => XMLHttpRequest
  ) => {
    this.fileValidationError('');
    if (fileContents.byteLength > SIZE_LIMIT) {
        this.fileValidationError(i18n.t('Couldn\'t upload file. File exceeds maximum size limit (50 MB).')());
        return;
    }
    return this.cloudUpload.onFileContents(userFileName, fileContents, contentType, prepareXHR);
  };

  getUploadEndpoint(contentType: string): Promise<FileUploadEndpoint> {
    return this.delegate.getVideoUploadEndpoint(contentType);
  }

  onFileUploaded(userFileName: string, fileName: string, publicURL: string, contentType: string): void {
    this.fileName = fileName;
    // avoid caches, since the old image has the same URL
    this.videoPublicURL(publicURL + '?' + new Date().getTime());
  }
}
