import * as ko from 'knockout';

import { Deferred } from '../utils/deferred';
import { forTrait } from '../api/datasets';
import { DatasetFacts } from './facts_table';
import * as sitesApi from '../api/sites';
import * as cvApi from '../api/crop_varieties';

let template = require('raw-loader!../../templates/components/tpp_facts_popup.html').default;

class TPPFactsPopup {
  loading = ko.observable(true);
  dataset = ko.observable<DatasetFacts>();
  result: Deferred<{}>;

  constructor(params: {
    trialId: string;
    measurementMetaId: string;
    cvId: string;
    siteId: string;
    result: Deferred<{}>;
  }) {
    this.result = params.result;

    Promise.all([
      sitesApi.retrieve(params.siteId),
      cvApi.retrieve(params.cvId),
      forTrait(params.trialId, params.measurementMetaId),
    ])
      .then(([site, cv, dataset]) => {
        this.dataset(new DatasetFacts(params.trialId, dataset, false, { site, cv }));
        this.loading(false);
      })
      .catch((e) => {
        this.loading(false);
        throw e;
      });
  }

  onClose = () => {
    this.result.resolve(null);
  };
}

ko.components.register('tpp-facts-popup', {
  viewModel: TPPFactsPopup,
  template: template,
});
