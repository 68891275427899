import * as ko from 'knockout';

import i18n from '../i18n';
import { tryFormatDate } from '../utils';
import * as datasetsApi from '../api/datasets';
import * as factsApi from '../api/facts';
import * as sitesApi from '../api/sites';
import * as cvApi from '../api/crop_varieties';
import { ListRequestParams, RemoveResult } from '../api/request';
import { ListLoaderDelegate, ListLoader } from '../components/list_loader';
import { asI18nText, I18nText } from '../i18n_text';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { MaybeKO, asObservable } from '../utils/ko_utils';
import { confirmDialog } from './confirm_dialog';
import { session } from '../session';
import { SiteData } from '../api/sites';
import { FilterDelegate } from './list_filters';
import { deflateList, serializeDate } from '../api/serialization';
import { DimensionData } from '../api/dimensions';
import { ColumnSelectManager, ColumnSelectItem } from './columns_select';
import { openFactEdit } from '../screens/fact_edit';
import { showRemoveFactsDialog } from './remove_facts_dialog';

let template = require('raw-loader!../../templates/components/facts_table.html').default;

class FactsTable {
  dataset: KnockoutObservable<DatasetFacts>;
  showPlots: KnockoutObservable<boolean>;
  showMapButton: KnockoutObservable<boolean>;
  allowEdit: KnockoutObservable<boolean>;

  constructor(params: {
    datasetFacts: MaybeKO<DatasetFacts>;
    showPlots: MaybeKO<boolean>;
    showMapButton: MaybeKO<boolean>;
    allowEdit: MaybeKO<boolean>;
  }) {
    this.dataset = asObservable(params.datasetFacts);
    this.showPlots = asObservable(params.showPlots);
    this.showMapButton = asObservable(params.showMapButton);
    this.allowEdit = asObservable(params.allowEdit);
  }
}

export class DatasetFacts implements ListLoaderDelegate<datasetsApi.DatasetFactSummaryData, Fact> {
  private missingRecordsOptions = [
    { id: 'include', name: i18n.t(['include', 'Include'])() },
    { id: 'exclude', name: i18n.t(['exclude', 'Exclude'])() },
  ];

  mapVisualizationFeatureEnabled = session.tenant() && session.tenant().map_visualization_enabled;

  id: string;
  nameJson = ko.observable(asI18nText(''));
  containsManagementData: boolean;
  hasVisits: boolean;
  hasPlots: boolean;
  hasReferenceDim: boolean;
  attributes: datasetsApi.DatasetAttribute[] = [];
  loadingMap = ko.observable(false);
  editMode = ko.observable(false);

  sortBys = ko.observableArray<KnockoutObservable<string>>();
  sortingOptions: { name: string; value: string }[] = [];
  allowEmptySortBy = true;

  private sites = ko.observableArray<SiteData>();
  private cropVarieties = ko.observableArray<DimensionData>();
  private modifiedAfterFilter = ko.observable<Date>(null);
  private missingRecords = ko.observable(this.missingRecordsOptions[0]);
  newFilters: FilterDelegate[] = [];

  columnSelectManager: ColumnSelectManager;

  private loader: ListLoader<datasetsApi.DatasetFactSummaryData, Fact>;

  loading = ko.observable(false);
  private needsReload = false;

  constructor(
    private trialId: string,
    private datasetData: datasetsApi.DatasetSummaryData,
    private allowUserSorting: boolean,
    private initialFilters?: { site: SiteData; cv: DimensionData }
  ) {
    this.setData(datasetData);
  }

  private setData(datasetData: datasetsApi.DatasetSummaryData) {
    this.id = datasetData.id;
    this.nameJson(datasetData.name_json);
    this.containsManagementData = datasetData.contains_management_data;
    this.hasVisits = datasetData.has_visits;
    this.hasPlots = datasetData.has_plots;
    this.hasReferenceDim = datasetData.has_reference_dim;

    this.attributes = datasetData.attributes;

    let cols: ColumnSelectItem[] = [{ title: i18n.t('Last location')(), initialValue: true }];
    cols = cols.concat(datasetData.attributes);
    this.columnSelectManager = new ColumnSelectManager(cols);

    if (datasetData.has_sites && this.initialFilters && this.initialFilters.site) {
      this.sites([this.initialFilters.site]);
    }
    if (datasetData.has_crop_varieties && this.initialFilters && this.initialFilters.cv) {
      this.cropVarieties([this.initialFilters.cv]);
    }

    if (this.allowUserSorting) {
      this.sortingOptions = [
        { name: i18n.t('Select')(), value: '' },
        { name: i18n.t('Modified')(), value: 'timestamp' },
      ];
      if (this.hasVisits) {
        this.sortingOptions.push(
          { name: i18n.t('Visit')(), value: 'visit' },
          { name: i18n.t('Visit date')(), value: 'visit_date' }
        );
      }
      if (this.hasPlots) {
        this.sortingOptions.push({ name: i18n.t('Plot')(), value: 'plot' });
      }
      for (let attr of this.attributes) {
        if (attr.sortable) {
          this.sortingOptions.push({ name: attr.title, value: attr.value });
        }
      }

      this.newFilters = [];
      if (datasetData.has_sites) {
        this.newFilters.push({
          title: i18n.t('Site')(),
          entities: this.sites,
          list: (params) => sitesApi.list({ dataset_id: datasetData.id, ...params }),
        });
      }
      if (datasetData.has_crop_varieties) {
        this.newFilters.push({
          title: i18n.t('Crop variety')(),
          entities: this.cropVarieties,
          list: (params) =>
            cvApi.list({
              dataset_id: datasetData.id,
              sort_by: 'name',
              ...params,
            }),
        });
      }
      this.newFilters.push({
        title: i18n.t('Modified after')(),
        value: this.modifiedAfterFilter,
      });
      this.newFilters.push({
        title: i18n.t(['missing_records', 'Missing records'])(),
        choices: this.missingRecordsOptions,
        value: this.missingRecords,
      });
    } else {
      this.sortingOptions = undefined;
      this.newFilters = undefined;
    }
    let defaultSort = this.attributes.filter((attr) => attr.default_sort >= 0);
    defaultSort.sort((a, b) => a.default_sort - b.default_sort);

    let sortBys = defaultSort.map((attr) => ko.observable(attr.value));
    sortBys.push(ko.observable(''));
    this.sortBys(sortBys);
  }

  onReady(loader: ListLoader<datasetsApi.DatasetFactSummaryData, Fact>) {
    this.loader = loader;
  }

  fetch(params: ListRequestParams): Promise<datasetsApi.DatasetFactSummaryData[]> {
    this.ensureEmptySortBy();

    let sortBy = this.sortBys()
      .map((obs) => obs())
      .filter((value) => value !== '');
    return datasetsApi.listFactSummaryData(this.datasetData.id, {
      sort_by: sortBy,
      include_empty: this.missingRecords().id === 'include',
      ...this.getFilters(),
      ...params,
    });
  }

  private getFilters(): datasetsApi.FactSummaryFilter {
    return {
      site_ids: deflateList(this.sites),
      cv_ids: deflateList(this.cropVarieties),
      modified_after: serializeDate(this.modifiedAfterFilter()),
    };
  }

  private ensureEmptySortBy() {
    let foundEmpty = false;
    let toRemove: KnockoutObservable<string>[] = [];
    for (let sortBy of this.sortBys()) {
      if (sortBy() === '') {
        if (foundEmpty) {
          toRemove.push(sortBy);
        }
        foundEmpty = true;
      }
    }

    if (foundEmpty) {
      for (let obs of toRemove) {
        this.sortBys.remove(obs);
      }
    } else {
      this.sortBys.push(ko.observable(''));
    }
  }

  instantiate(data: datasetsApi.DatasetFactSummaryData) {
    return new Fact(data);
  }

  doRemove = (fact: Fact) => {
    this.loader.confirmRemove(fact);
  };

  remove(id: string): Promise<RemoveResult> {
    return factsApi.remove(id);
  }

  canRemove(entity: Fact): boolean {
    return !!entity.id();
  }

  openEdit = async (fact: Fact) => {
    fact.updateData(await openFactEdit(fact.id()));
  };

  canBulkDelete = ko.pureComputed(
    () =>
      this.editMode() &&
      (this.sites().length > 0 || this.cropVarieties().length > 0 || !!this.modifiedAfterFilter())
  );

  onBulkDelete = async () => {
    await showRemoveFactsDialog(this.datasetData.id, this.getFilters());
    this.loader.refresh();
  };

  viewOnMap = async () => {
    this.loadingMap(true);
    let data = await datasetsApi.listFactSummaryData(this.datasetData.id, {
      sort_by: null,
      include_empty: this.missingRecords().id === 'include',
      ...this.getFilters(),
    });
    this.loadingMap(false);
    this.openMap(data);
  };

  viewFactOnMap = (fact: datasetsApi.DatasetFactSummaryData) => {
    this.openMap([fact]);
  };

  private openMap(facts: datasetsApi.DatasetFactSummaryData[]) {
    app.formsStackController.push({
      title: i18n.t('Map')(),
      name: 'map',
      isBig: true,
      params: {
        value: factSummaryToGeoJSON(facts),
        result: new Deferred<{}>(),
      },
    });
  }

  markReload() {
    this.needsReload = true;
  }

  async reloadIfNecessary() {
    if (!this.needsReload) {
      return;
    }

    // NOTE: this will remove all the DOM elements,
    // so when we set it back to false even non-ko attributes will be re-rendered
    this.loading(true);
    try {
      this.setData(await datasetsApi.retrieve(this.trialId, this.id));
      this.needsReload = false;
    } finally {
      this.loading(false);
    }
  }
}

export function factSummaryToGeoJSON(facts: datasetsApi.DatasetFactSummaryData[]) {
  let features: datasetsApi.GeoJSON[] = [];

  for (let fact of facts) {
    let info: { title: string; value: string }[] = [];
    let factFeatures: datasetsApi.GeoJSON[] = [];
    let id = `${fact.id}-${fact.measurement_meta_id}`;
    for (let value of fact.values || []) {
      if (value.value === null || value.value === undefined) {
        continue;
      }

      if (value.geo) {
        if (value.value) {
          let geoValue = value.value as datasetsApi.GeoJSON;
          features.push(geoValue);
          factFeatures.push(geoValue);

          if (geoValue.properties?.area) {
            info.push({
              title: value.title,
              value: `${geoValue.properties.area.toFixed(0)} m²`,
            });
          } else {
            info.push({
              title: i18n.t('Name')(),
              value: value.title,
            });
          }
          geoValue.properties = {...geoValue.properties, title: i18n.t('Trait')()}
        }
      } else {
        info.push({
          title: value.title,
          value: tryFormatDate(value.value.toString()),
        });
      }
    }

    for (let extra of fact.extras || []) {
      if (extra.comment) {
        info.push({ title: i18n.t('Comment')(), value: extra.comment });
      }
    }

    if (info.length > 0) {
      for (let feature of factFeatures) {
        // Some fact features might be missing the properties key
        feature.properties = {
          ...(feature.properties || {}),
          info,
        };
      }
    }

    for(let feature of factFeatures) {
      if(!feature.properties?.id) {
        feature.properties = {
          ...(feature.properties || {}),
          id
        };
      }
    }

    if (fact.location_lat && fact.location_lon) {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [fact.location_lon, fact.location_lat],
        },
        properties: {
          title: fact.scheduled_visit_name,
          lazy_load_url: `/api/v2/facts/${fact.id}/dim_mes_details/?measurement_meta_id=${fact.measurement_meta_id}`,
          info,
          id,
        },
        metaData: {
          fact_id: fact.id,
          scheduledVisitName: fact.scheduled_visit_name,
          user_id: fact.user_id,
        },
      });
    }
  }

  return features;
}

export function siteSummaryToGeoJSON(sites: datasetsApi.TrialSiteSummaryData[]): datasetsApi.GeoJSON[] {
  return sites
    .filter((site) => site.site_area !== null)
    .map((site) => ({
      type: 'Feature',
      geometry: site.site_area.geometry,
      properties: {
        info: [{ title: i18n.t('Name')(), value: site.name }],
        title: i18n.t('Site shape')(),
        iconType: 'site',
        id: site.id,
      },
    }));
}

class Fact {
  id = ko.observable<string>();
  nameJson = ko.observable<I18nText>(asI18nText(i18n.t(['observations_lowercase', 'observations'])()));
  expanded = ko.observable(false);
  removedMMIds = ko.observableArray();
  hasComment = ko.observable(false);
  hasPicture = ko.observable(false);
  hasDocument = ko.observable(false);
  data = ko.observable<datasetsApi.DatasetFactSummaryData>(null);

  canEdit = ko.pureComputed(() => this.id() && !this.data().is_ranking);

  constructor(data: datasetsApi.DatasetFactSummaryData) {
    this.updateData(data);
  }

  updateData(data: datasetsApi.DatasetFactSummaryData) {
    this.id(data.id);
    this.data(data);
    this.removedMMIds([]);
    this.hasComment(false);
    this.hasPicture(false);
    this.hasDocument(false);
    for (let extra of data.extras) {
      if (extra.comment) {
        this.hasComment(true);
      }
      if (extra.file_url && extra.mime_type.indexOf('image/') == 0) {
        this.hasPicture(true);
      } else if (extra.file_url) {
        this.hasDocument(true);
      }
    }
  }

  valueFor(point: datasetsApi.DatasetFactValue) {
    if (this.removedMMIds.indexOf(point.measurement_meta_id) >= 0) {
      return null;
    }

    return point.value;
  }

  hasValue(point: datasetsApi.DatasetFactValue) {
    let value = this.valueFor(point);

    return value !== null && value !== undefined && value !== '';
  }

  hasFileURL(point: datasetsApi.DatasetFileValue) {
    return point.file_url && this.removedMMIds.indexOf(point.measurement_meta_id) === -1;
  }

  isImage(point: datasetsApi.DatasetFileValue) {
    return point.mime_type.indexOf('image/') === 0;
  }

  toggleExpanded = () => {
    this.expanded(!this.expanded());
  };

  openMap = (point: { value: datasetsApi.GeoJSON }) => {
    if (point.value.properties?.area) {
      point = {
        value: {
          ...point.value,
          properties: {
            ...point.value.properties,
            info: [
              {
                title: i18n.t('Area')(),
                value: `${point.value.properties.area.toFixed(0)} m²`,
              },
            ],
          },
        },
      };
    }

    app.formsStackController.push({
      title: i18n.t('Map')(),
      name: 'map',
      isBig: true,
      params: {
        value: [point.value],
        result: new Deferred<{}>(),
      },
    });
  };

  removeObservation = (point: datasetsApi.DatasetFactValue | datasetsApi.DatasetFileValue) => {
    let title = i18n.t('Removing single observation')();
    let msg = i18n.t(
      'This observation will be set to blank. This operation cannot be reversed. Are you sure you want to continue?'
    )();

    confirmDialog(title, msg, 'dialog-remove')
      .then(() => {
        return factsApi.removeObservation(this.id(), {
          measurement_meta_id: point.measurement_meta_id,
        });
      })
      .then(() => {
        this.removedMMIds.push(point.measurement_meta_id);
      });
  };

  area(point: { value: datasetsApi.GeoJSON }): string {
    if (point && point.value && point.value.properties && point.value.properties.area) {
      return i18n.t('Area')() + ': ' + point.value.properties.area.toFixed(0) + 'm²';
    }

    return '';
  }

  formattedLocation() {
    const fact = this.data();

    if (!fact.location_lat || !fact.location_lon) {
      return '—';
    }

    return `${fact.location_lat.toFixed(4)}, ${fact.location_lon.toFixed(4)}`;
  }
}

export let factsTable = {
  name: 'facts-table',
  viewModel: FactsTable,
  template: template,
};

ko.components.register(factsTable.name, factsTable);
