import * as ko from 'knockout';

import { BaseForm } from './base_form';
import * as svApi from '../api/scheduled_visits';
import { Deferred } from '../utils/deferred';
import { createWithComponent } from '../utils/ko_utils';
import { ScheduledVisitLibrary } from '../models/scheduled_visit';
import { canEditScheduledVisits } from '../permissions';

let template = require('raw-loader!../../templates/scheduled_visit_library_edit.html').default;

class ScheduledVisitLibraryEditScreen extends BaseForm<svApi.ScheduledVisitData> {
  canEdit = ko.observable(canEditScheduledVisits());
  entity = ko.validatedObservable<ScheduledVisitLibrary>(null);

  constructor(
    params: { id: string; result?: Deferred<svApi.ScheduledVisitData> },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let dataPromise = params.id ? svApi.retrieve(params.id) : undefined;
    let promise = Promise.all([dataPromise]).then(([data]) => {
      this.entity(new ScheduledVisitLibrary(data));
    });
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(svApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
        if (!validation.isValid && validation.errors['days_offset']) {
          this.entity().days.serverError(
            validation.errors['days_offset'].join('. ')
          );
        }
      });
    }
  };
}

export const scheduledVisitLibraryEdit = {
  name: 'scheduled-visit-library-edit',
  viewModel: createWithComponent(ScheduledVisitLibraryEditScreen),
  template,
};

ko.components.register(scheduledVisitLibraryEdit.name, scheduledVisitLibraryEdit);
