import { ListRequestParams, request, listRequest, requestWithValidation, ValidationResult } from './request';

export enum Page {
  TRIAL_ANALYSIS = 'trial_analysis',
}

export interface SavedPageFilterData {
  id: number;
  page: Page;
  trial: number | null;
  value: Record<string, string | number>;
}

interface SavedPageFilterListRequestParams extends ListRequestParams {
  page: string;
  trial?: number;
}

export async function list(params: SavedPageFilterListRequestParams): Promise<SavedPageFilterData[]> {
  return listRequest('/api/v2/page-filters/', params, { version: 2 , injectTenant: true });
}

export async function create(data: SavedPageFilterData): Promise<ValidationResult<SavedPageFilterData>> {
  return requestWithValidation('POST', '/api/v2/page-filters/', data, 2);
};

export async function update(data: SavedPageFilterData): Promise<ValidationResult<SavedPageFilterData>> {
  return requestWithValidation('PUT', `/api/v2/page-filters/${data.id}/`, data, 2);
};

export async function remove(id: number): Promise<void> {
  return request<void>('DELETE', `/api/v2/page-filters/${id}/`, {}, { version: 2, injectTenant: true});
}
