import * as ko from 'knockout';
import i18n from '../i18n';

import { RemoveResult, UserListRequestParams } from '../api/request';
import { ListLoaderDelegate, ListFilter } from './list_loader';
import { Deferred } from '../utils/deferred';
import { defaultRateLimit } from '../models/attribute_meta';
import { I18nText } from '../i18n_text';
import * as usersApi from '../api/users';
import { findById } from '../utils';

let template = require('raw-loader!../../templates/components/user_advanced_search.html').default;

interface AdvancedSearchModel<TData, TModel> extends ListLoaderDelegate<TData, TModel> {
  allowMultipleSelections: boolean;
  loading: KnockoutObservable<boolean>;

  getItemName: (item: TModel) => I18nText | string;

  cancel: () => void;
  selectAll: () => void;
  done: () => void;
  onItemSelected: (item: TModel) => void;
  isItemSelected: (item: TModel) => boolean;
}

class UserAdvancedSearch implements AdvancedSearchModel<usersApi.UserData, usersApi.UserData> {
  filters: ListFilter[] = [
    {
      name: i18n.t('Name')(),
      slug: 'name_prefix',
      type: 'text',
      value: ko.observable('').extend({ rateLimit: defaultRateLimit }),
    },
    {
      name: i18n.t('Country')(),
      slug: 'country',
      type: 'text',
      value: ko.observable('').extend({ rateLimit: defaultRateLimit }),
    },
  ];

  private result: Deferred<usersApi.UserData | usersApi.UserData[]>;
  private entities: usersApi.UserData[];
  private selections = ko.observableArray<usersApi.UserData>();

  allowMultipleSelections = true;

  loading = ko.observable(false);

  constructor(params: {
    allowMultipleSelections: boolean;
    initialMultipleSelections: usersApi.UserData[];
    result: Deferred<usersApi.UserData | usersApi.UserData[]>;
  }) {
    this.allowMultipleSelections = params.allowMultipleSelections;
    this.selections(params.initialMultipleSelections || []);
    this.result = params.result;
  }

  getItemName(item: usersApi.UserData): string {
    return item.name;
  }

  cancel = () => {
    this.result.reject();
  };

  selectAll = () => {
    this.selections(this.entities);
  };

  done = () => {
    this.result.resolve(this.selections());
  };

  onItemSelected = (item: usersApi.UserData) => {
    let selection = findById(this.selections(), item.id);
    if (selection) {
      this.selections.remove(selection);
    } else {
      this.selections.push(item);
    }
  };

  isItemSelected = (item: usersApi.UserData) => {
    return !!findById(this.selections(), item.id);
  };

  async fetch(params: UserListRequestParams): Promise<usersApi.UserData[]> {
    params.name_prefix = this.filters[0].value() as string;
    params.country = this.filters[1].value() as string;
    this.entities = await usersApi.list(params);
    return this.entities;
  }

  instantiate(data: usersApi.UserData): usersApi.UserData {
    return data;
  }

  remove(id: string): Promise<RemoveResult> {
    return Promise.reject(null);
  }

  canRemove(entity: usersApi.UserData): boolean {
    return false;
  }
}

export let userAdvancedSearch = {
  name: 'user-advanced-search',
  viewModel: UserAdvancedSearch,
  template: template,
};

ko.components.register(userAdvancedSearch.name, userAdvancedSearch);
