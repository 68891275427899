import * as ko from 'knockout';

import i18n from '../i18n';
import { UserData, list as listUsers } from '../api/users';
import { OrganizationData, organizationTypesApi, OrganizationTypeData } from '../api/organizations';
import { FormSelectSearchConfiguration } from '../components/form_select_search';
import { session } from '../session';
import { CountryData } from '../api/countries';
import { getCountrySearchConfig } from '../components/configs/search_configs';

export class Organization {
  id = ko.observable<string>(null);
  name = ko.observable('').extend({
    required: true,
    serverError: true,
  });
  usersEmails = ko.observableArray<{email: ko.Observable<string>}>([]).extend({
    serverError: true,
  });
  country = ko.observable<CountryData>(null).extend({
    required: true,
  });
  contact = ko.observable('').extend({
    serverError: true,
  });
  address = ko.observable('').extend({
    serverError: true,
  });
  phone = ko.observable('').extend({
    serverError: true,
  });
  email = ko.observable('').extend({
    serverError: true,
  });
  organizationType = ko.observable<OrganizationTypeData>(null).extend({
    required: true,
  });
  responsible = ko.observable<UserData>(null);
  canEdit = ko.pureComputed(() => {
    return !this.id() || !this.country() || session.isAtLeastEditorFor(this.country());
  });

  showUserEmail: boolean;
  editUrl: string;

  countrySearchConfig: FormSelectSearchConfiguration<CountryData>;

  responsibleSearchConfig: FormSelectSearchConfiguration<UserData> = {
    getSummaryName: (user) => {
      return user.name;
    },
    list: (params) => {
      return listUsers({
        include_gate: this.baseName === 'partners',
        ...params,
      });
    },
    entity: this.responsible,
  };

  organizationTypeSearchConfig: FormSelectSearchConfiguration<OrganizationTypeData> = {
    getSummaryName: (entity) => entity.name_json,
    list: (params) => {
      return organizationTypesApi.list({
        use_for_breeders: this.baseName === 'breeders',
        use_for_producers: this.baseName === 'producers',
        use_for_customers: this.baseName === 'customers',
        ...params,
      });
    },
    entity: this.organizationType,
  };

  publicTypeName = ko.pureComputed(() => {
    if (!this.organizationType()) {
      return '';
    }

    return this.organizationType().public ? i18n.t('Public')() : i18n.t('Private')();
  });

  addEmail = () => {
    this.usersEmails.push({'email': ko.observable('')});
  }

  removeEmail = (item: any) => {
    this.usersEmails.remove(item);
  }

  constructor(private baseName: string, public data?: OrganizationData) {
    this.showUserEmail = !session.isCompany() && (baseName === 'producers' || baseName === 'buyers');

    if (data) {
      this.id(data.id);
      this.name(data.name);
      this.country(data.country);
      this.contact(data.contact);
      this.address(data.address);
      this.phone(data.phone);
      this.email(data.email);
      this.organizationType(data.organization_type);
      this.responsible(data.responsible);
      this.usersEmails(data.users_emails.map(email => ({'email': ko.observable(email)})));
    }

    if (session.isAdmin()) {
      this.organizationTypeSearchConfig.create = {
        title: i18n.t(['organization_type_title', 'Organization Type'])(),
        componentName: 'organization-type-edit',
      };
    }

    this.countrySearchConfig = getCountrySearchConfig(this.country);
    this.editUrl = '/' + this.baseName + '/' + this.id();
  }

  toData(): OrganizationData {
    return {
      id: this.id(),
      name: this.name(),
      users_emails: this.usersEmails().map(element => element.email()),
      country: this.country(),
      contact: this.contact(),
      address: this.address(),
      phone: this.phone(),
      email: this.email(),
      organization_type: this.organizationType(),
      responsible: this.responsible(),
    };
  }
}
