import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { EditCustomChart } from '../models/chart';
import * as dashboardApi from '../api/dashboard';

let template = require('raw-loader!../../templates/chart_edit.html').default;

class ChartEditScreen extends BaseForm<{}> {
  private trialId: string;

  trialName = ko.observable('');
  chart = ko.observable<EditCustomChart>(null);

  constructor(params: { trialId: string; id?: string }) {
    super({});

    this.trialId = params.trialId;

    let promise: Promise<void>;
    if (params.id) {
      promise = dashboardApi.retrieveEditCustomChart(params.trialId, params.id).then((data) => {
        this.trialName(data.trial_name);
        this.chart(new EditCustomChart(this.trialId, data, data));
      });
    } else {
      promise = dashboardApi.retrieveChartOptions(params.trialId).then((data) => {
        this.trialName(data.trial_name);
        this.chart(new EditCustomChart(this.trialId, data, undefined));
      });
    }
    this.loadedAfter(promise);
  }

  dispose() {
    if (this.chart()) {
      this.chart().dispose();
    }
  }

  save = () => {
    if (this.chart().validate()) {
      this.saving(true);
      let data = this.chart().toData();
      this.executeSaveRequest(dashboardApi.saveEditCustomChart(this.trialId, data)).then((validation) => {
        this.onRemoteValidation({}, this, validation);
      });
    }
  };
}

export let chartEdit = {
  name: 'chart-edit',
  viewModel: ChartEditScreen,
  template: template,
};

ko.components.register(chartEdit.name, chartEdit);
