import * as ko from 'knockout';

import { DimensionMeta } from '../models/dimension_meta';
import { Dimension } from '../models/dimension';
import * as dimensionsApi from '../api/dimensions';
import { BaseDimensionRecordEditDelegate } from '../components/base_dimension_record_edit';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';

let template = require('raw-loader!../../templates/dimension_record_edit.html').default;

class DimensionRecordEditScreen
  implements BaseDimensionRecordEditDelegate<dimensionsApi.DimensionData, Dimension>
{
  allowAnonymize = true;
  allowLinkingCrops = true;
  anyoneCanEdit = false;

  dimensionMetaId: string;
  dimensionId: string;
  result: Deferred<dimensionsApi.DimensionData>;
  initialName: string;

  onNameOverrideChange: (name: string) => void;
  nameOverride: string;

  dimension = ko.observable<Dimension>(null);

  constructor(params: {
    dimensionMetaId: string;
    id?: string;
    onNameOverrideChange?: (name: string) => void;
    nameOverride?: string;
    initialName?: string;
    result?: Deferred<dimensionsApi.DimensionData>;
  }) {
    this.dimensionMetaId = params.dimensionMetaId;
    this.dimensionId = params.id;
    this.result = params.result;
    this.initialName = params.initialName;
    this.onNameOverrideChange = params.onNameOverrideChange;
    this.nameOverride = params.nameOverride;
  }

  fetch(dimensionMetaId: string, id: string) {
    return dimensionsApi.retrieve(dimensionMetaId, id);
  }

  instantiate(data: dimensionsApi.DimensionData, dimensionMeta: DimensionMeta) {
    let dim = new Dimension(data, dimensionMeta);
    if (!data && this.initialName) {
      dim.nameJson(asI18nText(this.initialName));
    }

    return dim;
  }

  saveRequest(data: dimensionsApi.DimensionData) {
    return dimensionsApi.save(data);
  }
}

export let dimensionRecordEdit = {
  name: 'dimension-record-edit',
  viewModel: DimensionRecordEditScreen,
  template: template,
};

ko.components.register(dimensionRecordEdit.name, dimensionRecordEdit);
