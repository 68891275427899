import * as ko from 'knockout';
import { mergeAll } from '../api/review';
import { app } from '../app';
import i18n from '../i18n';
import { Deferred } from '../utils/deferred';

const template = require('raw-loader!../../templates/components/review_confirm_merge_all.html').default;

class ReviewConfirmMergeAll {
  saving = ko.observable(false);

  constructor(private params: { result: Deferred<void> }) {}

  onConfirm = async () => {
    this.saving(true);

    try {
      await mergeAll();
      this.params.result.resolve();
    } finally {
      this.saving(false);
    }
  };

  onCancel = () => {
    this.params.result.reject();
  };
}

const name = 'review-confirm-merge-all';

ko.components.register(name, { viewModel: ReviewConfirmMergeAll, template });

export function openReviewConfirmMergeAll(): Promise<{}> {
  return app.formsStackController.push({
    title: i18n.t('Confirm')(),
    name,
    params: { result: new Deferred<{}>() },
  });
}
