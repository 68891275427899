import * as ko from 'knockout';

import * as internalApi from '../../api/internal';
import { BaseLoadingScreen } from '../base_loading_screen';

let template = require('raw-loader!../../../templates/internal/tenants_in_trial.html').default;

class TenantsInTrialScreen extends BaseLoadingScreen {
  trial_accounts: internalApi.TenantSubscriptionData[];

  constructor() {
    super();
    let trial_accounts = internalApi.tenantsInTrial().then((data) => {
      this.trial_accounts = data;
    });
    this.loadedAfter(trial_accounts);
  }
}

export let tenantsInTrial = {
  name: 'tenants-in-trial',
  viewModel: TenantsInTrialScreen,
  template: template,
};

ko.components.register(tenantsInTrial.name, tenantsInTrial);
