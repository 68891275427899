window.APP_CONFIG = {
  TERMS_AND_CONDITIONS_URL: '',
  PRIVACY_POLICY_URL: '',
  TUTORIAL_URL: '',
  SHOW_TOUR: false,
  DEFAULT_ANONYMIZATION: 'anonymize',
  TRIAL_FIELDS_SPECIAL: true,
  PRIMARY_COLOR: '#283593',
  PRIMARY_COLOR_LIGHT: '#7986CB',
  ACCENT_COLOR: '#00C853',
  ENABLE_DIM_DISABLE: true,
  ENABLE_REPORTS: true,
  FORCE_TRIAL_RESET: false,
  CUSTOM_VARIETY_REQUEST_URL: '/documents/Variety_Entry_Request_Form.xlsx',
  AUTO_NAME_TRIALS: true,
  SHOW_TPP: true,
  SHOW_REGISTRATIONS: true,
  USE_TPP_MEETING_NAMING: true,
  CROP_VARIETY_REQUIRED_ORIGIN: true,
  CAN_SEE_PRODUCERS: true,
  CAN_REMOVE_RCBD_PLOT: false,
  RESTRICT_OBSERVATIONS_TO_LIBRARY: true,
  MM_LIMIT_TO_REQUIRED: true,
  AUTO_CV_CODES: true,
  CONFIRM_SV_DAYS: true,
  ALLOW_PASSWORD_LOGIN: true,
  SHOW_GEOGRAPHIC_ZONES: true,
};
