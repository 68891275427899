import { makeDefaultApi } from './request';
import { UserLogMixinData } from './changelog';

export interface OrganizationalRegionData extends UserLogMixinData {
  id: string;
  name: string;
  read_only?: boolean;
}

export const orgRegionApi = makeDefaultApi<OrganizationalRegionData>('organizational_regions');
