import * as ko from 'knockout';

import { addMarkers, initMap, resetOverlays } from './map';
import { injectTenantInURL } from '../api/request';
import { GeoZoneColored, SiteGeoZoneType } from '../api/sites';
import { getGeoZoneById } from '../api/v2/geographic_zones';
import i18n from './../i18n';

declare const google: any;

interface Params {
  zoneId: string | null;
  zoneType: SiteGeoZoneType;
  sites: [];
  zonesColored: GeoZoneColored[];
  loading: ko.Observable<boolean>;
}

const emphasizeGeoZonesOnMap = async (params: Params, map: any, zonesColored: GeoZoneColored[]) => {
  params.loading(true);
  map.data.forEach((feature: any) => map.data.remove(feature));
  zonesColored.forEach(async (zoneObject: GeoZoneColored, index: number) => {
    const zone = await getGeoZoneById(zoneObject.id);
    const geojson = zone.points_polygon;
    const features = map.data.addGeoJson({
      type: 'Feature',
      geometry: geojson,
    });
    const bounds = new google.maps.LatLngBounds();
    map.data.overrideStyle(features[0], {
      strokeWeight: 1,
      fillColor: zoneObject.color,
      fillOpacity: 0.5,
    });

    processPoints(features[0].getGeometry(), bounds.extend, bounds);
  });
  params.loading(false);
};

function createLegendComponent(zonesColored: GeoZoneColored[]) {
  // Create the legend container
  const legend = document.createElement('div');
  legend.id = 'map-legend';
  legend.className = 'map-legend';

  const title = document.createElement('div');
  title.className = 'map-legend-title';
  title.textContent = i18n.t('Zones')();
  legend.appendChild(title);

  // Iterate over zonesColored to create each legend item
  zonesColored.forEach((zone) => {
    const item = document.createElement('div');
    item.className = 'map-legend-item';

    const colorDiv = document.createElement('div');
    colorDiv.className = 'map-legend-color';
    colorDiv.style.backgroundColor = zone.color;
    item.appendChild(colorDiv);

    const codeDiv = document.createElement('div');
    codeDiv.className = 'map-legend-code';
    codeDiv.textContent = zone.grid_code.toString();
    item.appendChild(codeDiv);

    legend.appendChild(item);
  });

  return legend;
}

ko.bindingHandlers['mapZone'] = {
  init: (element: Element, valueAccessor: () => Params) => {
    const params = valueAccessor();
    const map = initMap(element);
    const zoneType = ko.unwrap(params.zoneType);
    const sites = ko.unwrap(params.sites);
    const zonesColored: GeoZoneColored[] = ko.unwrap(params.zonesColored);

    const positionsMarkers: any[] = [];
    sites.forEach((site: any) => {
      if (site.gps_location) {
        positionsMarkers.push(new google.maps.Marker({ position: site.gps_location }));
      }
    });
    addMarkers(positionsMarkers);
    const bounds = new google.maps.LatLngBounds();
    positionsMarkers.forEach((marker) => bounds.extend(marker.getPosition()));
    map.setCenter(bounds.getCenter());
    map.setZoom(5);

    const legendComponent = createLegendComponent(zonesColored);
    map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(legendComponent);

    emphasizeGeoZonesOnMap(params, map, zonesColored);

    const tiles = new google.maps.ImageMapType({
      getTileUrl: function (coord: { x: number; y: number }, zoom: number) {
        const normalizedCoord = getNormalizedCoord(coord, zoom);
        const bound = Math.pow(2, zoom);
        if (!normalizedCoord) return '';
        const zoneTypeUrl = zoneType === SiteGeoZoneType.GYGA_TED_ZONE ? 'ted' : 'climate';

        return injectTenantInURL(
          `/api/map_tiles/public/?name=tiles/${zoneTypeUrl}/${zoom}/${normalizedCoord.x}/${
            bound - normalizedCoord.y - 1
          }.png`
        );
      },
      tileSize: new google.maps.Size(256, 256),
      opacity: 0.4,
    });
    map.overlayMapTypes.push(tiles);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      map.data.forEach((feature: any) => map.data.remove(feature));
      map.overlayMapTypes.clear();
      resetOverlays();
      legendComponent.remove();
    });
  },
};

const processPoints = (geometry: any, callback: any, thisArg: any) => {
  if (geometry instanceof google.maps.LatLng) {
    callback.call(thisArg, geometry);
  } else if (geometry instanceof google.maps.Data.Point) {
    callback.call(thisArg, geometry.get());
  } else {
    // @ts-ignore
    geometry.getArray().forEach((g) => {
      processPoints(g, callback, thisArg);
    });
  }
};

// Normalizes the coords that tiles repeat across the x axis (horizontally)
// like the standard Google map tiles.
const getNormalizedCoord = (coord: { x: number; y: number }, zoom: number) => {
  const y = coord.y;
  let x = coord.x;

  // tile range in one direction range is dependent on zoom level
  // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
  const tileRange = 1 << zoom;

  // don't repeat across y-axis (vertically)
  if (y < 0 || y >= tileRange) {
    return null;
  }

  // repeat across x-axis
  if (x < 0 || x >= tileRange) {
    x = ((x % tileRange) + tileRange) % tileRange;
  }

  return { x: x, y: y };
};
