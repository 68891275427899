import * as ko from 'knockout';

import i18n from '../i18n';
import { ImportEntitiesDelegate } from '../components/import_entities';
import { BaseLoadingScreen } from './base_loading_screen';
import { downloadAgroRegionImportTemplate } from '../api/agro_regions';

const template = require('raw-loader!../../templates/components/import_entities_container.html').default;

class ImportAgroRegionsScreen extends BaseLoadingScreen implements ImportEntitiesDelegate<void> {
  title = ko.observable('Import agroclimatic regions');
  description = i18n.t(
    'To import agroclimatic regions, download the Excel template, fill-in the desired records and then upload the file.'
  )();
  backTitle = i18n.t('Back to list')();

  templateBaseName = 'agroclimatic_regions';
  importUrl = '/api/agro_regions/import_records/';

  constructor() {
    super();
    this.loaded();
  }

  downloadTemplate = downloadAgroRegionImportTemplate;
}

export const importAgroRegions = {
  name: 'import-agro-regions',
  viewModel: ImportAgroRegionsScreen,
  template,
};

ko.components.register(importAgroRegions.name, importAgroRegions);
