import * as ko from 'knockout';

import i18n from '../i18n';

ko.bindingHandlers['formattedBoolean'] = {
  update: (element: Element, valueAccessor: () => KnockoutObservable<boolean> | boolean) => {
    element.textContent = ko.unwrap(valueAccessor()) ? i18n.t('Yes')() : i18n.t('No')();
  },
};

ko.bindingHandlers['formattedBooleanOptional'] = {
  update: (element: Element, valueAccessor: () => KnockoutObservable<boolean> | boolean) => {
    let value = ko.unwrap(valueAccessor());
    if (value === null || value === undefined) {
      element.textContent = '';
    } else {
      element.textContent = value ? i18n.t('Yes')() : i18n.t('No')();
    }
  },
};
