import * as ko from 'knockout';

import * as usersApi from '../api/users';
import { gdmDWExport } from '../api/dw_export';

let template = require('raw-loader!../../templates/dw_export.html').default;

class GDMDWExport {
  isAdmin = ko.observable(false);
  ownerEmail = ko.observable<string>('');
  year = ko.observable<string>('');
  downloadUrl = ko.observable<string>(null);
  creatingArchive = ko.observable(false);

  enableInput = ko.pureComputed(() => this.isAdmin() && !this.creatingArchive());
  enabledCreatingArchive = ko.pureComputed(() => this.ownerEmail() !== '' && this.year() !== '');
  constructor() {
    usersApi.me().then((user) => {
      if (user.role !== 'admin') {
        return;
      }

      this.isAdmin(true);
    });
    this.year.subscribe(() => this.downloadUrl(null), this.creatingArchive(false));
    this.ownerEmail.subscribe(() => this.downloadUrl(null), this.creatingArchive(false));
  }

  download = () => {
    if (this.isAdmin() && this.enabledCreatingArchive()) {
      this.creatingArchive(true);
      gdmDWExport({ owner_email: this.ownerEmail(), year: this.year() })
        .then((res) => {
          this.downloadUrl(res.download_url);
        })
        .finally(() => {
          this.creatingArchive(false);
        });
    }
  };
}

export let dwExport = {
  name: 'dw_export',
  viewModel: GDMDWExport,
  template: template,
};

ko.components.register(dwExport.name, dwExport);
