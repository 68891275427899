import * as ko from 'knockout';

import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { RemoveResult } from '../api/request';
import i18n from '../i18n';

let template = require('raw-loader!../../templates/components/remove_dialog.html').default;

class RemoveDialog {
  name: string;
  msgs: string[];
  removeCount: number;
  private remove: () => Promise<RemoveResult>;
  private result: Deferred<{}>;

  deleting = ko.observable(false);
  succeded = ko.observable(null);
  removeResult = ko.observable<RemoveResult>();
  isBulkRemove = ko.pureComputed(() => this.removeCount >= 1);

  itemsText = ko.pureComputed(() => (this.removeCount > 1 ? i18n.t('items')() : i18n.t('item')()) + '.');
  confirmText = ko.pureComputed(() =>
    this.removeCount > 1
      ? i18n.t([
          'confirm_bulk_delete_many',
          'Are you sure you want to delete them?\nThis operation cannot be undone.',
        ])()
      : i18n.t(
          'confirm_bulk_delete_one',
          'Are you sure you want to delete it?\nThis operation cannot be undone.'
        )()
  );

  constructor(params: {
    name: string;
    msgs: string[];
    removeCount: number;
    remove: () => Promise<RemoveResult>;
    result: Deferred<{}>;
  }) {
    this.name = params.name;
    this.msgs = params.msgs;
    this.removeCount = params.removeCount;
    this.remove = params.remove;
    this.result = params.result;
  }

  onConfirm = () => {
    this.deleting(true);
    this.removeResult(null);

    this.remove()
      .then((data) => {
        this.deleting(false);
        if (data.did_delete) {
          this.succeded(true);
          this.result.resolve(null);
        } else {
          this.succeded(false);
          this.removeResult(data);
        }
      })
      .catch(() => {
        this.deleting(false);
        this.succeded(false);
      });
  };

  onCancel = () => {
    this.result.reject();
  };
}

ko.components.register('remove-dialog', {
  viewModel: RemoveDialog,
  template: template,
});

export function removeDialog(
  name: string,
  msgs: string[],
  remove: () => Promise<RemoveResult>,
  removeCount: number = 0
): Promise<{}> {
  return app.formsStackController.push({
    title: i18n.t('Confirm')(),
    name: 'remove-dialog',
    params: { name, msgs, removeCount, remove, result: new Deferred<{}>() },
  });
}
