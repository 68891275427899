import * as ko from 'knockout';

import { ValueMeta } from '../models/value_meta';
import { FormNestedEntitiesConfiguration } from './form_nested_entities';
import { UserData } from '../api/users';
import { FormulaSuggestion } from '../api/measurement_metas';

let template = require('raw-loader!../../templates/components/values_meta.html').default;
let templateMeasurementsMetas =
  require('raw-loader!../../templates/components/measurements_meta.html').default;

class ValuesMeta<T extends ValueMeta> {
  config: FormNestedEntitiesConfiguration<T>;
  user: UserData;
  allowEdit: KnockoutSubscribable<boolean>;
  allowEditAny: KnockoutSubscribable<boolean>;
  allowEditValidation: boolean;
  showAdvanced: KnockoutObservable<boolean>;
  availableSuggestions: KnockoutSubscribable<FormulaSuggestion[]>;

  constructor(params: {
    config: FormNestedEntitiesConfiguration<T>;
    user: UserData;
    allowEdit: KnockoutSubscribable<boolean>;
    allowEditAny: KnockoutSubscribable<boolean>;
    allowEditValidation: boolean;
    showAdvanced?: KnockoutObservable<boolean>;
    availableSuggestions?: KnockoutSubscribable<FormulaSuggestion[]>;
  }) {
    this.config = params.config;
    this.user = params.user;
    this.allowEdit = params.allowEdit;
    this.allowEditAny = params.allowEditAny === undefined ? ko.observable(true) : params.allowEditAny;
    this.allowEditValidation = params.allowEditValidation;
    this.showAdvanced = params.showAdvanced || ko.observable(true);
    this.availableSuggestions = params.availableSuggestions;
  }
}

ko.components.register('values-meta', {
  viewModel: ValuesMeta,
  template: template,
});
ko.components.register('measurements-meta', {
  viewModel: ValuesMeta,
  template: templateMeasurementsMetas,
});
