import * as ko from 'knockout';
import page from 'page';

import i18n from '../i18n';
import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import * as dimensionsApi from '../api/dimensions';
import { CountryData, countriesApi } from '../api/countries';
import * as tppsApi from '../api/tpps';
import * as cropsApi from '../api/crops';
import { FilterDelegate } from '../components/list_filters';
import { deflateList } from '../api/serialization';
import { updateLocationWithQueryString, asArray, downloadBlob, readFromLocalStorage } from '../utils';
import { BaseLoadingScreen } from './base_loading_screen';
import { Action } from '../components/basic_widgets';
import { session } from '../session';
import { translate } from '../i18n_text';
import { tppDownloadProductProfile } from '../components/tpp_download_product_profile';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { StaticList, tppStatesChoices } from '../api/static';
import { canEditTPP } from '../permissions';

const template = require('raw-loader!../../templates/tpps.html').default;

const tppsFiltersStorageKey = 'tpps-filters-v1'; // update when format is not compatible with existing saved formats

class TPPsScreen extends BaseLoadingScreen implements ListLoaderDelegate<tppsApi.TPPListData> {
  private cropFilter = ko.observableArray<dimensionsApi.DimensionData>();
  private countryFilter = ko.observableArray<CountryData>();
  private stateFilter = ko.observableArray<StaticList>(
    tppStatesChoices.filter((state) => state.id === 'active')
  );

  canEdit = canEditTPP();

  newFilters: FilterDelegate[] = [
    { title: i18n.t('Crop')(), entities: this.cropFilter, list: cropsApi.list },
    {
      title: i18n.t('Country')(),
      entities: this.countryFilter,
      list: (params) => countriesApi.list(params),
    },
    {
      title: i18n.t('State')(),
      entities: this.stateFilter,
      list: (params) =>
        new Promise<{ id?: string; name: string }[]>((resolve, reject) => {
          return resolve(tppStatesChoices);
        }),
    },
  ];

  constructor(params: { filters: { crop_ids: string | string[]; country_ids: string | string[] } }) {
    super();
    if (!session.tenant().tpp_enabled) {
      page.redirect(session.toTenantPath('/'));
    }
    const storageFilters = readFromLocalStorage(tppsFiltersStorageKey);
    let filters =
      storageFilters && Object.keys(params.filters).length === 0 ? storageFilters : params.filters;

    let cropIds = asArray(filters.crop_ids);
    let countryIds = asArray(filters.country_ids);
    let cropPromise =
      cropIds.length > 0 ? cropsApi.list({ ids: cropIds }) : Promise.resolve<cropsApi.CropData[]>([]);
    let countryPromise =
      countryIds.length > 0 ? countriesApi.list({ ids: countryIds }) : Promise.resolve<CountryData[]>([]);

    let promise = Promise.all([cropPromise, countryPromise]).then(([cropData, countryData]) => {
      if (cropData) {
        this.cropFilter(cropData);
      }
      if (countryData) {
        this.countryFilter(countryData);
      }
    });
    this.loadedAfter(promise);
  }

  fetch(params: ListRequestParams) {
    let filter = {
      country_ids: deflateList(this.countryFilter),
      crop_ids: deflateList(this.cropFilter),
      state_ids: deflateList(this.stateFilter),
    };
    updateLocationWithQueryString(filter);
    localStorage.setItem(tppsFiltersStorageKey, JSON.stringify(filter));

    return tppsApi.list({ ...filter, ...params });
  }

  instantiate(data: tppsApi.TPPListData) {
    return data;
  }

  remove(id: string) {
    return tppsApi.remove(id);
  }

  canRemove(data: tppsApi.TPPListData) {
    return this.canEdit;
  }

  getActions(entity: tppsApi.TPPListData): Action[] {
    return [
      {
        icon: 'score',
        title: APP_CONFIG.USE_TPP_MEETING_NAMING ? i18n.t('Advancement Meeting')() : i18n.t('Evaluation')(),
        cssClass: '',
        onClick: () => page(session.toTenantPath('/tpp_evaluation/' + entity.id + '/')),
      },
      {
        icon: 'picture_as_pdf',
        title: i18n.t('PDF Product Profile')(),
        cssClass: '',
        onClick: () => {
          tppsApi.tppPDF(entity.id).then((data) => downloadBlob(data, translate(entity.name_json) + '.pdf'));
        },
      },
      {
        icon: 'grid_on',
        title: i18n.t('Excel Product Profile')(),
        cssClass: '',
        onClick: () => {
          app.formsStackController.push({
            title: i18n.t('Preparing product profile')(),
            name: tppDownloadProductProfile.name,
            params: {
              id: entity.id,
              name: translate(entity.name_json),
              result: new Deferred<{}>(),
            },
            showNav: true,
          });
        },
      },
    ];
  }

  formattedAgroRegions(entity: tppsApi.TPPListData): string {
    return entity.target_agro_regions.map((region) => translate(region.name_json)).join(', ');
  }
}

export let tpps = { name: 'tpps', viewModel: TPPsScreen, template };

ko.components.register(tpps.name, tpps);
