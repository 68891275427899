import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';

import { registrationPhasesApi , RegistrationPhaseData} from '../api/registration_phases';
import { canEditRegistrationPhases } from '../permissions';

let template = require('raw-loader!../../templates/registration_phases.html').default;

export class RegistrationPhasesScreen implements ListLoaderDelegate<RegistrationPhaseData> {
  canEdit = canEditRegistrationPhases();

  fetch(params: ListRequestParams) {
    return registrationPhasesApi.list(params);
  }

  instantiate(registrationPhaseData: RegistrationPhaseData) {
    return registrationPhaseData 
  }

  getEditUrl(data: RegistrationPhaseData) {
    return '/registration_phases/' +data.id + '/';
  }

  remove(id: string) {
    return registrationPhasesApi.remove(id);
  }

  canRemove(data: RegistrationPhaseData) {
    return this.canEdit;
  }
}

export let registrationPhases = {
  name: 'registration_phases',
  viewModel: RegistrationPhasesScreen,
  template: template,
};

ko.components.register(registrationPhases.name, registrationPhases);
