import * as ko from 'knockout';

import { FormNestedEntities, FormNestedEntitiesConfiguration } from './form_nested_entities';
import { MaybeKO } from '../utils/ko_utils';

let template = require('raw-loader!../../templates/components/form_tabbed_entities.html').default;

export interface FormTabbedEntitiesConfiguration<T> extends FormNestedEntitiesConfiguration<T> {
  secondaryAddTitle?: ko.Computed<string> | MaybeKO<string>;
  secondaryAddIcon?: string;
  secondaryAdd?(): Promise<T[]>;
  description?: ko.Computed<string> | MaybeKO<string>;
}

class FormTabbedEntities<T> extends FormNestedEntities<T> {
  config: FormTabbedEntitiesConfiguration<T>;

  constructor(params: { config: FormTabbedEntitiesConfiguration<T> }) {
    super(params);

    if (!this.selectedEntity() && params.config.entities().length > 0) {
      this.selectedEntity(params.config.entities()[0]);
    }
  }

  secondaryAdd = () => {
    if (!this.config.secondaryAdd) {
      return;
    }

    this.config.secondaryAdd().then((entities) => {
      if (entities.length > 0) {
        this.selectedEntity(entities[entities.length - 1]);
      }
    });
  };

  isSelected = (entity: T) => {
    return (
      this.selectedEntity() === entity || (!this.selectedEntity() && this.config.entities()[0] === entity)
    );
  };

  toggleSelection = (entity: T) => {
    if (!this.isSelected(entity)) {
      this.selectedEntity(entity);
      this.config.showErrors(entity);
    }
  };
}

ko.components.register('form-tabbed-entities', {
  viewModel: FormTabbedEntities,
  template: template,
});
