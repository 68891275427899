import * as ko from 'knockout';

import { BaseForm } from './base_form';
import { TraitCategory } from '../models/trait_category';
import * as traitCategoriesApi from '../api/trait_categories';
import { Deferred } from '../utils/deferred';
import { asI18nText } from '../i18n_text';
import { createWithComponent } from '../utils/ko_utils';
import { DriverData } from '../api/drivers';
import { session } from '../session';

let template = require('raw-loader!../../templates/trait_category_edit.html').default;

class TraitCategoryEditScreen extends BaseForm<traitCategoriesApi.TraitCategoryData> {
  showDriver = session.tenant().tpp_enabled;

  entity = ko.validatedObservable<TraitCategory>(null);

  constructor(
    params: {
      id: string;
      initialName?: string;
      initialDriver?: ko.Observable<DriverData>;
      result?: Deferred<traitCategoriesApi.TraitCategoryData>;
    },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    super(params);

    let promise = Promise.all([params.id ? traitCategoriesApi.retrieve(params.id) : undefined]).then(
      ([data]) => {
        this.entity(new TraitCategory(data));
        if (!data && params.initialName) {
          this.entity().nameJson(asI18nText(params.initialName));
        }
        const initialDriver = ko.unwrap(params.initialDriver);
        if (!data && initialDriver) {
          this.entity().driver(initialDriver);
        }
      }
    );
    this.loadedAfter(promise).then(() => this.focusFirst(componentInfo.element));
  }

  save = () => {
    if (this.validateLocal(this.entity)) {
      let data = this.entity().toData();
      this.executeSaveRequest(traitCategoriesApi.save(data)).then((validation) => {
        this.onRemoteValidation(data, this.entity(), validation);
      });
    }
  };
}

export let traitCategoryEdit = {
  name: 'trait-category-edit',
  viewModel: createWithComponent(TraitCategoryEditScreen),
  template: template,
};

ko.components.register(traitCategoryEdit.name, traitCategoryEdit);
