export const setUnitPreferenceForTrait= (traitId: string, unitId: string) => {
  let unitPreferencesForTraitsStringified = localStorage.getItem('unitPreferencesForTraits') || "{}";

  let unitPreferencesForTraits = JSON.parse(unitPreferencesForTraitsStringified);

  unitPreferencesForTraits[traitId] = unitId;

  localStorage.setItem('unitPreferencesForTraits', JSON.stringify(unitPreferencesForTraits))

};

export const removeUnitPreferenceForTrait= (traitId: string) => {

}
