import i18n from './i18n';

// source: https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
export const LANGUAGES = [
  ['en', 'English', 'English'],
  ['fr', 'Français', 'French'],
  ['es', 'Español', 'Spanish'],
  ['de', 'Deutsch', 'German'],
  ['pt', 'Português', 'Portuguese'],
  ['bn', 'বাংলা', 'Bengali, Bangla'],
  ['ar', 'العربية', 'Arabic'],
  ['id', 'Bahasa Indonesia', 'Indonesian'],
  ["en_US", "English (United States)", "English (United States)"],
  ["zh", "中文（简体）", "Chinese (Simplified)"],
  ["cs", "Čeština", "Czech"],
  ["sk", "Slovenčina", "Slovak"],
  ["hr", "Hrvatski", "Croatian"],
  ["pl", "Polski", "Polish"],
  ["hu", "Magyar", "Hungarian"],
  ["ro", "Română", "Romanian"],
  ["bg", "Български", "Bulgarian"],
  ["tr", "Türkçe", "Turkish"],
  ["it", "Italiano", "Italian"],
];

export interface I18nText {
  default: string;
  [language: string]: string;
}

/**
 * Tries to return the value in the user prefered language.
 * If it's null or empty, returns the value in the default language
 */
export function translate(text: string | I18nText): string {
  if (!text) {
    return '';
  }
  if (typeof text === 'string') {
    return text;
  }

  return text[i18n.userInputLanguage()] || text[text['default']] || '';
}

export function asI18nText(value: string): I18nText {
  let text: I18nText = {
    default: i18n.userInputLanguage(),
  };
  text[i18n.userInputLanguage()] = value;

  return text;
}

export function copyI18nText(text: I18nText): I18nText {
  return ko.utils.extend({}, text) as I18nText;
}

export function isI18nTextEmpty(text: I18nText): boolean {
  if (!text) {
    return true;
  }

  for (let key in text) {
    if (key !== 'default' && text.hasOwnProperty(key) && text[key]) {
      return false;
    }
  }

  return true;
}

/**
 * Returns the default translation from i18n text
 */
export function getDefaultTranslation(text: I18nText | undefined): string {
  if (!text) {
    return '';
  }
  return text[text['default']] || '';
}
