// Sanitizes the provided value and sets the element's href attribute.

import { sanitizeUrl } from '@braintree/sanitize-url';
import * as ko from 'knockout';

ko.bindingHandlers['safeHref'] = {
  update: function (element: Element, valueAccessor: () => string) {
    const value = ko.utils.unwrapObservable(valueAccessor());
    const safeValue = sanitizeUrl(value);
    element.setAttribute('href', safeValue);
  },
};
