import { PlotListData } from '../../../api/datasets';
import { TrialWizard } from '../../../models/trial';

export class PlotsPreviewModel {
  readonly nRows: number;
  readonly nCols: number;

  private blockWidth: number;
  private blockHeight: number;
  private wrap: boolean;
  private keys: string[] = [];
  private existingIndexes = new Set<number>();
  private nextComboId = 0;
  private assignedComboIds: { [key: string]: number } = {};

  constructor(plotList: PlotListData, wizard: TrialWizard) {
    this.blockWidth = wizard.blockWidth();
    this.blockHeight = wizard.blockHeight();
    this.wrap = wizard.blockWrap();
    let isCRD = wizard.trial().plotDesign() === 'crd';
    let repDmId = wizard.replicationDM.id();

    this.nCols = plotList.plots.column_position.reduce((acc, pos) => Math.max(acc, pos), 1);
    this.nRows = plotList.plots.range_position.reduce((acc, pos) => Math.max(acc, pos), 1);

    for (let i = 0; i < plotList.plots.id.length; i++) {
      let idx = this.plotIndex(plotList.plots.range_position[i], plotList.plots.column_position[i]);

      this.keys[idx] = plotList.plots.dimensions_index[i]
        .map((dimIdx, dmIdx) => (isCRD || plotList.dms[dmIdx].id !== repDmId ? dimIdx.toString() : ''))
        .join('-');

      this.existingIndexes.add(idx);
    }
  }

  exists(x: number, y: number): boolean {
    return this.existingIndexes.has(this.plotIndex(x + 1, y + 1));
  }

  comboId(x: number, y: number): number {
    let key = this.keys[this.plotIndex(x + 1, y + 1)];
    if (this.assignedComboIds[key] === undefined) {
      this.assignedComboIds[key] = this.nextComboId++;
    }

    return this.assignedComboIds[key];
  }

  private plotIndex(range: number, col: number): number {
    return col - 1 + (range - 1) * this.nCols;
  }

  hasBorderTop(x: number, y: number): boolean {
    return x % this.blockHeight === 0;
  }

  hasBorderBottom(x: number, y: number): boolean {
    return x === this.nRows - 1 || (x + 1) % this.blockHeight === 0;
  }

  hasBorderLeft(x: number, y: number): boolean {
    return y === 0 || (y + this.offset(x)) % this.blockWidth === 0;
  }

  hasBorderRight(x: number, y: number): boolean {
    return y === this.nCols - 1 || (y + 1 + this.offset(x)) % this.blockWidth === 0;
  }

  private offset(x: number): number {
    return this.wrap ? x * this.nCols : 0;
  }
}
