import { makeDefaultApi } from './request';
import { I18nText } from '../i18n_text';
import { CountryData } from './countries';

export interface RegistrationRegionData {
  id?: string;
  name_json: I18nText;
  countries: CountryData[];
}

export const regRegionsApi = makeDefaultApi<RegistrationRegionData, { ids?: string[]; country_id?: string }>(
  'registration_regions'
);
