import * as ko from 'knockout';

import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { session } from '../session';
import {
  retrieveCustomReports,
  deleteCustomReport,
  CustomReportData,
} from '../api/custom_report';

let template = require('raw-loader!../../templates/custom_reports.html').default;

class CustomReportScreen {
  // TODO: Change this to CustomReport
  customReports = ko.observableArray<CustomReportData>([]);
  listActions = ko.observableArray<ListHeaderAction>([]);
  isAdmin = session.isAdmin();

  // @ts-ignore
  private noReportsPlaceholderText = session.isAdmin() ? i18n.t('Press Add to include a new custom report')() : i18n.t('No custom reports have been added yet')();

  constructor() {
    // TODO: Check permissions for this button
    if (this.isAdmin) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/custom_reports/new/',
        tooltipTitle: i18n.t('Add Custom Reports')(),
      });
    }

    this.fetchReports();
  }

  fetchReports = async () => {
    const data = await retrieveCustomReports({trial: null});

    this.customReports(data);
  };

  deleteCustomReport = async (id: number) => {
    await deleteCustomReport(id);
    await this.fetchReports();
  };
}

export let customReportsScreen = {
  name: 'custom-dashboard-screen',
  viewModel: CustomReportScreen,
  template: template,
};

ko.components.register(customReportsScreen.name, customReportsScreen);
