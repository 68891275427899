import * as ko from 'knockout';

import i18n from '../../i18n';
import * as dimensionsApi from '../../api/dimensions';
import { CountryData, countriesApi } from '../../api/countries';
import * as groupsApi from '../../api/groups';
import * as seasonsApi from '../../api/seasons';
import * as agroRegionsApi from '../../api/agro_regions';
import * as unitsApi from '../../api/units';
import * as traitCategoriesApi from '../../api/trait_categories';
import * as sitesApi from '../../api/sites';
import * as usersApi from '../../api/users';
import * as cropVarietiesApi from '../../api/crop_varieties';
import * as cropsApi from '../../api/crops';
import * as mmApi from '../../api/measurement_metas';
import * as namesApi from '../../api/names';
import * as trialsTypeApi from '../../api/trial_types';
import * as clientTypesApi from '../../api/client_types';
import * as driversApi from '../../api/drivers';
import * as regionsApi from '../../api/regions';
import * as documentCategoriesApi from '../../api/document_categories';
import * as dimensionMetasApi from '../../api/dimension_metas';
import * as svApi from '../../api/scheduled_visits';
import * as staticOptionsApi from '../../api/static';
import * as mmTagsApi from '../../api/measurement_meta_tags';
import { translate } from '../../i18n_text';
import { FormSelectSearchConfiguration } from '../form_select_search';
import { CV_STAGE_SLUG, CROP_SLUG } from '../../api/dimension_metas';
import { listForSelect } from '../../api/trials';
import { TrialSelectData } from '../../api/base_trials';
import {
  canEditTraitCategory,
  canEditCropVariety,
  canEditCrop,
  canEditBreeder,
  canCreateCountry,
  canEditDimension,
} from '../../permissions';
import { MaybeKO, KOMaybeArray, isKOArray } from '../../utils/ko_utils';
import { asArray } from '../../utils';
import { deflateSingle } from '../../api/serialization';
import { MeasurementMetaData } from '../../api/datasets';
import { session } from '../../session';
import { getCropCategoriesConfig, getCropVarietyTypesConfig } from '../../models/name';
import {
  BreederData,
  breedersApi,
  ProducerData,
  producersApi,
  CustomerData,
  customersApi,
  partnersApi,
} from '../../api/organizations';
import { RegistrationRegionData, regRegionsApi } from '../../api/registration_regions';
import { RegistrationPhaseData, registrationPhasesApi } from '../../api/registration_phases';
import { GetOptions } from '../../api/request';
import { projectApi } from '../../api/projects';
import { portofolioItemApi, PortofolioItemData } from '../../api/portofolio_items';
import { UnitData } from '../../api/units';
import { getDimensionCreate } from '../../models/dimension';

interface Options {
  disableCreate: boolean;
  tenantId?: string;
  publicList?: boolean;
}

export function getCropSearchConfig(
  observable: KOMaybeArray<cropsApi.CropData>,
  options?: Options
): FormSelectSearchConfiguration<cropsApi.CropData> {
  let config: FormSelectSearchConfiguration<cropsApi.CropData> = {
    getSummaryName: (record) => record.name_json,
    list: cropsApi.list,
    advancedSearch: session.isAtLeastStaff() && {
      componentName: 'dimension-advanced-search',
      extraParams: { dimensionMetaId: CROP_SLUG },
      instantiate: (data: cropsApi.CropData) => data,
      serialize: (data) => data,
    },
  };

  if (canEditCrop() && !(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Crop')(),
      componentName: 'crop-edit',
    };
  }

  return addTarget(observable, config);
}

export function getTrialTypeSearchConfig(
  entity: KnockoutObservable<trialsTypeApi.TrialTypeData>,
  options?: Options
): FormSelectSearchConfiguration<trialsTypeApi.TrialTypeData> {
  let config: FormSelectSearchConfiguration<trialsTypeApi.TrialTypeData> = {
    getSummaryName: (record) => record.name_json,
    list: (params) => trialsTypeApi.list(params, options),
    entity,
  };

  if (canEditDimension() && !(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Trial type')(),
      componentName: 'trial-type-edit',
    };
  } else {
    config.create = undefined;
  }

  return config;
}

export function getStageSearchConfig(
  entity: KnockoutObservable<dimensionsApi.DimensionData>,
  options?: Options
): FormSelectSearchConfiguration<dimensionsApi.DimensionData> {
  let config = getSystemDimensionSearchConfig(i18n.t('Stage')(), entity, CV_STAGE_SLUG, options);

  if (canEditDimension() && !(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Stage')(),
      componentName: 'stage-edit',
    };
  } else {
    config.create = undefined;
  }

  return config;
}

export function getSiteSearchConfig(
  entity: KnockoutObservable<sitesApi.SiteData>
): FormSelectSearchConfiguration<sitesApi.SiteData> {
  return {
    getSummaryName: (site) => {
      return site.name_json;
    },
    list: (params) => {
      return sitesApi.list({ country_ids: null, ...params });
    },
    entity: entity,
  };
}

export function getCropVarietySearchConfig(
  entity: KnockoutObservable<namesApi.NameI18nData>,
  crop?: KnockoutObservable<dimensionsApi.DimensionData>
): FormSelectSearchConfiguration<namesApi.NameI18nData>;
export function getCropVarietySearchConfig(
  entity: KnockoutObservable<cropVarietiesApi.CropVarietyData>,
  crop?: KnockoutObservable<dimensionsApi.DimensionData>
): FormSelectSearchConfiguration<cropVarietiesApi.CropVarietyData>;
export function getCropVarietySearchConfig(
  entity: KnockoutObservable<cropVarietiesApi.CropVarietyData>,
  crop?: KnockoutObservable<dimensionsApi.DimensionData>
): FormSelectSearchConfiguration<cropVarietiesApi.CropVarietyData> {
  let config: FormSelectSearchConfiguration<cropVarietiesApi.CropVarietyData> = {
    getSummaryName: (cv) => cv.name_json,
    list: (params) =>
      cropVarietiesApi.list({
        crop_ids: asArray(crop && crop() ? crop().id : null),
        sort_by: null,
        ...params,
      }),
    entity,
  };

  return addCVCreate(config, crop, false);
}

export function getCropVarietyListSearchConfig(
  entities: KnockoutObservableArray<cropVarietiesApi.CropVarietyData>,
  crop?: KnockoutObservable<dimensionsApi.DimensionData>
): FormSelectSearchConfiguration<cropVarietiesApi.CropVarietyData> {
  let config: FormSelectSearchConfiguration<cropVarietiesApi.CropVarietyData> = {
    getSummaryName: (cv) => cv.name_json,
    list: (params) =>
      cropVarietiesApi.list({
        crop_ids: asArray(crop && crop() ? crop().id : null),
        sort_by: null,
        ...params,
      }),
    entities,
  };

  return addCVCreate(config, crop, true);
}

function addCVCreate(
  config: FormSelectSearchConfiguration<cropVarietiesApi.CropVarietyData>,
  crop: KnockoutObservable<dimensionsApi.DimensionData>,
  multi: boolean
) {
  if (canEditCropVariety(session.tenant())) {
    config.create = {
      title: i18n.t('Crop variety')(),
      componentName: 'crop-variety-edit',
      extraParams: { initialCrop: crop, multi },
    };
  }

  return config;
}

export function getSystemDimensionSearchConfig(
  createTitle: string,
  saveTo: KnockoutObservable<dimensionsApi.DimensionData>,
  slug: string,
  options?: Options
): FormSelectSearchConfiguration<dimensionsApi.DimensionData> {
  let config: FormSelectSearchConfiguration<dimensionsApi.DimensionData> = {
    getSummaryName: (record) => {
      return record.name_json;
    },
    list: (params) => {
      return dimensionsApi.list(slug, {}, params);
    },
    entity: saveTo,
    advancedSearch: {
      componentName: 'dimension-advanced-search',
      extraParams: { dimensionMetaId: slug },
      instantiate: (data: dimensionsApi.DimensionData) => data,
      serialize: (data) => data,
    },
  };

  if (!(options && options.disableCreate)) {
    config.create = {
      title: createTitle,
      componentName: 'dimension-record-edit',
      extraParams: { dimensionMetaId: slug },
    };
  }

  return config;
}

interface TrialSearchOptions {
  template: boolean;
  crop?: KnockoutObservable<dimensionsApi.DimensionData>;
  trial_type?: KnockoutObservable<dimensionsApi.DimensionData>;
  include_plot_count?: boolean;
}

export function getTrialSearchConfig(
  trial: KnockoutObservable<TrialSelectData>,
  options: TrialSearchOptions
): FormSelectSearchConfiguration<TrialSelectData> {
  return {
    getSummaryName: (trial) => {
      return trial.name_json;
    },
    list: (params) => {
      let cropIds = ko.unwrap(options.crop) ? [options.crop().id] : [];
      let typeIds = ko.unwrap(options.trial_type) ? [options.trial_type().id] : [];
      return listForSelect({
        template: options.template,
        crop_ids: cropIds,
        trial_type_ids: typeIds,
        include_plot_count: options.include_plot_count,
        ...params,
      });
    },
    entity: trial,
  };
}

export function getCountrySearchConfig(
  observable: KOMaybeArray<CountryData>,
  options?: Options & { role?: 'viewer' | 'editor' | 'head' }
): FormSelectSearchConfiguration<CountryData> {
  let config: FormSelectSearchConfiguration<CountryData> = {
    getSummaryName: (entity) => {
      return entity.name;
    },
    list: (params) => {
      let role = (options && options.role) || 'editor';
      return countriesApi.list({ role, ...params }, options);
    },
  };

  if (!(options && options.disableCreate) && canCreateCountry()) {
    config.create = {
      title: i18n.t('Country')(),
      componentName: 'country-edit',
    };
  }

  return addTarget(observable, config);
}

export function getGroupSearchConfig(
  group: KnockoutObservable<groupsApi.BaseGroupData>
): FormSelectSearchConfiguration<groupsApi.BaseGroupData> {
  return {
    getSummaryName: (group) => {
      return group.name;
    },

    list: (params) => {
      return groupsApi.list(params);
    },

    entity: group,
  };
}

export function getSeasonSearchConfig(
  season: KnockoutObservable<seasonsApi.SeasonData>,
  country?: MaybeKO<CountryData>,
  options?: Options
) {
  let config: FormSelectSearchConfiguration<seasonsApi.SeasonData> = {
    getSummaryName: (record) => {
      return record.name_json;
    },
    list: (params) => {
      let countryValue = country && ko.unwrap(country);
      return seasonsApi.list({
        country_id: country && countryValue ? countryValue.id : undefined,
        ...params,
      });
    },
    entity: season,
  };

  if (!(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Season')(),
      componentName: 'season-edit',
    };
  }

  return config;
}

export function getCustomerSearchConfig(
  customer: KnockoutObservable<namesApi.NameData>,
  options?: Options
): FormSelectSearchConfiguration<namesApi.NameData>;
export function getCustomerSearchConfig(customer: KnockoutObservable<CustomerData>, options?: Options) {
  let config: FormSelectSearchConfiguration<CustomerData> = {
    getSummaryName: (record) => record.name,
    list: (params) => customersApi.list(params),
    entity: customer,
  };

  if (!(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Customers')(),
      componentName: 'customer-edit',
    };
  }

  return config;
}

export function getPartnerSearchConfig(partner: KnockoutObservable<namesApi.NameData>, options?: Options) {
  let config: FormSelectSearchConfiguration<namesApi.NameData> = {
    getSummaryName: (record) => record.name,
    list: (params) => partnersApi.list(params),
    entity: partner,
  };

  if (!(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Partners')(),
      componentName: 'partner-edit',
    };
  }

  return config;
}

export function getAgroRegionSearchConfig(
  region: KOMaybeArray<agroRegionsApi.AgroRegionData>,
  country: MaybeKO<CountryData>
): FormSelectSearchConfiguration<agroRegionsApi.AgroRegionData> {
  return addTarget(region, {
    getSummaryName: (record) => record.name_json,
    list: (params) => {
      let countryValue = ko.unwrap(country);
      return agroRegionsApi.list({
        country_id: countryValue ? countryValue.id : undefined,
        ...params,
      });
    },
    create: {
      title: i18n.t('Agroclimatic region')(),
      componentName: 'agro-region-edit',
      extraParams: { initialCountry: country },
    },
  });
}

export function getUnitSearchConfig(
  entity: KnockoutObservable<UnitData>,
  options?: { getCategories?: () => string[] }
): FormSelectSearchConfiguration<UnitData> {
  return {
    getSummaryName: (entity) =>
      entity.description + ' (' + entity.name + ', ' + entity.unit_category_name + ')',
    list: (params) => {
      const categories = options && options.getCategories ? options.getCategories() : null;
      return unitsApi.list({ categories, ...params });
    },
    entity: entity,
  };
}

export function getTraitCategorySearchConfig(
  entity: KnockoutObservable<traitCategoriesApi.TraitCategoryData>,
  filters?: {
    clientType: KnockoutObservable<clientTypesApi.ClientTypeData>;
    driver: KnockoutObservable<driversApi.DriverData>;
  },
  user?: usersApi.UserData
) {
  let config: FormSelectSearchConfiguration<traitCategoriesApi.TraitCategoryData> = {
    getSummaryName: (record) => {
      return record.name_json;
    },
    list: (params) => {
      let clientTypeIds: string[] = [];
      let driverIds: string[] = [];
      if (filters) {
        clientTypeIds = asArray(ko.unwrap(filters.clientType)?.id);
        driverIds = asArray(ko.unwrap(filters.driver)?.id);
      }
      return traitCategoriesApi.list({
        client_type_ids: clientTypeIds,
        driver_ids: driverIds,
        ...params,
      });
    },
    entity: entity,
  };

  if (user && canEditTraitCategory(user)) {
    config.create = {
      title: i18n.t('Trait Categories')(),
      componentName: 'trait-category-edit',
      extraParams: { initialDriver: filters?.driver },
    };
  }

  return config;
}

export function getUserSearchConfig(
  entity: KOMaybeArray<usersApi.UserData>,
  roles?: usersApi.UserRole[]
): FormSelectSearchConfiguration<usersApi.UserData>;
export function getUserSearchConfig(
  entity: KOMaybeArray<{ id?: string; name: string }>,
  roles?: usersApi.UserRole[]
): FormSelectSearchConfiguration<{ id?: string; name: string }>;
export function getUserSearchConfig(
  entity: KOMaybeArray<{ id?: string; name: string }>,
  roles?: usersApi.UserRole[]
): FormSelectSearchConfiguration<{ id?: string; name: string }> {
  return addTarget(entity, {
    getSummaryName: (record) => record.name,
    list: (params) => usersApi.list({ roles: roles, ...params }),
  });
}

export function getMMLibrarySearchConfig(
  entity: KnockoutObservable<MeasurementMetaData>,
  crops: KOMaybeArray<dimensionsApi.DimensionData>,
  traitCategory: KnockoutObservable<traitCategoriesApi.TraitCategoryData>,
  mmTag: KnockoutObservable<mmTagsApi.MeasurementMetaTagData>,
  options?: {
    onlyFor?: ko.Computed<mmApi.LibraryTypeFilter> | MaybeKO<mmApi.LibraryTypeFilter>;
    management: boolean;
  }
): FormSelectSearchConfiguration<MeasurementMetaData>;
export function getMMLibrarySearchConfig(
  entity: KnockoutObservable<namesApi.NameI18nData>,
  crops: KOMaybeArray<dimensionsApi.DimensionData>,
  traitCategory: KnockoutObservable<traitCategoriesApi.TraitCategoryData>,
  mmTag: KnockoutObservable<mmTagsApi.MeasurementMetaTagData>,
  options?: {
    onlyFor?: ko.Computed<mmApi.LibraryTypeFilter> | MaybeKO<mmApi.LibraryTypeFilter>;
    management: boolean;
  }
): FormSelectSearchConfiguration<namesApi.NameI18nData>;
export function getMMLibrarySearchConfig(
  entity: KnockoutObservable<namesApi.NameI18nData>,
  crops: KOMaybeArray<dimensionsApi.DimensionData>,
  traitCategory: KnockoutObservable<traitCategoriesApi.TraitCategoryData>,
  mmTag: KnockoutObservable<mmTagsApi.MeasurementMetaTagData>,
  options?: {
    onlyFor?: ko.Computed<mmApi.LibraryTypeFilter> | MaybeKO<mmApi.LibraryTypeFilter>;
    management: boolean;
  }
): FormSelectSearchConfiguration<namesApi.NameI18nData> {
  return {
    list: (params) => {
      let cropIds = asArray(crops?.()).map((crop) => crop.id);
      let traitCategoryIds = traitCategory ? asArray(deflateSingle(traitCategory())) : [];
      let mmTagIds = mmTag ? asArray(deflateSingle(mmTag())) : [];
      return mmApi.list({
        crop_ids: cropIds,
        trait_category_ids: traitCategoryIds,
        mm_tag_ids: mmTagIds,
        only_for: (options && ko.unwrap(options.onlyFor)) || null,
        management: options ? options.management : null,
        ...params,
      });
    },
    entity,
    getSummaryName: (entity) => entity.name_json,
  };
}

export function getMMTraitsSearchConfig(
  observable: KOMaybeArray<namesApi.NameI18nData>
): FormSelectSearchConfiguration<namesApi.NameI18nData> {
  let config: FormSelectSearchConfiguration<namesApi.NameI18nData> = {
    getSummaryName: (entity) => entity.name_json,
    advancedSearch: {
      componentName: 'trait-advanced-search',
      extraParams: { initialMultipleSelection: observable },
      instantiate: (data: namesApi.NameI18nData) => {
        return data;
      },
      serialize: (instance) => {
        return instance;
      },
    },
    list: (params) => {
      return mmApi.list({
        crop_ids: null,
        trait_category_ids: null,
        mm_tag_ids: null,
        only_for: null,
        management: false,
        ...params,
      });
    },
  };
  return addTarget(observable, config);
}

export function getCropCategorySearchConfig(
  observable: KnockoutObservable<namesApi.NameI18nData>,
  options?: Options
): FormSelectSearchConfiguration<namesApi.NameI18nData> {
  let config: FormSelectSearchConfiguration<namesApi.NameI18nData> = {
    getSummaryName: (entity) => {
      return entity.name_json;
    },
    list: (params) => {
      return namesApi.list('crop_categories', params, options);
    },
    entity: observable,
  };

  if (!(options && options.disableCreate) && session.isAtLeastEditor()) {
    config.create = {
      title: i18n.t(['crop_category_title', 'Crop Category'])(),
      componentName: 'name-edit',
      extraParams: getCropCategoriesConfig(),
    };
  }

  return config;
}

export function getBreederSearchConfig(
  observable: KnockoutObservable<BreederData | namesApi.NameData>,
  disableCreate?: boolean
): FormSelectSearchConfiguration<BreederData | namesApi.NameData> {
  let config: FormSelectSearchConfiguration<BreederData | namesApi.NameData> = {
    getSummaryName: (entity) => {
      return entity.name;
    },
    list: (params) => {
      return breedersApi.list({ role: 'editor', ...params });
    },
    entity: observable,
  };

  if (!disableCreate && canEditBreeder(session.tenant())) {
    config.create = {
      title: i18n.t('Breeder')(),
      componentName: 'breeder-edit',
    };
  }

  return config;
}

export function getCropVarietyTypeSearchConfig(
  observable: KnockoutObservable<namesApi.NameI18nData>,
  disableCreate?: boolean
): FormSelectSearchConfiguration<namesApi.NameI18nData> {
  let config: FormSelectSearchConfiguration<namesApi.NameI18nData> = {
    getSummaryName: (entity) => {
      return entity.name_json;
    },
    list: (params) => {
      return namesApi.list('crop_variety_types', params);
    },
    entity: observable,
  };

  if (!disableCreate && session.isAtLeastEditor()) {
    config.create = {
      title: i18n.t('OPV/Hybrid')(),
      componentName: 'name-edit',
      extraParams: getCropVarietyTypesConfig(),
    };
  }

  return config;
}

export function getPhaseSearchConfig(
  observable: KOMaybeArray<RegistrationPhaseData> | KnockoutObservable<RegistrationPhaseData>,
  options?: Options,
  type?: string
): FormSelectSearchConfiguration<RegistrationPhaseData> {
  let config: FormSelectSearchConfiguration<RegistrationPhaseData> = {
    getSummaryName: (record) => `${translate(record.name_json)} (${record.type})`,
    list: (params) => registrationPhasesApi.list({ ...params, type: type }, options),
  };

  if (session.isAtLeastEditor() && !(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Registration Phase')(),
      componentName: 'registration-phase-edit',
    };
  }

  return addTarget(observable, config);
}

export function getRegistrationRegionSearchConfig(
  region: KnockoutObservable<RegistrationRegionData>,
  options?: Options
): FormSelectSearchConfiguration<RegistrationRegionData> {
  let config: FormSelectSearchConfiguration<RegistrationRegionData> = {
    getSummaryName: (record) => record.name_json,
    list: (params) => regRegionsApi.list(params, options),
    entity: region,
  };

  if (!(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Registration Region')(),
      componentName: 'registration-region-edit',
    };
  }

  return config;
}

export function getProducerSearchConfig(
  observable: KnockoutObservable<ProducerData>,
  options?: {
    disableCreate?: boolean;
    country?: MaybeKO<CountryData>;
    restrictCountry?: boolean;
  } & GetOptions
): FormSelectSearchConfiguration<ProducerData> {
  let config: FormSelectSearchConfiguration<ProducerData> = {
    getSummaryName: (entity) => {
      return entity.name;
    },
    list: (params) => {
      let countryId = options && ko.unwrap(options.country) && ko.unwrap(options.country).id;
      return producersApi.list(
        {
          role: 'editor',
          country_id: countryId,
          restrict_country: options && options.restrictCountry,
          ...params,
        },
        options
      );
    },
    entity: observable,
  };

  if (session.isAtLeastEditor() && !(options && options.disableCreate)) {
    config.create = {
      title: i18n.t('Producer')(),
      componentName: 'producer-edit',
      extraParams: {
        initialCountry: options && options.country,
      },
    };
  }

  return config;
}

export function getPISearchConfig(
  observable: KOMaybeArray<PortofolioItemData>,
  options?: Options
): FormSelectSearchConfiguration<PortofolioItemData> {
  let config: FormSelectSearchConfiguration<PortofolioItemData> = {
    getSummaryName: (entity) => {
      return `${entity.number} - ${entity.name}`;
    },
    list: (params) => {
      return portofolioItemApi.list(params, options);
    },
  };

  return addTarget(observable, config);
}

export function getProjectSearchConfig(
  observable: KnockoutObservable<namesApi.NameData>,
  options?: Options
): FormSelectSearchConfiguration<namesApi.NameData> {
  let config: FormSelectSearchConfiguration<namesApi.NameData> = {
    getSummaryName: (entity) => {
      return entity.name;
    },
    list: (params) => {
      return projectApi.list(params, options);
    },
    entity: observable,
  };

  if (!(options && options.disableCreate) && session.isAdmin()) {
    config.create = {
      title: i18n.t('Project')(),
      componentName: 'project-edit',
    };
  }

  return config;
}

function addTarget<T>(
  observable: KOMaybeArray<T>,
  config: FormSelectSearchConfiguration<T>
): FormSelectSearchConfiguration<T> {
  if (isKOArray(observable)) {
    config.entities = observable;
  } else {
    config.entity = observable;
  }

  return config;
}

export function getClientTypeSearchConfig(
  observable: KOMaybeArray<clientTypesApi.ClientTypeData>
): FormSelectSearchConfiguration<clientTypesApi.ClientTypeData> {
  let config: FormSelectSearchConfiguration<clientTypesApi.ClientTypeData> = {
    getSummaryName: (entity) => entity.name_json,
    list: (params) => clientTypesApi.list(params),
  };

  if (canEditTraitCategory(session.tenant())) {
    config.create = {
      title: i18n.t('Client Types')(),
      componentName: 'client-type-edit',
    };
  }

  return addTarget(observable, config);
}

export function getDriverSearchConfig(
  observable: KOMaybeArray<driversApi.DriverData>,
  clientType?: ko.Observable<clientTypesApi.ClientTypeData>
): FormSelectSearchConfiguration<driversApi.DriverData> {
  let config: FormSelectSearchConfiguration<driversApi.DriverData> = {
    getSummaryName: (entity) => entity.name_json,
    list: (params) =>
      driversApi.list({
        client_type_ids: asArray(ko.unwrap(clientType)?.id),
        ...params,
      }),
  };

  if (canEditTraitCategory(session.tenant())) {
    config.create = {
      title: i18n.t('Drivers')(),
      componentName: 'driver-edit',
    };
  }

  return addTarget(observable, config);
}

export function getRegionSearchConfig(
  observable: KOMaybeArray<regionsApi.RegionData>,
  country: ko.Observable<CountryData>
): FormSelectSearchConfiguration<regionsApi.RegionData> {
  return addTarget(observable, {
    getSummaryName: (record) => record.name_json,
    list: (params) => {
      let countryData = country();
      return regionsApi.list({
        country_id: countryData ? countryData.id : undefined,
        ...params,
      });
    },
    create: {
      title: i18n.t('Region')(),
      componentName: 'region-edit',
      extraParams: { initialCountry: country() },
    },
  });
}

export function getDocumentCategorySearchConfig(
  observable: KOMaybeArray<documentCategoriesApi.DocumentCategoryData>
): FormSelectSearchConfiguration<documentCategoriesApi.DocumentCategoryData> {
  return addTarget(observable, {
    getSummaryName: (record) => record.name_json,
    list: (params) => documentCategoriesApi.list(params),
    create: {
      title: i18n.t('Document tag')(),
      componentName: 'document-category-edit',
    },
  });
}

export function getDMSearchConfig(
  observable: KOMaybeArray<dimensionMetasApi.DimensionMetaData>
): FormSelectSearchConfiguration<dimensionMetasApi.DimensionMetaData> {
  return addTarget(observable, {
    getSummaryName: (dm) => dm.name_json,
    list: (params) => dimensionMetasApi.listExcludingDates(params),
  });
}

export function getDimensionSearchConfig(
  observable: KOMaybeArray<dimensionsApi.DimensionData>,
  dm: dimensionMetasApi.DimensionMetaData
): FormSelectSearchConfiguration<dimensionsApi.DimensionData> {
  const config = addTarget(observable, {
    getSummaryName: (dimension) => dimension.name_json,
    list: (params) => {
      if (!dm) {
        return Promise.resolve([]);
      }
      return dimensionsApi.list(dm.id, {}, params);
    },
  });

  if (dm && (dm.slug !== dimensionMetasApi.CROP_VARIETY_SLUG || canEditCropVariety(session.tenant()))) {
    config.create = getDimensionCreate(dm);
  }

  return config;
}

export function getScheduledVisitSearchConfig(
  obs: KOMaybeArray<namesApi.NameI18nData>
): FormSelectSearchConfiguration<namesApi.NameI18nData> {
  const resultsFilter = (results: namesApi.NameI18nData[]) => {
    const existingCodes = (obs() as namesApi.NameI18nData[]).map(
      (scheduledVisit: mmTagsApi.MeasurementMetaTagData) => scheduledVisit.code
    );

    return results.filter((scheduledVisit) => !existingCodes.includes(scheduledVisit.code));
  };
  return addTarget(obs, {
    getSummaryName: (sv) => sv.name_json,
    list: (params) => svApi.list(params),
    resultsFilter,
  });
}

export function getMMTagSearchConfig(
  observable: KOMaybeArray<mmTagsApi.MeasurementMetaTagData>,
  options?: { disableCreate: boolean }
): FormSelectSearchConfiguration<mmTagsApi.MeasurementMetaTagData> {
  return addTarget(observable, {
    getSummaryName: (record) => record.name_json,
    list: (params) => mmTagsApi.list(params),
    create: options?.disableCreate
      ? undefined
      : {
          title: i18n.t('Trait tag')(),
          componentName: 'measurement-meta-tag-edit',
        },
  });
}

export function getStaticListSearchConfig(
  observable: KOMaybeArray<staticOptionsApi.StaticList>,
  staticList: staticOptionsApi.StaticList[]
): FormSelectSearchConfiguration<staticOptionsApi.StaticList> {
  return addTarget(observable, {
    getSummaryName: (sv) => sv.name,
    list: (params) =>
      new Promise<{ id?: string; name: string }[]>((resolve, reject) => {
        return resolve(staticList);
      }),
  });
}
