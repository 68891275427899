import * as ko from 'knockout';

import i18n from '../i18n';
import { NameI18nData } from '../api/names';
import { session } from '../session';
import { I18nText } from '../i18n_text';

export class NameI18n {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });
  canEdit = session.isAtLeastEditor();
  editUrl: string;

  constructor(private baseName: string, data?: NameI18nData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
    }

    this.editUrl = '/' + this.baseName + '/' + this.id();
  }

  toData(): NameI18nData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
    };
  }
}

export interface NameEntityConfig {
  baseName: string;
  title: string;
  createTitle: string;
  editTitle: string;
}

export function getCropVarietyTypesConfig(): NameEntityConfig {
  return {
    baseName: 'crop_variety_types',
    title: i18n.t('OPV/Hybrid')(),
    createTitle: i18n.t('Create OPV/Hybrid')(),
    editTitle: i18n.t('Edit OPV/Hybrid')(),
  };
}

export function getCropCategoriesConfig(): NameEntityConfig {
  return {
    baseName: 'crop_categories',
    title: i18n.t('Crop Categories')(),
    createTitle: i18n.t('Create Crop Category')(),
    editTitle: i18n.t('Edit Crop Category')(),
  };
}
