import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import { Group } from '../models/group';
import * as groupsApi from '../api/groups';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { canEditGroup } from '../permissions';

let template = require('raw-loader!../../templates/groups.html').default;

class GroupsScreen implements ListLoaderDelegate<groupsApi.BaseGroupData, Group> {
  canEdit = canEditGroup();
  listActions = ko.observableArray<ListHeaderAction>([]);

  constructor() {
    if (this.canEdit) {
      this.listActions.push({
        title: i18n.t('Add')(),
        icon: 'add_circle',
        href: '/groups/new/',
        tooltipTitle: i18n.t('Add group')(),
      });
    }
  }

  fetch(params: ListRequestParams) {
    return groupsApi.list(params);
  }

  instantiate(groupData: groupsApi.BaseGroupData) {
    return new Group(false, groupData);
  }

  remove(id: string) {
    return groupsApi.remove(id);
  }

  canRemove(group: Group) {
    return this.canEdit;
  }
}

export let groups = {
  name: 'groups',
  viewModel: GroupsScreen,
  template: template,
};

ko.components.register(groups.name, groups);
