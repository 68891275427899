import * as ko from 'knockout';

import i18n from '../../i18n';
import { app } from '../../app';
import { Deferred } from '../../utils/deferred';
import { fieldSorter, focusFirst } from '../../utils';
import { createWithComponent } from '../../utils/ko_utils';
import {
  PlotsSiteEditModel,
  SitePlotData,
  TrialAttributeMeta,
  TrialAttributeValue,
} from './plots_edit/plots_edit_model';
import { session } from '../../session';

const template = require('raw-loader!../../../templates/components/trial_wizard/select_plot.html').default;

type DimensionColumn = { id: string; name: string; idx: number };
type ExtendedSitePlotData = SitePlotData & { [key: number]: string };

class SelectPlot {
  private result: Deferred<SitePlotData>;
  private plotsModel: PlotsSiteEditModel;
  dimensions: DimensionColumn[];
  attributeValues: TrialAttributeValue[];
  attributeMetas: TrialAttributeMeta[];
  sortBy = ko.observableArray([]);
  searchTerm = ko.observable('');
  filteredPlots = ko.pureComputed(() => this.getFilteredPlots());
  filteredSortedPlots = ko.pureComputed(() => {
    return this.filteredPlots().sort(fieldSorter(this.plotsModel.sortPlotsOnAddBy()));
  });

  isTreatmentManagementEnabled = ko.observable(false);

  constructor(
    params: { result: Deferred<SitePlotData>; plots: PlotsSiteEditModel },
    componentInfo: KnockoutComponentTypes.ComponentInfo
  ) {
    this.result = params.result;
    this.plotsModel = params.plots;
    this.dimensions = params.plots.dmExSite();
    this.attributeMetas = this.plotsModel.trialAttributeMetas();
    this.attributeValues = this.plotsModel.trialAttributeValues();
    this.isTreatmentManagementEnabled(session.isTreatmentManagementEnabledForTrial(this.plotsModel.wizard.trial()));
    focusFirst(componentInfo.element);
  }

  private getFilteredPlots() {
    let term = this.searchTerm().trim().toLocaleLowerCase();
    let termParts = term.split(/(?:\s|,)+/).filter((part) => !!part);

    let res: ExtendedSitePlotData[] = [];
    for (let i = 0; i < this.plotsModel.nPlots(); i++) {
      let plot = this.plotsModel.sortedPlot(i);
      if (!plot.excluded) {
        continue;
      }
      const dimensions = this.plotsModel.dimensionsByPlot(plot);
      const plotAttributes = this.plotsModel.attributeValuesByPlot(plot);

      if (!term) {
        res.push({ ...plot, ...dimensions, ...plotAttributes });
        continue;
      }

      let startMatchFrom = 0;
      let searchString = this.plotsModel.dimensionNamesExSite(plot, ' ').toLocaleLowerCase();
      searchString += Object.values(plotAttributes).join(' ');
      searchString += this.plotsModel.treatmentName(plot).toLocaleLowerCase();

      let matches =
        !term ||
        termParts.every((part) => {
          let matchStart = searchString.indexOf(part, startMatchFrom);
          startMatchFrom = matchStart + part.length;

          return matchStart > -1;
        });
      if (matches) {
        res.push({ ...plot, ...dimensions, ...plotAttributes });
      }
    }

    return res;
  }

  getAttributeMetas(dimension: any) {
    if (dimension) return this.attributeMetas.filter((meta) => meta.metaId === dimension.id);
    return this.attributeMetas;
  }

  getDimensionAttributes(plot: any, dmIdx: any) {
    return this.attributeValues.filter((attr) => attr.limitId == this.plotsModel.dimensionId(plot, dmIdx));
  }

  dimensionName(plot: SitePlotData, dmIdx: number) {
    return this.plotsModel.dimensionName(plot, dmIdx);
  }

  treatmentName(plot: SitePlotData) {
    return this.plotsModel.treatmentName(plot);
  }

  onKeyUp = (data: {}, event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (this.filteredPlots().length === 1) {
        this.onSelected(this.filteredSortedPlots()[0]);
      }
    }
    if (event.key === 'Esc' || event.key === 'Escape') {
      this.cancel();
    }
  };

  onSelected = (plot: ExtendedSitePlotData) => {
    this.result.resolve(plot);
  };

  cancel = () => {
    this.result.reject();
  };

  sortColumn = (column: DimensionColumn) => {
    toggleSortField(this.plotsModel.sortPlotsOnAddBy, column.id);
  };

  getSortDirectionByField = (column: DimensionColumn) => {
    if (!column) return null;

    const field = column?.id;
    const sortingColumn = this.plotsModel.sortPlotsOnAddBy;
    let direction = null;
    let index = null;

    if (sortingColumn.indexOf(`-${field}`) > -1) {
      direction = 'desc';
      index = sortingColumn.indexOf(`-${field}`);
    } else if (sortingColumn.indexOf(field) > -1) {
      direction = 'asc';
      index = sortingColumn.indexOf(field);
    }

    if (!direction) return null;
    return { direction, index };
  };
}

const toggleSortField = (arr: ko.ObservableArray<string>, field: string) => {
  // add field to a sort array
  // if field is exists then invert
  // if invert is exists then remove
  if (arr.indexOf(`-${field}`) > -1) {
    arr.remove(`-${field}`);
  } else if (arr.indexOf(field) > -1) {
    arr.replace(field, `-${field}`);
  } else {
    arr.push(field);
  }
};

const name = 'select-plot';

ko.components.register(name, {
  viewModel: createWithComponent(SelectPlot),
  template: template,
});

export function showSelectPlot(plots: PlotsSiteEditModel): Promise<SitePlotData> {
  return app.formsStackController.push({
    title: i18n.t('Select the plot to add')(),
    name,
    className: name,
    showNav: true,
    isBig: true,
    params: { result: new Deferred<SitePlotData>(), plots },
  });
}
