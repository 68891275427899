import * as ko from 'knockout';
import { Deferred } from '../../utils/deferred';

import { app } from '../../app';
import i18n from '../../i18n';
import { chooseDataEntryPage, getExtraPlotData } from '../../utils/qr_code_utils';
import { PlotData, TrialVisits } from '../../api/v2/interfaces';

const template = require('raw-loader!./plot_not_found_redirect.html').default;

class PlotNotFoundRedirect {
  private result: Deferred<{}>;

  plotId: string;

  trialName = ko.observable('');
  siteName = ko.observable('');
  plotName = ko.observable('');
  loading = ko.observable(true);

  trialVisits: TrialVisits = null;
  plot: PlotData = null;

  constructor(params: { plotId: string; result: Deferred<{}> }) {
    this.result = params.result;
    this.plotId = params.plotId;
    this.onOpen();
  }

  onOpen = () => {
    this.loading(true);

    getExtraPlotData(this.plotId).then((response) => {
      this.trialVisits = response.trial;
      this.plot = response.plot;
      this.trialName(this.trialVisits.trial.name);
      this.plotName(this.plot.name);
      const visit = this.trialVisits.visits.find((visit) => visit.site_id === this.plot.site_id);
      this.siteName(visit.site_name);
      this.loading(false);
    });
  };

  onConfirm = () => {
    chooseDataEntryPage(this.plot, this.trialVisits);
  };

  onClose = () => {
    app.formsStackController.clear();
    this.result.resolve(null);
  };
}

ko.components.register('plot-not-found-redirect', {
  viewModel: PlotNotFoundRedirect,
  template,
});

export function openPlotNotFoundRedirect(plotId: string): Promise<{}> {
  return app.formsStackController.push({
    title: i18n.t('Plot not found')(),
    name: 'plot-not-found-redirect',
    params: { plotId, result: new Deferred<{}>() },
  });
}
