import * as ko from 'knockout';

import * as trialDocumentsApi from '../api/trial_documents';
import { DocumentCategoryData } from '../api/document_categories';
import { getDocumentCategorySearchConfig } from '../components/configs/search_configs';

export class TrialDocument {
  id = ko.observable<string>(null);
  sync = ko.observable(false);
  categories = ko.observableArray<DocumentCategoryData>();
  fileName = ko.observable('');
  fileUrl = ko.observable('').extend({ required: true });
  userFileName = ko.observable('');
  mimeType = ko.observable('');

  categoriesSearch = getDocumentCategorySearchConfig(this.categories);

  constructor(data?: trialDocumentsApi.TrialDocumentData) {
    if (data) {
      this.id(data.id);
      this.sync(data.sync);
      this.categories(data.categories);
      this.fileName(data.file_name);
      this.fileUrl(data.file_url);
      this.userFileName(data.user_file_name);
      this.mimeType(data.mime_type);
    }
  }

  toData(): trialDocumentsApi.TrialDocumentData {
    return {
      id: this.id(),
      sync: this.sync(),
      categories: this.categories(),
      file_name: this.fileName(),
      file_url: this.fileUrl(),
      user_file_name: this.userFileName(),
      mime_type: this.mimeType(),
    };
  }
}
