import * as ko from 'knockout';

import { ListLoaderDelegate, NO_PAGINATION } from '../components/list_loader';
import * as trialsApi from '../api/trials';
import i18n from '../i18n';
import { ListHeaderAction } from '../components/list_header';
import { app } from '../app';
import { Deferred } from '../utils/deferred';
import { dimensionMetaEdit } from './dimension_meta_edit';
import { SITE_SLUG } from '../api/dimension_metas';
import { siteEdit } from './site_edit';

let template = require('raw-loader!../../templates/trial_weather_site_status.html').default;

const FORMATTED_STATUS = {
  collecting: i18n.t('Collecting')(),
  not_active: i18n.t('Trial not active')(),
  quota_exceeded: i18n.t('Quota exceeded')(),
  no_dm_attr: i18n.t('No GPS attribute defined for sites')(),
  no_dim_attr: i18n.t('GPS attribute not set for site')(),
  weather_disabled: i18n.t('Weather add-on not active')(),
  unknown: i18n.t('Status not available yet')(),
};

class TrialWeatherSiteStatusScreen implements ListLoaderDelegate<trialsApi.WeatherSiteStatusData> {
  pageSize = NO_PAGINATION;

  private trialId: string;
  private shouldEdit = ko.observableArray<string>();

  listActions = ko.observableArray<ListHeaderAction>();

  constructor(params: { id: string }) {
    this.trialId = params.id;
    this.load();
  }

  private load = async () => {
    let data = await trialsApi.weatherRequiredActions(this.trialId);

    if (data.site_defines_location) {
      this.listActions([]);
    } else {
      this.listActions([
        {
          title: i18n.t('Define GPS attribute')(),
          icon: 'warning',
          onClick: this.onDefineGPS,
        },
      ]);
    }
    this.shouldEdit(data.sites_without_location);
  };

  onDefineGPS = () => {
    app.formsStackController.push({
      title: '',
      name: dimensionMetaEdit.name,
      params: {
        result: this.makeReloadDeferred(),
        id: SITE_SLUG,
        autoAddLocation: true,
      },
    });
  };

  onEditSite = (data: trialsApi.WeatherSiteStatusData) => {
    app.formsStackController.push({
      title: i18n.t('Edit site')(),
      name: siteEdit.name,
      params: {
        result: this.makeReloadDeferred(),
        id: data.site_id,
      },
    });
  };

  private makeReloadDeferred(): Deferred<{}> {
    let res = new Deferred();
    res.promise.then(this.load);

    return res;
  }

  fetch() {
    return trialsApi.listWeatherSiteStatus(this.trialId);
  }

  instantiate(data: trialsApi.WeatherSiteStatusData) {
    return data;
  }

  remove() {
    return Promise.reject(null);
  }

  canRemove() {
    return false;
  }

  goBack = () => {
    history.back();
  };

  formattedStatus(data: trialsApi.WeatherSiteStatusData) {
    return FORMATTED_STATUS[data.status];
  }

  showEditLink(data: trialsApi.WeatherSiteStatusData) {
    return data.status === 'no_dim_attr' && this.shouldEdit().indexOf(data.site_id) > -1;
  }
}

export let trialWeatherSiteStatus = {
  name: 'trial-weather-site-status',
  viewModel: TrialWeatherSiteStatusScreen,
  template: template,
};

ko.components.register(trialWeatherSiteStatus.name, trialWeatherSiteStatus);
