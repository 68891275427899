import * as ko from 'knockout';

import * as documentCategorisApi from '../api/document_categories';
import { I18nText } from '../i18n_text';

export class DocumentCategory {
  id = ko.observable<string>(null);
  nameJson = ko.observable<I18nText>().extend({
    i18nTextRequired: true,
    serverError: true,
  });

  constructor(data?: documentCategorisApi.DocumentCategoryData) {
    if (data) {
      this.id(data.id);
      this.nameJson(data.name_json);
    }
  }

  toData(): documentCategorisApi.DocumentCategoryData {
    return {
      id: this.id(),
      name_json: this.nameJson(),
    };
  }
}
