import * as ko from 'knockout';

import { ListRequestParams } from '../api/request';
import { ListLoaderDelegate } from '../components/list_loader';
import * as mmTagsApi from '../api/measurement_meta_tags';
import { ListHeaderAction } from '../components/list_header';
import i18n from '../i18n';
import { canEditTraitTags } from '../permissions';

const template = require('raw-loader!../../templates/measurement_meta_tags.html').default;

class MeasurementMetaTagsScreen implements ListLoaderDelegate<mmTagsApi.MeasurementMetaTagData> {
  canEdit = canEditTraitTags();
  listActions = ko.observableArray<ListHeaderAction>([]);

  constructor() {
    if (this.canEdit) {
      this.listActions.push(
        {
          title: i18n.t('Add')(),
          icon: 'add_circle',
          href: '/trait_tags/new/',
          tooltipTitle: i18n.t('Add Trait Tag')(),
        }
      );
    }
  }

  fetch(params: ListRequestParams) {
    return mmTagsApi.list(params);
  }

  instantiate(data: mmTagsApi.MeasurementMetaTagData) {
    return data;
  }

  remove(id: string) {
    return mmTagsApi.remove(id);
  }

  canRemove(data: mmTagsApi.MeasurementMetaTagData) {
    return this.canEdit;
  }
}

export const measurementMetaTags = {
  name: 'measurement-meta-tags',
  viewModel: MeasurementMetaTagsScreen,
  template,
};

ko.components.register(measurementMetaTags.name, measurementMetaTags);
